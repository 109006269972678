<template>
  <b-row class="border mt-1">
    <b-col>
      <div
        :key="index"
        v-for="(segment, index) in blankSegments"
      >
        <b>Blank {{ index+1 }}</b>
        <b-table :items="rows[index]" hover responsive small striped>
          <template v-slot:cell(option)="row">
            <div v-markdown="row.item.option"></div>
          </template>
          <template v-slot:cell(response)="row">
            <font-awesome-icon
              icon="check"
              v-if="row.item.response"
            ></font-awesome-icon>
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'FillInTheBlanks',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.createTableData()
    },
    data() {
      return {
        rows: []
      }
    },
    methods: {
      createTableData() {
        for (const [index, segment] of this.blankSegments.entries()) {
          const optionRow = []
          for (const option of segment.options) {
            const row = {
              option,
              response: this.responses[index] === option
            }
            optionRow.push(row)
          }
          if (segment.type === 'short_answer') {
            const row = {
              option: 'Other',
              response: !segment.options.includes(this.responses[index])
            }
            optionRow.push(row)
          }
          this.rows.push(optionRow)
        }
      }
    },
    computed: {
      blankSegments() {
        return this.item.for_exam.segments.filter( (segment) => {
          if (segment.type === 'content') {
            return false
          }
          return true
        })
      },
      responses() {
        return this.item.for_exam.response.filter( (response) => {
          if (response) {
            return true
          }
          return false
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
