<template>
  <div>
    <div v-if="!loading">
      <b-form @submit.prevent="onSubmit" novalidate id="settings-form">
        <div>
          <b-tabs v-model="tabIndex" card content-class="mt-3">
            <b-tab title="Project" class="pt-2 mt-2">
              <b-alert :show="!hasOrg" variant="info"
                >You currently do not have an organization associated with this
                project and will need to select one in order to be able to use
                premium features.</b-alert
              >
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.projectSection
                    variant="primary"
                    class="text-left"
                    >Project Settings</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="projectSection"
                  :visible="sectionIsVisible('settings')"
                  role="tabpanel"
                  accordion="project"
                  @show="setCurrentSection('settings')"
                >
                  <b-card-body>
                    <b-form-group
                      id="input-group-organization-id"
                      label="Organization"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-organization-id"
                    >
                      <b-form-select
                        :options="orgOptions"
                        id="input-organization-id"
                        v-bind:disabled="!!project.organization"
                        v-model="rootFields.organization_id"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      id="input-group-name"
                      label="Name *"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        placeholder="Project name"
                        required
                        type="text"
                        v-model="rootFields.name"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-name"
                      label="Display Name"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-display-name"
                    >
                      <b-form-input
                        id="input-display-name"
                        placeholder="Name to display to candidates. Defaults to project name if not set."
                        type="text"
                        v-model="rootFields.display_name"
                      >
                      </b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-description"
                      label="Description"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-description"
                    >
                      <textarea-autosize
                        :min-height="37"
                        class="form-control"
                        id="input-description"
                        placeholder="Project description"
                        rows="1"
                        v-model="settingsFields.description"
                      />
                    </b-form-group>
                    <b-form-group
                      id="input-group-status"
                      label="Status"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-status"
                    >
                      <b-form-select
                        :options="statusOptions"
                        id="input-status"
                        v-model="metaFields.status"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      id="input-group-score-report-reply-to-email"
                      label="Score Report Reply To Email"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-score-report-reply-to-email"
                    >
                      <b-form-input
                        id="input-score-report-reply-to-email"
                        placeholder="Email to receive replies from score reports"
                        type="text"
                        v-model="settingsFields.score_report_reply_to_email"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-disable-deliveries"
                      label="Disable delivery creation"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-disable-deliveries"
                      :description="orgHasDeliveriesDisabled ? 'Organization has deliveries disabled for all projects' : null"
                    >
                      <b-form-checkbox
                        :disabled="orgHasDeliveriesDisabled"
                        :checked="rootFields.delivery_creation_disabled"
                        id="input-disable-deliveries"
                        @change="onDeliveryCreationDisabledChange"
                      ></b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      id="input-group-references-required"
                      label="Item references required"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="input-references-required"
                    >
                      <b-form-checkbox
                        v-model="settingsFields.item_reference_required"
                        id="input-references-required"
                      ></b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      id="item-writing-instructions-label"
                      label="Item writing instructions"
                      label-cols-lg="3"
                      label-cols-sm="4"
                      label-for="item-writing-instructions"
                    >
                      <textarea-autosize
                        id="item-writing-instructions"
                        rows="1"
                        class="form-control"
                        :min-height="37"
                        placeholder="Enter instructions for item writers"
                        v-model="settingsFields.item_writing_instructions"
                      />
                    </b-form-group>

                    <b-button
                      class="mt-3"
                      :disabled="isSaving || shouldDisable(['edit_exam_settings', 'edit_exam_meta'])"
                      form="settings-form"
                      type="submit"
                      variant="secondary"
                    >
                      <b-spinner
                        label="Small Spinner"
                        small
                        v-show="isSaving"
                      ></b-spinner
                      >&nbsp;Save
                    </b-button>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <AutoNaming
                :project="project"
                :sectionIsVisible="sectionIsVisible"
                :setCurrentSection="setCurrentSection"
                :customFieldOptions="customFieldOptions"
                :isSaving="isSaving"
                :autoNamingChanged="autoNamingChanged"
                :shouldDisable="shouldDisable(['edit_exam_settings', 'edit_exam_meta'])"
              />

              <b-card v-if="isAdmin" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.copyProject
                    variant="primary"
                    class="text-left"
                    >Copy Project</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="copyProject"
                  role="tabpanel"
                  :visible="sectionIsVisible('copy')"
                  accordion="project"
                  @show="setCurrentSection('copy')"
                >
                <b-card-body>
                  <b-form-group label="New project name" label-for="new-proj-name-input">
                    <b-form-input
                      id="new-proj-name-input"
                      placeholder="New project name"
                      type="text"
                      v-model="createForm.name"
                    ></b-form-input>
                  </b-form-group>
                  <ProjectTemplate :project="project" :createForm="createForm" :disableSelector="true" />
                </b-card-body>
                </b-collapse>
              </b-card>
            </b-tab>
            <b-tab title="Custom Fields">
              <b-alert
                class="d-flex justify-content-between align-items-center mt-4"
                show
                v-if="!isPremium && isPremiumEnabled"
                variant="info"
              >
                Free accounts cannot edit custom fields
                <b-button @click="upgrade" size="sm" variant="info"
                  >Upgrade to Premium</b-button
                >
              </b-alert>
              <div
                :key="key"
                no-body
                v-for="(value, key) in metaFields.custom_field_order"
                class="mb-2"
              >
                <h4>{{ groupName(key) }}</h4>
                <div
                  class="mb-4"
                  v-if="metaFields.custom_field_order[key].length"
                >
                  <b-row
                    :key="index"
                    class="pb-1 border-bottom"
                    v-for="(fieldId, index) in metaFields.custom_field_order[
                      key
                    ]"
                  >
                    <b-col class="mt-1" sm="3">
                      <b-link @click="openCustomFieldModal(fieldId, key)">{{
                        metaFields.custom[fieldId].name
                      }}</b-link>
                    </b-col>
                    <b-col class="mt-1" sm="3">{{
                      typeName(metaFields.custom[fieldId].type)
                    }}</b-col>
                    <b-col class="mt-1" sm="3">{{
                      inPreviewText(metaFields.custom[fieldId].preview)
                    }}</b-col>
                    <b-col class="text-right mt-1" sm="3" v-if="isPremium">
                      <b-button-group>
                        <b-button
                          :disabled="shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                          @click="openCustomFieldModal(fieldId, key)"
                          size="sm"
                          variant="white"
                        >
                          <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button
                          :disabled="index === 0 || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                          @click="
                            moveArrayElement(
                              metaFields.custom_field_order[key],
                              index,
                              -1
                            )
                          "
                          size="sm"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="arrow-up"
                          ></font-awesome-icon>
                        </b-button>
                        <b-button
                          :disabled="
                            index ===
                              metaFields.custom_field_order[key].length - 1 || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])
                          "
                          @click="
                            moveArrayElement(
                              metaFields.custom_field_order[key],
                              index,
                              1
                            )
                          "
                          size="sm"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="arrow-down"
                          ></font-awesome-icon>
                        </b-button>
                        <b-button
                          :disabled="shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                          @click="
                            confirmDeleteCustomField(
                              index,
                              key,
                              metaFields.custom[fieldId].name
                            )
                          "
                          size="sm"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                          ></font-awesome-icon>
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </div>
                <p v-else class="color-info">
                  <i>
                    There are no custom fields created for this section.
                  </i>
                </p>
              </div>
              <b-button-group class="mt-3">
                <b-button
                  :disabled="shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                  @click="openCustomFieldModal(null)"
                  size="sm"
                  v-if="isPremium"
                  variant="primary-light"
                  >Add a Custom Field</b-button
                >
              </b-button-group>
              <div>
                <b-button
                  :disabled="isSaving || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                  class="mt-3"
                  form="settings-form"
                  type="submit"
                  variant="secondary"
                >
                  <b-spinner
                    label="Small Spinner"
                    small
                    v-show="isSaving"
                  ></b-spinner
                  >&nbsp;Save
                </b-button>
              </div>
            </b-tab>
            <b-tab title="Item Defaults">
              <b-card class="mb-1" no-body v-for="item in defaultItems" :key="item.type">
                <b-card-header class="p-1">
                  <b-button
                    block
                    class="text-left"
                    size="lg"
                    v-b-toggle="`toggle-${item.type}`"
                    variant="primary"
                    >{{ item.friendlyName }}</b-button
                  >
                </b-card-header>
                <b-collapse :id="`toggle-${item.type}`">
                  <b-card-body>
                    <ItemSettings
                      :fields="item"
                      :type="item.type"
                      :item-ids="allItemIds"
                      settings-only
                    />
                    <div>
                      <b-button
                        :disabled="isSaving || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                        class="mt-4"
                        form="settings-form"
                        type="submit"
                        variant="secondary"
                      >
                      <b-spinner
                        label="Small Spinner"
                        small
                        v-show="isSaving"
                        ></b-spinner
                        >&nbsp;Save
                      </b-button>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-tab>
            <b-tab title="Delivery Settings">
              <b-alert show variant="info">
                <font-awesome-icon icon="info-circle" class="mr-1" />

                Launchpads and LMS have moved. Configure them on the <b-link :to="{ name: 'projectLaunchOptions' }">launch options</b-link> page now.
              </b-alert>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.colorsSection
                    variant="primary"
                    class="text-left"
                    >Exam Colors</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="colorsSection"
                  visible
                  role="tabpanel"
                  accordion="del"
                >
                  <b-card-body>
                    <b-row>
                      <b-col md="4">
                        <div class="row mt-3">
                          <div class="col">
                          <label>Navigation color</label>
                          <ColorInput
                            :value="settingsFields.navigation_color"
                            @color-changed="onColorChanged($event, 'navigation_color')"
                          />
                        </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col">
                          <label>Button color</label>
                          <ColorInput
                            :value="settingsFields.button_color"
                            @color-changed="onColorChanged($event, 'button_color')"
                          />
                        </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col">
                          <label>Background color</label>
                          <ColorInput
                            :value="settingsFields.background_color"
                            @color-changed="onColorChanged($event, 'background_color')"
                          />
                        </div>
                        </div>
                          <b-button variant="outline-primary" class="mt-4" size="sm" @click="setDefaultColors">Use default colors</b-button>
                          <br>
                            <b-button
                              :disabled="isSaving || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                              class="mt-4"
                              form="settings-form"
                              type="submit"
                              variant="secondary"
                            >
                            <b-spinner
                              label="Small Spinner"
                              small
                              v-show="isSaving"
                              ></b-spinner
                              >&nbsp;Save
                            </b-button>
                        <br><br>
                      </b-col>
                      <b-col>
                        <div style="text-align: center;"><small>PREVIEW</small></div>
                          <div class="preview-box">
                            <div class="previewcolumn1">
                              <div class="navigation" :style="{ background: settingsFields.navigation_color }">
                                <span class="top-button" style="line-height: .95em;"><svg data-v-19f9f8c9="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-bars fa-w-14"><path data-v-19f9f8c9="" fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" class=""></path></svg></span>
                                <span class="top-button">Section / Page</span>
                                <span class="top-button">Flag</span>
                              </div>
                              <div class="content-area" :style="{ background: settingsFields.background_color }">
                              <div class="item-box">
                                <p>1. Item stem</p>
                                <hr>
                                <ul>
                                  <li>Option</li>
                                  <li>Option</li>
                                  <li>Option</li>
                                  <li>Option</li>
                                </ul>
                              </div>
                              <div class="bottom-buttons">
                                <span class="button" :style="{ background: settingsFields.button_color }">
                                  Previous
                                </span>
                                <span class="button right" :style="{ background: settingsFields.button_color }">
                                  Next
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="previewcolumn2" :style="{ background: settingsFields.navigation_color }">
                            <div class="previewcolumn2inner">
                                <div class="topbuttons">
                                  <div class="topbutton" :style="{ background: settingsFields.navigation_color }">
                                    Content
                                  </div>
                                  <div class="topbuttonwhite">
                                    Settings
                                  </div>
                                </div>
                                <div class="widgetexpanded">
                                  Expanded content.
                                </div>
                                <div class="examtimer">
                                  <font-awesome-icon icon="clock" /> Exam timer
                                </div>
                                <div class="widget" :style="{ background: settingsFields.navigation_color } ">
                                  Supplemental content
                                </div>
                                <div class="widget" :style="{ background: settingsFields.navigation_color }">
                                  Widget
                                </div>
                            </div>
                          </div> 
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.examineeInfoSection
                    variant="primary"
                    class="text-left"
                    >Examinee Information</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="examineeInfoSection"
                  visible
                  role="tabpanel"
                  accordion="del"
                >
                  <b-card-body>
                    <div class="border-bottom">
                      <b-form-checkbox v-model="settingsFields.use_org_schema" class="mb-3">
                        Use examinee info from the organization
                      </b-form-checkbox>

                      <div v-if="project.organization && settingsFields.use_org_schema">
                        <div v-for="(schema, index) in project.organization.settings.examinee_schema" :key="index">
                          <b-form-group label="Type" label-cols-lg="3" label-cols-md="4">
                            <b-form-select :value="schema.type || 'text'" :options="typeOptions" disabled />
                          </b-form-group>

                          <b-form-group label="Key" label-cols-lg="3" label-cols-md="4">
                            <b-form-input :value="schema.key" disabled />

                            <template #description>
                              <b-form-checkbox :checked="schema.required" class="d-inline mr-2" disabled>
                                Required
                              </b-form-checkbox>

                              <b-form-checkbox :checked="schema.preview" class="d-inline ml-2" disabled>
                                In Preview
                              </b-form-checkbox>
                            </template>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <div v-if="!settingsFields.use_org_schema">
                      <div
                        :key="index"
                        class="py-2 mt-1 border-bottom"
                        v-for="(schema, index) in settingsFields.examinee_schema"
                      >
                        <b-form-group label="Type" label-cols-lg="3" label-cols-md="4">
                          <b-form-select v-model="schema.type" :options="typeOptions" />
                        </b-form-group>

                        <b-form-group label="Key" label-cols-lg="3" label-cols-md="4">
                          <b-form-input v-model="schema.key" />

                          <template #description>
                            <b-form-checkbox v-model="schema.required" class="d-inline mr-2">
                              Required
                            </b-form-checkbox>

                            <b-form-checkbox v-model="schema.preview" class="d-inline ml-2">
                              In Preview
                            </b-form-checkbox>
                          </template>
                        </b-form-group>

                        <div class="w-100 d-flex justify-content-end">
                          <b-button-group>
                            <b-button @click="moveArrayElement(settingsFields.examinee_schema, index, -1)" :disabled="index === 0" size="sm" variant="white">
                              <font-awesome-icon icon="arrow-up" />
                            </b-button>

                            <b-button @click="moveArrayElement(settingsFields.examinee_schema, index, 1)" :disabled="index === settingsFields.examinee_schema.length - 1" size="sm" variant="white">
                              <font-awesome-icon icon="arrow-down" />
                            </b-button>

                            <b-button @click="deleteExamineeSchema(index)" size="sm" variant="white">
                              <font-awesome-icon icon="trash-alt" />
                            </b-button>
                          </b-button-group>
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-button
                        :disabled="shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                        v-if="!settingsFields.use_org_schema"
                        @click="addExamineeSchema()"
                        class="mt-3"
                        size="sm"
                        variant="primary-light"
                        >Add Examinee Info Field</b-button
                      >
                    </div>
                    <b-button
                      :disabled="isSaving || shouldDisable(['edit_exam_meta', 'edit_exam_settings'])"
                      class="mt-3"
                      form="settings-form"
                      type="submit"
                      variant="secondary"
                    >
                      <b-spinner
                        label="Small Spinner"
                        small
                        v-show="isSaving"
                      ></b-spinner
                      >&nbsp;Save
                    </b-button>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-tab>
            <b-tab title="Translations">
              <b-card no-body class="mb-1" v-for="language in languages" :key="language.id">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    @click="toggleCurrentSection(`translations-${language.id}`)"
                    variant="primary"
                    class="text-left"
                    >{{ language.name }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  :id="`translations-${language.id}`"
                  :visible="sectionIsVisible(`translations-${language.id}`)"
                  role="tabpanel"
                  accordion="translations"
                >
                  <b-card-body>
                    <LanguagesTranslationsSettings
                      :language="language"
                      :project="project"
                    />
                  </b-card-body>
                </b-collapse>
              </b-card>

            </b-tab>
          </b-tabs>
        </div>
        <b-modal
          :title="customFieldModalTitle"
          id="custom-field-modal"
          size="xl"
          scrollable
          v-b-modal.modal-xl
          @ok="saveCustomField()"
          @hidden="onHideCustomFieldModal"
        >
          <b-form-group
            id="field-group-location"
            label="Location"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="field-location"
          >
            <b-form-select
              :disabled="locationDisabled"
              :options="customFieldLocationOptions"
              @change="locationChanged"
              id="field-location"
              v-model="selectedLocation"
            ></b-form-select>
          </b-form-group>
          <b-form-group :disabled="!isPremium" label="Name">
            <b-row>
              <b-col class="mt-1" sm="6">
                <b-form-input
                  class="mr-2"
                  placeholder="Field Name"
                  v-model="customField.name"
                ></b-form-input>
              </b-col>
              <b-col class="mt-1" sm="6">
                <b-form-select
                  :disabled="!isFreshField()"
                  :options="customFieldTypeOptions"
                  v-model="customField.type"
                  @change="typeChanged"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-form-group label="Description" class="mt-4">
              <b-row>
                <b-col class="mt-1">
                  <textarea-autosize
                    :min-height="37"
                    class="form-control"
                    placeholder="Description"
                    rows="1"
                    v-model="customField.description"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mt-4">
              <b-form-checkbox
                inline
                v-if="selectedLocation !== 'options'"
                v-model="customField.preview"
                >Show in preview</b-form-checkbox
              >
              <b-form-checkbox inline v-model="customField.required"
                >Required</b-form-checkbox
              >
              <b-form-checkbox inline v-model="customField.force_version"
                >Version on change</b-form-checkbox
              >
              <b-form-checkbox
                v-if="isAdmin"
                inline
                v-model="customField.admin_only"
                >Admin only</b-form-checkbox
              >
              <b-form-checkbox
                inline
                v-if="isSelectField()"
                v-model="customField.extendable"
                >Extendable</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                inline
                v-if="selectedLocation !== 'options' && customField.type === 'select_one'"
                :disabled="!customField.set_content_area && numOtherContentAreaFields(metaFields.custom, customField.id) > 0"
                v-model="customField.set_content_area"
                @change="$forceUpdate()"
                >Set content area on change</b-form-checkbox
              >
              <b-form-checkbox
                inline
                v-if="
                  selectedLocation !== 'options' &&
                    customField.type === 'number'
                "
                v-model="customField.set_difficulty"
                >Set difficulty on change</b-form-checkbox
              >
            </b-form-group>
          </b-form-group>
          <div v-if="isSelectField()">
            <p>Select Options</p>
            <b-form-group :disabled="!isPremium" id="optionlist">
              <div
                v-bind:key="optionId"
                v-for="(optionId, index) in customField.option_order"
              >
                <b-row
                  class="my-4 flex-column-reverse flex-md-row"
                  v-if="customField.options[optionId].is_divider"
                >
                  <b-col md="9">
                    <b-input-group>
                      <b-form-input
                        disabled
                        value="Divider"
                        class="mr-2"
                      ></b-form-input>
                      <b-form-input
                        placeholder="Optional Text"
                        class="mr-2"
                        v-model="customField.options[optionId].text"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="3" class="text-right">
                    <b-button-group>
                      <b-button
                        @click="
                          moveArrayElement(customField.option_order, index, -1)
                        "
                        size="sm"
                        v-bind:disabled="index === 0"
                        variant="white"
                      >
                        <font-awesome-icon icon="arrow-up"></font-awesome-icon>
                      </b-button>
                      <b-button
                        @click="
                          moveArrayElement(customField.option_order, index, 1)
                        "
                        size="sm"
                        v-bind:disabled="
                          index === customField.option_order.length - 1
                        "
                        variant="white"
                      >
                        <font-awesome-icon
                          icon="arrow-down"
                        ></font-awesome-icon>
                      </b-button>
                      <b-button
                        @click="deleteOption(index)"
                        size="sm"
                        variant="white"
                      >
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>

                <b-card
                  bg-variant="light"
                  class="my-3"
                  style="border-left: 5px solid #333"
                  body-class="py-1 pl-2 pr-1"
                  v-if="!customField.options[optionId].is_divider"
                >
                  <b-row class="flex-column-reverse flex-md-row">
                    <b-col md="9" class="mt-1">
                      <b-input-group>
                        <b-form-input
                          class="mr-2 w-50"
                          placeholder="Text"
                          v-model="customField.options[optionId].text"
                        ></b-form-input>
                        <b-form-input
                          placeholder="Preview Text"
                          v-model="customField.options[optionId].preview_text"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col class="text-right">
                      <b-button-group class="mt-2">
                        <b-button
                          :variant="lockVariant(optionId)"
                          @click="toggleOptionLock(optionId)"
                          size="sm"
                        >
                          <font-awesome-icon
                            :icon="lockIcon(optionId)"
                          ></font-awesome-icon>
                        </b-button>
                        <b-button
                          @click="
                            moveArrayElement(
                              customField.option_order,
                              index,
                              -1
                            )
                          "
                          size="sm"
                          v-bind:disabled="index === 0"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="arrow-up"
                          ></font-awesome-icon>
                        </b-button>
                        <b-button
                          @click="
                            moveArrayElement(customField.option_order, index, 1)
                          "
                          size="sm"
                          v-bind:disabled="
                            index === customField.option_order.length - 1
                          "
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="arrow-down"
                          ></font-awesome-icon>
                        </b-button>
                        <b-button
                          :disabled="shouldDisableDelete(index)"
                          @click="deleteOption(index)"
                          size="sm"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                          ></font-awesome-icon>
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="9">
                      <b-input-group>
                        <textarea-autosize
                          :min-height="38"
                          class="form-control mb-1 mr-2 w-50"
                          placeholder="Description"
                          rows="1"
                          v-model="customField.options[optionId].description"
                        />
                        <b-form-input
                          min="1"
                          placeholder="Target"
                          step="1"
                          type="number"
                          v-model.number="customField.options[optionId].target"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col>
                      <b-button-group :id="'picker' + optionId">
                        <div
                          id="picker"
                          v-if="colorPickerId === 'picker' + optionId"
                        >
                          <ColorPicker
                            :value="colorPickerValue"
                            @input="updateColor"
                          />
                        </div>
                        <b-form-input
                          :value="customField.options[optionId].color"
                          @focus.stop="toggleColorPicker(optionId, true)"
                          @input="colorFromInput($event, optionId)"
                          placeholder="Color"
                        ></b-form-input>
                        <b-button
                          @click.stop="toggleColorPicker(optionId)"
                          style="color: #eee;"
                          v-bind:style="{
                            'background-color':
                              customField.options[optionId].color
                          }"
                          variant="white"
                        >
                          <font-awesome-icon
                            icon="eye-dropper"
                          ></font-awesome-icon>
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-form-group>
            <b-button-group v-if="isPremium">
              <b-button @click="addOption()" size="sm" variant="primary-light"
                >Add Option</b-button
              >
              <b-button @click="addDivider()" size="sm" variant="primary-light"
                >Add Divider</b-button
              >
            </b-button-group>
          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button-group>
              <b-button @click="cancel" variant="white">Cancel</b-button>
              <b-button @click="ok" v-if="isPremium" variant="secondary"
                >Save</b-button
              >
            </b-button-group>
          </template>
        </b-modal>
        <b-modal
          @ok="deleteCustomField"
          id="delete-custom-field-modal"
          size="xl"
          title="Confirm"
        >
          Are you sure you want delete the custom field
          <span class="font-weight-bold">{{ customModalData.name }}</span
          >?
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button-group>
              <b-button @click="cancel" variant="white">Cancel</b-button>
              <b-button @click="ok" variant="warning"
                >Delete custom field</b-button
              >
            </b-button-group>
          </template>
        </b-modal>
      </b-form>

      <!-- Confirm slug change modal -->
      <b-modal
        @ok="saveProject({ updateSlug: true })"
        @cancel="saveProject({ updateSlug: false })"
        id="confirm-slug-change-modal"
        size="xl"
        title="Update slug"
      >
        Exam name changed! Do you want to update the exam slug? Select Ok to
        update the slug and invalid saved links. Select No to save the name
        only.
        <template v-slot:modal-footer="{ ok, cancel, close }">
          <b-button-group>
            <b-button @click="close" variant="white">Cancel</b-button>
            <b-button @click="cancel" variant="white"
              >No. Update exam name only.</b-button
            >
            <b-button @click="ok" variant="warning"
              >Yes. Update slug and invalidate links.</b-button
            >
          </b-button-group>
        </template>
      </b-modal>

      <!-- Confirm custom field type delete modal -->
      <b-modal
        @ok="markOptionForDelete()"
        id="delete-custom-field-type-modal"
        title="Confirm"
      >
        The option you are trying to delete might be an item's only selected option.
        Please select a replacement option for when this is the case:
        <br><br>
        <b-form-select
          :options="deleteOptionTypeModalData.options"
          v-model="deleteOptionTypeModalData.replacementOptionKey"
        ></b-form-select>
        <br><br>
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button-group>
            <b-button @click="cancel" variant="white">Cancel</b-button>
            <b-button :disabled="!deleteOptionTypeModalData.replacementOptionKey" @click="ok" variant="warning"
              >Delete</b-button
            >
          </b-button-group>
        </template>
      </b-modal>

    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import { HTTP } from '../../utils/requests'
  import { DEFAULT_EXAM_COLORS, SEI_API_BASE, STATUS_OPTIONS, IS_UPGRADE_TO_PREMIUM_ENABLED, DEFAULT_ITEM_SETTINGS } from '../../utils/constants'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy } from '../../utils/misc.js'
  import { VALIDATE } from '../../utils/validate'
  import { SESSION } from '../../utils/session'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { DefaultItem } from '../../utils/default-item'
  import { DateTimeParser } from '../../utils/date-time-parser'
  import { Language } from '../../utils/language'

  import get from 'lodash.get'

  import { Chrome } from 'vue-color'
  import Spinner from '../Spinner'
  import ProjectTemplate from '../ProjectTemplate'
  import ColorInput from './ColorInput'
  import AutoNaming from './AutoNaming'
  import ItemSettings from  './ItemSettings'
  import LanguagesTranslationsSettings from './LanguagesTranslationsSettings.vue'

  const EMTPY_CUSTOM_FIELD = {
    name: '',
    preview: false,
    required: false,
    extendable: false,
    force_version: false,
    by_option: false,
    set_content_area: false,
    set_difficulty: false,
    option_order: [],
    type: 'text',
    replaced_options: {}
  }

  const CUSTOM_FIELD_TYPE_OPTIONS = [
    { value: 'text', text: 'Text' },
    { value: 'number', text: 'Number' },
    { value: 'select_one', text: 'Select One' },
    { value: 'select_multi', text: 'Select Multiple' }
  ]

  const CUSTOM_FIELD_LOCATION_OPTIONS = [
    { value: 'top', text: 'Item Top' },
    { value: 'options', text: 'Item Options' },
    { value: 'meta', text: 'Item Meta' }
  ]

  const TYPE_OPTIONS = [
    { text: 'Text', value: 'text' },
    { text: 'Email', value: 'email' },
    { text: 'Name', value: 'name' },
  ]

  const EMPTY_OPTION = {
    text: '',
    color: '',
    preview_text: '',
    target: null,
    lock: false
  }

  const EMPTY_DIVIDER = {
    text: '',
    is_divider: true
  }

  const TYPE_NAMES = {
    text: 'Text',
    number: 'Number',
    select_one: 'Select One',
    select_multi: 'Select Multiple'
  }

  const TAB_NAMES = ['#Project', '#CustomFields', '#ItemDefaults', '#Deliveries', '#Translations']

  function splitCustomOrderIntoGroups(order = [], fields) {
    const groups = {
      top: [],
      options: [],
      meta: []
    }

    for (const id of order) {
      const field = fields[id]

      if (field.pinned) {
        groups.top.push(id)
        continue
      }

      if (field.by_option) {
        groups.options.push(id)
        continue
      }

      groups.meta.push(id)
    }

    return groups
  }

  function submitFormAfterModalClose() {
    setTimeout(() => {
      const form = document.getElementById('settings-form')
      form.dispatchEvent(new Event('submit', { cancelable: true }))
    }, 500)
  }

  function isValidHexColor(color) {
    return /^#[0-9A-F]{6}$/i.test(color) || !color
  }

  export default {
    name: 'Settings',
    components: {
      ColorPicker: Chrome,
      Spinner,
      ProjectTemplate,
      ColorInput,
      AutoNaming,
      ItemSettings,
      LanguagesTranslationsSettings
    },
    async created() {
      this.cache.name = this.project.name
      this.setTabIndex()

      this.section = get(this.$router, 'currentRoute.query.section', 'settings')

      await this.setOrgOptions()
      this.setFields()
      this.loading = false

      this.metaHelper = initMetaHelper(this.project)

      if (this.metaHelper.hasCustomFields) {
        this.customFieldOptions = this.project.meta.scorpion.custom_field_order
          .map(fieldId => this.project.meta.scorpion.custom[fieldId])
          .filter(field => field.type !== 'select_multi')
          .filter(field => !field.by_option)
          .map(field => ({ value: field.id, text: field.name }))
      }

      this.customFieldOptions = [
        { value: '', text: 'All' },
        ...this.customFieldOptions
      ]

      this.dateTimeParser = DateTimeParser(this.$moment)
      this.FetchLanguages()
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickedOutside)
    },
    props: {
      project: {
        type: Object
      },
      isSaving: {
        type: Boolean
      },
      isPremium: {
        type: Boolean
      }
    },
    data() {
      return {
        loading: true,
        colorPickerId: null,
        rootFields: {},
        metaFields: {},
        settingsFields: {},
        customModalData: {},
        orgOptions: [],
        statusOptions: STATUS_OPTIONS,
        customFieldLocationOptions: CUSTOM_FIELD_LOCATION_OPTIONS,
        customFieldTypeOptions: CUSTOM_FIELD_TYPE_OPTIONS,
        customField: EMTPY_CUSTOM_FIELD,
        typeOptions: TYPE_OPTIONS,
        customFieldModalTitle: 'New Custom Field',
        selectedLocation: 'meta',
        namingScheme: [],
        customFieldOptions: [],
        metaHelper: {},
        editingOptionId: '',
        tabIndex: 0,
        section: '',
        cache: {},
        createForm: {
          name: ''
        },
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED,
        allItemIds: [],
        defaultItems: [],
        languages: [],
        deleteOptionTypeModalData: {
          index: -1,
          options: [],
          replacementOptionKey: ''
        },
        newOptionIds: []
      }
    },
    methods: {
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.saveProject()
        }
      },
      saveProject(options) {
        const nameChanged = this.cache.name !== this.rootFields.name

        if (!options && nameChanged) {
          this.$bvModal.show('confirm-slug-change-modal')
          return
        }

        const updateSlug = get(options, 'updateSlug', false)

        let safeProject = deepCopy(this.project)
        const metaFields = deepCopy(this.metaFields)

        const order = []
        for (const groupName in metaFields.custom_field_order) {
          const groupOrder = metaFields.custom_field_order[groupName]
          order.push(...groupOrder)
        }

        metaFields.custom_field_order = order

        if (!safeProject.settings.item_defaults) {
          safeProject.settings.item_defaults = {}
        }

        Object.assign(safeProject, this.rootFields)
        Object.assign(safeProject.settings, this.settingsFields)
        Object.assign(safeProject.meta.scorpion, metaFields)
        Object.assign(safeProject.settings.item_defaults, DefaultItem.BuildItemDefaults(this.defaultItems))

        this.cache.name = safeProject.name
        EVENT.saveProject(safeProject, { updateSlug })
      },
      async getOrganizations() {
        const url = `${SEI_API_BASE}/organizations`
        const response = await HTTP.get(url)
        return response.data.results
      },
      async setOrgOptions() {
        const orgs = await this.getOrganizations()
        let options = [{ text: 'Select', value: null, disabled: true }]

        if (this.project.organization) {
          const { id, name } = this.project.organization
          options.push({
            value: id,
            text: name
          })
        }

        for (let org of orgs) {
          options.push({ value: org.id, text: org.name })
        }
        this.orgOptions = options
      },
      setFields() {
        if (!this.project.meta.hasOwnProperty('scorpion')) { // eslint-disable-line no-prototype-builtins
          this.project.meta.scorpion = {} // eslint-disable-line vue/no-mutating-props
        }
        this.rootFields = {
          name: this.project.name,
          display_name: this.project.display_name,
          organization_id: this.project.organization_id,
          delivery_creation_disabled:
            this.project.delivery_creation_disabled || false
        }
        this.settingsFields = {
          examinee_schema: this.project.settings.examinee_schema || [],
          use_org_schema: this.project.settings.use_org_schema,
          naming_scheme_segments:
            this.project.settings.naming_scheme_segments || [],
          naming_scheme_compiled: this.project.settings.naming_scheme_compiled,
          naming_scheme_advanced:
            this.project.settings.naming_scheme_advanced || false,
          description: this.project.settings.description,
          item_reference_required: this.project.settings.item_reference_required,
          navigation_color: this.project.settings.navigation_color || DEFAULT_EXAM_COLORS.navigation_color,
          button_color: this.project.settings.button_color || DEFAULT_EXAM_COLORS.button_color,
          background_color: this.project.settings.background_color || DEFAULT_EXAM_COLORS.background_color,
          score_report_reply_to_email: this.project.settings.score_report_reply_to_email,
          item_writing_instructions: this.project.settings.item_writing_instructions
        }
        this.metaFields = {
          status: this.project.meta.scorpion.status,
          custom: this.project.meta.scorpion.custom || {},
          custom_field_order: splitCustomOrderIntoGroups(
            this.project.meta.scorpion.custom_field_order,
            this.project.meta.scorpion.custom
          )
        }

        for (const schema of this.settingsFields.examinee_schema) {
          if (!schema.type) schema.type = 'text'
        }

        const defaultItems = []
        for (const type of Object.keys(DEFAULT_ITEM_SETTINGS)) {
          const item = new DefaultItem(type)
          const settings = this.project.settings.item_defaults?.settings?.[type]

          if (settings)  {
            item.updateSettings(settings)
          }

          defaultItems.push(item)
        }
        this.defaultItems = defaultItems
      },
      openCustomFieldModal(fieldId, key = 'meta') {
        this.selectedLocation = key
        if (fieldId) {
          if (key !== 'options') {
            this.customFieldLocationOptions = CUSTOM_FIELD_LOCATION_OPTIONS.filter(
              location => location.value !== 'options'
            )
          } else {
            this.customFieldLocationOptions = CUSTOM_FIELD_LOCATION_OPTIONS
          }

          this.customField = deepCopy(this.metaFields.custom[fieldId])
          this.customFieldModalTitle = this.customField.name
          this.customModalData.key = key
        } else {
          this.customFieldLocationOptions = CUSTOM_FIELD_LOCATION_OPTIONS
          this.customField = deepCopy(EMTPY_CUSTOM_FIELD)
          this.customFieldModalTitle = 'New Custom Field'
        }

        this.customField['replaced_options'] = {}
        this.locationChanged(key)
        this.$bvModal.show('custom-field-modal')
      },
      locationChanged(location) {
        this.customField.by_option = location === 'options'
        this.customField.pinned = location === 'top'

        if (this.customField.by_option) {
          this.customField.preview = false
          this.customField.set_content_area = false
          this.customField.set_difficulty = false
        }

        if (
          this.customField.type === 'text' ||
          this.customField.type === 'number'
        ) {
          this.customField.extendable = false
        }
      },
      typeChanged(type) {
        if (type !== 'number') {
          this.customField.set_difficulty = false
        }
        if (type !== 'select_one') {
          this.customField.set_content_area = false
        }
      },
      numOtherContentAreaFields(fields, fieldId) {
        let count = 0
        for (const fId in fields) {
          if (fId != fieldId) {
            const field = fields[fId]
            if (field.set_content_area) {
              count++
            }
          }
        }
        return count
      },
      async saveCustomField() {
        if (this.isFreshField()) {
          const fieldId = uuidv4()
          this.customField.id = fieldId
          this.metaFields.custom_field_order[this.selectedLocation].push(
            fieldId
          )
          this.metaFields.custom[fieldId] = this.customField
        } else {
          this.metaFields.custom[this.customField.id] = this.customField

          if (this.customModalData.key !== this.selectedLocation) {
            const oldGroup = this.metaFields.custom_field_order[
              this.customModalData.key
            ]

            const newGroup = this.metaFields.custom_field_order[
              this.selectedLocation
            ]

            const index = oldGroup.findIndex(
              fieldId => fieldId === this.customField.id
            )

            oldGroup.splice(index, 1)
            newGroup.push(this.customField.id)
          }
        }
        submitFormAfterModalClose()
      },
      isFreshField() {
        return !this.customField.id
      },
      isSelectField() {
        return this.customField.type.startsWith('select')
      },
      addToOptions(optionOrDivider) {
        if (!this.customField.hasOwnProperty('options')) { // eslint-disable-line no-prototype-builtins
          this.$set(this.customField, 'options', {})
          this.$set(this.customField, 'option_order', [])
        }
        this.$set(this.customField.options, optionOrDivider.id, optionOrDivider)
        this.customField.option_order.push(optionOrDivider.id)
      },
      addOption() {
        const newOption = deepCopy(EMPTY_OPTION)
        newOption.id = uuidv4()
        this.newOptionIds.push(newOption.id)
        newOption.color = null
        this.addToOptions(newOption)
        this.$nextTick(() => this.scrollToBottom())
      },
      addDivider() {
        const newDivider = deepCopy(EMPTY_DIVIDER)
        newDivider.id = uuidv4()
        this.addToOptions(newDivider)
        this.$nextTick(() => this.scrollToBottom())
      },
      scrollToBottom() {
        setTimeout(() => {
          const element = document.getElementById('optionlist')
          element.scrollTop = element.scrollHeight
        }, 10)
      },
      deleteOption(index) {
        const optionId = this.customField.option_order[index]
        const option = this.customField.options[optionId]
        const isNew = this.newOptionIds.includes(option.id)
        if (this.isSelectField() && !option.is_divider && !isNew) {
          const typeOptions = deepCopy(this.customField.options)
          delete typeOptions[optionId]
          const typeOptionsList = [{text: 'Please choose one...', value: ''}, {text: 'No Field', value: 'no_field'}]
          for (const [key, option] of Object.entries(typeOptions)) {
            if (!option.is_divider && !isNew) {
              typeOptionsList.push(
                {
                  text: option.text,
                  value: key
                }
              )
            }
          }
          this.deleteOptionTypeModalData.index = index
          this.deleteOptionTypeModalData.options = typeOptionsList
          this.deleteOptionTypeModalData.replacementOptionKey = ''

          this.$bvModal.show('delete-custom-field-type-modal')
          return
        }
        this.customField.option_order.splice(index, 1)
        delete this.customField.options[optionId]
      },
      markOptionForDelete() {
        const index = this.deleteOptionTypeModalData.index
        const optionId = this.customField.option_order[index]
        const fieldId = this.customField.id
        const replaceOptKey = this.deleteOptionTypeModalData.replacementOptionKey

        if (replaceOptKey !== 'no_field') {
          if (!this.customField['replaced_options'][fieldId]) {
            this.customField['replaced_options'][fieldId] = {}
          }

          this.customField['replaced_options'][fieldId][optionId] = replaceOptKey
        }

        this.customField.option_order.splice(index, 1)
        delete this.customField.options[optionId]
      },
      shouldDisableDelete(index) {
        const optionId = this.customField.option_order[index]
        const fieldId = this.customField.id
        const replacedOptions = this.customField['replaced_options'][fieldId]
        if (replacedOptions && Object.values(replacedOptions).includes(optionId)) {
          return true
        }

        return false
      },
      onHideCustomFieldModal() {
        this.newOptionIds = []
      },
      toggleOptionLock(optionId) {
        this.$set(
          this.customField.options[optionId],
          'lock',
          !this.customField.options[optionId].lock
        )
      },
      toggleColorPicker(optionId, fromInput) {
        if (this.editingOptionId === optionId && !fromInput) {
          return this.closeColorPicker()
        }

        if (!this.colorPickerId) {
          document.addEventListener('click', this.clickedOutside)
        }

        this.colorPickerId = 'picker' + optionId

        this.editingOptionId = optionId
      },
      clickedOutside(event) {
        const target = event.target
        const popup = document.getElementById('picker')
        const field = document.getElementById(`picker${this.editingOptionId}`)

        const notPicker = popup !== target && field !== target
        const notInPicker = !popup.contains(target) && !field.contains(target)

        if (notPicker && notInPicker) {
          this.closeColorPicker()
        }
      },
      closeColorPicker() {
        this.editingOptionId = ''
        this.colorPickerId = null
        document.removeEventListener('click', this.clickedOutside)
      },
      updateColor(color) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.customField.options[this.editingOptionId].color = color.hex
        }, 0)
      },
      colorFromInput(value, optionId) {
        const colorIsValid = isValidHexColor(value)

        if (colorIsValid) {
          this.customField.options[optionId].color = value ? value : null
        }
      },
      lockVariant(optionId) {
        return this.customField.options[optionId].lock ? 'warning' : 'white'
      },
      lockIcon(optionId) {
        return this.customField.options[optionId].lock ? 'lock' : 'lock-open'
      },
      typeName(type) {
        return TYPE_NAMES[type]
      },
      inPreviewText(inPreview) {
        return inPreview ? 'In Preview' : ''
      },
      confirmDeleteCustomField(index, key, name) {
        this.customModalData = {
          index,
          key,
          name
        }
        this.$bvModal.show('delete-custom-field-modal')
      },
      deleteCustomField() {
        const { index, key } = this.customModalData
        const fieldId = this.metaFields.custom_field_order[key][index]
        this.metaFields.custom_field_order[key].splice(index, 1)
        delete this.metaFields.custom[fieldId]
        submitFormAfterModalClose()
      },
      addExamineeSchema() {
        this.settingsFields.examinee_schema.push({
          key: '',
          type: 'text',
          preview: false,
          required: false
        })
      },
      deleteExamineeSchema(index) {
        this.settingsFields.examinee_schema.splice(index, 1)
      },
      moveArrayElement(array, index, by) {
        const newIndex = index + by
        const element = array.splice(index, 1)[0]
        array.splice(newIndex, 0, element)
      },
      upgrade() {
        const orgId = this.project.organization_id

        if (!orgId) {
          return scrollTo(0, 0)
        }

        this.$router.push({
          name: 'orgBilling',
          params: { orgId }
        })
      },
      groupName(key) {
        return `Item Page: ${key}`
      },
      setTabIndex() {
        const index = TAB_NAMES.indexOf(this.$route.hash)
        if (index > -1) {
          this.tabIndex = index
        }
      },
      changeTab(tabIndex, section) {
        this.tabIndex = tabIndex
        this.section = section
        scrollTo(0, 0)
      },
      sectionIsVisible(section) {
        return this.section === section
      },
      setCurrentSection(section) {
        this.section = section
      },
      toggleCurrentSection(section) {
        if (this.section === section) this.setCurrentSection()
        else this.setCurrentSection(section)
      },
      async onDeliveryCreationDisabledChange(value) {
        this.rootFields.delivery_creation_disabled = value

        const confirmed = await this.$bvModal.msgBoxConfirm(
          `Are you sure you want to ${ value ? 'disable' : 'enable' } delivery creation for this exam?`,
          {
            title: 'Confirm',
            okVariant: 'primary-light',
            cancelVariant: 'white',
            size: 'sm'
          }
        )

        if (!confirmed) {
          this.$nextTick(() => {
            this.rootFields.delivery_creation_disabled = !value
          })
        }
      },
      onColorChanged(color, property) {
        this.settingsFields[property] = color
      },
      setDefaultColors() {
        const { navigation_color, button_color, background_color } = DEFAULT_EXAM_COLORS
        this.settingsFields.navigation_color = navigation_color
        this.settingsFields.button_color = button_color
        this.settingsFields.background_color = background_color
      },
      autoNamingChanged({ naming_scheme_advanced, naming_scheme_compiled, naming_scheme_segments }) {
        this.settingsFields.naming_scheme_advanced = naming_scheme_advanced
        this.settingsFields.naming_scheme_compiled = naming_scheme_compiled
        this.settingsFields.naming_scheme_segments = naming_scheme_segments
      },
      clearDateTime() {
        this.editingRole = {
          ...this.editingRole,
          _expDate: null,
          _expTime: null
        }
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      async FetchLanguages() {
        const response = await Language.FetchAll(this.project.id)

        if (response.error) {
          console.error('it broke')
          return
        }

        this.languages = [...response.results]
      }
    },
    watch: {
      tabIndex: function(index) {
        const tabName = TAB_NAMES[index]
        window.history.replaceState(null, null, tabName)
      }
    },
    computed: {
      colorPickerValue() {
        return this.customField.options[this.editingOptionId].color || ''
      },
      hasOrg() {
        return Boolean(this.project.organization_id)
      },
      locationDisabled() {
        return (
          (!this.isFreshField() && this.selectedLocation === 'options') ||
          !this.isPremium
        )
      },
      isAdmin() {
        return SESSION.isAdmin()
      },
      orgHasDeliveriesDisabled() {
        return get(this.project, 'organization.delivery_creation_disabled', false)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .preview-box {
    border: 1px solid #ccc; padding: 8px 8px; border-radius: 12px;
    display:flex;
  }
  .previewcolumn1 {
    flex:2;
  }
  .previewcolumn2 {
    flex: 1;
    display:flex;
  }
  .previewcolumn2inner {
    flex:auto;
    background: #00000080;
    display:flex;
    flex-direction:column;
    gap: 4px;
    padding: 5px;
  }
  
  .widget {
    color: #fff;
    padding: 6px;
  }
  .widgetexpanded {
    background: #fff;
    padding: 10px;
    height: 100%;
  }
  .top-button {
    padding: 4px 8px;
    margin-right: 4px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.281);
  }
  .topbuttons {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
  .topbuttonwhite {
    flex: 1; background: #ffffff; padding: 8px auto; color: black; text-align:center;
  }
  .topbutton {
    flex: 1; padding: 8px auto; color: white; text-align:center;
  }
  .examtimer {
    color: #fff; padding: 8px; border: 1px solid #ffffff50; border-radius: 6px;
  }
  .navigation {
    padding: 4px; overflow:auto; border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .content-area {
    padding: 8px;
  }
  .item-box {
    background: white; margin: 25px; color: #fff;padding: 18px; color: black; border-radius: 6px;
  }
  .bottom-buttons {
    margin: 25px; color: #fff; overflow: auto;
  }
  .button {
    padding: 8px 16px; color: white; width: 100px; text-align:center; margin-right: 1px; line-height: .95em; float: left;
  }
  .right {
    float:right;
  }
  #picker {
    position: absolute;
    z-index: 9999999;
    right: 8px;
    bottom: 44px;
    .swatch {
      max-width: 50px;
      height: 50px;
      display: inline-block;
    }
  }
</style>
