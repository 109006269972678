<template>
  <div v-if="promises.length" class="mb-3">
    <b-progress
      :max="total"
      :value="completed"
      show-progress
      animated
      variant="warning"
    ></b-progress>
  </div>
</template>

<script>
  export default {
    name: 'ProgressBar',
    props: {
      promises: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        total: 0,
        completed: 0
      }
    },
    watch: {
      promises: function(promiseArr) {
        this.total = promiseArr.length
        this.completed = 0
        for (const promise of promiseArr) {
          promise.then(() => {
            this.completed += 1
          })
        }
      }
    },
    computed: {}
  }
</script>
