<template>
    <div v-if="!loading" id="flows">
        <b-navbar variant="light" sticky>
            <b-button :disabled="shouldDisable('edit_exam_settings')" @click="addFlow" variant="secondary" class="mr-2">
                Add Flow
            </b-button>

            <SurveysModal />

            <AgreementsModal />
        </b-navbar>

        <b-container class="mt-3" fluid>
            <h3>
                Flows: {{ flows.length }}
            </h3>

            <b-table
                v-if="flows.length"
                :items="flows"
                :fields="fields"
                bordered
                hover
                responsive
                fixed
                striped
            >
                <template #cell(tags)="row">
                    <b-link :to="rowLink(row.item)" class="stretched-link" />

                    <div v-if="row.item.is_default">
                        <b-badge variant="info">
                            Default
                        </b-badge>
                    </div>

                    <div v-else>
                        {{ row.item.tags }}
                    </div>
                </template>
            </b-table>
        </b-container>
    </div>

    <Spinner v-else />
</template>

<script>
import Spinner from '../Spinner.vue'
import SurveysModal from './SurveysModal.vue'
import AgreementsModal from './AgreementsModal.vue'

import { EVENT } from '../../utils/event-bus.js'
import { HTTP } from '../../utils/requests.js'
import { SEI_API_BASE } from '../../utils/constants.js'
import { SESSION } from '../../utils/session.js'

async function getFlowsRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/flows`

        const response = await HTTP.get(url)

        return { data: response.data.results }
    } catch (error) {
        return { error }
    }
}

function formatFlows (flows) {
    for (const flow of flows) {
        flow.tags = flow.tags.join(', ')
    }
}

const FIELDS = [
    { key: 'tags', label: 'Tags' }
]

export default {
    name: 'Flow',
    components: {
        Spinner,
        SurveysModal,
        AgreementsModal
    },
    data () {
        return {
            loading: true,
            flows: [],
            fields: FIELDS
        }
    },
    created () {
        this.getFlows()
    },
    methods: {
        addFlow () {
            const route = {
                name: 'projectFlow',
                params: {
                    flowId: 'new'
                }
            }

            this.$router.push(route)
        },
        async getFlows () {
            this.loading = true

            const { data, error } = await getFlowsRequest()

            this.loading = false

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to load flows.'
                }

                return EVENT.alert(alertData)
            }

            if (data.length === 1) {
                const [ flow ] = data

                const route = {
                    name: 'projectFlow',
                    params: {
                        flowId: flow.id
                    }
                }

                return this.$router.replace(route)
            }

            formatFlows(data)

            this.flows = data
        },
        rowLink (flow) {
            return {
                name: 'projectFlow',
                params: { flowId: flow.id }
            }
        },
        shouldDisable(neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        },
    }
}
</script>

<style lang="scss">
#flows tr td {
    transform: rotate(0);
}
</style>
