<script>
  import { splitLabel } from '../../utils/misc'
  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    name: 'AnalyticsBarChart',
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object
      },
      height: {
        type: Number,
        default: 200
      }
    },
    mounted() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                callback(label) {
                  return splitLabel(label)
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title() {
              return ''
            },
            label(tooltip, data) {
              return data.labels[tooltip.index]
            }
          }
        }
      })
    }
  }
</script>
