<template>
    <div>
        <b-button @click="getAgreements" :disabled="loading || shouldDisable('view_agreements')" variant="white">
            <b-spinner v-if="loading" small />

            Agreements
        </b-button>

        <b-modal @hidden="onModalHidden" id="agreements-modal" size="xl" title="Agreements" no-fade>
            <b-card v-for="(agreement, index) in editableAgreements" :key="agreement.id" class="mb-2" no-body>
                <b-card-header class="p-1">
                    <b-button :disabled="shouldDisable('edit_agreements')" @click="setActiveAgreement(agreement.id)" class="text-left button-header" variant="primary" size="lg" block>
                        {{ agreement.name }}
                    </b-button>
                </b-card-header>

                <b-card-body v-if="showAgreement(agreement.id)">
                    <b-form-group label="Name" label-cols-lg="3" label-cols-sm="4">
                        <b-form-input v-model="agreement.name" />
                    </b-form-group>

                    <b-form-group label="Content" label-cols-lg="3" label-cols-sm="4">
                        <b-form-textarea v-model="agreement.content" max-rows="99" no-resize />
                    </b-form-group>

                    <div class="d-flex justify-content-end mt-2">
                        <b-button :disabled="shouldDisable('edit_agreements')" @click="deleteAgreement(index)" variant="link">
                            Delete this agreement
                        </b-button>
                    </div>
                </b-card-body>
            </b-card>

            <template #modal-footer="{ cancel }">
                <div class="w-100 d-flex justify-content-between">
                    <b-button :disabled="shouldDisable('edit_agreements')" @click="addAgreement" variant="primary-light">
                        Add Agreement
                    </b-button>

                    <div>
                        <b-button @click="cancel" variant="white" class="mr-2">
                            Cancel
                        </b-button>

                        <b-button @click="beforeSave" :disabled="shouldDisable('edit_agreements') || saving" variant="secondary">
                            <b-spinner v-if="saving" small />

                            Save
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { deepCopy } from '../../utils/misc.js'
import { EVENT } from '../../utils/event-bus.js'
import { HTTP } from '../../utils/requests.js'
import { SEI_API_BASE } from '../../utils/constants.js'
import { SESSION } from '../../utils/session.js'

import { v4 as uuidv4 } from 'uuid'

async function getAgreementsRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/agreements`

        const response = await HTTP.get(url)

        return { data: response.data.results }
    } catch (error) {
        return { error }
    }
}

async function saveNewAgreementRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/agreements`

        const response = await HTTP.post(url, payload)

        return { data: { ...response.data, originalId: payload.id } }
    } catch (error) {
        return { error }
    }
}

async function saveAgreementRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/agreements/${ payload.id }`

        const response = await HTTP.put(url, payload)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function deleteAgreementRequest (agreementId) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/agreements/${ agreementId }`

        await HTTP.delete(url)

        return { data: { deleted: agreementId } }
    } catch (error) {
        return { error }
    }
}

function checkForChanges (before, after) {
    let changed = false

    for (const property of ['name', 'content']) {
        if (before[property] !== after[property]) changed = true
    }

    return changed
}

export default {
    name: 'Agreements',
    data () {
        return {
            editableAgreements: [],
            loading: false,
            saving: false,
            loaded: false,
            active: null
        }
    },
    created () {
        EVENT.$on('get-agreements', (stepId) => this.getAgreements(false, stepId))

        EVENT.$on('view-agreement', this.viewAgreement)
    },
    beforeDestroy () {
        EVENT.$off('get-agreements')

        EVENT.$off('view-agreement')
    },
    methods: {
        async getAgreements (showModal, stepId) {
            if (!this.loaded) {
                this.$emit('loading-agreements')

                this.loading = true

                const { data, error } = await getAgreementsRequest()

                this.loading = false

                if (error) {
                    const alertData = {
                        variant: 'danger',
                        message: 'Failed to load agreements.'
                    }

                    this.emitAgreements(true)

                    return EVENT.alert(alertData)
                }

                this.agreements = data

                this.loaded = true

                this.emitAgreements(false, stepId)
            }

            this.editableAgreements = deepCopy(this.agreements)

            if (showModal) {
                this.$bvModal.show('agreements-modal')
            }
        },
        beforeSave () {
            const newAgreements = []

            const updatedAgreements = []

            const deletedAgreements = []

            for (const agreement of this.editableAgreements) {
                if (agreement.isNew) newAgreements.push(agreement)
            }

            for (const agreement of this.agreements) {
                const index = this.editableAgreements.findIndex(a => a.id === agreement.id)

                if (index === -1) {
                    deletedAgreements.push(agreement.id)

                    continue
                }

                const editableAgreement = this.editableAgreements[index]

                const agreementChanged = checkForChanges(agreement, editableAgreement)

                if (agreementChanged) updatedAgreements.push(editableAgreement)
            }

            const requests = []

            for (const agreement of newAgreements) {
                const request = saveNewAgreementRequest(agreement)

                requests.push(request)
            }

            for (const agreement of updatedAgreements) {
                const request = saveAgreementRequest(agreement)

                requests.push(request)
            }

            for (const agreementId of deletedAgreements) {
                const request = deleteAgreementRequest(agreementId)

                requests.push(request)
            }

            this.saveAgreements(requests)
        },
        async saveAgreements (requests) {
            this.saving = true

            const responses = await Promise.all(requests)

            this.saving = false

            let error = false

            for (const response of responses) {
                if (response.error) {
                    error = true

                    continue
                }

                if (response.data.deleted) {
                    const index = this.agreements.findIndex(agreement => agreement.id === response.data.deleted)

                    this.agreements.splice(index, 1)

                    continue
                }

                if (response.data.originalId) {
                    const index = this.editableAgreements.findIndex(agreement => agreement.id === response.data.originalId)

                    if (this.active === response.data.originalId) this.active = response.data.id

                    delete response.data.originalId

                    this.editableAgreements.splice(index, 1, response.data)

                    const copy = deepCopy(response.data)

                    this.agreements.push(copy)

                    continue
                }

                const index = this.agreements.findIndex(agreement => agreement.id === response.data.id)

                this.agreements.splice(index, 1, response.data)
            }

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to save changes to agreements.'
                }

                EVENT.alert(alertData)
            }

            this.emitAgreements()
        },
        addAgreement () {
            const agreement = {
                name: '',
                content: '',
                id: uuidv4(),
                isNew: true
            }

            this.editableAgreements.push(agreement)

            this.active = agreement.id
        },
        deleteAgreement (index) {
            this.editableAgreements.splice(index, 1)
        },
        viewAgreement (agreementId) {
            this.active = agreementId

            this.$bvModal.show('agreements-modal')
        },
        setActiveAgreement (agreementId) {
            if (this.active === agreementId) {
                return this.active = null
            }

            this.active = agreementId
        },
        showAgreement (agreementId) {
            return this.active === agreementId
        },
        onModalHidden () {
            this.active = null
        },
        emitAgreements (error, stepId) {
            this.$emit('agreements', this.agreements, error, stepId)
        },
        shouldDisable(neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        }
    }
}
</script>

<style lang="scss" scoped>
.button-header {
    min-height: 48px;
}
</style>
