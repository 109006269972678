export const initializeStripe = publicKey => {
  const stripe = Stripe(publicKey) // eslint-disable-line no-undef

  return {
    mountElements() {
      const elements = stripe.elements()
      const style = {
        base: {
          fontSize: '16px',
          color: '#32325d',
          fontFamily:
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: 'rgba(0,0,0,0.4)'
          }
        }
      }
      const card = elements.create('card', { style: style })

      card.mount('#card-element')

      return card
    },
    async retrieveSetupIntent(clientSecret) {
      const result = await stripe.retrieveSetupIntent(clientSecret)

      return result.setupIntent
    },
    async confirmCardSetup(clientSecret, card, billingDetails) {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: card,
          billing_details: billingDetails
        }
      })

      return result
    },
    async confirmPayment(clientSecret, paymentMethodId) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId
      })

      return result
    }
  }
}
