<template>
  <div>
    <TopNavigation />
    <b-navbar sticky variant="light">
      <b-button-group v-if="!search">
        <b-button @click="showCreateAppModal" variant="primary-light"
          >New App</b-button
        >
        <b-button v-b-toggle.sidebar-search variant="white">Search</b-button>
      </b-button-group>
      <div class="w-100 d-flex" v-else>
        <b-form-input
          autofocus
          placeholder="Search Apps"
          style="maxWidth: 600px"
          v-model="searchText"
        />
        <b-button
          @click="hideSearch"
          class="ml-2"
          key="search-cancel-btn"
          variant="white"
          >Cancel</b-button
        >
      </div>
    </b-navbar>
    <b-sidebar
      backdrop
      backdrop-variant="black"
      bg-variant="light"
      id="sidebar-search"
      right
      shadow
      sidebar-class="border-left border-medium"
      title="Search Apps"
      width="75%"
      lazy
    >
      <b-container fluid>
        <b-card no-body>
          <b-card-body class="pt-2 pb-3">
            <label>Search keywords</label>
            <b-input-group>
              <b-form-input
                @keyup.enter="searchApps()"
                autofocus
                placeholder="Search Apps"
                ref="search"
                :value="searchText"
              />
              <b-input-group-append>
                <b-button
                  @click="searchApps()"
                  size="sm"
                  variant="primary-light"
                  >Search</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-card-body>
        </b-card>
        <b-button @click="clearSearch" class="mt-2" size="sm" variant="link"
          >Clear search</b-button
        >
      </b-container>
    </b-sidebar>
    <b-container class="mt-3" fluid v-if="apps">
      <div class="d-flex justify-content-between mb-2">
        <h3>Apps: {{ appCount }}</h3>
        <div>
          <b-dropdown right text="Sort By" variant="white" size="sm">
            <b-dropdown-item @click="changeSort('first_name')">
              Name
              <font-awesome-icon
                icon="check"
                v-if="sortBy === 'first_name'"
              ></font-awesome-icon>
            </b-dropdown-item>
            <b-dropdown-item @click="changeSort('app_description')">
              Description
              <font-awesome-icon
                icon="check"
                v-if="sortBy === 'app_description'"
              ></font-awesome-icon>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown right class="ml-1" text="Order" variant="white" size="sm">
            <b-dropdown-item @click="changeOrder('asc')">
              Ascending
              <font-awesome-icon
                icon="check"
                v-if="sortOrder === 'asc'"
              ></font-awesome-icon>
            </b-dropdown-item>
            <b-dropdown-item @click="changeOrder('desc')">
              Descending
              <font-awesome-icon
                icon="check"
                v-if="sortOrder === 'desc'"
              ></font-awesome-icon>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- Apps List -->
      <div v-if="!error">
        <b-table
          :fields="fields"
          :items="filteredApps()"
          @row-clicked="rowClicked"
          bordered
          hover
          id="apps"
          responsive
          small
          striped
          v-if="apps.length"
        >
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: field.key === 'starred' ? '40px' : 'auto' }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:cell(starred)="data">
            <div class="text-center" data-star style="pointerEvents: none">
              <div style="pointerEvents: none;">
                <font-awesome-icon
                  class="text-secondary"
                  icon="star"
                  v-if="data.item.starred"
                ></font-awesome-icon>
                <font-awesome-icon
                  :icon="['far', 'star']"
                  v-else
                ></font-awesome-icon>
              </div>
            </div>
          </template>
        </b-table>
        <div class="p-2" v-else>No Apps.</div>
      </div>
      <b-alert
        class="d-flex justify-content-between align-items-center"
        show
        v-else
        variant="danger"
      >
        Failed to load apps.
        <b-button @click="reload" variant="danger">Reload</b-button>
      </b-alert>
      <!-- Create App Modal -->
      <b-modal
        @hidden="hideCreateAppModal"
        id="create-app-modal"
        size="xl"
        title="Create New App"
      >
        <b-form @submit.prevent="onSubmit" id="create-app-form" novalidate>
          <b-form-group label="Name *" label-for="name-input">
            <b-form-input
              autocomplete="off"
              id="name-input"
              required
              type="text"
              v-model="appForm.first_name"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button-group>
            <b-button @click="cancel" variant="white">Cancel</b-button>
            <b-button
              :disabled="disableCreateAppBtn"
              form="create-app-form"
              type="submit"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isCreateApp"
              ></b-spinner
              >&nbsp;Create
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
    </b-container>
    <!-- Loading Spinner -->
    <Spinner v-else />
  </div>
</template>

<script>
  import { HTTP } from '../utils/requests'
  import { SEI_API_BASE } from '../utils/constants'
  import { LOCAL } from '../utils/local'
  import { EVENT } from '../utils/event-bus'
  import { VALIDATE } from '../utils/validate'

  import get from 'lodash.get'

  import TopNavigation from './TopNavigation'
  import Spinner from './Spinner'

  function sortApps(apps, property, direction) {
    const starred = []
    const unstarred = []

    const sortedApps = apps.sort((current, next) => {
      const currentValue = get(current, property, '')
      const nextValue = get(next, property, '')

      if (direction === 'asc') {
        return currentValue.localeCompare(nextValue)
      }

      return nextValue.localeCompare(currentValue)
    })

    for (const app of sortedApps) {
      if (app.starred) {
        starred.push(app)
      } else {
        unstarred.push(app)
      }
    }

    return [...starred, ...unstarred]
  }

  export default {
    name: 'AppsPage',
    components: {
      TopNavigation,
      Spinner
    },
    data() {
      return {
        error: false,
        loading: true,
        apps: null,
        isCreateApp: false,
        fields: [
          { key: 'starred', label: '' },
          { key: 'first_name', label: 'Name' },
          { key: 'app_description', label: 'Description' }
        ],
        appForm: {
          first_name: ''
        },
        search: false,
        searchText: '',
        appCount: 0,
        sortBy: 'first_name',
        sortOrder: 'asc'
      }
    },
    methods: {
      changeSort(sort) {
        this.sortBy = sort
        this.apps = sortApps(this.apps, this.sortBy, this.sortOrder)
      },
      changeOrder(order) {
        this.sortOrder = order
        this.apps = sortApps(this.apps, this.sortBy, this.sortOrder)
      },
      filteredApps() {
        const filtered = this.apps.filter(app => {
          return app.first_name
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        })
        this.appCount = filtered.length
        return filtered
      },
      rowClicked(record) {
        const star = event.target.querySelector('[data-star]')

        if (star) {
          const app = this.apps.find(app => app.caveon_id === record.caveon_id)
          this.setStars(app)
          return this.apps = sortApps(this.apps, this.sortBy, this.sortOrder)
        }

        this.$router.push({
          name: 'appSettings',
          params: { appId: record.caveon_id }
        })
      },
      searchApps() {
        this.searchText = this.$refs.search.localValue
      },
      clearSearch() {
        this.$refs.search.localValue = ''
        this.searchApps()
      },
      showSearch() {
        this.search = true
      },
      hideSearch() {
        this.search = false
        this.searchText = ''
      },
      showCreateAppModal() {
        this.$bvModal.show('create-app-modal')
      },
      hideCreateAppModal() {
        this.$bvModal.hide('create-app-modal')
        this.appForm = {
          first_name: ''
        }
      },
      async getApps() {
        try {
          const url = `${SEI_API_BASE}/apps`
          const response = await HTTP.get(url)
          this.getStars(response.data.results)
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.createNewApp()
        }
      },
      async createNewApp() {
        try {
          this.isCreateApp = true
          const url = `${SEI_API_BASE}/apps`
          const response = await HTTP.post(url, this.appForm)
          const app = response.data
          app.starred = false
          const apps = [...this.apps, app]
          this.apps = sortApps(apps, this.sortBy, this.sortOrder)
          this.hideCreateAppModal()
          this.$router.push({
            name: 'appSettings',
            params: { appId: app.caveon_id }
          })
        } catch (error) {
          if (error?.response?.data?.messages) {
            const msg = error.response.data.messages.join(', ')
            EVENT.alert({
              variant: 'danger',
              message: msg
            })
          } else {
            EVENT.alert({
              variant: 'danger',
              message: 'Failed to create app! Please try again.'
            })
          }
        } finally {
          this.isCreateApp = false
        }
      },
      getStars(apps) {
        const starred = LOCAL.star.get('apps')
        for (const app of apps) {
          app.starred = starred.includes(app.caveon_id)
        }
        this.apps = sortApps(apps, this.sortBy, this.sortOrder)
        this.appCount = apps.length
      },
      setStars(app) {
        const starred = LOCAL.star.get('apps')
        app.starred = !app.starred
        if (app.starred) {
          starred.push(app.caveon_id)
        } else {
          starred.splice(starred.indexOf(app.caveon_id), 1)
        }
        LOCAL.star.set('apps', starred)
      },
      reload() {
        this.loading = true
        this.getApps()
      }
    },
    async created() {
      this.getApps()
    },
    computed: {
      sortedApps() {
        return [...this.apps].sort(
          (current, next) => next.starred - current.starred
        )
      },
      disableCreateAppBtn() {
        return this.isCreateApp
      }
    }
  }
</script>

<style lang="scss" scoped></style>
