<template>
  <b-button-group>
    <b-button
      :disabled="index === 0"
      @click="moveOption(index, -1)"
      size="sm"
      variant="white"
    >
      <font-awesome-icon icon="arrow-up"></font-awesome-icon>
    </b-button>
    <b-button
      :disabled="index === options.length - 1"
      @click="moveOption(index, 1)"
      size="sm"
      variant="white"
    >
      <font-awesome-icon icon="arrow-down"></font-awesome-icon>
    </b-button>
    <b-button @click="removeOption(index)" size="sm" variant="white">
      <font-awesome-icon icon="trash"></font-awesome-icon>
    </b-button>
  </b-button-group>
</template>

<script>
  export default {
    name: 'ItemOptionsReorder',
    props: {
      options: {
        type: Array
      },
      optionsKey: {
        type: Array,
        default: () => []
      },
      optionsWeight: {
        type: Array,
        default: () => []
      },
      index: {
        type: Number
      }
    },
    methods: {
      moveOption(index, by) {
        const newIndex = index + by

        const [optionValue] = this.options.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        const [keyValue] = this.optionsKey.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        const [weightValue] = this.optionsWeight.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.options.splice(newIndex, 0, optionValue) // eslint-disable-line vue/no-mutating-props
        this.optionsKey.splice(newIndex, 0, keyValue) // eslint-disable-line vue/no-mutating-props
        this.optionsWeight.splice(newIndex, 0, weightValue) // eslint-disable-line vue/no-mutating-props
        this.$emit('options-reordered', index, newIndex)
      },
      removeOption(index) {
        this.options.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.optionsKey.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.optionsWeight.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.$emit('option-deleted', index)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
