<template>
  <b-container class="mt-3" fluid role="tablist">
    <b-form>
      <div :key="hook" v-for="hook in activeHooks">
        <HookInput
          :hooks="fields"
          :invalidInputs="invalidInputs"
          :name="hook"
          @remove-hook="removeHook"
          @validate-input="validateInput"
        />
      </div>
      <b-button class="mt-2" variant="white" @click="openModal"
        >Add a Hook</b-button
      >
      <b-modal
        size="xl"
        title="Add a Hook"
        id="add-hook-modal"
        @ok="addSelectedHook"
      >
        <div>
          <label>Event</label>
          <b-select :options="validOptions" v-model="selected"></b-select>
        </div>
        <div class="mt-2">
          <label>URL</label>
          <b-input placeholder="Enter URL..." v-model="url"></b-input>
        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button @click="ok" :disabled="!selected">Add Hook</b-button>
        </template>
      </b-modal>
    </b-form>
  </b-container>
</template>

<script>
import { deepCopy } from '../../utils/misc.js'
import { URL_REGEX } from '../../utils/constants'

import HookInput from './HookInput'

export default {
  name: 'Hooks',
  components: {
    HookInput
  },
  created() {
    this.setFields()
    this.setActiveHooks()
    this.formatIncomingData()
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: {},
      invalidInputs: [],
      selected: '',
      url: '',
      activeHooks: [],
      options: [
        { value: '', text: 'Select', disabled: true },
        {
          label: 'Delivery',
          options: [
            { value: 'delivery_completed', text: 'Completed' },
            { value: 'delivery_created', text: 'Created' },
            { value: 'delivery_rescored', text: 'Rescored' },
            { value: 'delivery_started', text: 'Started' },
            { value: 'delivery_submitted', text: 'Submitted' },
            { value: 'delivery_suspended', text: 'Suspended' },
            { value: 'delivery_connected', text: 'Connected' },
            { value: 'delivery_disconnected', text: 'Disconnected' },
            { value: 'delivery_settings_changed', text: 'Settings Updated' }
          ]
        },
        {
          label: 'Exam',
          options: [
            { value: 'exam_settings_updated', text: 'Settings Updated' },
            { value: 'exam_stats_updated', text: 'Stats Updated' }
          ]
        },
        {
          label: 'Exported',
          options: [{ value: 'data_exported', text: 'Data' }]
        },
        {
          label: 'File',
          options: [
            { value: 'file_created', text: 'Created' },
            { value: 'file_deleted', text: 'Deleted' }
          ]
        },
        {
          label: 'Form',
          options: [
            { value: 'form_created', text: 'Created' },
            {
              value: 'form_live_version_changed',
              text: 'Live Version Changed'
            },
            { value: 'form_updated', text: 'Updated' },
            { value: 'form_versioned', text: 'Versioned' }
          ]
        },
        {
          label: 'Item',
          options: [
            { value: 'item_comment_created', text: 'Comment Created' },
            { value: 'item_created', text: 'Created' },
            {
              value: 'item_live_version_changed',
              text: 'Live Version Changed'
            },
            { value: 'item_updated', text: 'Updated' },
            { value: 'item_versioned', text: 'Versioned' }
          ]
        },
        {
          label: 'Role',
          options: [
            { value: 'role_created', text: 'Created' },
            { value: 'role_deleted', text: 'Deleted' }
          ]
        },
        {
          label: 'Shared Content',
          options: [
            { value: 'shared_content_created', text: 'Created' },
            { value: 'shared_content_deleted', text: 'Deleted' },
            { value: 'shared_content_updated', text: 'Updated' }
          ]
        }
      ]
    }
  },
  methods: {
    report() {
      const formattedFields = this.formatOutgoingData()

      const error = Boolean(this.invalidInputs.length)

      this.$emit('report', formattedFields, 'hooks', error)
    },
    setFields() {
      const {
        app_settings: { hooks }
      } = this.app
      this.fields = deepCopy(hooks)
    },
    openModal() {
      this.selected = ''
      this.url = ''
      this.$bvModal.show('add-hook-modal')
    },
    addSelectedHook() {
      this.fields[this.selected] = this.url
      this.activeHooks.push(this.selected)
      this.validateInput(this.selected, this.url)
    },
    removeHook(name) {
      const index = this.activeHooks.indexOf(name)
      this.activeHooks.splice(index, 1)
      delete this.fields[name]

      const invalidIndex = this.invalidInputs.indexOf(name)
      if (invalidIndex > -1) {
        this.invalidInputs.splice(invalidIndex, 1)
      }
    },
    setActiveHooks() {
      this.activeHooks = Object.keys(this.fields)
    },
    formatIncomingData() {
      for (const key of this.activeHooks) {
        this.fields[key] = this.fields[key].replace('https://', '')
      }
    },
    formatOutgoingData() {
      const formattedFields = deepCopy(this.fields)

      for (const key of Object.keys(formattedFields)) {
        formattedFields[key] = 'https://' + formattedFields[key]
      }

      return formattedFields
    },
    validateInput(name, url) {
      const index = this.invalidInputs.indexOf(name)

      if (!URL_REGEX.test(url)) {
        if (index < 0) {
          this.invalidInputs.push(name)
        }
      } else {
        if (index > -1) {
          this.invalidInputs.splice(index, 1)
        }
      }

      this.fields[name] = url.replace('https://', '')
    }
  },
  computed: {
    validOptions() {
      const validOptions = deepCopy(this.options)
      for (let i = 0; i < validOptions.length; i++) {
        // Hide options that are already selected
        // eslint-disable-next-line no-prototype-builtins
        if (validOptions[i].hasOwnProperty('options')) {
          validOptions[i]['options'] = validOptions[i]['options'].filter(
            (option) => {
              return !this.activeHooks.includes(option.value)
            }
          )
          // Hide category if it has no valid options
          if (validOptions[i]['options'].length < 1) {
            delete validOptions[i]
          }
        }
      }
      return validOptions
    }
  }
}
</script>

<style lang="scss" scoped></style>
