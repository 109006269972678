<template>
  <div v-if="!loading">
    <div>
      <b-navbar sticky variant="light" class="pr-0">
            <b-row class="w-100">
              <b-col cols="6">
                <b-button-group class="mr-2">
                  <b-button
                    :disabled="isSavingForm"
                    @click="submitThroughStep()"
                    style="margin-right: 2px;"
                    variant="secondary"
                  >
                    <b-spinner label="Small Spinner" small v-show="isSavingForm"></b-spinner
                    >&nbsp;Save
                  </b-button>
                  <b-dropdown :disabled="isSavingForm" left variant="secondary">
                    <b-dropdown-item @click="submitThroughStep('version')"
                      >Save as new version</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="submitThroughStep('exit')"
                      >Save and exit</b-dropdown-item
                    >
                  </b-dropdown>
                </b-button-group>
                <b-button-group class="mr-1">
                  <!-- TODO: Implement Launch Exam
                  <b-button variant="white">Launch Exam</b-button>
                  TODO: Implement Proctor Controls
                  <b-button variant="white">Launch Proctor Controls</b-button> -->
                  <b-dropdown text="Export" v-if="form.id" variant="white">
                    <b-dropdown-item
                      @click="routeToExport('caveon_delivery_summary')"
                      title="Delivery summary"
                      >Delivery summary</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="routeToExport('caveon_responses')"
                      title="Item responses"
                      >Item responses</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="routeToExport('caveon_scores')"
                      title="Delivery scores"
                      >Delivery scores</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="routeToExport('caveon_scores_for_forms')"
                      title="Form scores"
                      >Form scores</b-dropdown-item
                    >
                  </b-dropdown>
                </b-button-group>
                <b-button
                  v-show="!isClone"
                  :disabled="isSavingForm"
                  @click="cloneForm()"
                  variant="white"
                  >Clone</b-button
                >
              </b-col>
              <b-col cols="6" class="text-right pr-0">
                <b-button-group class="mr-1">
                  <b-button @click="openNewFormModal" :disabled="isSavingForm" variant="secondary">
                    Add New Form
                  </b-button>
                 
                </b-button-group>
                         <!-- Create Form Modal -->
            <b-modal
              id="new-form-modal"
              size="xl"
              title="Add New Form"
            >
              <b-form @submit.prevent="onSubmitNewForm" id="new-form" novalidate>
                <b-form-group>
                  <label for="form-name">Form Name *</label>
                  <b-form-input
                    autofocus
                    id="form-name"
                    required
                    type="text"
                    v-model="newFormName"
                  />
                </b-form-group>

                <ConfigurationSelect
                  :configurations="configurations"
                  :selected-configuration="newFormConfiguration"
                  @configuration-selected="onConfigurationSelected"
                  stack-name
                />

              </b-form>
              <template v-slot:modal-footer="{ cancel }">
                <b-button-group>
                  <b-button @click="cancel" variant="white">Cancel</b-button>
                  <b-button form="new-form" type="submit" variant="secondary">
                    <b-spinner
                      label="Small Spinner"
                      small
                      v-show="isCreatingForm"
                    ></b-spinner
                    >&nbsp;Create
                  </b-button>
                </b-button-group>
              </template>
            </b-modal>

            
              </b-col>
            </b-row>
   
      </b-navbar>

      <b-container class="mt-3" fluid>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          v-if="error"
          variant="danger"
        >
          Failed to load app.
          <b-button @click="reload" variant="danger">Reload</b-button>
        </b-alert>
        <b-form
          @submit.prevent="onSubmit"
          ref="form"
          novalidate
          v-else
        >
          <b-form-group
            label="Form Name *"
            label-cols-sm="3"
            label-for="form-name-input"
          >
            <b-form-input
              id="form-name-input"
              required
              type="text"
              v-model="form.name"
            ></b-form-input>
          </b-form-group>
          <b-row v-if="!isClone">
            <b-col md="3">
              <label for="form-version-input">Version</label>
            </b-col>
            <b-col md="9">
              <b-form-select
                @change="getForm(form.version.version_number)"
                :options="versionOptions"
                id="form-version-input"
                style="width: 120px;"
                v-model="form.version.version_number"
              ></b-form-select>
              <span
                class="live-text ml-1 mr-4"
                v-if="form.live_version_number === form.version.version_number"
                variant="outline-secondary"
                >LIVE</span
              >
              <span v-else>
                <b-button
                  @click="activateVersion"
                  class="ml-1 mr-4"
                  variant="primary-light"
                >
                  <b-spinner small v-show="isActivatingVersion"></b-spinner
                  >&nbsp;MAKE LIVE
                </b-button>
              </span>
              <div class="d-sm-block d-md-inline-block mt-2">
                <!-- TODO: Buttons can link to deliveries listing filtered by form -->
                <b-button class="mr-1" size="sm" variant="white">
                  Version Exposures
                  <b-badge pill variant="info">{{
                    form.version.num_exposures
                  }}</b-badge>
                </b-button>
                <!-- TODO: Link to Analytics filtered by form -->
              </div>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col md="3">
              <label for="form-configuration-input">Configuration</label>
            </b-col>
            <b-col md="9">
              <div v-if="!loadingConfigurations">
                <div v-if="configurationError">
                  Failed to load configurations.
                  <b-button variant="link" @click="getConfigurations">Retry</b-button>
                </div>
                <ConfigurationSelect
                  id="form-configuration-input"
                  :configurations="configurations"
                  :selected-configuration="this.form.version.configuration_id"
                  stack-name
                  @configuration-selected="onConfigurationSelected"
                  :no-label="true"
                  v-else
                />
              </div>
              <b-spinner small v-else></b-spinner>
              <b-row>
                <b-col>
                  <b-button variant="primary-light" size="sm" class="mr-2" @click="openEditConfigurationModal" :disabled="loadingConfigurations || configurationError">Edit This Configuration</b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button variant="primary-light" size="sm" class="mr-2" @click="cloneConfiguration" :disabled="loadingConfigurations || configurationError">Clone</b-button>
                  <b-button variant="primary-light" size="sm" @click="newConfiguration" :disabled="loadingConfigurations || configurationError">Create New</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="mt-4">
            <b-tabs v-model="tabIndex" content-class="mt-3">
              <b-tab title="Content">
                <b-form-group
                  label="Order of sections"
                  label-cols-lg="3"
                  label-cols-sm="4"
                  label-for="form-order-content-input"
                  v-if="isMultiSection"
                >
                  <b-form-select
                    :options="groupOrderOptions"
                    id="form-order-content-input"
                    v-model="form.version.group_order"
                  ></b-form-select>
                </b-form-group>
                <div v-if="!loadingItems">
                  <div
                    :key="section.id"
                    v-for="(section, index) in form.version.groups"
                  >
                    <FormSection
                      :hideArrow="hideArrow(index)"
                      :isExpanded="newestSection === index"
                      :itemMap="items"
                      :itemIdsByMeta="itemIdsByMeta"
                      :section="section"
                      :sectionIndex="index"
                      :form="form"
                      @add-item="addItem"
                      @move-item="moveItem"
                      @move-section="moveSection"
                      @remove-item="removeItem"
                      @set-section-prop="setSectionProp"
                      @toggle-section="toggleSection"
                      @open-modal="onOpenModal"
                    />
                  </div>
                </div>
                <Spinner v-else />
                <b-button
                  @click="addSection"
                  class="mt-3 mb-3"
                  variant="primary-light"
                  >Add Section</b-button
                >
              </b-tab>
              <b-tab title="Activity Log" v-if="isAdmin">
                <ResourceActivityLogs 
                  :resourceId="formId"
                  :projectId="project.id"
                />
              </b-tab>
              <!-- TODO: Unhide this tab once this feature is fully implemented -->
              <b-tab title="Apps & Widgets" v-if="false">
                <p>
                  Select the apps and widgets you want available on the
                  form:
                </p>
                <div
                  :key="app.caveon_id"
                  class="align-items-center mb-3"
                  v-for="app in availableApps"
                >
                  <b-form-checkbox>
                    {{ app.first_name }}
                    <br />
                    <small>{{ app.app_description }}</small>
                  </b-form-checkbox>
                </div>
              </b-tab>

              <b-tab title="Translations" lazy>
                <b-select :options="languageOptions" v-model="selectedLanguageId"></b-select>
                <LanguagesTranslationsForms
                  v-if="selectedLanguageId"
                  :key="selectedLanguageId"
                  :language="selectedLanguage"
                  :project="project"
                  :formId="form.id"
                  :versionNumber="form.version.version_number"
                  :uiOptions="{showFilters: false, showLanguageName: true}"
                />
              </b-tab>
              
            </b-tabs>
          </div>
        </b-form>
      </b-container>
    </div>

    <b-modal
      id="edit-configuration-modal"
      size="xl"
      :title="editConfigurationModalTitle"
      @hide="onConfigEditorClose"
      @hidden="onConfigEditorHidden"
      scrollable
    >
      <ConfigurationEditor
        edit-only
       :configuration="configuration"
       :configurations="configurations" 
       @configuration-selected="onConfigurationSelected"
       @configuration-changed="onConfigurationChanged"
       @save-form="onSaveForm"
       @configuration-deleted="onConfigurationDeleted"
       @configuration-save-state="onConfigurationSaveState"
      />
      <template v-slot:modal-footer="{ cancel }">
        <div class="text-right w-100">
          <b-button
            v-if="!configuration.is_default && !clone && !isNew"
            variant="white"
            class="float-left"
            @click="deleteConfiguration"
            :disabled="configurationSaveState"
          >
            Delete
          </b-button>
          <b-button-group>
            <b-button @click="cancel" variant="white">Cancel</b-button>
            <b-button
              variant="secondary"
              @click="beforeSave"
              :disabled="configurationSaveState"
            >
              <b-spinner v-show="configurationSaveState" label="Small Spinner" small />&nbsp;Save
            </b-button
            >       
          </b-button-group>
        </div>

      </template>
    </b-modal>

    <b-modal
      id="section-settings"
      size="xl"
      title="Edit Section Settings"
      no-fade
      @hide="updateSectionSettings"
    >
      <b-form-group
        label="Name"
        label-for="section-name"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-input
          id="section-name"
          type="text"
          v-model="editing.name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Instructions"
        label-for="section-instructions"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <textarea-autosize
          :min-height="37"
          class="form-control"
          id="section-instructions"
          placeholder="Enter instructions"
          rows="1"
          v-model="editing.instructions"
        />
      </b-form-group>
      <b-form-group
        label="Item order"
        label-for="section-item-order"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-select
          id="section-item-order"
          :options="[{ text: 'In Order', value: 'in_order' }, { text: 'Random', value: 'random' }]"
          v-model="editing.order"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="Number of items presented per page"
        label-for="section-items-per-page"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-input
          id="section-items-per-page"
          type="number"
          min="0"
          placeholder="Leave blank for all"
          v-model.number="editing.items_per_page"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Target difficulty"
        label-for="section-target-difficulty"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-input
          id="section-target-difficulty"
          type="number"
          min="0"
          @input="defaultNumberInputToNull($event, editing, 'target_difficulty')"
          :value="editing.target_difficulty"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Section time limit (seconds)"
        label-for="section-time-limit"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-input
          id="section-time-limit"
          type="number"
          min="0"
          v-model.number="editing.time_limit"
        ></b-form-input>
        <b-form-checkbox
          class="mt-2"
          v-model="editing.pause_exam_timer"
        >Pause the exam timer while examinees are in this section.
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Widgets"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <div class="mt-2">
          <b-checkbox
            v-for="widget in widgets"
            :key="widget.id"
            :checked="groupHasWidgetEnabled(widget.id)"
            @change="enableOrDisableWidget(widget.id)"
          >
            {{ widget.name }}
          </b-checkbox>
        </div>
      </b-form-group>
      <b-form-group
        v-if="editing.showItemsToSelect"
        label="Number of items to select from the pool"
        label-for="section-items-to-select"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-form-input
          id="section-items-to-select"
          min="0"
          placeholder="Leave blank for all"
          type="number"
          @input="defaultNumberInputToNull($event, editing, 'items_to_select')"
          :value="editing.items_to_select"
        ></b-form-input><i>Consider leaving this field blank in favor of item pool selection rules.</i>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Close</b-button>
        </b-button-group>
      </template>
    </b-modal>

    <b-modal
      title="Edit Item Pool"
      size="xl"
      id="item-pool"
      no-fade
    >
      <ItemPool
        :item-map="items"
        :section="editing"
        :section-index="editingSectionIndex"
        :form="form"
        edit
        @set-pool-items="onSetPoolItems"
      />
      <template #modal-footer="{ cancel }">
        <b-button variant="white" @click="cancel">Close</b-button>
      </template>
    </b-modal>

    <b-modal
      title="Edit Selection Rules"
      size="xl"
      id="selection-rules"
      no-fade
    >
      <SelectionRules
        :section="editing"
        :section-index="editingSectionIndex"
        edit
        @selection-rule-update="onSelectionRuleUpdate"
      />
      <template #modal-footer="{ cancel }">
        <b-button variant="white" @click="cancel">Close</b-button>
      </template>
    </b-modal>
  </div>
  <!-- Loading Spinner -->
  <Spinner v-else />
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE, DEFAULT_SECURITY_OPTIONS, DEFAULT_CONFIGURATION } from '../../utils/constants'
  import { SESSION } from '../../utils/session'
  import { VALIDATE } from '../../utils/validate'

  import get from 'lodash.get'
  import { v4 as uuidv4 } from 'uuid'

  import ResourceActivityLogs from './ResourceActivityLogs.vue'
  import ItemPool from './ItemPool'
  import SelectionRules from './SelectionRules'
  import Spinner from '../../components/Spinner'
  import ConfigurationEditor from './ConfigurationEditor'
  import ConfigurationSelect from './ConfigurationSelect'
  import FormSection from './FormSection'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy, removeEmptyKeys, defaultNumberInputToNull, setMissingKeysOnParentConfiguration } from '../../utils/misc.js'
  import { Language } from '../../utils/language'
  import LanguagesTranslationsForms from './LanguagesTranslationsForms.vue'

  const TAB_NAMES = ['#Content', '#ActivityLog', '#Translations', '#Configuration']

  async function postFormData(form, examId) {
    try {
      let url = `${SEI_API_BASE}/exams/${examId}/forms`
      const result = await HTTP.post(url, form)
      return { data: result.data }
    } catch (error) {
      return { error }
    }
  }

  async function getListOfConfigurations(projectId, page) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/configurations?page=${page}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }

  }

  async function getWidgetsRequest(projectId, page) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/widgets?page=${page}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveConfigurationSettings (projectId, configuration) {
    try {
      const url = `${SEI_API_BASE}/exams/${ projectId }/configurations/${ configuration.id }`

      const payload = {
        settings: configuration.settings
      }

      removeEmptyKeys(payload.settings)

      const response = await HTTP.put(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'FormEditor',
    components: {
      ItemPool,
      SelectionRules,
      Spinner,
      ConfigurationEditor,
      FormSection,
      ConfigurationSelect,
      LanguagesTranslationsForms,
      ResourceActivityLogs
    },
    created() {
      this.formId = this.$route.params.formId

      if (SESSION.isAdmin()) {
        this.isAdmin = true
      }

      this.getForm()
      this.getConfigurations()
      this.getWidgets()
      this.setTabIndex()
      this.FetchLanguages()
      SESSION.initSupportedLanguages()
    },
    props: {
      project: {
        type: Object
      }
    },
    data() {
      return {
        configurationSaveState: false,
        loading: true,
        loadingConfigurations: true,
        loadingItems: true,
        isActivatingVersion: false,
        isSavingForm: false,
        configChanged: false,
        formId: null,
        form: {},
        configuration: {},
        configurations: [],
        widgets: [],
        items: {},
        itemIdsByMeta: {},
        editing: {},
        editingSectionIndex: null,
        availableApps: [],
        error: false,
        configurationError: false,
        isLinearMode: false,
        newestSection: 0,
        tabIndex: 0,
        step: null,
        originalFormName: null,
        splitCharOptions: [
          { value: '', text: 'By Selected Value' },
          { value: '|', text: 'By Divider' }
        ],
        groupOrderOptions: [
          { value: 'default', text: 'In Order' },
          { value: 'random', text: 'Random' }
        ],
        isAdmin: false,
        statusOptions: [
          { value: 'draft', text: 'Inactive' },
          { value: 'active', text: 'Active' }
        ],
        languages: [],
        selectedLanguageId: null,
        isCreatingForm: false,
        newFormConfiguration: null,
        newFormName: '',
        clone: false,
        isNew: false,
        defaultNumberInputToNull
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (
        this.isClone && !this.step &&
        !confirm('Leave without saving changes? Your clone will not be saved.')
      ) {
        return
      }

      next()
    },
    computed: {
      editConfigurationModalTitle () {
        if (this.isNew) {
          return 'New Configuration'
        }

        if (this.clone) {
          return 'Clone Configuration'
        }

        return 'Edit Configuration'
      },
      versionOptions() {
        let versionArr = []
        for (let i = 1; i <= this.form.num_versions; i++) {
          const liveVersion = this.form.live_version_number
          const currentVersion = this.form.version.version_number

          if (i === liveVersion && i !== currentVersion) {
            versionArr.push({ text: `${i} (Live)`, value: i })
            continue
          }
          versionArr.push(i)
        }
        return versionArr
      },
      isMultiSection() {
        if (this.form.version.groups.length > 1) {
          return true
        }
        return false
      },
      isClone() {
        return this.$route.params.formId === 'clone'
      },
      formNames() {
        return this.project.forms.map(form => {
          return form.name
        })
      },
      languageOptions() {
        return [{text: 'Select language', value: null}].concat(this.languages.map(language => {
          return {
            text: language.name,
            value: language.id
          }
        }))
      },
      selectedLanguage() {
        if (!this.selectedLanguageId) return

        return this.languages.filter(language => {
          return language.id === this.selectedLanguageId
        })[0]
      }
    },
    methods: {
      onConfigurationSaveState (saving) {
        if ((this.clone || this.isNew) && this.configurationSaveState && !saving) {
          this.clone = false

          this.isNew = false
        }

        this.configurationSaveState = saving
      },
      beforeSave () {
        let saveNew = this.clone || this.isNew

        this.saveConfiguration(saveNew)
      },
      saveConfiguration (saveNew) {
        EVENT.$emit('save-configuration', saveNew)
      },
      async deleteConfiguration () {
        const message = 'Are you sure you would like to delete this configuration?'

        const options = {
            title: 'Confirm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelVariant: 'white',
            size: 'sm',
            noFade: true
        }

        const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

        if (!confirmed) return

        EVENT.$emit('delete-configuration')
      },
      async getForm(versionNum) {
        try {
          this.loading = true
          if (!versionNum) {
            versionNum = -1
          }
          let formId = this.$route.params.formId
          if (this.isClone) {
            formId = SESSION.formClone.id
            versionNum = SESSION.formClone.version_number
          }

          let url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/forms/${formId}?include=version,item_ids&version_number=${versionNum}`
          let response = await HTTP.get(url)
          this.form = response.data
          for (const group of this.form.version.groups) {
            group.showItemsToSelect = Boolean(group.items_to_select)
          }
          if (this.isClone) {
            this.form.name = this.form.name + ' (Clone)'
          }
          this.originalFormName = this.form.name
          this.setFields()
          this.setSectionIds()
          this.getAllApps()
          this.getAllItems()
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      async getConfigurations() {
        this.loadingConfigurations = true

        let moreConfigurations = true
        let page = 1

        const configurations = []

        while (moreConfigurations) {
          const { data, error } = await getListOfConfigurations(this.project.id, page)

          if (error) {
            this.loadingConfigurations = false
            return this.configurationError = true
          }

          configurations.push(...data.results)
          moreConfigurations = data.has_next
          page++
        }

        const defaultConfiguration = configurations.find(c => c.is_default)

        setMissingKeysOnParentConfiguration(defaultConfiguration)

        this.loadingConfigurations = false
        this.configurations = configurations
        this.configurationError = false
      },
      async getWidgets() {
        const widgets = [
          { name: 'Calculator', id: 'calculator' },
          { name: 'Notepad', id: 'notepad' }
        ]

        for (const integration of this.project.integrations) {
            const { id, app: { first_name, app_settings: { content: { take_widget } } } } = integration

            if (take_widget) {
              widgets.push({ name: first_name, id })
            }
        }

        let hasMore = true
        let page = 1

        while (hasMore) {
          const { data, error } = await getWidgetsRequest(this.project.id, page)

          if (error) {
            const alert = {
              message: 'Failed to load widgets',
              variant: 'danger'
            }

            return EVENT.alert(alert)
          }

          widgets.push(...data.results)
          hasMore = data.has_next
          page++
        }

        this.widgets = widgets.sort((current, next) => current.name.localeCompare(next.name))
      },
      groupHasWidgetEnabled(widgetId) {
        const disabledWidgets = this.editing.disabled_widgets

        if (!disabledWidgets) return true

        return !disabledWidgets.includes(widgetId)
      },
      enableOrDisableWidget(widgetId) {
        if (!this.editing.disabled_widgets) {
          this.$set(this.editing, 'disabled_widgets', [])
        }

        const index = this.editing.disabled_widgets.indexOf(widgetId)

        if (index !== -1) {
          return this.editing.disabled_widgets.splice(index, 1)
        }

        this.editing.disabled_widgets.push(widgetId)
      },
      async activateVersion() {
        try {
          this.isActivatingVersion = true
          let url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/forms/${this.$route.params.formId}/activate_version`
          let data = { version_number: this.form.version.version_number }
          await HTTP.post(url, data)
          this.form.live_version_number = data.version_number
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to make version live!'
          })
        } finally {
          this.isActivatingVersion = false
        }
      },
      async onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          const {
            version: { groups, settings }
          } = this.form
          const sectionTimerOrInstructions = groups.some(
            group => group.time_limit || group.instructions
          )
          const sumOfSectionTimers = groups.reduce((count, group) => {
            return group.time_limit ? count + group.time_limit : count
          }, 0)

          if (this.originalFormName != this.form.name && this.formNames.indexOf(this.form.name) > -1) {
            EVENT.alert({
              variant: 'danger',
              message: `The name "${this.form.name}" is already taken.`
            })
            return
          }

          if (sectionTimerOrInstructions && !settings.enable_sections) {
            const enableSections = await this.$bvModal.msgBoxConfirm(
              'Section timers and instructions will not go into effect unless sections have been enabled. Would you like to enable sections before saving?',
              {
                title: 'Enable Sections',
                size: 'xl',
                okTitle: 'Enable Sections',
                okVariant: 'secondary',
                cancelTitle: "Don't Enable Sections",
                cancelVariant: 'white'
              }
            )
            if (enableSections) {
              settings.enable_sections = true

              const configuration = this.configurations.find(config => config.id === this.form.version.configuration_id)

              configuration.settings.enable_sections = true

              await saveConfigurationSettings(SESSION.project.id, configuration)
            }
          }

          if (
            settings.enable_sections &&
            settings.time_limit &&
            settings.time_limit < sumOfSectionTimers
          ) {
            const shouldSave = await this.$bvModal.msgBoxConfirm(
              'The sum of section time limits is greater than the exam time limit. This can cause the exam to end even if a section still has time left. Would you like to save anyway?',
              {
                title: 'Section Timers',
                size: 'xl',
                okTitle: 'Save',
                okVariant: 'secondary',
                cancelVariant: 'white'
              }
            )
            if (!shouldSave) {
              return
            }
          }

          this.saveForm()
        }
      },
      onSubmitNewForm (event) {
        const isValid = VALIDATE.validateFields(event.target)

        if (isValid) {
          this.createNewForm()
        }
      },
      async createNewForm () {
        this.isCreatingForm = true

        const form = {
          name: this.newFormName,
          configuration_id: this.newFormConfiguration,
          groups: []
        }

        const { data } = await postFormData(form, this.$route.params.projectId)

        if (!data) {
          return
        }
        
        EVENT.updateFormCount(1)

        this.isCreatingForm = false

        this.$bvModal.hide('new-form-modal')

        this.$router.push({
          name: 'projectFormEditor',
          params: {
            formId: data.id
          }
        })
      },
      submitThroughStep(step = null) {
        this.step = step
        this.$refs.form.dispatchEvent(new Event('submit', { cancelable: true }))
      },
      async saveForm() {
        try {
          this.isSavingForm = true

          if (this.isClone) {
            const newForm = await this.createForm()

            if (this.step === 'exit') {
              return this.$router.push({
                name: 'projectForms'
              })
            }

            return this.$router.push({
              name: 'projectFormEditor',
              params: { formId: newForm.id }
            })
          }

          let url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/forms/${this.$route.params.formId}`
          let data = { ...this.form.version }
          data.name = this.form.name

          delete data.settings

          if (this.step === 'version') {
            data.force_fresh = true
          }

          const result = await HTTP.put(url, data)

          if (this.step === 'exit') {
            return this.$router.push({
              name: 'projectForms'
            })
          }

          if (this.form.num_versions !== result.data.num_versions) {
            this.getForm(result.data.num_versions)
          }
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save form!'
          })
        } finally {
          this.isSavingForm = false
        }
      },
      async createForm() {
        const form = {
          name: this.form.name,
          force_fresh: true,
          group_order: this.form.version.group_order,
          groups: this.form.version.groups,
          configuration_id: this.form.version.configuration_id
        }

        const { data, error } = await postFormData(
          form,
          this.$route.params.projectId
        )
        if (error) {
          throw new Error(error)
        }
        return data
      },
      async getAllApps() {
        try {
          const privateApps = await HTTP.get(`${SEI_API_BASE}/apps`)
          const publicApps = await HTTP.get(`${SEI_API_BASE}/apps/public`)
          const allApps = [
            ...privateApps.data.results,
            ...publicApps.data.results
          ]
          const uniqueAppIds = new Set()
          const apps = []

          for (const app of allApps) {
            if (!uniqueAppIds.has(app.caveon_id)) {
              apps.push(app)
              uniqueAppIds.add(app.caveon_id)
            }
          }

          this.availableApps = apps
        } catch (error) {
          this.error = true
        }
      },
      async getAllItems() {
        try {
          this.loadingItems = true
          const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/items/lookup`
          const result = await HTTP.get(url)
          this.items = result.data
          this.setItemMetaLookup()
        } catch (error) {
          this.error = true
        } finally {
          this.loadingItems = false
        }
      },
      setItemMetaLookup() {
        const itemIdsByMeta = {}

        for (const [itemId, item] of Object.entries(this.items)) {
          const itemMeta = item.meta?.scorpion

          if (!itemMeta) continue

          for (const [fieldId, value] of Object.entries(itemMeta)) {
            if (Array.isArray(value)) {
              for (const subValue of value) {
                const metaKey = `${ fieldId }${ String(subValue) }`

                if (itemIdsByMeta[metaKey]) {
                  itemIdsByMeta[metaKey].add(itemId)
                } else {
                  itemIdsByMeta[metaKey] = new Set([itemId])
                }
              }

              continue
            }

            const metaKey = `${ fieldId }${ String(value) }`

            if (itemIdsByMeta[metaKey]) {
              itemIdsByMeta[metaKey].add(itemId)
            } else {
              itemIdsByMeta[metaKey] = new Set([itemId])
            }
          }
        }

        this.itemIdsByMeta = itemIdsByMeta
      },
      reload() {
        this.loading = true
        this.getForm()
      },
      onConfigurationSelected(configurationId) {
        this.form.version.configuration_id = configurationId
        this.newFormConfiguration = configurationId
      },
      setFields() {
        this.form.version.settings.mode = get(
          this.form,
          'version.settings.mode',
          'linear'
        )
        this.$set(this.form.version.settings, 'selected_languages', [])
        this.form.version.settings.selected_languages = get(
          this.form,
          'version.settings.selected_languages',
          ['English']
        )
        this.form.version.settings.default_language = get(
          this.form,
          'version.settings.default_language',
          'English'
        )
        this.form.version.settings.score_scale_lower = get(
          this.form,
          'version.settings.score_scale_lower',
          0
        )
        this.form.version.settings.score_scale_upper = get(
          this.form,
          'version.settings.score_scale_upper',
          100
        )
        this.form.version.settings.instructions = get(
          this.form,
          'version.settings.instructions',
          ''
        )
        this.form.version.group_order = get(
          this.form,
          'version.group_order',
          'default'
        )
        this.form.version.settings.show_score = get(
          this.form,
          'version.settings.show_score',
          true
        )
        this.form.version.settings.show_pass_fail = get(
          this.form,
          'version.settings.show_pass_fail',
          true
        )
        this.form.version.settings.show_cutscore = get(
          this.form,
          'version.settings.show_cutscore',
          true
        )
        this.form.version.settings.show_print_button = get(
          this.form,
          'version.settings.show_print_button',
          true
        )
        this.form.version.settings.show_email_button = get(
          this.form,
          'version.settings.show_email_button',
          true
        )
        this.form.version.settings.show_score_scale = get(
          this.form,
          'version.settings.show_score_scale',
          true
        )
        this.form.version.settings.show_total_score = get(
          this.form,
          'version.settings.show_total_score',
          true
        )
        this.form.version.settings.show_content_area_breakdown = get(
          this.form,
          'version.settings.show_content_area_breakdown',
          false
        )
        this.form.version.settings.show_content_area_breakdown_range_text = get(
          this.form,
          'version.settings.show_content_area_breakdown_range_text',
          false
        )
        this.form.version.settings.content_area_split_char = get(
          this.form,
          'version.settings.content_area_split_char',
          ''
        )
        this.form.version.settings.feedback_enabled = get(
          this.form,
          'version.settings.feedback_enabled',
          false
        )
        this.form.version.settings.exam_review_enabled = get(
          this.form,
          'version.settings.exam_review_enabled',
          false
        )
        this.form.version.settings.calculator = get(
          this.form,
          'version.settings.calculator',
          false
        )
        this.form.version.settings.comments = get(
          this.form,
          'version.settings.comments',
          false
        )
        this.form.version.settings.security_options = get(
          this.form,
          'version.settings.security_options',
          DEFAULT_SECURITY_OPTIONS
        )
        this.form.version.settings.time_limit = get(
          this.form,
          'version.settings.time_limit',
          null
        )

        // Need this to make the array reactive
        let score_ranges_tmp = this.form.version.settings.score_ranges
        this.$set(this.form.version.settings, 'score_ranges', [])
        this.form.version.settings.score_ranges = get(
          this.form,
          'version.settings.score_ranges',
          []
        )
        if (score_ranges_tmp) {
          this.form.version.settings.score_ranges = score_ranges_tmp
        }

        let cutscores_tmp = this.form.version.settings.cutscores
        this.$set(this.form.version.settings, 'cutscores', [])
        this.form.version.settings.cutscores = get(
          this.form,
          'version.settings.cutscores',
          []
        )
        if (cutscores_tmp) {
          this.form.version.settings.cutscores = cutscores_tmp
        }

        this.updateIsLinear()
      },
      addCutscore() {
        this.form.version.settings.cutscores.push({
          description: '',
          name: '',
          score: null
        })
      },
      deleteCutscore(index) {
        this.form.version.settings.cutscores.splice(index, 1)
      },
      addScoreRange() {
        this.form.version.settings.score_ranges.push({
          end: null,
          start: null,
          text: ''
        })
      },
      deleteScoreRange(index) {
        this.form.version.settings.score_ranges.splice(index, 1)
      },
      updateIsLinear() {
        if (this.form.version.settings.mode === 'linear') {
          this.isLinearMode = true
        } else {
          this.isLinearMode = false
        }
      },
      addItem(args) {
        const { sectionIndex, id } = args
        let items = this.form.version.groups[sectionIndex].item_ids
        items.push(id)
      },
      removeItem(args) {
        const { sectionIndex, index } = args
        let items = this.form.version.groups[sectionIndex].item_ids
        items.splice(index, 1)
      },
      moveItem(args) {
        const { sectionIndex, index, indexModifier } = args
        let items = this.form.version.groups[sectionIndex].item_ids
        this.movePoolItem(index, index + indexModifier, items)
      },
      movePoolItem(fromIndex, toIndex, items) {
        let id = items.splice(fromIndex, 1)[0]
        items.splice(toIndex, 0, id)
      },
      setSectionProp(args) {
        const { sectionIndex, key, value } = args
        this.form.version.groups[sectionIndex][key] = value
      },
      addSection() {
        this.form.version.groups.push({
          id: uuidv4(),
          item_ids: [],
          name: `Section ${this.form.version.groups.length + 1}`,
          instructions: '',
          items_to_select: null,
          items_per_page: 1,
          order: 'in_order',
          time_limit: null,
          pause_exam_timer: false,
          disabled_widgets: ['calculator', 'notepad']
        })
        this.newestSection = this.form.version.groups.length - 1
      },
      moveSection(args) {
        this.newestSection = null

        this.$nextTick(() => {
          const { direction, currIndex } = args
          if (direction === 'up') {
            let removedSections = this.form.version.groups.splice(currIndex, 1)
            this.form.version.groups.splice(currIndex - 1, 0, removedSections[0])
          }
          if (direction === 'down') {
            let removedSections = this.form.version.groups.splice(currIndex, 1)
            this.form.version.groups.splice(currIndex + 1, 0, removedSections[0])
          }
          if (direction === 'delete') {
            this.form.version.groups.splice(currIndex, 1)
          }
        })
      },
      hideArrow(index) {
        if (index === 0) {
          return 'up'
        } else if (index === this.form.version.groups.length - 1) {
          return 'down'
        } else {
          return 'none'
        }
      },
      routeToExport(type) {
        const query = { type }
        let formIdKey = 'form_id'

        if (type === 'caveon_scores_for_forms') {
          formIdKey = 'form_ids'
        }

        query[formIdKey] = this.form.id

        this.$router.push({ name: 'projectExport', query })
      },
      setTabIndex() {
        const index = TAB_NAMES.indexOf(this.$route.hash)
        if (index > -1) {
          this.tabIndex = index
        }
      },
      setSectionIds() {
        for (const group of this.form.version.groups) {
          if (!group.id) {
            const id = uuidv4()
            this.$set(group, 'id', id)
          }
        }
      },
      cloneForm() {
        if (this.isClone) {
          return EVENT.reloadRoute()
        }
        SESSION.formClone = {
          id: this.$route.params.formId,
          version_number: this.form.version.version_number
        }
        this.$router.push({
          name: 'projectFormEditor',
          params: { formId: 'clone' }
        })
      },
      openNewFormModal () {
        const defaultConfiguration = this.configurations.find(config => config.is_default)
        this.newFormConfiguration = defaultConfiguration.id
        this.newFormName = ''
        this.$bvModal.show('new-form-modal')
      },
      toggleSection(index) {
        if (this.newestSection === index) {
          return (this.newestSection = null)
        }

        this.newestSection = index
      },
      newConfiguration () {
        this.isNew = true

        this.openEditConfigurationModal()
      },
      cloneConfiguration () {
        this.clone = true

        this.openEditConfigurationModal()
      },
      openEditConfigurationModal() {
        if (this.isNew) {
          this.configuration = deepCopy(DEFAULT_CONFIGURATION)
        } else {
          const matchedConfigs = this.configurations.filter(config => config.id === this.form.version.configuration_id)
          this.configuration = deepCopy(matchedConfigs.pop())

          if (this.clone) {
            this.configuration.name += ' (clone)'
          }
        }

        this.$bvModal.show('edit-configuration-modal')
      },
      onOpenModal(modal, index) {
        this.editing = this.form.version.groups[index]
        this.editingSectionIndex = index
        this.$bvModal.show(modal)
      },
      onSetPoolItems() {
        EVENT.$emit('update-pool-items' + this.editingSectionIndex)
      },
      onSelectionRuleUpdate(rules) {
        this.editing.selection_rules = rules
        EVENT.$emit('update-rules' + this.editingSectionIndex, rules)
      },
      updateSectionSettings() {
        EVENT.$emit('update-settings' + this.editing.id)
      },
      async FetchLanguages() {
        const response = await Language.FetchAll(this.project.id)

        if (response.error) {
          console.error('it broke')
          return
        }

        this.languages = [...response.results]
      },
      onConfigEditorClose(event) {
        if (this.configChanged && !confirm('Leave without saving changes?')) {
          event.preventDefault()
        }
      },
      onConfigEditorHidden () {
        this.clone = false

        this.isNew = false
      },
      onConfigurationChanged(isChanged, newConfig=null) {
        this.configChanged = isChanged
        if (newConfig !== null) {
          Object.assign(this.configuration, newConfig)
        }
      },
      onSaveForm() {
        this.submitThroughStep()
      },
      onConfigurationDeleted (configId) {
        this.configurations = this.configurations.filter(config => config.id !== configId)

        this.form.version.configuration_id = this.configurations.find(config => config.is_default).id
      }
    },
    watch: {
      tabIndex: function(index) {
        const tabName = TAB_NAMES[index]
        window.history.replaceState(null, null, tabName)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
