<template>
  <b-button v-b-modal.generate variant="white">
    Generate

    <b-modal id="generate" size="xl" title="Generate Item Variations">
      <b-form-group
        label="Item Name Format"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-input type="text" v-model="baseName" />
        <b-form-text id="input-live-help"
          >Use the special designation <code>$number</code> to include the
          variation number and <code>$date</code> to include today's date in
          item names. Leave blank to use the default naming scheme.</b-form-text
        >
      </b-form-group>
      <b-form-group
        label="Number of variations"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <b-input type="number" v-model.number="numVariations" min="1" :max="maxVariations" step="1" />
        <b-form-text>The maximum amount allowed is {{ maxVariations }}. If the number is greater than the maximum it will be reduced to it. The number of variations is also limited by the number of permutations of the item.</b-form-text>
      </b-form-group>
      <b-form-checkbox v-model="randomizeOptions" class="mt-3"
        >Randomize options</b-form-checkbox
      >
      <ItemsPreviewTable
        :project="project"
        :items="pendingItems"
        :loading="loading"
        :hide-show-more="true"
        @delete-items="deleteItems"
        show-meta-changed
      />
      <p v-show="pendingItems.length">
        <small
          >Variations saved with a blank name will use the project naming
          scheme.</small
        >
      </p>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="preCancel(cancel)" variant="white">Cancel</b-button>
          <b-button
            @click="doGenerate()"
            variant="secondary"
            :disabled="loading"
            >Generate</b-button
          >
        </b-button-group>
      </template>
    </b-modal>
  </b-button>
</template>

<script>
  import { MAX_NUM_VARIATIONS, SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import ItemsPreviewTable from './ItemsPreviewTable'
  import { deepCopy } from '../../utils/misc.js'
  import shuffle from 'lodash.shuffle'

  async function generate(projectId, itemId, payload) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/generate_v2`
      const response = await HTTP.post(url, payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  function randomize(item) {
    if (!item.content.options) {
      return item
    }

    const optionsMap = item.content.options.reduce((acc, option, index) => {
      acc[option] = item.settings.key[index]

      return acc
    }, {})

    const options = shuffle(Object.keys(optionsMap))
    const key = options.reduce((acc, option) => {
      acc.push(optionsMap[option])

      return acc
    }, [])

    item.content.options = options
    item.settings.key = key

    return item
  }

  export default {
    name: 'ItemGenerate',
    components: {
      ItemsPreviewTable
    },
    props: {
      project: {
        type: Object
      },
      item: {
        type: Object
      }
    },
    created() {},
    data() {
      return {
        loading: false,
        numVariations: 1,
        pendingItems: [],
        baseName: `${this.item.name} ($date)`,
        randomizeOptions: false,
        maxVariations: MAX_NUM_VARIATIONS
      }
    },
    methods: {
      async doGenerate() {
        this.loading = true

        if (this.numVariations > this.maxVariations) {
          this.numVariations = this.maxVariations
        }

        const response = await generate(this.project.id, this.item.id, {
          num_of_variations: this.numVariations
        })

        const generatedItems = response.results
        if (!generatedItems) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to generate items: ' + response.error.message + '.'
          })
          this.loading = false
          return
        }

        const date = new Date().toLocaleDateString()

        for (let [index, payload] of generatedItems.entries()) {
          payload.name = this.baseName
            .replace(/\$number/gi, index + 1)
            .replace(/\$date/gi, date)
          payload.meta = deepCopy(this.item.version.meta)

          if (this.randomizeOptions) {
            payload = randomize(payload)
          }

          if (this.item.enemy_ids) {
            payload.enemy_ids = [ ...this.item.enemy_ids ]
          }
          if (this.item.references) {
            payload.references = [ ...this.item.references ]
          }
        }

        this.pendingItems = [...generatedItems]
        this.loading = false
      },
      deleteItems(index) {
        if (index === -1) {
          this.pendingItems = []
        } else {
          this.pendingItems.splice(index, 1)
        }
      },
      preCancel(cancel) {
        this.deleteItems(-1)
        cancel()
      }
    }
  }
</script>

<style lang="scss" scoped></style>
