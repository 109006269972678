<template>
  <div class="legend-box py-2 px-3">
    <div
      :key="index"
      class="mb-1"
      v-for="(legend, index) in chart.legend.legendItems"
    >
      <span
        :class="legend.hidden ? 'fading' : null"
        @click="updateDataset(legend)"
        class="pointer"
      >
        <div
          :style="{ backgroundColor: legend.fillStyle }"
          class="color d-inline-block mr-2"
        ></div>
        {{ legend.text }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AnalyticsLegend',
    props: {
      chart: {
        type: Object
      }
    },
    methods: {
      updateDataset(legend) {
        const meta = this.chart.getDatasetMeta(0)
        const chartData = meta.data[legend.index]
        chartData.hidden = !chartData.hidden
        legend.hidden = chartData.hidden
        this.chart.update()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .color {
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
  }
  .legend-box {
    max-height: 250px;
    overflow-y: scroll;
  }
  .fading {
    opacity: 0.3;
  }
  .pointer {
    cursor: pointer;
  }
</style>
