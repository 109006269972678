<template>
  <div>
    <b-row class="my-3">
      <b-col lg="3" md="4" sm="6">
        <label for="Points">Points</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <b-form-input
          id="Points"
          min="0"
          step="0.01"
          type="number"
          @input="defaultNumberInputToZero($event, fields.settings, 'points')"
          :value="fields.settings.points"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="type !== 'smart'">
      <b-col lg="3" md="4" sm="6"
        ><label for="ScoringMethod">Scoring Method</label></b-col
      >
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          id="ScoringMethod"
          :options="scoringOptions"
          :value="fields.settings.scoring"
          @change="onScoringChange"
        ></b-form-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="fields.settings.scoring === 'cutscore'">
      <b-col lg="3" md="4" sm="6"
        ><label for="CutscoreValue">Minimum score for full credit</label></b-col
      >
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-input id="CutscoreValue" v-model.number="fields.settings.cutscore_min" type="number" step="0.05" min="0" max="1" placeholder="Enter a value betwen 0 and 1"></b-input>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="displayOptionOrder">
      <b-col lg="3" md="4" sm="6">
        <label for="Order">Option Order</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          :options="orderOptions"
          id="Order"
          v-model="fields.settings.presentation_order"
        ></b-form-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <div v-if="displayNumOptions">
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="CorrectOptions">Correct Options to Present</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            id="CorrectOptions"
            min="0"
            step="1"
            type="number"
            @input="defaultNumberInputToNull($event, fields.settings, 'keys_to_present')"
            :value="fields.settings.keys_to_present"
          ></b-form-input>
          <small>(Leave blank for all)</small>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="IncorrectPresent">Incorrect Options to Present</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            min="0"
            step="1"
            type="number"
            id="IncorrectPresent"
            @input="defaultNumberInputToNull($event, fields.settings, 'distractors_to_present')"
            :value="fields.settings.distractors_to_present"
          ></b-form-input>
          <small>(Leave blank for all)</small>
        </b-col>
      </b-row>
    </div>
    <b-row class="my-3" v-if="type === 'multiple_choice'">
      <b-col lg="3" md="4" sm="6">
        <label for="SelectAllThatApply">Select All That Apply</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          :options="selectOptions"
          id="SelectionLimit"
          v-model="fields.settings.select_all_that_apply"
        ></b-form-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="type === 'multiple_choice' && !fields.settings.select_all_that_apply">
      <b-col lg="3" md="4" sm="6">
        <label for="SelectionLimit">Selection Limit</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <b-form-input
          id="Selection Limit"
          min="0"
          type="number"
          @input="defaultNumberInputToZero($event, fields.settings, 'selection_limit')"
          :value="fields.settings.selection_limit"
        ></b-form-input>
      </b-col>
    </b-row>
    <div v-if="type === 'domc'">
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="CorrectKeys"
            >To get the item right, how many correct options must be answered
            appropriately?</label
          >
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            id="CorrectKeys"
            min="0"
            step="1"
            type="number"
            @input="defaultNumberInputToNull($event, fields.settings, 'correct_keys')"
            :value="fields.settings.correct_keys"
          ></b-form-input>
          <small>(Leave blank for all in presentation set)</small>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="IncorrectKeys"
            >To get the item right, how many incorrect options must be answered
            appropriately?</label
          >
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            min="0"
            step="1"
            type="number"
            id="IncorrectKeys"
            @input="defaultNumberInputToNull($event, fields.settings, 'correct_distractors')"
            :value="fields.settings.correct_distractors"
          ></b-form-input>
          <small>(Leave blank to have this rule not apply)</small>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="WrongNumber"
            >To get the item wrong, how many correct options must be answered
            inappropriately?</label
          >
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            id="WrongNumber"
            min="0"
            step="1"
            type="number"
            @input="defaultNumberInputToNull($event, fields.settings, 'missed_keys')"
            :value="fields.settings.missed_keys"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="WrongNumber2"
            >To get the item wrong, how many incorrect options must be answered
            inappropriately?</label
          >
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            id="WrongNumber2"
            min="0"
            step="1"
            type="number"
            @input="defaultNumberInputToNull($event, fields.settings, 'missed_distractors')"
            :value="fields.settings.missed_distractors"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="ExtraOption">Extra Option Probability</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <b-form-input
            min="0"
            step="0.1"
            id="ExtraOption"
            type="number"
            @input="defaultNumberInputToZero($event, fields.settings, 'extra_option_prob')"
            :value="fields.settings.extra_option_prob"
          ></b-form-input>
        </b-col>
      </b-row>
    </div>
    <b-row class="my-3" v-if="type === 'short_answer'">
      <b-col lg="3" md="4" sm="6">
        <label>Method</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          :options="methodOptions"
          v-model="fields.settings.method"
        ></b-form-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="type === 'hotspot'">
      <b-col lg="3" md="4" sm="6">
        <label>Number of Selections</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <b-form-input
          min="0"
          step="1"
          type="number"
          @input="defaultNumberInputToZero($event, fields.settings, 'num_selections')"
          :value="fields.settings.num_selections"
        ></b-form-input>
        <small>(0 or anything over the number of correct spots is reduced to that number)</small>
      </b-col>
    </b-row>
    <div v-if="type === 'scale'">
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label>Method</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-select
            :options="scaleMethodOptions"
            v-model="fields.settings.method"
          ></b-form-select>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label>Key</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-input
            step="1"
            type="number"
            v-model.number="fields.settings.key"
            :required="!!fields.settings.method"
          ></b-form-input>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label>Lower Bound *</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-input
            step="1"
            type="number"
            v-model.number="fields.settings.bounds[0]"
            required
          ></b-form-input>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label>Upper Bound *</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-input
            step="1"
            type="number"
            v-model.number="fields.settings.bounds[1]"
            required
          ></b-form-input>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
      <b-row class="my-3" v-if="!(bulk || settingsOnly)">
        <b-col lg="3" md="4" sm="6">
          <label>Lower Label (30 chars max)</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-input
            maxlength="30"
            v-model="fields.content.labels[0]"
          ></b-form-input>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
      <b-row class="my-3" v-if="!(bulk || settingsOnly)">
        <b-col lg="3" md="4" sm="6">
          <label>Upper Label (30 chars max)</label>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-input
            maxlength="30"
            v-model="fields.content.labels[1]"
          ></b-form-input>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-col>
      </b-row>
    </div>
    <b-row class="my-3" v-if="!settingsOnly">
      <b-col lg="3" md="4" sm="6">
        <label for="Enemies">Enemies</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <v-select
          :options="itemIds"
          :reduce="option => option.id"
          id="Enemies"
          label="name"
          multiple
          placeholder="Select enemies"
          v-model="fields.enemy_ids"
        ></v-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="type === 'likert'">
      <b-col lg="3" md="4" sm="6">
        <label for="likert-option-order">Option Order</label>
      </b-col>
      <b-col lg="9" md="8" sm="6">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          id="likert-option-order"
          :options="likertOrderOptions"
          v-model="fields.settings.option_order"
        />
        <!-- eslint-enable vue/no-mutating-props -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { deepCopy, defaultNumberInputToNull, defaultNumberInputToZero} from '../../utils/misc'
  import { DEFAULT_ITEM_SETTINGS } from '../../utils/constants'

  const DISPLAY_OPTION_ORDER = [
    'build_list',
    'domc',
    'matching',
    'multiple_choice'
  ]

  const DISPLAY_NUM_OPTIONS = ['domc', 'multiple_choice']
  const DISPLAY_PARTIAL_SCORING = ['multiple_choice', 'matching', 'hotspot', 'fill_in_the_blanks']
  const DEFAULT_SCORING_OPTIONS = [
    { text: 'Default', value: 'default' },
    { text: 'Manual', value: 'manual' }
  ]
  const PARTIAL_SCORING_OPTION = {
    text: 'Partial',
    value: 'partial'
  }
  const CUTSCORE_SCORING_OPTION = {
    text: 'Cutscore',
    value: 'cutscore'
  }
  const DISPLAY_WEIGHTED_SCORING = ['multiple_choice', 'short_answer', 'matching', 'hotspot', 'build_list', 'fill_in_the_blanks']
  const WEIGHTED_SCORING_OPTION = { text: 'Weighted', value: 'weighted' }


  const getScoringOptions = type_ => {
    let scoringOptions = [
      ...DEFAULT_SCORING_OPTIONS
    ]

    if (DISPLAY_PARTIAL_SCORING.includes(type_)) {
      scoringOptions = [
        ...scoringOptions,
        PARTIAL_SCORING_OPTION,
        CUTSCORE_SCORING_OPTION
      ]
    }

    if (DISPLAY_WEIGHTED_SCORING.includes(type_)) {
      scoringOptions = [
        ...scoringOptions,
        WEIGHTED_SCORING_OPTION
      ]
    }

    return scoringOptions
  }

  export default {
    name: 'ItemSettings',
    props: {
      fields: {
        type: Object
      },
      type: {
        type: String
      },
      itemIds: {
        type: Array
      },
      bulk: {
        type: Boolean
      },
      settingsOnly: {
        type: Boolean
      }
    },
    created() {
      this.scoringOptions = getScoringOptions(this.type)

      const defaultSettings = deepCopy(DEFAULT_ITEM_SETTINGS[this.type])
      for (const setting in defaultSettings) {
        if (!this.fields.settings.hasOwnProperty(setting)) { // eslint-disable-line no-prototype-builtins
          this.fields.settings[setting] = defaultSettings[setting] // eslint-disable-line vue/no-mutating-props
        }
      }
    },
    watch: {
      type(newType) {
        this.scoringOptions = getScoringOptions(newType)
      }
    },
    data() {
      return {
        selectOptions: [
          { text: 'Disabled', value: false },
          { text: 'Enabled', value: true }
        ],
        scoringOptions: DEFAULT_SCORING_OPTIONS,
        orderOptions: [
          { text: 'Random', value: 'random' },
          { text: 'Fixed', value: 'default' },
          { text: 'Length', value: 'length' },
          { text: 'Alphabetical', value: 'alphabetical' }
        ],
        methodOptions: [
          { text: 'Exact', value: 'exact' },
          { text: 'Ignore Case', value: 'ignore_case' },
          { text: 'Regular Expression', value: 'regex' }
        ],
        scaleMethodOptions: [
          { text: 'Percent', value: null },
          { text: 'Exact', value: 'exact' }
        ],
        likertOrderOptions: [
          { text: 'Ascending (strongly disagree first)', value: 'asc' },
          { text: 'Descending (strongly agree first)', value: 'desc' }
        ],
        mutablePoints: this.points,
        defaultNumberInputToNull,
        defaultNumberInputToZero
      }
    },
    methods: {
      onScoringChange(value) {
        this.fields.settings.scoring = value // eslint-disable-line vue/no-mutating-props
      }
    },
    computed: {
      displayOptionOrder() {
        return DISPLAY_OPTION_ORDER.includes(this.type)
      },
      displayNumOptions() {
        return DISPLAY_NUM_OPTIONS.includes(this.type)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
