<template>
  <div>
    <b-container class="p-0">
      <h6>Visual watermark</h6>
      <b-card>
        <b-card-header>Dash pattern</b-card-header>
        <b-card-body>
          <label for="tags-dash-pattern">Enter dash patterns. Example: 23133. Type a full or partial pattern and press enter. Add each pattern in a new tag.</label>
          <b-form-tags input-id="tags-dash-pattern" v-model="dashPatterns"></b-form-tags>
        </b-card-body>
      </b-card>
      <br>
      <b-card>
        <b-card-header>Delivery ID</b-card-header>
        <b-card-body>
          <label for="tags-delivery-id">Enter all or part of a delivery ID. If you have only parts of delivery IDs, enter them each individually.</label>
          <b-form-tags input-id="tags-delivery-id" v-model="deliveryIds"></b-form-tags>
        </b-card-body>
      </b-card>
      <br>
      <b-card>
        <b-card-header>Smart item rendering</b-card-header>
        <b-card-body>
          <label for="tags-smart-stem">Enter all or part of a smart item stem or option text.</label>
          <b-form-tags input-id="tags-smart-stem" v-model="smartItemRenderings"></b-form-tags>
        </b-card-body>
      </b-card>
    </b-container>
    <br>
    <b-container class="p-0">
      <h6>Deliveries that contain specific items</h6>
      <b-card>
        <b-card-header>Items</b-card-header>
        <b-card-body>
          <b-checkbox v-model="includeItemOrder">Include item order</b-checkbox>
          <p>Selected Items</p>
          <div>
            <p v-show="!pendingItems.length">No items selected</p>
            <b-list-group class="listing-pending-items">
              <b-list-group-item v-for="(item, index) in pendingItems" :key="item.id">
                {{ item.name }}
                <b-button-group class="float-right">
                  <b-button v-b-toggle="[ 'details-' + item.id ]" variant="white" size="sm">Additional parameters</b-button>
                  <b-button
                    :disabled="index === 0"
                    @click="moveItemInList(index, -1)"
                    size="sm"
                    variant="white"
                  >
                    <font-awesome-icon icon="arrow-up"></font-awesome-icon>
                  </b-button>
                  <b-button
                    :disabled="index === pendingItems.length - 1"
                    @click="moveItemInList(index, 1)"
                    size="sm"
                    variant="white"
                  >
                    <font-awesome-icon icon="arrow-down"></font-awesome-icon>
                  </b-button>
                  <b-button @click="removeItemFromList(index)" size="sm" variant="white">
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                  </b-button>
                </b-button-group>
                <b-collapse :id="'details-' + item.id" class="mt-2">
                  <b-form-group label="Version">
                    <b-select
                      :options="item.versionArray"
                      v-if="item.num_versions > 1"
                      v-model="item.desiredVersion"
                    ></b-select>
                  </b-form-group>
                  <p>{{ item.versions[0].content.stem }}</p>
                  <div>
                    <b-row v-for="(variable, index) in item.itemVars.vars" :key="index">
                      <b-col>
                        <label for="variable-name">Variable</label>
                        <b-form-input input-id="variable-name" v-model="variable.key"></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="variable-value">Value</label>
                        <b-form-input input-id="variable-value" v-model="variable.val"></b-form-input>
                      </b-col>
                    </b-row>
                    <br>
                    <b-button variant="white" size="sm" class="float-right" @click="item.itemVars.vars.push({ key: '', val: ''})">Add variable</b-button>
                    <br>
                    <br>
                  </div>

                  <div v-if="supportsOptions(item.itemType)">
                    <b-button v-if="!showOptions[item.id]" variant="white" size="sm" class="float-right" @click="updateShowOptions(item)">Add options</b-button>
                    <div v-if="item.viewedOptions && item.viewedOptions.length">
                      <label v-show="item.viewedOptions.length > 0">
                        <b-checkbox v-model="item.allOptionsSeen">Includes all options presented</b-checkbox>
                      </label> &emsp;
                      <label v-show="item.viewedOptions.length > 0">
                        <b-checkbox v-model="item.includeOptionOrder">Include option order</b-checkbox>
                      </label>

                      <b-list-group>
                        <b-list-group-item v-for="(option, index) in item.viewedOptions" :key="index">
                          {{ option[0] }}
                          <b-button-group class="float-right">
                            <b-button
                              :disabled="index === 0"
                              @click="moveOptionInList(item, index, -1)"
                              size="sm"
                              variant="white"
                            >
                              <font-awesome-icon icon="arrow-up"></font-awesome-icon>
                            </b-button>
                            <b-button
                              :disabled="index === item.viewedOptions.length - 1"
                              @click="moveOptionInList(item, index, 1)"
                              size="sm"
                              variant="white"
                            >
                              <font-awesome-icon icon="arrow-down"></font-awesome-icon>
                            </b-button>
                            <b-button @click="removeOptionFromList(item, index)" size="sm" variant="white">
                              <font-awesome-icon icon="trash"></font-awesome-icon>
                            </b-button>
                          </b-button-group>
                        </b-list-group-item>
                      </b-list-group>

                    </div>

                    <div>
                      <b-list-group v-if="showOptions[item.id]">
                        <b-list-group-item v-for="(value, option) in item.combinedOptions" :key="option">
                          {{ option }}<b-button @click="addOptionFromList(item, option, value)" size="sm" variant="white" class="float-right">+</b-button>
                        </b-list-group-item>
                      </b-list-group>
                    </div>

                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>
          <br>
          <p>Select an item from below or type an item name into the search box</p>
          <b-form-input v-model="searchText" placeholder="Search"></b-form-input>
          <b-list-group class="listing-all-items">
            <b-list-group-item v-for="item in filteredItems" :key="item.value">
              {{ item.text }}
              <b-button variant="white" size="sm" class="float-right" @click="addItem(item)">+</b-button>
            </b-list-group-item>
          </b-list-group>

        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'


  const MAX_ATTEMPTS = 300
  const POLLING_INTERVAL_MS = 1500

  async function poll(jobId) {
    const url = `${SEI_API_BASE}/jobs/${jobId}`
    const response = await HTTP.get(url)

    return response.data
  }

  async function getAllItems(projectId) {
    const url = `${SEI_API_BASE}/exams/${projectId}/items/lookup`
    const result = await HTTP.get(url)

    return { allItems: result.data, error: null }
  }

  async function getFullItem(projectId, itemId) {
    const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}?include=versions`
    const result = await HTTP.get(url)

    return result.data
  }

  function prepOptions (options) {
    const cleanOptions = options.map(option => {
      return option[1]
    })

    const simplifiedOptionSets = cleanOptions.reduce(function tl (accumulator, value) {
      const tmp = []
      accumulator.forEach(function (a0) {
        value.forEach(function (a1) {
          tmp.push(a0.concat(a1))
        })
      })
      return tmp
    }, [[]])

    const trimmedSimple = simplifiedOptionSets.map(set => {
      const uniques = []
      set.map(val => {
        if (uniques.indexOf(val) === -1) {
          uniques.push(val)
        }
      })
      return uniques
    })

    return trimmedSimple
  }

  function makeSimplifiedItem (item) {
    const simplifiedItem = {
      id: item.id
    }

    if (item.itemVars.vars[0] && item.itemVars.vars[0].key !== '') {
      const variables = {}
      item.itemVars.vars.map(function (variable) {
        variables[variable.key] = variable.val
      })
      simplifiedItem.variables = variables
    }

    if (item.viewedOptions && item.viewedOptions.length > 0) {
      simplifiedItem.entire = item.allOptionsSeen
      simplifiedItem.ordered = item.includeOptionOrder
      simplifiedItem.options = prepOptions(item.viewedOptions)
    }

    if (item.desiredVersion > 0) {
      simplifiedItem.version_number = item.desiredVersion
    }

    return simplifiedItem
  }

  async function watermarkSearch(projectId, payload) {
    const url = `${SEI_API_BASE}/exams/${projectId}/deliveries/watermark_search`
    const response = await HTTP.post(url, payload)
    const jobId = response.data.job_id

    if (!jobId) {
      throw new Error('job creation failed')
    }

    return { jobId }
  }

  function prepItemForDisplay (rawItem) {
    const versionArray = [{ text: 'All', value: null }]

    rawItem.viewedOptions = []
    rawItem.allOptionsSeen = false
    rawItem.includeOptionOrder = false
    rawItem.itemVars = { vars: [] }

    if (rawItem.num_versions > 1) {
      for (let i = 0; i < rawItem.num_versions; i++) {
        versionArray.push({ text: i + 1, value: i + 1 })
      }
      rawItem.versionArray = versionArray
      rawItem.desiredVersion = null
    }
    rawItem.itemType = rawItem.versions[0].settings.type

    return rawItem
  }

  function combineAllOptions (versions) {
    const optionSet = {}
    versions.map(version => {
      version.content.options.map((option, i) => {
        if (optionSet[option] && optionSet[option].indexOf(i) < 0) {
          optionSet[option].push(i)
        } else {
          optionSet[option] = [i]
        }
      })
    })
    return optionSet
  }

  function processAllItems(allItems) {
    return Object.keys(allItems)
      .map(itemId => {
        return { text: allItems[itemId].n, value: itemId }
      })
      .sort((a, b) => {
        return a.text.localeCompare(b.text)
      })
  }

  export default {
    name: 'DeliveriesSearch',
    props: {
      project: {
        type: Object
      }
    },
    data() {
      return {
        allItems: [],
        dashPatterns: [],
        deliveryIds: [],
        includeItemOrder: false,
        itemNames: [],
        polling: false,
        interval: null,
        intervalCount: 0,
        job: null,
        pendingItems: [],
        selectedItems: [],
        searchText: '',
        showOptions: {},
        smartItemRenderings: []
      }
    },
    async created() {
      const { allItems } = await getAllItems(this.project.id)

      this.allItems = processAllItems(allItems)
    },
    methods: {
      report () {
        if (this.dashPatterns.length || this.deliveryIds.length || this.smartItemRenderings.length || this.pendingItems.length) {
          this.fetchDelivieries()

          return
        }

        this.$emit('ready')
      },
      initPolling(jobId) {
        this.polling = true
        this.interval = setInterval(async () => {
          try {
            if (this.intervalCount >= MAX_ATTEMPTS) {
              throw new Error('max attempts reached')
            }

            this.intervalCount++
            const response = await poll(jobId)

            if (response.status === 'failed') {
              throw new Error(response.status)
            }

            if (response.result) {
              this.clearPolling().then(() => {
                this.job = response
                this.seeResults()
              })
            }
          } catch (e) {
            this.job = {
              status: 'failed'
            }
            this.clearPolling()
          }
        }, POLLING_INTERVAL_MS)
      },
      clearPolling() {
        this.polling = false
        clearInterval(this.interval)

        return Promise.resolve()
      },
      async fetchDelivieries() {
        const postObject = {
          count_only: true,
          delivery_id_chunks: this.deliveryIds,
          pattern_chunks: this.dashPatterns,
          ordered: this.includeItemOrder,
          items: this.pendingItems.map(makeSimplifiedItem),
          smart_item_renderings: this.smartItemRenderings
        }

        try {
          const { jobId } = await watermarkSearch(this.project.id, postObject)

          this.initPolling(jobId)
        } catch (e) {
          this.job = {
            status: 'failed'
          }
          this.clearPolling()
        }
      },
      async addItem(item) {
        this.allItems = this.allItems.filter(i => i.value !== item.value)
        const rawItem = prepItemForDisplay(await getFullItem(this.project.id, item.value))

        try {
          if (this.supportsOptions(rawItem.versions[0].settings.type)) {
            rawItem.combinedOptions = combineAllOptions(rawItem.versions)
          }
        } catch (err) {
          // NOOP
        }

        this.pendingItems.push(rawItem)
      },
      seeResults() {
        const queryKey = this.job.result.query_key

        this.$emit('ready', queryKey)
      },
      supportsOptions (itemType) {
        return itemType === 'domc' || itemType === 'multiple_choice' || itemType === 'matching' || itemType === 'build_list'
      },
      addOptionFromList (item, option, value) {
        delete item.combinedOptions[option]
        item.viewedOptions.push([option, value])
      },
      updateShowOptions(item) {
        this.showOptions = {
          ...this.showOptions,
          [item.id]: true
        }
      },
      moveOptionInList(item, index, amount) {
        const to = index + amount

        const a = item.viewedOptions[index]
        const b = item.viewedOptions[to]
        const viewedOptions = [
          ...item.viewedOptions
        ]

        viewedOptions[index] = b
        viewedOptions[to] = a

        item.viewedOptions = viewedOptions
      },
      removeOptionFromList(item, index) {
        const [ option ] = item.viewedOptions.splice(index, 1)

        item.combinedOptions = {
          ...item.combinedOptions,
          [option[0]]: option[1]
        }
      },
      moveItemInList(index, amount) {
        const to = index + amount

        const a = this.pendingItems[index]
        const b = this.pendingItems[to]
        const pendingItems = [
          ...this.pendingItems
        ]

        pendingItems[index] = b
        pendingItems[to] = a

        this.pendingItems = pendingItems
      },
      removeItemFromList(index) {
        const [ removedItem ] = this.pendingItems.splice(index, 1)
        const allItems = [ ...this.allItems, { text: removedItem.name, value: removedItem.id } ]

        allItems.sort((a, b) => {
          return a.text.localeCompare(b.text)
        })

        this.allItems = [ ...allItems ]
      }
    },
    computed: {
      filteredItems() {
        if (!(this.searchText && this.searchText.length)) return this.allItems

        return this.allItems.filter(item => item.text.toLowerCase().startsWith(this.searchText.toLowerCase()))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .listing-all-items {
    max-height: 200px;
    height: 200px;
    overflow-y: scroll;
  }
  .listing-pending-items {
    max-height: 200px;
    overflow-y: scroll;
  }
</style>
