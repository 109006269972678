<template>
  <div> 
    <b-card class="my-1" no-body>
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          size="lg"
          variant="primary"
          @click="toggleSection"
        >
          {{ dropdownHeader }}
          <div class="float-right">
            <!-- <b-button variant="white" size="sm" class="mr-2" @click.stop="openModal('section-settings')">Settings</b-button>
            <b-button variant="white" size="sm" class="mr-2" @click.stop="openModal('item-pool')">Add Items</b-button>
            <b-button variant="white" size="sm" class="mr-2" @click.stop="openModal('selection-rules')">Selection Rules</b-button> -->
            <b-button-group @click.stop>
              <b-button
                :disabled="hideArrow === 'up'"
                @click="moveSection('up')"
                size="sm"
                variant="white"
              >
                <font-awesome-icon icon="arrow-up"></font-awesome-icon>
              </b-button>
              <b-button
                :disabled="hideArrow === 'down'"
                @click="moveSection('down')"
                size="sm"
                variant="white"
              >
                <font-awesome-icon icon="arrow-down"></font-awesome-icon>
              </b-button>
              <b-button @click="moveSection('delete')" size="sm" variant="white">
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </div>
        </b-button>
      </b-card-header>
      <b-card-body v-if="isExpanded">
        <!-- TODO: Unhide once this is supported on backend -->
        <b-form-group
          v-if="false"
          :label-for="`define-pool-${sectionIndex}`"
          label="Define the item pool"
          label-cols-lg="3"
          label-cols-sm="4"
        >
          <b-form-select
            :id="`define-pool-${sectionIndex}`"
            :options="[
              'Manually add items to a list',
              'Non-deleted items in the project'
            ]"
            v-bind:value="section.item_pool_definition | formatPoolDef"
            v-on:input="changeItemPoolDefinition($event)"
          ></b-form-select>
        </b-form-group>

        <h5 class="ml-1 mb-3"><b-button variant="primary-light" size="sm" class="ml-2 float-right" @click.stop="openModal('section-settings')">Edit</b-button> Section Settings
          
        </h5>

        <b-row class="mr-1 ml-1 mt-2 tables">
            <b-col class="p-0 preview">
                <b-table
                    :fields="settingsFields"
                    :items="settingsData"
                    class="mb-0 p-1"
                    fixed
                    small
                    striped
                ></b-table>
            </b-col>
        </b-row>

        <h5 class="mr-1 mt-3 mb-3">Item Pool ({{ sectionItemCount }})</h5>
        <SelectionRules 
          :section="section" 
          :section-index="sectionIndex" 
          :item-map="itemMap"
          :item-ids-by-meta="itemIdsByMeta"
          @open-modal="onOpenModal" 
        />
        <ItemPool
          :item-map="itemMap"
          :section="section"
          :section-index="sectionIndex"
          :form="form"
          @open-modal="onOpenModal"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import get from 'lodash.get'

  import { EVENT } from '../../utils/event-bus'

  import ItemPool from './ItemPool'
  import SelectionRules from './SelectionRules'

  export default {
    name: 'FormSection',
    components: {
      ItemPool,
      SelectionRules
    },
    data() {
      return {
        search: '',
        poolSearch: '',
        fields: [
          { key: 'buttons', label: '', sortable: false },
          { key: 'n', label: 'Name', sortable: true },
          { key: 'd', label: 'Difficulty', sortable: true }
        ],
        poolFields: [
          { key: 'n', label: 'Name', sortable: true },
          { key: 'd', label: 'Difficulty', sortable: true },
          { key: 'buttons', label: '', sortable: false }
        ],
        settingsFields: [
          { key: 'setting' },
          { key: 'value' }
        ],
        settingsData: []
      }
    },
    created() {
      EVENT.$on('update-settings' + this.section.id, this.setSettingsData)

      /*  eslint-disable vue/no-mutating-props */
      this.section.item_pool_definition = get(
        this.section,
        'item_pool_definition',
        'manual'
      )
      this.section.instructions = get(this.section, 'instructions', '')
      this.section.time_limit = get(this.section, 'time_limit', null)
      this.section.pause_exam_timer = get(
        this.section,
        'pause_exam_timer',
        false
      )
      /* eslint-enable vue/no-mutating-props */
      this.setSettingsData()
    },
    beforeDestroy() {
      EVENT.$off('update-settings' + this.section.id)
    },
    props: {
      section: {
        type: Object
      },
      itemMap: {
        type: Object
      },
      itemIdsByMeta: {
        type: Object
      },
      isExpanded: {
        type: Boolean
      },
      sectionIndex: {
        type: Number
      },
      hideArrow: {
        type: String
      },
      form: {
        type: Object
      }
    },
    methods: {
      addItem(id) {
        this.$emit('add-item', {
          sectionIndex: this.sectionIndex,
          id: id
        })
      },
      removeItem(index) {
        this.$emit('remove-item', {
          sectionIndex: this.sectionIndex,
          index: index
        })
      },
      moveItemUp(index) {
        this.$emit('move-item', {
          sectionIndex: this.sectionIndex,
          index: index,
          indexModifier: -1
        })
      },
      moveItemDown(index) {
        this.$emit('move-item', {
          sectionIndex: this.sectionIndex,
          index: index,
          indexModifier: 1
        })
      },
      isFirstItem(index) {
        if (index === 0) {
          return true
        }
        return false
      },
      isLastItem(index) {
        if (index === this.poolItems.length - 1) {
          return true
        }
        return false
      },
      changeItemPoolDefinition(value) {
        if (value === 'Manually add items to a list') {
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'item_ids',
            value: []
          })
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'item_pool_definition',
            value: 'manual'
          })
        }
        if (value === 'Non-deleted items in the project') {
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'item_ids',
            value: this.nonDeletedItemIds
          })
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'item_pool_definition',
            value: 'non_deleted'
          })
        }
      },
      itemOrderChanged(newValue) {
        if (newValue === 'Random') {
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'order',
            value: 'random'
          })
        } else {
          this.$emit('set-section-prop', {
            sectionIndex: this.sectionIndex,
            key: 'order',
            value: 'in_order'
          })
        }
      },
      moveSection(direction) {
        this.$emit('move-section', {
          direction: direction,
          currIndex: this.sectionIndex
        })
      },
      toggleSection() {
        this.$emit('toggle-section', this.sectionIndex)
      },
      openModal(modal) {
        this.$emit('open-modal', modal, this.sectionIndex)
      },
      orderValue(order) {
        return order === 'in_order' ? 'In Order' : 'Random'
      },
      sectionTimeLimitValue(timeLimit) {
        return timeLimit ? timeLimit : 'Unlimited'
      },
      pauseExamTimerValue(pauseExamTimer) {
        return pauseExamTimer ? 'Enabled' : 'Disabled'
      },
      setSettingsData() {
        const section = this.section

        const settingsData = [
          { setting: 'Name', value: section.name },
          { setting: 'Instructions', value: section.instructions },
          { setting: 'Item order', value: this.orderValue(section.order) },
          { setting: 'Number of items presented per page', value: section.items_per_page },
          { setting: 'Target difficulty', value: section.target_difficulty },
          { setting: 'Section time limit (seconds)', value: this.sectionTimeLimitValue(section.time_limit) },
          { setting: 'Pause the exam timer while examinees are in this section', value: this.pauseExamTimerValue(section.pause_exam_timer) }
        ]

        if (section.items_to_select) {
          settingsData.splice(2, 0, { setting: 'Pull this many items at random', value: section.items_to_select })
        }

        this.settingsData = settingsData
      },
      onOpenModal(modal) {
        this.$emit('open-modal', modal, this.sectionIndex)
      },
    },
    computed: {
      dropdownHeader() {
        if (!this.section.name) {
          return `${this.sectionIndex + 1}.`
        } else {
          return `${this.sectionIndex + 1}. ${this.section.name}`
        }
      },
      averageDifficulty() {
        if (this.poolItems.length > 0) {
          return (
            this.poolItems
              .map(item => item.d)
              .reduce(function(accumulator, difficulty) {
                return accumulator + difficulty
              }) / this.poolItems.length
          ).toFixed(2)
        } else {
          return (0).toFixed(2)
        }
      },
      isManual() {
        if (this.section.item_pool_definition === 'manual') {
          return true
        }
        return false
      },
      poolItems() {
        const list = []
        for (const id of this.section.item_ids) {
          list.push({ id: id, ...this.itemMap[id] })
        }
        return list
      },
      allItems() {
        let itemList = []
        Object.entries(this.itemMap).forEach(([key, value]) => {
          itemList.push({ id: key, ...value })
        })
        return itemList
      },
      nonDeletedItemIds() {
        let nonDeletedItems = []
        for (const item of this.allItems) {
          if (item.del === false) {
            nonDeletedItems.push(item.id)
          }
        }
        return nonDeletedItems
      },
      sectionItemCount() {
        let count = this.section.item_ids.length

        if (this.section.selection_rules) {
          for (const rule of this.section.selection_rules) {
            let amountToAdd = 0

            if (rule.num_items) {
              amountToAdd = rule.num_items
            } else if (rule.count) {
              amountToAdd = rule.count
            }

            count += amountToAdd
          }
        }

        return `${ count } Item${ count !== 1 ? 's' : '' }`
      }
    },
    filters: {
      formatPoolDef(defValue) {
        if (defValue === 'manual') {
          return 'Manually add items to a list'
        } else if (defValue === 'non_deleted') {
          return 'Non-deleted items in the project'
        } else {
          return ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pools-top-left {
    background: #eee;
    text-align: center;
  }
  .pools-top-right {
    -webkit-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    background: #ccc;
    text-align: center;
  }
  .pools-left,
  .pools-right {
    max-height: 500px;
    overflow-y: auto;
    flex: 1;
    font-size: 0.8em;
    border-top: 1px solid #ccc;
    button {
      font-size: 0.7em;
    }
  }
  .pools-left {
    background: #fff;
  }
  .pools-right {
    -webkit-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    max-height: 500px;
    overflow-y: auto;
    background: #eee;
  }
  .tables {
    border: 1px solid #ccc;
  }
  .preview {
      flex: 1;
      font-size: 0.8em;
      background: #fff;
  }
</style>
