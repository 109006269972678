<template>
    <div>
        <b-button @click="getSurveys" :disabled="loading || shouldDisable('view_surveys')" class="mr-2" variant="white">
            <b-spinner v-if="loading" small />

            Surveys
        </b-button>

        <b-modal @hidden="onModalHidden" id="surveys-modal" size="xl" title="Surveys" no-fade>
            <b-card v-for="(survey, index) in editableSurveys" :key="survey.id" class="mb-2" no-body>
                <b-card-header class="p-1">
                    <b-button :disabled="shouldDisable('edit_surveys')" @click="setActiveSurvey(survey.id)" class="text-left button-header" variant="primary" size="lg" block>
                        {{ survey.name }}
                    </b-button>
                </b-card-header>

                <b-card-body v-if="showSurvey(survey.id)">
                    <b-form-group label="Name" label-cols-lg="3" label-cols-sm="4">
                        <b-form-input v-model="survey.name" />
                    </b-form-group>

                    <b-form-group label="Instructions" label-cols-lg="3" label-cols-sm="4">
                        <b-form-textarea v-model="survey.instructions" max-rows="99" no-resize />
                    </b-form-group>

                    <b-form-group label="Time Limit (Seconds)" label-cols-lg="3" label-cols-sm="4">
                        <b-form-input v-model.number="survey.time_limit" type="number" min="0" step="1" />
                    </b-form-group>

                    <ItemPool
                        :item-map="itemMap"
                        :section="survey"
                        :section-index="index"
                        edit
                    />

                    <div class="d-flex justify-content-end mt-2">
                        <b-button :disabled="shouldDisable('edit_surveys')" @click="deleteSurvey(index)" variant="link">
                            Delete this survey
                        </b-button>
                    </div>
                </b-card-body>
            </b-card>

            <template #modal-footer="{ cancel }">
                <div class="w-100 d-flex justify-content-between">
                    <b-button :disabled="shouldDisable('edit_surveys')" @click="addSurvey" variant="primary-light">
                        Add Survey
                    </b-button>

                    <div>
                        <b-button @click="cancel" variant="white" class="mr-2">
                            Cancel
                        </b-button>

                        <b-button @click="beforeSave" :disabled="shouldDisable('edit_surveys') || saving" variant="secondary">
                            <b-spinner v-if="saving" small />

                            Save
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { arrayEquality, deepCopy } from '../../utils/misc.js'
import { EVENT } from '../../utils/event-bus.js'
import { HTTP } from '../../utils/requests.js'
import { SEI_API_BASE } from '../../utils/constants.js'
import { SESSION } from '../../utils/session.js'

import { v4 as uuidv4 } from 'uuid'

import ItemPool from './ItemPool.vue'

async function getSurveysRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/surveys`

        const response = await HTTP.get(url)

        return { data: response.data.results }
    } catch (error) {
        return { error }
    }
}

async function getItemMapRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/items/lookup`

        const response = await HTTP.get(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function saveNewSurveyRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/surveys`

        const response = await HTTP.post(url, payload)

        return { data: { ...response.data, originalId: payload.id } }
    } catch (error) {
        return { error }
    }
}

async function saveSurveyRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/surveys/${ payload.id }`

        const response = await HTTP.put(url, payload)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function deleteSurveyRequest (surveyId) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/surveys/${ surveyId }`

        await HTTP.delete(url)

        return { data: { deleted: surveyId } }
    } catch (error) {
        return { error }
    }
}

function checkForChanges (before, after) {
    let changed = false

    for (const property of ['name', 'instructions', 'time_limit']) {
        if (before[property] !== after[property]) changed = true
    }

    if (!arrayEquality(before.item_ids, after.item_ids)) changed = true

    return changed
}

export default {
    name: 'Surveys',
    components: {
        ItemPool
    },
    data () {
        return {
            editableSurveys: [],
            itemMap: {},
            loading: false,
            saving: false,
            loaded: false,
            active: null
        }
    },
    created () {
        EVENT.$on('get-surveys', (stepId) => this.getSurveys(false, stepId))

        EVENT.$on('view-survey', this.viewSurvey)
    },
    beforeDestroy () {
        EVENT.$off('get-surveys')

        EVENT.$off('view-survey')
    },
    methods: {
        async getSurveys (showModal, stepId) {
            if (!this.loaded) {
                this.$emit('loading-surveys')

                this.loading = true

                const tasks = [ getSurveysRequest(), getItemMapRequest() ]

                const [ surveyResponse, itemMapResponse ] = await Promise.all(tasks)

                this.loading = false

                if (surveyResponse.error || itemMapResponse.error) {
                    const alertData = {
                        variant: 'danger',
                        message: 'Failed to load surveys.'
                    }

                    this.emitSurveys(true)

                    return EVENT.alert(alertData)
                }

                this.surveys = surveyResponse.data

                this.itemMap = itemMapResponse.data

                this.loaded = true

                this.emitSurveys(false, stepId)
            }

            this.editableSurveys = deepCopy(this.surveys)

            if (showModal) {
                this.$bvModal.show('surveys-modal')
            }
        },
        beforeSave () {
            const newSurveys = []

            const updatedSurveys = []

            const deletedSurveys = []

            for (const survey of this.editableSurveys) {
                if (survey.isNew) newSurveys.push(survey)
            }

            for (const survey of this.surveys) {
                const index = this.editableSurveys.findIndex(s => s.id === survey.id)

                if (index === -1) {
                    deletedSurveys.push(survey.id)

                    continue
                }

                const editableSurvey = this.editableSurveys[index]

                const surveyChanged = checkForChanges(survey, editableSurvey)

                if (surveyChanged) updatedSurveys.push(editableSurvey)
            }

            const requests = []

            for (const survey of newSurveys) {
                const request = saveNewSurveyRequest(survey)

                requests.push(request)
            }

            for (const survey of updatedSurveys) {
                const request = saveSurveyRequest(survey)

                requests.push(request)
            }

            for (const surveyId of deletedSurveys) {
                const request = deleteSurveyRequest(surveyId)

                requests.push(request)
            }

            this.saveSurveys(requests)
        },
        async saveSurveys (requests) {
            this.saving = true

            const responses = await Promise.all(requests)

            this.saving = false

            let error = false

            for (const response of responses) {
                if (response.error) {
                    error = true

                    continue
                }

                if (response.data.deleted) {
                    const index = this.surveys.findIndex(survey => survey.id === response.data.deleted)

                    this.surveys.splice(index, 1)

                    continue
                }

                if (response.data.originalId) {
                    const index = this.editableSurveys.findIndex(survey => survey.id === response.data.originalId)

                    if (this.active === response.data.originalId) this.active = response.data.id

                    delete response.data.originalId

                    this.editableSurveys.splice(index, 1, response.data)

                    const copy = deepCopy(response.data)

                    this.surveys.push(copy)

                    continue
                }

                const index = this.surveys.findIndex(survey => survey.id === response.data.id)

                this.surveys.splice(index, 1, response.data)
            }

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to save changes to surveys.'
                }

                EVENT.alert(alertData)
            }

            this.emitSurveys()
        },
        addSurvey () {
            const survey = {
                name: '',
                instructions: '',
                time_limit: null,
                item_ids: [],
                id: uuidv4(),
                isNew: true
            }

            this.editableSurveys.push(survey)

            this.active = survey.id
        },
        deleteSurvey (index) {
            this.editableSurveys.splice(index, 1)
        },
        viewSurvey (surveyId) {
            this.active = surveyId

            this.$bvModal.show('surveys-modal')
        },
        setActiveSurvey (surveyId) {
            if (this.active === surveyId) {
                return this.active = null
            }

            this.active = surveyId
        },
        showSurvey (surveyId) {
            return this.active === surveyId
        },
        onModalHidden () {
            this.active = null
        },
        emitSurveys (error, stepId) {
            this.$emit('surveys', this.surveys, error, stepId)
        },
        shouldDisable(neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        }
    }
}
</script>

<style lang="scss" scoped>
.button-header {
    min-height: 48px;
}
</style>
