<template>
  <b-row class="border mt-1">
    <b-col>
      <b-table :items="rows" hover responsive small striped>
        <template v-slot:cell(option)="row">
          <div v-markdown="row.item.option"></div>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Matching',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.createTableData()
    },
    data() {
      return {
        rows: []
      }
    },
    methods: {
      createTableData() {
        for (const [index, option] of this.item.for_exam.options.entries()) {
          const row = {
            option,
            response: this.getChosenMatch(index)
          }
          this.rows.push(row)
        }
      },
      getChosenMatch(index) {
        try {
          return this.item.for_exam.matches[this.item.for_exam.response[index]]
        } catch (error) {
          return 'No Response'
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
