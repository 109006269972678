<template>
  <div>
    <ProjectsPage :user="myApp" is-app />
  </div>
</template>

<script>
import ProjectsPage from '../ProjectsPage.vue'

export default {
  name: 'Projects',
  components: {
    ProjectsPage
  },
  props: {
    myApp: {
      type: Object
    }
  }
}
</script>

<style>

</style>