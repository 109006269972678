<template>
  <div class="wrapper">
    <div class="message-box p-4">
      <b-row>
        <b-col md="3" class="text-center">
          <img src="../assets/scorpion-logo.svg" width="110" class="m-3" />
        </b-col>
        <b-col>
          <h3>
            Sorry, the page you're looking for does not exist or you don't have access to the page.
          </h3>
          <b-button to="/projects" variant="primary-light"
            >Return to Home</b-button
          >
          <br /><br />
          <div class="white-box p-3">
            <h4>Are you looking to perform a review?</h4>
            <p>
              Find reviews at:
              <a href="http://scorpionreviews.caveon.com"
                >scorpionreviews.caveon.com</a
              >
            </p>
            <b-button variant="primary-light">Go to Reviews</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Error'
  }
</script>

<style lang="scss" scoped>
  @import '../styles/variables.scss';

  .message-box {
    background: $scorpion-primary;
    color: #fff;
    width: 85%;
    margin: 20px auto;
    border-radius: 8px;
  }
  .white-box {
    color: $scorpion-primary;
    background: #fff;
    border-radius: 8px;
  }
</style>
