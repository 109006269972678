<template>
    <div>
        <b-container class="mt-3" fluid>
            <b-tabs>
                <b-tab v-if="showLaunchPads" title="Launchpads" class="pt-4 px-2">
                    <LaunchPads :project="project" />
                </b-tab>

                <b-tab title="LTI" class="pt-4 px-3">
                    <Lms v-if="showLMS" :project="project" :is-saving="isSaving" />
                </b-tab>

                <b-tab title="Bulk Proctor" class="pt-4 px-3">
                    <BulkProctor :isSaving="isSaving" :project="project" :shouldDisable="shouldDisable(['edit_exam_settings', 'edit_exam_meta'])" />
                </b-tab>
            </b-tabs>
        </b-container>
    </div>
</template>

<script>
import LaunchPads from './LaunchPads.vue'
import Lms from './Lms.vue'
import BulkProctor from './BulkProctor.vue'

import { SESSION } from '../../utils/session'

export default {
    name: 'LaunchOptions',
    components: {
        LaunchPads,
        Lms,
        BulkProctor
    },
    props: {
        project: {
            type: Object
        },
        isSaving: {
            type: Boolean
        }
    },
    data() {
        return {
            showLMS: false,
            showLaunchPads: false
        }
    },
    created() {
        this.showLMS = SESSION.hasPermissions('manage_integrations')
        this.showLaunchPads = SESSION.hasPermissions('view_exam_settings')
    },
    methods: {
        shouldDisable (neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
