<template>
  <div>
    <b-container class="mt-4" fluid>
      <div v-if="!isExporting">
        <b-form-group label="Export type" label-cols-lg="3" label-cols-md="4">
          <b-select
            :value="type"
            :options="exporterOptions"
            @change="onExporterChange"
          ></b-select>
        </b-form-group>
        <b-row v-if="!widgetUrl">
          <b-col>
            <div :key="field.value" class="mr-1" v-for="field in form()">
              <b-form-group>
                <b-row>
                  <b-col md="4" lg="3">
                    <label
                      v-if="
                        field.type !== 'bool' && field.type !== 'instructions'
                      "
                      >{{ field.text }}</label
                    >
                  </b-col>
                  <b-col>
                    <b-select
                      :options="customFieldOptions"
                      v-if="fieldIs(field, 'reference')"
                      v-model="filterData[field.value]"
                    ></b-select>

                    <b-form-checkbox
                      v-if="fieldIs(field, 'reference')"
                      v-model="filterData.extra.dividers[field.value]"
                      >Use dividers</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-if="fieldIs(field, 'bool')"
                      v-model="filterData[field.value]"
                      >{{ field.text }}</b-form-checkbox
                    >

                    <b-input
                      type="text"
                      v-if="fieldIs(field, 'text,number')"
                      v-model="filterData[field.value]"
                    ></b-input>

                    <b-input
                      type="date"
                      v-if="fieldIs(field, 'date')"
                      v-model="filterData[field.value]"
                    ></b-input>

                    <b-select
                      :options="deliveryStatuses"
                      multiple
                      v-if="fieldIs(field, 'delivery_statuses')"
                      v-model="filterData[field.value]"
                    ></b-select>

                    <b-form-checkbox
                      v-if="type === 'caveon_yaml'"
                      v-model="filterData.extra.encrypt"
                    >
                      Encrypt
                    </b-form-checkbox>
                    <b-form-input
                      v-if="filterData.extra.encrypt"
                      required
                      id="password-input"
                      type="password"
                      placeholder="Password"
                      v-model="filterData.extra.password"
                    ></b-form-input>

                    <ItemFilters
                      :project="project"
                      @items-changed="setItemIds"
                      v-if="fieldIs(field, 'item_ids')"
                    />

                    <p v-if="fieldIs(field, 'instructions')">
                      <i>Note: {{ field.text }}</i>
                    </p>

                    <b-select
                      :options="languageOptions"
                      v-if="fieldIs(field, 'language_id')"
                      v-model="filterData[field.value]"
                    ></b-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="widgetUrl">
        <div>
          <b-button @click="clearForm" variant="dark">Start over</b-button>
        </div>
        <b-embed
          class="iframe-box"
          :src="widgetUrl"
          aspect="16by9"
          type="iframe"
        >
        </b-embed>
      </div>

      <div v-if="isExporting">
        <h4>{{ workingOn }} {{ job.status }}</h4>
        <b-form-group v-if="job.result">
          <a v-if="job.result.url" :href="job.result.url"
            >Click here to download</a
          >
          <p v-else>No results</p>
        </b-form-group>
        <b-form-group class="mt-3">
          <b-button @click="clearForm" variant="dark">Start over</b-button>
        </b-form-group>
      </div>

      <b-row class="mt-1" v-if="!isExporting && !widgetUrl">
        <b-col offset-md="4" offset-lg="3">
          <b-button :disabled="!valid()" @click="export_" variant="secondary"
            >Run Export</b-button
          >
          <b-button @click="clearForm" variant="white" class="float-right"
            >Clear All Settings</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import { SESSION } from '../../utils/session'
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { iframeUrl } from '../../utils/misc'
  import ItemFilters from './ItemFilters.vue'
  import get from 'lodash.get'
  import { Language } from '../../utils/language'


  const MAX_ATTEMPTS = 300
  const POLLING_INTERVAL_MS = 1000
  const DELIVERY_STATUSES = [
    { value: 'fresh', text: 'Fresh' },
    { value: 'in_progress', text: 'In progress' },
    { value: 'manual_scoring', text: 'Manual scoring' },
    { value: 'complete', text: 'Complete' },
    { value: 'suspended', text: 'Suspended' },
    { value: 'abandoned', text: 'Abandoned' }
  ]
  const GET_CLEAN_DATA = () => {
    return {
      statuses: [],
      item_ids: [],
      extra: { dividers: {}, encrypt: false, password: '' },
      language_id: null
    }
  }
  async function poll(jobId) {
    const url = `${SEI_API_BASE}/jobs/${jobId}`
    const response = await HTTP.get(url)

    return response.data
  }

  async function export_(examId, type, data) {
    const url = `${SEI_API_BASE}/exams/${examId}/export_v2`
    const response = await HTTP.post(url, { type, data })
    const jobId = response.data.job_id

    if (!jobId) {
      throw new Error('job creation failed')
    }

    return { jobId }
  }

  export default {
    name: 'Export',
    components: {
      ItemFilters
    },
    props: {
      project: {
        type: Object
      }
    },
    data() {
      return {
        interval: null,
        polling: false,
        intervalCount: 0,
        deliveryStatuses: DELIVERY_STATUSES,
        filterData: {},
        exporterLookup: {},
        type: null,
        widgetUrl: null,
        isExporting: false,
        exporterOptions: [{ value: null, text: 'Select', disabled: true }],
        customFieldOptions: [{ value: null, text: 'Select', disabled: true }],
        job: {},
        languages: []
      }
    },
    created() {
      this.filterData = GET_CLEAN_DATA()
      this.exporterLookup = this.project.exporters.reduce((acc, exporter) => {
        acc[exporter.value] = exporter

        return acc
      }, {})

      const exportWidgets = []
      for (const integration of this.project.integrations) {
        if (integration.app.app_settings.content.export_widget) {
          this.exporterLookup[integration.id] = integration
          exportWidgets.push({
            text: integration.app.first_name,
            value: integration.id
          })
        }
      }

      this.exporterOptions = [
        ...this.exporterOptions,
        ...this.project.exporters,
        ...exportWidgets
      ]

      const hasCustomFields =
        this.project.meta.scorpion &&
        this.project.meta.scorpion.custom_field_order &&
        this.project.meta.scorpion.custom_field_order.length

      if (hasCustomFields) {
        const customFields = this.project.meta.scorpion.custom_field_order
          .map(fieldId => this.project.meta.scorpion.custom[fieldId])
          .filter(field => field.type.startsWith('select_'))
          .filter(field => !field.by_option)
          .map(field => {
            return {
              text: field.name,
              value: field.id
            }
          })

        this.customFieldOptions = [...this.customFieldOptions, ...customFields]
      }

      this.initFilterData()
      this.FetchLanguages()
    },
    methods: {
      initPolling(jobId) {
        this.polling = true
        this.interval = setInterval(async () => {
          try {
            if (this.intervalCount >= MAX_ATTEMPTS) {
              throw new Error('max attempts reached')
            }

            this.intervalCount++
            const response = await poll(jobId)

            if (response.status === 'failed') {
              throw new Error(response.status)
            }

            this.job = response

            if (response.result) {
              this.clearPolling()
            }
          } catch (e) {
            this.job = {
              status: 'failed'
            }
            this.clearPolling()
          }
        }, POLLING_INTERVAL_MS)
      },
      clearPolling() {
        this.polling = false
        clearInterval(this.interval)
      },
      async export_() {
        this.isExporting = true

        if (!this.type) {
          return
        }

        try {
          const { jobId } = await export_(
            this.project.id,
            this.type,
            this.filterData
          )

          this.initPolling(jobId)
        } catch (e) {
          this.job = {
            status: 'failed'
          }
          this.clearPolling()
        }
      },
      clearForm() {
        this.clearPolling()
        this.intervalCount = 0
        this.filterData = GET_CLEAN_DATA()
        this.type = null
        this.isExporting = false
        this.job = {}
        this.widgetUrl = null
      },
      form() {
        return (
          this.exporterLookup[this.type] &&
          this.exporterLookup[this.type]['form']
        )
      },
      setItemIds(itemIds) {
        this.filterData.item_ids = itemIds
      },
      valid() {
        if (this.filterData.extra.encrypt && this.filterData.extra.password.length < 1) {
          return false
        }

        return this.type
      },
      fieldIs(field, typeStr) {
        const types = typeStr.split(',')

        return types.includes(field.type)
      },
      onExporterChange(value) {
        if (this.exporterLookup[value].app) {
          const integration = this.exporterLookup[value]
          let replaceWith = '.'
          if (SESSION.env) replaceWith = `.${SESSION.env}.`
          this.widgetUrl = iframeUrl(
            integration.app.app_settings.content.export_widget.replace('.env.', replaceWith),
            integration.shared_token,
            integration.exam_id
          )
        } else {
          this.widgetUrl = null
        }

        this.type = value
      },
      initFilterData() {
        const defaultFilterDataSet = this.initDefaultFilterData()

        if (defaultFilterDataSet) this.initQueryFilterData()
      },
      initDefaultFilterData() {
        for (const type of Object.keys(this.exporterLookup)) {
          const form = this.exporterLookup[type]['form']

          if (!form) continue

          for (const field of form) {
            if (this.fieldIs(field, 'reference')) {
              this.filterData[field.value] = null
            }

            if (this.fieldIs(field, 'language_id')) {
              this.filterData[field.value] = null
            }
          }
        }

        return 1
      },
      initQueryFilterData() {
        if (this.$route.query.type) {
          this.type = this.$route.query.type
        }

        const form = this.form()

        if (form) {
          const fieldValues = form.map(field => field.value)

          for (const value of fieldValues) {
            const queryValue = this.$route.query[value]

            if (queryValue) {
              this.filterData[value] = queryValue
            }
          }
        }
      },
      async FetchLanguages() {
        const response = await Language.FetchAll(this.project.id)

        if (response.error) {
          return
        }

        this.languages = [...response.results]
      }
    },
    computed: {
      workingOn() {
        return get(this.job, 'meta.working_on', 'Working...')
      },
      languageOptions() {
        return [{text: 'Default language', value: null}].concat(this.languages.map(language => {
          return {
            text: language.name,
            value: language.id
          }
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .iframe-box {
    min-height: 80vh;
  }
  .embed-responsive-16by9 {
    min-height: 80vh;
    height: 100%;
  }
</style>
