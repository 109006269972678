<template>
  <b-tabs content-class="p-3 bg-white rounded">
    <!-- <b-tab title="Get Started">
      <div v-if="!loading">
        <div v-if="!getStartedError" v-markdown="getStartedContent"></div>
        <b-alert
          class="m-0 d-flex justify-content-between align-items-center"
          show
          v-else
          variant="danger"
        >
          Failed to load get started information.
          <b-button @click="getContent()" size="sm" variant="danger"
            >Reload</b-button
          >
        </b-alert>
      </div>
      <Spinner v-else />
    </b-tab> -->
    <b-tab :title="pageTitle">
      <div v-if="!loading">
        <div v-if="!pageError" v-markdown="pageContent"></div>
        <b-alert
          class="m-0 d-flex justify-content-between align-items-center"
          show
          v-else
          variant="danger"
        >
          Failed to load page information.
          <b-button @click="getContent()" size="sm" variant="danger"
            >Reload</b-button
          >
        </b-alert>
      </div>
      <Spinner v-else />
      <div class="text-right" style="color: #ddd; font-size: .75em;">
        <small>{{ helpPage }}-pagehelp</small>
      </div>
    </b-tab>
    <b-tab title="Support">
      <div v-if="!loading">
        <div v-if="!supportError" v-markdown="supportContent"></div>
        <b-alert
          class="m-0 d-flex justify-content-between align-items-center"
          show
          v-else
          variant="danger"
        >
          Failed to load support information.
          <b-button @click="getContent()" size="sm" variant="danger"
            >Reload</b-button
          >
        </b-alert>
      </div>
      <Spinner v-else />
    </b-tab>
  </b-tabs>
</template>

<script>
  import { HTTP } from '../utils/requests'

  import Spinner from './Spinner'

  async function contentRequest(key) {
    try {
      const url = `https://caveon-content.s3-us-west-2.amazonaws.com/${key}`

      const options = { transformRequest: [(data, headers) => {
        delete headers.Authorization

        return data
      }]}

      const response = await HTTP.get(url, options)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'CaveonContent',
    components: {
      Spinner
    },
    created() {
      this.getContent()
    },
    watch: {
      helpPage() {
        this.getContent()
      }
    },
    data() {
      return {
        pageContent: '',
        getStartedContent: '',
        supportContent: '',
        getStartedError: false,
        pageError: false,
        supportError: false,
        loading: true
      }
    },
    methods: {
      async getContent() {
        this.loading = true
        this.getStartedError = false
        this.pageError = false
        this.supportError = false

        const pageContentKey = `${this.helpPage}-pagehelp.md`
        const getStartedContentKey = `globalgetstarted.md`
        const supportContentKey = `globalsupport.md`

        const [page, getstarted, support] = await Promise.all([
          contentRequest(pageContentKey),
          contentRequest(getStartedContentKey),
          contentRequest(supportContentKey)
        ])

        if (page.data) {
          this.pageContent = page.data
        } else {
          this.pageError = true
        }

        if (getstarted.data) {
          this.getStartedContent = getstarted.data
        } else {
          this.getStartedError = true
        }

        if (support.data) {
          this.supportContent = support.data
        } else {
          this.supportError = true
        }

        this.loading = false
      }
    },
    props: {
      helpPage: {
        type: String
      },
      pageTitle: {
        type: String
      }
    }
  }
</script>
