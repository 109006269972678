export const SEI_API_BASE = '/api'
export const SEI_API_LOGIN = '/caveonid/login'
export const SEI_API_REGISTER = '/caveonid/register'
export const STATUS_OPTIONS = [
  {
    value: 'active',
    text: 'Active'
  },
  {
    value: 'archived',
    text: 'Archived'
  }
]
export const URL_REGEX = new RegExp(
  /^(https:\/\/www\.|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
)

export const MAX_NUM_VARIATIONS = 1000

export const DEFAULT_SECURITY_OPTIONS = {
  start_paused: true,
  suspicious_event_threshold: 0,
  block_screen: true,
  copy_paste: true,
  proctor_setting_overrides: false,
  fresh_token_each_launch: true
}

export const DEFAULT_ITEM_SETTINGS = {
  multiple_choice: {
    points: 1,
    key: [0, 0, 0, 0],
    weights: [0, 0, 0, 0],
    scoring: 'default',
    presentation_order: 'random',
    keys_to_present: null,
    distractors_to_present: null,
    select_all_that_apply: false,
    type: 'multiple_choice',
    cutscore_min: null
  },
  true_false: {
    points: 1,
    key: 1,
    scoring: 'default',
    type: 'true_false'
  },
  domc: {
    points: 1,
    key: [0, 0, 0, 0],
    scoring: 'default',
    presentation_order: 'random',
    keys_to_present: null,
    distractors_to_present: null,
    correct_keys: null,
    correct_distractors: null,
    missed_keys: 1,
    missed_distractors: 1,
    extra_option_prob: 0.5,
    type: 'domc'
  },
  matching: {
    points: 1,
    key: [null, null, null, null],
    weights: [0, 0, 0, 0],
    match_max_uses: [],
    scoring: 'default',
    presentation_order: 'random',
    type: 'matching',
    cutscore_min: null
  },
  build_list: {
    points: 1,
    key: [null, null, null, null],
    weights: [0, 0, 0, 0],
    presentation_order: 'random',
    scoring: 'default',
    type: 'build_list'
  },
  short_answer: {
    points: 1,
    key: [0, 0, 0, 0],
    weights: [0, 0, 0, 0],
    scoring: 'default',
    method: 'exact',
    type: 'short_answer'
  },
  hotspot: {
    points: 1,
    scoring: 'default',
    key: [],
    weights: [],
    spots: [],
    num_selections: 0,
    type: 'hotspot',
    cutscore_min: null
  },
  essay: {
    points: 1,
    scoring: 'default',
    type: 'essay'
  },
  likert: {
    points: 1,
    scoring: 'default',
    option_order: 'desc',
    type: 'likert',
    key: null
  },
  scale: {
    points: 1,
    key: null,
    method: null,
    scoring: 'default',
    bounds: [1, 10],
    type: 'scale'
  },
  external: {
    points: 1,
    key: [0, 0, 0, 0],
    scoring: 'default',
    type: 'external'
  },
  smart: {
    points: 1,
    type: 'smart'
  },
  fill_in_the_blanks: {
    points: 1, 
    type: 'fill_in_the_blanks',
    scoring: 'default',
  }
}

export const DEFAULT_ITEM_SETTINGS_FOR_BULK_UPDATE = {
  multiple_choice: {
    points: 1,
    scoring: 'default',
    presentation_order: 'random',
    keys_to_present: null,
    distractors_to_present: null,
    select_all_that_apply: false,
    selection_limit: null,
    cutscore_min: null
  },
  true_false: {
    points: 1,
    scoring: 'default'
  },
  domc: {
    points: 1,
    scoring: 'default',
    presentation_order: 'random',
    keys_to_present: null,
    distractors_to_present: null,
    correct_keys: null,
    correct_distractors: null,
    missed_keys: 1,
    missed_distractors: 1,
    extra_option_prob: 0.5
  },
  matching: {
    points: 1,
    scoring: 'default',
    presentation_order: 'random',
    cutscore_min: null
  },
  build_list: {
    points: 1,
    scoring: 'default',
    presentation_order: 'random'
  },
  short_answer: {
    points: 1,
    scoring: 'default',
    method: 'exact'
  },
  hotspot: {
    points: 1,
    scoring: 'default',
    num_selections: 0,
    cutscore_min: null
  },
  essay: {
    points: 1,
    scoring: 'default'
  },
  likert: {
    points: 1,
    scoring: 'default',
    option_order: 'desc'
  },
  scale: {
    points: 1,
    scoring: 'default',
    method: null,
    key: null,
    bounds: [1, 10]
  },
  external: {
    points: 1,
    scoring: 'default'
  },
  smart: {
    points: 1
  },
  fill_in_the_blanks: {
    points: 1, 
    scoring: 'default'
  }
}

export const DEFAULT_ITEM_CONTENT = {
  build_list: {
    stem: '',
    shared: [],
    widgets: [],
    options: ['', '', '', '']
  },
  domc: {
    stem: '',
    shared: [],
    widgets: [],
    options: ['', '', '', '']
  },
  external: {
    stem: '',
    shared: [],
    widgets: [],
    options: ['', '', '', ''],
    url: ''
  },
  hotspot: {
    stem: '',
    shared: [],
    widgets: [],
    url: ''
  },
  essay: {
    stem: '',
    shared: [],
    widgets: []
  },
  likert: {
    stem: '',
    shared: [],
    widgets: []
  },
  true_false: {
    stem: '',
    shared: [],
    widgets: []
  },
  matching: {
    stem: '',
    shared: [],
    widgets: [],
    matches: [],
    options: ['', '', '', '']
  },
  multiple_choice: {
    stem: '',
    shared: [],
    widgets: [],
    options: ['', '', '', '']
  },
  scale: {
    stem: '',
    shared: [],
    widgets: [],
    labels: ['', '']
  },
  short_answer: {
    stem: '',
    shared: [],
    widgets: [],
    options: ['', '', '', '']
  },
  smart: {
    shared: [],
    widgets: []
  },
  fill_in_the_blanks: {
    shared: [],
    segments: [],
    widgets: []
  }
}

export const DEFAULT_ROUTE_NAME = 'projectItems'

export const NOTIFICATION_TRIGGER_OPTIONS = [
  {
    text: 'Select',
    value: '',
    disabled: true
  },
  {
    label: 'Delivery',
    options: [
      {
        text: 'Delivery Created',
        value: 'delivery_created'
      },
      {
        text: 'Delivery Started',
        value: 'delivery_started'
      },
      {
        text: 'Delivery Submitted',
        value: 'delivery_submitted'
      },
      {
        text: 'Delivery Rescored',
        value: 'delivery_rescored'
      },
      {
        text: 'Delivery Completed',
        value: 'delivery_completed'
      },
      {
        text: 'Delivery Suspended',
        value: 'delivery_suspended'
      }
    ]
  },
  {
    label: 'App',
    options: [
      {
        text: 'App Permissions Changed',
        value: 'app_permissions_changed'
      }
    ]
  }
]

export const NOTIFICATION_TYPE_OPTIONS = [
  {
    text: 'Select',
    value: '',
    disabled: true
  },
  {
    text: 'Email',
    value: 'email'
  },
  {
    text: 'SMS',
    value: 'sms'
  }
]

export const CHART_COLORS = [
  '#3490c0',
  '#7cbee1',
  '#73c034',
  '#a3e077',
  '#ceba30',
  '#d6cb7f',
  '#da823b',
  '#ebae7c',
  '#b93939',
  '#d17d7d',
  '#885cb1',
  '#b998d8',
  '#7a6b57',
  '#a7957d',
  '#708988'
]

export const CHART_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
]

export const DEFAULT_CONFIGURATION = {
  name: '',
  settings: {
    security_options: {}
  }
}

export const DEFAULT_EXAM_COLORS = {
  navigation_color: '#3C6683',
  button_color: '#5384A3',
  background_color: '#DEDEDE'
}

export const IS_UPGRADE_TO_PREMIUM_ENABLED = false

export const ALL_PERMISSIONS = [
  'view_exam_settings',
  'edit_exam_settings',
  'edit_exam_meta',
  'delete_exam',
  'manage_admins',
  'view_activity_logs',
  'manage_users',
  'manage_integrations',
  'create_deliveries',
  'view_deliveries',
  'edit_deliveries',
  'edit_delivery_meta',
  'delete_deliveries',
  'run_deliveries',
  'proctor_deliveries',
  'edit_responses',
  'export_data',
  'export_content',
  'manage_examinees',
  'create_items',
  'view_items',
  'edit_items',
  'edit_item_meta',
  'delete_items',
  'view_forms',
  'manage_forms',
  'view_surveys',
  'edit_surveys',
  'view_agreements',
  'edit_agreements',
  'manage_translations'
]

export const ROLE_TYPES = [
  {
    title: 'Administrator',
    users: [],
    type: 'admin',
    description: 'Administrators have full access to the content of this project.',
    isAdd: true,
    isDisableDeleteBtn: false
  },
  {
    title: 'Content',
    users: [],
    type: 'content',
    description: '',
    isAdd: false,
    isDisableDeleteBtn: false
  },
  {
    title: 'Delivery',
    users: [],
    type: 'delivery',
    description: '',
    isAdd: false,
    isDisableDeleteBtn: false
  },
  {
    title: 'Item Worker',
    users: [],
    type: 'workshop',
    description: 'Item workers can view, create, edit, and delete Items. All the other sections are hidden.',
    isAdd: true,
    isDisableDeleteBtn: false
  },
  {
    title: 'Delivery Worker',
    users: [],
    type: 'delivery_worker',
    description: 'Delivery workers can view and create deliveries. All the other sections are hidden.',
    isAdd: true,
    isDisableDeleteBtn: false
  },
  {
    title: 'Proctor',
    users: [],
    type: 'proctor',
    description: 'Proctors can view active deliveries in exam watch.',
    isAdd: true,
    isDisableDeleteBtn: false
  },
  {
    title: 'Manager',
    users: [],
    type: 'manager',
    description: '',
    isAdd: false,
    isDisableDeleteBtn: false
  },
  {
    title: 'Viewer',
    users: [],
    type: 'viewer',
    description: '',
    isAdd: false,
    isDisableDeleteBtn: false
  },{
    title: 'Translator',
    users: [],
    type: 'translator',
    description: 'User can view and translate exam content.',
    isAdd: true,
    isDisableDeleteBtn: false
  }
]

export const BUILTIN_LANGUAGES = ['English', 'Français', 'عربى', 'Español', '中国人', '日本', 'Português', 'Deutsch']

export const ITEM_TYPES = [
  { text: 'Build List', value: 'build_list' },
  { text: 'DOMC', value: 'domc' },
  { text: 'Essay', value: 'essay' },
  { text: 'External', value: 'external' },
  { text: 'Hotspot', value: 'hotspot' },
  { text: 'Likert', value: 'likert' },
  { text: 'Matching', value: 'matching' },
  { text: 'Multiple Choice', value: 'multiple_choice' },
  { text: 'Scale', value: 'scale' },
  { text: 'Short Answer', value: 'short_answer' },
  { text: 'Smart', value: 'smart' },
  { text: 'True False', value: 'true_false' },
  { text: 'Fill In the Blanks', value: 'fill_in_the_blanks' }
]

export const PROJECT_PAGES = [
  {
    route: 'projectItems',
    permissions: [ 'view_items' ],
    requireAllPermissions: true
  },
  {
    route: 'languagesHome',
    permissions: [ 'manage_translations' ],
    requireAllPermissions: true
  },
  {
    route: 'projectFlows',
    permissions: [ 'view_exam_settings' ],
    requireAllPermissions: true
  },
  {
    route: 'projectForms',
    permissions: [ 'manage_forms' ],
    requireAllPermissions: true
  },
  {
    route: 'projectLaunchOptions',
    permissions: [ 'view_exam_settings', 'manage_integrations' ],
    requireAllPermissions: false
  },
  {
    route: 'projectDeliveries',
    permissions: [ 'view_deliveries' ],
    requireAllPermissions: true
  },
  {
    route: 'projectImport',
    permissions: [ 'view_items', 'edit_items', 'create_items', 'delete_items' ],
    requireAllPermissions: true
  },
  {
    route: 'projectExport',
    permissions: [ 'export_data' ],
    requireAllPermissions: true
  },
  {
    route: 'projectSettings',
    permissions: [ 'view_exam_settings' ],
    requireAllPermissions: true
  },
  {
    route: 'projectAccess',
    permissions: [ 'manage_integrations', 'manage_users' ],
    requireAllPermissions: false
  },
  {
    route: 'projectActivityLogs',
    permissions: [ 'view_activity_logs' ],
    requireAllPermissions: true
  }
]

export const CONFIGURATION_KEYS = [
  'instructions',
  'proctor_code',
  'time_limit',
  'mode',
  'selected_languages',
  'default_language',
  'score_scale_lower',
  'score_scale_upper',
  'cutscores',
  'content_area_split_char',
  'pause_resume',
  'feedback_enabled',
  'exam_review_enabled',
  'calculator',
  'comments',
  'score_ranges',
  'score_report_content',
  'score_report_preface',
  'score_report_pass_message',
  'score_report_fail_message',
  'show_score',
  'show_total_score',
  'show_pass_fail',
  'show_cutscore',
  'show_print_button',
  'show_email_button',
  'show_score_scale',
  'show_content_area_score',
  'show_content_area_breakdown',
  'show_content_area_breakdown_range_text',
  'score_display_type',
  'feedback_hide_key',
  'adjust_time_after_disconnect'
]

export const CONFIGURATION_SECURITY_OPTION_KEYS = [
  'start_paused',
  'suspicious_event_threshold',
  'block_screen',
  'copy_paste',
  'proctor_setting_overrides',
  'fresh_token_each_launch',
  'allow_selection'
]

export const CONFIGURATION_ADAPTIVE_KEYS = [
  'min_items',
  'max_items',
  'max_error'
]

export const CONFIGURATION_LINEAR_KEYS = [
  'end_early',
  'mark_and_review',
  'enable_sections'
]

export const CONFIGURATION_MARK_AND_REVIEW_KEYS = [
  'prevent_section_mark_and_review'
]

export const CONFIGURATION_VALUES = {
  "instructions": "",
    "proctor_code": null,
    "security_options": {
        "start_paused": false,
        "suspicious_event_threshold": 0,
        "block_screen": true,
        "copy_paste": true,
        "proctor_setting_overrides": false,
        "fresh_token_each_launch": true,
        "allow_selection": false
    },
    "time_limit": 0,
    "mode": "linear",
    "selected_languages": [
        "English"
    ],
    "default_language": "English",
    "score_scale_lower": 0,
    "score_scale_upper": 100,
    "cutscores": [],
    "content_area_split_char": "",
    "pause_resume": false,
    "feedback_enabled": false,
    "exam_review_enabled": false,
    "calculator": false,
    "comments": true,
    "mark_and_review": true,
    "prevent_section_mark_and_review": false,
    "enable_sections": true,
    "end_early": false,
    "min_items": null,
    "max_items": null,
    "max_error": null,
    "score_ranges": [],
    "score_report_content": "",
    "score_report_preface": "",
    "score_report_pass_message": "",
    "score_report_fail_message": "",
    "show_score": true,
    "show_total_score": true,
    "show_pass_fail": false,
    "show_cutscore": false,
    "show_print_button": true,
    "show_email_button": true,
    "show_score_scale": false,
    "show_content_area_score": false,
    "show_content_area_breakdown": false,
    "show_content_area_breakdown_range_text": false,
    "score_display_type": "dec",
    "feedback_hide_key": false,
    "adjust_time_after_disconnect": true
}
