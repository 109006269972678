var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticStyle:{"backgroundColor":"white"},attrs:{"disabled":_vm.disabled || !_vm.isPremium,"options":_vm.getOptions(_vm.field),"reduce":function (option) { return option.value; },"required":_vm.field.required,"selectable":function (option) { return !option.disabled; },"taggable":_vm.field.extendable,"multiple":_vm.multiple,"data-value":_vm.fieldIsValid,"create-option":function (tag) { return ({ text: tag, value: tag }); },"label":"text","placeholder":"Not selected"},on:{"input":_vm.updateField},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
var option = ref.option;
var deselect = ref.deselect;
return [_c('span',{class:("vs__selected border " + (option.color ? 'pl-0' : ''))},[(option.color)?_c('div',{staticClass:"mr-1",style:({
            backgroundColor: option.color,
            width: '15px',
            height: '100%',
            borderRadius: '3px 0 0 3px'
          })}):_vm._e(),_vm._v(" "+_vm._s(option.text)+" "),_c('button',{staticClass:"vs__deselect no-focus",style:({ color: 'rgba(60, 60, 60, 0.5)' }),attrs:{"disabled":_vm.disabled || !_vm.isPremium,"type":"button"},on:{"click":function($event){$event.stopPropagation();return deselect(option)},"mousedown":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]}},{key:"option",fn:function(ref){
          var color = ref.color;
          var text = ref.text;
return [(color)?_c('div',{staticClass:"option p-1 d-inline-block",style:({ background: color })}):_vm._e(),_vm._v(" "+_vm._s(text)+" ")]}}]),model:{value:(_vm.values[_vm.field.id]),callback:function ($$v) {_vm.$set(_vm.values, _vm.field.id, $$v)},expression:"values[field.id]"}}),_vm._l((_vm.descriptions),function(description,index){return _c('div',{key:index,staticStyle:{"overflow-wrap":"break-word"}},[(description.text)?_c('small',[(_vm.multiple)?_c('span',[_vm._v(_vm._s(description.name)+": ")]):_vm._e(),_vm._v(" "+_vm._s(description.text)+" ")]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }