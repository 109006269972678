<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle.form-configurations-listing
        variant="primary"
        >Configurations ({{ configurations.length }})</b-button
      >
    </b-card-header>

    <b-collapse
      id="form-configurations-listing"
      :visible="expand"
      v-if="configurations.length"
    >
      <b-card-body>
        <h5>
          Parent Configuration
        </h5>

        <b-table
          :fields="fields"
          :items="parent"
          @row-clicked="editConfiguration"
          hover
          responsive
          small
          striped
        >
          <template v-slot:table-colgroup="scope">
            <col :key="field.key" v-for="field in scope.fields" />
          </template>
          <template v-slot:cell(button)="row">
            <b-button-group class="float-right">
              <b-button
                :disabled="shouldDisable"
                @click="cloneConfiguration(row.item)"
                size="sm"
                variant="white"
                class="float-right"
              >
                Clone
              </b-button>

              <b-button
                :disabled="shouldDisable"
                @click="editConfiguration(row.item)"
                size="sm"
                variant="white"
                class="float-right"
              >
                Edit
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <div v-if="children.length">
          <h5>
            Children
          </h5>
          <div class="mb-2">
            <small>Fields left blank in these configurations use the settings of the <i>parent configuration</i>.</small>
          </div>
          <b-table
            :fields="fields"
            :items="children"
            @row-clicked="editConfiguration"
            hover
            responsive
            small
            striped
          >
            <template v-slot:table-colgroup="scope">
              <col :key="field.key" v-for="field in scope.fields" />
            </template>
            <template v-slot:cell(button)="row">
              <b-button-group class="float-right">
                <b-button
                  :disabled="shouldDisable"
                  @click="cloneConfiguration(row.item)"
                  size="sm"
                  variant="white"
                  class="float-right"
                >
                  Clone
                </b-button>

                <b-button
                  :disabled="shouldDisable"
                  @click="editConfiguration(row.item)"
                  size="sm"
                  variant="white"
                  class="float-right"
                >
                  Edit
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'ConfigurationListing',
  props: {
    expand: {
      type: Boolean
    },
    configurations: {
      type: Array
    },
    shouldDisable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parent: [],
      children: [],
      fields: [
        { key: 'name', sortable: true },
        { key: 'button', label: '' }
      ]
    }
  },
  created () {
    for (const configuration of this.configurations) {
      if (configuration.is_default) {
        this.parent.push(configuration)
      } else {
        this.children.push(configuration)
      }
    }
  },
  methods: {
    cloneConfiguration (configuration) {
      if (this.shouldDisable) {
        return
      }

      this.$emit('edit-configuration', configuration, true)
    },
    editConfiguration(configuration) {
      if (this.shouldDisable) {
        return
      }
      this.$emit('edit-configuration', configuration)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
