<template>
  <div>
    <b-container class="mt-3" fluid>
      <h2>Delivery Counts</h2>
      <DashboardChart :chart-data="chartData" :options="options" />
    </b-container>
  </div>
</template>
<script>
  import DashboardChart from './DashboardChart'

  export default {
    name: 'OrganizationDashboard',
    components: {
      DashboardChart
    },
    created() {
      this.createChartData(this.org.dashboard)
    },
    props: {
      org: {
        type: Object
      }
    },
    data() {
      return {
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec'
        ],
        colors: [
          '#1395ba',
          '#0d3c55',
          '#a2b86c',
          '#ce4699',
          '#e1c903',
          '#e16501',
          '#bf3737',
          '#e2f5fc',
          '#4eb0bc',
          '#9457bb'
        ],
        chartData: {},
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    methods: {
      createChartData(dashboard) {
        const labels = []
        const datasets = []
        for (let i = 0; i < dashboard.months.length; i++) {
          const month = dashboard.months[i]
          const monthIdx = month[1] - 1
          labels.push(this.months[monthIdx])
        }
        for (let i = 0; i < dashboard.counts.length; i++) {
          const line = dashboard.counts[i]
          const dataset = {
            data: line.data,
            label: line.label,
            backgroundColor: this.colors[i],
            borderColor: this.colors[i],
            fill: false
          }
          datasets.push(dataset)
        }
        this.chartData = {
          labels,
          datasets
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
