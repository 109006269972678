<template>
  <div class="splash-page">
    <div class="wrapper">



        <div class="columnright">
          <span className={css.imgbackground}>
            <img src="../assets/scorpion-color2.png" alt="Scorpion Logo" width="380" />
          </span>
          <div class="loginlinks">
              <b-button v-b-modal.caveonid-modal class="ctabutton rounded" size="sm" variant="primary">
                Sign in with Caveon ID
              </b-button>
          </div>
          <small>
            <a href="https://www.caveon.com/online-testing-3/" target="_blank" class="morelink">Learn more about the Scorpion</a>
          </small>
        </div>
        <div class="columnleft">
          <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash1.jpg" alt="Scorpion Item Development" width="100%"/>
            <section class="section1">
              <h2>Looking for the right tool for the job?</h2>
              <h3>Develop, deliver, and secure your exams with Scorpion.</h3>
            </section>
            <section class="section2">
              <p>Designed by exam development professionals for exam development professionals, Caveon's online testing technology contains the tools you need to:</p>

              <div class="row">
                <div class="col1">
                  <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash3.jpg" alt="Scorpion AIG" width="100%"/>
                </div>
                <div class="col2">
                  <h3>Design items, build exams, and remotely collaborate in one clean, simple workspace.</h3>
                </div>
                </div>
                <div class="row">
                <div class="col1">
                  <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash5.jpg" alt="Scorpion Integration" width="100%"/>
                </div>
                <div class="col2">
                 <h3>Deploy exams, give scores, remotely proctor, and pull valuable reports.</h3>
                </div>


                </div>
                <div class="row">
                <div class="col1">
                  <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash4.jpg" alt="Scorpion Secure Roles" width="100%"/>
                </div>
                <div class="col2">
                 <h3>Generate thousands of items automatically and immediately with AIG.</h3>
                </div>
              </div>

              <div class="row">
                <div class="col1">
                  <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash2.jpg" alt="Scorpion Exam Deliveries" width="100%"/>
                </div>
                <div class="col2">
                  <h3>Secure your content with access controls, authentication and by using innovative design.</h3>
                </div>
              </div>


                <div class="row">
                <div class="col1">
                  <img src="https://caveon-content.s3-us-west-2.amazonaws.com/splash6.jpg" alt="Scorpion Custom Data and Workflows" width="100%"/>
                </div>
                <div class="col2">
                 <h3>Integrate with your favorite tools—from proctoring to learning management systems—or build your own custom integrations.</h3>
                </div>
              </div>


              <h4>The results speak for themselves:</h4>
            </section>
            <section class="section3">
              <blockquote>
                “I have worked with many different item banking solutions over the years – many of which are too difficult to navigate for even the experienced user. Scorpion has been instrumental in our item writers’ and/or reviewers’ ability to quickly understand its functionality and use it effectively. In fact, any solution that is easy-to-use and intuitive provides significant value to the exam development cycle. For someone who is responsible for managing the development, maintenance, and support of our exams, the ability to track the history of an exam project and associated items is essential to our ability to defend each item, if ever challenged.”
              </blockquote>
              <small><p>
                    - Exam Consultant, UiPath Inc.</p></small>
            </section>
        </div>
    </div>
  </div>
</template>
<script>
  import { SEI_API_LOGIN, SEI_API_REGISTER } from '../utils/constants'
  export default {
    name: 'SplashPage',
    created() {
      this.removeItemWritingInstructions()
    },
    data() {
      return {
        register_url: SEI_API_REGISTER,
        login_url: SEI_API_LOGIN
      }
    },
    methods: {
      removeItemWritingInstructions() {
        const storage = window.sessionStorage
        const keysToRemove = Object.keys(storage).filter(key => ( key.startsWith('item-writing-instructions-') ))

        keysToRemove.forEach(key => {
          storage.removeItem(key)
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  body {background: #39263a; padding-bottom:0!important;}

  .loginWrapper {min-height: 100%; flex:1;flex-direction:row-reverse; overflow: auto;}
  .wrapper{flex-direction: row-reverse; align-content:stretch;display:flex; min-height:calc(100%-100px); overflow: auto; margin-bottom: -100px;}
  .columnleft { flex: 2; background:#614363; color: #fff;
      h2 { font-weight:normal;   color: #fff; font-size: 2em; display: inline-block; }
      h3 { font-size: 1.5em;}
      h3, h4 { font-weight:normal; color: #fff; }
      h4 { font-weight:normal; margin-top: 50px;}
      a { text-decoration: underline; color: #fff; display: inline-block; margin-top: 30px; }
  }
  .columnright { flex:1; background: #ffffff; padding: 15px; text-align: center;
      img{display:block; margin: 40px auto 30px;}
      .ctabutton {display:inline-block; width: 180px;
      &:hover {background:#fff;color:#000; border: 1px solid #000;}}
  }
  .section1 {
      padding: 50px 30px;
      background: #412e42;
  }
  .section2 {
      padding: 30px 30px 0;
      .row { display:flex; flex-direction: row; margin: 35px 0; }
      .col1 { flex:1; margin-right: 30px; }
      .col2 { flex:2; padding-right: 15%; }
  }
  .section3 { background: #412e42; padding:30px 50px; text-align: left; margin: 0 30px 30px;
      border-radius: 8px;
      blockquote {
          font-size: 1.5em;
          font-style: italic;
          margin: 15px 0;
          font-family: 'Times New Roman', Times, serif;
      }
      p {
          font-size: 16px; margin: 0 20% 0 0;font-family: 'Times New Roman', Times, serif;
      }
  }

  .ctabutton { width: 160px; display:inline-block; background:#000;border-radius:4px;color:#fff;font-size:13px; margin:6px;padding:10px;text-decoration:none;transition:all .25s; text-align: center; border: 1px solid transparent; white-space: nowrap;}
  .ctabutton:hover {background:#fff;color:#000; border: 1px solid #000;}
  .morelink { margin-top: 20px; display:inline-block; }
  @media only screen and (max-width: 800px) {
      .wrapper{ flex-direction: column; }
      .columnleft { flex:auto; }
      .columnright {
          box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
          z-index: 2;
      }
  }



</style>
