<template>
  <div class="pools-container py-1 px-0">
    <b-modal
      @ok="onEnemyModalOk()"
      @cancel="onEnemyModalCancel()"
      id="enemies-modal"
      size="sm"
      title="Enemy Check"
      no-fade
      v-if="edit"
    >
      <div>
        <div v-if="form">
          {{ enemyModalText }}
        </div>
        <div v-else>
          {{ enemyModalTextSurvey }}
        </div>
        <b-list-group class="mt-2">
          <b-list-group-item v-for="name in enemyNames" :key="name">{{ name }}</b-list-group-item>
        </b-list-group>
      </div>
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button @click="cancel" variant="white">Don't Add The Item{{ multiple ? 's' : '' }}</b-button>
          <b-button @click="ok" variant="white">Yes, Add The Item{{ multiple ? 's' : '' }}</b-button>
        </template>
    </b-modal>
    <div class="ml-1">
      <p v-if="!edit" class="mt-3">
        <b-button variant="primary-light" size="sm" class="mr-2 float-right" @click="openModal('item-pool')">Edit</b-button> Manually Selected Items ({{ manuallySelectedItemCount }})
      <b-button
        href="#"
        tabindex="0"
        v-b-tooltip.focus
        title="Manually selected items will always be included in the item pool for this section."
        variant="unstyled"
        class="p-0"
      >
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
      </b-button>
      </p>
      <b-row class="mt-2" v-if="anyEnemies">
      <b-col :style="{ color: '#a51622' }">
        <div class="d-flex">
          <font-awesome-icon icon="exclamation-circle" class="mr-1 mt-1"></font-awesome-icon>
          <span v-if="form">
            This form contains enemy items. Review all sections. Enemy items will cause display and scoring errors.
          </span>
          <span v-else>
            This survey contains enemy items. Enemy items will cause display and scoring errors.
          </span>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-2" v-if="poolHasDuplicatesOnSamePage()">
      <b-col :style="{ color: '#a51622' }">
        <div class="d-flex">
          <font-awesome-icon icon="exclamation-circle" class="mr-1 mt-1"></font-awesome-icon>
          <span>
            Having duplicate items on the same page can cause issues.
            Please reorder the duplicate items or change the number of items presented per page.
            If pulling items at random, set number of items presented per page to 1.
          </span>
        </div>
      </b-col>
    </b-row>
      <!-- ({{ filteredPoolItems.length }}) -->
      <div v-if="edit" class="mb-3">
        <ItemPoolFilters @apply-filters="filterItems" />
      </div>
    </div>

    <b-row class="mr-1 ml-1 tables" v-if="showManuallySelected">
      <b-col class="p-0 pools-left">
        <b-pagination
          v-if="filteredPoolItems.length > perPage"
          :per-page="perPage"
          :total-rows="filteredPoolItems.length"
          class="float-right mr-1 my-1"
          v-model="poolCurrentPage"
        />
        <b-table
          :fields="poolFields"
          :id="`pool-items-table-${sectionIndex}`"
          :items="filteredPoolItems"
          :per-page="perPage"
          :current-page="poolCurrentPage"
          class="mb-0 p-1"
          fixed
          small
          striped
        >
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: columnWidth(field.key) }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:head(buttons)>
            <b-button
              @click="removeFiltered"
              class="float-left"
              size="sm"
              variant="white"
            >
              <font-awesome-icon icon="trash" />&nbsp;All
            </b-button>
          </template>
          <template v-slot:cell(buttons)="scope">
            <b-button-group class="float-right">
              <b-button
                :disabled="isFirstItem(scope.index)"
                @click="moveItem(scope.index, -1)"
                size="sm"
                variant="white"
              >
                <font-awesome-icon icon="arrow-up"></font-awesome-icon>
              </b-button>
              <b-button
                :disabled="isLastItem(scope.index)"
                @click="moveItem(scope.index, 1)"
                size="sm"
                variant="white"
              >
                <font-awesome-icon icon="arrow-down"></font-awesome-icon>
              </b-button>
              <b-button
                @click="removeItem(scope.item.id)"
                size="sm"
                variant="white"
              >
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:cell(info)="scope">
            <b-badge class="rounded-circle mr-1" :variant="infoVariant(scope.item.hasEnemies)" v-b-tooltip.hover="{ interactive: false }" :title="enemyTitle(scope.item.enemyNames)">E</b-badge>
            <b-badge class="rounded-circle mr-1" :variant="infoVariant(scope.item.del)" v-b-tooltip.hover="{ interactive: false }" title="Deleted">D</b-badge>
            <b-badge class="rounded-circle mr-1" :variant="infoVariant(scope.item.hasDuplicates)" v-b-tooltip.hover="{ interactive: false }" title="Duplicate">Dup</b-badge>
          </template>
          <template
            v-for="field in previewFields"
            v-slot:[customCell(field.key)]="scope"
          >
            <div :key="field.key" v-if="scope.item[field.key]">
              <div
                class="d-flex flex-wrap"
                v-if="Array.isArray(scope.item[field.key])"
              >
                <b-badge
                  :key="property"
                  :style="{ backgroundColor: 'white', color: 'black' }"
                  :title="option.tooltip"
                  class="d-flex align-items-center custom-badge border"
                  pill
                  v-b-tooltip="{ trigger: 'hover', interactive: false }"
                  v-for="(option, property) in scope.item[field.key]"
                >
                  <div
                    :style="{
                      backgroundColor: option.color,
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%'
                    }"
                    class="mr-1"
                    v-if="option.color"
                  ></div>
                  <div class="text-capitalize text-truncate py-1">
                    {{ option.value }}
                  </div>
                </b-badge>
              </div>
              <div v-else>{{ scope.item[field.key] }}</div>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col class="pools-right p-0" v-if="edit">
        <b-pagination
          v-if="filteredItems.length > perPage"
          :per-page="perPage"
          :total-rows="filteredItems.length"
          class="float-right mr-1 my-1"
          v-model="availableCurrentPage"
        />
        <b-table
          :fields="fields"
          :id="`available-items-table-${sectionIndex}`"
          :items="filteredItems"
          :per-page="perPage"
          :current-page="availableCurrentPage"
          class="mb-0 p-1"
          fixed
          small
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
        >
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: columnWidth(field.key) }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:head(buttons)>
            <b-button
              @click="addFiltered()"
              class="float-left"
              size="sm"
              variant="white"
            >
              <font-awesome-icon icon="plus" />&nbsp;All
            </b-button>
          </template>
          <template v-slot:cell(buttons)="scope">
            <div class="d-flex align-items-center">
              <b-button
                @click="addItem(scope.item)"
                class="py-1 px-2 rounded"
                variant="white"
              >
                <font-awesome-icon icon="plus"></font-awesome-icon>
              </b-button>
              <span v-if="scope.item.enemyNames" class="ml-1" :style="{ fontSize: '15px' }" v-b-tooltip.hover="{ interactive: false }" :title="scope.item.enemyNames">
                <font-awesome-icon icon="exclamation-circle" :style="{ color: '#a51622' }"></font-awesome-icon>
              </span>
            </div>
          </template>
          <template
            v-for="field in previewFields"
            v-slot:[customCell(field.key)]="scope"
          >
            <div :key="field.key" v-if="scope.item[field.key]">
              <div
                class="d-flex flex-wrap"
                v-if="Array.isArray(scope.item[field.key])"
              >
                <b-badge
                  :key="property"
                  :style="{ backgroundColor: 'white', color: 'black' }"
                  :title="option.tooltip"
                  class="d-flex align-items-center custom-badge border"
                  pill
                  v-b-tooltip="{ trigger: 'hover', interactive: false }"
                  v-for="(option, property) in scope.item[field.key]"
                >
                  <div
                    :style="{
                      backgroundColor: option.color,
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%'
                    }"
                    class="mr-1"
                    v-if="option.color"
                  ></div>
                  <div class="text-capitalize text-truncate py-1">
                    {{ option.value }}
                  </div>
                </b-badge>
              </div>
              <div v-else>{{ scope.item[field.key] }}</div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { EVENT } from '../../utils/event-bus'
  import { friendlyName } from '../../utils/misc'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { SESSION } from '../../utils/session'

  import get from 'lodash.get'

  import ItemPoolFilters from './ItemPoolFilters'

  export default {
    name: 'ItemPool',
    components: {
      ItemPoolFilters
    },
    props: {
      section: {
        type: Object
      },
      itemMap: {
        type: Object
      },
      sectionIndex: {
        type: Number
      },
      form: {
        type: Object
      },
      edit: {
        type: Boolean
      }
    },
    created() {
      if (!this.edit) {
        this.poolFields.shift()

        EVENT.$on('update-pool-items' + this.sectionIndex, this.setPoolItems)
      }
      this.formatItemMap()
      this.setFields()
      this.setPoolItems()
      this.setAllItems()
    },
    beforeDestroy() {
      if (this.edit) {
        this.$emit('set-pool-items')
      } else {
        EVENT.$off('update-pool-items' + this.sectionIndex)
      }
    },
    data() {
      return {
        allItems: [],
        filteredItems: [],
        poolItems: [],
        filteredPoolItems: [],
        enemyNames: [],
        originalIds: [],
        item: {},
        multiple: false,
        hideUsedItems: true,
        fields: [
          { key: 'buttons', label: '', sortable: false },
          { key: 'n', label: 'Name', sortable: true },
          { key: 'd', label: 'Difficulty', sortable: true, formatter: value => (value).toFixed(2) },
          { key: 'preview', label: 'Stem', sortable: true },

          {
            key: 'type',
            sortable: true,
            formatter: value => friendlyName(value)
          }
        ],
        poolFields: [
          { key: 'buttons', label: '', sortable: false },
          { key: 'info', label: 'Info', sortable: false },
          { key: 'n', label: 'Name', sortable: true },
          { key: 'd', label: 'Difficulty', sortable: true, formatter: value => (value).toFixed(2)  },
          { key: 'preview', label: 'Stem', sortable: true },
          {
            key: 'type',
            sortable: true,
            formatter: value => friendlyName(value)
          }
        ],
        previewFields: [],
        metaHelper: initMetaHelper(SESSION.project),
        poolCurrentPage: 1,
        availableCurrentPage: 1,
        perPage: 100,
        sortBy: 'n',
        sortDesc: false
      }
    },
    methods: {
      customCell(key) {
        return `cell(${key})`
      },
      openModal(modal) {
        this.$emit('open-modal', modal)
      },
      columnWidth(key) {
        let width
        switch (key) {
          case 'buttons':
            width = '85px'
            break
          case 'n':
            width = '200px'
            break
          case 'd':
            width = '65px'
            break
          case 'preview':
            width = '500px'
            break
          case 'type':
            width = '121px'
            break
          default:
            width = '100px'
        }

        return width + ' !important'
      },
      onEnemyModalOk() {
        if (this.multiple) {
          return this.addFiltered(true)
        }

        this.addItem(this.item, true)
      },
      onEnemyModalCancel() {
        if (this.multiple) {
          this.clearSectionIds()
        }
      },
      addItem(item, confirmed = false) {
        if (!confirmed) {
          const enemyNames = this.getEnemyNames(item)

          if (enemyNames.size) {
            this.item = item
            this.multiple = false
            this.enemyNames = Array.from(enemyNames)

            return this.$bvModal.show('enemies-modal')
          }
        }

        if (this.hideUsedItems) {
          const index = this.filteredItems.findIndex(filteredItem => filteredItem.id === item.id)
          this.filteredItems.splice(index, 1)
        }

        this.section.item_ids.push(item.id) // eslint-disable-line vue/no-mutating-props
        this.setPoolItems()
        this.surveyChanged()
      },
      removeItem(id) {
        if (this.hideUsedItems) {
          const itemExists = this.filteredItems.some(item => item.id === id)
          if (!itemExists) {
            const item = this.allItems.find(item => item.id === id)
            if (item) {
              this.filteredItems.push(item)
            }
          }
        }
        const index = this.section.item_ids.indexOf(id)
        this.section.item_ids.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        const filteredIndex = this.filteredPoolItems.findIndex(item => item.id === id)
        this.filteredPoolItems.splice(filteredIndex, 1)
        this.setPoolItems()
        this.surveyChanged()
      },
      moveItem(index, by) {
        const [id] = this.section.item_ids.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.section.item_ids.splice(index + by, 0, id) // eslint-disable-line vue/no-mutating-props
        this.setPoolItems()
        this.surveyChanged()
      },
      clearSectionIds() {
        this.section.item_ids = this.originalIds // eslint-disable-line vue/no-mutating-props
      },
      addFiltered(confirmed = false) {
        const { item_ids } = this.section
        const originalIds = [ ...item_ids ]
        const allEnemyNames = new Set()

        const filteredItemsSorted = [ ...this.filteredItems ]

        filteredItemsSorted.sort((objA, objB) => {
          const a = objA[this.sortBy]
          const b = objB[this.sortBy]

          if (typeof a === 'number' && typeof b === 'number') {
            return a - b
          }

          return this._toString(a).localeCompare(this._toString(b))
        })

        if (this.sortDesc) {
          filteredItemsSorted.reverse()
        }

        if (!confirmed) {
          for (const item of filteredItemsSorted) {
            item_ids.push(item.id)

            const enemyNames = this.getEnemyNames(item)
            enemyNames.forEach(eName => allEnemyNames.add(eName))
          }

          if (allEnemyNames.size) {
            this.originalIds = originalIds
            this.multiple = true
            this.enemyNames = Array.from(allEnemyNames)

            return this.$bvModal.show('enemies-modal')
          }
        }
        if (this.hideUsedItems) {
          this.filteredItems = []
        }
        this.setPoolItems()
        this.surveyChanged()
      },
      removeFiltered() {
        for (const item of this.filteredPoolItems) {
          if (this.hideUsedItems) {
            const itemExists = this.filteredItems.some(filteredItem => filteredItem.id === item.id)
            if (!itemExists) {
              const itemToAdd = this.allItems.find(allItem => allItem.id === item.id)
              if (itemToAdd) {
                this.filteredItems.push(itemToAdd)
              }
            }
          }
          const index = this.section.item_ids.indexOf(item.id)
          this.section.item_ids.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        }
        this.filteredPoolItems = []
        this.setPoolItems(false)
        this.surveyChanged()
      },
      filterUsedItems(hideUsedItems) {
        if (hideUsedItems) {
          const itemIdsInUse = new Set([])
          for (const item of this.poolItems) {
            itemIdsInUse.add(item.id)
          }
          this.filteredItems = this.allItems.filter(item => !itemIdsInUse.has(item.id))
        } else {
          this.filteredItems = [ ...this.allItems ]
        }

        this.hideUsedItems = hideUsedItems

        this.setAvailableItemEnemies()
      },
      isFirstItem(index) {
        return index === 0
      },
      isLastItem(index) {
        return index === this.filteredPoolItems.length - 1
      },
      filterItems(filters) {
        const { search, filter, value, hideUsedItems } = filters

        this.hideUsedItems = hideUsedItems

        const filteredList = []
        const itemIdsInUse = new Set([])

        for (const item of this.poolItems) {
          itemIdsInUse.add(item.id)
        }

        for (const item of this.allItems) {
          if (hideUsedItems && itemIdsInUse.has(item.id)) continue

          let searchPassed = true
          if (search) {
            searchPassed =
              item.n.toLowerCase().includes(search.toLowerCase()) || item.preview.toLowerCase().includes(search.toLowerCase())
          }

          let filterPassed = true
          if (filter) {
            if (filter === 'type') {
              filterPassed = item.type === value
            } else {
              if (Array.isArray(value)) {
                const metaValue = get(item, `meta.scorpion.${filter}`, [])
                filterPassed = value.every(currentValue =>
                  metaValue.includes(currentValue)
                )
              } else {
                const metaValue = get(item, `meta.scorpion.${filter}`)
                filterPassed = metaValue == value
              }
            }
          }

          if (searchPassed && filterPassed) {
            filteredList.push(item)
          }
        }

        this.filteredItems = this.formatItems(filteredList)

        this.setAvailableItemEnemies()
      },
      formatItemMap() {
        for (const [, item] of Object.entries(this.itemMap)) {
          if (!item.preview_fields) continue

          for (const [fieldId, fieldValue] of Object.entries(
            item.preview_fields
          )) {
            const customField = this.metaHelper.getField(fieldId)

            if (!customField || (!fieldValue && fieldValue !== 0)) {
              continue
            }

            if (customField.type.startsWith('select_')) {
              if (typeof fieldValue === 'string') {
                if (customField.options[fieldValue]) {
                  const { text, preview_text, color } = customField.options[
                    fieldValue
                  ]
                  item[fieldId] = [
                    {
                      value: preview_text || text,
                      tooltip: text,
                      color
                    }
                  ]
                }
              } else {
                const values = []
                for (const id of fieldValue) {
                  if (customField.options[id]) {
                    const { text, preview_text, color } = customField.options[
                      id
                    ]
                    values.push({
                      value: preview_text || text,
                      tooltip: text,
                      color
                    })
                  }
                }
                item[fieldId] = values
              }
            } else {
              item[fieldId] = fieldValue
            }
          }
        }
      },
      setFields() {
        const previewFields = this.metaHelper.getPreviewFields()
        const fields = []
        for (const field of previewFields) {
          fields.push({
            key: field.id,
            label: field.name
          })
        }
        this.previewFields = fields
        this.fields.push(...fields)
        this.poolFields.push(...fields)
      },
      setAllItems() {
        const items = []
        for (const [key, value] of Object.entries(this.itemMap)) {
          if (!value.del) {
            items.push({ id: key, ...value })
          }
        }

        this.allItems = [...items]
        this.filterUsedItems(true)
      },
      setPoolItems(setFiltered = true) {
        const items = []
        for (const id of this.section.item_ids) {
          items.push({ id, ...this.itemMap[id] })
        }

        this.poolItems = [...items]
        if (setFiltered) {
          this.filteredPoolItems = this.formatItems(items)
        }

        this.setAvailableItemEnemies()
      },
      setAvailableItemEnemies() {
        for (const item of this.filteredItems) {
          const enemyNames = this.getEnemyNames(item)

          if (enemyNames.size) {
            item.enemyNames = `Enemy of ${Array.from(enemyNames).join(', ')} in Item Pool`
          } else {
            item.enemyNames = null
          }
        }
      },
      formatItems(items) {
        for (const item of items) {
          const namesOfEnemies = [ ...this.getEnemyNames(item) ]

          item.enemyNames = namesOfEnemies.join(', ')
          item.hasEnemies = namesOfEnemies.length > 0
          item.hasDuplicates = this.section.item_ids.filter(id => (
            id === item.id
          )).length > 1
        }

        return items
      },
      poolHasDuplicatesOnSamePage() {
        const indicesOfDuplicates = []
        for (const [index, item] of this.poolItems.entries()) {
          item._rowVariant = null
          if (item.hasDuplicates) {
            indicesOfDuplicates.push(index)
          }
        }

        let { items_per_page, items_to_select, order } = this.section

        if (!items_per_page) {
          items_per_page = this.poolItems.length
        }

        if (indicesOfDuplicates.length <= 1 || items_per_page === 1) {
          return false
        }

        if (order === 'random' || items_to_select) {
          for (const index of indicesOfDuplicates) {
            this.poolItems[index]._rowVariant = 'danger'
          }
          return true
        }

        let displayMessage = false
        for (let i = 0; i < this.poolItems.length; i += items_per_page) {
          const indicesInRange = indicesOfDuplicates.filter(
            (index) => i <= index && index <= i + (items_per_page - 1)
          )
          if (indicesInRange.length > 1) {
            indicesInRange.map(
              (index) => (this.poolItems[index]._rowVariant = 'danger')
            )
            displayMessage = true
          }
        }

        return displayMessage
      },
      surveyChanged() {
        this.$emit('survey-changed', {
          sectionIndex: this.sectionIndex
        })
      },
      infoVariant(info) {
        return info ? 'black' : 'white'
      },
      enemyTitle(names) {
        return names.length ? `Enemy of ${names}` : 'Enemy'
      },
      getEnemyNames(item) {
        const enemyNames = new Set()

        const groups = this.form ? this.form.version.groups : [this.section]

        for (const group of groups) {
          for (const id of item.enemy_ids) {
            if (group.item_ids.includes(id)) {
              const name = this.itemMap[id].n
              enemyNames.add(name)
            }
          }
        }

        return enemyNames
      },
      _toString(value) {
        if (value === null || typeof value === 'undefined') {
          return ''
        } else if (value instanceof Object) {
          return Object.keys(value)
            .sort()
            .map(key => toString(value[key]))
            .join(' ')
        } else {
          return String(value)
        }
      }
    },
    computed: {
      averageDifficulty() {
        const filteredItemsLength = this.filteredPoolItems.length

        if (filteredItemsLength) {
          const combinedDifficulty = this.filteredPoolItems
            .map(item => item.d)
            .reduce((accumulator, difficulty) => accumulator + difficulty)
          return (combinedDifficulty / filteredItemsLength).toFixed(2)
        }

        return (0).toFixed(2)
      },
      enemyModalText() {
        if (this.multiple) {
          return 'The items being added have enemies in a form section. Do you still want to add these items despite having the following enemies?'
        }

        return 'The item being added has enemies in a form section. Do you still want to add this item despite having the following enemies?'
      },
      enemyModalTextSurvey() {
        if (this.multiple) {
          return 'The items being added have enemies in the item pool. Do you still want to add these items despite having the following enemies?'
        }

        return 'The item being added has enemies in the item pool. Do you still want to add this item despite having the following enemies?'
      },
      anyEnemies() {
        const groups = this.form ? this.form.version.groups : [this.section]

        for (const item of this.poolItems) {
          for (const id of item.enemy_ids) {
            for (const group of groups) {
              if (group.item_ids.includes(id)) {
                return true
              }
            }
          }
        }

        return false
      },
      showManuallySelected() {
        return this.edit || this.filteredPoolItems.length
      },
      manuallySelectedItemCount() {
        const count = this.section.item_ids.length

        return `${ count } Item${ count !== 1 ? 's' : '' }`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pools-container {
    background: #fff;
  }
  .tables {
    border: 1px solid #ccc;
  }
  .pools-top-left {
    background: #eee;
  }
  .pools-top-right {
    -webkit-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    background: #ccc;
  }
  .pools-left,
  .pools-right {
    max-height: 500px;
    overflow-y: auto;
    flex: 1;
    font-size: 0.8em;
    overflow-wrap: break-word;
    button {
      font-size: 0.7em;
    }
  }
  .pools-left {
    background: #fff;
  }
  .pools-right {
    -webkit-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    box-shadow: inset 6px 0px 6px -4px rgba(0, 0, 0, 0.26);
    max-height: 500px;
    overflow-y: auto;
    background: #eee;
  }
</style>
