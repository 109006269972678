<template>
  <div>
    <div v-if="!loading">
      <div v-if="!error">
        <!-- Roles -->
        <div v-if="!isLoadingRoles">
          <div :key="role.id" v-for="role in roles">
            <AccessRole
              :expand="expand"
              :role="role"
              :project="project"
              @open-add-role="openAddRole"
              @edit-role="editRole"
              @remove-role="removeRole"
            ></AccessRole>
          </div>
        </div>
        <div v-else>
          <b-spinner label="Small Spinner" small></b-spinner>
        </div>
      </div>
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load roles.
          <b-button @click="getRoles" variant="danger">Reload</b-button>
        </b-alert>
      </b-container>

      <!-- Add/Edit Role Modal -->
      <b-modal
        :title="addRoleModalTitle"
        @hidden="hideAddRoleModal"
        id="add-role-modal"
        size="xl"
      >
        <b-alert :show="editingRole.invalid" variant="danger">
          This role has invalid restrictions. Please update!
        </b-alert>

        <b-form @submit.prevent="onSubmitRole" novalidate id="add-role-form">
          <b-form-group
            :label="
              editMode
                ? 'User:'
                : 'Enter an email or select a user/group from your organization *'
            "
          >
            <RolesInput
              :options="filteredRoleOptions"
              :isDisabled="editMode"
              :editRole="editingRole"
              @roles-changed="rolesChanged"
            />
          </b-form-group>
          <b-form-group
            v-if="restrictionsAllowed(type)"
            label="Restrictions:"
            label-cols-md="4"
          >
            <div v-for="(restriction, index) in metaRestrictions" :key="index">
              <b-select
                :options="roleCustomFieldOptions"
                v-model="restriction.fieldId"
                class="mb-2"
              ></b-select>
              <div v-if="restriction.fieldId">
                <v-select
                  v-if="
                    ['text', 'number'].includes(
                      metaHelper.getFieldType(restriction.fieldId)
                    )
                  "
                  :disabled="!isPremium"
                  v-model="restriction[restriction.fieldId]"
                  taggable
                  multiple
                  label="text"
                  placeholder="Not selected"
                  style="backgroundColor: white"
                  class="mb-4"
                >
                  <template #selected-option-container="{ option, deselect }">
                    <span
                      :class="
                        `vs__selected border ${option.color ? 'pl-0' : ''}`
                      "
                    >
                      <div
                        :style="{
                          backgroundColor: option.color,
                          width: '15px',
                          height: '100%',
                          borderRadius: '3px 0 0 3px'
                        }"
                        class="mr-1"
                        v-if="option.color"
                      ></div>
                      {{ option.text }}
                      <button
                        :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                        @click.stop="deselect(option)"
                        @mousedown.stop
                        class="vs__deselect no-focus"
                        type="button"
                      >
                        <font-awesome-icon icon="times"></font-awesome-icon>
                      </button>
                    </span>
                  </template>
                  <template #option="{ color, text }">
                    <div
                      :style="{ background: color }"
                      class="option p-1 d-inline-block"
                      v-if="color"
                    ></div>
                    {{ text }}
                  </template>
                </v-select>

                <v-select
                  v-if="
                    ['select_one', 'select_multi'].includes(
                      metaHelper.getFieldType(restriction.fieldId)
                    )
                  "
                  :disabled="!isPremium"
                  :options="metaHelper.getFieldOptions(restriction.fieldId)"
                  :reduce="option => option.value"
                  :selectable="option => !option.disabled"
                  v-model="restriction[restriction.fieldId]"
                  multiple
                  label="text"
                  placeholder="Not selected"
                  style="backgroundColor: white"
                  class="mb-4"
                >
                  <template #selected-option-container="{ option, deselect }">
                    <span
                      :class="
                        `vs__selected border ${option.color ? 'pl-0' : ''}`
                      "
                    >
                      <div
                        :style="{
                          backgroundColor: option.color,
                          width: '15px',
                          height: '100%',
                          borderRadius: '3px 0 0 3px'
                        }"
                        class="mr-1"
                        v-if="option.color"
                      ></div>
                      {{ option.text }}
                      <button
                        :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                        @click.stop="deselect(option)"
                        @mousedown.stop
                        class="vs__deselect no-focus"
                        type="button"
                      >
                        <font-awesome-icon icon="times"></font-awesome-icon>
                      </button>
                    </span>
                  </template>
                  <template #option="{ color, text }">
                    <div
                      :style="{ background: color }"
                      class="option p-1 d-inline-block"
                      v-if="color"
                    ></div>
                    {{ text }}
                  </template>
                </v-select>
              </div>
            </div>
            <b-button
              @click="metaRestrictions.push({ fieldId: '' })"
              variant="primary-light"
              size="sm"
              >Add restriction</b-button
            >
          </b-form-group>
          <b-form-group
            v-if="tagRestrictionsAllowed(type)"
            label="Tag Restrictions:"
            label-cols-md="4"
          >
            <b-tags v-model="tagRestrictions" placeholder="Type a new tag and press enter" />
          </b-form-group>

          <b-form-group
            label="Expire access (your local date/time):"
            label-cols-sm="4"
            label-for="datepicker-date"
          >
          <b-row>
            <b-col>
              <b-form-datepicker
                id="datepicker-date"
                size="sm"
                class="mb-2"
                v-model="editingRole._expDate"
              ></b-form-datepicker>

              <b-form-timepicker size="sm" class="mb-2" locale="en" v-model="editingRole._expTime"></b-form-timepicker>
            </b-col>
            <b-col cols="2">
              <b-button size="sm" variant="white" @click="clearDateTime">Clear</b-button>
            </b-col>
          </b-row>
          </b-form-group>
        </b-form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button-group>
            <b-button @click="cancel" variant="white">Cancel</b-button>
            <b-button
              :disabled="disableAddRoleBtn"
              type="submit"
              form="add-role-form"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isAddingRole"
              ></b-spinner
              >&nbsp;Save
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE, ROLE_TYPES } from '../../utils/constants'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy } from '../../utils/misc.js'
  import { VALIDATE } from '../../utils/validate'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { DateTimeParser } from '../../utils/date-time-parser'

  import get from 'lodash.get'
  import Spinner from '../Spinner'
  import AccessRole from './AccessRole'
  import RolesInput from '../RolesInput'


  export default {
    name: 'Roles',
    components: {
      Spinner,
      AccessRole,
      RolesInput,
    },
    async created() {
      await this.getRoles()
      this.loading = false

      this.metaHelper = initMetaHelper(this.project)

      if (this.metaHelper.hasCustomFields) {
        this.customFieldOptions = this.project.meta.scorpion.custom_field_order
          .map(fieldId => this.project.meta.scorpion.custom[fieldId])
          .filter(field => field.type !== 'select_multi')
          .filter(field => !field.by_option)
          .map(field => ({ value: field.id, text: field.name }))
      }

      this.customFieldOptions = [
        { value: '', text: 'All' },
        ...this.customFieldOptions
      ]

      if (this.metaHelper.hasCustomFields) {
        this.roleCustomFieldOptions = this.metaHelper.getOptions()
      }

      this.dateTimeParser = DateTimeParser(this.$moment)
    },
    props: {
      project: {
        type: Object
      },
      isSaving: {
        type: Boolean
      },
      isPremium: {
        type: Boolean
      }
    },
    data() {
      return {
        loading: true,
        customFieldOptions: [],
        roleCustomFieldOptions: [],
        metaHelper: {},
        error: false,
        isAddingRole: false,
        type: '',
        roleOptions: [],
        addingRoles: [],
        addingGroup: [],
        editingRole: { value: '', type: '', key: '', id: null },
        isLoadingRoles: true,
        expand: true,
        metaRestrictions: [],
        tagRestrictions: [],
        roles: deepCopy(ROLE_TYPES),
        editMode: false
      }
    },
    methods: {
      async getRoles() {
        try {
          let url = `${SEI_API_BASE}/exams/${this.project.id}/roles?include=user,group`
          let response = await HTTP.get(url)
          let results = response.data.results
          this.processRoles(results)
          this.roleOptions = await this.getUsersAndGroups()
          this.error = false
        } catch (error) {
          if (error.response.status === 403) {
            EVENT.alert({
              variant: 'danger',
              message:
                'You are not authorized to view this page. Redirecting...'
            })
            setTimeout(() => {
              this.$router.push({ path: '/projects' })
            }, 2000)
            return
          }
          this.error = true
        } finally {
          this.isLoadingRoles = false
        }
      },
      processRoles(results) {
        for (const role of this.roles) {
          role.users = []
          for (const result of results) {
            if (result.type === role.type) {
              role.users.push(result)
            }
          }
        }
      },
      async removeRole(roleId, type) {
        const currRole = this.getRole(type)
        try {
          currRole.isDisableDeleteBtn = true
          let url = `${SEI_API_BASE}/exams/${this.project.id}/roles/${roleId}`
          await HTTP.delete(url)
          await this.getRoles()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete role! Please try again.'
          })
        } finally {
          currRole.isDisableDeleteBtn = false
        }
      },
      async getUsersAndGroups() {
        let url = `${SEI_API_BASE}/exams/${this.project.id}/users_and_groups`
        let response = await HTTP.get(url)
        return this.getRoleOptions(response.data.results)
      },
      getRoleOptions(roles) {
        let options = []
        for (const role of roles) {
          if (role.caveon_id) {
            let option = {
              key: role.caveon_id,
              value: role.email,
              name: `${role.email} (User)`,
              type: 'user'
            }
            options.push(option)
          } else if (role.id) {
            let option = {
              key: role.id,
              value: role.name,
              name: `${role.name} (Group)`,
              type: 'group'
            }
            options.push(option)
          }
        }
        return options
      },
      editRole(type, role) {
        this.editMode = true
        this.metaRestrictions = []
        this.tagRestrictions = []

        this.type = type
        if (role.meta_restrictions?.length) {
          this.metaRestrictions = role.meta_restrictions.map(restriction => {
            return {
              fieldId: restriction[0],
              [restriction[0]]: restriction[1]
            }
          })
        }

        if (role.tag_restrictions) {
          this.tagRestrictions = role.tag_restrictions
        }


        this.editingRole = {
          type: role.group_id ? 'group' : 'user',
          value: role.user && role.user.email || role.group && role.group.name,
          key: role.group_id,
          id: role.id,
          invalid: role.is_invalid
        }

        if (role.expires_at) {
          const { localDate, localTime } = this.dateTimeParser.parseUtc(role.expires_at)

          this.editingRole._expDate = localDate
          this.editingRole._expTime = localTime
        }

        this.$bvModal.show('add-role-modal')
      },
      openAddRole(type) {
        this.editMode = false
        this.type = type
        this.$bvModal.show('add-role-modal')
        this.metaRestrictions = []
        this.tagRestrictions = []
      },
      onSubmitRole(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.saveRoles()
        }
      },
      rolesChanged(result) {
        
        if (this.editMode) {
          this.editingRole = {
            ...this.editingRole,
            ...result
          }
        } else {
          this.addingRoles = []
          this.addingGroup = []

          for (const role of result) {
            if (role.type === 'user') {
              this.addingRoles.push(role.value)

              continue
            }

            this.addingGroup.push(role.key)
          }
        }
      },
      async saveRoles() {
        try {
          this.isAddingRole = true
          if (!this.editMode) {
            const url = `${SEI_API_BASE}/exams/${this.project.id}/roles`
            const addRolePromises = []
              for (const role of this.addingGroup) {
                const { _expDate, _expTime } = role
                const data = this.getRoleData({ ...role, _expDate, _expTime })
                data.group_id = role
                const rolePromise = HTTP.post(url, data)
                addRolePromises.push(rolePromise)
              
              }
            
              for (const role of this.addingRoles) {
                if (role.includes(',')) {
                  const emailEnd = role.indexOf(',')
                  const email = role.substring(0, emailEnd)
                  const tagsSubstring = role.substring(emailEnd + 1)
                  const tagArray = tagsSubstring.split(/[,;]/)
                  const tags = tagArray.map(tag => tag.trim()).filter(tag => tag !== "")
                  
                  const { _expDate, _expTime } = role

                  const data = this.getRoleData({ ...role, _expDate, _expTime });
                  data.user_email = email
                  data.tag_restrictions = tags

                  const rolePromise = HTTP.post(url, data)
                  addRolePromises.push(rolePromise)
                } else {
                  const { _expDate, _expTime } = role

                  const data = this.getRoleData({ ...role, _expDate, _expTime })
                  data.user_email = role

                  const rolePromise = HTTP.post(url, data)
                  addRolePromises.push(rolePromise)
                }
              }

              await Promise.all(addRolePromises)
          } else {
            const url = `${SEI_API_BASE}/exams/${this.project.id}/roles/${this.editingRole.id}`
            const { _expDate, _expTime } = this.editingRole
            const data = this.getRoleData({ ...this.editingRole, _expDate, _expTime })
            
            data.email = this.editingRole.value
            data.tags = this.editingRole.tags;
            await HTTP.put(url, data)
          }
          await this.getRoles()
          this.$bvModal.hide('add-role-modal')
        } catch (error) {
          const jsonStr = get(error.response, 'config.data')
          const data = this.getJSON(jsonStr)
          const email = get(data, 'user_email')
          const resp = get(error, 'response.data')
          if (resp && resp.messages && resp.messages.length) {
            EVENT.alert({
              variant: 'danger',
              message: `Error: ${resp.messages[0]}: ${email}`
            })
          } else {
            EVENT.alert({
              variant: 'danger',
              message: 'Error: There was an error while saving your roles!'
            })
          }
        } finally {
          this.editMode = false
          this.isAddingRole = false
          this.editingRole = { value: '', type: '', key: '', id: null }
        }
      },
      getJSON(str) {
        try {
          return JSON.parse(str)
        } catch (error) {
          return ''
        }
      },
      getRoleData(role) {
        let data = {
          type: this.type
        }
        if (role.type === 'user') {
          data.user_email = role.value
        }
        if (role.type === 'group') {
          data.group_id = role.key
        }
        if (this.metaRestrictions && this.metaRestrictions.length) {
          const cleanRestrictions = this.metaRestrictions.filter(
            restriction => {
              return restriction.fieldId && restriction[restriction.fieldId]
            }
          )
          data.meta_restrictions = cleanRestrictions.reduce(
            (all, restriction) => {
              const value = restriction[restriction.fieldId]
              all.push([restriction.fieldId, value])
              return all
            },
            []
          )
        }

        if (this.tagRestrictions.length) {
          data.tag_restrictions = this.tagRestrictions.map(tag => tag.toLowerCase());
        }

        data.expires_at = this.dateTimeParser.parseLocal({ localDate: role._expDate, localTime: role._expTime })

        return data
      },
      hideAddRoleModal() {
        this.emailInput = ''
      },
      getRole(type) {
        for (const role of this.roles) {
          if (role.type === type) {
            return role
          }
        }

        return {}
      },
      restrictionsAllowed(type) {
        return type === 'workshop'
      },
      tagRestrictionsAllowed(type) {
        return type === 'proctor'
      },
      clearDateTime() {
        this.editingRole = {
          ...this.editingRole,
          _expDate: null,
          _expTime: null
        }
      },
    },
    computed: {
      disableAddRoleBtn() {
        return this.isAddingRole
      },
      addRoleModalTitle() {
        let action = 'Add'
        if (this.editMode) {
          action = 'Edit'
        }
        return `${action} Role: ${this.type.charAt(0).toUpperCase() +
          this.type.slice(1)}`
      },
      filteredRoleOptions() {
        for (const role of this.roles) {
          if (role.type === this.type) {
            const idArr = role.users.map(user => {
              if (user.user_caveon_id) {
                return user.user_caveon_id
              }
              if (user.group_id) {
                return user.group_id
              }
            })
            const usedIds = new Set(idArr)
            const filteredOptions = []
            for (const roleOption of this.roleOptions) {
              if (!usedIds.has(roleOption.key)) {
                filteredOptions.push(roleOption)
                usedIds.add(roleOption.key)
              }
            }
            return filteredOptions
          }
        }
        return this.roleOptions
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>