<template>
  <div>
    <TopNavigation :resource="org" />
    <div v-if="!loading">
      <router-view :isSaving="isSaving" :org="org" v-if="!error" />
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load organization.
          <b-button @click="reload" variant="danger">Reload</b-button>
        </b-alert>
      </b-container>
    </div>
    <!-- Loading Spinner -->
    <Spinner v-else />
  </div>
</template>

<script>
  import { SEI_API_BASE } from '../utils/constants'
  import { HTTP } from '../utils/requests'
  import { EVENT } from '../utils/event-bus'

  import TopNavigation from './TopNavigation'
  import Spinner from './Spinner'

  export default {
    name: 'Organization',
    components: {
      TopNavigation,
      Spinner
    },
    created() {
      EVENT.$on(EVENT.saveOrgEvent, org => {
        this.saveOrg(org)
      })

      EVENT.$on(EVENT.getOrgEvent, () => {
        this.getOrg()
      })

      this.getOrg()
    },
    beforeDestroy() {
      EVENT.$off(EVENT.saveOrgEvent)
      EVENT.$off(EVENT.getOrgEvent)
    },
    data() {
      return {
        org: null,
        orgUrl: `${SEI_API_BASE}/organizations/${this.$route.params.orgId}?include=billing_info,delivery_creation_disabled,settings&exclude=exams`,
        loading: true,
        error: false,
        isSaving: false
      }
    },
    methods: {
      async getOrg() {
        try {
          const response = await HTTP.get(this.orgUrl)
          this.org = response.data
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      async saveOrg(org) {
        try {
          this.isSaving = true
          const result = await HTTP.put(this.orgUrl, org)
          this.org = Object.assign({}, this.org, result.data)
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save! Please try again.'
          })
        } finally {
          this.isSaving = false
        }
      },
      reload() {
        this.loading = true
        this.getOrg()
      }
    }
  }
</script>

<style lang="scss" scoped></style>
