<template>
  <b-container class="mt-3" fluid v-if="!loading">
    <TranslationsStickyTools>
      <template v-slot:columns>
        <b-col cols="2">
          <b-badge
            class="text-capitalize statuses"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="content.isComplete"
            >C</b-badge
          >
          <b-badge
            class="text-capitalize statuses"
            variant="warning"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="!content.isComplete"
            >I</b-badge
          >
        </b-col>
        <b-col cols="6">
          <b-input-group>
            <b-select variant="white" class="mr-1" :options="MODE_OPTIONS" size="sm" v-model="mode"></b-select>
            <TranslateManyContentButton v-if="!isPreview" :project="project" :language="language" class="ml-3" :object="content" />
          </b-input-group>
        </b-col>
        <b-col cols="4" class="text-right">          
          <b-button-group size="sm" class="ml-2">
            <b-button @click="save(0)" variant="warning" class="mr-1" size="sm">Save as Incomplete</b-button>
            <b-button @click="save(1)" size="sm">Save as complete</b-button>
          </b-button-group>
        </b-col>
      </template>
    </TranslationsStickyTools>
    <b-row class="p-2 flex-wrap flex-column flex-md-row flex-stretch">
      <b-col cols="12" md="6" class="p-1">
        <p class="mb-2">Project name</p>
      </b-col>
      <b-col cols="12" md="6" class="p-1">
        <b-row align-v="stretch">
          <b-col>
            <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="content.name" v-model="content.form.name" />
            <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
          </b-col>
        </b-row>
      </b-col>
      <b-input disabled :value="content.name" style="flex:1;" class="m-1"></b-input>
      <b-input v-if="isEdit" v-model="content.form.name" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-input>
      <div v-if="isPreview" v-text="content.form.name || ''" class="preview-box" v-direction="language.properties.direction"></div>
    </b-row>

    <b-row class="p-2 flex-wrap flex-column flex-md-row flex-stretch">
      <b-col cols="12" md="6" class="p-1">
        <p class="mb-2">Display name</p>
      </b-col>
      <b-col cols="12" md="6" class="p-1">
        <b-row align-v="stretch">
          <b-col>
            <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="content.display_name" v-model="content.form.display_name" />
            <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
          </b-col>
        </b-row>
      </b-col>
      <b-input disabled :value="content.display_name" style="flex:1;" class="m-1"></b-input>
      <b-input v-if="isEdit" v-model="content.form.display_name" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-input>
      <div v-if="isPreview" v-text="content.form.display_name || ''" class="preview-box" v-direction="language.properties.direction"></div>
    </b-row>

    <b-row class="p-2 flex-wrap flex-column flex-md-row flex-stretch">
      <b-col cols="12" md="6" class="p-1">
        <p class="mb-2">Description</p>
      </b-col>
      <b-col cols="12" md="6" class="p-1">
        <b-row align-v="stretch">
          <b-col>
            <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="content.description" v-model="content.form.description" />
            <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
          </b-col>
        </b-row>
      </b-col>
      <b-textarea disabled :value="content.description" style="flex:1;" class="m-1"></b-textarea>
      <b-textarea v-if="isEdit" v-model="content.form.description" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
      <div v-if="isPreview" v-markdown="content.form.description || ''" class="preview-box" v-direction="language.properties.direction"></div>
    </b-row> 
    <b-row v-for="(schema, index) in content.examinee_schema" :key="index" class="p-2 flex-wrap flex-column flex-md-row flex-stretch">
      <b-col cols="12" md="6" class="p-1">
        <p class="mb-2">Examinee field</p>
      </b-col>
      <b-col cols="12" md="6" class="p-1">
        <b-row align-v="stretch">
          <b-col>
            <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="schema.key" v-model="content.form.examinee_schema[index].key" />
            <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
          </b-col>
        </b-row>
      </b-col>
      <b-textarea disabled :value="schema.key" style="flex:1;" class="m-1"></b-textarea>
      <b-textarea v-if="isEdit" v-model="content.form.examinee_schema[index].key" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
      <div v-if="isPreview" v-text="content.form.examinee_schema[index].key || ''" class="preview-box" v-direction="language.properties.direction"></div>
    </b-row>  
  </b-container>
  <Spinner v-else />
</template>
<script>
import Spinner from '../Spinner'
import { TranslationsHelper, TranslationType, TranslationModes } from '../../utils/translation'
import TranslationsStickyTools from './TranslationsStickyTools.vue'
import TranslateContentButton from './TranslateContentButton.vue'
import { EVENT } from '../../utils/event-bus'
import TranslateManyContentButton from './TranslateManyContentButton.vue'


export default {
  name: 'LanguagesTranslationsItems',
  components: {
    Spinner,
    TranslationsStickyTools,
    TranslateContentButton,
    TranslateManyContentButton
  },
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      content: {},
      mode: TranslationModes.PREVIEW,
      MODE_OPTIONS: TranslationModes.OPTIONS
    }
  },
  async created() {
    this.helper = TranslationsHelper.Factory(this.project.id, this.language.id, TranslationType.settings)

    await this.fetchContent()
    await this.fetchTranslations()

    this.loading = false
  },
  methods: {
    async fetchContent() {
      const { name, display_name, description, settings: { examinee_schema }} = this.project

      await this.helper.fetchContent({ name, display_name, description, examinee_schema })

      this.content = this.helper.things[0]
    },
    async fetchTranslations() {
      await this.helper.fetchTranslations()

      this.content.applyTranslation(this.helper.translations[0])
    },
    async save(complete) {
      const response = await this.helper.save(complete)
      const {error} = response[0]

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save! Please try again.'
        })
      }
      
      EVENT.alert({
        variant: 'success',
        message: 'Saved'
      })
    }
  },
  computed: {
    isEdit() {
      return this.mode === 'edit'
    },
    isPreview() {
      return this.mode === 'preview'
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview-box {
    flex: 1; 
    border-left: 1px solid #ccc;
    margin: 5px 10px;
    padding: 5px 10px;
    max-width: 48%;
  }
  @media only screen and (max-width: 768px) {
    .preview-box {
      max-width: 100%;
    }
  }
</style>
