<template>
  <div class="p-4" v-show="show">
    <h3 class="mb-3">
      {{ projectName }}
    </h3>

    <b-row>
      <b-col :style="{ overflowX: 'hidden' }">
        <b-table 
          @row-clicked="onRowClick"
          @filtered="onFiltered"
          :fields="fields"
          :items="exams"
          :filter="search"
          :filter-included-fields="included"
          sort-by="examinee"
          responsive
          fixed
          bordered
          hover
          striped
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :style="{ width: getColumnWidth(field.key) }" />
          </template>

          <template #head(checked)>
            <div class="d-flex justify-content-center">
              <b-checkbox @change="onChangeAll" :checked="allChecked" class="ml-2" plain />
            </div>
          </template>

          <template #cell(checked)="scope">
            <div class="d-flex justify-content-center align-items-center">
              <b-checkbox @change="onCheckboxChange(scope.item.id)" :checked="isChecked(scope.item.id)" class="ml-2" plain />
            </div>
          </template>

          <template #cell(statusText)="scope">
            <div :class="`text-capitalize status-text-${scope.item.statusVariant}`">{{ scope.item.statusText }}</div>
          </template>

          <template #cell(logLastEventUTC)="scope">
            <div>{{ scope.item.logLastEvent }}</div>
          </template>

          <template #cell(progressComplete)="scope">
            <b-progress :max="scope.item.progressTotal">
              <b-progress-bar :value="scope.item.progressComplete" :label="scope.item.progressText" variant="warning" />
            </b-progress>
          </template>

          <template #cell(tagsAsString)="scope">
            <b-badge v-for="(tag, index) in scope.item.tags" :key="index" variant="secondary" class="mr-1">
              {{ tag }}
            </b-badge>
          </template>

          <template #cell(info)="scope">
            <b-badge v-if="scope.item.paused" v-b-tooltip.hover title="Exam is paused" variant="info" class="mr-1">
              <font-awesome-icon icon="pause" />
            </b-badge>

            <b-badge v-if="scope.item.disconnected" v-b-tooltip.hover title="Examinee has disconnected" variant="danger" class="mr-1">
              <font-awesome-icon icon="plug" />
            </b-badge>

            <b-badge v-if="scope.item.spawned" v-b-tooltip.hover title="Examinee started a new delivery" variant="success" class="mr-1">
              <font-awesome-icon icon="link" />
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { EVENT } from '../utils/event-bus'

const FIELDS = [
  { key: 'checked', label: '' },
  { key: 'statusText', label: 'Status', sortable: true },
  { key: 'examinee', label: 'Examinee', tdClass: 'ellipsis', sortable: true },
  { key: 'logEvents', label: 'Events', sortable: true },
  { key: 'logWarnings', label: 'Warnings', sortable: true },
  { key: 'logLastEventUTC', label: 'Last Event', sortable: true },
  { key: 'progressComplete', label: 'Progress', sortable: true },
  { key: 'tagsAsString', label: 'Tags', sortable: true },
  { key: 'info', label: 'Exam Info' }
]

const INCLUDED = [
  'statusText',
  'examinee',
  'logEvents',
  'logWarnings',
  'logLastEvent',
  'progressComplete',
  'tagsAsString'
]

export default {
  name: 'ExamWatchTable',
  props: {
    exams: {
      type: Array
    },
    projectName: {
      type: String
    },
    search: {
      type: String
    }
  },
  beforeDestroy() {
    const ids = this.exams.map((exam) => exam.id)
    this.$emit('remove-selected', ids)
  },
  data() {
    return {
      fields: FIELDS,
      included: INCLUDED,
      show: true,
      selected: []
    }
  },
  methods: {
    onRowClick(row) {
      EVENT.$emit('exam-details-modal', row, true)
    },
    onFiltered(exams, number) {
      this.show = Boolean(number)
    },
    onChangeAll(changeAll) {
      const ids = this.exams.map((exam) => exam.id)

      if (changeAll) {
        this.selected = ids
        this.$emit('add-selected', ids)
      } else {
        this.selected = []
        this.$emit('remove-selected', ids)
      }
    },
    onCheckboxChange(id) {
      const index = this.selected.indexOf(id)

      if (index !== -1) {
        const removed = this.selected.splice(index, 1)
        this.$emit('remove-selected', removed)
        return
      }

      this.selected.push(id)
      this.$emit('add-selected', [id])
    },
    isChecked(id) {
      return this.selected.includes(id)
    },
    updateSelected(removedIds) {
      this.selected = this.selected.filter(id => !removedIds.includes(id))
    },
    clearSelected() {
      this.selected = []
    },
    getColumnWidth(key) {
      let width

      switch(key) {
        case 'checked':
          width = '50px'
          break
        case 'statusText':
          width = '150px'
          break
        case 'logEvents':
          width = '100px'
          break
        case 'logWarnings':
          width = '100px'
          break
        case 'logLastEventUTC':
          width = '200px'
          break
        case 'info':
          width = '110px'
          break
        default:
          width = '170px'
      }

      return width + ' !important'
    }
  },
  computed: {
    allChecked() {
      return this.selected.length === this.exams.length
    }
  }
}
</script>

<style lang="scss" scoped>
.status-text-fresh {
  color: #194a52;
}

.status-text-in-progress {
  color: #703702;
}

.status-text-complete {
  color: #375f01;
}

.status-text-manual-scoring {
  color: #523055;
}

.status-text-suspended {
  color: #560b12;
}

.status-text-abandoned {
  color: #765f12;
}

.progress .progress-bar {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
