<template>
    <div v-if="preview">
        <font-awesome-icon :icon="preview" />
    </div>

    <div v-else>
        <b-input @input="onSearchInput" class="mb-2" placeholder="Search Icons..." />

        <div @click="onIconClick" class="border icon-picker-container mb-4 p-1">
            <span v-for="(icon, index) in filteredIcons" :class="active(icon)" :data-icon="icon" :key="index" class="clickable">
                <font-awesome-icon :icon="icon" class="not-clickable" />
            </span>
        </div>
    </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'

export default {
    name: 'IconPicker',
    props: {
        preview: {
            type: String
        },
        selected: {
            type: String
        }
    },
    data () {
        const fas = Object.keys(library.definitions.fas).filter(icon => icon !== 'font-awesome-logo-full')

        return {
            icons: fas,
            searchRegex: null
        }
    },
    methods: {
        active (icon) {
            return this.selected === icon ? 'icon-active' : null
        },
        onIconClick (event) {
            const icon = event.target?.dataset?.icon

            if (!icon) return

            this.$emit('icon-selected', icon)
        },
        onSearchInput (value) {
            if (!value) return this.searchRegex = null

            this.searchRegex = new RegExp(`${ value }.*`, "i")
        }
    },
    computed: {
        filteredIcons () {
            if (!this.searchRegex) return this.icons

            return this.icons.filter(icon => this.searchRegex.test(icon))
        }
    }
}
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}

.not-clickable {
    pointer-events: none;
}

.icon-active {
    background: #9e5da3;
    color: #fff;
    &:hover {
        background: #9e5da3!important;
        color: #fff!important;
    }
}

.icon-picker-container {
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}
.icon-picker-container span {
    flex: auto;
    width: 40px;
    border: 1px solid #eee;
    padding: 10px; 
    margin: 3px;
    text-align:center;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
        background: #eee;
    }
}
</style>
