<template>
  <b-dropdown 
    variant="primary" 
    ref="dropdown"
  >
    <template v-slot:button-content>
      <span v-if="name" class="text-capitalize text-white">
        {{ name }}
        <small style="font-size: .65em;">
          <b-badge v-if="displayFreeBadge()" variant="white">Free</b-badge>
        </small>
      </span>
      <b-spinner label="Small Spinner" small v-else></b-spinner>
    </template>

    <div v-if="isProject">
      <b-dropdown-item
        disabled
        class="nav-divider"
        v-if="hasPages('content')"
      >
        <div>Content</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(page, index) in groupedPages.content"
        :key="index + 'content'"
        :to="routeOptions(page)"
      >
        {{ page.name }}
      </b-dropdown-item>
      <b-dropdown-item
        disabled
        class="border-top border-primary nav-divider"
        v-if="hasPages('exam')"
      >
        <div>Exam</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(page, index) in groupedPages.exam"
        :key="index + 'exam'"
        :to="routeOptions(page)"
      >
        {{ page.name }}
      </b-dropdown-item>
      <b-dropdown-item
        disabled
        class="border-top border-primary nav-divider"
        v-if="hasPages('data')"
      >
        <div>Data</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(page, index) in groupedPages.data"
        :key="index + 'data'"
        :to="routeOptions(page)"
      >
        {{ page.name }}
      </b-dropdown-item>
      <b-dropdown-item
        disabled
        class="border-top border-primary nav-divider"
        v-if="hasPages('project')"
      >
        <div>Project</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(page, index) in groupedPages.project"
        :key="index + 'project'"
        :to="routeOptions(page)"
      >
        {{ page.name }}
      </b-dropdown-item>
      <b-dropdown-item
        disabled
        class="border-top border-primary nav-divider"
        v-if="hasPages('apps')"
      >
        <div>Integrated Apps</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="(page, index) in groupedPages.apps"
        :key="index + 'apps'"
        :to="routeOptions(page)"
      >
        {{ page.name }}
      </b-dropdown-item>
    </div>

    <div v-else>
      <b-dropdown-item
        :key="index"
        :to="routeOptions(page)"
        class="text-capitalize"
        v-for="(page, index) in pagesToDisplay"
        >{{ page.name }}</b-dropdown-item
      >
    </div>
  </b-dropdown>
</template>

<script>
  import { SESSION } from '../utils/session'
  import { IS_UPGRADE_TO_PREMIUM_ENABLED } from '../utils/constants'
  import get from 'lodash.get'

  export default {
    name: 'ResourceDropdown',
    created() {     
      this.my_permissions = get(SESSION.project, 'my_permissions', [])

      this.dropdownContent()
      this.$root.$on('hide-dropdown', this.hideDropdown)
    },
    beforeDestroy() {
      this.$root.$off('hide-dropdown', this.hideDropdown)
    },
    props: {
      name: {
        type: String
      },
      resource: {
        type: Object
      },
      isProject: {
        type: Boolean
      },
      isPremium: {
        type: Boolean
      }
    },
    watch: {
      $route() {
        this.dropdownContent()
      },
      resource() {
        this.dropdownContent()
      }
    },
    data() {
      return {
        projectPages: [
          { 
            name: 'Items', 
            routeName: 'projectItems', 
            group: 'content',
            rules: [
              {
                restriction: 'one',
                perms: ['view_items']
              }
            ]
          },
          { 
            name: 'Languages', 
            routeName: 'languagesHome', 
            group: 'content',
            rules: [
              {
                restriction: 'one',
                perms: ['manage_translations']
              }
            ]
          },
          {
            name: 'Flows',
            routeName: 'projectFlows',
            group: 'exam',
            rules: [
              {
                restriction: 'one',
                perms: ['view_exam_settings']
              }
            ]
          },
          {
            name: 'Forms',
            routeName: 'projectForms',
            group: 'exam',
            rules: [
              {
                restriction: 'one',
                perms: ['manage_forms']
              }
            ]
          },
          {
            name: 'Launch Options',
            routeName: 'projectLaunchOptions',
            group: 'exam',
            rules: [
              {
                restriction: 'one',
                perms: ['view_exam_settings', 'manage_integrations']
              }
            ]
          },
          {
            name: 'Deliveries',
            routeName: 'projectDeliveries',
            group: 'data',
            rules: [
              {
                restriction: 'all',
                perms: ['view_deliveries']
              }
            ]
          },
          {
            name: 'Import',
            routeName: 'projectImport',
            group: 'data',
            rules: [
              {
                restriction: 'all',
                perms: ['view_items', 'edit_items', 'create_items', 'delete_items']
              }
            ]
          },
          {
            name: 'Export',
            routeName: 'projectExport',
            group: 'data',
            rules: [
              {
                restriction: 'one',
                perms: ['export_data']
              }
            ]
          },
          {
            name: 'Settings',
            routeName: 'projectSettings',
            group: 'project',
            rules: [
              {
                restriction: 'one',
                perms: ['view_exam_settings']
              }
            ]
          },
          {
            name: 'Access',
            routeName: 'projectAccess',
            group: 'project',
            rules: [
              {
                restriction: 'one',
                perms: ['manage_integrations', 'manage_users']
              }
            ]
          },
          {
            name: 'Activity Logs',
            routeName: 'projectActivityLogs',
            group: 'project',
            rules: [
              {
                restriction: 'all',
                perms: ['view_activity_logs']
              }
            ]
          }
        ],
        organizationPages: [
          { name: 'Billing', routeName: 'orgBilling' },
          { name: 'Dashboard', routeName: 'orgDashboard' },
          { name: 'Access', routeName: 'orgGroups' },
          { name: 'Settings', routeName: 'orgSettings' }
        ],
        appPages: [
          { name: 'App Settings', routeName: 'appSettings' },
          { name: 'App Access', routeName: 'appAccess' },
          { name: 'App Projects', routeName: 'appProjects' }
        ],
        pagesToDisplay: [],
        groupedPages: {
          content: [],
          exam: [],
          data: [],
          project: [],
          apps: []
        },
        my_permissions: []
      }
    },
    methods: {
      dropdownContent() {
        if (this.$route.path.startsWith('/projects')) {
          let additionalPages = []

          const allowedPages = this.projectPages.filter(page => {
            const isAllowed = this.isAllowed(page.rules)
            return isAllowed
          })

          if (allowedPages.length === this.projectPages.length) {
            additionalPages = this.additionalPages()
          }

          const pages = [...allowedPages, ...additionalPages]
          const groups = {
            content: [],
            exam: [],
            data: [],
            project: [],
            apps: []
          }

          for (const page of pages) {
            if (page.routeName === 'projectFlows') {
              page.name = `Flow${ this.resource?.flow_count > 1 ? 's' : '' }`
            }

            if (page.routeName === 'projectForms') {
              page.name = `Form${ this.resource?.form_count > 1 ? 's' : '' }`
            }

            groups[page.group].push(page)
          }

          this.groupedPages = groups
        } else if (this.$route.path.startsWith('/organizations')) {
          this.pagesToDisplay = this.organizationPages
        } else if (this.$route.path.startsWith('/apps')) {
          this.pagesToDisplay = this.appPages
        }
      },
      additionalPages() {
        const pages = []
        if (this.resource) {
          for (const integration of this.resource.integrations) {
            const isFullPage = integration.app.app_settings.content.full_page
            const isDashboard = integration.app.app_settings.content.dashboard_widget

            if (isFullPage || isDashboard) {
              const page = {
                name: integration.app.first_name,
                routeName: 'projectAppFull',
                appId: integration.app_caveon_id,
                group: 'apps'
              }
              pages.push(page)
            }
          }

          if (!this.isPremium && IS_UPGRADE_TO_PREMIUM_ENABLED) {
            let routeName = 'orgBilling'
            const orgId = this.resource.organization_id

            if (!orgId) {
              routeName = 'projectSettings'
            }
            pages.push({
              name: 'Upgrade to Premium',
              routeName,
              orgId,
              group: 'project'
            })
          }
        }
        return pages
      },
      routeOptions(page) {
        const { routeName, appId, orgId } = page
        const route = { name: routeName, params: {} }
        if (appId) {
          route.params.appId = appId
        }
        if (orgId) {
          route.params.orgId = orgId
        }
        return route
      },
      displayFreeBadge() {
        return Boolean(this.isProject && !SESSION.isPremium())
      },
      isAllowed(rules) {
        // Check each rule is satisfied
        for (const rule of rules) {
          // Must have ALL of the listed permissions
          if (rule.restriction === 'all') {
            if (!rule.perms.every(p => { 
              if (this.my_permissions.includes(p)) {
                return true
              }
              return false
             })) {
              return false
            }
          }
          // Must have at least ONE of the listed permissions
          if (rule.restriction === 'one') {
            let isAllowed = false
            for (const p of this.my_permissions) {
              if (rule.perms.includes(p)) {
                isAllowed = true
                break
              }
            }
            if (!isAllowed) {
              return false
            }
          }
        }
        // No rules failed
        return true
      },
      hasPages(group) {
        return this.groupedPages[group].length
      },
      hideDropdown() {
        this.$refs.dropdown.hide()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-divider {
    margin-left: 0px;
    text-transform: uppercase;
    font-size: 0.75em;
    div {
      margin-left: -12px;
    }
  }
</style>
