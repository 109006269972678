<template>
  <div v-if="!avoid.includes(this.$route.name)">
    <div class="container-fluid">
      <b-navbar class="row dropdown-menu-purple" type="dark" variant="dark">
        <b-col class="pl-0">
          <div class="d-block d-sm-inline mb-1">
            <b-button-group class="mr-2">
              <b-dropdown v-if="!isAppAlias" no-caret variant="primary">
                <template v-slot:button-content>
                  <font-awesome-icon icon="bars"></font-awesome-icon>
                </template>
                <b-dropdown-item title="Projects" to="/projects"
                  >Projects</b-dropdown-item
                >
                <b-dropdown-item title="Apps" to="/apps">Apps</b-dropdown-item>
                <b-dropdown-item to="/organizations">Orgs</b-dropdown-item>
                <b-dropdown-item to="/examwatch">Exam Watch</b-dropdown-item>
                <b-dropdown-item v-b-modal.caveonid-modal title="Account">{{
                  userTitle
                }}</b-dropdown-item>
              </b-dropdown>
            </b-button-group>

            <b-button-group class="mr-2" v-if="display">
              <ResourceDropdown
                v-if="resource"
                :resource="resource"
                :name="name"
                :is-project="isProject"
                :is-premium="isPremium"
              />
            </b-button-group>
          </div>

          <b-button
            v-if="!display"
            class="text-capitalize text-white"
            variant="link"
            disabled
          >
            {{ pageTitle }}
          </b-button>
          <b-button-group class="mr-2" v-if="display && !hideBackRoute">
            <b-button
              v-if="!backLink()"
              class="text-capitalize text-white"
              variant="link"
              disabled
              >{{ pageTitle }}</b-button
            >
            <b-button
              :to="backLink()"
              v-if="backLink()"
              class="text-capitalize"
              variant="primary"
              >{{ pageTitle }}</b-button
            >
          </b-button-group>
          <b-button
            class="text-white"
            disabled
            v-if="subPageTitle"
            variant="link"
            >{{ subPageTitle }}</b-button
          >
        </b-col>
        <b-col cols="2" md="3" class="text-right pr-0">
          <b-button
            v-b-toggle.sidebar-right
            class="notifications"
            ref="notificationCount"
          >
            <font-awesome-icon
              icon="bell"
              class="d-none d-sm-inline"
            ></font-awesome-icon>
            <span class="ml-1">{{ newNotifications }}</span>
          </b-button>
          <b-button
            variant="link"
            class="d-none d-md-inline-block p-0 ml-3"
            disabled
          >
            <img src="../assets/scorpion-logo.svg" width="25" />
          </b-button>
        </b-col>
      </b-navbar>
    </div>
    <!-- <Widgets /> -->
    <Notifications :new-notifications="newNotifications" />
    <div
      style="position:fixed; bottom:0; right: 10px; z-index:calc(1030 + 4)"
      v-if="helpPage"
    >
      <b-button
        v-b-toggle.sidebar-help
        style="border-bottom-right-radius: 0; border-bottom-left-radius:0;"
        variant="dark"
        size="sm"
      >
        <font-awesome-icon icon="book" class="mr-2"></font-awesome-icon>
        Help: {{ pageTitle }}
      </b-button>
    </div>
    <b-sidebar
      backdrop
      backdrop-variant="black"
      id="sidebar-help"
      title="Help"
      shadow
      right
      bg-variant="light"
      width="75%"
      v-if="helpPage"
      lazy
      sidebar-class="border-left border-medium"
    >
      <b-container class="border-top p-3 mb-2" fluid>
        <CaveonContent :help-page="helpPage" :page-title="pageTitle" />
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
  import { SESSION } from '../utils/session'
  import { socketProvider } from '../utils/sockets'
  import CaveonContent from './CaveonContent'
  import ResourceDropdown from './ResourceDropdown'
  import Notifications from './TopNavigationNotifications'
  // import Widgets from './TopNavigationWidgets'

  export default {
    name: 'TopNavigation',
    components: {
      ResourceDropdown,
      Notifications,
      // Widgets,
      CaveonContent
    },
    async created() {
      this.createButtons()
    },
    props: {
      resource: {
        type: Object
      },
      isProject: {
        type: Boolean
      },
      isPremium: {
        type: Boolean
      }
    },
    watch: {
      resource(newSource) {
        if (!newSource.hasOwnProperty('name')) { // eslint-disable-line no-prototype-builtins
          this.name = newSource.first_name
        } else {
          this.name = newSource.name
        }
      },
      newNotifications(newCount) {
        if (!newCount) {
          return
        }

        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          const button = this.$refs.notificationCount
          button.classList.remove('new-notification')

          setTimeout(() => {
            button.classList.add('new-notification')
          }, 10)
        }, 1000)
      }
    },
    data() {
      return {
        avoid: ['splash', 'notFound'],
        base: ['dashboard', 'projects', 'apps', 'orgs', 'examWatch'],
        name: '',
        route: '',
        display: false,
        selected: 'all'
      }
    },
    methods: {
      createButtons() {
        const { name, path } = this.$route
        this.display = !this.base.includes(name)
        if (path.startsWith('/projects')) {
          this.route = 'projectItems'
        } else if (path.startsWith('/organizations')) {
          this.route = 'orgDashboard'
        } else if (path.startsWith('/apps')) {
          this.route = 'appDashboard'
        }
      },
      backLink() {
        if (this.$route.meta.backLink) {
          return { name: this.$route.meta.backLink }
        }
      }
    },
    computed: {
      hideBackRoute () {
        const hideFlowsRoute = this.$route.name === 'projectFlow' && this.resource?.flow_count < 2

        const hideFormsRoute = this.$route.name === 'projectFormEditor' && this.resource?.form_count < 2

        return hideFlowsRoute || hideFormsRoute
      },
      userTitle() {
        const shorterFirst = SESSION.user.first_name[0] + '.'
        return `${shorterFirst} ${SESSION.user.last_name}`
      },
      pageTitle() {
        return this.$route.meta.title
      },
      subPageTitle() {
        return this.$route.meta.subPage
      },
      helpPage() {
        return this.$route.meta.helpPage
      },
      newNotifications() {
        return socketProvider.state.newNotifications
      },
      isAppAlias () {
        return SESSION.isAppAlias
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '../styles/variables.scss';
  .notifications {
    padding: 0 6px;
    font-size: 0.75em;
    border-radius: 8px;
  }
  .notifications-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .notifications-top {
    height: 100px;
  }
  .notifications-box {
    background: white;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .list-group-item {
    padding: 0.25rem 1rem 0.25rem 2rem;
  }
  .notification-badge {
    float: left;
    margin: 0 0 0 -25px;
    height: 63px;
    width: 13px;
    color: rgba(0, 0, 0, 0);
  }
  .navbar {
    z-index: 1021;
  }
  .navbar-brand {
    font-size: 1em;
  }
  .bottom-links {
    flex-direction: row;
    .list-group-item {
      background: $scorpion-primary-medium;
      text-align: center;
      &:hover {
        background: darken($scorpion-primary, 5%);
      }
    }
  }
  .icon-box {
    text-transform: uppercase;
    font-size: 0.7em;
    color: $scorpion-white;
    svg {
      font-size: 3em;
    }
  }
  .navbar .list-group-item,
  .navbar .list-group-item-action:hover {
    background: $scorpion-primary;
    color: $scorpion-white;
    transition: all 0.25s;
    &:hover {
      background: darken($scorpion-primary, 5%);
    }
  }
  .breadcrumb {
    display: inline-flex;
  }
  li.breadcrumb-item {
    display: inline-block;
  }
  .dropdown-menu svg {
    margin: 0 15px 0 0;
  }
  .new-notification {
    animation-name: expand;
    animation-duration: 0.8s;
  }
  @keyframes expand {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
