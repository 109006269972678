import get from 'lodash.get'

export const initMetaHelper = function(project) {
  const customFields = get(project, 'meta.scorpion.custom', {})
  const customFieldOrder = get(project, 'meta.scorpion.custom_field_order', [])
  const hasCustomFields = Boolean(customFieldOrder.length)

  return {
    customFields,
    customFieldOrder,
    hasCustomFields,
    getOptions() {
      return customFieldOrder.map(fieldId => ({
        text: customFields[fieldId].name,
        value: fieldId
      }))
    },
    getFilterableOptions() {
      return customFieldOrder
        .filter(fieldId => !customFields[fieldId].by_option)
        .map(fieldId => ({
          text: customFields[fieldId].name,
          value: fieldId
        }))
    },
    getFieldType(fieldId) {
      return get(customFields[fieldId], 'type', '')
    },
    getField(fieldId) {
      return customFields[fieldId]
    },
    getFieldOptions(fieldId) {
      const optionOrder = get(customFields[fieldId], 'option_order', [])

      return optionOrder.map(optionId => {
        return {
          text: customFields[fieldId].options[optionId].text,
          value: optionId,
          color: customFields[fieldId].options[optionId].color,
          disabled: customFields[fieldId].options[optionId].is_divider
        }
      })
    },
    getOptionLevelFieldIds() {
      return customFieldOrder.filter(fieldId => customFields[fieldId].by_option)
    },
    getPinnedFields() {
      return customFieldOrder
        .filter(fieldId => customFields[fieldId].pinned)
        .map(fieldId => customFields[fieldId])
    },
    getByOptionFields() {
      return customFieldOrder
        .filter(fieldId => customFields[fieldId].by_option)
        .map(fieldId => customFields[fieldId])
    },
    getMetaFields() {
      return customFieldOrder
        .filter(
          fieldId =>
            !customFields[fieldId].by_option && !customFields[fieldId].pinned
        )
        .map(fieldId => customFields[fieldId])
    },
    getPreviewFields() {
      return customFieldOrder
        .filter(fieldId => customFields[fieldId].preview)
        .map(fieldId => customFields[fieldId])
    },
    getSelectFields() {
      return customFieldOrder
        .filter(fieldId => this.getFieldType(fieldId).startsWith('select'))
        .map(fieldId => customFields[fieldId])
    }
  }
}
