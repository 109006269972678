var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"data-value":_vm.selectedTags,"options":_vm.projectTags,"reduce":function (option) { return option.name.trim().toLowerCase(); },"placeholder":"Enter Tags","label":"description","multiple":_vm.multiple,"taggable":_vm.taggable,"create-option":function (value) { return ({
            name: value,
            description: '',
            verbose: '',
          }); }},on:{"input":function($event){return _vm.tagsUpdated($event)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("No results found.")])]}},{key:"option",fn:function(ref){
          var name = ref.name;
          var description = ref.description;
return [_c('div',[_c('b',[_vm._v(_vm._s(name))]),_vm._v(" "+_vm._s(description))])]}},{key:"selected-option",fn:function(ref){
          var name = ref.name;
          var description = ref.description;
          var verbose = ref.verbose;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.d700",modifiers:{"hover":true,"d700":true}}],attrs:{"title":verbose}},[_c('b',[_vm._v(_vm._s(name))]),_vm._v(" "+_vm._s(description))])]}}]),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }