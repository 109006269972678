<template>
  <b-row class="border mt-1">
    <b-col>
      <small class="d-block my-1">Response</small>
      <div class="position-relative d-inline-block">
        <img
          :src="hotspotUrl"
          @load="createPoints"
          :alt="altText"
          style="maxWidth: 100%;"
        />
        <div
          :key="'correct' + index"
          :style="correctPoint"
          v-for="(correctPoint, index) in correct"
        ></div>
        <span
          :key="'point' + index"
          :style="point"
          v-for="(point, index) in points"
        >
          <font-awesome-icon
            class="text-black"
            icon="times"
          ></font-awesome-icon>
        </span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'HotSpot',
    props: {
      item: {
        type: Object
      }
    },
    data() {
      return {
        correct: [],
        points: [],
        hotspotUrl: '',
        altText: ''
      }
    },
    created() {
      this.setHotSpotUrl()
    },
    methods: {
      setHotSpotUrl() {
        const regex = /!\[(.*)\]\((.*)\)/
        const url = this.item.for_exam.url
        if (regex.test(url)) {
          const matches = url.match(regex)
          this.altText = matches[1] || 'Hotspot'
          this.hotspotUrl = matches[2]
        }
      },
      createPoints(event) {
        const image = event.target
        const width = image.width
        const height = image.height
        for (const response of this.item.for_exam.response) {
          const x = response[0] * width
          const y = response[1] * height
          const point = {
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`
          }
          this.points.push(point)
        }

        for (const [
          index,
          spot
        ] of this.item.item_version.settings.spots.entries()) {
          let backgroundColor = '#d20202'
          if (this.item.item_version.settings.key[index]) {
            backgroundColor = '#6dd201'
          }
          const point = {
            left: `${width * spot[0][0]}px`,
            top: `${height * spot[0][1]}px`,
            right: `${width - width * spot[1][0]}px`,
            bottom: `${height - height * spot[1][1]}px`,
            border: `2px solid black`,
            position: 'absolute',
            backgroundColor,
            opacity: 0.6
          }
          this.correct.push(point)
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
