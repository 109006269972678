<template>
	<div>
		<OrgsListing @remove-orgs="removeOrgs" :fields="fieldsToDisplay" :orgs="app.organizations" :removing="removing" ref="orgListing" />

		<b-modal @hidden="addOrgModalHidden" id="add-org-modal" size="xl" title="Add Organizations">
			<b-form @submit.prevent="onSubmit" id="add-orgs-form" novalidate>
				<b-form-group label="Organizations" label-for="email-addresses-input">
					<OrgsInput @orgs-changed="orgsChanged" :options="orgs" />
				</b-form-group>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button-group>
					<b-button @click="cancel" variant="white">
						Cancel
					</b-button>

					<b-button :disabled="saving" form="add-orgs-form" type="submit" variant="secondary">
						<b-spinner v-show="saving" label="Small Spinner" small />&nbsp;Add Orgs
					</b-button>
				</b-button-group>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants'
import { EVENT } from '../../utils/event-bus'
import { VALIDATE } from '../../utils/validate'

import OrgsListing from './OrgsListing'
import OrgsInput from '../OrgsInput'

const PER_PAGE = 100

const FIELDS_TO_DISPLAY = [
	{ key: 'checked', label: '' },
	{ key: 'name', label: 'Name', sortable: true },
	{ key: 'remove', label: '' }
]

async function addOrgs (appId, orgIds) {
	try {
		const url = `${ SEI_API_BASE }/apps/${ appId }/add_orgs`

		const payload = {
			organization_ids: orgIds
		}

		const response = await HTTP.post(url, payload)

		return { data: response.data }
	} catch (error) {
		return { error }
	}
}

async function removeOrgsRequest (appId, orgIds) {
	try {
		const url = `${ SEI_API_BASE }/apps/${ appId }/remove_orgs`

		const payload = {
			organization_ids: orgIds
		}

		const response = await HTTP.post(url, payload)

		return { data: response.data }
	} catch (error) {
		return { error }
	}
}

async function getOrgsRequest (page) {
	try {
		const url = `${ SEI_API_BASE }/organizations?only=id,name&page=${ page }&per_page=${ PER_PAGE }`

		const response = await HTTP.get(url)

		return { data: response.data }
	} catch (error) {
		return { error }
	}
}

export default {
	name: 'Orgs',
	components: {
		OrgsListing,
		OrgsInput
	},
	created () {
		this.dropdownId = 'accordion-' + this._uid

		this.getOrgs()
	},
	props: {
		app: {
			type: Object
		}
	},
	data() {
		return {
			loading: true,
			saving: false,
			removing: false,
			orgs: [],
			orgsToAdd: [],
			dropdownId: '',
			fieldsToDisplay: FIELDS_TO_DISPLAY
		}
	},
	methods: {
		async getOrgs () {
			this.loading = true

			let page = 1
			let more = true

			const orgs = []

			while (more) {
				const { data, error } = await getOrgsRequest(page)

				if (error) {
					more = false

					continue
				}

				orgs.push(...data.results)

				more = data.has_more

				page++
			}

			this.orgs = orgs

			this.loading = false
		},
		orgsChanged (orgsToAdd) {
			this.orgsToAdd = orgsToAdd
		},
		addOrgModalHidden () {
			this.orgsToAdd = []
		},
		onSubmit (event) {
			const isValid = VALIDATE.validateFields(event.target)

			if (isValid) {
				this.addOrgs()
			}
		},
		async addOrgs () {
			this.saving = true

			const { error } = await addOrgs(this.app.caveon_id, this.orgsToAdd)

			this.saving = false

			if (error) {
				return EVENT.alert({
					variant: 'danger',
					message: 'Failed to add! Please try again.'
				})
			}

			this.$emit('get-app')

			this.$bvModal.hide('add-org-modal')
		},
		async removeOrgs (orgIds) {
			this.removing = true

			const { error } = await removeOrgsRequest(this.app.caveon_id, orgIds)

			this.removing = false

			if (error) {
				return EVENT.alert({
					variant: 'danger',
					message: 'Failed to delete! Please try again.'
				})
			}

			this.$emit('get-app')

			this.$refs.orgListing.clearChecked()
		}
  }
}
</script>

<style lang="scss" scoped>
</style>
