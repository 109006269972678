<template>
  <!-- Project Template -->
  <div>
      <b-modal ref="confirm-dupe-name" hide-footer size="sm" title="Confirm duplicate project name">
        <div class="d-block text-center">
          <p>{{ createForm.name }} is the name of an existing project.</p>
          <p>If you continue, you will have multiple projects with the same name.</p>
        </div>
        <br>
        <b-button-group style="float: right;">
          <b-button variant="warning" @click="cancelSubmit">Cancel</b-button>
          <b-button variant="success" @click="submit(1)">Continue with name {{ createForm.name }}</b-button>
        </b-button-group>
      </b-modal>

      <Spinner v-if="loading"></Spinner>
      <div v-else>
        <div v-if="!disableSelector">
          <b-form-group
            label="Search projects"
          >
            <b-form-input
              v-model="searchText"
            ></b-form-input>
          </b-form-group>
          <b-list-group>
            <b-list-group-item
              button
              v-for="project in filteredTemplateProjects"
              :key="project.value"
              @click="toggleSelectedProject(project)"
            >{{ project.text }}</b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <!-- Components -->
      <div>
        <div v-if="selectedProject">
          <p class="mt-3">Select components to include:</p>

          <b-checkbox v-model="templateOptions.users">Users with administrator role</b-checkbox>
          <b-checkbox v-model="templateOptions.agreements">Agreements</b-checkbox>
          <b-checkbox v-model="templateOptions.items">Items, shared content and files</b-checkbox>
          <b-checkbox v-model="templateOptions.forms" :disabled="!templateOptions.items">Forms</b-checkbox>
          <b-checkbox v-model="templateOptions.surveys" :disabled="!templateOptions.items">Surveys</b-checkbox>
          <b-checkbox v-model="templateOptions.translations">Translations</b-checkbox>
          <br>
          <div>
            <b-button
              variant="secondary"
              @click="submit()"
              :disabled="isCopying"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isCopying"
              ></b-spinner
              >&nbsp;Create copy
            </b-button>
          </div>

          <div v-if="jobLog.length">
            <ul style="list-style-type: none">
              <li v-for="(log, index) in jobLog" :key="index">
                {{ log }}
              </li>
            </ul>
            <div v-if="job.result && job.result.exam_id">
              <a :href="newProjectUrl">Open new project</a>
            </div>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
    import { HTTP } from '../utils/requests'
    import { SEI_API_BASE } from '../utils/constants'
    import { EVENT } from '../utils/event-bus'
    import Spinner from './Spinner'
    import get from 'lodash.get'

    const MAX_ATTEMPTS = 300
    const POLLING_INTERVAL_MS = 1000

    async function getAdminProjectList() {
      try {
        const url = `${SEI_API_BASE}/exams/admin_exams`
        const response = await HTTP.get(url)
        return { data: response.data }
      } catch (error) {
        return { error }
      }
    }

    async function poll(jobId) {
      const url = `${SEI_API_BASE}/jobs/${jobId}`
      const response = await HTTP.get(url)

      return response.data
    }

    async function copyProject(examId, options) {
      const url = `${SEI_API_BASE}/exams/${examId}/copy_project`
      const payload = { ...options }
      const response = await HTTP.post(url, payload)
      const jobId = response.data.job_id

      if (!jobId) {
        throw new Error('job creation failed')
      }

      return { jobId }
    }

    async function getMyProjects() {
      try {
        const url = `${SEI_API_BASE}/exams?only=id,name&page=1&per_page=99999`
        const response = await HTTP.get(url)
        const projects = response.data.results

        return { projects }
      } catch (error) {
        return { error }
      }
    }

    export default {
      name: 'ProjectTemplate',
      components: {
        Spinner
      },
      props: {
        overrideProjects: {
          type: Array
        },
        project: {
          type: Object,
          default: () => {}
        },
        createForm: {
          type: Object,
          default: () => {}
        },
        disableSelector: {
          type: Boolean
        }
      },
      data() {
        return {
          loading: true,
          selectedProject: null,
          templateProjects: [],
          templateOptions: {
            users: false,
            agreements: false,
            items: false,
            forms: false,
            surveys: false,
            translations: false
          },
          interval: null,
          polling: false,
          intervalCount: 0,
          job: {},
          jobLog: [],
          isCopying: false,
          projects: [],
          searchText: ''
        }
      },
      async created() {
        const { data: adminProjects, error: adminListError } = await getAdminProjectList()
        let myProjects, myProjectsError

        if (this.overrideProjects) {
          myProjects = [ ...this.overrideProjects ]
        } else {
          const myProjectsResponse = await getMyProjects()

          myProjectsError = myProjectsResponse.error
          myProjects = myProjectsResponse.projects
        }

        const hasError = adminListError || myProjectsError

        this.loading = false
          
        if (hasError) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to get project list. Please try again.'
          })

          return
        }

        this.templateProjects = [
          ...this.templateProjects,
          ...myProjects
            .filter(project => adminProjects.includes(project.id))  
            .map(project => {
              return {
                value: project.id,
                text: project.name
              }
            })
        ]

        this.projects = myProjects
        this.selectedProject = this.project && this.project.id
      },
      methods: {
        cancelSubmit() {
          this.$refs['confirm-dupe-name'].hide()
        },
        async submit(confirmed) {
          const projectNames = this.projects.map(project => project.name)
          if (this.createForm.name && projectNames.includes(this.createForm.name) && !confirmed) {
            this.$refs['confirm-dupe-name'].show()
            return
          }

          this.$refs['confirm-dupe-name'].hide()

          await this.copyProjectHandler(this.selectedProject, { options: { ...this.createForm, ...this.templateOptions } })
        },
        async copyProjectHandler(projectId, projectOptions) {
          this.jobLog = []
          this.isCopying = true
          this.job = {}

          try {
            const { jobId } = await copyProject(projectId, projectOptions)

            this.initPolling(jobId)
          } catch (e) {
            this.job = {
              status: 'failed'
            }
            this.clearPolling()
          }
        },
        initPolling(jobId) {
          this.polling = true
          this.interval = setInterval(async () => {
            try {
              if (this.intervalCount >= MAX_ATTEMPTS) {
                throw new Error('max attempts reached')
              }

              this.intervalCount++
              const response = await poll(jobId)

              if (response.status === 'failed') {
                throw new Error(response.status)
              }

              this.job = response

              const workingOn = get(response, 'meta.working_on', '')
              const newStatus =
                this.jobLog.length === 0 ||
                (this.jobLog.length &&
                  this.jobLog[this.jobLog.length - 1] !== workingOn)

              if (workingOn && newStatus) {
                this.jobLog.push(workingOn)
              }

              if (response.result) {
                this.clearPolling()
              }
            } catch (e) {
              const failed = 'Copy failed'

              this.jobLog.push(failed)
              EVENT.alert({
                variant: 'danger',
                message: failed
              })
              this.job = {
                status: 'failed'
              }

              this.clearPolling()
            }
          }, POLLING_INTERVAL_MS)
        },
        clearPolling() {
          this.polling = false
          this.isCopying = false
          clearInterval(this.interval)
        },
        toggleSelectedProject(project) {
          if (this.selectedProject === project.value) {
            this.selectedProject = null
          } else {
            this.selectedProject = project.value
          }
        }
      },
      computed: {
        newProjectUrl() {
          if (!this.job.result) return ''

          const newProjectId = this.job.result.exam_id

          return `${window.location.origin}/projects/${newProjectId}`
        },
        filteredTemplateProjects() {
          if (this.selectedProject) return this.templateProjects.filter(project => project.value === this.selectedProject)
          if (this.searchText) return this.templateProjects.filter(project => project.text.toLowerCase().startsWith(this.searchText))

          return this.templateProjects
        }
      }
    }
  </script>
  
  <style lang="scss" scoped></style>

  