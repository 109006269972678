<template>
  <b-row class="border mt-1">
    <b-col>
      <b-table :items="rows" hover responsive small striped>
        <template v-slot:cell(option)="row">
          <div v-markdown="row.item.option"></div>
        </template>
        <template v-slot:cell(response)="row">
          <font-awesome-icon
            icon="check"
            v-if="row.item.response"
          ></font-awesome-icon>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Likert',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.createTableData()
    },
    data() {
      return {
        rows: []
      }
    },
    methods: {
      createTableData() {
        for (const [index, option] of this.item.for_exam.options.entries()) {
          const row = {
            option,
            response: this.isSelected(index)
          }
          this.rows.push(row)
        }
      },
      isSelected(index) {
        return this.item.for_exam.values[index] === this.item.for_exam.response
      }
    }
  }
</script>

<style lang="scss" scoped></style>
