<template>
  <b-container class="mt-3" fluid v-if="!loading">
    <TranslationModalWrapper
      :modalId="modalId"
      :closeModal="closeModal"
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      :mode="mode"
      @changes="handleModalChanges"
      @previous="previous"
      @next="next"
    >
      <template v-slot:extraButtons>
        <TranslateManyContentButton v-if="!isPreview" :project="project" :language="language" class="ml-3" :object="active" />
      </template>

      <template v-slot:name>
        <b-badge
          class="text-capitalize statuses"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          v-if="active.isComplete"
          >C</b-badge
        >
        <b-badge
          class="text-capitalize statuses"
          variant="warning"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          v-if="!active.isComplete"
          >I</b-badge
        >
        {{ active.name }}
      </template>

      <template v-slot:content>
        <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Instructions</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.settings.instructions" v-model="active.form.settings.instructions" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled :value="active.settings.instructions" style="flex:1;" class="m-1"></b-textarea>
          <b-textarea v-if="isEdit" v-model="active.form.settings.instructions" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.settings.instructions || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Score report content</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.settings.score_report_content" v-model="active.form.settings.score_report_content" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled :value="active.settings.score_report_content" style="flex:1;" class="m-1"></b-textarea>
          <b-textarea v-if="isEdit" v-model="active.form.settings.score_report_content" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.settings.score_report_content || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Score report preface</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.settings.score_report_preface" v-model="active.form.settings.score_report_preface" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled :value="active.settings.score_report_preface" style="flex:1;" class="m-1"></b-textarea>
          <b-textarea v-if="isEdit" v-model="active.form.settings.score_report_preface" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.settings.score_report_preface || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Score report pass message</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.settings.score_report_pass_message" v-model="active.form.settings.score_report_pass_message" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled :value="active.settings.score_report_pass_message" style="flex:1;" class="m-1"></b-textarea>
          <b-textarea v-if="isEdit" v-model="active.form.settings.score_report_pass_message" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.settings.score_report_pass_message || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Score report fail message</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.settings.score_report_fail_message" v-model="active.form.settings.score_report_fail_message" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled :value="active.settings.score_report_fail_message" style="flex:1;" class="m-1"></b-textarea>
          <b-textarea v-if="isEdit" v-model="active.form.settings.score_report_fail_message" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.settings.score_report_fail_message || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <div v-for="(group, groupIndex) in active.groups" :key="group.id">
          <b-row class="pt-2 mx-3 flex-wrap flex-column flex-md-row">
            <b-col cols="12" md="6" class="p-1">
              <p class="mb-2">Section Name</p>
            </b-col>
            <b-col cols="12" md="6" class="p-1">
              <b-row align-v="stretch">
                <b-col>
                   <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="group.name" v-model="active.form.groups[groupIndex].name" />
                   <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                </b-col>
              </b-row>
            </b-col>
            <b-input disabled :value="group.name" style="flex:1;" class="m-1"></b-input>
            <b-input v-if="isEdit" :value="active.form.groups[groupIndex].name" @change="handleGroupChanges('name', groupIndex, $event)" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-input>
            <div v-if="isPreview" v-markdown="active.form.groups[groupIndex].name || ''" class="preview-box" v-direction="language.properties.direction"></div>
          </b-row> 

          <b-row class="border-bottom pb-3 mx-3 flex-wrap flex-column flex-md-row">
            <b-col cols="12" md="6" class="p-1">
              <p class="mb-2">Section Instructions</p>
            </b-col>
            <b-col cols="12" md="6" class="p-1">
              <b-row align-v="stretch">
                <b-col>
                  <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="group.instructions" v-model="active.form.groups[groupIndex].instructions" />
                  <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                </b-col>
              </b-row>
            </b-col>
            <b-textarea disabled :value="group.instructions" style="flex:1;" class="m-1"></b-textarea>
            <b-textarea v-if="isEdit" :value="active.form.groups[groupIndex].instructions" @change="handleGroupChanges('instructions', groupIndex, $event)" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-textarea>
            <div v-if="isPreview" v-markdown="active.form.groups[groupIndex].instructions || ''" class="preview-box" v-direction="language.properties.direction"></div>
          </b-row>
        </div>
      </template>

      <template v-slot:saveBtns>
        <b-button-group>
          <b-button @click="save(0)" variant="warning" class="mr-1" size="sm">Save as incomplete</b-button>
          <b-button @click="save(1)" size="sm">Save as Complete</b-button>
        </b-button-group>
      </template>
    </TranslationModalWrapper>

    <TranslationsStickyTools>
      <template v-slot:columns>
        <b-col cols="12" sm="6" md="3" class="mb-1">
          <b-select
            class="ml-1"
            size="sm"
            name="live-latest"
            :options="LIVE_OR_LATEST_OPTIONS"
            v-model="liveOrLatest"
            @change="changeLiveOrLatest()"
            v-if="showFilters"
          ></b-select>
        </b-col>
        <b-col cols="12" sm="6" md="3" class="mb-1">
          <b-form @submit="search()" v-if="showFilters">
            <b-input-group>
              <b-input size="sm" type="text" placeholder="Search" v-model="searchText"></b-input>
              <b-button size="sm" type="submit" variant="white">Search</b-button>
            </b-input-group>
          </b-form>
        </b-col>
        <b-col cols="6" sm="6" md="3" class="mb-1">
          <b-select size="sm" :value="helper.status" @change="filter($event)" v-if="showFilters">
            <option value="">All</option>
            <option value="not_complete">Incomplete</option>
            <option value="complete">Complete</option>
          </b-select>
        </b-col>
        <b-col cols="6" md="3" class="mb-1">
          <b-pagination
            size="sm"
            v-if="showPagination"
            :per-page="perPage"
            :total-rows="total"
            :value="page"
            class="float-right mb-0"
            @change="changePage"
          ></b-pagination>
        </b-col>
      </template>
    </TranslationsStickyTools>

    <div v-if="forms.length">
      <b-button v-for="form in forms" :key="form.id" @click="setActive(form.id)" class="w-100 border-bottom text-left d-block pb-2" variant="text" size="sm">
        <b-row>
          <b-col cols="2" md="1">
            <b-badge
              class="text-capitalize statuses"
              v-b-tooltip="{ trigger: 'hover', interactive: false }"
              v-if="form.isComplete"
              >C</b-badge
            >
            <b-badge
            class="text-capitalize statuses"
            variant="warning"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="!form.isComplete"
            >I</b-badge
          >
          </b-col>
          <b-col cols="5">
            <div>{{ form.name }}</div>
          </b-col>
          <b-col cols="5" v-if="showLanguageName">
            <div>{{ language.name }}</div>
          </b-col>
        </b-row>
      </b-button>
    </div>
    <div v-else>Nothing to translate</div>
  </b-container>

  <Spinner v-else />
</template>
<script>
import Spinner from '../Spinner'
import { TranslationsHelper, TranslationType, TranslationModes } from '../../utils/translation'
import TranslationModalWrapper from './TranslationModalWrapper.vue'
import TranslationsStickyTools from './TranslationsStickyTools.vue'
import TranslateContentButton from './TranslateContentButton.vue'
import { EVENT } from '../../utils/event-bus'
import TranslateManyContentButton from './TranslateManyContentButton.vue'


export default {
  name: 'LanguagesTranslationsForms',
  components: {
    Spinner,
    TranslationModalWrapper,
    TranslationsStickyTools,
    TranslateContentButton,
    TranslateManyContentButton
  },
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    },
    formId: {
      type: String
    },
    versionNumber: {
      type: Number
    },
    uiOptions: {
      type: Object,
      default() {
        return {
          showFilters: true,
          showLanguageName: false
        }
      }
    }
  },
  data() {
    return {
      loading: true,
      helper: null,
      forms: [],
      liveOrLatest: 'live',
      LIVE_OR_LATEST_OPTIONS: [
        {text: 'Live form versions', value: 'live'},
        {text: 'Latest form versions', value: 'latest'}
      ],
      active: {},
      activeIndex: -1,
      perPage: 15,
      page: 1,
      total: 0,
      mode:  TranslationModes.PREVIEW,
      EMPTY: {settings: {}, form: {settings: {}}},
      searchText: ''
    }
  },
  async created() {
    this.active = {...this.EMPTY}
    this.helper = TranslationsHelper.Factory(this.project.id, this.language.id, TranslationType.form)

    this.languageOptions = [{value: this.language.id, text: this.language.name}]
    this.searchText = this.helper.searchText

    await this.fetchPage()
  },
  methods: {
    async fetchPage() {
      this.loading = true
      this.helper.setPagination({page: this.page, perPage: this.perPage})

      await this.fetchContent()
      await this.fetchTranslations()

      this.loading = false
    },
    async fetchContent() {
      await this.helper.fetchContent({ isLive: this.liveOrLatest === 'live', formId: this.formId, versionNumber: this.versionNumber })

      this.forms = this.helper.things
      this.total = this.helper.total
    },
    async fetchTranslations() {
      await this.helper.fetchTranslations()

      this.forms.forEach(form => {
        const {id} = form

        if (this.helper.translations[id]) {
          form.applyTranslation(this.helper.translations[id])
        }
      })
    },
    async save(isComplete) {
      const { error } = await this.active.save(isComplete)

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save! Please try again.'
        })
      }

      EVENT.alert({
        variant: 'success',
        message: 'Saved'
      })
    },
    setActive(id) {
      const activeIndex = this.forms.map(form => form.id).indexOf(id)

      if (activeIndex < 0 || activeIndex >= this.forms.length) return {}

      const activeContent = this.forms[activeIndex]

      this.activeIndex = activeIndex
      this.active = activeContent
      this.$bvModal.show(this.modalId)
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
      this.active = {...this.EMPTY}
    },
    previous() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
      }

      this.active = this.forms[this.activeIndex]
    },
    next() {
      if (this.activeIndex < this.forms.length - 1) {
        this.activeIndex = this.activeIndex + 1
      }

      this.active = this.forms[this.activeIndex]
    },
    handleModalChanges(changes) {
      Object.keys(changes).forEach(key => {
        this[key] = changes[key]
      })
    },
    async changePage(page) {
      this.page = page
      await this.fetchPage()
    },
    handleGroupChanges(key, groupIndex, value) {
      if (groupIndex >= 0 && groupIndex < this.active.form.groups.length) {
        this.active.form.groups[groupIndex][key] = value
      }
    },
    async search() {
      this.helper.setFilters({searchText: this.searchText})

      await this.fetchPage()
    },
    async filter(status) {
      this.helper.setFilters({status})

      await this.fetchPage()
    },
    changeLiveOrLatest() {
      this.fetchPage()
    }
  },
  computed: {
    type() {
      return TranslationType[`form_${this.liveOrLatest}`]
    },
    showPagination() {
      return this.total > this.perPage
    },
    modalId() {
      return `edit-modal-${this.language.id}`
    },
    isEdit() {
      return this.mode === 'edit'
    },
    hasPrevious() {
      return this.activeIndex > 0
    },
    hasNext() {
      return this.activeIndex < this.forms.length - 1
    },
    isPreview() {
      return this.mode === 'preview'
    },
    showFilters() {
      return this.uiOptions.showFilters
    },
    showLanguageName() {
      return this.uiOptions.showLanguageName
    }
  }
}
</script>

<style lang="scss" scoped>
  .sticky-tools {
    position:sticky; 
    top: 40px; 
    z-index: 1; 
    padding: 8px 0; 
    background-color: #fff; 
    border-bottom: 1px dashed #eee;
    margin-bottom: 30px;
  }
  .preview-box {
    flex: 1; 
    border-left: 1px solid #ccc;
    margin: 5px 10px;
    padding: 5px 10px;
    max-width: 48%;
  }
  @media only screen and (max-width: 768px) {
    .preview-box {
      max-width: 100%;
    }
  }
</style>
