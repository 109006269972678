import { EVENT } from './event-bus'

import get from 'lodash.get'

const DEFAULT_STYLES = {
  borderColor: '#cfcfcf',
  boxShadow: 'none'
}

const INVALID_STYLES = {
  borderColor: '#D86A03',
  boxShadow: '0 0 2px #D86A03'
}

export const VALIDATE = {
  validateFields(form, noMessage = false, noHighlight = false) {
    let hasInvalid = false
    let firstInvalidField = null
    const requiredFields = form.querySelectorAll('[required]')

    for (const field of requiredFields) {
      let elementToHighlight = field

      if (field.hasAttribute('data-value')) {
        elementToHighlight = field.querySelector('div')
        const value = field.getAttribute('data-value')
        field.validity = { valid: Boolean(value) }
      }

      if (!noHighlight) {
        Object.assign(elementToHighlight.style, DEFAULT_STYLES)
      }

      const isValid = get(field.validity, 'valid')

      if (!isValid) {
        if (!noHighlight) {
          Object.assign(elementToHighlight.style, INVALID_STYLES)
        }

        hasInvalid = true

        if (!firstInvalidField) {
          firstInvalidField = field
        }
      }
    }

    if (hasInvalid) {
      if (!noMessage) {
        EVENT.alert({
          variant: 'danger',
          message: 'Please fill in the required fields'
        })

        firstInvalidField.scrollIntoView({ block: 'center' })
      }

      return false
    }

    return true
  }
}
