<template>
    <div>
        <b-modal title="Add Filter" size="sm" id="rule-filter-modal" no-fade @ok="addFilter" v-if="edit">
            <b-select :options="options" v-model="selected" />
            <template #modal-footer="{ cancel, ok }">
                <b-button variant="white" @click="cancel">Cancel</b-button>
                <b-button variant="primary" @click="ok">Add</b-button>
            </template>
        </b-modal>

        <b-modal title="Rule Info" size="sm" id="rule-info-modal" no-fade v-if="edit">
            {{ ruleSelectionAmount }} 
            <span v-show="Boolean(ruleSelectionFilters.length)">
                with the custom field value{{ ruleSelectionFilters.length > 1 ? 's' : '' }} of:
            </span>
            <div v-show="Boolean(ruleSelectionFilters.length)">
                <div v-for="(filter, filterIndex) in ruleSelectionFilters" :key="filterIndex" class="filter-container" data-bruh>
                    <h6 class="my-2" v-if="filterIndex">AND</h6>
                    <div class="mt-2">
                        {{ filter.name }}: 
                        <span v-for="(option, optionIndex) in filter.options" :key="optionIndex">
                            <h6 class="d-inline" v-if="optionIndex">OR</h6>
                            {{ option.disabled ? option.text + ' (Divider)' : option.text }}
                        </span>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button variant="white" @click="cancel">Close</b-button>
            </template>
        </b-modal>

        <div v-if="edit">
            <div v-for="(rule, index) in editableRules" :key="index" class="rule px-4 py-4 mb-4 mx-1">
                <b-form-group label="Number of Items" label-cols-sm="3">
                    <b-form-input 
                        type="number" 
                        min="0" 
                        placeholder="Leave blank or 0 for all" 
                        :value="rule.num_items"
                        @input="defaultNumberInputToNull($event, rule, 'num_items')"
                        v-if="edit" 
                    />

                    <div v-else class="h-100 d-flex align-items-center">{{ numItemsAsText(rule.num_items) }}</div>
                </b-form-group>

                <b-form-group v-for="(value, key, index) in rule.filters" :key="index" :label="nameLookup[key]" label-cols-sm="3">
                    <div v-if="edit">
                        <div class="bg-white">
                            <v-select
                                :options="subOptions[key]"
                                :reduce="option => option.value"
                                label="text"
                                multiple
                                placeholder="Select Values"
                                v-model="rule.filters[key]"
                            >
                                <template #selected-option-container="{ option, deselect }">
                                    <span :class="`vs__selected border ${ option.color ? 'pl-0' : ''}`">
                                        <div
                                            :style="{
                                                backgroundColor: option.color,
                                                width: '15px',
                                                height: '100%',
                                                borderRadius: '3px 0 0 3px'
                                            }"
                                            class="mr-1"
                                            v-if="option.color"
                                        />

                                        <div class="mr-2" v-else />

                                        {{ option.disabled ? `${ option.text } (Divider)` : option.text }}

                                        <button
                                            :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                                            @click.stop="deselect(option)"
                                            @mousedown.stop
                                            class="vs__deselect no-focus"
                                            type="button"
                                        >
                                            <font-awesome-icon icon="times"></font-awesome-icon>
                                        </button>
                                    </span>
                                </template>

                                <template #option="{ color, text }">
                                    <div
                                        v-if="color"
                                        :style="{ background: color }"
                                        class="option p-1 d-inline-block"
                                    />

                                    {{ text }}
                                </template>
                            </v-select>
                        </div>

                        <b-button variant="link" size="sm" class="pl-0" @click="removeFilter(rule, key)">Remove Filter</b-button>
                    </div>
                    <div v-else class="h-100 d-flex align-items-center">
                        {{ filtersAsText(key, value) }}
                    </div>
                </b-form-group>

                <div class="d-flex justify-content-between mt-4" v-if="edit">
                    <b-button variant="white" @click="openFilterModal(rule)">Add Filter</b-button>
                    <b-button variant="white" @click="removeRule(index)">
                        <font-awesome-icon icon="trash" />
                    </b-button>
                </div>

                <b-button class="mt-4 pl-0" variant="link" size="sm" @click="openRuleInfoModal(rule)">What items will this rule select?</b-button>
            </div>
        </div>

        <div v-else>
            <div class="border-bottom pb-1">
                <p class="mt-3 ml-1 mb-3"><b-button variant="primary-light" size="sm" class="mr-2 float-right" @click="openModal('selection-rules')">Edit</b-button>Selection Rules ({{ selectionRulesItemCount }})
                <b-button
                    href="#"
                    tabindex="0"
                    v-b-tooltip.focus
                    title="Selection rules generate an item pool for this section by filtering the project items."
                    variant="unstyled"
                    class="p-0"
                >
                    <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </b-button>
                </p>
                <!-- ({{ editableRules.length }}) -->

                <b-row class="mr-1 ml-1 mt-2 tables" v-for="(items, index) in preview" :key="index">
                    <b-col class="p-0 preview">
                        <b-table
                            :fields="fields"
                            :items="items"
                            class="mb-0 p-1"
                            fixed
                            small
                            striped
                        ></b-table>
                    </b-col>
                </b-row>
            </div>
        </div>

        <b-button variant="primary" class="ml-1" @click="addRule" v-if="edit">Add Rule</b-button>
    </div>
</template>

<script>
import { deepCopy, defaultNumberInputToNull, setUnion, setIntersection } from '../../utils/misc'
import { EVENT } from '../../utils/event-bus'
import { initMetaHelper } from '../../utils/meta-helper'
import { SESSION } from '../../utils/session'

function updateFilteredItemIds (fieldId, value, itemIdsByMeta, filteredItemIds) {
    const metaKey = `${ fieldId }${ String(value) }`

    const itemIdsMetaSet = itemIdsByMeta[metaKey]

    if (!itemIdsMetaSet) return filteredItemIds

    return setUnion(filteredItemIds, itemIdsMetaSet)
}

export default {
    name: 'SelectionRules',
    created() {
        this.metaHelper = initMetaHelper(SESSION.project)

        this.setSelectFieldData()

        if (!this.edit) {
            EVENT.$on('update-rules' + this.sectionIndex, this.setEditableRules)

            this.setSelectionRuleCounts(this.rules)
        }

        this.setEditableRules()
    },
    beforeDestroy() {
        if (this.edit) {
            const rulesWithEmptyFiltersRemoved = this.removeEmptyFilters()

            this.$emit('selection-rule-update', rulesWithEmptyFiltersRemoved)
        } else {
            EVENT.$off('update-rules' + this.sectionIndex)
        }
    },
    props: {
        itemMap: {
            type: Object
        },
        itemIdsByMeta: {
            type: Object
        },
        section: {
            type: Object
        },
        sectionIndex: {
            type: Number
        },
        edit: {
            type: Boolean
        }
    },
    data() {
        return {
            editableRules: [],
            options: [],
            subOptions: {},
            selected: null,
            selectedRule: {},
            metaHelper: {},
            dividerLookup: {},
            fields: [
                { key: 'field', label: 'Field', sortable: false },
                { key: 'value', label: 'Value', sortable: false }
            ],
            preview: [],
            defaultNumberInputToNull,
            total: 0
        }
    },
    methods: {
        setSelectionRuleCounts(rules) {
            let total = 0

            for (const [index, rule] of rules.entries()) {
                if (rule.num_items) {
                    total += rule.num_items
                    continue
                }

                const itemIdSets = []

                for (const [fieldId, possibleValues] of Object.entries(rule.filters)) {
                    let filteredItemIds = new Set()

                    for (const possibleValue of possibleValues) {
                        if (possibleValue in this.dividerLookup) {
                            const dividerOptionKeys = this.dividerLookup[possibleValue]

                            for (const key of dividerOptionKeys) {
                                filteredItemIds = updateFilteredItemIds(fieldId, key, this.itemIdsByMeta, filteredItemIds)
                            }
                        }

                        filteredItemIds = updateFilteredItemIds(fieldId, possibleValue, this.itemIdsByMeta, filteredItemIds)
                    }

                    itemIdSets.push(filteredItemIds)
                }

                let count

                if (itemIdSets.length) {
                    let intersection = itemIdSets.shift()

                    for (const itemIdSet of itemIdSets) {
                        intersection = setIntersection(intersection, itemIdSet)
                    }

                    count = intersection.size
                } else {
                    count = Object.keys(this.itemMap).length
                }

                rule.count = count

                this.section.selection_rules.splice(index, 1, rule) // eslint-disable-line vue/no-mutating-props

                total += count
            }

            this.total = total
        },
        setEditableRules(rules) {
            if (rules) {
                this.setSelectionRuleCounts(rules)
            } else {
                rules = this.rules
            }

            this.editableRules = deepCopy(rules)

            if (!this.edit) {
                const preview = []

                for (const rule of this.editableRules) {
                    const items = []

                    let value = rule.num_items

                    if (!rule.num_items) {
                        value = `All (${ rule.count })`
                    }

                    items.push({ field: 'Number of Items', value })

                    for (const [key, value] of Object.entries(rule.filters)) {
                        items.push({ field: this.nameLookup[key], value: this.filtersAsText(key, value) })
                    }

                    preview.push(items)
                }

                this.preview = preview
            }
        },
        addRule() {
            const rule = {
                num_items: null,
                filters: {}
            }

            this.editableRules.push(rule)
        },
        removeRule(index) {
            this.editableRules.splice(index, 1)
        },
        addFilter() {
            this.$set(this.selectedRule.filters, this.selected, [])
        },
        removeFilter(rule, key) {
            this.$delete(rule.filters, key)
        },
        openFilterModal(rule) {
            this.selected = null
            this.selectedRule = rule

            this.$bvModal.show('rule-filter-modal')
        },
        openRuleInfoModal(rule) {
            this.selectedRule = rule

            this.$bvModal.show('rule-info-modal')
        },
        setSelectFieldData() {
            const selectFields = this.metaHelper.getSelectFields()

            const options = [{ text: 'Select', value: null, disabled: true }]

            const subOptions = {}

            const nameLookup = {}

            const dividerLookup =  {}

            for (const field of selectFields) {
                const option = {
                    text: field.name,
                    value: field.id
                }

                options.push(option)

                subOptions[field.id] = this.metaHelper.getFieldOptions(field.id)

                nameLookup[field.id] = field.name

                let dividerKey

                for (const option of subOptions[field.id]) {
                    if (option.disabled) {
                        dividerKey = option.value

                        dividerLookup[dividerKey] = new Set()

                        continue
                    }

                    if (dividerKey) dividerLookup[dividerKey].add(option.value)
                }
            }

            this.options = options

            this.subOptions = subOptions

            this.nameLookup = nameLookup

            this.dividerLookup = dividerLookup
        },
        removeEmptyFilters() {
            const rules = []

            for (const editableRule of this.editableRules) {
                const rule = { num_items: editableRule.num_items, filters: {} }

                for (const [key, value] of Object.entries(editableRule.filters)) {
                    if (!value.length) continue

                    rule.filters[key] = value
                }

                rules.push(rule)
            }

            return rules
        },
        numItemsAsText(num) {
            return num ? num : 'All'
        },
        filtersAsText(key, values) {
            const subOptions = this.subOptions[key]

            const names = []

            for (const value of values) {
                const option = subOptions.find(option => option.value === value)

                if (option) {
                    let name = option.text

                    if (option.disabled) {
                        name += ' (Divider)'
                    }

                    names.push(name)
                }
            }

            return names.join(', ')
        },
        openModal(modal) {
            this.$emit('open-modal', modal)
        }
    },
    computed: {
        rules() {
            return this.section.selection_rules || []
        },
        ruleSelectionAmount() {
            const { num_items } = this.selectedRule

            let numberOfItems = 'all'
            let plural = 's'

            if (num_items) {
                numberOfItems = num_items
                
                if (num_items === 1) {
                    plural = ''
                }
            }

            return `This rule will select ${ numberOfItems } item${ plural }`
        },
        ruleSelectionFilters() {
            if (!this.selectedRule.filters) return []

            const filters = []

            for (const [key, values] of Object.entries(this.selectedRule.filters)) {
                const name = this.nameLookup[key]

                const filter = { name, options: [] }

                for (const value of values) {
                    const option = this.subOptions[key].find(option => option.value === value)

                    if (option) {
                        filter.options.push(option)
                    }
                }

                filters.push(filter)
            }

            return filters
        },
        selectionRulesItemCount() {
            return `${ this.total } Item${ this.total !== 1 ? 's' : '' }`
        }
    }
}
</script>

<style lang="scss" scoped>
.rule {
    background: #eee;
    border-radius: 3px;

    .form-group:last-of-type {
        margin-bottom: 0;
    }
}
.tables {
    border: 1px solid #ccc;
}
.preview {
    flex: 1;
    font-size: 0.8em;
    background: #fff;
}
.list {
  padding-left: 0px;
  margin-bottom: 0px;
}
.filter-container {
    overflow: auto;
    white-space: nowrap;

}
</style>
