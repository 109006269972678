<template>
  <b-row class="border mt-1">
    <b-col>
      <small>Response &nbsp; {{ bounds }}</small>
      <h6>{{ scaleResponse }}</h6>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Scale',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.formatItemData()
    },
    data() {
      return {
        bounds: ''
      }
    },
    methods: {
      formatItemData() {
        const lowerBound = this.item.for_exam.bounds[0]
        const upperBound = this.item.for_exam.bounds[1]
        this.bounds = `(${lowerBound} to ${upperBound})`
      }
    },
    computed: {
      scaleResponse() {
        const response = this.item.for_exam.response
        if (response) {
          return response
        }
        return 'No Response'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
