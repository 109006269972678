<template>
  <b-row class="border mt-1">
    <b-col>
      <small>Response</small>
      <h6>{{ itemResponse }}</h6>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'TrueFalse',
    props: {
      item: {
        type: Object
      }
    },
    computed: {
      itemResponse() {
        const response = this.item.for_exam.response
        if (typeof response === 'number') {
          return response ? 'True' : 'False'
        }
        return 'No Response'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
