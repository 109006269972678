<template>
  <b-button v-b-modal.resources variant="white">
    Resources
    <b-modal
      hide-footer
      id="resources"
      size="xl"
      title="Content Resources"
      @hidden="clearActiveTab"
      scrollable
    >
      <div v-if="!loading">
        <b-tabs content-class="mt-3" v-if="!error">
          <ResourceFiles
            :files="files"
            :multiple="true"
            @update-files="updateFiles"
          />
          <ResourceShared
            :shared-content="sharedContent"
            @update-shared="updateShared"
            :active="isActive('shared')"
          />
          <ResourceReference
            :references="references"
            @update-reference="updateReference"
            :active="isActive('reference')"
          />
          <ResourceWidgets 
            :widgets="widgets"
            :active="isActive('widgets')"
            @update-widgets="updateWidgets"
          />
        </b-tabs>
        <b-container class="mt-3" fluid v-else>
          <b-alert
            class="d-flex justify-content-between align-items-center"
            show
            variant="danger"
          >
            Failed to load resources.
            <b-button @click="loadResources()" variant="danger"
              >Reload</b-button
            >
          </b-alert>
        </b-container>
      </div>
      <Spinner v-else />
    </b-modal>
  </b-button>
</template>

<script>
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'

  import Spinner from '../Spinner'
  import ResourceFiles from './ResourceFiles'
  import ResourceShared from './ResourceShared'
  import ResourceReference from './ResourceReference'
  import ResourceWidgets from './ResourceWidgets'

  export default {
    name: 'Resource',
    components: {
      Spinner,
      ResourceFiles,
      ResourceShared,
      ResourceReference,
      ResourceWidgets
    },
    props: {
      project: {
        type: Object
      },
      activeTab: {
        type: String
      }
    },
    created() {
      this.loadResources()
    },
    data() {
      return {
        files: [],
        sharedContent: [],
        references: [],
        widgets: [],
        loading: true,
        error: false
      }
    },
    methods: {
      async loadResources() {
        try {
          this.loading = true
          await Promise.all([
            this.getFiles(),
            this.getSharedContent(),
            this.getReferences(),
            this.getWidgets()
          ])
          this.$emit('resource-loaded')
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      async getFiles() {
        const files = []
        let hasMore = true
        let page = 1
        while (hasMore) {
          const url = `${SEI_API_BASE}/exams/${this.project.id}/files?page=${page}&include=download_url,url_code,embed_code,created_at`
          const response = await HTTP.get(url)
          files.push(...response.data.results)
          hasMore = response.data.has_next
          page++
        }
        this.files = files
      },
      updateFiles(files) {
        this.files = files
      },
      async getSharedContent() {
        const sharedContent = []
        let hasMore = true
        let page = 1
        while (hasMore) {
          const url = `${SEI_API_BASE}/exams/${this.project.id}/shared_content?page=${page}`
          const response = await HTTP.get(url)
          sharedContent.push(...response.data.results)
          hasMore = response.data.has_next
          page++
        }
        this.updateShared(sharedContent)
      },
      updateShared(sharedContent) {
        this.sharedContent = sharedContent
        this.$emit('shared-updated', this.sharedContent, true)
      },
      async getReferences() {
        const references = []
        let hasMore = true
        let page = 1
        while (hasMore) {
          const url = `${SEI_API_BASE}/exams/${this.project.id}/references?page=${page}`
          const response = await HTTP.get(url)
          references.push(...response.data.results)
          hasMore = response.data.has_next
          page++
        }
        this.updateReference(references)
      },
      updateReference(references) {
        this.references = references
        this.$emit('references-updated', this.references)
      },
      async getWidgets() {
        const widgets = [
          { 
            name: 'Calculator', 
            id: 'calculator', 
            type: 'default',
            windowed: true
          },
          { 
            name: 'Notepad',
            id: 'notepad',
            type: 'default',
            windowed: true
          }
        ]

        for (const integration of this.project.integrations) {
            const { id, app: { first_name, app_settings: { content: { take_widget } } } } = integration

            if (take_widget) {
              const integrationWidget = {
                  name: first_name,
                  url: take_widget.url,
                  windowed: take_widget.windowed,
                  icon: take_widget.icon,
                  type: 'integration',
                  id
              }

              widgets.push(integrationWidget)
          }
        }

        let hasMore = true
        let page = 1

        while (hasMore) {
          const url = `${SEI_API_BASE}/exams/${this.project.id}/widgets?page=${page}`
          const response = await HTTP.get(url)
          widgets.push(...response.data.results)
          hasMore = response.data.has_next
          page++
        }

        this.updateWidgets(widgets)
      },
      updateWidgets(widgets) {
        this.widgets = widgets.sort((current, next) => current.name.localeCompare(next.name))
        this.$emit('widgets-updated', this.widgets)
      },
      isActive(tab) {
        return this.activeTab === tab
      },
      clearActiveTab() {
        this.$emit('clear-active')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
