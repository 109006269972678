<template>
  <div v-if="!loading">
    <div v-if="references.length">
      <b-table
        :fields="fields"
        :items="references"
        bordered
        hover
        responsive
        small
        striped
      >
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            variant="white"
            @click="deleteFile(row.item, row.index)"
            class="mr-2"
            >Delete</b-button
          >
        </template>
      </b-table>

      <div>
        <b-button size="sm" variant="white" @click="deleteAll" class="mr-2"
          >Delete All</b-button
        >
      </div>
    </div>
    <div class="p-2" v-else>No references to review</div>
  </div>
  <Spinner v-else />
</template>

<script>
  import Spinner from '../Spinner'

  export default {
    name: 'ReferencesPreviewTable',
    components: {
      Spinner
    },
    props: {
      references: {
        type: Array
      },
      project: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        fields: [{ key: 'name', sortable: true }, { key: 'actions' }],
        selected: {}
      }
    },
    methods: {
      deleteAll() {
        this.$emit('delete-references', -1)
      },
      deleteFile(_, index) {
        this.$emit('delete-references', index)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
