<template>
  <div>
    <TopNavigation
      :resource="project"
      :is-project="true"
      :is-premium="isPremium"
    />
    <div v-if="!loading">
      <div v-if="!error">
        <b-alert :show="!upToDate" variant="info" class="mb-0">
          App integrations have been updated and their changes need to be
          <b-link
            :to="{ name: 'projectAccess', hash: '#IntegratedApps', params: { accepting: true }}"
            >accepted</b-link
          >
        </b-alert>
        <router-view
          :is-premium="isPremium"
          :is-viewer="isViewer"
          :isSaving="isSaving"
          :key="$route.fullPath"
          :project="project"
          :up-to-date="upToDate"
          @get-project="getProject"
          @update-project="saveProject"
        />
      </div>
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load project.
          <b-button @click="reload" variant="danger">Reload</b-button>
        </b-alert>
      </b-container>
    </div>
    <!-- Loading Spinner -->
    <Spinner v-else />
  </div>
</template>

<script>
  import TopNavigation from './TopNavigation.vue'
  import Spinner from './Spinner'

  import { SEI_API_BASE, PROJECT_PAGES } from '../utils/constants'
  import { HTTP } from '../utils/requests'
  import { EVENT } from '../utils/event-bus'
  import { SESSION } from '../utils/session'
  import { deepCopy } from '../utils/misc.js'

  import get from 'lodash.get'

  function projectHasAllPermissions (permissions) {
    let hasAllPermissions = true

    for (const permission of permissions) {
      const projectHasPermission = SESSION.project.my_permissions.includes(permission)

      if (!projectHasPermission) {
        hasAllPermissions = false

        break
      }
    }

    return hasAllPermissions
  }

  function projectHasOnePermission (permissions) {
    let hasOnePermission = false

    for (const permission of permissions) {
      const projectHasPermission = SESSION.project.my_permissions.includes(permission)

      if (projectHasPermission) {
        hasOnePermission = true

        break
      }
    }

    return hasOnePermission
  }

  export default {
    name: 'Project',
    components: {
      TopNavigation,
      Spinner
    },
    created() {
      EVENT.$on(EVENT.saveProjectEvent, (project, options) => {
        this.saveProject(project, options)
      })

      EVENT.$on(EVENT.getProjectEvent, () => {
        this.getProject()
      })

      EVENT.$on(EVENT.updateFlowCountEvent, (by) => {
        this.updateFlowCount(by)
      })

      EVENT.$on(EVENT.updateFormCountEvent, (by) => {
        this.updateFormCount(by)
      })

      EVENT.$on(EVENT.reloadRouteEvent, () => {
        this.reloadRoute()
      })

      SESSION.clearItems()

      this.getProject()
    },
    beforeDestroy() {
      EVENT.$off(EVENT.saveProjectEvent)
      EVENT.$off(EVENT.getProjectEvent)
      EVENT.$off(EVENT.reloadRouteEvent)
      EVENT.$off(EVENT.updateFlowCountEvent)
      EVENT.$off(EVENT.updateFormCountEvent)
    },
    beforeRouteUpdate(to, from, next) {
      if (to.params.projectId !== from.params.projectId) {
        this.loading = true
        SESSION.clearItems()
        this.getProject(to.params.projectId)
      }

      if (to.name === 'project' && from.name !== 'projects') {
        next('/projects')
      }

      next()
    },
    data() {
      return {
        project: null,
        upToDate: true,
        loading: true,
        error: false,
        isSaving: false,
        isViewer: false,
        isPremium: false
      }
    },
    methods: {
      async getProject(projectId) {
        if (!projectId) {
          projectId = this.$route.params.projectId
        }

        try {
          let url = `${SEI_API_BASE}/exams/${projectId}?include=dashboard,organization,forms,my_role_types,role_restrictions,integrations,exporters,delivery_creation_disabled,importers,tags,flow_count,form_count,my_permissions`
          let response = await HTTP.get(url)
          this.project = response.data
          this.isViewer =
            this.project.my_role_types.includes('viewer') &&
            this.project.my_role_types.length === 1
          SESSION.project = deepCopy(response.data)
          this.isPremium = get(this.project, 'organization.is_premium')
          this.error = false

          if (this.$route.name === 'project') {
            let routeName

            for (const page of PROJECT_PAGES) {
              let permissionsMet

              if (page.requireAllPermissions) {
                permissionsMet = projectHasAllPermissions(page.permissions)
              } else {
                permissionsMet = projectHasOnePermission(page.permissions)
              }

              if (permissionsMet) {
                routeName = page.route

                break
              }
            }

            this.$router.push({ name: routeName })
          }
        } catch (error) {
          this.error = true
          this.$router.push('/error')
        } finally {
          this.loading = false
          this.upToDateCheck()
        }
      },
      updateFlowCount (by) {
        this.project.flow_count += by
      },
      updateFormCount (by) {
        this.project.form_count += by
      },
      upToDateCheck() {
        const outOfDate = this.project.integrations.filter(
          integration => !integration.is_up_to_date
        )
        this.upToDate = Boolean(!outOfDate.length)
      },
      async saveProject(project, options) {
        try {
          delete project.settings.flow
          const updateSlug = get(options, 'updateSlug', false)
          this.isSaving = true
          const url = `${SEI_API_BASE}/exams/${this.project.id}?include=tags,dashboard,organization,forms,my_role_types,my_permissions,role_restrictions,integrations,exporters,delivery_creation_disabled,importers&update_slug=${updateSlug}`
          const response = await HTTP.put(url, project)
          this.project = response.data
          SESSION.project = deepCopy(response.data)
          this.isSaving = false
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save! Please try again.'
          })
        } finally {
          this.isSaving = false
        }
      },
      reload() {
        this.loading = true
        this.getProject()
      },
      reloadRoute() {
        this.loading = true
        this.$nextTick(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
