<template>
  <div v-if="!loading">
    <div v-if="items.length">
      <br />
      <b>{{ items.length }} Item(s) Generated:</b>
      <b-table
        :fields="fields"
        :items="items"
        @row-clicked="rowClicked"
        bordered
        hover
        responsive
        small
        striped
      >
        <template v-slot:cell(name)="row">
          <div>
            {{ row.item.name }}
            <span
              v-b-tooltip="{
                trigger: 'hover',
                title:
                  'Name matches an existing item. Saving will create a new version.',
                interactive: false
              }"
              v-show="hasExistingItem(row.item)"
            >
              <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
            </span>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            @click="saveItemPreview(row.item, row.index)"
            class="mr-2"
            >Save</b-button
          >
          <b-button
            size="sm"
            variant="white"
            @click="deleteItemPreview(row.item, row.index)"
            class="mr-2"
            >Delete</b-button
          >
        </template>
        <template v-slot:cell(errMsg)="row">
          <b-button
            size="sm"
            variant="white"
            class="mr-2"
            @click="saveItemError = row.item.errMsg"
            v-b-modal.saveItemErrorModal
            v-show="row.item.errMsg"
            >Show Error</b-button
          >
        </template>
      </b-table>

      <ProgressBar :promises="tasks" />

      <div>
        <b-button size="sm" @click="saveAll" class="mr-2">Save All</b-button>
        <b-button size="sm" variant="white" @click="deleteAll" class="mr-2"
          >Delete All</b-button
        >
      </div>
    </div>
    <div class="p-2" v-else>No items to review</div>

    <b-modal id="saveItemErrorModal" title="Error" ok-only>
      <pre>{{ saveItemError }}</pre>
    </b-modal>

    <b-modal
      @ok.prevent="saveFromModal"
      ref="preview-item"
      size="xl"
      title="Preview Item"
    >
      <div v-if="selected">
        <b-form-group>
          <div>
            <label for="name">Name</label>&nbsp;
            <span
              v-b-tooltip="{
                trigger: 'hover',
                title:
                  'Name matches an existing item. Saving will create a new version.',
                interactive: false
              }"
              v-show="hasExistingItem(selected)"
            >
              <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
            </span>
            <input
              id="name"
              type="text"
              v-model="selected.name"
              class="w-100"
            />
          </div>
        </b-form-group>

        <b-form-group label="Content">
          <div>
            <label for="stem" class="d-flex justify-content-between align-items-center">
              Stem
              <b-row v-if="!hideShowMore">
                <b-col class="text-right">
                  <b-button
                    @click="toggleShowMore = !toggleShowMore"
                    size="sm"
                    variant="link"
                  >{{ optionExpandOrCollapse }}</b-button
                  >
                </b-col>
              </b-row>
            </label>
            <textarea-autosize
              class="mb-3 form-control"
              id="stem"
              v-model="selected.content.stem"
              rows="1"
              :min-height="111"
            />
          </div>
          <b-collapse :visible="toggleShowMore" class="py-2 px-3">
            <b-row class="my-3">
              <b-col lg="3" md="4" sm="6">
                <label for="feedback">Feedback</label>
              </b-col>
              <b-col lg="9" md="8" sm="6">
                <b-form-input
                  placeholder="Text"
                  v-model="selected.feedback['stem']"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-collapse>
          <ItemOptions
            :project="project"
            :fields="selected"
            :isNew="true"
            :saving="false"
            :type="selected.settings.type"
            :show-more="toggleShowMore"
          />
        </b-form-group>

        <b-card class="mb-1" no-body>
          <b-card-header class="p-1">
            <b-button
              v-b-toggle.settings-collapse
              block
              class="text-left"
              variant="primary"
            >
              Settings
            </b-button>
          </b-card-header>
          <b-collapse id="settings-collapse">
            <b-card-body>
              <ItemSettings
                :fields="selected"
                :type="selected.settings.type"
                :itemIds="allIds"
              />
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card class="mb-1" no-body>
          <b-card-header class="p-1">
            <b-button
              v-b-toggle.references-collapse
              block
              class="text-left"
              variant="primary"
            >
              References
            </b-button>
          </b-card-header>
          <b-collapse id="references-collapse">
            <b-card-body>
              <b-row
                :key="index"
                class="option"
                v-for="(reference, index) in selected.references"
              >
                <b-col lg="5" md="9">
                  {{ getReferenceName(reference.reference_id) }}
                </b-col>
                <b-col lg="5" md="9">
                  {{ reference.location }}
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card class="mb-1" no-body>
          <b-card-header class="p-1">
            <b-button
              v-b-toggle.meta-collapse
              block
              class="text-left"
              variant="primary"
            >
              Custom fields
            </b-button>
          </b-card-header>
          <b-collapse id="meta-collapse">
            <b-card-body>
              <ItemMeta :values="selected.meta.scorpion" />
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel()" variant="white">Cancel</b-button>
          <b-button @click="ok()" variant="secondary">Save</b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
  <Spinner v-else />
</template>

<script>
  import ItemMeta from './ItemMeta'
  import ItemSettings from './ItemSettings'
  import ItemOptions from './ItemOptions'
  import Spinner from '../Spinner'
  import ProgressBar from '../ProgressBar'

  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy, friendlyName } from '../../utils/misc'
  import { SESSION } from '../../utils/session'

  import get from 'lodash.get'
  import isEqual from 'lodash.isequal'

  async function getAllIds(projectId) {
    try {
      const response = await HTTP.get(
        `${SEI_API_BASE}/exams/${projectId}/items/all_ids`
      )

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function saveItem(projectId, item, itemId) {
    try {
      let requestMethod = 'post'
      let url = `${SEI_API_BASE}/exams/${projectId}/items`

      if (itemId) {
        requestMethod = 'put'
        url += `/${itemId}`
      }

      const response = await HTTP[requestMethod](url, item)

      return {
        data: response.data
      }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ItemsPreviewTable',
    components: {
      ItemMeta,
      ItemSettings,
      ItemOptions,
      Spinner,
      ProgressBar
    },
    props: {
      items: {
        type: Array
      },
      project: {
        type: Object
      },
      loading: {
        type: Boolean,
        default: false
      },
      hideShowMore: {
        type: Boolean,
        default: false
      },
      showMetaChanged: {
        type: Boolean,
        default: false
      },
      references: {
        type: Array,
        default: () => []
      }
    },
    async created() {
      this.allIds = await getAllIds(this.project.id)
      this.itemNames = new Set(this.allIds.map(idName => idName.name))
    },
    data() {
      return {
        saveItemError: '',
        showErrorColumn: false,
        toggleShowMore: false,
        selected: null,
        allIds: [],
        itemNames: new Set([]),
        tasks: []
      }
    },
    methods: {
      rowClicked(item, index) {
        const itemCopy = deepCopy(item)
        this.selected = {
          meta: {
            scorpion: {}
          },
          feedback: {},
          ...itemCopy
        }
        this.index = index
        this.showModal('preview-item')
      },
      showModal(ref) {
        this.$refs[ref].show()
      },
      hideModal(ref) {
        this.$refs[ref].hide()
      },
      async saveFromModal() {
        await this.saveItemPreview(this.selected, this.index)
        this.selected = null
        setTimeout(() => this.hideModal('preview-item'))
      },
      async saveItemPreview(item, index) {
        const existingItemId = this.getExistingItemId(item)
        const existingMeta = get(this.items[index], 'meta.scorpion', {})
        const metaChanged = !isEqual(item.meta.scorpion, existingMeta)
        const multipleItems = this.items.length > 1

        if (this.showMetaChanged && metaChanged && multipleItems) {
          const updateMeta = await this.$bvModal.msgBoxConfirm(
              'Would you like to update all other generated items with the changes to this items meta?', {
                title: 'Meta Changed',
                size: 'sm',
                okVariant: 'secondary',
                okTitle: 'Ok',
                cancelVariant: 'white',
                cancelTitle: 'No',
              })

          if (updateMeta) {
            for (const i of this.items) {
              i.meta = deepCopy(item.meta)
            }
          }
        }

        const result = await saveItem(this.project.id, item, existingItemId)
        const isSuccess = get(result, 'data.name', '').length > 0

        if (!isSuccess) {
          const errorObj = get(result, 'error.response.data.errors', {})
          errorObj.requestError = get(result, 'error.message', 'None')
          this.$set(this.items, index, {
            ...this.items[index],
            errMsg: JSON.stringify(errorObj, null, 2)
          })
          this.showErrorColumn = true
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save item!'
          })
          return
        }

        EVENT.alert({
          variant: 'success',
          message: 'Item saved successfully!'
        })

        this.deleteItemPreview(item, index)
      },
      getExistingItemId(item) {
        return this.allIds.reduce((itemId, idName) => {
          if (idName.name === item.name) {
            itemId = idName.id
          }

          return itemId
        }, null)
      },
      deleteItemPreview(item, index) {
        this.$emit('delete-items', index)
      },
      async saveAll() {
        this.tasks = this.items.map(item => {
          const existingItemId = this.getExistingItemId(item)

          return saveItem(this.project.id, item, existingItemId)
        })

        const results = await Promise.allSettled(this.tasks)

        let isError = false
        let markDelete = []
        for (const [index, result] of results.entries()) {
          const isSuccess = get(result, 'value.data.name', '').length > 0
          if (isSuccess) {
            markDelete.push(index)
          } else {
            isError = true
            const errorObj = get(result, 'value.error.response.data.errors', {})
            errorObj.requestError = get(result, 'value.error.message', 'None')
            this.$set(this.items, index, {
              ...this.items[index],
              errMsg: JSON.stringify(errorObj, null, 2)
            })
          }
        }

        if (isError) {
          this.showErrorColumn = true
          this.deleteMultiple(markDelete)
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save one or more items!'
          })
          return
        }

        setTimeout(() => this.deleteAll(), 1500)
        EVENT.alert({
          variant: 'success',
          message: 'All items saved successfully!'
        })
      },
      deleteAll() {
        this.$emit('delete-items', -1)
        this.tasks = []
      },
      deleteMultiple(indexArr) {
        this.$emit('delete-mult-items', indexArr)
        this.tasks = []
      },
      hasExistingItem(item) {
        if (item) {
          return this.itemNames && this.itemNames.has(item.name)
        }
      },
      getReferenceName(referenceId) {
        return this.references.find(ref => ref.id === referenceId).name
      }
    },
    computed: {
      isPremium() {
        return SESSION.isPremium()
      },
      fields() {
        const fields = [
          { key: 'name', sortable: true },
          {
            key: 'settings.type',
            sortable: true,
            label: 'Type',
            formatter: value => friendlyName(value)
          },
          { key: 'actions' }
        ]
        if (this.showErrorColumn) {
          fields.push({ key: 'errMsg', label: 'Errors' })
        }
        return fields
      },
      optionExpandOrCollapse() {
        if (this.toggleShowMore) return 'Hide more'
        return 'Show more'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
