<template>
  <b-card class="mb-1" no-body v-if="display">
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle="dropdownId"
        variant="primary"
        >{{ dropdownHeader }}</b-button
      >
    </b-card-header>
    <b-collapse :id="dropdownId" v-if="data.length" :visible="expand">
      <b-card-body>
        <b-table
          :fields="fields"
          :items="data"
          :style="clickableTable"
          @row-clicked="rowClicked"
          hover
          responsive
          small
          striped
        >
          <template v-slot:cell(item_idx)="row">{{
            row.item.item_idx + 1
          }}</template>
          <template v-slot:cell(manual_score)="row">
            <span v-if="!needsManualScore(row.item)">{{
              row.item.manual_score
            }}</span>
            <span
              v-b-tooltip="{
                trigger: 'hover',
                title: 'Requires Manual Score',
                interactive: false
              }"
              v-else
            >
              <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
            </span>
          </template>
          <template v-slot:cell(is_excluded)="row">
            <span
              v-b-tooltip="{
                trigger: 'hover',
                title: 'Item Is Excluded',
                interactive: false
              }"
              v-if="row.item.is_excluded"
            >
              <font-awesome-icon icon="check"></font-awesome-icon>
            </span>
          </template>
          <template v-slot:cell(comments)="row">
            <span
              v-b-tooltip="{
                trigger: 'hover',
                title: 'Item Has Comments',
                interactive: false
              }"
              v-if="row.item.comments.length"
            >
              <font-awesome-icon icon="comment"></font-awesome-icon>
            </span>
          </template>
        </b-table>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  export default {
    name: 'DeliveryListing',
    props: {
      data: {
        type: Array
      },
      fields: {
        type: Array
      },
      display: {
        type: Boolean,
        default: true
      },
      clickable: {
        type: Boolean
      },
      title: {
        type: String
      },
      modal: {
        type: String
      },
      expand: {
        type: Boolean
      }
    },
    created() {
      this.dropdownId = 'dropdown-' + this._uid
    },
    data() {
      return {
        dropdownId: ''
      }
    },
    methods: {
      needsManualScore(item) {
        const required = item.requires_manual_score
        const needsScore = !item.manual_score || item.manual_score === '-'
        const isNotExcluded = !item.is_excluded
        return required && needsScore && isNotExcluded
      },
      rowClicked(record, index) {
        if (record._index) {
          index = record._index
        }
        this.$emit('open-modal', this.modal, record, index)
      }
    },
    computed: {
      dropdownHeader() {
        return `${this.title} (${this.data.length})`
      },
      clickableTable() {
        return this.clickable ? '' : 'pointerEvents: none;'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
