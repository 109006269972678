<template>
  <b-container fluid v-if="display">
    <b-row class="mb-2">
      <b-col sm="4" md="4" lg="3">
        <label class="p-1">
          Score Weight (0-100) 
        </label>
      </b-col>
      <b-col sm="3" md="2">
        <b-form-input id="option-weight" name="option-weight" type="number" min="0" max="100" v-model.number="safeWeight" @change="weightChanged()" value="0"></b-form-input>
      </b-col>
      <b-col>
       &nbsp;
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  const DISPLAY_FOR_ALL_OPTIONS = ['build_list', 'matching']
  export default {
    name: 'ItemOptionWeight',
    props: {
      item: {
        type: Object
      },
      optionIndex: {
        type: Number
      },
      weight: {
        type: Number
      },
      propSetWeight: {
        type: Function
      }
    },
    created() {
      this.safeWeight = this.weight
      this.propSetWeight(this.optionIndex, this.safeWeight)
    },
    data() {
      return {
        safeWeight: 0
      }
    },
    methods: {
      weightChanged() {
        this.propSetWeight(this.optionIndex, this.safeWeight)
      }
    },
    watch: {
      weight: function (newVal) {
        this.safeWeight = newVal
      }
    },
    computed: {
      display() {
        if (this.item.settings.scoring !== 'weighted') {
          return false
        }
        if (DISPLAY_FOR_ALL_OPTIONS.includes(this.item.settings.type)) {
          return true
        }
        return this.item.settings.key[this.optionIndex]
      }
    }
  }
</script>

<style lang="scss" scoped></style>
