<template>
  <div>
    <div class="mb-4">
      <h5>Completed Deliveries</h5>
      <AnalyticsLineChart
        :chart-data="chartData.counts"
        :chart-scale-options="chartScaleOptions.counts"
      />
    </div>
    <div class="mb-4">
      <h5>Average Score</h5>
      <AnalyticsLineChart
        :chart-data="chartData.averageScore"
        :chart-scale-options="chartScaleOptions.averageScore"
      />
    </div>
    <div class="mb-4">
      <h5>Average Duration</h5>
      <AnalyticsLineChart
        :chart-data="chartData.averageDuration"
        :chart-scale-options="chartScaleOptions.averageDuration"
      />
    </div>
    <div v-if="chartData.passRate">
      <h5>Pass Rate</h5>
      <AnalyticsLineChart
        :chart-data="chartData.passRate"
        :chart-scale-options="chartScaleOptions.passRate"
      />
    </div>
  </div>
</template>

<script>
  import { CHART_COLORS, CHART_MONTHS } from '../../utils/constants'
  import { SESSION } from '../../utils/session'

  import AnalyticsLineChart from './AnalyticsLineChart'

  function formatChartData(data, name) {
    let datasets

    if (!name) {
      const counts = []
      let index = 0

      for (const count of data) {
        const { data, label } = count
        const color = CHART_COLORS[index]

        counts.push({
          data,
          label,
          backgroundColor: color,
          borderColor: color,
          fill: false
        })

        if (index === CHART_COLORS.length - 1) {
          index = 0
        } else {
          index++
        }
      }

      datasets = counts
    } else {
      const [color] = CHART_COLORS

      datasets = [
        {
          data,
          label: name,
          backgroundColor: color,
          borderColor: color,
          fill: false
        }
      ]
    }

    return datasets
  }

  function createChartData() {
    const {
      dashboard: { avg_score, avg_seconds, counts, months, pass_rate }
    } = SESSION.project

    const chartLabels = []

    for (const month of months) {
      const index = month[1] - 1
      chartLabels.push(CHART_MONTHS[index])
    }

    const chartData = {
      counts: {
        datasets: formatChartData(counts, null),
        labels: chartLabels
      },
      averageScore: {
        datasets: formatChartData(avg_score.data, 'Average Score'),
        labels: chartLabels
      },
      averageDuration: {
        datasets: formatChartData(avg_seconds.data, 'Average Duration'),
        labels: chartLabels
      }
    }

    if (pass_rate) {
      chartData.passRate = {
        datasets: formatChartData(pass_rate.data, 'Pass Rate'),
        labels: chartLabels
      }
    }

    return chartData
  }

  function createScaleOptions() {
    const { score_scale_lower, score_scale_upper } = SESSION.project.settings

    return {
      counts: {
        scales: {
          yAxes: [{ ticks: { beginAtZero: true } }]
        }
      },
      averageScore: {
        scales: {
          yAxes: [{ ticks: { min: score_scale_lower, max: score_scale_upper } }]
        }
      },
      averageDuration: {
        scales: {
          yAxes: [{ ticks: { beginAtZero: true } }]
        }
      },
      passRate: {
        scales: {
          yAxes: [{ ticks: { min: 0, max: 1 } }]
        }
      }
    }
  }

  export default {
    name: 'AnalyticsDelivery',
    components: {
      AnalyticsLineChart
    },
    created() {
      this.chartData = createChartData()
      this.chartScaleOptions = createScaleOptions()
    },
    data() {
      return {
        chartData: {},
        chartScaleOptions: {}
      }
    }
  }
</script>

<style lang="scss" scoped></style>
