<template>
  <div>
    <b-button
      :disabled="loading"
      @click="loadAnalytics"
      variant="white"
    >
      <b-spinner label="Small Spinner" small v-show="loading"></b-spinner>
      &nbsp;Analytics
    </b-button>

    <b-modal v-model="showModal" title="Development Analytics" scrollable>
      <b-row>
        <b-col class="text-right mt-2">
          <i v-if="chartData.lastUpdated">Last updated: {{ formattedTime }}</i>
        </b-col>
      </b-row>
  
      <div v-if="display">
        <b-card class="my-4">
          <h5 class="mb-4">Item Type</h5>
          <AnalyticsPieChart
            :chart-data="chartData.types"
            v-if="hasDataToDisplay(chartData.types)"
          />
          <i v-else>No data to display</i>
        </b-card>
        <b-card class="mb-4">
          <h5 class="mb-4">Items Created By Users</h5>
          <AnalyticsPieChart
            :chart-data="chartData.users"
            v-if="hasDataToDisplay(chartData.users)"
          />
          <i v-else>No data to display</i>
        </b-card>
        <b-card :key="meta.id" class="mb-4" v-for="meta in chartData.meta">
          <h5 class="mb-4">Item Counts for Custom Field: {{ meta.name }}</h5>
          <div v-if="hasDataToDisplay(meta.data)">
            <AnalyticsBarChart
              :chart-data="meta.data"
              v-if="meta.type === 'select_multi'"
            />
            <AnalyticsPieChart :chart-data="meta.data" v-else />
          </div>
          <i v-else>No data to display</i>
          <h5 class="mt-4" v-if="meta.progressStats.length">Targets</h5>
          <div
            :key="index"
            class="my-4"
            v-for="(progressStat, index) in meta.progressStats"
          >
            <b-row>
              <b-col>
                <div class="mb-1">{{ progressStat.name }}</div>
                <b-progress :max="100" height="30px">
                  <b-progress-bar
                    :style="{ backgroundColor: progressStat.color }"
                    :value="progressStat.value"
                    :label="`${progressStat.value}%`"
                  ></b-progress-bar>
                </b-progress>
              </b-col>
              <b-col class="d-flex align-items-end" sm="1">{{
                progressStat.count
              }}</b-col>
            </b-row>
          </div>
        </b-card>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="closeModal()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import { LOCAL } from '../../utils/local'
  import { EVENT } from '../../utils/event-bus'
  import { friendlyName } from '../../utils/misc'
  import { HTTP } from '../../utils/requests'
  import { CHART_COLORS, SEI_API_BASE } from '../../utils/constants'
  import { SESSION } from '../../utils/session'

  import get from 'lodash.get'

  import AnalyticsBarChart from './AnalyticsBarChart'
  import AnalyticsPieChart from './AnalyticsPieChart'

  async function getItemAnalytics(projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/item_analytics`

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  function createCounts(results) {
    const counts = {
      types: {},
      users: {},
      meta: {}
    }

    for (const result of results) {
      if (counts.types[result.type]) {
        counts.types[result.type]++
      } else {
        counts.types[result.type] = 1
      }

      if (counts.users[result.name]) {
        counts.users[result.name]++
      } else {
        counts.users[result.name] = 1
      }

      const meta = get(result.meta, 'scorpion')

      if (meta) {
        for (const fieldId in meta) {
          const value = meta[fieldId]

          if (Array.isArray(value)) {
            for (const optionId of value) {
              if (counts.meta[optionId]) {
                counts.meta[optionId]++
              } else {
                counts.meta[optionId] = 1
              }
            }
          } else {
            if (counts.meta[value]) {
              counts.meta[value]++
            } else {
              counts.meta[value] = 1
            }
          }
        }
      }
    }

    return counts
  }

  function formatChartData(counts) {
    const labels = []
    const datasets = {
      backgroundColor: [],
      data: []
    }
    let index = 0

    for (const key in counts) {
      const count = counts[key]

      const friendlyLabel = friendlyName(key)
      labels.push(`${friendlyLabel} (${count ? count : 0})`)

      datasets.backgroundColor.push(CHART_COLORS[index])
      datasets.data.push(count)

      if (index === CHART_COLORS.length - 1) {
        index = 0
      } else {
        index++
      }
    }

    return { datasets: [datasets], labels }
  }

  function formatMetaChartData(counts) {
    const customOrder = get(SESSION.project, 'meta.scorpion.custom_field_order')
    const customFields = get(SESSION.project, 'meta.scorpion.custom')

    if (!customOrder || !customFields) {
      return {}
    }

    const { custom, custom_field_order } = SESSION.project.meta.scorpion
    const metaDatasets = []

    for (const fieldId of custom_field_order) {
      const customField = custom[fieldId]

      if (!customField.type.startsWith('select_')) {
        continue
      }

      const labels = []
      const datasets = {
        backgroundColor: [],
        data: []
      }
      const progressStats = []

      let index = 0

      for (const optionId of customField.option_order) {
        const option = customField.options[optionId]

        if (option.is_divider) {
          continue
        }

        let color = CHART_COLORS[index]
        if (option.color) {
          color = option.color
        }

        if (typeof option.target === 'number' && option.target > 0) {
          const count = counts[option.id] ? counts[option.id] : 0
          let percentage = Math.floor((count / option.target) * 100)

          if (percentage > 100) {
            percentage = 100
          }

          progressStats.push({
            name: option.text,
            value: percentage,
            count: `${count} / ${option.target}`,
            color
          })
        }

        if (customField.type === 'select_multi') {
          datasets.label = 'Amount'
        }

        labels.push(
          `${option.text} (${counts[option.id] ? counts[option.id] : 0})`
        )
        datasets.backgroundColor.push(color)
        datasets.data.push(counts[option.id])

        if (!option.color) {
          index = index === CHART_COLORS.length - 1 ? 0 : index + 1
        }
      }

      metaDatasets.push({
        name: customField.name,
        data: { labels, datasets: [datasets] },
        id: fieldId,
        type: customField.type,
        progressStats
      })
    }

    return metaDatasets
  }

  export default {
    name: 'AnalyticsDevelopment',
    components: {
      AnalyticsBarChart,
      AnalyticsPieChart
    },
    created() {
      this.chartData = LOCAL.analytics.get()
      this.display = Boolean(Object.keys(this.chartData).length)
    },
    data() {
      return {
        chartData: {},
        loading: false,
        display: false,
        showModal: false
      }
    },
    methods: {
      async loadAnalytics() {
        this.loading = true

        const { data, error } = await getItemAnalytics(SESSION.project.id)

        if (error) {
          this.loading = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to load analytics.'
          })
        }

        const { meta, types, users } = createCounts(data.results)

        const chartData = {
          types: formatChartData(types),
          users: formatChartData(users),
          meta: formatMetaChartData(meta),
          lastUpdated: Date.now()
        }

        LOCAL.analytics.set(chartData)

        this.chartData = chartData
        this.display = true
        this.loading = false
        this.showModal = true
      },
      hasDataToDisplay(data) {
        return data.datasets[0].data.some(value => value)
      },
      closeModal() {
        this.showModal = false
      }
    },
    computed: {
      formattedTime() {
        return this.$moment
          .utc(this.chartData.lastUpdated)
          .local()
          .format('MMM DD, h:mm A')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
