<template>
  <div>
    <b-container fluid class="m-0 p-0">
      <div v-show="!loading" class="iframe-fixed">
        <b-embed
          :src="appUrl"
          @load="contentLoaded"
          type="iframe"
        ></b-embed>
      </div>
      <Spinner v-if="loading" />
    </b-container>
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import Spinner from '../Spinner'

  export default {
    name: 'FullApp',
    components: {
      Spinner
    },
    created() {
      this.createUrl()
      window.addEventListener('blur', this.clickedInFrame)
    },
    beforeDestroy() {
      window.removeEventListener('blur', this.clickedInFrame)
    },
    props: {
      project: {
        type: Object
      }
    },
    watch: {
      $route() {
        this.createUrl()
      }
    },
    data() {
      return {
        appUrl: '',
        loading: true
      }
    },
    methods: {
      createUrl() {
        this.loading = true
        const integration = this.project.integrations.find(
          integration =>
            integration.app_caveon_id === this.$route.params.appId
        )

        let url = integration.app.app_settings.content.full_page

        if (!url) {
          url = integration.app.app_settings.content.dashboard_widget
        }
        let replaceWith = '.'
        if (SESSION.env) replaceWith = `.${SESSION.env}.`
        url = url.replace('.env.', replaceWith)
        const delimeter = url.indexOf('?') > -1 ? '&' : '?'
        const shared_token = integration.shared_token
        const exam_id = integration.exam_id
        this.appUrl = `${url}${delimeter}jwt=${shared_token}&exam_id=${exam_id}`
      },
      contentLoaded() {
        this.loading = false
      },
      clickedInFrame() {
        this.$root.$emit('hide-dropdown')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
