import { render, staticRenderFns } from "./ModalContentSwitcher.vue?vue&type=template&id=41d4f055&scoped=true&"
import script from "./ModalContentSwitcher.vue?vue&type=script&lang=js&"
export * from "./ModalContentSwitcher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41d4f055",
  null
  
)

export default component.exports