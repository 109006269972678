<template>
  <div>
    <h4>1. Add External Tool to LMS</h4>
    <p>
      Use the following information in order to add an external tool to your LMS
      using the LTI standard.
    </p>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Consumer Key</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-form-input
            :value="project.id"
            id="consumer-key-input"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-tooltip
              :show.sync="showCopyTooltip['consumer-key-input']"
              target="consumer-key-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('consumer-key-input')"
              id="consumer-key-copy-btn"
              variant="white"
              >Copy</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Consumer Secret</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-form-input
            :value="ltiSecret"
            id="consumer-secret-input"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-tooltip
              :show.sync="showCopyTooltip['consumer-secret-input']"
              target="consumer-secret-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('consumer-secret-input')"
              id="consumer-secret-copy-btn"
              variant="white"
              >Copy</b-button
            >
          </b-input-group-append>
          <b-input-group-append>
            <b-button :disabled="shouldDisable" v-b-modal.regenerate-secret-modal variant="white"
              >Regenerate</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Launch URL</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-form-input
            :value="launchUrl"
            id="launch-url-input"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-tooltip
              :show.sync="showCopyTooltip['launch-url-input']"
              target="launch-url-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('launch-url-input')"
              id="launch-url-copy-btn"
              variant="white"
              >Copy</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">XML Configuration URL</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-form-input
            :value="xmlConfigurationUrl"
            id="xml-configuration-url-input"
            readonly
          ></b-form-input>
          <b-input-group-append>
            <b-tooltip
              :show.sync="showCopyTooltip['xml-configuration-url-input']"
              target="xml-configuration-url-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('xml-configuration-url-input')"
              id="xml-configuration-url-copy-btn"
              variant="white"
              >Copy</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <h4 class="mt-4">2. Map Your LMS Data to Scorpion</h4>
    <p>
      Use this tool to map the information provided by your LMS to the examinee
      details you have selected for your SEI exam. Required SEI details denoted
      with *. If a required SEI field is not mapped to your LMS, it will be
      filled with SEI defaults. Not all fields are guaranteed to be provided by
      the LMS.
    </p>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Given Name</label>
      </b-col>
      <b-col sm="9">
        <b-form-select
          :options="options"
          v-model="safeProject.settings.lti_map.lis_person_name_given"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Family Name</label>
      </b-col>
      <b-col sm="9">
        <b-form-select
          :options="options"
          v-model="safeProject.settings.lti_map.lis_person_name_family"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Full Name</label>
      </b-col>
      <b-col sm="9">
        <b-form-select
          :options="options"
          v-model="safeProject.settings.lti_map.lis_person_name_full"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Email Address</label>
      </b-col>
      <b-col sm="9">
        <b-form-select
          :options="options"
          v-model="
            safeProject.settings.lti_map.lis_person_contact_email_primary
          "
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="3">
        <label for="input-default">Tags</label>
      </b-col>
      <b-col sm="9">
        <tag-selector :tags="project.tags" :selected="safeProject.settings.lti_tags" @tags-updated="tagsUpdated" />
      </b-col>
    </b-row>
    <b-button
      :disabled="isSaving || shouldDisable"
      @click="saveLtiMap"
      class="mt-3"
      variant="secondary"
    >
      <b-spinner label="Small Spinner" small v-show="isSaving"></b-spinner
      >&nbsp;Save
    </b-button>
    <h4 class="mt-4">3. XML Cartridge Configuration</h4>
    <p>
      Use this tool to map the information provided by your LMS to the examinee
      details you have selected for your SEI exam. Required SEI details denoted
      with *. If a required SEI field is not mapped to your LMS, it will be
      filled with SEI defaults. Not all fields are guaranteed to be provided by
      the LMS.
    </p>
    <pre class="prettyPre p-3">
&#x3C;cartridge_basiclti_link
  xmlns=&#x22;http://www.imsglobal.org/xsd/imslticc_v1p0&#x22;
  xmlns:blti=&#x22;http://www.imsglobal.org/xsd/imsbasiclti_v1p0&#x22;
  xmlns:lticm=&#x22;http://www.imsglobal.org/xsd/imslticm_v1p0&#x22;
  xmlns:lticp=&#x22;http://www.imsglobal.org/xsd/imslticp_v1p0&#x22;
  xmlns:xsi=&#x22;http://www.w3.org/2001/XMLSchema-instance&#x22; xsi:schemalocation=&#x22;http://www.imsglobal.org/xsd/imslticc_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticc_v1p0.xsd http://www.imsglobal.org/xsd/imsbasiclti_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imsbasiclti_v1p0p1.xsd http://www.imsglobal.org/xsd/imslticm_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticm_v1p0.xsd http://www.imsglobal.org/xsd/imslticp_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imslticp_v1p0.xsd&#x22;&#x3E;
  &#x3C;blti:title&#x3E;Caveon SEI&#x3C;/blti:title&#x3E;
  &#x3C;blti:description&#x3E;
    &#x3C;!--[CDATA[A way to connect to Caveon&#x27;s SEI tools to deliver secure exams through your LMS]]--&#x3E;
  &#x3C;/blti:description&#x3E;
  &#x3C;blti:launch_url&#x3E;https://scorpion.caveon.com/lti&#x3C;/blti:launch_url&#x3E;
  &#x3C;blti:custom&#x3E;&#x3C;/blti:custom&#x3E;
  &#x3C;blti:extensions platform=&#x22;canvas.instructure.com&#x22;&#x3E;
    &#x3C;lticm:property name=&#x22;tool_id&#x22;&#x3E;caveon_sei_lms_integration&#x3C;/lticm:property&#x3E;
    &#x3C;lticm:property name=&#x22;privacy_level&#x22;&#x3E;public&#x3C;/lticm:property&#x3E;
  &#x3C;/blti:extensions&#x3E;
&#x3C;/cartridge_basiclti_link&#x3E;
          </pre
    >

    <!-- Regenerate Consumer Secret Confirm Modal -->
    <b-modal
      @ok.prevent="regenerateSecret()"
      id="regenerate-secret-modal"
      size="xl"
      title="Regenerate Secret"
    >
      <p>
        Regenerating the consumer secret will invalidate your previous secret.
        This action is not recoverable. Are you sure you want to continue?
      </p>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            :disabled="isRegeneratingSecret"
            @click="ok"
            variant="secondary"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isRegeneratingSecret"
            ></b-spinner
            >&nbsp;Continue
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'
  import { EVENT } from '../../utils/event-bus'

  import { deepCopy } from '../../utils/misc.js'
  import TagSelector from './TagSelector.vue'

  export default {
    name: 'LMS',
    components: {
      TagSelector
    },
    async created() {
      this.safeProject = deepCopy(this.project)
      if (!this.safeProject.settings.lti_tags) {
        this.safeProject.settings.lti_tags = []
      }
      this.setMapOptions()
      await this.getLtiSecret()
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      isSaving: {
        type: Boolean
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        safeProject: {},
        ltiSecret: '',
        isRegeneratingSecret: false,
        options: [{ value: '', text: 'Unmapped' }],
        showCopyTooltip: {
          'consumer-key-input': false,
          'consumer-secret-input': false,
          'launch-url-input': false,
          'xml-configuration-url-input': false
        }
      }
    },
    methods: {
      setMapOptions() {
        for (const option of this.safeProject.settings.examinee_schema) {
          this.options.push({
            value: option.key,
            text: option.key
          })
        }
      },
      copyText(idSelector) {
        let input = document.querySelector('#' + idSelector)
        input.select()
        document.execCommand('copy')
        this.showCopyTooltip[idSelector] = true
        setTimeout(() => (this.showCopyTooltip[idSelector] = false), 1200)
      },
      async getLtiSecret() {
        let url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/lti_secret`
        let response = await HTTP.get(url)
        this.ltiSecret = response.data.secret
      },
      async regenerateSecret() {
        try {
          this.isRegeneratingSecret = true
          let url = `${SEI_API_BASE}/exams/${this.safeProject.id}/generate_lti_secret`
          let response = await HTTP.get(url)
          this.ltiSecret = response.data.secret
          this.$bvModal.hide('regenerate-secret-modal')
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to regenerate secret! Please try again.'
          })
        } finally {
          this.isRegeneratingSecret = false
        }
      },
      saveLtiMap() {
        EVENT.saveProject(this.safeProject)
      },
      tagsUpdated(tags) {
        this.safeProject.settings.lti_tags = tags
      }
    },
    computed: {
      launchUrl() {
        return window.location.origin + '/lti'
      },
      xmlConfigurationUrl() {
        return window.location.origin + '/lti_config'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .prettyPre {
    overflow: auto;
    background: #eee;
    border: 1px solid #bbb;
    display: block;
    font-family: monospace;
    white-space: pre;
    font-size: 1em;
  }
</style>
