<template>
  <div>
    <b-alert :show="configuration.is_default" variant="info">
        This is the <i>parent configuration</i>.<br/><small>Fields left blank on other configurations will default to the settings on this configuration.</small>
      </b-alert>
    <b-form-group
      label="Configuration Name"
      label-cols-lg="3"
      label-cols-sm="4"
      label-for="configuration-name"
      v-if="!hideName"
    >
      <!-- eslint-disable vue/no-mutating-props -->
      <b-form-input
        id="configuration-name"
        v-model="configuration.name"
        :disabled="!editable"
      ></b-form-input>
      <!-- eslint-enable vue/no-mutating-props -->
    </b-form-group>
    <div class="d-flex justify-content-between align-items-center">
      <b-button class="pl-0" variant="link" size="sm" @click="expand = !expand">{{
        expandOrCollapse
      }}</b-button>
      <b-button
        variant="white"
        size="sm"
        @click="clearAllSettings"
        v-if="canShowWarnings"
      >Clear All Settings</b-button>
    </div>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.exam-rules-collapse
        >
          Exam Rules
        </b-button>
      </b-card-header>
      <b-collapse id="exam-rules-collapse" :visible="expand">
        <b-card-body>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="proctor-code-input"
          >
          <template #label>
            Proctor Code
            <span
              :title="defaultValueMessage('proctor_code')"
              v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
              v-if="!configuration.settings.proctor_code && canShowWarnings"
            >
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
            </span>
          </template>
          <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="proctor-code-input"
              @input="defaultTextInputToNull($event, configuration.settings, 'proctor_code')"
              :value="configuration.settings.proctor_code"
              :disabled="!editable"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="time-limit-input"
            description="Enter 0 for no limit"
          >
            <template #label>
              Exam Time Limit (seconds)
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('time_limit')"
                v-if="!configuration.settings.time_limit && configuration.settings.time_limit !== 0 && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="time-limit-input"
              type="number"
              min="0"
              step="1"
              @input="defaultNumberInputToNull($event, configuration.settings, 'time_limit')"
              :value="configuration.settings.time_limit"
              :disabled="!editable"
              :state="getInvalidState('time_limit')"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="exam-mode-input"
          >
            <template #label>
              Exam Mode
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('mode')"
                v-if="!configuration.settings.mode && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="examModeOptions"
              id="exam-mode-input"
              v-model="configuration.settings.mode"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label="Available Languages"
            description="* Scorpion built-in languages."
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="selected-language-input"
          >
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="languageOptions"
              id="selected-language-input"
              multiple
              placeholder="Select languages"
              @change="onSelectedLanguageChange"
              :value="configuration.settings.selected_languages"
              :disabled="!editable"
              dir="ltr"
              :state="getInvalidState('selected_languages')"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            v-if="showDefaultLanguage"
            label="Default Language"
            description="* Scorpion built-in languages."
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="default-language-input"
          >
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="defaultLanguageOptions"
              id="default-language-input"
              v-model="configuration.settings.default_language"
              :disabled="!editable"
              :state="getInvalidState('default_language')"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label="Min Items"
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="min-items-input"
            v-if="isAdaptiveMode"
          >
          <template #label>
              Min Items
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('min_items')"
                v-if="!configuration.settings.min_items && configuration.settings.min_items !== 0 && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="min-items-input"
              min="0"
              type="number"
              @input="defaultNumberInputToNull($event, configuration.settings, 'min_items')"
              :value="configuration.settings.min_items"
              :disabled="!editable"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="max-items-input"
            v-if="isAdaptiveMode"
          >
          <template #label>
              Max Items
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('max_items')"
                v-if="!configuration.settings.max_items && configuration.settings.max_items !== 0 && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="max-items-input"
              min="0"
              type="number"
              @input="defaultNumberInputToNull($event, configuration.settings, 'max_items')"
              :value="configuration.settings.max_items"
              :disabled="!editable"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="max-error-input"
            v-if="isAdaptiveMode"
          >
          <template #label>
              Max Error
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('max_error')"
                v-if="!configuration.settings.max_error && configuration.settings.max_error !== 0 && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="max-error-input"
              min="0"
              type="number"
              @input="defaultNumberInputToNull($event, configuration.settings, 'max_error')"
              :value="configuration.settings.max_error"
              :disabled="!editable"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <!-- eslint-disable vue/no-mutating-props -->
          <hr>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="end-early-input"
            v-if="!isAdaptiveMode"
          >
            <template #label>
              End the test early when a passing or failing score is determined
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('end_early')"
                v-if="!configuration.settings.end_early && configuration.settings.end_early !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="end-early-input"
              v-model="configuration.settings.end_early"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="mark-and-review-input"
            v-if="!isAdaptiveMode"
          >
            <template #label>
              Allow users to go back and change answers
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('mark_and_review')"
                v-if="!configuration.settings.mark_and_review && configuration.settings.mark_and_review !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="mark-and-review-input"
              v-model="configuration.settings.mark_and_review"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="prevent-section-mark-and-review-input"
            v-if="configuration.settings.mark_and_review && !isAdaptiveMode"
          >
            <template #label>
              Prevent mark and review between sections
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('prevemt_section_mark_and_review')"
                v-if="!configuration.settings.prevent_section_mark_and_review && configuration.settings.prevent_section_mark_and_review !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="prevent-section-mark-and-review-input"
              v-model="configuration.settings.prevent_section_mark_and_review"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="enable-sections-input"
            v-if="!isAdaptiveMode"
          >
            <template #label>
              Enable section timers and navigation
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('enable_sections')"
                v-if="!configuration.settings.enable_sections && configuration.settings.enable_sections !== false && canShowWarnings"              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="enable-sections-input"
              v-model="configuration.settings.enable_sections"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="pause-resume-input"
          >
            <template #label>
              Allow users to pause and resume their own exam
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('pause_resume')"
                v-if="!configuration.settings.pause_resume && configuration.settings.pause_resume !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="pause-resume-input"
              v-model="configuration.settings.pause_resume"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="feedback-enabled-input"
          >
            <template #label>
              Show item feedback after each page
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('feedback_enabled')"
                v-if="!configuration.settings.feedback_enabled && configuration.settings.feedback_enabled !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="feedback-enabled-input"
              v-model="configuration.settings.feedback_enabled"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="exam-review-enabled-input"
          >
            <template #label>
              Show item feedback at the end of exam
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('exam_review_enabled')"
                v-if="!configuration.settings.exam_review_enabled && configuration.settings.exam_review_enabled !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="exam-review-enabled-input"
              v-model="configuration.settings.exam_review_enabled"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="feedback-key-enabled-input"
          >
            <template #label>
              Hide correct responses on feedback page
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('feedback_hide_key')"
                v-if="!configuration.settings.feedback_hide_key && configuration.settings.feedback_hide_key !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="feedback-key-enabled-input"
              v-model="configuration.settings.feedback_hide_key"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="comments-input"
          >
            <template #label>
              Allow users to submit comments on items
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('comments')"
                v-if="!configuration.settings.comments && configuration.settings.comments !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="comments-input"
              v-model="configuration.settings.comments"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="adjust-time-after-disconnect-input"
          >
            <template #label>
              Adjust time after a user disconnects
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('adjust_time_after_disconnect')"
                v-if="!configuration.settings.adjust_time_after_disconnect && configuration.settings.adjust_time_after_disconnect !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="adjust-time-after-disconnect-input"
              v-model="configuration.settings.adjust_time_after_disconnect"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.instructions-collapse
        >
          Exam Instructions
        </b-button>
      </b-card-header>
      <b-collapse id="instructions-collapse" :visible="expand">
        <b-card-body>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="instructions-input"
          >
          <template #label>
              Instructions
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('instructions')"
                v-if="!configuration.settings.instructions && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-textarea 
              max-rows="99"
              id="instructions-input"
              placeholder="Enter instructions"
              rows="1"
              no-resize
              :value="configuration.settings.instructions"
              @input="defaultTextInputToNull($event, configuration.settings, 'instructions')"
              :readonly="!editable"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.security-options-collapse
        >
          Security Options
        </b-button>
      </b-card-header>
      <b-collapse id="security-options-collapse" :visible="expand">
        <b-card-body>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="start-paused-input"
          >
            <template #label>
              Start the exam in a paused state
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('start_paused')"
                v-if="!configuration.settings.security_options.start_paused && configuration.settings.security_options.start_paused !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="start-paused-input"
              v-model="configuration.settings.security_options.start_paused"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="suspicious-event-threshold-input"
            description="Enter 0 for no limit"
          >
            <template #label>
              Suspicious event threshold
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('suspicious_event_threshold')"
                v-if="!configuration.settings.security_options.suspicious_event_threshold && configuration.settings.security_options.suspicious_event_threshold !== 0 && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-input
              id="suspicious-event-threshold-input"
              type="number"
              min="0"
              step="1"
              @input="defaultNumberInputToNull($event, configuration.settings.security_options, 'suspicious_event_threshold')"
              :value="configuration.settings.security_options.suspicious_event_threshold"
              :disabled="!editable"
              :state="getInvalidState('suspicious_event_threshold')"
            ></b-form-input>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="fresh-token-each-launch-input"
          >
            <template #label>
              Generate fresh token each launch
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('fresh_token_each_launch')"
                v-if="!configuration.settings.security_options.fresh_token_each_launch && configuration.settings.security_options.fresh_token_each_launch !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="fresh-token-each-launch-input"
              v-model="configuration.settings.security_options.fresh_token_each_launch"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <!-- eslint-enable vue/no-mutating-props -->

          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="block-screen-input"
          >
            <template #label>
              Block screen on certain keys
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('block_screen')"
                v-if="!configuration.settings.security_options.block_screen && configuration.settings.security_options.block_screen !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="block-screen-input"
              v-model="configuration.settings.security_options.block_screen"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="allow-text-selection-input"
          >
            <template #label>
              Block text selection
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('allow_selection')"
                v-if="!configuration.settings.security_options.allow_selection && configuration.settings.security_options.allow_selection !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="flippedBoolOptions"
              id="allow-text-selection-input"
              v-model="configuration.settings.security_options.allow_selection"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="allow-copy-paste-input"
          >
            <template #label>
              Block copy paste
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('copy_paste')"
                v-if="!configuration.settings.security_options.copy_paste && configuration.settings.security_options.copy_paste !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="allow-copy-paste-input"
              v-model="configuration.settings.security_options.copy_paste"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="proctor-setting-overrides-input"
          >
            <template #label>
              Proctor setting overrides
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('proctor_setting_overrides')"
                v-if="!configuration.settings.security_options.proctor_setting_overrides && configuration.settings.security_options.proctor_setting_overrides !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="proctor-setting-overrides-input"
              v-model="configuration.settings.security_options.proctor_setting_overrides"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.scoring-collapse
        >
          Scoring
        </b-button>
      </b-card-header>
      <b-collapse id="scoring-collapse" :visible="expand">
        <b-card-body>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="score-scale"
          >
            <template #label>
              Score Scale
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_scale_lower')"
                v-if="(!configuration.settings.score_scale_lower || !configuration.settings.score_scale_upper) && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <b-input-group id="score-scale">
              <!-- eslint-disable vue/no-mutating-props -->
              <b-form-input
                class="mr-1"
                placeholder="Score Scale Lower"
                @input="defaultNumberInputToNull($event, configuration.settings, 'score_scale_lower')"
                :value="configuration.settings.score_scale_lower"
                :disabled="!editable"
                :state="getInvalidState('score_scale_lower')"
              ></b-form-input>
              <!-- eslint-enable vue/no-mutating-props -->

              <!-- eslint-disable vue/no-mutating-props -->
              <b-form-input
                placeholder="Score Scale Upper"
                @input="defaultNumberInputToNull($event, configuration.settings, 'score_scale_upper')"
                :value="configuration.settings.score_scale_upper"
                :disabled="!editable"
                :state="getInvalidState('score_scale_upper')"
              ></b-form-input>
              <!-- eslint-enable vue/no-mutating-props -->
            </b-input-group>
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="score-report-preface"
          >
            <template #label>
              Score Report Preface
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_report')"
                v-if="!configuration.settings.score_report_preface && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-textarea 
              max-rows="99"
              id="score-report-preface"
              placeholder="Enter Score Report Preface"
              rows="1"
              no-resize
              :value="configuration.settings.score_report_preface"
              @input="defaultTextInputToNull($event, configuration.settings, 'score_report_preface')"
              :readonly="!editable"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="score-report-content"
          >
            <template #label>
              Score Report Content
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_report_content')"
                v-if="!configuration.settings.score_report_content && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-textarea 
              max-rows="99"
              id="score-report-content"
              placeholder="Enter Score Report Content"
              rows="1"
              no-resize
              :value="configuration.settings.score_report_content"
              @input="defaultTextInputToNull($event, configuration.settings, 'score_report_content')"
              :readonly="!editable"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="score-report-pass-message"
          >
            <template #label>
              Score Report Pass Message
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_report_pass_message')"
                v-if="!configuration.settings.score_report_pass_message && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-textarea 
              max-rows="99"
              id="score-report-pass-message"
              placeholder="Enter Score Report Pass Message"
              rows="1"
              no-resize
              :value="configuration.settings.score_report_pass_message"
              @input="defaultTextInputToNull($event, configuration.settings, 'score_report_pass_message')"
              :readonly="!editable"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="score-report-fail-message"
          >
            <template #label>
              Score Report Fail Message
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_report_fail_message')"
                v-if="!configuration.settings.score_report_fail_message && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-textarea 
              max-rows="99"
              id="score-report-fail-message"
              placeholder="Enter Score Report Fail Message"
              rows="1"
              no-resize
              :value="configuration.settings.score_report_fail_message"
              @input="defaultTextInputToNull($event, configuration.settings, 'score_report_fail_message')"
              :readonly="!editable"
            />
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <!-- eslint-disable vue/no-mutating-props -->

          <hr>
          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-score-input"
          >
            <template #label>
              Show Scaled Score
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_score')"
                v-if="!configuration.settings.show_score && configuration.settings.show_score !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-score-input"
              v-model="configuration.settings.show_score"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-score-scale-input"
          >
            <template #label>
              Show Score Scale
              <span
                v-b-tooltip.html="{trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_score_scale')"
                v-if="!configuration.settings.show_score_scale && configuration.settings.show_score_scale !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-score-scale-input"
              v-model="configuration.settings.show_score_scale"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-total-score-input"
          >
            <template #label>
              Show Total Score
              <span
                v-b-tooltip.html="{trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_total_score')"
                v-if="!configuration.settings.show_total_score && configuration.settings.show_total_score !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-total-score-input"
              v-model="configuration.settings.show_total_score"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-pass-fail-input"
          >
            <template #label>
              Show Pass/Fail Decision
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_pass_fail')"
                v-if="!configuration.settings.show_pass_fail && configuration.settings.show_pass_fail !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-pass-fail-input"
              v-model="configuration.settings.show_pass_fail"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-cutscore-input"
          >
            <template #label>
              Show Cutscore
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_cutscore')"
                v-if="!configuration.settings.show_cutscore && configuration.settings.show_cutscore !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-cutscore-input"
              v-model="configuration.settings.show_cutscore"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-print-button-input"
          >
            <template #label>
              Show Print Button
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_print_button')"
                v-if="!configuration.settings.show_print_button && configuration.settings.show_print_button !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-print-button-input"
              v-model="configuration.settings.show_print_button"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-email-button-input"
          >
            <template #label>
              Show Email Button
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_email_button')"
                v-if="!configuration.settings.show_email_button && configuration.settings.show_email_button !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-email-button-input"
              v-model="configuration.settings.show_email_button"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-content-area-score-input"
          >
            <template #label>
              Show Content Area Breakdown Score
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_content_area_score')"
                v-if="!configuration.settings.show_content_area_score && configuration.settings.show_content_area_score !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-content-area-score-input"
              v-model="configuration.settings.show_content_area_score"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-content-area-breakdown-input"
          >
            <template #label>
              Show Content Area Breakdown Percentage
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_content_area_breakdown')"
                v-if="!configuration.settings.show_content_area_breakdown && configuration.settings.show_content_area_breakdown !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-content-area-breakdown-input"
              v-model="configuration.settings.show_content_area_breakdown"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
          <!-- eslint-enable vue/no-mutating-props -->

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-content-area-breakdown-range-text-input"
          >
            <template #label>
              Show Content Area Breakdown Text
              <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('show_content_area_breakdown_range_text')"
                v-if="!configuration.settings.show_content_area_breakdown_range_text && configuration.settings.show_content_area_breakdown_range_text !== false && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="boolOptions"
              id="show-content-area-breakdown-range-text-input"
              v-model="configuration.settings.show_content_area_breakdown_range_text"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>

          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-score-integer-input"
          >
            <template #label>
              Score display type
              <span
                v-b-tooltip.html="{trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_display_type')"
                v-if="!configuration.settings.score_display_type && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
            <!-- eslint-disable vue/no-mutating-props -->
            <b-form-select
              :options="scoreDisplayTypeOptions"
              id="show-score-integer-input"
              v-model="configuration.settings.score_display_type"
              :disabled="!editable"
            ></b-form-select>
            <!-- eslint-enable vue/no-mutating-props -->
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.content-areas-collapse
        >
          Content Areas
        </b-button>
      </b-card-header>
      <b-collapse id="content-areas-collapse" :visible="expand">
        <b-card-body>



          <b-form-group
            label-cols-lg="5"
            label-cols-sm="6"
            label-for="show-print-button-input"
          >


          <template #label>
            Content Area Split
            <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('content_area_split_char')"
                :style="{ fontSize: '16px' }"
                v-if="!configuration.settings.content_area_split_char && configuration.settings.content_area_split_char !== '' && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
          </template>

          <!-- eslint-disable vue/no-mutating-props -->
          <b-form-select
            type="text"
            :options="splitCharOptions"
            v-model="configuration.settings.content_area_split_char"
            :disabled="!editable"
          ></b-form-select>
          <!-- eslint-enable vue/no-mutating-props -->


          </b-form-group>
          <p class="mt-4">
            Content Area Breakdown Text:
            <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('score_ranges')"
                :style="{ fontSize: '16px' }"
                v-if="(!configuration.settings.score_ranges || !configuration.settings.score_ranges.length) && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
          </p>
          <div>
            <b-card
              :key="index"
              bg-variant="light"
              class="mt-3"
              no-body
              v-for="(score_range, index) in configuration.settings
                .score_ranges"
            >
              <b-card-body>
                <b-form-group
                  :label-for="'score-range-start' + index"
                  label="Lower *"
                  label-cols-sm="3"
                >
                  <b-form-input
                    :id="'score-range-start' + index"
                    max="100"
                    min="0"
                    required
                    type="number"
                    v-model.number="score_range.start"
                    :disabled="!editable"
                    :state="getInvalidState('scoreRangeStart', index)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label-for="'score-range-end' + index"
                  label="Upper *"
                  label-cols-sm="3"
                >
                  <b-form-input
                    :id="'score-range-end' + index"
                    max="100"
                    min="0"
                    required
                    type="number"
                    v-model.number="score_range.end"
                    :disabled="!editable"
                    :state="getInvalidState('scoreRangeEnd', index)"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label-for="'score-range-text' + index"
                  label="Text *"
                  label-cols-sm="3"
                >
                  <b-form-textarea 
                    required
                    max-rows="99"
                    :id="'score-range-text' + index"
                    rows="1"
                    no-resize
                    v-model="score_range.text"
                    :readonly="!editable"
                    :state="getInvalidState('scoreRangeText', index)"
                  />
                </b-form-group>

                <b-link
                  @click="deleteScoreRange(index)"
                  class="float-right"
                  variant="white"
                  :disabled="!editable"
                  >Delete Score Range</b-link
                >
              </b-card-body>
            </b-card>

            <b-button
              @click="addScoreRange"
              class="mt-3 mb-3"
              size="sm"
              variant="primary-light"
              :disabled="!editable"
              >Add Score Range</b-button
            >
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="my-1">
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left"
          variant="primary"
          size="lg"
          v-b-toggle.cutscores-collapse
        >
          Cutscores
        </b-button>
      </b-card-header>
      <b-collapse id="cutscores-collapse" :visible="expand">
        <b-card-body>
          <h4>
            <span
                v-b-tooltip.html="{ trigger: 'hover', interactive: false }"
                :title="defaultValueMessage('cutscores')"
                :style="{ fontSize: '16px' }"
                v-if="(!configuration.settings.cutscores || !configuration.settings.cutscores.length) && canShowWarnings"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
          </h4>
          <div>
            <b-card
              :key="index"
              bg-variant="light"
              class="mt-3"
              no-body
              v-for="(cutscore, index) in configuration.settings.cutscores"
            >
              <b-card-body>
                <b-form-group
                  :label-for="'cutscore-name' + index"
                  label="Cutscore Name *"
                  label-cols-sm="3"
                >
                  <b-form-input
                    @input="defaultTextInputToNull($event, cutscore, 'name')"
                    :value="cutscore.name"
                    :id="'cutscore-name' + index"
                    required
                    type="text"
                    :disabled="!editable"
                    :state="getInvalidState('cutscoreNames', index)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label-for="'required-score' + index"
                  label="Required Score *"
                  label-cols-sm="3"
                >
                  <b-form-input
                    :id="'required-score' + index"
                    required
                    type="number"
                    v-model.number="cutscore.score"
                    :disabled="!editable"
                    :state="getInvalidState('cutscoreScores', index)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label-for="'cutscore-description' + index"
                  label="Description"
                  label-cols-sm="3"
                >
                  <textarea-autosize
                    :id="'cutscore-description' + index"
                    :min-height="37"
                    class="form-control"
                    rows="1"
                    v-model="cutscore.description"
                    :disabled="!editable"
                  />
                </b-form-group>
                <b-link
                  @click="deleteCutscore(index)"
                  class="float-right"
                  variant="white"
                  :disabled="!editable"
                  >Delete Cutscore</b-link
                >
              </b-card-body>
            </b-card>
            <b-button
              @click="addCutscore"
              class="mt-3 mb-3"
              size="sm"
              variant="primary-light"
              :disabled="!editable"
              >Add Cutscore</b-button
            >
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { Language } from '../../utils/language'
import { defaultNumberInputToNull, defaultTextInputToNull } from '../../utils/misc'
import { SESSION } from '../../utils/session'
import { BUILTIN_LANGUAGES } from '../../utils/constants'
const READABLE_VALUES = {
  "true": "Enabled",
  "false": "Disabled",
  "dec": "Decimal",
  "int": "Integer"
}

function flattenAndUpdate(objToUpdate, objToUpdateFrom) {
  for (let key in objToUpdateFrom) {
    let value = objToUpdateFrom[key]
    if (typeof value == 'object') {
      flattenAndUpdate(objToUpdate, value)
    } else {
      objToUpdate[key] = value
    }
  }
}
export default {
  name: 'ConfigurationFields',
  props: {
    configuration: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: true
    },
    hideName: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    invalidFields: {
      type: Object
    }
  },
  created() {
    if (!this.configuration.is_default) {
      this.boolOptions.unshift({text: 'Use Value From Parent Configuration', value: null})

      this.flippedBoolOptions.unshift({ text: 'Use Value From Parent Configuration', value: null })

      this.splitCharOptions.unshift({ text: 'Use Value From Parent Configuration', value: null })

      this.scoreDisplayTypeOptions.unshift({ text: 'Use Value From Parent Configuration', value: null })
    }

    if (!this.configuration.settings.security_options) {
      this.$set(this.configuration.settings, 'security_options', {})
    }
    if (!this.configuration.settings.selected_languages) {
      this.$set(this.configuration.settings, 'selected_languages', [])
      this.configuration.settings.selected_languages = ['English'] // eslint-disable-line vue/no-mutating-props
    }
    if (!this.configuration.settings.default_language) {
      this.configuration.settings.default_language = 'English' // eslint-disable-line vue/no-mutating-props
    }
    if (this.configuration.settings.cutscores) {
      for (const cutscore of this.configuration.settings.cutscores) {
        if (!cutscore.name) cutscore.name = null
      }
    }
    flattenAndUpdate(this.defaultFieldValues, SESSION.project.settings)
    this.FetchLanguages()
  },
  data() {
    return {
      expand: !this.collapsed,
      boolOptions: [
        {text: 'Enabled', value: true},
        {text: 'Disabled', value: false}
      ],
      flippedBoolOptions: [
        { text: 'Enabled', value: false },
        { text: 'Disabled', value: true }
      ],
      examModeOptions: ['linear', 'adaptive'],
      splitCharOptions: [
        { text: 'By Selected Value', value: '' },
        { text: 'By Divider', value: '|' }
      ],
      scoreDisplayTypeOptions: [
        { text: 'Decimal', value: 'dec' },
        { text: 'Integer', value: 'int' }
      ],
      missingFieldMessage: 'This setting has no value and will use the value set in the parent configuration:',
      defaultFieldValues: {},
      defaultNumberInputToNull,
      defaultTextInputToNull,
      languages: []
    }
  },
  methods: {
    onSelectedLanguageChange (value) {
      const { settings } = this.configuration

      settings.selected_languages = value

      if (!settings.selected_languages.includes(settings.default_language)) {
        settings.default_language = settings.selected_languages[0] || null
      }
    },
    getInvalidState (key, index) {
      if (this.invalidFields && key in this.invalidFields) {
        if (typeof index === 'number') {
          if (index in this.invalidFields[key]) {
            return false
          }

          return null
        }

        return false
      }

      return null
    },
    addScoreRange() {
      if (!this.configuration.settings.score_ranges) {
        this.$set(this.configuration.settings, 'score_ranges', [])
      }

      this.configuration.settings.score_ranges.push({ // eslint-disable-line vue/no-mutating-props
        end: null,
        start: null,
        text: ''
      })
    },
    deleteScoreRange(index) {
      this.configuration.settings.score_ranges.splice(index, 1) // eslint-disable-line vue/no-mutating-props
    },
    addCutscore() {
      if (!this.configuration.settings.cutscores) {
        this.$set(this.configuration.settings, 'cutscores', [])
      }

      this.configuration.settings.cutscores.push({ // eslint-disable-line vue/no-mutating-props
        description: '',
        name: null,
        score: null
      })
    },
    deleteCutscore(index) {
      this.configuration.settings.cutscores.splice(index, 1) // eslint-disable-line vue/no-mutating-props
    },
    onBlockTextSelectionChange() {
      const securityOptions = this.configuration.settings.security_options

      if (!securityOptions.hasOwnProperty('allow_selection')) { // eslint-disable-line no-prototype-builtins
        return this.$set(securityOptions, 'allow_selection', false)
      }

      securityOptions.allow_selection = !securityOptions.allow_selection
    },
    clearAllSettings() {
      // eslint-disable-next-line vue/no-mutating-props
      this.configuration.settings = {
        security_options: {}
      }
    },
    defaultValueMessage(field) {
      return this.missingFieldMessage + "<br><br>" + this.readableValue(this.defaultFieldValues[field])
    },
    readableValue(value) {
      if (typeof value === 'boolean') {
        value = JSON.stringify(value)
      }
      if (value in READABLE_VALUES) return READABLE_VALUES[value]
      return value
    },
    async FetchLanguages() {
      const response = await Language.FetchAll(SESSION.project.id)

      if (response.error) {
        console.error('it broke')
        return
      }

      this.languages = [...response.results]
    },
    sortOptionByTextDesc(a, b) {
      return a.text.localeCompare(b.text)
    }
  },
  computed: {
    blockTextSelectionValue() {
      const securityOptions = this.configuration.settings.security_options

      if (!securityOptions.hasOwnProperty('allow_selection')) { // eslint-disable-line no-prototype-builtins
        return false
      }

      return !securityOptions.allow_selection
    },
    expandOrCollapse() {
      return this.expand ? 'Collapse All' : 'Expand All'
    },
    isAdaptiveMode() {
      return this.configuration.settings.mode === 'adaptive'
    },
    canShowWarnings() {
      return !this.configuration.is_default && this.editable
    },
    showDefaultLanguage() {
      return this.configuration.settings.selected_languages && this.configuration.settings.selected_languages.length
    },
    builtinLanguageOptions() {
      const builtinLanguageOptions = BUILTIN_LANGUAGES.map(language => {
        return {text: language + '*', value: language}
      }).sort(this.sortOptionByTextDesc)

      return builtinLanguageOptions
    },
    defaultLanguageOptions() {
      const selectedlanguages = this.languages.filter(language => {
        return this.configuration.settings.selected_languages.includes(language.id)
      })

      const selectedLanguageOptions = selectedlanguages.map(language => {
        return {text: language.name, value: language.id}
      })

      const filteredBuiltinLanguageOptions = this.builtinLanguageOptions.filter(language => {
        return this.configuration.settings.selected_languages.includes(language.value)
      })

      const options = [
        ...selectedLanguageOptions,
        ...filteredBuiltinLanguageOptions
      ]

      return options
    },
    languageOptions() {
      const customLanguageOptions = this.languages.map(language => {
        return {text: language.name, value: language.id}
      }).sort(this.sortOptionByTextDesc)

      const options = [
        ...customLanguageOptions,
        ...this.builtinLanguageOptions
      ]

      return options
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
