<template>
  <b-modal @hide="onModalHide" @hidden="onModalHidden" id="details-modal" size="xl" title="Exam Details" no-fade>
    
    <div v-if="showExamInfo" class="mt-4">
      <h5>Exam Info</h5>

      <h5>
        <b-badge v-if="exam.paused" variant="info" class="p-2 mr-2">
          <font-awesome-icon icon="pause" class="mr-1" />
          Exam is paused
        </b-badge>
        <b-badge v-if="exam.disconnected" variant="danger" class="p-2 mr-2">
          <font-awesome-icon icon="plug" class="mr-1" />
          Examinee has disconnected
        </b-badge>
        <b-badge @click="openSpawnedExam" v-if="exam.spawned" variant="success" class="p-2 badge-link">
          <font-awesome-icon icon="link" class="mr-1" />
          Examinee started a new delivery
        </b-badge>
      </h5>
    </div>
    <h5 class="my-3">Progress</h5>

    <b-progress :max="exam.progressTotal">
      <b-progress-bar :value="exam.progressComplete" :label="exam.progressText" variant="warning" />
    </b-progress>
    <b-alert :variant="exam.statusVariant" show class="mt-3 d-flex justify-content-between align-items-center">
      {{ status }}

      <b-dropdown text="Actions" variant="white" right>
        <div v-if="freshOrInProgress">
          <b-dropdown-item-button @click="onProctorEvent('resume')">
            <font-awesome-icon icon="play" class="mr-1" />
            Resume
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="onProctorEvent('pause')">
            <font-awesome-icon icon="pause" class="mr-1" />
            Pause
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="onProctorEvent('stabilize')">
            <font-awesome-icon icon="redo" class="mr-1" />
            Fix Stuck
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="onProctorEvent('suspend')">
            <font-awesome-icon icon="stop" class="mr-1" />
            Suspend
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="onProctorEvent('override')">
            <font-awesome-icon icon="clock" class="mr-1" />
            Override Waiting Room
          </b-dropdown-item-button>
        </div>

        <b-dropdown-item-button v-if="exam.spawned" @click="openSpawnedExam">
          <font-awesome-icon icon="link" class="mr-1" />
          View new delivery
        </b-dropdown-item-button>

        <b-dropdown-item-button v-if="exam.disconnected" @click="onRemoveExam">
          <font-awesome-icon icon="trash" class="mr-1" />
          Remove Exam From View
        </b-dropdown-item-button>
      </b-dropdown>
    </b-alert>

    <hr/>
    <div v-if="freshOrInProgress" >
      <h5 class="mt-4">Launch Tokens 
        <b-button
          @click="getLaunchTokens()"
          :disabled="loadingTokens"
          variant="white"
          size="sm"
          class="float-right"
        >
          <b-spinner
            label="Small Spinner"
            small
            v-show="loadingTokens"
          ></b-spinner
          >&nbsp;Generate
        </b-button></h5>
      <div v-if="!loadingTokens && launchLink" class="mt-4">
        <label for="take">Take URL</label>
        <b-input-group class="mb-3">
          <template v-slot:append>
            <b-tooltip
              :show.sync="showCopyTooltip['take']"
              target="take-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('take')"
              id="take-copy-btn"
              variant="white"
              >Copy</b-button
            >
            <b-button @click="launch('launchLink')">Launch</b-button>
          </template>
          <b-form-input
            :value="launchLink"
            class="mr-1"
            name="take"
            readonly
            ref="take"
          ></b-form-input>
        </b-input-group>
        <label for="proctor">Proctor URL</label>
        <b-input-group class="mb-3">
          <b-form-input
            :value="proctorLink"
            class="mr-1"
            name="proctor"
            readonly
            ref="proctor"
          ></b-form-input>
          <b-input-group-append>
            <b-tooltip
              :show.sync="showCopyTooltip['proctor']"
              target="proctor-copy-btn"
              title="Copied!"
              triggers="manual"
            ></b-tooltip>
            <b-button
              @click="copyText('proctor')"
              id="proctor-copy-btn"
              variant="white"
              >Copy</b-button
            >
            <b-button @click="launch('proctorLink')">Launch</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <hr />
    </div>
    <div v-if="showTags" class="mt-4">
      <h5>Tags</h5>

      <h5>
        <b-badge v-for="(tag, index) in exam.tags" :key="index" variant="secondary" class="p-2 mr-2">
          {{ tag }}
        </b-badge>
      </h5>
    </div>
    
    <div v-if="showExamineeInfo" class="my-4">
      <h5>Examinee Info</h5>
      <b-row class="py-2 no-gutters" v-for="(value, key, index) in exam.examineeInfo" :key="index" style="border-bottom: 1px dashed #eee;">
        <b-col cols="6">{{ key }}: </b-col>
        <b-col cols="6">{{ value }}</b-col>
      </b-row>
      <hr>
    </div>

    <!-- New Proctor controls -->
    <template v-if="exam.proctorSettingOverrides">
      <h5 class="mb-4">
          Settings

          <b-button
              @click="toggleSecuritySettings"
              variant="white"
              size="sm"
              class="float-right"
          >
              {{ toggleSecuritySettingsText }}
          </b-button>
      </h5>

      <div v-show="showSecuritySettings" class="mt-2">
        <b-row class="no-gutters mb-3">
          <b-col cols="12" sm="6">
            <label>Add time (seconds)</label>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-input v-model.number="moreTime" id="more-time" type="number" min="0" />
            <small>
              {{ currentTimeLimit }}

              <br>

              {{ newTimeLimit }}
          </small>
          </b-col>
        </b-row>

        <b-form-group
          label="Allow users to go back and change answers"
          label-for="go-back"
          label-cols-lg="6"
          label-cols-sm="6"
        >
          <b-form-select v-model="markAndReview" :options="boolOptions" id="go-back" />
        </b-form-group>

        <b-form-group
          label="Prevent mark and review between sections"
          label-for="mark-and-review"
          label-cols-lg="6"
          label-cols-sm="6"
        >
          <b-form-select v-model="preventSectionMarkAndReview" :options="boolOptions" id="mark-and-review" />
        </b-form-group>

        <b-form-group
          label="Block screen on certain keys"
          label-for="block-keys"
          label-cols-lg="6"
          label-cols-sm="6"
        >
          <b-form-select v-model="blockScreen" :options="boolOptions" id="block-keys" />
        </b-form-group>

        <b-form-group
          label="Block text selection"
          label-for="block-selection"
          label-cols-lg="6"
          label-cols-sm="6"
        >
          <b-form-select v-model="allowSelection" :options="flippedBoolOptions" id="block-selection" />
        </b-form-group>

        <b-form-group
          label="Block copy paste"
          label-for="block-copy-paste"
          label-cols-lg="6"
          label-cols-sm="6"
        >
          <b-form-select v-model="copyPaste" :options="boolOptions" id="block-copy-paste" />
        </b-form-group>
        <b-button
          @click="saveSecuritySettings"
          :disabled="savingSettings"
          variant="secondary"
          size="sm"
        >
          Save changes
        </b-button>
      </div>

      <hr>
    </template>
    <!-- End of New Proctor controls -->

    <h5 class="mt-4">Logs</h5>

    <b-list-group>
      <b-list-group-item v-for="(log, index) in exam.logs" :key="index" :class="logClass(log)">
        <div class="d-flex justify-content-between w-100">
          <span class="log-content">{{ log.event }} </span>
          <span>{{ log.time }}</span>
        </div>
      </b-list-group-item>
    </b-list-group>

    <template #modal-footer="{ cancel }">
      <b-button @click="cancel" variant="white">Close</b-button>
    </template>
  </b-modal>
</template>

<script>
import { EVENT } from '../utils/event-bus'
import { HTTP } from '../utils/requests'
import { SEI_API_BASE } from '../utils/constants'

async function getLaunchTokenData(projectId, deliveryId) {
  try {
    const launchUrl = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}/get_launch_token`
    const proctorUrl = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}/get_proctor_token`
    const responseArray = await Promise.all([
      HTTP.get(launchUrl),
      HTTP.get(proctorUrl)
    ])
    const launchToken = responseArray[0].data.launch_token
    const proctorToken = responseArray[1].data.proctor_token
    return { data: { launchToken, proctorToken } }
  } catch (error) {
    return { error }
  }
}

export default {
  name: 'ExamWatchDetails',
  created() {
    EVENT.$on('exam-details-modal', this.onBeforeOpenModal)
  },
  beforeDestroy() {
    EVENT.$off('exam-details-modal')
  },
  props: [
    'socket'
  ],
  data() {
    return {
      exam: {},
      loadingTokens: false,
      showSecuritySettings: false,
      savingSettings: false,
      launchLink: '',
      proctorLink: '',
      showCopyTooltip: {
        take: false,
        proctor: false
      },
      boolOptions: [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false }
      ],
      flippedBoolOptions: [
        { text: 'Enabled', value: false },
        { text: 'Disabled', value: true }
      ],
      moreTime: null,
      markAndReview: false,
      preventSectionMarkAndReview: false,
      blockScreen: false,
      allowSelection: false,
      copyPaste: false
    }
  },
  methods: {
    saveSecuritySettings () {
        this.savingSettings = true

        const payload = {
            delivery_id: this.exam.id,
            mark_and_review: this.markAndReview,
            prevent_section_mark_and_review: this.preventSectionMarkAndReview,
            block_screen: this.blockScreen,
            allow_selection: this.allowSelection,
            copy_paste: this.copyPaste
        }

        if (this.exam.timeLimit && this.moreTime && this.moreTime > 0) {
            payload.time_limit = this.exam.timeLimit + this.moreTime
        }

        this.socket.emit('exam_watch_settings', payload, () => {
            this.savingSettings = false
        })
    },
    toggleSecuritySettings () {
        this.showSecuritySettings = !this.showSecuritySettings
    },
    logClass(log) {
      let classString = 'd-flex align-items-center '

      if (log.warning) {
        classString += 'log-warning'
      } else {
        classString += 'log-event'
      }

      return classString
    },
    onProctorEvent(event) {
      this.$emit('proctor-event', event, this.exam.id)
    },
    onRemoveExam() {
      this.$emit('remove-exam', this.exam.projectId, this.exam.id)
      this.$bvModal.hide('details-modal')
    },
    onBeforeOpenModal(exam, updateSettings) {
      if (updateSettings) {
        this.moreTime = null

        this.markAndReview = exam.markAndReview

        this.preventSectionMarkAndReview = exam.preventSectionMarkAndReview

        this.blockScreen = exam.blockScreen

        this.allowSelection = exam.allowSelection

        this.copyPaste = exam.copyPaste
      }

      this.exam = exam
      this.$bvModal.show('details-modal')

      this.$emit('active-exam', exam.id)
    },
    onModalHide() {
      this.$emit('active-exam', null)
    },
    onModalHidden() {
      this.showSecuritySettings = false

      this.moreTime = null
    },
    async getLaunchTokens() {
      this.loadingTokens = true
      const { data, error } = await getLaunchTokenData(this.exam.projectId, this.exam.id)
      if (error) {
        EVENT.alert({
          variant: 'danger',
          message: 'Failed to get launch tokens.'
        })
        this.loadingTokens = false
        return
      }
      this.launchLink = `${location.origin}/take?launch_token=${data.launchToken}`
      this.proctorLink = `${location.origin}/proctor/${data.proctorToken}`
      this.loadingTokens = false
    },
    copyText(type) {
      this.$refs[type].select()
      document.execCommand('copy')
      this.showCopyTooltip[type] = true
      setTimeout(() => (this.showCopyTooltip[type] = false), 1200)
    },
    launch(type) {
      open(this[type], '_blank')
    },
    openSpawnedExam() {
      this.$emit('set-spawned-exam', this.exam.projectId, this.exam.spawnedDeliveryId)
    }
  },
  computed: {
    currentTimeLimit () {
        const time = this.exam.timeLimit ? `${ this.exam.timeLimit } seconds` : 'Unlimited'

        return `Current time limit: ${ time }`
    },
    newTimeLimit () {
        if (!this.moreTime || this.moreTime < 0 || !this.exam.timeLimit) return ''

        const time = this.exam.timeLimit + this.moreTime

        return `New time limit: ${ time } seconds`
    },
    toggleSecuritySettingsText () {
        return this.showSecuritySettings ? 'Hide' : 'Show'
    },
    status() {
      const { statusText } = this.exam

      let prefixText = 'Exam is'

      if (statusText === 'manual scoring') {
        prefixText = 'Exam needs'
      }

      if (statusText === 'abandoned' || statusText === 'suspended') {
        prefixText = 'Exam has been'
      }

      return `${prefixText} ${statusText}`
    },
    showExamInfo() {
      return this.exam.paused || this.exam.disconnected || this.exam.spawned
    },
    showExamineeInfo() {
      const { examineeInfo } = this.exam

      return examineeInfo && Object.keys(examineeInfo).length
    },
    showTags() {
      return Boolean(this.exam.tags?.length)
    },
    freshOrInProgress() {
      return ['fresh', 'in progress'].includes(this.exam.statusText)
    }
  }
}
</script>

<style lang="scss" scoped>
.log-warning {
  border-left: 5px solid red;
}

.log-event {
  .log-content {
    margin-left: 4px;
  }
}

.badge-link {
  cursor: pointer;
}
</style>
