<template>
  <div>
    <b-button v-if="isEdit" :disabled="shouldDisable('manage_translations')" @click="showModal" variant="white" size="sm">Edit properties</b-button>
    <b-button v-else :disabled="shouldDisable('manage_translations')" @click="showModal">Add language</b-button>

    <b-modal
      :id="modalId"
      size="xl"
      title="Language"
    >
      <b-form @submit.prevent="submitLanguageForm" id="language-form" novalidate>

        <b-form-group label="Name *" label-for="name-input" label-cols-lg="3" label-cols-sm="4">
          <b-form-input
            id="name-input"
            required
            type="text"
            v-model="languageForm.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Direction" label-for="direction-input" label-cols-lg="3" label-cols-sm="4">
          <b-form-select
            :options="DIRECTION_OPTIONS"
            id="direction-input"
            v-model="languageForm.properties.direction"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Auto-translate language" label-for="auto-translate-input" label-cols-lg="3" label-cols-sm="4">
          <b-form-select
            :options="languageOptions"
            id="auto-translate-input"
            v-model="languageForm.properties.auto_translate_language_code"
          ></b-form-select>
        </b-form-group>
        
        <!-- <b-form-group v-if="!isEdit" label-for="google-translate-input" label-cols-lg="3" label-cols-sm="4">
          <b-form-checkbox
            id="google-translate-input"
            v-model="languageForm.googleTranslate"
            class="d-inline"
          ></b-form-checkbox>
          <label>Populate all fields with Google translations upon creation.</label>
        </b-form-group> -->
      </b-form>

      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="closeModal(cancel)" variant="white">Cancel</b-button>
          <b-button
            form="language-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>
<script>

import { SESSION } from '../../utils/session'

export default {
  name: 'TranslationsLanguageAddEdit',
  components: {},
  props: {
		project: {
      type: Object
    },
    language: {
      type: Object
    },
    supportedLanguages: {
      type: Object
    }
  },
  data() {
    return {
      DIRECTION_OPTIONS: [
        {value: 'ltr', text: 'Left to right'},
        {value: 'rtl', text: 'Right to left'}
      ],
      saving: false,
      DEFAULT_LANGUAGE_OPTION: {text: 'Select a language for auto translation', value: null}
    }
  },
  async created() {
    if (this.language) {
      this.languageForm = { ...this.language.toJson() }
    }
  },
  methods: {
    async submitLanguageForm() {
      if (this.language.id) {
        await this.language.update(this.languageForm)
      } else {
        await this.language.create(this.languageForm)
      }

      this.closeModal(this.hideModal)

      this.$forceUpdate()
    },
    showModal() {
      this.$bvModal.show(this.modalId)
    },
    hideModal() {
      this.$bvModal.hide(this.modalId)
    },
    closeModal(closeFn) {
      this.$emit('closed')
      closeFn()
    },
    shouldDisable(neededPerms) {
      return !SESSION.hasPermissions(neededPerms)
    }
  },
  computed: {
    modalId() {
      let id = 'language-modal'

      if (this.language) {
        id += `-${this.language.id}`
      }

      return id
    },
    isEdit() {
      return !!this.language?.id
    },
    languageOptions() {
      if (this.supportedLanguages) {
        return [this.DEFAULT_LANGUAGE_OPTION, ...this.supportedLanguages.vue_options]
      }

      return [this.DEFAULT_LANGUAGE_OPTION]
    }
  }
}
</script>

<style lang="scss" scoped></style>
