<template>
  <div>
    <b-alert
      show
      variant="info"
      v-if="!isPremium && isPremiumEnabled"
      class="d-flex justify-content-between align-items-center"
    >
      Free accounts cannot edit item meta
      <b-button size="sm" @click="upgrade" variant="info"
        >Upgrade to Premium</b-button
      >
    </b-alert>

    <div>
      <small v-if="showRestrictionMessage"
        >Changing custom fields could limit your access to this item</small
      >
    </div>
    <div :key="id" v-for="id in metaKeys">
      <b-row class="my-3">
        <b-col lg="3" md="4" sm="6">
          <label for="Order">{{ fieldLabel(id) }}</label>
          <div v-if="hasDescription(id)">
            <small>{{ getDescription(id) }}</small>
          </div>
        </b-col>
        <b-col lg="9" md="8" sm="6">
          <textarea-autosize
            rows="1"
            class="form-control"
            :min-height="37"
            :disabled="!isPremium || disabled(id)"
            :required="required(id)"
            placeholder="Text"
            v-if="display(id, 'text')"
            v-model="field[id][optionId]"
          />

          <b-form-input
            :disabled="!isPremium || disabled(id)"
            :required="required(id)"
            placeholder="Number"
            type="number"
            v-if="display(id, 'number')"
            v-model.number="field[id][optionId]"
          ></b-form-input>

          <v-select
            :create-option="tag => ({ text: tag, value: tag })"
            :data-value="isValid(id, optionId)"
            :disabled="!isPremium || disabled(id)"
            :options="options[id]"
            :reduce="option => option.value"
            :required="required(id)"
            :selectable="option => !option.disabled"
            :multiple="display(id, 'select_multi')"
            :taggable="extendable(id)"
            v-if="display(id, 'select_one') || display(id, 'select_multi')"
            label="text"
            placeholder="Not selected"
            style="backgroundColor: white"
            v-model="field[id][optionId]"
          >
            <template #selected-option-container="{ option, deselect }">
              <span
                :class="`vs__selected border ${option.color ? 'pl-0' : ''}`"
              >
                <div
                  :style="{
                    backgroundColor: option.color,
                    width: '15px',
                    height: '100%',
                    borderRadius: '3px 0 0 3px'
                  }"
                  class="mr-1"
                  v-if="option.color"
                ></div>
                {{ option.text }}
                <button
                  :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                  :disabled="!isPremium || disabled(id)"
                  @click.stop="deselect(option)"
                  @mousedown.stop
                  class="vs__deselect no-focus"
                  type="button"
                >
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
              </span>
            </template>
            <template #option="{ color, text }">
              <div
                :style="{ background: color }"
                class="option p-1 d-inline-block"
                v-if="color"
              ></div>
              {{ text }}
            </template>
          </v-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { IS_UPGRADE_TO_PREMIUM_ENABLED } from '../../utils/constants'
  import { deepCopy } from '../../utils/misc'
  import { SESSION } from '../../utils/session'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { v4 as uuidv4 } from 'uuid'
  import get from 'lodash.get'

  const EMPTY_OPTION = {
    text: '',
    color: '',
    preview_text: ''
  }

  const EMPTY_DIVIDER = {
    text: '',
    is_divider: true
  }

  export default {
    name: 'ItemMeta',
    props: {
      fields: {
        type: Object
      },
      project: {
        type: Object
      },
      isPremium: {
        type: Boolean
      },
      only: {
        type: Array,
        default: null
      },
      optionId: {
        type: String
      }
    },
    created() {
      this.metaHelper = initMetaHelper(SESSION.project)
      this.setFields()
      this.createOptions()
    },
    data() {
      return {
        options: {},
        editing: {},
        metaHelper: {},
        tag: '',
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED
      }
    },
    methods: {
      setFields() {
        if (!this.fields.meta) {
          this.fields.meta = {} // eslint-disable-line vue/no-mutating-props
        }

        if (!this.fields.meta.scorpion) {
          this.$set(this.fields.meta, 'scorpion', {})
        }
      },
      createOptions() {
        if (!this.metaHelper.hasCustomFields) return

        for (const id in this.project.meta.scorpion.custom) {
          const field = this.project.meta.scorpion.custom[id]

          if (!this.fields.meta.scorpion.hasOwnProperty(id)) { // eslint-disable-line no-prototype-builtins
            this.fields.meta.scorpion[id] = // eslint-disable-line vue/no-mutating-props
              field.type === 'select_multi' ? [] : ''
          }

          if (!field.type.startsWith('select_')) continue

          const options = [{ text: 'Select', value: '', disabled: true }]

          for (const optionId of field.option_order) {
            options.push({
              text: field.options[optionId].text,
              value: optionId,
              disabled: field.options[optionId].is_divider,
              color: field.options[optionId].color
            })
          }

          this.$set(this.options, id, options)
        }
      },
      addOption() {
        const newOption = deepCopy(EMPTY_OPTION)
        newOption.id = uuidv4()
        this.editing.options[newOption.id] = newOption
        this.editing.option_order.push(newOption.id)
        this.$nextTick(() => this.scrollToBottom())
      },
      addDivider() {
        const newDivider = deepCopy(EMPTY_DIVIDER)
        newDivider.id = uuidv4()
        this.editing.options[newDivider.id] = newDivider
        this.editing.option_order.push(newDivider.id)
        this.$nextTick(() => this.scrollToBottom())
      },
      scrollToBottom() {
        setTimeout(() => {
          const element = document.getElementById('optionlist')
          element.scrollTop = element.scrollHeight
        }, 10)
      },
      deleteOption(index) {
        const optionId = this.editing.option_order[index]
        this.editing.option_order.splice(index, 1)
        delete this.editing.options[optionId]
      },
      moveArrayElement(array, index, by) {
        const newIndex = index + by
        const element = array.splice(index, 1)[0]
        array.splice(newIndex, 0, element)
      },
      openModal() {
        this.$bvModal.show('options-modal')
      },
      display(id, type) {
        return this.project.meta.scorpion.custom[id].type === type
      },
      required(id) {
        return this.project.meta.scorpion.custom[id].required
      },
      extendable(id) {
        return this.project.meta.scorpion.custom[id].extendable
      },
      disabled(id) {
        return (
          !SESSION.isAdmin() && this.project.meta.scorpion.custom[id].admin_only
        )
      },
      fieldLabel(id) {
        const customField = this.project.meta.scorpion.custom[id]
        let label = customField.name

        if (customField.required) {
          label += ' *'
        }

        return label
      },
      isSelectable(option) {
        return !option.is_divider
      },
      upgrade() {
        const orgId = this.project.organization_id

        if (!orgId) {
          return this.$router.push({
            name: 'projectSettings'
          })
        }

        this.$router.push({
          name: 'orgBilling',
          params: { orgId }
        })
      },
      isValid(id, optionId) {
        const field = this.field[id]
        const length = get(field[optionId], 'length')

        return length ? length : ''
      },
      hasDescription(id) {
        return this.getDescription(id).length
      },
      getDescription(id) {
        return this.project.meta.scorpion.custom[id].description || ''
      }
    },
    computed: {
      field() {
        return this.fields.meta.scorpion
      },
      metaKeys() {
        if (this.only && this.only.length >= 0) {
          return this.only
        }

        return this.project.meta.scorpion.custom_field_order.filter(metaKey => {
          if (!this.project.meta.scorpion.custom[metaKey].by_option) {
            return metaKey
          }
        })
      },
      isWorkshop() {
        return SESSION.project.my_role_types.includes('workshop')
      },
      showRestrictionMessage() {
        return this.isWorkshop && this.metaKeys && this.metaKeys.length
      }
    }
  }
</script>
