<template>
  <b-container class="mt-3" fluid v-if="!loading">
     <TranslationsStickyTools>
      <template v-slot:columns>
        <b-col cols="12" sm="6" md="4" class="mb-1">
          <b-form @submit="search()">
            <b-input-group>
              <b-input size="sm" type="text" placeholder="Search" v-model="searchText"></b-input>
              <b-button size="sm" type="submit" variant="white">Search</b-button>
            </b-input-group>
          </b-form>
        </b-col>
        <b-col cols="6" sm="6" md="4" class="mb-1">
          <b-select size="sm" :value="helper.status" @change="filter($event)" v-if="showFilters">
            <option value="">All</option>
            <option value="not_complete">Incomplete</option>
            <option value="complete">Complete</option>
          </b-select>
        </b-col>
        <b-col cols="6" md="4" class="mb-1">
          <b-pagination
            size="sm"
            v-if="showPagination"
            :per-page="perPage"
            :total-rows="total"
            :value="page"
            class="float-right"
            @change="changePage"
          ></b-pagination>
        </b-col>
      </template>
    </TranslationsStickyTools>

    <div v-if="agreements.length">
      <b-button v-for="agreement in agreements" :key="agreement.id" class="w-100 border-bottom d-block pb-2" style="text-align:unset;" @click="setActive(agreement.id)" variant="text" size="sm">
        <b-row>
          <b-col cols="2" md="1">
            <b-badge
              class="text-capitalize statuses"
              v-b-tooltip="{ trigger: 'hover', interactive: false }"
              v-if="agreement.isComplete"
              >C</b-badge
            >
            <b-badge
              class="text-capitalize statuses"
              variant="warning"
              v-b-tooltip="{ trigger: 'hover', interactive: false }"
              v-if="!agreement.isComplete"
              >I</b-badge
            >
          </b-col>
          <b-col cols="5">
            <label>Name</label>
            <small><div v-text="preview(agreement.name)"></div></small>
          </b-col>
          <b-col cols="6">
            <label>Translation</label>
            <small><div v-text="preview(agreement.form.name)" v-direction="language.properties.direction"></div></small>
          </b-col>
        </b-row>
      </b-button>
      <b-row>
        <b-col class="mt-3">
          <b-pagination
            size="sm"
            v-if="showPagination"
            :per-page="perPage"
            :total-rows="total"
            :value="page"
            class="float-right"
            @change="changePage"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div v-else>Nothing to translate</div>

    <TranslationModalWrapper
      :modalId="modalId"
      :closeModal="closeModal"
      :save="save"
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      :mode="mode"
      @changes="handleModalChanges"
      @previous="previous"
      @next="next"
    >
      <template v-slot:extraButtons>
        <TranslateManyContentButton v-if="!isPreview" :project="project" :language="language" class="ml-3" :object="active" />
      </template>

      <template v-slot:name>
        <b-badge
          class="text-capitalize statuses"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          v-if="active.isComplete"
          >C</b-badge
        >
        <b-badge
          class="text-capitalize statuses"
          variant="warning"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          v-if="!active.isComplete"
          >I</b-badge
        >
        {{ active.name }}
      </template>

      <template v-slot:content>
        <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Name</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.name" v-model="active.form.name" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled style="flex:1;" class="m-1" :value="active.name"></b-textarea>
          <b-textarea style="flex:1;" class="m-1" v-model="active.form.name" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.name || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>
        <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Content</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.content" v-model="active.form.content" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled style="flex:1;" class="m-1" :value="active.content"></b-textarea>
          <b-textarea style="flex:1;" class="m-1" v-model="active.form.content" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.content || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>
      </template>

      <template v-slot:saveBtns>
        <b-button-group>
          <b-button @click="save(0)" variant="warning" class="mr-1" size="sm">Save as incomplete</b-button>
          <b-button @click="save(1)" size="sm">Save as Complete</b-button>
        </b-button-group>
      </template>

    </TranslationModalWrapper>

  </b-container>
  <Spinner v-else />
</template>
<script>
import Spinner from '../Spinner'
import { TranslationsHelper, TranslationType, TranslationModes } from '../../utils/translation'
import TranslationModalWrapper from './TranslationModalWrapper.vue'
import TranslationsStickyTools from './TranslationsStickyTools.vue'
import TranslateContentButton from './TranslateContentButton.vue'
import { EVENT } from '../../utils/event-bus'
import TranslateManyContentButton from './TranslateManyContentButton.vue'


export default {
  name: 'LanguagesTranslationsAgreements',
  components: {
    Spinner,
    TranslationModalWrapper,
    TranslationsStickyTools,
    TranslateContentButton,
    TranslateManyContentButton
  },
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    },
    uiOptions: {
      type: Object,
      default() {
        return {
          showFilters: true,
          showLanguageName: false
        }
      }
    }
  },
  data() {
    return {
      helper: null,
      agreements: [],
      loading: true,
      active: {form:{}},
      activeIndex: -1,
      perPage: 15,
      page: 1,
      total: 0,
      mode: TranslationModes.PREVIEW,
      searchText: ''
    }
  },
  async created() {
    this.helper = TranslationsHelper.Factory(this.project.id, this.language.id, TranslationType.agreement)
    this.searchText = this.helper.searchText

    await this.fetchPage()
  },
  methods: {
    async fetchContent() {
      this.helper.setPagination({page: this.page, perPage: this.perPage})

      await this.helper.fetchContent()

      this.agreements = this.helper.things
      this.total = this.helper.total
    },
    async fetchTranslations() {
      await this.helper.fetchTranslations()

      this.agreements.forEach(agreement => {
        const {id} = agreement

        if (this.helper.translations[id]) {
          agreement.applyTranslation(this.helper.translations[id])
        }
      })
    },
    async save(complete) {
      const { error } = await this.active.save(complete)

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save! Please try again.'
        })
      }

      EVENT.alert({
        variant: 'success',
        message: 'Saved'
      })
    },
    preview(content) {
      return content
        ? content.length > 99 ? content.slice(0, 97) + '...' : content
        : ''
    },
    setActive(id) {
      const activeIndex = this.agreements.map(agreement => agreement.id).indexOf(id)

      if (activeIndex < 0 || activeIndex >= this.agreements.length) return {}

      this.activeIndex = activeIndex
      this.active = this.agreements[activeIndex]
      this.$bvModal.show(this.modalId)
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
      this.active = {form:{}}
    },
    previous() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
      }

      this.active = this.agreements[this.activeIndex]
    },
    next() {
      if (this.activeIndex < this.agreements.length - 1) {
        this.activeIndex = this.activeIndex + 1
      }

      this.active = this.agreements[this.activeIndex]
    },
    handleModalChanges(changes) {
      Object.keys(changes).forEach(key => {
        this[key] = changes[key]
      })
    },
    async fetchPage() {
      this.loading = true
      this.helper.setPagination({page: this.page, perPage: this.perPage})

      await this.fetchContent()
      await this.fetchTranslations()

      this.loading = false
    },
    async search() {
      this.helper.setFilters({searchText: this.searchText})

      await this.fetchPage()
    },
    async filter(status) {
      this.helper.setFilters({status})

      await this.fetchPage()
    },
    async changePage(page) {
      this.page = page
      await this.fetchPage()
    }
  },
  computed: {
    isEdit() {
      return this.mode === 'edit'
    },
    hasPrevious() {
      return this.activeIndex > 0
    },
    hasNext() {
      return this.activeIndex < this.agreements.length - 1
    },
    showPagination() {
      return this.total > this.perPage
    },
    modalId() {
      return `edit-modal-${this.language.id}`
    },
    isPreview() {
      return this.mode === 'preview'
    },
    showFilters() {
      return this.uiOptions.showFilters
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview-box {
    flex: 1; 
    border-left: 1px solid #ccc;
    margin: 5px 10px;
    padding: 5px 10px;max-width: 48%;
  }
  @media only screen and (max-width: 768px) {
    .preview-box {
      max-width: 100%;
    }
  }
</style>
