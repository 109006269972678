<template>
  <div v-if="!loading">
    <b-row class="pb-2">
      <!-- <b-col><small>Not started:</small>
        <b-button class="progressBtn notStarted" variant="danger">{{ progress.notStarted }}</b-button>
      </b-col> -->
      <!-- <b-col><small>In progress: </small>
        <b-button class="progressBtn inProgress" variant="warning">{{ progress.inProgress }}</b-button></b-col> -->
      <b-col><small>Incomplete: </small>
        <b-button class="progressBtn needsReview" variant="warning">{{ progress.notComplete }}</b-button></b-col>
      <b-col><small>Complete: </small>
        <b-button class="progressBtn complete" variant="success">{{ progress.complete }}</b-button></b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <b-progress :value="progress.percentComplete" :min="0" :max="100" show-progress variant="primary-light"></b-progress>
        <small>{{ progress.percentCompleteFriendly() }} complete ({{ progress.complete }} of {{ progress.total }})</small>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>
<script>
import { LanguageProgress } from '../../utils/language'
import Spinner from '../Spinner'

export default {
  name: 'LanguagesProgress',
  components: {Spinner},
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    }
  },
  data() {
    return {
      progress: null,
      loading: true
    }
  },
  async created() {
    this.progress = await LanguageProgress.FetchByLanguage(this.project.id, this.language.id)
    this.loading = false
  },
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
  .progressBtn {
    text-align: center;
    padding: 3px 10px;
    font-size: 1.25em;
    line-height: normal;
    width: 100%;
    height: auto;
    border:none;
  }
</style>
