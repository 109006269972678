<template>
  <div>
    <b-modal id="padding" title="Padding options" size="sm" cancel-disabled>
      <b-container>
        <b-row v-if="selectedSegment && selectedSegment._padding">
          <div>
            <b-input-group prepend="Character" class="mt-3">
              <b-input
                type="text"
                v-model="selectedSegment._padding.char"
                class="mr-2"
                placeholder="Character"
              />
            </b-input-group>

            <b-input-group prepend="Length" class="mt-3">
              <b-input
                type="number"
                v-model.number="selectedSegment._padding.length"
                class="mr-2"
                placeholder="Character"
              />
            </b-input-group>

            <b-input-group prepend="Direction" class="mt-3">
              <b-form-select
                v-model="selectedSegment._padding.left"
                :options="paddingDirection"
                class="mr-2"
              ></b-form-select>
            </b-input-group>

          </div>
        </b-row>
        <br>
        <b-row>
          <b-button
            @click="togglePadding(selectedSegment, selectedSegmentIndex)"
            variant="primary-light"
          >
            <span v-if="selectedSegment && selectedSegment._padding">Remove padding</span>
            <span v-else>Add padding</span>
          </b-button>
        </b-row>
      </b-container>

      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          v-b-toggle.schemeSection
          variant="primary"
          class="text-left"
          >Item Name Scheme</b-button
        >
      </b-card-header>
      <b-collapse
        id="schemeSection"
        role="tabpanel"
        :visible="sectionIsVisible('scheme')"
        accordion="project"
        @show="setCurrentSection('scheme')"
      >
        <b-card-body>
          <h4>Segments</h4>
          <b-row class="d-flex justify-content-between">
            <b-col>
              <b-row
                class="py-1 d-flex justify-content-between"
                v-for="(segment, index) in computedSegments"
                :key="index"
              >
                <b-col>
                  <b-input-group>
                    <b-form-select
                      @change="onSegmentChange(segment, $event)"
                      :value="segment.type"
                      :options="namingSegmentOptions"
                      class="mr-2"
                    ></b-form-select>
                    <b-input
                      type="text"
                      v-if="segment.type === 'text'"
                      v-model="segment.arg"
                      class="mr-2"
                      placeholder="Text"
                    />
                    <b-form-select
                      v-if="allowsArg(segment.type)"
                      v-model="segment.arg"
                      :options="customFieldOptions"
                      class="mr-2"
                    ></b-form-select>

                    <div
                      v-if="allowsPadding(segment.type)"
                      class="mr-2"
                    >
                    
                      <b-button
                        @click="showPaddingOptions(segment, index)"
                        variant="primary-light"
                      >
                        Padding options
                      </b-button>

                    </div>

                    <b-button-group>
                      <b-button
                        :disabled="index === 0 || shouldDisable"
                        @click="
                          moveArrayElement(
                            settingsFields.naming_scheme_segments,
                            index,
                            -1
                          )
                        "
                        size="sm"
                        variant="white"
                      >
                        <font-awesome-icon
                          icon="arrow-up"
                        ></font-awesome-icon>
                      </b-button>
                      <b-button
                        :disabled="
                          index ===
                            settingsFields.naming_scheme_segments
                              .length -
                              1
                        "
                        @click="
                          moveArrayElement(
                            settingsFields.naming_scheme_segments,
                            index,
                            1
                          )
                        "
                        size="sm"
                        variant="white"
                      >
                        <font-awesome-icon
                          icon="arrow-down"
                        ></font-awesome-icon>
                      </b-button>
                      <b-button
                        v-if="
                          settingsFields.naming_scheme_segments
                            .length === 1
                        "
                        disabled
                        size="sm"
                        variant="white"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                        ></font-awesome-icon>
                      </b-button>
                      <b-button
                        @click="deleteNamingSchemeSegment(index)"
                        size="sm"
                        variant="white"
                        v-else
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                        ></font-awesome-icon>
                      </b-button>
                    </b-button-group>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="py-1">
                <b-col>
                  <b-button
                    :disabled="shouldDisable"
                    class="mt-2"
                    variant="primary-light"
                    size="sm"
                    @click="addNamingSchemeSegment()"
                    >Add a segment</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-checkbox
            :disabled="shouldDisable"
            class="mt-3 mb-2"
            v-model="settingsFields.naming_scheme_advanced"
            >Use advanced editor</b-checkbox
          >
          <b-collapse
            :visible="settingsFields.naming_scheme_advanced"
          >
            <i
              >Enter in your own scheme or create one from the
              segments above</i
            >
            <b-row class="mt-2">
              <b-col class="mb-2" md="8">
                <b-input
                  placeholder="Enter scheme..."
                  v-model="settingsFields.naming_scheme_compiled"
                />
              </b-col>
              <b-col md="4">
                <b-button
                  variant="primary-light"
                  @click="createFromSegments"
                  >Create from segments</b-button
                >
              </b-col>
            </b-row>
          </b-collapse>
          <b-button
            :disabled="isSaving || shouldDisable"
            class="mt-3"
            variant="secondary"
            @click="preSubmit"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isSaving"
            ></b-spinner
            >&nbsp;Save
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>

  </div>
</template>

<script>
  // import { deepCopy } from '../../utils/misc.js'

  // import get from 'lodash.get'


  const NAMING_SEGMENT_OPTIONS = [
    { value: 'text', text: 'Text' },
    { value: 'project_name', text: 'Project Name' },
    { value: 'type', text: 'Item Type' },
    { value: 'item_count', text: 'Item Count' },
    { value: 'custom_field_value', text: 'Custom Field Value' },
    { value: 'custom_field_preview', text: 'Custom Field Preview' },
    { value: 'initials', text: 'Custom Field Initials' }
  ]

  function processPadding(paddingOptions, fnToPad) {
    const { char, length, left } = paddingOptions

    if (!left) {
      return `{{ pad(${fnToPad}, "${char}", ${length}, 0) }}`
    }

    return `{{ pad(${fnToPad}, "${char}", ${length}) }}`
  }

  function convertToJinja(namingScheme) {
    const result = []

    for (const segment of namingScheme) {
      switch (segment.type) {
        case 'type': {
          result.push(`{{ type }}`)
          break
        }

        case 'initials': {
          result.push(`{{ initials("${segment.arg}") }}`)
          break
        }

        case 'item_count': {
          const itemCountFn = `item_count("${segment.arg}")`

          if (segment._padding) {
            result.push(processPadding(segment._padding, itemCountFn))
          } else {
            result.push(`{{ ${itemCountFn} }}`)
          }

          break
        }

        case 'custom_field_value': {
          result.push(`{{ custom_field_value("${segment.arg}") }}`)
          break
        }

        case 'custom_field_preview': {
          result.push(`{{ custom_field_preview("${segment.arg}") }}`)
          break
        }

        case 'project_name': {
          result.push(`{{ project_name }}`)
          break
        }

        default: {
          result.push(segment.arg)
        }

      }
    }

    return result.join('')
  }


  export default {
    name: 'AutoNaming',
    async created() {
      this.settingsFields = {
        naming_scheme_segments: this.project.settings.naming_scheme_segments || [],
        naming_scheme_compiled: this.project.settings.naming_scheme_compiled,
        naming_scheme_advanced: this.project.settings.naming_scheme_advanced || false,
      }
    },
    props: {
      project: {
        type: Object
      },
      sectionIsVisible: {
        type: Function,
        default: () => {}
      },
      setCurrentSection: {
        type: Function,
        default: () => {}
      },
      customFieldOptions: {
        type: Array
      },
      isSaving: {
        type: Boolean
      },
      autoNamingChanged: {
        type: Function,
        default: () => {}
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        namingSegmentOptions: NAMING_SEGMENT_OPTIONS,
        namingScheme: [],
        settingsFields: {},
        paddingOptions: [
          { value: null, text: 'No padding' },
          { value: true, text: 'Padding' }
        ],
        paddingDirection: [
          { value: true, text: 'Left' },
          { value: false, text: 'Right' }
        ],
        defaultPaddingParams: {
          char: '0',
          length: 3,
          left: true
        },
        selectedSegment: null
      }
    },
    methods: {
      deleteNamingSchemeSegment(index) {
        this.settingsFields.naming_scheme_segments.splice(index, 1)
      },
      addNamingSchemeSegment() {
        this.settingsFields.naming_scheme_segments.push({
          arg: '',
          type: 'text'
        })
      },
      allowsArg(type) {
        return (
          type &&
          [
            'item_count',
            'initials',
            'custom_field_value',
            'custom_field_preview'
          ].includes(type)
        )
      },
      allowsPadding(type) {
        return (
          type &&
          [
            'item_count',
          ].includes(type)
        )
      },
      onSegmentChange(segment, value) {
        segment.type = value
        segment.arg = ''
      },
      createFromSegments() {
        this.settingsFields.naming_scheme_compiled = convertToJinja(
          this.settingsFields.naming_scheme_segments
        )
      },
      preSubmit() {
        if (!this.settingsFields.naming_scheme_advanced) {
          this.settingsFields.naming_scheme_compiled = convertToJinja(
            this.settingsFields.naming_scheme_segments
          )
        }

        this.autoNamingChanged(this.settingsFields)
        this.submit()
      },
      submit() {
        const form = document.getElementById('settings-form')
        form.dispatchEvent(new Event('submit', { cancelable: true }))
      },
      showPaddingOptions(segment, index) {
        this.selectedSegment = segment
        this.selectedSegmentIndex = index
        this.$bvModal.show('padding')
      },
      togglePadding(segment, index) {
        if (segment._padding) {
          delete segment._padding
        } else {
          segment._padding = { ...this.defaultPaddingParams }
        }

        this.settingsFields.naming_scheme_segments.splice(index, 1, segment)
      },
      moveArrayElement(array, index, by) {
        const newIndex = index + by
        const element = array.splice(index, 1)[0]
        array.splice(newIndex, 0, element)
      }
    },
    computed: {
      itemNamePreview() {
        const preview = []

        for (const segment of this.settingsFields.naming_scheme_segments) {
          switch (segment.type) {
            case 'initials':
              preview.push(`TI`)
              break
            case 'item_count':
              preview.push('45')
              break
            case 'type':
              preview.push('multiple choice')
              break
            default:
              preview.push(segment.arg)
          }
        }

        return preview.join('')
      },
      computedSegments() {
        return this.settingsFields.naming_scheme_segments
      }
    }
  }
</script>

<style lang="scss" scoped>

  .preview-box {
    border: 1px solid #ccc; padding: 0 8px 8px; border-radius: 12px;
  }
  .top-button {
    padding: 4px 8px; 
    margin-right: 4px; 
    color: rgb(255, 255, 255); 
    border: 1px solid rgba(255, 255, 255, 0.281);
  }
  .page {
    color: white; 
    width: 50px; 
    display:inline-block;
    text-align:center; 
    margin-right: 2px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: white;
  }
  .visited {
    color: white; 
  }
  .current {
    border-color: white;
  }
  .flag {
    color: black;
  }
  .navigation {
    padding: 4px; overflow:auto; border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .button-row {
    padding: 4px;
  }
  .content-area {
    padding: 8px;
  }
  .supplemental-info {
    margin: 25px; color: #fff;padding: 4px 8px;
  }
  .item-box {
    background: white; margin: 25px; color: #fff;padding: 18px; color: black; border-radius: 6px;
  }
  .bottom-buttons {
    margin: 25px; color: #fff; overflow: auto;
  }
  .button {
    padding: 8px 16px; color: white; width: 100px; text-align:center; margin-right: 1px; line-height: .95em; float: left;
  }
  .right {
    float:right;
  }

  #picker {
    position: absolute;
    z-index: 9999999;
    right: 8px;
    bottom: 44px;
    .swatch {
      max-width: 50px;
      height: 50px;
      display: inline-block;
    }
  }
</style>
