const { CONFIGURATION_VALUES } = require('./constants')

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function friendlyName(value) {
  if (!value) return ''

  value = value.replace(/_/g, ' ')

  return value.charAt(0).toUpperCase() + value.slice(1)
}

function notificationVariant(event) {
  let variant

  switch (event) {
    case 'delivery_created':
      variant = 'fresh'
      break
    case 'delivery_started':
      variant = 'in-progress'
      break
    case 'delivery_submitted':
      variant = 'submitted'
      break
    case 'delivery_rescored':
      variant = 'rescored'
      break
    case 'delivery_completed':
      variant = 'complete'
      break
    case 'delivery_suspended':
      variant = 'suspended'
      break
  }

  return variant
}

function splitLabel(label) {
  const labels = []
  let length = 0
  let line = ''

  const words = label.split(' ')

  for (const word of words) {
    line += ` ${word}`
    length += word.length

    if (length >= 30) {
      labels.push(line)
      length = 0
      line = ''
    }
  }

  return [...labels, line]
}

function iframeUrl(url, jwt, projectId, itemId, deliveryId) {
  const delimeter = url.indexOf('?') > -1 ? '&' : '?'
  const itemIdParam = itemId ? `&item_id=${itemId}` : ''
  const deliveryIdParam = deliveryId ? `&delivery_id=${deliveryId}` : ''
  return `${url}${delimeter}jwt=${jwt}&exam_id=${projectId}${itemIdParam}${deliveryIdParam}`
}

function defaultNumberInputToZero(value, field, property) {
  if (isNaN(value)) {
    value = 0
  } else {
    value = Number(value)
  }

  field[property] = value
}

function defaultNumberInputToNull(value, field, property) {
  if (!value || isNaN(value)) {
    value = null
  } else {
    value = Number(value)
  }

  field[property] = value
}

function defaultTextInputToNull(value, field, property) {
  field[property] = value ? value : null
}

function removeEmptyKeys(object) {
  for (const [key, value] of Object.entries(object)) {
    let isEmptyArray = false
    if (Array.isArray(value)) {
      isEmptyArray = !value.length
    }

    let isEmptyObject = false
    if (typeof value === 'object' && value !== null) {
      isEmptyObject = !Object.keys(value).length
    }

    if (value === null || isEmptyArray || isEmptyObject) {
      delete object[key]
    }
  }
}

function setUnion(setA, setB) {
  const union = new Set(setA)

  for (const value of setB) {
    union.add(value)
  }

  return union
}

function setIntersection(setA, setB) {
  const intersection = new Set()

  for (const value of setB) {
    if (setA.has(value)) intersection.add(value)
  }

  return intersection
}

function objectToQueryString(input) {
  if (!input) return ''

  const params = []

  for (let [key, value] of Object.entries(input)) {
    if (Array.isArray(value)) {
      value = value.join(',')
    }

    params.push(`${key}=${value}`)
  }

  return '?' + params.join('&')
}

function arrayEquality (arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) return false

  for (let i = 0; i < arrayA.length; i++) {
    if (arrayA[i] !== arrayB[i]) return false
  }

  return true
}

function setMissingKeysOnParentConfiguration (configuration) {
  const defaultSettings = deepCopy(CONFIGURATION_VALUES)

  for (const [key, value] of Object.entries(defaultSettings)) {
    if (!(key in configuration.settings)) {
      configuration.settings[key] = value
    }
  }

  for (const [key, value] of Object.entries(defaultSettings.security_options)) {
    if (!(key in configuration.settings.security_options)) {
      configuration.settings.security_options[key] = value
    }
  }
}

function formatItemsForPreview(items = [], customFields) {
  if (!customFields) {
    return items.map(item => {
      item.stem = item.preview_fields.stem
      return item
    })
  }

  const formattedItems = []

  for (const item of items) {
    const formattedItem = { ...item }

    formattedItem.stem = formattedItem.preview_fields.stem

    for (const previewFieldId in formattedItem.preview_fields) {
      const customField = customFields[previewFieldId]
      const previewFieldValue =
        formattedItem.preview_fields[previewFieldId]

      if (!customField || (!previewFieldValue && previewFieldValue !== 0)) {
        continue
      }

      if (customField.options) {
        if (typeof previewFieldValue === 'string') {
          if (customField.options[previewFieldValue]) {
            const { color, preview_text, text } = customField.options[
              previewFieldValue
            ]

            formattedItem[previewFieldId] = [
              {
                value: preview_text || text,
                tooltip: text,
                color
              }
            ]
          }
        } else {
          const options = []
          for (const value of previewFieldValue) {
            if (customField.options[value]) {
              const { color, preview_text, text } = customField.options[
                value
              ]
              const option = {
                value: preview_text || text,
                tooltip: text,
                color
              }
              options.push(option)
            }
          }
          formattedItem[previewFieldId] = options
        }
      } else {
        formattedItem[previewFieldId] = previewFieldValue
      }
    }
    formattedItems.push(formattedItem)
  }
  return formattedItems
}

module.exports = {
  deepCopy,
  friendlyName,
  notificationVariant,
  splitLabel,
  iframeUrl,
  defaultNumberInputToZero,
  defaultNumberInputToNull,
  defaultTextInputToNull,
  removeEmptyKeys,
  setUnion,
  setIntersection,
  objectToQueryString,
  arrayEquality,
  setMissingKeysOnParentConfiguration,
  formatItemsForPreview
}
