var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[(!_vm.error)?_c('div',[(!_vm.isLoadingRoles)?_c('div',_vm._l((_vm.roles),function(role){return _c('div',{key:role.id},[_c('AccessRole',{attrs:{"expand":_vm.expand,"role":role,"project":_vm.project},on:{"open-add-role":_vm.openAddRole,"edit-role":_vm.editRole,"remove-role":_vm.removeRole}})],1)}),0):_c('div',[_c('b-spinner',{attrs:{"label":"Small Spinner","small":""}})],1)]):_c('b-container',{staticClass:"mt-3",attrs:{"fluid":""}},[_c('b-alert',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"show":"","variant":"danger"}},[_vm._v(" Failed to load roles. "),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.getRoles}},[_vm._v("Reload")])],1)],1),_c('b-modal',{attrs:{"title":_vm.addRoleModalTitle,"id":"add-role-modal","size":"xl"},on:{"hidden":_vm.hideAddRoleModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button-group',[_c('b-button',{attrs:{"variant":"white"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"disabled":_vm.disableAddRoleBtn,"type":"submit","form":"add-role-form","variant":"secondary"}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAddingRole),expression:"isAddingRole"}],attrs:{"label":"Small Spinner","small":""}}),_vm._v(" Save ")],1)],1)]}}],null,false,3739104494)},[_c('b-alert',{attrs:{"show":_vm.editingRole.invalid,"variant":"danger"}},[_vm._v(" This role has invalid restrictions. Please update! ")]),_c('b-form',{attrs:{"novalidate":"","id":"add-role-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitRole($event)}}},[_c('b-form-group',{attrs:{"label":_vm.editMode
              ? 'User:'
              : 'Enter an email or select a user/group from your organization *'}},[_c('RolesInput',{attrs:{"options":_vm.filteredRoleOptions,"isDisabled":_vm.editMode,"editRole":_vm.editingRole},on:{"roles-changed":_vm.rolesChanged}})],1),(_vm.restrictionsAllowed(_vm.type))?_c('b-form-group',{attrs:{"label":"Restrictions:","label-cols-md":"4"}},[_vm._l((_vm.metaRestrictions),function(restriction,index){return _c('div',{key:index},[_c('b-select',{staticClass:"mb-2",attrs:{"options":_vm.roleCustomFieldOptions},model:{value:(restriction.fieldId),callback:function ($$v) {_vm.$set(restriction, "fieldId", $$v)},expression:"restriction.fieldId"}}),(restriction.fieldId)?_c('div',[(
                  ['text', 'number'].includes(
                    _vm.metaHelper.getFieldType(restriction.fieldId)
                  )
                )?_c('v-select',{staticClass:"mb-4",staticStyle:{"backgroundColor":"white"},attrs:{"disabled":!_vm.isPremium,"taggable":"","multiple":"","label":"text","placeholder":"Not selected"},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                var option = ref.option;
                var deselect = ref.deselect;
return [_c('span',{class:("vs__selected border " + (option.color ? 'pl-0' : ''))},[(option.color)?_c('div',{staticClass:"mr-1",style:({
                        backgroundColor: option.color,
                        width: '15px',
                        height: '100%',
                        borderRadius: '3px 0 0 3px'
                      })}):_vm._e(),_vm._v(" "+_vm._s(option.text)+" "),_c('button',{staticClass:"vs__deselect no-focus",style:({ color: 'rgba(60, 60, 60, 0.5)' }),attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return deselect(option)},"mousedown":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]}},{key:"option",fn:function(ref){
                      var color = ref.color;
                      var text = ref.text;
return [(color)?_c('div',{staticClass:"option p-1 d-inline-block",style:({ background: color })}):_vm._e(),_vm._v(" "+_vm._s(text)+" ")]}}],null,true),model:{value:(restriction[restriction.fieldId]),callback:function ($$v) {_vm.$set(restriction, restriction.fieldId, $$v)},expression:"restriction[restriction.fieldId]"}}):_vm._e(),(
                  ['select_one', 'select_multi'].includes(
                    _vm.metaHelper.getFieldType(restriction.fieldId)
                  )
                )?_c('v-select',{staticClass:"mb-4",staticStyle:{"backgroundColor":"white"},attrs:{"disabled":!_vm.isPremium,"options":_vm.metaHelper.getFieldOptions(restriction.fieldId),"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.disabled; },"multiple":"","label":"text","placeholder":"Not selected"},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                var option = ref.option;
                var deselect = ref.deselect;
return [_c('span',{class:("vs__selected border " + (option.color ? 'pl-0' : ''))},[(option.color)?_c('div',{staticClass:"mr-1",style:({
                        backgroundColor: option.color,
                        width: '15px',
                        height: '100%',
                        borderRadius: '3px 0 0 3px'
                      })}):_vm._e(),_vm._v(" "+_vm._s(option.text)+" "),_c('button',{staticClass:"vs__deselect no-focus",style:({ color: 'rgba(60, 60, 60, 0.5)' }),attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return deselect(option)},"mousedown":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]}},{key:"option",fn:function(ref){
                      var color = ref.color;
                      var text = ref.text;
return [(color)?_c('div',{staticClass:"option p-1 d-inline-block",style:({ background: color })}):_vm._e(),_vm._v(" "+_vm._s(text)+" ")]}}],null,true),model:{value:(restriction[restriction.fieldId]),callback:function ($$v) {_vm.$set(restriction, restriction.fieldId, $$v)},expression:"restriction[restriction.fieldId]"}}):_vm._e()],1):_vm._e()],1)}),_c('b-button',{attrs:{"variant":"primary-light","size":"sm"},on:{"click":function($event){return _vm.metaRestrictions.push({ fieldId: '' })}}},[_vm._v("Add restriction")])],2):_vm._e(),(_vm.tagRestrictionsAllowed(_vm.type))?_c('b-form-group',{attrs:{"label":"Tag Restrictions:","label-cols-md":"4"}},[_c('b-tags',{attrs:{"placeholder":"Type a new tag and press enter"},model:{value:(_vm.tagRestrictions),callback:function ($$v) {_vm.tagRestrictions=$$v},expression:"tagRestrictions"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Expire access (your local date/time):","label-cols-sm":"4","label-for":"datepicker-date"}},[_c('b-row',[_c('b-col',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"datepicker-date","size":"sm"},model:{value:(_vm.editingRole._expDate),callback:function ($$v) {_vm.$set(_vm.editingRole, "_expDate", $$v)},expression:"editingRole._expDate"}}),_c('b-form-timepicker',{staticClass:"mb-2",attrs:{"size":"sm","locale":"en"},model:{value:(_vm.editingRole._expTime),callback:function ($$v) {_vm.$set(_vm.editingRole, "_expTime", $$v)},expression:"editingRole._expTime"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{attrs:{"size":"sm","variant":"white"},on:{"click":_vm.clearDateTime}},[_vm._v("Clear")])],1)],1)],1)],1)],1)],1):_c('Spinner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }