<template>
  <b-container class="mt-3" fluid>
    <b-form>
      <div :key="widget" v-for="widget in activeWidgets">
        <ContentWidget
          :invalidInputs="invalidInputs"
          :name="widget"
          :widgets="fields"
          @remove-widget="removeWidget"
          @validate-input="validateInput"
        />
      </div>
      <b-button class="mt-2" variant="white" @click="openModal"
        >Add a Widget</b-button
      >
      <b-modal
        size="xl"
        title="Add a Widget"
        id="add-widget-modal"
        @ok="addSelectedWidget"
      >
        <div>
          <label>Type</label>
          <b-select :options="validOptions" v-model="selected"></b-select>
        </div>
        <div class="mt-2">
          <label>URL</label>
          <b-input placeholder="Enter URL..." v-model="url"></b-input>
        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button @click="ok" :disabled="!selected">Add Widget</b-button>
        </template>
      </b-modal>
    </b-form>
  </b-container>
</template>

<script>
import { deepCopy } from '../../utils/misc.js'
import { URL_REGEX } from '../../utils/constants'

import ContentWidget from './ContentWidget'

export default {
  name: 'Content',
  components: {
    ContentWidget
  },
  created() {
    this.setFields()
    this.setActiveWidgets()
    this.formatIncomingData()
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: {},
      selected: '',
      url: '',
      invalidInputs: [],
      activeWidgets: [],
      options: [
        { value: '', text: 'Select', disabled: true },
        { value: 'delivery_widget', text: 'Delivery' },
        { value: 'export_widget', text: 'Export' },
        { value: 'item_widget', text: 'Item' },
        { value: 'take_widget', text: 'Take' },
        { value: 'full_page', text: 'Full Page App' }
      ]
    }
  },
  methods: {
    report() {
      const formattedFields = this.formatOutgoingData()

      const error = Boolean(this.invalidInputs.length)

      this.$emit('report', formattedFields, 'content', error)
    },
    setFields() {
      const {
        app_settings: { content }
      } = this.app
      this.fields = deepCopy(content)
    },
    setActiveWidgets() {
      this.activeWidgets = Object.keys(this.fields)
    },
    openModal() {
      this.selected = ''
      this.url = ''
      this.$bvModal.show('add-widget-modal')
    },
    addSelectedWidget() {
      if (this.selected === 'take_widget') {
        this.fields[this.selected] = {
          url: this.url,
          windowed: false,
          icon: null
        }
      } else {
        this.fields[this.selected] = this.url
      }

      this.activeWidgets.push(this.selected)
      this.validateInput(this.selected, this.url)
    },
    removeWidget(name) {
      const index = this.activeWidgets.indexOf(name)

      if (name == 'take_widget') {
        delete this.fields.take_widget
        this.activeWidgets.splice(index, 1)
      } else {
        this.activeWidgets.splice(index, 1)
        delete this.fields[name]
      }

      const invalidIndex = this.invalidInputs.indexOf(name)

      if (invalidIndex > -1) {
        this.invalidInputs.splice(invalidIndex, 1)
      }
    },
    validateInput(name, url) {
      const index = this.invalidInputs.indexOf(name)

      if (!URL_REGEX.test(url)) {
        if (index < 0) {
          this.invalidInputs.push(name)
        }
      } else {
        if (index > -1) {
          this.invalidInputs.splice(index, 1)
        }
      }

      if (name == 'take_widget') {
        this.fields[name].url = url.replace('https://', '')
      } else {
        this.fields[name] = url.replace('https://', '')
      }
    },
    formatIncomingData() {
      for (const key of this.activeWidgets) {
        if (key === 'take_widget') {
          this.fields[key].url = this.fields[key].url.replace('https://', '')
        } else {
          this.fields[key] = this.fields[key].replace('https://', '')
        }
      }
    },
    formatOutgoingData() {
      const formattedFields = deepCopy(this.fields)

      for (const key of Object.keys(formattedFields)) {
        if (key === 'take_widget') {
          formattedFields[key].url = 'https://' + formattedFields[key].url
        } else {
          formattedFields[key] = 'https://' + formattedFields[key]
        }
      }

      return formattedFields
    }
  },
  computed: {
    validOptions() {
      return this.options.filter((option) => {
        return !this.activeWidgets.includes(option.value)
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
