<template>
  <div>
    <TopNavigation :user="user" v-if="!isApp" />
    <b-navbar sticky variant="light">
      <b-button-group>
        <b-button v-b-modal.create-project-modal variant="secondary"
          >New Project</b-button
        >
        <ApiCredentials v-if="isApp" :app-id="user.caveon_id" class="ml-2" />
      </b-button-group>
      <b-form-input
        placeholder="Filter Projects"
        class="ml-3"
        style="maxWidth: 250px"
        v-model="searchText"
      />
      <b-checkbox :checked="showArchived" @change="includeArchived" class="ml-2"
        >Include Archived</b-checkbox
      >
    </b-navbar>

    <b-modal
      @hidden="exitCreateForm"
      id="create-project-modal"
      ref="create-project-modal"
      size="xl"
      title="Create Project"
    >
      <b-form @submit.prevent="onSubmit" id="create-project-form" novalidate>
        <b-form-group label="Name *" label-for="name-input">
          <b-form-input
            id="name-input"
            placeholder="Project Name"
            required
            type="text"
            v-model="createForm.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Display Name" label-for="display-name-input">
          <b-form-input
            id="display-name-input"
            placeholder="Name to display to candidates. Defaults to project name if not set."
            type="text"
            v-model="createForm.display_name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Organization" label-for="organization-input">
          <b-form-select
            :options="orgOptions"
            id="organization-input"
            v-model="createForm.organization"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Description" label-for="description-input">
          <textarea-autosize
            :min-height="37"
            class="form-control"
            id="description-input"
            placeholder="Project Description"
            rows="1"
            v-model="createForm.description"
          />
        </b-form-group>
        <b-form-group label="Status" label-for="status-input">
          <b-form-select
            :options="statusOptions"
            id="status-input"
            v-model="createForm.status"
          ></b-form-select>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="createForm.use_template"
            >Use an existing project as a template for this
            project.</b-form-checkbox
          >
        </b-form-group>
      </b-form>

      <ProjectTemplate v-if="createForm.use_template" :overrideProjects="projects" @close-create-modal="closeCreateModal" :createForm="createForm" />

      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            v-if="!createForm.use_template"
            :disabled="disableCreateProjectBtn"
            form="create-project-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isCreatingProject"
            ></b-spinner
            >&nbsp;Create
          </b-button>
        </b-button-group>
      </template>
    </b-modal>


    <b-container class="mt-3" fluid v-if="!loadingProjects">
      <div class="d-flex justify-content-between mb-2">
        <h3>Projects: {{ projectCount }}</h3>
        <div>
          <b-dropdown right text="Sort By" variant="white" size="sm">
            <b-dropdown-item @click="changeSort('name')">
              Name
              <font-awesome-icon
                icon="check"
                v-if="sortBy === 'name'"
              ></font-awesome-icon>
            </b-dropdown-item>
            <b-dropdown-item @click="changeSort('created_at')">
              Date Created
              <font-awesome-icon
                icon="check"
                v-if="sortBy === 'created_at'"
              ></font-awesome-icon>
            </b-dropdown-item>
            <b-dropdown-item @click="changeSort('organization.name')">
              Organization
              <font-awesome-icon
                icon="check"
                v-if="sortBy === 'organization.name'"
              ></font-awesome-icon>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown right class="ml-1" text="Order" variant="white" size="sm">
            <b-dropdown-item @click="changeOrder('asc')">
              Ascending
              <font-awesome-icon
                icon="check"
                v-if="sortOrder === 'asc'"
              ></font-awesome-icon>
            </b-dropdown-item>
            <b-dropdown-item @click="changeOrder('desc')">
              Descending
              <font-awesome-icon
                icon="check"
                v-if="sortOrder === 'desc'"
              ></font-awesome-icon>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div v-if="!error">
        <b-table
          :fields="fields"
          :items="filteredProjects()"
          @row-clicked="rowClicked"
          bordered
          hover
          id="projects"
          responsive
          small
          striped
          v-if="projects.length"
        >
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: field.key === 'starred' ? '40px' : 'auto' }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:cell(starred)="data">
            <div class="text-center" data-star style="pointerEvents: none">
              <div style="pointerEvents: none;">
                <font-awesome-icon
                  class="text-secondary"
                  icon="star"
                  v-if="data.item.starred"
                ></font-awesome-icon>
                <font-awesome-icon
                  :icon="['far', 'star']"
                  v-else
                ></font-awesome-icon>
              </div>
            </div>
          </template>
          <template v-slot:cell(name)="row">
            {{ row.item.name }}
            <span
              class="ml-2"
              title="Archived"
              v-b-tooltip.hover
              v-if="archivedIcon(row.item)"
            >
              <font-awesome-icon icon="archive"></font-awesome-icon>
            </span>
          </template>
        </b-table>
        <div class="p-2" v-else>No Projects.</div>
      </div>
      <b-alert
        class="d-flex justify-content-between align-items-center"
        show
        v-else
        variant="danger"
      >
        Failed to load projects.
        <b-button @click="reload" variant="danger">Reload</b-button>
      </b-alert>
    </b-container>
    <Spinner v-else />
  </div>
</template>

<script>
  import { HTTP } from '../utils/requests'
  import { LOCAL } from '../utils/local'
  import { SEI_API_BASE } from '../utils/constants'
  import { STATUS_OPTIONS } from '../utils/constants'
  import { EVENT } from '../utils/event-bus'
  import { VALIDATE } from './../utils/validate'

  import get from 'lodash.get'

  import ApiCredentials from './app/ApiCredentials.vue'
  import TopNavigation from './TopNavigation'
  import Spinner from './Spinner'
  import ProjectTemplate from './ProjectTemplate'


  const GET_CLEAN_PROJECT_FORM = () => {
    return {
      name: '',
      organization: '',
      identifier: '',
      description: '',
      status: 'active',
      use_template: false,
      template: null
    }
  }

  async function getOrganizationsData(page) {
    try {
      const url = `${SEI_API_BASE}/organizations?page=${page}&only=id,name,my_organization_permissions&include=my_organization_permissions`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveProject(data) {
    try {
      const url = `${SEI_API_BASE}/exams/?only=id,name,organization,meta,created_at`
      const response = await HTTP.post(url, data)
      const newProject = response.data
      return { newProject }
    } catch (error) {
      return { error }
    }
  }

  async function getListOfProjects(page, showArchived, appCaveonId) {
    let query = ''

    if (!showArchived) {
      query += '&meta=scorpion.status=active'
    }

    if (appCaveonId) {
      query += `&app_caveon_id=${ appCaveonId }`
    }

    try {
      const url = `${SEI_API_BASE}/exams?only=id,name,organization,meta,created_at&page=${page}${query}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getAppAliasToken(caveon_id, exam_id) {
    try {
      const url = `${SEI_API_BASE}/apps/${caveon_id}/app_alias_token/${exam_id}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  function sortProjects(projects, property, direction) {
    const starred = []
    const unstarred = []

    const sortedProjects = projects.sort((current, next) => {
      const currentValue = get(current, property, '')
      const nextValue = get(next, property, '')

      if (direction === 'asc') {
        return currentValue.localeCompare(nextValue)
      }

      return nextValue.localeCompare(currentValue)
    })

    for (const project of sortedProjects) {
      if (project.starred) {
        starred.push(project)
      } else {
        unstarred.push(project)
      }
    }

    return [...starred, ...unstarred]
  }

  export default {
    name: 'ProjectsPage',
    components: {
      ApiCredentials,
      TopNavigation,
      Spinner,
      ProjectTemplate
    },
    data() {
      return {
        projects: [],
        groups: [],
        organizations: {},
        orgOptions: [{ value: '', text: 'Select', disabled: true }],
        error: false,
        searchText: '',
        expand: true,
        fields: [
          { key: 'starred', label: '' },
          { key: 'name' },
          {
            key: 'organization',
            formatter: org => (org ? org.name : '')
          },
          {
            key: 'created_at',
            label: 'Date Created',
            formatter: date =>
              this.$moment
                .utc(date)
                .local()
                .format('L')
          }
        ],
        tempProject: null,
        loadingProjects: true,
        loadingOrganizations: true,
        createForm: GET_CLEAN_PROJECT_FORM(),
        statusOptions: STATUS_OPTIONS,
        isCreatingProject: false,
        showArchived: false,
        sortBy: 'name',
        sortOrder: 'asc',
        projectCount: 0
      }
    },
    created() {
      this.showArchived = LOCAL.projectListing.get()
      this.getProjects()
      this.getOrganizations()
    },
    props: {
      user: {
        type: Object
      },
      isApp: {
        type: Boolean
      }
    },
    methods: {
      async getProjects() {
        this.loadingProjects = true

        let moreProjects = true
        let page = 1
        let appCaveonId = null

        const projects = []

        if (this.isApp) {
          appCaveonId = this.user.caveon_id
        }

        while (moreProjects) {
          const { data, error } = await getListOfProjects(
            page,
            this.showArchived,
            appCaveonId
          )

          if (error) {
            this.loadingProjects = false
            return (this.error = true)
          }

          const starred = this.getStars(data.results)
          projects.push(...starred)
          moreProjects = data.has_next
          page++
        }
        this.projects = sortProjects(projects, this.sortBy, this.sortOrder)
        this.error = false
        this.loadingProjects = false
      },
      async getOrganizations() {
        const organizations = []

        let page = 1
        let hasMore = true

        while (hasMore) {
          const { data, error } = await getOrganizationsData(page)
          if (error) {
            this.loadingOrganizations = false
            this.error = true
            return
          }

          organizations.push(...data.results)
          hasMore = data.has_next
          page++
        }

        for (const org of organizations) {
          this.organizations[org.id] = org
          if (org.my_organization_permissions.includes('create_exams')) {
            this.orgOptions.push({ value: org.id, text: org.name })
          }
        }

        this.loadingOrganizations = false
        this.error = false
      },
      createGroups() {
        const groupBy = LOCAL.projectListing.get()
        const groupsList = []

        if (!groupBy || groupBy === 'active') {
          const groups = {
            active: {
              name: 'Active',
              data: []
            },
            archived: {
              name: 'Archived',
              data: []
            }
          }

          for (const project of this.projects) {
            const status = get(project, 'meta.scorpion.status')

            if (status === 'active') {
              groups.active.data.push(project)
            } else {
              groups.archived.data.push(project)
            }
          }

          groupsList.push(groups.active, groups.archived)
        } else if (groupBy === 'organization') {
          const groups = {}
          const noGroup = {
            name: 'No Organization',
            data: []
          }

          for (const project of this.projects) {
            const organization = this.organizations[project.organization_id]

            if (organization) {
              if (!groups[organization.id]) {
                groups[organization.id] = {
                  name: organization.name,
                  data: []
                }
              }

              groups[organization.id].data.push(project)
            } else {
              noGroup.data.push(project)
            }
          }

          for (const groupId in groups) {
            groupsList.push(groups[groupId])
          }

          groupsList.sort((current, next) =>
            current.name.localeCompare(next.name)
          )
          groupsList.push(noGroup)
        }

        this.groups = groupsList
      },
      async createProject() {
        if (this.use_template) {
          return
        }

        this.isCreatingProject = true
        if (!this.tempProject) {
          let data = {
            caveon_ids: [this.user.caveon_id],
            name: this.createForm.name,
            display_name: this.createForm.display_name,
            meta: {
              scorpion: {
                description: this.createForm.description,
                identifier: this.createForm.identifier,
                status: this.createForm.status
              }
            },
            create_default_form: true
          }
          if (this.createForm.organization) {
            data.organization_id = this.createForm.organization
          }

          const { newProject, error } = await saveProject(data)
          if (error) {
            EVENT.alert({
              variant: 'danger',
              message: 'Failed to create project.'
            })
            this.isCreatingProject = false
            return
          }

          this.tempProject = newProject
        }

        this.tempProject.starred = false
        if (!this.tempProject.meta.scorpion.status.length) {
          this.createForm.status = 'archived'
        }

        const newProjectId = this.tempProject.id
        const projects = [this.tempProject, ...this.projects]
        this.projects = sortProjects(projects, this.sortBy, this.sortOrder)
        this.exitCreateForm()
        this.isCreatingProject = false

        this.$router.push({
          name: 'project',
          params: { projectId: newProjectId }
        })
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.createProject()
        }
      },
      exitCreateForm() {
        this.$bvModal.hide('create-project-modal')
        this.createForm = GET_CLEAN_PROJECT_FORM()
        this.tempProject = null
      },
      getStars(projects) {
        const starred = LOCAL.star.get('projects')
        for (const project of projects) {
          project.starred = starred.includes(project.id)
        }
        return projects
      },
      setStars(project) {
        const starred = LOCAL.star.get('projects')
        project.starred = !project.starred
        if (project.starred) {
          starred.push(project.id)
        } else {
          starred.splice(starred.indexOf(project.id), 1)
        }
        LOCAL.star.set('projects', starred)
      },
      reload() {
        this.getProjects()
      },
      groupBy(option) {
        LOCAL.projectListing.set(option)
        this.createGroups()
      },
      archivedIcon(project) {
        const status = get(project, 'meta.scorpion.status')

        return status !== 'active'
      },
      includeArchived(value) {
        LOCAL.projectListing.set(value)
        this.showArchived = value
        this.getProjects()
      },
      async rowClicked(record) {
        const star = event.target.querySelector('[data-star]')
        if (star) {
          const project = this.projects.find(
            project => project.id === record.id
          )
          this.setStars(project)
          return (this.projects = sortProjects(
            this.projects,
            this.sortBy,
            this.sortOrder
          ))
        }
        if (this.isApp) {
          const { data, error } = await getAppAliasToken(this.user.caveon_id, record.id)
          if (error) {
            EVENT.alert({
              variant: 'danger',
              message: 'Failed to open project. Please try again.'
            })
            return
          }
          const routeData = this.$router.resolve({ name: 'project', params: { projectId: record.id }, query: { alias_token: data } })
          window.open(routeData.href, '_blank')
          return
        }
        this.$router.push({
          name: 'project',
          params: { projectId: record.id }
        })
      },
      changeSort(property) {
        this.sortBy = property
        this.projects = sortProjects(this.projects, this.sortBy, this.sortOrder)
      },
      changeOrder(order) {
        this.sortOrder = order
        this.projects = sortProjects(this.projects, this.sortBy, this.sortOrder)
      },
      filteredProjects() {
        const filtered = this.projects.filter(project => {
          return project.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        })
        this.projectCount = filtered.length
        return filtered
      },
      closeCreateModal() {
        this.$refs['create-project-modal'].hide()
      }
    },
    computed: {
      disableCreateProjectBtn() {
        return this.isCreatingProject
      }
    }
  }
</script>

<style lang="scss" scoped>
  .padded-border {
    border: 1px solid gainsboro;
    padding: 10px;
  }
</style>
