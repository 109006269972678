<template>
  <div>
    <b-modal title="Filter Available Items" size="sm" id="item-pool-filters-modal" no-fade @ok="applyFilters">
      <label>Search Items</label>
      <b-input class="mb-2" placeholder="Enter Value" v-model="search" />
      <label>Filter Items</label>
      <b-select
        :options="options"
        :value="filter"
        @change="onFilterChange"
        class="mb-2"
      />
      <div class="mb-2" v-if="filter">
        <b-input
          placeholder="Text"
          type="text"
          v-if="display(filter, ['text'])"
          v-model="value"
        />
        <b-input
          placeholder="Number"
          type="number"
          v-if="display(filter, ['number'])"
          v-model="value"
        />
        <b-select
          :options="subOptions.type"
          v-if="display(filter, ['type'])"
          v-model="value"
        />
        <v-select
          :multiple="getFieldType(filter) === 'select_multi'"
          :options="subOptions[filter]"
          :reduce="option => option.value"
          :selectable="option => !option.disabled"
          label="text"
          placeholder="Select"
          style="backgroundColor: white"
          v-if="display(filter, ['select_one', 'select_multi'])"
          v-model="value"
        >
          <template #selected-option-container="{ option, deselect }">
            <span :class="`vs__selected border ${option.color ? 'pl-0' : ''}`">
              <div
                :style="{
                  backgroundColor: option.color,
                  width: '15px',
                  height: '100%',
                  borderRadius: '3px 0 0 3px'
                }"
                class="mr-1"
                v-if="option.color"
              ></div>
              {{ option.text }}
              <button
                :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                @click.stop="deselect(option)"
                @mousedown.stop
                class="vs__deselect no-focus"
                type="button"
              >
                <font-awesome-icon icon="times"></font-awesome-icon>
              </button>
            </span>
          </template>
          <template #option="{ color, text }">
            <div
              :style="{ background: color }"
              class="option p-1 d-inline-block"
              v-if="color"
            ></div>
            {{ text }}
          </template>
        </v-select>
      </div>
      <b-checkbox
        v-model="hideUsedItems"
        class="mt-2"
      >
        Hide Used Items
      </b-checkbox>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <b-button variant="white" @click="clearFilters">Reset Filters</b-button>
          <div>
            <b-button variant="white" @click="cancel">Close</b-button>
            <b-button variant="primary" @click="ok" class="ml-2">Apply</b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-button variant="white" @click="openFiltersModal">Filter Available Items</b-button>
  </div>
</template>

<script>
  import { initMetaHelper } from '../../utils/meta-helper'
  import { SESSION } from '../../utils/session'

  const BASE_OPTIONS = [
    { text: 'Select', value: '', disabled: true },
    { text: 'Item Type', value: 'type' }
  ]

  const ITEM_TYPE_OPTIONS = [
    { text: 'Select', value: '', disabled: true },
    { text: 'Build List', value: 'build_list' },
    { text: 'DOMC', value: 'domc' },
    { text: 'Essay', value: 'essay' },
    { text: 'External', value: 'external' },
    { text: 'Hotspot', value: 'hotspot' },
    { text: 'Likert', value: 'likert' },
    { text: 'Matching', value: 'matching' },
    { text: 'Multiple Choice', value: 'multiple_choice' },
    { text: 'Scale', value: 'scale' },
    { text: 'Short Answer', value: 'short_answer' },
    { text: 'Smart', value: 'smart' },
    { text: 'True False', value: 'true_false' },
    { text: 'Fill In the Blanks', value: 'fill_in_the_blanks' }
  ]

  export default {
    name: 'FormsSectionFilters',
    created() {
      this.metaHelper = initMetaHelper(SESSION.project)
      const fieldOptions = this.metaHelper.getFilterableOptions()
      this.options = [...BASE_OPTIONS, ...fieldOptions]
      this.createSubOptions(fieldOptions)
    },
    data() {
      return {
        options: [],
        subOptions: {},
        search: '',
        filter: '',
        value: '',
        hideUsedItems: true,
        metaHelper: null
      }
    },
    methods: {
      applyFilters() {
        const filters = {
          search: this.search,
          filter: this.filter,
          value: this.value,
          hideUsedItems: this.hideUsedItems
        }

        this.$emit('apply-filters', filters)
      },
      clearFilters() {
        this.search = ''
        this.filter = ''
        this.value = ''
        this.hideUsedItems = true
      },
      createSubOptions(options) {
        this.subOptions.type = ITEM_TYPE_OPTIONS

        for (const option of options) {
          const optionType = this.getFieldType(option.value)
          if (optionType.startsWith('select_')) {
            this.subOptions[option.value] = this.metaHelper.getFieldOptions(
              option.value
            )
          }
        }
      },
      display(filter, types) {
        const fieldType = this.getFieldType(filter)

        return types.includes(fieldType) || types.includes(filter)
      },
      getFieldType(filter) {
        return this.metaHelper.getFieldType(filter)
      },
      onFilterChange(filter) {
        this.filter = filter
        const fieldType = this.getFieldType(filter)

        if (fieldType === 'number') {
          return (this.value = 0)
        }

        if (this.fieldType === 'select_multi') {
          return (this.value = [])
        }

        this.value = ''
      },
      openFiltersModal() {
        this.$bvModal.show('item-pool-filters-modal')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
