<template>
	<div>
		<b-navbar sticky variant="light">
			<b-button-group>
				<ApiCredentials :app-id="app.caveon_id" />
			</b-button-group>
		</b-navbar>
	</div>
</template>

<script>
import ApiCredentials from './ApiCredentials.vue'

import { deepCopy } from '../../utils/misc.js'

export default {
	name: 'Dashboard',
	components: {
		ApiCredentials
	},
	props: {
		myApp: {
			type: Object
		}
	},
	data () {
		return {
			app: null
		}
	},
	created () {
		this.app = deepCopy(this.myApp)
	}
}
</script>

<style lang="scss" scoped>
</style>
