<template>
  <div id="app">
    <Spinner v-if="loading"/>
    <div v-else>
      <SplashPage v-if="!showRoute" />
      <div v-else>
        <div v-if="adminMessage" class="position-fixed fixed-top">
          <b-alert
            class="mb-0 rounded-0"
            dismissible
            show
            variant="danger"
            @dismissed="onAdminMessageDismissed"
          >{{ adminMessage }}</b-alert>
        </div>
        <router-view :user="user" />
        <b-alert class="mb-0" style="position: fixed; bottom: 0; left: 0;" :show="isPreview" variant="info" dismissible>
          You are currently using scorpion in a preview environment
        </b-alert>
      </div>
    </div>
    <b-modal 
      id="caveonid-modal" 
      title="Caveon ID" 
      scrollable 
      centered 
      static 
      hide-header 
      footer-sm 
      no-enforce-focus 
      no-fade 
      size="lg"
      :hide-footer="caveonIdModalLocked"
      :no-close-on-esc="caveonIdModalLocked"
      :no-close-on-backdrop="caveonIdModalLocked"
    >
      <b-embed v-if="caveonIdUrl"
        :src="caveonIdUrl"
        type="iframe"
        id="caveonid-frame"
      ></b-embed>
      <template #modal-footer="{ close }">
        <b-button size="sm" variant="white" @click="close('close')">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import { EVENT } from './utils/event-bus'
  import { HTTP } from './utils/requests'
  import { setCaveonIdUrl, setAccessToken } from './utils/axios-factory'
  import { SEI_API_BASE } from './utils/constants'
  import { SESSION } from './utils/session'
  import { socketProvider } from './utils/sockets'

  import get from 'lodash.get'
  import throttle from 'lodash.throttle'

  import SplashPage from './components/SplashPage.vue'
  import Spinner from './components/Spinner.vue'

  async function getPreferences() {
    try {
      const url = `${SEI_API_BASE}/preferences`

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'App',
    components: {
      SplashPage,
      Spinner
    },
    async created() {
      // Listen globally for any alert events
      EVENT.$on(EVENT.alertEvent, args => {
        this.alert(args)
      })
      this.registerListeners()
      window.addEventListener('message', (event) => {
        if (event.origin !== this.caveondIdOrigin) return

        switch (event.data.type) {
          case 'caveonid_access_token':
            if (event.data.accessToken) {
              setAccessToken(event.data.accessToken)
              socketProvider.setAccessToken(event.data.accessToken)
              if (!this.user.is_authenticated) {
                this.getMe()
              }
            } else {
              this.init({})
            }
            break
          case 'logout':
            window.location.replace('/')
            break
          case 'caveonid_close_modal':
            this.$bvModal.hide('caveonid-modal')
            break
          case 'caveonid_open_modal':
            this.$bvModal.show('caveonid-modal')
            break
          case 'caveonid_lock_modal':
            this.caveonIdModalLocked = true
            this.$bvModal.show('caveonid-modal')
            break
          case 'caveonid_unlock_modal':
            this.caveonIdModalLocked = false
            this.$bvModal.hide('caveonid-modal')
            break
        }
      }, false)

      const aliasToken = this.$route.query.alias_token

      if (aliasToken && aliasToken.length) {
        SESSION.isAppAlias = true

        setAccessToken(aliasToken)

        socketProvider.setAccessToken(aliasToken)

        const query = Object.assign({}, this.$route.query)

        delete query.alias_token

        this.$router.replace({ query })

        this.getMe()
      } else {
        this.caveonIdUrl = await this.getCaveonIdUrl()

        setCaveonIdUrl(this.caveonIdUrl)
        
        this.caveondIdOrigin = new URL(this.caveonIdUrl).origin
      }
    },
    beforeDestroy() {
      EVENT.$off(EVENT.alertEvent)
    },
    data() {
      return {
        user: {},
        caveonIdUrl: null,
        caveondIdOrigin: null,
        caveonIdModalLocked: false,
        loading: true,
      }
    },

    methods: {
      async getMe() {
        let url = `${SEI_API_BASE}/users/me`
        let response = await HTTP.get(url)
        const { user, app } = response.data
        const env = response.headers['x-environment']
        this.init(user, app, env)
      },
      async init(user, app, env) {
        SESSION.user = user
        SESSION.app = app
        SESSION.env = env

        if (SESSION.user.is_authenticated) {
          const { data } = await getPreferences()

          const preferences = get(data, 'preferences')

          if (preferences) {
            SESSION.preferences = data.preferences
          }

          let options = {}

          if (this.isExamWatch()) {
            options = {
              query: {
                'proctor-token': this.$route.query.token
              }
            }
          }

          socketProvider.init(options)

          if (this.$router.currentRoute.path === '/') {
            this.$router.push({ path: '/projects' })
          }
        }

        this.user = SESSION.user
        this.loading = false
      },
      alert(args) {
        let { variant, title, message } = args
        if (!title) {
          title = variant === 'success' ? 'Success' : 'Error'
        }
        this.$bvToast.toast(message, {
          title,
          autoHideDelay: 3000,
          variant,
          solid: true
        })
      },
      onAdminMessageDismissed() {
        socketProvider.setAdminMessage(null)
      },
      async getCaveonIdUrl() {
        const url = '/caveonid/url'
        let response = await HTTP.get(url)
        return response.data.url
      },
      registerListeners() {
        this.throttled = throttle(this.updateLastActivity, 5000)

        window.onmousemove = this.throttled
        window.onmousedown = this.throttled
        window.ontouchstart = this.throttled
        window.onclick = this.throttled
        window.onkeydown = this.throttled
        window.addEventListener('scroll', this.throttled, true)
      },
      updateLastActivity() {
        const caveonIdFrame = document.getElementById('caveonid-frame')
        const payload = {
          type: 'caveonid_activity',
          client: 'scorpion'
        }

        if (caveonIdFrame?.contentWindow) {
          caveonIdFrame.contentWindow.postMessage(payload, this.caveonIdUrl)
        }
      },
      isExamWatch() {
        return this.$route.path === '/examwatch' && this.$route.query.token
      }
    },
    computed: {
      isPreview() {
        return location.origin.includes('scorpionpreview')
      },
      adminMessage() {
        return socketProvider.state.adminMessage
      },
      showRoute() {
        return this.user?.is_authenticated || this.isExamWatch()
      }
    }
  }
</script>

<style lang="scss">
  #app {
    height: 100%;
  }
  #caveonid-modal .modal-content {
    height: 95%;
  }
  #caveonid-modal .modal-body {
    padding: 5px!important;
  }
  .embed-responsive {
    height: 100%;
  }
</style>
