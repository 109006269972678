<template>
  <b-modal 
    :id="modalId"
    title="Modal"
    scrollable
    centered 
    static
    footer-sm 
    no-fade 
    size="lg"
  >
  <template #modal-header="{}" flex-sm-column>
    <b-col cols="6">
      <div class="statusBox"></div>
      <slot name="name"></slot>
    </b-col>
    <b-col cols="6">
      <b-row>
        <b-col cols="11">
          <b-row>
            <b-col>
            <b-button v-for="option in MODE_OPTIONS" :key="option.value" size="sm" class="switcher"
              :variant="mode === option.value ? 'white, disabled' : 'primary'" @click="handleChanges('mode', option.value)">
              {{ option.text }}
            </b-button>
            <b-select v-if="showLiveOrLatest" :value="liveOrLatest" :options="LIVE_OR_LATEST_OPTIONS" size="sm"
              @change="handleChanges('liveOrLatest', $event)"></b-select>
            </b-col>
            <b-col>
              <slot name="extraButtons"></slot>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <b-button class="ml-2 px-2" size="sm" variant="white" @click="close()">
            X
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </template>

  <slot name="content"></slot>

  <template #modal-footer="{}">
    <b-col>
      <b-button-group v-if="showPrevNext">
        <b-button variant="white" @click="previous" :disabled="!hasPrevious" size="sm">
          <font-awesome-icon
            class="mr-2"
            icon="caret-left"
          ></font-awesome-icon>Previous</b-button>
        <b-button variant="white" @click="next" :disabled="!hasNext" size="sm">Next<font-awesome-icon
            class="ml-2"
            icon="caret-right"
          ></font-awesome-icon></b-button>
      </b-button-group>
    </b-col>
    <b-col class="text-right">
      <slot name="saveBtns"></slot>
    </b-col>
  </template>
</b-modal>

</template>

<script>
import { TranslationModes } from '../../utils/translation'


export default {
  name: 'TranslationModalWrapper',
  components: {},
  props: [
    'liveOrLatest',
    'modalId',
    'closeModal',
    'hasPrevious',
    'hasNext',
    'mode'
  ],
  data() {
    return {
      LIVE_OR_LATEST_OPTIONS: [
        {text: 'Live item versions', value: 'live'},
        {text: 'Latest item versions', value: 'latest'}
      ],
      MODE_OPTIONS: TranslationModes.OPTIONS
    }
  },
  async created() {},
  methods: {
    close() {
      return this.closeModal()
    },
    handleChanges(key, value) {
      this.$emit('changes', {[key]: value})
    },
    previous() {
      this.$emit('previous')
    },
    next() {
      this.$emit('next')
    }
  },
  computed: {
    showLiveOrLatest() {
      return this.liveOrLatest
    },
    showPrevNext() {
      return this.hasPrevious || this.hasNext
    }
  }
}
</script>
