<template>
  <div v-if="!loading">
    <div v-if="files.length">
      <b-table
        :fields="fields"
        :items="files"
        @row-clicked="rowClicked"
        bordered
        hover
        responsive
        small
        striped
      >
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            variant="white"
            @click="deleteFile(row.item, row.index)"
            class="mr-2"
            >Delete</b-button
          >
        </template>
      </b-table>

      <div>
        <b-button size="sm" variant="white" @click="deleteAll" class="mr-2"
          >Delete All</b-button
        >
      </div>
    </div>
    <div class="p-2" v-else>No files to review</div>

    <b-modal ref="preview-file" size="xl" title="Preview File">
      <b-form-group label="Name">
        <input type="text" v-model="selected.name" class="w-100" />
      </b-form-group>

      <object>
        <embed :src="selected.url" />
      </object>

      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel()" variant="white">Cancel</b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
  <Spinner v-else />
</template>

<script>
  import Spinner from '../Spinner'

  export default {
    name: 'FilesPreviewTable',
    components: {
      Spinner
    },
    props: {
      files: {
        type: Array
      },
      project: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        fields: [{ key: 'name', sortable: true }, { key: 'actions' }],
        selected: {}
      }
    },
    methods: {
      rowClicked(item, index) {
        this.selected = item
        this.index = index
        this.showModal('preview-file')
      },
      showModal(ref) {
        this.$refs[ref].show()
      },
      hideModal(ref) {
        this.$refs[ref].hide()
      },
      deleteAll() {
        this.$emit('delete-files', -1)
      },
      deleteFile(_, index) {
        this.$emit('delete-files', index)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
