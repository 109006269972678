<template>
  <div v-if="!loading">
    <b-alert :show="Boolean(alertMessage)" variant="danger">
        <font-awesome-icon icon="exclamation-circle" class="mr-2" />
        {{ alertMessage }}
    </b-alert>

    <h3>
      {{ dropdownHeader }}
    </h3>

    <b-row>
      <b-col :style="{ overflowX: 'hidden' }">
        <b-table
          v-if="hasLaunchpads"
          @row-clicked="editLaunchpad"
          :fields="fields"
          :items="launchpads"
          sort-by="name"
          responsive
          fixed
          bordered
          hover
          striped
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :style="{ width: getColumnWidth(field.key) }" />
          </template>

          <template #cell(delivery_count)="row">
            {{ row.item.progress }}
          </template>

          <template #cell(active)="row">
            <font-awesome-icon v-show="row.item.active && row.item.dateActive && !row.item.deliveryLimitMet" icon="check" />
          </template>

          <template #cell(buttons)="row">
            <div class="d-flex justify-content-end">
              <b-button :disabled="shouldDisable('edit_exam_settings')" @click="editLaunchpad(row.item)" class="mr-2" size="sm" variant="white">
                Edit
              </b-button>

              <b-button @click="openLaunchpad(row.item)" size="sm" variant="white">
                Launch
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-button :disabled="shouldDisable('edit_exam_settings')" @click="newLaunchpad" variant="secondary" class="mt-2">
      Add a Launchpad
    </b-button>

    <b-modal :title="modalTitle" id="launchpad-modal" size="xl" no-fade>
      <b-alert :show="deliveryLimitMet" variant="danger">
        <font-awesome-icon icon="exclamation-circle" class="mr-2" />
        This launchpad has met the delivery limit
      </b-alert>
      
      <b-form-group v-if="!isNew" label="Launch URL" label-for="launchpad-url" class="mb-4 border-bottom pb-2">
        <b-input-group>
          <b-form-input :value="launchpadURL(editing)" class="mr-2" id="launchpad-url" ref="input" readonly />
          <b-tooltip ref="tooltip" target="launchpad-copy" title="Copied!" triggers="click" />
          <b-button @click="copyText" id="launchpad-copy" size="sm" variant="white">
            Copy
          </b-button>
        </b-input-group>
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Name" label-for="launchpad-name">
          <b-form-input v-model="editing.name" id="launchpad-name" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Delivery Tags" label-for="launchpad-tags" description="If no tags are added, deliveries will be routed to the default flow">
          <tag-selector id="launchpad-tags" :tags="project.tags" :selected="editing.tags" @tags-updated="tagsUpdated" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Status" label-for="launchpad-status">
          <b-form-select v-model="editing.active" :options="statusOptions" id="launchpad-status" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Sign In With" label-for="launchpad-auth">
          <b-form-select v-model="editing.auth_type" :options="authOptions" id="launchpad-auth" />
      </b-form-group>

      <b-form-group v-if="editing.auth_type" label-cols-sm="5" label="Attempts Allowed" description="Leave blank or 0 for unlimited" label-for="launchpad-attempts">
          <b-form-input v-model.number="editing.max_attempts" min="0" step="1" type="number" id="launchpad-attempts" />
      </b-form-group>

      <b-form-group v-if="editing.auth_type" label-cols-sm="5" label="Wait Time Between Attempts (Seconds)" description="Leave blank or 0 for no delay between attempts" label-for="launchpad-wait-between-attempts">
          <b-form-input v-model.number="editing.min_wait_seconds" min="0" step="1" type="number" id="launchpad-wait-between-attempts" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Passcode" description="Leave blank for no passcode" label-for="launchpad-pass-code">
          <b-form-input v-model="editing.code" id="launchpad-pass-code" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Proctor Code" description="Required for secure exams" label-for="launchpad-proctor-code">
          <b-form-input v-model="editing.proctor_code" id="launchpad-proctor-code" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Form" label-for="launchpad-form">
          <b-form-select v-model="editing.form_id" :options="formOptions" id="launchpad-form" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Default Language" description="Used when the form is set to random" label-for="launchpad-default-language">
          <b-form-select v-model="editing.default_language" :options="languageOptions" id="launchpad-default-language" />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Examinee Fields To Hide" label-for="launchpad-hide-examinee-fields">
          <v-select v-model="editing.examinee_fields_to_hide" :options="examineeOptions" id="launchpad-hide-examinee-fields" multiple />
      </b-form-group>

      <b-form-group label-cols-sm="5" label="Delivery Limit" description="Leave blank or 0 for unlimited" label-for="launchpad-deactivate-after">
          <b-form-input v-model.number="editing.deactivate_after" min="0" step="1" type="number" id="launchpad-deactivate-after" />
      </b-form-group>

      <b-form-group v-if="showProgress" label="Amount of Deliveries Given" label-for="launchpad-progress">
          <b-progress :max="cache.deactivate_after" id="launchpad-progress">
              <b-progress-bar :value="cache.delivery_count" :label="cache.progress" variant="warning" />
          </b-progress>
      </b-form-group>

      <b-form-group label="Start Date">
          <b-input-group>
              <b-form-datepicker v-model="editing.startDate" class="mr-2" />
              <b-form-timepicker v-model="editing.startTime" class="mr-2" locale="en" />
              <b-button @click="clearDatetime('start')" size="sm" variant="white">
                  Clear
              </b-button>
          </b-input-group>
      </b-form-group>

      <b-form-group label="End Date">
          <b-input-group>
              <b-form-datepicker v-model="editing.endDate" class="mr-2" />
              <b-form-timepicker v-model="editing.endTime" class="mr-2" locale="en" />
              <b-button cols="1" sm="2"  @click="clearDatetime('end')" size="sm" variant="white">
                  Clear
              </b-button>
          </b-input-group>
      </b-form-group>

      <b-form-group label="Meta Fields">
        <b-card v-for="(metaField, index) in editing.meta_fields_to_add" :key="index" class="mb-4">
          <b-form-group label-cols-sm="3" label="Type">
            <b-form-select v-model="metaField.type" :options="metaOptions" />
          </b-form-group>

          <b-form-group label-cols-sm="3" label="Key">
            <b-form-input v-model="metaField.key" class="mr-1" placeholder="Key" />
          </b-form-group>

          <b-form-group label-cols-sm="3" label="Label">
            <b-form-input v-model="metaField.label" placeholder="Label" />
          </b-form-group>

          <b-form-group v-show="showMetaOptions(metaField.type)" label="Options">
            <div v-for="(option, optionIndex) in metaField.options" :key="optionIndex" :class="optionClass(optionIndex, metaField.options.length)">
              <b-input-group :prepend="optionLabel(optionIndex)">
                <b-form-input v-model="metaField.options[optionIndex]" />

                <b-button @click="removeMetaOption(metaField.options, optionIndex)" variant="white" size="sm" class="ml-1">
                  <font-awesome-icon icon="trash" />
                </b-button>
              </b-input-group>
            </div>
          </b-form-group>

          <b-row class="mt-4">
              <b-col>
                  <b-button v-show="showMetaOptions(metaField.type)" @click="addMetaFieldOption(metaField.options)" variant="primary-light" size="sm">
                      Add Option
                  </b-button>
              </b-col>

              <b-col class="d-flex justify-content-end">
                  <b-button @click="removeMetaField(index)" variant="link" size="sm">
                      Delete
                  </b-button>
              </b-col>
          </b-row>
        </b-card>

        <b-button @click="addMetaField" variant="white" size="sm">Add Meta Field</b-button>
      </b-form-group>

      <template #modal-footer="{ cancel }">
        <b-button v-if="!isNew" @click="removeLaunchpad" :disabled="saving || shouldDisable('edit_exam_settings')" class="mr-auto" size="sm" variant="link">
          Delete
        </b-button>

        <b-button @click="cancel" :disabled="saving" class="mr-2" variant="white">
          Cancel
        </b-button>

        <b-button @click="beforeSave" :disabled="saving || shouldDisable('edit_exam_settings')">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>

  <Spinner v-else />
</template>

<script>
  import { deepCopy } from '../../utils/misc.js'
  import { EVENT } from '../../utils/event-bus'
  import { BUILTIN_LANGUAGES, SEI_API_BASE } from '../../utils/constants'
  import { SESSION } from '../../utils/session.js'
  import { HTTP } from '../../utils/requests'
  import {Language} from '../../utils/language'

  import Spinner from '../Spinner.vue'
  import TagSelector from './TagSelector.vue'

  const AUTH_OPTIONS = [
    { text: 'None', value: null },
    { text: 'Google', value: 'google' }
  ]

  const STATUS_OPTIONS = [
    { text: 'Live', value: true  },
    { text: 'Archived', value: false }
  ]

  const FIELDS = [
    { key: 'name', label: 'Name', tdClass: 'ellipsis', sortable: true },
    { key: 'code', label: 'Passcode', tdClass: 'ellipsis', sortable: true },
    { key: 'delivery_count', label: 'Limit', tdClass: 'ellipsis', sortable: true },
    { key: 'active', label: 'Active', sortable: true },
    { key: 'buttons', label: '' }
  ]

  const META_OPTIONS = [
    { text: 'Text', value: 'text' },
    { text: 'Checkbox', value: 'boolean' },
    { text: 'Select', value: 'select' }
  ]

  async function getLaunchpadsRequest () {
    try {
      const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/launchpads`

      const response = await HTTP.get(url)

      return { data: response.data.results }
    } catch (error) {
      return { error }
    }
  }

  async function getFormsRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/forms`

        const response = await HTTP.get(url)

        return { data: response.data.results }
    } catch (error) {
        return { error }
    }
  }

  async function saveNewLaunchpadRequest (payload) {
    try {
      const url = `${ SEI_API_BASE}/exams/${ SESSION.project.id }/launchpads`

      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveLaunchpadRequest (payload) {
    try {
      const url = `${ SEI_API_BASE}/exams/${ SESSION.project.id }/launchpads/${ payload.id }`

      const response = await HTTP.put(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function removeLaunchpadRequest (launchpadId) {
    try {
      const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/launchpads/${ launchpadId }`

      const response = await HTTP.delete(url)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'LaunchPads',
    components: {
      Spinner,
      TagSelector
    },
    props: {
      project: {
        type: Object
      }
    },
    async created() {

      this.setExamineeOptions()
      this.getLaunchpads()
      this.FetchLanguages()
      this.fetchForms()
    },
    data () {
      return {
        fields: FIELDS,
        authOptions: AUTH_OPTIONS,
        metaOptions: META_OPTIONS,
        statusOptions: STATUS_OPTIONS,
        launchpads: [],
        languages: [],
        formOptions: [],
        examineeOptions: [],
        editing: {},
        cache: {},
        loading: true,
        saving: false,
        isNew: false
      }
    },
    methods: {
      async fetchForms() {
        const { data, error } = await getFormsRequest()

        if (error) {
          const alertData = {
            variant: 'danger',
            message: 'Failed to load forms.'
          }
          EVENT.alert(alertData)
        } else {
          this.setFormOptions(data)
        }
    },
    setFormOptions(forms) {
      const formOptions = [{ value: null, text: 'Use flow form selection' }]
      for (const form of forms) {
        const formOption = {
          value: form.id,
          text: form.name
        }
        formOptions.push(formOption)
      }
      this.formOptions = formOptions
    },
      async getLaunchpads () {
        this.loading = true

        const { data, error } = await getLaunchpadsRequest()

        this.loading = false

        if (error) {
          const alertData = {
            variant: 'danger',
            message: 'Failed to load launchpads.'
          }

          return EVENT.alert(alertData)
        }

        this.setLaunchpads(data)
      },
      setLaunchpads (results) {
        const launchpads = []

        for (const result of results) {
          this.setDateActive(result)

          this.setProgress(result)

          this.setValues(result)

          launchpads.push(result)
        }

        this.launchpads = launchpads
      },
      setDateActive (launchpad) {
        launchpad.dateActive = this.checkIfDateActive(launchpad)
      },
      setProgress (launchpad) {
        launchpad.progress = ''

        if (launchpad.deactivate_after) {
          launchpad.progress = `${ launchpad.delivery_count ? launchpad.delivery_count : 0 } / ${ launchpad.deactivate_after }`
        }
      },
      getColumnWidth (key) {
        let width

        switch(key) {
          case 'active':
            width = '90px'
            break
          case 'buttons':
            width = '140px'
            break
          default:
            width = 'auto'
            break
        }

        return width + ' !important'
      },
      setExamineeOptions () {
        let examineeSchema

        if (SESSION.project.settings.use_org_schema) {
          examineeSchema = SESSION.project.organization.settings.examinee_schema
        } else {
          examineeSchema = SESSION.project.settings.examinee_schema
        }

        const examineeOptions = []

        for (const schema of examineeSchema) {
          examineeOptions.push(schema.key)
        }

        this.examineeOptions = examineeOptions
      },
      setValues (launchpad) {
        this.addLocalDatesForEditing(launchpad)

        if (!launchpad.default_language) {
            launchpad.default_language = 'English'
        }

        if (!launchpad.tags) {
            launchpad.tags = []
        }

        if (!launchpad.examinee_fields_to_hide) {
            launchpad.examinee_fields_to_hide = []
        }

        if (!launchpad.meta_fields_to_add) {
            launchpad.meta_fields_to_add = []
        }

        if (!launchpad.auth_type && launchpad.auth_type !== null) {
            launchpad.auth_type = null
        }
      },
      beforeSave () {
        if (!this.editing.name.trim()) {
            const alertData = {
                variant: 'danger',
                message: 'Launchpad name cannot be empty.'
            }
            EVENT.alert(alertData)
            return
        }
        const existingLaunchpad = this.launchpads.find(launchpad => {
            const existingName = launchpad.name.trim().toLowerCase()
            const editingName = this.editing.name.trim().toLowerCase()
            
            return existingName === editingName
        })

        if (existingLaunchpad) {
            if (existingLaunchpad.id !== this.editing.id) {
                const alertData = {
                    variant: 'danger',
                    message: 'A launchpad already exists with that name. Please choose a new name.'
                }
                EVENT.alert(alertData)
                return
            }
        }

        if (this.isNew) {
          this.saveNewLaunchpad()
        } else {
          this.saveLaunchpad()
        }
      },
      async saveNewLaunchpad () {
        const datesAreValid = this.validateDates()

        if (!datesAreValid) return

        this.setUtcDatetimeForSaving()

        this.saving = true

        const { data, error } = await saveNewLaunchpadRequest(this.editing)

        this.saving = false

        if (error) {
          const alertData = {
            variant: 'danger',
            message: 'Failed to save launchpad.'
          }

          return EVENT.alert(alertData)
        }

        this.setDateActive(data)

        this.setProgress(data)

        this.setValues(data)

        this.launchpads.push(data)

        this.$bvModal.hide('launchpad-modal')
      },
      async prepareLaunchpadForSave () {

        const datesAreValid = this.validateDates()

        if (!datesAreValid) return true

        this.setUtcDatetimeForSaving()
      },
      async saveLaunchpad () {
        const canceled = await this.prepareLaunchpadForSave()

        if (canceled) return

        this.saving = true

        const { data, error } = await saveLaunchpadRequest(this.editing)

        this.saving = false

        if (error) {
          const alertData = {
            variant: 'danger',
            message: 'Failed to save launchpad.'
          }

          return EVENT.alert(alertData)
        }

        const index = this.launchpads.findIndex(launchpad => launchpad.id === data.id)

        this.setDateActive(data)

        this.setProgress(data)

        this.setValues(data)

        this.launchpads.splice(index, 1, data)

        this.$bvModal.hide('launchpad-modal')
      },
      newLaunchpad () {
        this.editing = {
          name: '',
          code: '',
          proctor_code: '',
          default_language: 'English',
          tags: [],
          examinee_fields_to_hide: [],
          meta_fields_to_add: [],
          active: true,
          form_id: null,
          auth_type: null,
          max_attempts: null,
          min_wait_seconds: null
        }

        this.isNew = true

        this.$bvModal.show('launchpad-modal')
      },
      async removeLaunchpad () {
        this.saving = true

        const { error } = await removeLaunchpadRequest(this.editing.id)

        this.saving = false

        if (error) {
          const alertData = {
            variant: 'danger',
            message: 'Failed to delete launchpad.'
          }

          return EVENT.alert(alertData)
        }

        const index = this.launchpads.findIndex(launchpad => launchpad.id === this.editing.id)

        this.launchpads.splice(index, 1)

        this.$bvModal.hide('launchpad-modal')
      },
      launchpadURL (launchpad) {
        const launchpadBase = `${ location.origin }/launchpad/${ SESSION.project.slug }`

        if (launchpad.name) {
          return `${ launchpadBase }/${ launchpad.name }`
        }

        return launchpadBase
      },
      openLaunchpad (launchpad) {
        if (!launchpad.active) {
          const alertData = {
            variant: 'danger',
            message: 'Launchpad is not active.'
          }

          return EVENT.alert(alertData)
        }

        const url = this.launchpadURL(launchpad)

        open(url, '_blank')
      },
      editLaunchpad (launchpad) {
        launchpad = this.addLocalDatesForEditing(launchpad)

        if (!launchpad.default_language) {
          launchpad.default_language = 'English'
        }

        if (!launchpad.tags) {
          launchpad.tags = []
        }

        if (!launchpad.examinee_fields_to_hide) {
          launchpad.examinee_fields_to_hide = []
        }

        if (!launchpad.meta_fields_to_add) {
          launchpad.meta_fields_to_add = []
        }

        if (!launchpad.auth_type && launchpad.auth_type !== null) {
          launchpad.auth_type = null
        }

        this.editing = deepCopy(launchpad)

        this.cache = deepCopy(launchpad)

        this.isNew = false

        this.$bvModal.show('launchpad-modal')
      },
      copyText () {
        this.$refs.input.select()

        document.execCommand('copy')

        setTimeout(() => this.$refs.tooltip.$emit('close'), 1200)
      },
      addLocalDatesForEditing (launchpad) {
        const { start_datetime, end_datetime } = launchpad

        if (start_datetime) {
          const { localDate, localTime } = this.parseUtcDatetime(start_datetime)

          launchpad.startDate = localDate
          launchpad.startTime = localTime
        }

        if (end_datetime) {
          const { localDate, localTime } = this.parseUtcDatetime(end_datetime)

          launchpad.endDate = localDate
          launchpad.endTime = localTime
        }

        return launchpad
      },
      parseUtcDatetime (utcDatetime) {
        const startDatetime = this.$moment.utc(utcDatetime).toDate()

        const localDate = this.$moment(startDatetime).local().format('YYYY-MM-DD')

        const localTime = this.$moment(startDatetime).local().format('HH:mm:ss')

        return { localDate, localTime }
      },
      setUtcDatetimeForSaving () {
        const { startDate, startTime, endDate, endTime } = this.editing

        if (startDate && startTime) {
          this.editing.start_datetime = this.parseLocalDateTime({ localDate: startDate, localTime: startTime })
        } else {
          this.editing.start_datetime = null
        }

        if (endDate && endTime) {
          this.editing.end_datetime = this.parseLocalDateTime({ localDate: endDate, localTime: endTime })
        } else {
          this.editing.end_datetime = null
        }
      },
      parseLocalDateTime (localDateTime) {
        const { localDate, localTime } = localDateTime

        const date = this.$moment(`${ localDate } ${ localTime }`).toDate()

        const utc = this.$moment.utc(date)

        return utc
      },
      validateDates () {
        const dates = []

        for (const startEnd of ['start', 'end']) {
          const startEndDate = `${ startEnd }Date`
          const startEndTime = `${ startEnd }Time`

          const date = this.editing[startEndDate]
          const time = this.editing[startEndTime]

          if (date || time) {
            if (!date || !time) {
              let message = 'Missing ' + startEnd

              if (!date) {
                message += ' date.'
              } else {
                message += ' time.'
              }

              const alertData = {
                variant: 'danger',
                message
              }

              EVENT.alert(alertData)

              return false
            }

            const parsed = this.parseLocalDateTime({ localDate: date, localTime: time })

            dates.push(parsed)
          }
        }

        const [ start, end ] = dates

        if (start && end && start.isAfter(end)) {
          const alertData = {
            variant: 'danger',
            message: 'The start date and time cannot be after the end date and time.'
          }

          EVENT.alert(alertData)

          return false
        }

        return true
      },
      clearDatetime (startEnd) {
        const dateKey = `${ startEnd }Date`
        const timeKey = `${ startEnd }Time`

        this.editing[dateKey] = null
        this.editing[timeKey] = null
      },
      checkIfDateActive (launchpad) {
        const { start_datetime, end_datetime } = launchpad

        if (!start_datetime && !end_datetime) return true

        const now = this.$moment.utc()

        let activeStart = true
        let activeEnd = true

        if (start_datetime) {
          const startDatetime = this.$moment.utc(start_datetime)

          activeStart = now.isAfter(startDatetime)
        }

        if (end_datetime) {
          const endDatetime = this.$moment.utc(end_datetime)

          activeEnd = endDatetime.isAfter(now)
        }

        return activeStart && activeEnd
      },
      async FetchLanguages() {
        const response = await Language.FetchAll(SESSION.project.id)

        if (response.error) {
          console.error('it broke')
          return
        }

        this.languages = [...response.results]
      },
      addMetaField () {
        const metaField = {
          key: '',
          label: '',
          type: 'text',
          options: []
        }

        this.editing.meta_fields_to_add.push(metaField)
      },
      showMetaOptions (type) {
        return type === 'select'
      },
      removeMetaField (index) {
        this.editing.meta_fields_to_add.splice(index, 1)
      },
      addMetaFieldOption (options) {
        options.push('')
      },
      removeMetaOption (options, index) {
        options.splice(index, 1)
      },
      optionLabel (index) {
        return String(index + 1)
      },
      optionClass (index, length) {
        return index === length - 1 ? '' : 'mb-4'
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      tagsUpdated(tags) {
        this.editing.tags = tags
      }
    },
    computed: {
      alertMessage () {
        let disabledForProjectOrOrg

        const orgDisabled = SESSION.project.organization?.delivery_creation_disabled

        const projectDisabled = SESSION.project.delivery_creation_disabled

        if (orgDisabled && projectDisabled) {
            disabledForProjectOrOrg = 'project and its organization'
        } else if (orgDisabled) {
            disabledForProjectOrOrg = 'projects organization'
        } else if (projectDisabled) {
            disabledForProjectOrOrg = 'project'
        }

        if (!disabledForProjectOrOrg) return ''

        return `Delivery creation has been disabled for this ${ disabledForProjectOrOrg }. Launchpads will not work until delivery creation is re-enabled.`
      },
      deliveryLimitMet () {
        const { deactivate_after, delivery_count } = this.cache

        if (!deactivate_after || !delivery_count) return false

        return delivery_count >= deactivate_after
      },
      dropdownHeader () {
        return 'Launchpads: ' + this.launchpads.length
      },
      hasLaunchpads () {
        return Boolean(this.launchpads.length)
      },
      languageOptions() {
        const sortDesc = (a, b) => {
          return a.text.localeCompare(b.text)
        }

        const customLanguageOptions = this.languages.map(language => {
          return {text: language.name, value: language.id}
        }).sort(sortDesc)

        const builtinLanguageOptions = BUILTIN_LANGUAGES.map(language => {
          return {text: language + ' (builtin)', value: language}
        }).sort(this.sortOptionByTextDesc)

        const options = [
          ...customLanguageOptions,
          ...builtinLanguageOptions
        ]

        return options
      },
      modalTitle () {
        return this.isNew ? 'New Launchpad' : 'Edit Launchpad'
      },
      showProgress () {
        return !this.isNew && this.cache.deactivate_after
      }
    }
  }
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
