<template>
  <div>
    <b-modal
      @ok.prevent="saveItem()"
      id="confirm-save-modal"
      ref="confirm-save-modal"
      size="xl"
      title="Confirm"
    >
      <p>The item must be created before smart item content can be edited.</p>
      <p>Do you want to save now?</p>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button :disabled="saving" @click="cancel" variant="white"
            >Cancel</b-button
          >
          <b-button :disabled="saving" @click="ok" variant="secondary">
            <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <div v-if="optionList.includes(type)">
      <div class="options-box" id="options-list">
        <div
          :class="isChecked(index) ? 'option correct' : 'option'"
          :key="index"
          class="option"
          v-for="(option, index) in fields.content.options"
        >
          <b-row class="px-3 pb-2">
            <b-col>
              <h5>
                <b-badge
                  class="float-left border option-number"
                  pill
                  variant="white"
                  >{{ index + 1 }}</b-badge
                >
              </h5>
            </b-col>
          </b-row>
          <b-row class="pb-2 px-3">
            <b-col md="10" sm="9">
              <b-form-textarea
                class="mb-1"
                max-rows="99"
                placeholder="Option Text"
                ref="options"
                no-resize
                :value="fields.content.options[index]"
                @input="onOptionInput($event, index)"
              />
            </b-col>
            <b-col md="2" sm="3">
              <ItemOptionsReorder
                :index="index"
                :options="fields.content.options"
                :optionsKey="fields.settings.key"
                :optionsWeight="fields.settings.weights"
                @option-deleted="deleteOptionMeta"
                @options-reordered="reorderOptionMeta"
              />
              <b-form-checkbox
                :checked="isChecked(index)"
                @change="checkOption($event, index)"
                class="mt-2"
                >Correct</b-form-checkbox
              >
            </b-col>
          </b-row>
          <ItemOptionWeight :item="fields" :weight="getWeight(index)" :optionIndex="index" :propSetWeight="setWeight" />
          <b-collapse
            :visible="showMore"
            class="py-2 px-3"
            id="option-meta"
          >
            <b-row class="my-3">
              <b-col lg="3" md="4" sm="6">
                <label for="feedback">Feedback</label>
              </b-col>
              <b-col lg="9" md="8" sm="6">
                <b-form-textarea 
                  class="mb-3"
                  max-rows="99"
                  placeholder="Enter Feedback"
                  no-resize
                  :value="fields.feedback[index]"
                  @input="onFeedbackInput($event, index)"
                />
              </b-col>
            </b-row>

            <ItemOptionsMeta
              :fields="fields"
              :is-premium="isPremium"
              :only="optionMeta"
              :option-id="String(index)"
              :project="project"
            />
          </b-collapse>
        </div>
      </div>
      <b-button
        @click="addOption()"
        class="mt-3"
        size="sm"
        variant="primary-light"
        >Add Option</b-button
      >
    </div>

    <!-- FILL IN THE BLANKS -->
    <div v-if="type === 'fill_in_the_blanks'">
      <div class="options-box" id="options-list">
        <div
          :key="'segment_' + index"
          class="content-block"
          v-for="(segment, index) in fields.content.segments"
        >
          <b-row class="pb-2 px-3">
            <b-col md="10" sm="9">
              <ItemOptionSegment ref="ItemOptionSegments" class="mb-3" :segment="segment" :scoring="fields.settings.scoring" :index="index" />
            </b-col>
            <b-col md="2" sm="3">
              <ItemOptionsReorder
                :index="index"
                :options="fields.content.segments"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <b-button
        @click="addContentSegment()"
        class="mt-3 mr-3"
        size="sm"
        variant="primary-light"
        >Add Content Segment</b-button>
      <b-button
        @click="addSelectField()"
        class="mt-3 mr-3"
        size="sm"
        variant="primary-light"
        >Add Select Field</b-button>
      <b-button
        @click="addShortAnswerField()"
        class="mt-3 mr-3"
        size="sm"
        variant="primary-light"
        >Add Short Answer Field</b-button>
    </div>

    <div v-if="type === 'true_false'">
      <!-- eslint-disable vue/no-mutating-props -->
      <b-form-radio-group
        :options="trueFalseOptions"
        stacked
        v-model.number="fields.settings.key"
      ></b-form-radio-group>
      <!-- eslint-enable vue/no-mutating-props -->
    </div>

    <div v-if="type === 'matching'">
      <div class="options-box mb-2" id="options-list">
        <div
          :key="index + 'option'"
          class="option"
          v-for="(option, index) in fields.content.options"
        >
          <b-row class="px-3 pb-2">
            <b-col>
              <h5>
                <b-badge
                  class="float-left border option-number"
                  pill
                  variant="white"
                  >{{ index + 1 }}</b-badge
                >
              </h5>
            </b-col>
          </b-row>
          <b-row class="pb-2 px-3">
            <b-col lg="10" md="9">
              <b-form-textarea
                class="mb-2"
                max-rows="99"
                placeholder="Option Text"
                ref="options"
                no-resize
                :value="fields.content.options[index]"
                @input="onOptionInput($event, index)"
              />
              <b-form-select 
                class="mb-2"
                :options="matchOptions"
                :value="fields.settings.key[index]"
                @change="onMatchChange($event, index)"
              />
            </b-col>
            <b-col lg="2" md="3">
              <ItemOptionsReorder
                :index="index"
                :options="fields.content.options"
                :optionsKey="fields.settings.key"
                :optionsWeight="fields.settings.weights"
                @option-deleted="deleteOptionMeta"
                @options-reordered="reorderOptionMeta"
              />
            </b-col>
          </b-row>
          <ItemOptionWeight :item="fields" :weight="getWeight(index)" :optionIndex="index" :propSetWeight="setWeight" />
          <b-collapse
            :visible="showMore"
            class="py-2 px-3"
            id="option-meta"
          >
            <b-row class="my-3">
              <b-col lg="3" md="4" sm="6">
                <label for="feedback">Feedback</label>
              </b-col>
              <b-col lg="9" md="8" sm="6">
                <b-form-textarea 
                  class="mb-2"
                  max-rows="99"
                  placeholder="Enter Feedback"
                  no-resize
                  :value="fields.feedback[index]"
                  @input="onFeedbackInput($event, index)"
                />
              </b-col>
            </b-row>
            <ItemOptionsMeta
              :fields="fields"
              :is-premium="isPremium"
              :only="optionMeta"
              :option-id="String(index)"
              :project="project"
            />
          </b-collapse>
        </div>
      </div>
      <b-button @click="addOption(true)" size="sm" variant="primary-light"
        >Add Option</b-button
      >
      <b-card class="mt-3" no-body>
        <b-card-header>
          <h5 class="mb-0">Matches</h5>
        </b-card-header>
        <b-card-body>
          <b-row
            :key="index + 'match'"
            class="dashed-line pb-2 option"
            v-for="(match, index) in fields.content.matches"
          >
            <b-col md="10">
              <label>Match Text</label>
              <b-form-input :value="fields.content.matches[index]" @input="onMatchInput($event, index)" />
            </b-col>
            <b-col md="2">
              <label>Max Uses</label>
              <b-form-input
                class="mb-1"
                min="0"
                step="1"
                type="number"
                @input="defaultNumberInputToNull($event, fields.settings.match_max_uses, `${index}`)"
                :value="fields.settings.match_max_uses[index]"
              ></b-form-input>
              <b-button @click="removeMatch(index)" size="sm" variant="white">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-button
            @click="addMatch()"
            class="mt-3"
            size="sm"
            variant="primary-light"
            >Add Match</b-button
          >
        </b-card-body>
      </b-card>
    </div>

    <div v-if="type === 'build_list'">
      <div class="options-box mb-2" id="options-list">
        <div
          :key="index"
          class="option"
          v-for="(option, index) in fields.content.options"
        >
          <b-row class="px-3 pb-2">
            <b-col>
              <h5>
                <b-badge
                  class="float-left border option-number"
                  pill
                  variant="white"
                  >{{ index + 1 }}</b-badge
                >
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8" sm="12">
              <b-form-textarea
                max-rows="99"
                placeholder="Option Text"
                ref="options"
                no-resize
                :value="fields.content.options[index]"
                @input="onOptionInput($event, index)"
              />
            </b-col>
            <b-col md="2" sm="12">
              <label>Order</label>
              <b-input
                class="mb-2"
                min="0"
                step="1"
                type="number"
                @input="defaultNumberInputToNull($event, fields.settings.key, `${index}`)"
                :value="fields.settings.key[index]"
              ></b-input>
            </b-col>
            <b-col md="2" sm="12">
              <ItemOptionsReorder
                :index="index"
                :options="fields.content.options"
                :optionsKey="fields.settings.key"
                :optionsWeight="fields.settings.weights"
                @option-deleted="deleteOptionMeta"
                @options-reordered="reorderOptionMeta"
              />
            </b-col>
          </b-row>

          <ItemOptionWeight :item="fields" :weight="getWeight(index)" :optionIndex="index" :propSetWeight="setWeight" />

          <b-collapse
            :visible="showMore"
            class="py-2 px-3"
            id="option-meta"
          >
            <b-row class="my-3">
              <b-col lg="3" md="4" sm="6">
                <label for="feedback">Feedback</label>
              </b-col>
              <b-col lg="9" md="8" sm="6">
                <b-form-textarea 
                  max-rows="99"
                  placeholder="Enter Feedback"
                  no-resize
                  :value="fields.feedback[index]"
                  @input="onFeedbackInput($event, index)"
                />
              </b-col>
            </b-row>
            <ItemOptionsMeta
              :fields="fields"
              :is-premium="isPremium"
              :only="optionMeta"
              :option-id="String(index)"
              :project="project"
            />
          </b-collapse>
        </div>
      </div>

      <b-button
        @click="addOption(true)"
        class="mt-3"
        size="sm"
        variant="primary-light"
        >Add Option</b-button
      >
    </div>
    <div v-if="type === 'hotspot'">
      <div class="my-3">
        <label>Image URL</label>
        <b-input-group class="mb-3">
          <b-input v-model="fields.content.url"></b-input> <!-- eslint-disable-line vue/no-mutating-props -->
          <b-input-group-append>
            <b-button
              @click="loadImage()"
              variant="primary-light"
              :disabled="loadingImage"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="loadingImage"
              ></b-spinner
              >&nbsp;Load Image
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <label>Alternate Text</label>
        <b-input-group class="mb-3">
          <b-input v-model="fields.content.alt_text"></b-input> <!-- eslint-disable-line vue/no-mutating-props -->
        </b-input-group>
      </div>
      <div class="border-top-dashed pt-3" v-if="image">
        <div v-show="!imageError && !loadingImage">
          <b-row>
            <b-col sm="8">{{ modeHeader }}</b-col>
            <b-col class="text-right">
              <b-button @click="changeEdit()" size="sm" variant="white">{{
                hotSpotButtonText
              }}</b-button>
            </b-col>
          </b-row>
          <p>
            <small>{{ modeText }}</small>
          </p>
          <div
            @mousedown="mouseDown"
            @mousemove="mouseMove"
            @mouseup="mouseUp"
            class="position-relative border d-inline-block"
            ref="canvas"
            style="margin: 15px;"
          >
            <b-img
              :draggable="false"
              :src="image"
              :alt="fields.content.alt_text"
              @error="imageFailed()"
              @load="drawHotspots()"
              style="maxWidth: 100%;"
            />
            <div
              :key="index"
              :style="!isEditing ? 'pointerEvents: none' : ''"
              v-for="(box, index) in boxes"
            >
              <div :ref="index === boxes.length - 1 ? 'box' : ''" :style="box">
                <div class="position-absolute">
                  <div class="box" v-if="isEditing">
                  <b-checkbox
                    :checked="isChecked(index)"
                    @change="changeHotSpotKey($event, index)"
                    v-if="isEditing"
                    >Correct</b-checkbox
                  >
                  <div v-if="isEditing && fields.settings.scoring === 'weighted' && isChecked(index)">

                    <b-button block variant="white" class="my-2" size="sm" @click="$bvModal.show('modal-item-option-weight-' + index)">Weight</b-button>

                    <b-modal :id="'modal-item-option-weight-' + index" title="Change option weight">
                      <ItemOptionWeight :item="fields" :weight="getWeight(index)" :optionIndex="index" :propSetWeight="setWeight" />
                      <template #modal-footer>
                        <div class="w-100">
                          <b-button variant="primary"  @click="$bvModal.hide('modal-item-option-weight-' + index)" class="float-right" size="sm">Save</b-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                  <b-button
                    @click="removeHotspot(index)"
                    size="sm"
                    v-if="isEditing"
                    variant="white"
                    block
                  >
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                  </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-alert class="mb-0" :show="imageError" variant="danger"
          >Failed to load image.</b-alert
        >
      </div>
    </div>

    <div v-if="type === 'external'">
      <b-button
        @click="changeAdvanced()"
        class="mb-3"
        size="sm"
        variant="primary-light"
        >{{ externalSettingsText }}</b-button
      >
      <div v-if="!showAdvancedExternal">
        <b-row>
          <b-col>
            <label>Static iFrame URL *</label>
            <b-input required v-model="fields.content.url"></b-input> <!-- eslint-disable-line vue/no-mutating-props -->
          </b-col>
        </b-row>

        <b-row>
          <!-- eslint-disable vue/no-mutating-props -->
          <b-col>
            <label>Open as:</label>
            <b-select
              :options="externalOpeningOptions"
              class="mb-3"
              v-model="fields.settings.open_as"
            ></b-select>
          </b-col>
          <!-- eslint-enable vue/no-mutating-props -->
        </b-row>
      </div>
      <div id="options-list" v-else>
        <label>Request iFrame URL *</label>
        <b-input required class="mb-3" v-model="fields.settings.request.url"></b-input> <!-- eslint-disable-line vue/no-mutating-props -->
        <label>Request Method</label>
        <!-- eslint-disable vue/no-mutating-props -->
        <b-select
          :options="externalMethodOptions"
          class="mb-3"
          v-model="fields.settings.request.method"
        ></b-select>
        <!-- eslint-enable vue/no-mutating-props -->
        <label class="d-block">Request Headers</label>
        <b-row
          :key="index + 'header'"
          class="option"
          v-for="(header, index) in fields.settings._headers"
        >
          <b-col md="5">
            <b-form-input
              class="mb-1"
              placeholder="Header Key"
              :value="header.key"
              @input="onHeaderKeyInput($event, index)"
            ></b-form-input>
          </b-col>
          <b-col md="5">
            <b-form-input
              class="mb-1"
              placeholder="Header Value"
              :value="header.value"
              @input="onHeaderValueInput($event, index)"
            ></b-form-input>
          </b-col>
          <b-col md="1">
            <b-button @click="removeHeader(index)" size="sm" variant="white">
              <font-awesome-icon icon="trash"></font-awesome-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-button @click="addHeader()" class="my-2" size="sm" variant="primary"
          >Add Header</b-button
        >

        <label class="d-block">Request JSON</label>
        <b-row
          :key="index + 'json'"
          class="option"
          v-for="(json, index) in fields.settings._json"
        >
          <b-col md="5">
            <b-form-input
              class="mb-1"
              placeholder="JSON Key"
              :value="json.key"
              @input="onJsonKeyInput($event, index)"
            ></b-form-input>
          </b-col>
          <b-col md="5">
            <b-form-input
              class="mb-1"
              placeholder="JSON Value"
              :value="json.value"
              @input="onJsonValueInput($event, index)"
            ></b-form-input>
          </b-col>
          <b-col md="1">
            <b-button @click="removeJSON(index)" size="sm" variant="white">
              <font-awesome-icon icon="trash"></font-awesome-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-button @click="addJSON()" class="my-2" size="sm" variant="primary"
          >Add JSON</b-button
        >
      </div>
      <label class="mt-3 d-block">Options</label>
      <b-row
        :class="isChecked(index) ? 'option correct' : 'option'"
        :key="index"
        v-for="(option, index) in fields.content.options"
      >
        <b-col md="10" sm="9">
          <b-form-textarea
            class="mb-1"
            max-rows="99"
            placeholder="Option Text"
            ref="options"
            no-resize
            :value="fields.content.options[index]"
            @input="onOptionInput($event, index)"
          />
          <b-button size="sm" variant="link" @click="autoFillOption(index)">
            Autofill
          </b-button>
        </b-col>
        <b-col md="2" sm="3">
          <ItemOptionsReorder
            :index="index"
            :options="fields.content.options"
            :optionsKey="fields.settings.key"
            :optionsWeight="fields.settings.weights"
            @option-deleted="deleteOptionMeta"
            @options-reordered="reorderOptionMeta"
          />
          <b-form-checkbox
            :checked="isChecked(index)"
            @change="checkOption($event, index)"
            class="mt-2"
            >Correct</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-button @click="addOption()" class="mt-2" size="sm" variant="primary"
        >Add Option</b-button
      >
    </div>

    <div v-if="type === 'smart'" class="mt-4">
      <p>
        Smart Items are used to render items based on variables. The Caveon Smart Item API generates these items using configurations set within an API Source. You can have multiple API Sources to further randomize the rendering of Smart Items. </p>
        <p><strong>Changes in the Smart Item API editor and the source list below are not versioned and will affect live tests.</strong>
        </p>
      <div v-if="isNew">
        <b-button :disabled="saving" v-b-modal.confirm-save-modal>View SmartItem Content</b-button>
      </div>
      <div v-else>
        <SmartItemParts :item="item" :propSmartItemPartChanged="smartPartChanged" />
      </div>
    </div>
  </div>
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { SESSION } from '../../utils/session'
  import { deepCopy, defaultNumberInputToNull } from '../../utils/misc'

  import ItemOptionsReorder from './ItemOptionsReorder'
  import ItemOptionsMeta from './ItemOptionsMeta'
  import SmartItemParts from './SmartItemParts'
  import ItemOptionWeight from './ItemOptionWeight'
  import ItemOptionSegment from './ItemOptionSegment.vue'

  import get from 'lodash.get'

  const HOTSPOT_TEXT = {
    edit: {
      header: 'Edit Hotspots',
      text: 'Remove hotspot areas or mark them as correct/incorrect.'
    },
    create: {
      header: 'Create Hotspots',
      text:
        'Click and drag to create hotspot rectangles on the image below. To account for different devices and physical user abilities, please draw your hotspot slightly larger than the intended target if possible.'
    }
  }

  const HOTSPOT_BORDER = ['1px solid #d20202', '1px solid #6dd201']

  const EXTERNAL_ADVANCED_SCHEMA = {
    headers: {},
    json: {},
    method: 'get',
    url: ''
  }

  const DEFAULT_CONTENT_SEGMENT = {
    type: 'content',
    text: ''
  }

  const DEFAULT_SELECT_SEGMENT = {
    type: 'select',
    options: [],
    randomize: false,
    weight: 100
  }

  const DEFAULT_SHORT_ANSWER_SEGMENT = {
    type: 'short_answer',
    options: [],
    scoring: 'exact',
    weight: 100
  }


  async function getImageUrl(template, projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/parse_template`
      const payload = { template: template }

      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  function createBox(left, top, width, height, correct) {
    const box = {
      left: left + 'px',
      top: top + 'px',
      position: 'absolute',
      background: 'rgba(255, 255, 255, 0.5)',
      width: width + 'px',
      height: height + 'px',
      border: HOTSPOT_BORDER[correct],
      boxShadow: '0px 0px 3px #fff, inset 0px 0px 3px #000',

    }
    return box
  }

  export default {
    name: 'ItemOptions',
    components: {
      ItemOptionsReorder,
      ItemOptionsMeta,
      SmartItemParts,
      ItemOptionWeight,
      ItemOptionSegment
    },
    props: {
      project: {
        type: Object,
        default: () => ({})
      },
      fields: {
        type: Object
      },
      type: {
        type: String
      },
      isNew: {
        type: Boolean
      },
      saving: {
        type: Boolean
      },
      showMore: {
        type: Boolean
      },
      toggle: {
        type: Boolean
      },
      item: {
        type: Object,
        default: () => ({})
      }
    },
    watch: {
      type(newType, oldType) {
        if (newType === 'hotspot') {
          this.addHotspotResizeListener()
        }

        if (oldType === 'hotspot') {
          this.removeHotspotResizeListener()
        }

        if (newType === 'external') {
          this.formatExternal()
        }
      },
      toggle() {
        this.optionExpand = true
      }
    },
    created() {
      this.metaHelper = initMetaHelper(this.project)

      if (this.type === 'hotspot') {
        this.loadImage()
        this.addHotspotResizeListener()
      }

      if (this.type === 'external') {
        this.formatExternal()
      }

      if (this.metaHelper.hasCustomFields) {
        this.optionMeta = this.getMetaFieldKeys()
        this.fillMissingMetaDefaults(this.optionMeta)
      }
    },
    destroyed() {
      this.removeHotspotResizeListener()
    },
    data() {
      return {
        options: {},
        trueFalseOptions: [
          { value: 1, text: 'True' },
          { value: 0, text: 'False' }
        ],
        externalMethodOptions: [
          { text: 'GET', value: 'get' },
          { text: 'POST', value: 'post' }
        ],
        externalOpeningOptions: [
          { text: 'iframe', value: 'iframe' },
          { text: 'tab', value: 'tab' },
          { text: 'popup', value: 'popup' }
        ],
        optionList: ['multiple_choice', 'domc', 'short_answer'],
        image: '',
        imageError: false,
        dragging: false,
        isEditing: false,
        loadingImage: false,
        loadingSmart: false,
        optionExpand: false,
        showAdvancedExternal: false,
        boxes: [],
        start: {
          x: 0,
          y: 0
        },
        orderOptions: [],
        optionMeta: [],
        defaultNumberInputToNull
      }
    },
    methods: {
      addOption(matchingOrBuild = false) {
        this.fields.content.options.push('') // eslint-disable-line vue/no-mutating-props
        const hasWeights = get(this.fields.settings, 'weights', false)
        if (hasWeights) {
          this.fields.settings.weights.push(0) // eslint-disable-line vue/no-mutating-props
        }
        if (matchingOrBuild) {
          this.fields.settings.key.push(null) // eslint-disable-line vue/no-mutating-props
        } else {
          this.fields.settings.key.push(0) // eslint-disable-line vue/no-mutating-props
        }
        this.scroll()
        this.focusLastOption()
      },
      addContentSegment() {
        this.fields.content.segments.push({ ...DEFAULT_CONTENT_SEGMENT }) // eslint-disable-line vue/no-mutating-props
        this.focusLastSegment()
      },
      addSelectField() {
        this.fields.content.segments.push({ ...DEFAULT_SELECT_SEGMENT }) // eslint-disable-line vue/no-mutating-props
        this.focusLastSegment()
      },
      addShortAnswerField() {
        this.fields.content.segments.push({ ...DEFAULT_SHORT_ANSWER_SEGMENT }) // eslint-disable-line vue/no-mutating-props
        this.focusLastSegment()
      },
      focusLastSegment() {
        this.$nextTick(() => {
          const lastIdx = this.fields.content.segments.length - 1
          this.$refs.ItemOptionSegments[lastIdx].giveFocus()
        });
      },
      focusLastOption() {
        this.$nextTick(() => {
          const lastIdx = this.fields.content.options.length - 1
          this.$refs.options[lastIdx].$el.focus()
        });
      },
      onOptionInput(value, index) {
        this.fields.content.options[index] = value // eslint-disable-line vue/no-mutating-props
      },
      onFeedbackInput(value, index) {
        this.fields.feedback[index] = value // eslint-disable-line vue/no-mutating-props
      },
      onMatchInput(value, index) {
        this.$set(this.fields.content.matches, index, value) // eslint-disable-line vue/no-mutating-props
      },
      onMatchChange(value, index) {
        this.fields.settings.key[index] = Number(value) // eslint-disable-line vue/no-mutating-props
      },
      onHeaderKeyInput(value, index) {
        this.fields.settings._headers[index].key = value // eslint-disable-line vue/no-mutating-props
      },
      onHeaderValueInput(value, index) {
        this.fields.settings._headers[index].value = value // eslint-disable-line vue/no-mutating-props
      },
      onJsonKeyInput(value, index) {
        this.fields.settings._json[index].key = value // eslint-disable-line vue/no-mutating-props
      },
      onJsonValueInput(value, index) {
        this.fields.settings._json[index].value = value // eslint-disable-line vue/no-mutating-props
      },
      checkOption(value, index) {
        const updatedValue = value ? 1 : 0
        this.fields.settings.key.splice(index, 1, updatedValue) // eslint-disable-line vue/no-mutating-props
      },
      isChecked(index) {
        return Boolean(this.fields.settings.key[index])
      },
      removeMatch(index) {
        this.fields.content.matches.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.fields.settings.match_max_uses.splice(index, 1) // eslint-disable-line vue/no-mutating-props

        for (const [i, key] of this.fields.settings.key.entries()) {
          if (index === key) {
            this.fields.settings.key[i] = null // eslint-disable-line vue/no-mutating-props
          }

          if (key > index) {
            this.fields.settings.key[i] = this.fields.settings.key[i] - 1 // eslint-disable-line vue/no-mutating-props
          }
        }
      },
      addMatch() {
        this.fields.content.matches.push('') // eslint-disable-line vue/no-mutating-props
        this.fields.settings.match_max_uses.push(1) // eslint-disable-line vue/no-mutating-props
      },
      addHotspotResizeListener() {
        window.addEventListener('resize', this.drawHotspots)
      },
      removeHotspotResizeListener() {
        window.removeEventListener('resize', this.drawHotspots)
      },
      async loadImage() {
        this.loadingImage = true
        this.imageError = false

        let url = this.fields.content.url
        let altText = ''

        if (url.startsWith('{{')) {
          const { data, error } = await getImageUrl(url, this.project.id)

          if (error) {
            this.imageError = true
            this.loadingImage = false
          }

          url = data.parsed

          const regex = /!\[(.*)\]\((.*)\)/
          if (regex.test(url)) {
            const matches = url.match(regex)
            altText = matches[1] || 'Hotspot'
            url = matches[2]
          }
        }

        // Only use embed alt text if there's not one set on the input field
        if (!this.fields.content.alt_text) {
          this.fields.content.alt_text = altText // eslint-disable-line vue/no-mutating-props
        }

        this.image = url
        this.loadingImage = false
      },
      imageFailed() {
        this.imageError = true
      },
      changeEdit() {
        this.isEditing = !this.isEditing
      },
      changeHotSpotKey(value, index) {
        const number = Number(value)
        this.fields.settings.key[index] = number // eslint-disable-line vue/no-mutating-props
        const updatedBox = {
          ...this.boxes[index],
          border: HOTSPOT_BORDER[number]
        }
        this.boxes.splice(index, 1, updatedBox)
      },
      drawHotspots() {
        const canvas = this.$refs.canvas
        if (canvas) {
          this.boxes = []

          const canvasWidth = canvas.offsetWidth
          const canvasHeight = canvas.offsetHeight

          for (const [index, spot] of this.fields.settings.spots.entries()) {
            var tlX = spot[0][0] * canvasWidth
            var tlY = spot[0][1] * canvasHeight
            var brX = spot[1][0] * canvasWidth
            var brY = spot[1][1] * canvasHeight

            const width = Math.round(brX - tlX)
            const height = Math.round(brY - tlY)

            const correct = this.fields.settings.key[index]

            const box = createBox(tlX, tlY, width, height, correct)

            this.boxes.push(box)
          }
        }
      },
      removeHotspot(index) {
        this.boxes.splice(index, 1)
        this.fields.settings.weights.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.fields.settings.spots.splice(index, 1) // eslint-disable-line vue/no-mutating-props
        this.fields.settings.key.splice(index, 1) // eslint-disable-line vue/no-mutating-props
      },
      mouseDown(event) {
        if (!this.isEditing) {
          this.dragging = true
          this.start = {
            x: event.offsetX,
            y: event.offsetY
          }
          const box = createBox(this.start.x, this.start.y, null, null, 1)
          this.fields.settings.key.push(1) // eslint-disable-line vue/no-mutating-props
          this.fields.settings.weights.push(0) // eslint-disable-line vue/no-mutating-props
          this.boxes.push(box)
        }
      },
      mouseMove(event) {
        if (this.dragging) {
          let left
          if (event.offsetX - this.start.x < 0) {
            left = event.offsetX
          } else {
            left = this.start.x
          }

          let top
          if (event.offsetY - this.start.y < 0) {
            top = event.offsetY
          } else {
            top = this.start.y
          }

          const width = Math.abs(event.offsetX - this.start.x)
          const height = Math.abs(event.offsetY - this.start.y)
          const box = createBox(left, top, width, height, 1)
          this.boxes.pop()
          this.boxes.push(box)
        }
      },
      mouseUp() {
        if (!this.isEditing) {
          this.dragging = false
          this.storeHotSpot()
        }
      },
      storeHotSpot() {
        const canvas = this.$refs.canvas
        const canvasWidth = canvas.offsetWidth
        const canvasHeight = canvas.offsetHeight
        const box = this.$refs.box[0]

        const fromTop = box.offsetTop
        const fromLeft = box.offsetLeft
        const width = box.clientWidth
        const height = box.clientHeight
        const tlY = fromTop / canvasHeight
        const tlX = fromLeft / canvasWidth
        const brY = (fromTop + height) / canvasHeight
        const brX = (fromLeft + width) / canvasWidth

        this.fields.settings.spots.push([ // eslint-disable-line vue/no-mutating-props
          [tlX, tlY],
          [brX, brY]
        ])
      },
      autoFillOption(index) {
        this.$emit('open-preview', index)
      },
      changeAdvanced() {
        this.fields.settings._advanced = !this.fields.settings._advanced // eslint-disable-line vue/no-mutating-props
        this.showAdvancedExternal = this.fields.settings._advanced

        if (this.fields.settings._advanced) {
          this.fields.settings.request = { ...EXTERNAL_ADVANCED_SCHEMA } // eslint-disable-line vue/no-mutating-props
        } else {
          delete this.fields.settings.request
        }
      },
      formatExternal() {
        const request = this.fields.settings.request

        if (!this.fields.settings.hasOwnProperty('_advanced')) { // eslint-disable-line no-prototype-builtins
          this.$set(this.fields.settings, '_headers', [])
          this.$set(this.fields.settings, '_json', [])
          this.$set(this.fields.settings, '_advanced', request ? true : false)
          this.showAdvancedExternal = this.fields.settings._advanced
        }

        if (request) {
          for (const key in request.headers) {
            const header = {
              key,
              value: request.headers[key]
            }

            this.fields.settings._headers.push(header) // eslint-disable-line vue/no-mutating-props
          }

          for (const key in request.json) {
            const json = {
              key,
              value: request.json[key]
            }

            this.fields.settings._json.push(json) // eslint-disable-line vue/no-mutating-props
          }
        }
      },
      addHeader() {
        this.fields.settings._headers.push({ key: '', value: '' }) // eslint-disable-line vue/no-mutating-props
      },
      addJSON() {
        this.fields.settings._json.push({ key: '', value: '' }) // eslint-disable-line vue/no-mutating-props
      },
      removeHeader(index) {
        this.fields.settings._headers.splice(index, 1) // eslint-disable-line vue/no-mutating-props
      },
      removeJSON(index) {
        this.fields.settings._json.splice(index, 1) // eslint-disable-line vue/no-mutating-props
      },
      saveItem() {
        this.$emit('save')
      },
      scroll() {
        setTimeout(() => {
          const element = document.getElementById('options-list')
          if (element) {
            element.scrollTop = element.scrollHeight
          }
        }, 10)
      },
      getMetaFieldKeys() {
        const keys = []

        for (const metaKey of this.project.meta.scorpion.custom_field_order) {
          if (this.project.meta.scorpion.custom[metaKey].by_option) {
            keys.push(metaKey)
          }
        }

        return keys
      },
      fillMissingMetaDefaults(optionFields) {
        this.fields.meta = { // eslint-disable-line vue/no-mutating-props
          scorpion: {},
          ...this.fields.meta
        }

        for (const key of optionFields) {
          if (typeof this.fields.meta.scorpion[key] !== 'object') {
            this.fields.meta.scorpion[key] = {} // eslint-disable-line vue/no-mutating-props
          }
        }
      },
      deleteOptionMeta(index) {
        if (!this.metaHelper.hasCustomFields) {
          return
        }

        const meta = deepCopy(this.fields.meta)
        for (const fieldId of this.optionMeta) {
          const field = meta.scorpion[fieldId]
          delete field[index]

          for (const [key, value] of Object.entries(field)) {
            if (key > index) {
              delete field[key]
              field[key - 1] = value
            }
          }
        }
        this.fields.meta = meta // eslint-disable-line vue/no-mutating-props
      },
      reorderOptionMeta(from, to) {
        if (!this.metaHelper.hasCustomFields) {
          return
        }

        const meta = deepCopy(this.fields.meta)
        for (const key of this.optionMeta) {
          const field = meta.scorpion[key]
          const fromValue = field[from]
          const toValue = field[to]
          field[from] = toValue
          field[to] = fromValue
        }
        this.fields.meta = meta // eslint-disable-line vue/no-mutating-props

        this.fields.feedback = Object.keys(this.fields.feedback).reduce(feedback => { // eslint-disable-line vue/no-mutating-props
          feedback[to] = this.fields.feedback[from]
          feedback[from] = this.fields.feedback[to]

          return feedback
        }, { ...this.fields.feedback })
      },
      smartPartChanged(smartParts) {
        this.fields.smartitem_parts = smartParts // eslint-disable-line vue/no-mutating-props
      },
      setWeight(index, weight) {
        if (this.fields.settings.weights == undefined) {
          this.fields.settings.weights = [] // eslint-disable-line vue/no-mutating-props
        }
        const newWeights = [ ...this.fields.settings.weights ]
        newWeights[index] = weight
        this.fields.settings.weights = newWeights // eslint-disable-line vue/no-mutating-props
      },
      getWeight(index) {
        if (this.fields.settings.weights) {
          return this.fields.settings.weights[index] || 0
        }
        return 0
      }
    },
    computed: {
      matchOptions() {
        const options = [{ text: 'Select a match...', value: null }]
        for (const [index, match] of this.fields.content.matches.entries()) {
          options.push({ text: match, value: index })
        }
        return options
      },
      optionExpandOrCollapse() {
        if (this.optionExpand) return 'Hide more'
        return 'Show more'
      },
      hotSpotButtonText() {
        if (this.isEditing) {
          return 'Create Hotspots'
        }
        return 'Edit or Remove Hotspots'
      },
      modeHeader() {
        if (this.isEditing) {
          return HOTSPOT_TEXT.edit.header
        }
        return HOTSPOT_TEXT.create.header
      },
      modeText() {
        if (this.isEditing) {
          return HOTSPOT_TEXT.edit.text
        }
        return HOTSPOT_TEXT.create.text
      },
      externalSettingsText() {
        if (this.showAdvancedExternal) {
          return 'Show Basic Settings'
        }
        return 'Show Advanced Settings'
      },
      isPremium() {
        return SESSION.isPremium()
      },
      hasOptionMeta() {
        if (this.metaHelper.hasCustomFields) {
          return this.metaHelper.getOptionLevelFieldIds().length
        }

        return 0
      },
      smartPreview() {
        return get(this.item, 'preview_fields.stem', '')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .create {
    pointer-events: none;
  }

  .correct {
    border: 2px solid #6dd201 !important;
  }

  .option {
    background: #eee;
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 8px 8px 0;
    margin: 8px 0 18px 12px;
  }
  .content-block {
    background: #eee;
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 8px 8px 0;
    margin: 8px 0 8px;
  }
  .option-number {
    margin: -22px 0 0 -38px;
  }
  .border-top-dashed {
    border-top: 1px dashed #eee;
  }
  .position-absolute .box {
    background: #eee;
    padding: 8px;
    box-shadow: 0 0 5px rgb(131, 131, 131);
  }
</style>
