<template>
  <div>
    <b-modal @ok="onModalOk" id="filters-modal" size="xl" title="Projects" no-fade scrollable>
      <b-alert :show="showRestrictionAlert" variant="warning">
        You have tag restrictions on some exams. These tags are marked with a <font-awesome-icon icon="lock" class="ml-1" />
      </b-alert>

      <b-input-group class="mb-3">
        <b-input v-model="search" placeholder="Search Projects" autofocus />
      </b-input-group>

      <small class="form-text text-muted mb-1">
        Select the projects you would like to see exams for:
      </small>

      <b-button @click="onSelectAll" class="mb-2" size="sm">
        Select All
      </b-button>

      <b-list-group @click="onRowClick">
        <b-list-group-item v-for="option in filteredOptions" :key="option.id" class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <input :checked="option.checked" :id="option.id" type="checkbox">
            <label :for="option.id" class="ml-2">{{ option.name }}</label>
          </div>

          <div v-if="option.locked">
            <b-badge v-for="(tag, index) in option.tags" :key="index" variant="warning" class="p-2 ml-1">
              <font-awesome-icon icon="lock" class="mr-1" />
              {{ tag }}
            </b-badge>
          </div>

          <div v-else>
            <b-badge v-for="(tag, index) in option.tags" :key="index" variant="secondary" class="p-2 ml-1">
              {{ tag }}
            </b-badge>

            <b-button class="ml-2" variant="white" size="sm" :id="option.id">
              <font-awesome-icon icon="plus" class="no-pointer" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>

      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="white">Cancel</b-button>
        <b-button @click="ok" variant="secondary">Confirm</b-button>
      </template>
    </b-modal>

    <b-modal @hidden="onTagChanges" id="filters-tags-modal" size="sm" title="Tag Filters" no-fade>
      <b-tags v-model="tags" placeholder="Enter Tags" remove-on-delete autofocus />

      <template #modal-footer="{ cancel }">
        <b-button @click="cancel" variant="white">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { deepCopy } from '../utils/misc'
import { EVENT } from '../utils/event-bus'

function tagsChanged(newTags, oldTags) {
  newTags = newTags ? newTags : []

  oldTags = oldTags ? oldTags : []

  if (newTags.length !== oldTags.length) return true

  let changed = false

  for (const tag of newTags) {
    if (!oldTags.includes(tag)) {
      changed = true

      break
    }
  }

  return changed
}

export default {
  name: 'ExamWatchFilters',
  props: {
    projectLookup: {
      type: Object
    }
  },
  created() {
    this.setOptions()

    EVENT.$on('open-filter-modal', this.onBeforeOpenModal)
  },
  beforeDestroy() {
    EVENT.$off('open-filter-modal')
  },
  data() {
    return {
      search: '',
      editing: '',
      tags: [],
      options: [],
      original: [],
      showRestrictionAlert: false
    }
  },
  methods: {
    onSelectAll () {
        for (const option of this.filteredOptions) {
            option.checked = true
        }
    },
    setOptions() {
      const options = []

      let showRestrictionAlert = false

      for (const [projectId, projectInfo] of Object.entries(this.projectLookup)) {
        const option = { id: projectId, name: projectInfo.name, checked: false }

        if (projectInfo.tags) {
          option.tags = projectInfo.tags
          option.locked = true

          showRestrictionAlert = true
        }

        options.push(option)
      }

      options.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      this.options = options

      this.original = deepCopy(options)

      this.showRestrictionAlert = showRestrictionAlert
    },
    onRowClick(event) {
      if (!event.target.type) return

      const id = event.target.getAttribute('id')

      const index = this.options.findIndex(option => option.id === id)

      const option = this.options[index]

      if (event.target.type === 'button') {
        this.editing = id

        if (option.tags) {
          this.tags = option.tags
        } else {
          this.tags = []
        }

        this.$bvModal.show('filters-tags-modal')

        return
      }

      option.checked = !option.checked

      this.options.splice(index, 1, option)
    },
    onTagChanges() {
      const index = this.options.findIndex(option => option.id === this.editing)

      const option = this.options[index]

      option.tags = this.tags

      if (!option.tags.length) {
        delete option.tags
      }

      this.options.splice(index, 1, option)
    },
    onBeforeOpenModal() {
      this.options = deepCopy(this.original)

      this.$bvModal.show('filters-modal')
    },
    onModalOk() {
      const newProjectIds = []
      const removedProjectIds = []

      const newTagFilters = {}
      const removedTagFilters = {}

      for (const option of this.options) {
        const originalOption = this.original.find(originalOption => originalOption.id === option.id)

        if (option.checked && !originalOption.checked) {
          if (option.tags) {
            newTagFilters[option.id] = option.tags
            
            continue
          }

          newProjectIds.push(option.id)

          continue
        }

        if (!option.checked && originalOption.checked) {
          if (originalOption.tags) {
            removedTagFilters[originalOption.id] = originalOption.tags

            continue
          }

          removedProjectIds.push(originalOption.id)

          continue
        }

        const changed = tagsChanged(option.tags, originalOption.tags)

        if (option.checked && changed) {
          if (option.tags) {
            newTagFilters[option.id] = option.tags
          }

          if (originalOption.tags) {
            removedTagFilters[originalOption.id] = originalOption.tags
          }
        }
      }

      const newIds = Boolean(newProjectIds.length)

      const removedIds = Boolean(removedProjectIds.length)

      const newFilters = Boolean(Object.keys(newTagFilters).length)

      const removedFilters = Boolean(Object.keys(removedTagFilters).length)

      if (newIds || removedIds || newFilters || removedFilters) {
        this.$emit('filters-changed', newProjectIds, removedProjectIds, newTagFilters, removedTagFilters)
      }

      this.original = deepCopy(this.options)
    }
  },
  computed: {
    filteredOptions() {
      return this.options.filter(option => option.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>

<style lang="scss" scoped>
.no-pointer {
  pointer-events: none;
}
</style>
