<template>
  <b-row class="sticky-tools">
    <slot name="columns"></slot>
  </b-row>
</template>
<script>
export default {
  name: 'TranslationStickyTools',
  components: {
  },
  props: [],
  data() {
    return {}
  },
  async created() {},
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
  .sticky-tools {
    position:sticky; 
    top: 40px; 
    z-index: 1; 
    padding: 8px 0; 
    background-color: #fff; 
    border-bottom: 1px dashed #eee;
    margin-bottom: 30px;
  }
</style>
