<template>
  <div>
    <v-select
      @input="rolesChanged"
      :data-value="selected"
      :options="options"
      :disabled="isDisabled"
      :placeholder="placeholder"
      label="name"
      multiple
      :required="selected.length < 1"
      taggable
      :create-option="
        value => ({
          key: '',
          type: 'user',
          value: value,
          name: `${value} (User)`
        })
      "
      v-model="selected"
    >
      <template v-slot:no-options="">
        <em style="opacity: 0.5;"
          >No results found. Please manually enter an email.</em
        >
      </template>
    </v-select>
    <br />
    <div v-if="!isDisabled">
      <b-form-group
        label="Import emails from XLSX/CSV"
        label-cols-sm="4"
        label-for="import-csv-emails"
      >
        <b-input-group>
          <input
            id="import-csv-emails"
            @change="importEmails($event)"
            type="file"
            variant="dark"
          />
        </b-input-group>
    </b-form-group>



    </div>
  </div>
</template>

<script>
  import xlsx from 'xlsx'

  // const DELIMITERS = [' ', ',', ';', '\t']

  export default {
    name: 'RolesInput',
    props: {
      options: {
        type: Array,
        required: false,
        default: () => []
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      editRole: {
        type: Object,
        default: () => ({
          name: ''
        })
      },
      placeholder: {
        type: String,
        default: () => ''
      }
    },
    data() {
      return {
        selected: []
      }
    },
    created() {
      if (this.isDisabled) {
        this.selected = [this.editRole.value]
      }
    },
    methods: {
      rolesChanged() {
        this.$emit('roles-changed', this.selected)
      },
      hasTagsColumn(text) {
        const lines = text.split('\n');
        if (lines.length === 0) return false

        const firstLine = lines[0].toLowerCase()
        if (firstLine.includes('tags_restrictions')) {
          return true;
        }
        return false
      },
      importEmails(event) {
        let csvReader = new FileReader()
        csvReader.onload = event => {
          const hasTags = this.hasTagsColumn(event.target.result)
          this.processCSV(event.target.result, hasTags)
        }

        const file = event.target.files[0]
        const ext = file.name.split('.').pop()
        if (ext === 'xlsx') {
          const excelReader = new FileReader()
          excelReader.onload = event => {
            const data = new Uint8Array(event.target.result)
            const workbook = xlsx.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const csv = xlsx.utils.sheet_to_csv(workbook.Sheets[sheetName])
            const hasTags = this.hasTagsColumn(csv)
            this.processCSV(csv, hasTags)
          }
          excelReader.readAsArrayBuffer(file)
        } else {
            csvReader.readAsText(file)
          }
      }, 
      processCSV(text, hasTags) {
      const DELIMITERS = [' ', ',', ';', '\t'];
      const lines = text.split('\n')
      const uniqueEmails = new Set()
      if(hasTags){
        for (let i = 1; i < lines.length; i++) {
          const line = lines[i]
          const email_tags = line.split(' ')
          const email = email_tags[0].replace(/"/g, '').trim()
          if (email.trim() !== '' && !uniqueEmails.has(email)) {
            uniqueEmails.add(email)
            const role = {
              key: '',
              type: 'user',
              value: email,
              name: `${email} (User)`
            }
            this.selected.push(role)
          }
        }
        this.rolesChanged()
        }
      else {
    for (const line of lines) {
      const delimiter = new RegExp('[' + DELIMITERS.join('') + ']')
      const emailArray = line.split(delimiter)
      const emails = emailArray.map(email => email.replace(/"/g, '').trim())

      for (const email of emails) {
        if (email.trim() !== '' && !uniqueEmails.has(email)) {
          uniqueEmails.add(email);
          const role = {
            key: '',
            type: 'user',
            value: email,
            name: `${email} (User)`
          };
          this.selected.push(role);
        }
      }
    }
    this.rolesChanged();
  }
    }
    }
  }
</script>

<style></style>