var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr'),_c('h4',{staticClass:"my-3"},[_vm._v("Filters")]),_c('b-checkbox',{model:{value:(_vm.includeDeleted),callback:function ($$v) {_vm.includeDeleted=$$v},expression:"includeDeleted"}},[_vm._v("Include deleted items")]),_c('b-checkbox',{model:{value:(_vm.includeFiles),callback:function ($$v) {_vm.includeFiles=$$v},expression:"includeFiles"}},[_vm._v("Only include items with files")]),_c('b-checkbox',{model:{value:(_vm.includeShared),callback:function ($$v) {_vm.includeShared=$$v},expression:"includeShared"}},[_vm._v("Only include items with shared content")]),_c('b-checkbox',{model:{value:(_vm.includeReferences),callback:function ($$v) {_vm.includeReferences=$$v},expression:"includeReferences"}},[_vm._v("Only include items with references")]),_c('b-checkbox',{model:{value:(_vm.includeEnemies),callback:function ($$v) {_vm.includeEnemies=$$v},expression:"includeEnemies"}},[_vm._v("Only include items with enemies")]),_c('br'),_vm._l((_vm.filters),function(filter,index){return _c('b-card',{key:index,staticClass:"mt-2",attrs:{"bg-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"py-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b-select',{attrs:{"options":_vm.options,"value":filter.field_id},on:{"change":function($event){return _vm.filterChanged(arguments, index)}}})],1),_c('b-col',{attrs:{"cols":"9","sm":"10","md":"5","lg":"6"}},[(filter.field_id)?_c('div',[(
                _vm.fieldIs(filter.field_id, ['created_dates', 'edited_dates'])
              )?_c('div',[_c('label',[_vm._v("Start Date")]),_c('b-form-datepicker',{staticClass:"mb-2",model:{value:(filter.field_value.start_date),callback:function ($$v) {_vm.$set(filter.field_value, "start_date", $$v)},expression:"filter.field_value.start_date"}}),_c('label',[_vm._v("End Date")]),_c('b-form-datepicker',{staticClass:"mb-2",model:{value:(filter.field_value.end_date),callback:function ($$v) {_vm.$set(filter.field_value, "end_date", $$v)},expression:"filter.field_value.end_date"}})],1):_vm._e(),(_vm.fieldIs(filter.field_id, ['text']))?_c('b-input',{attrs:{"type":"text","placeholder":"Text"},model:{value:(filter.field_value),callback:function ($$v) {_vm.$set(filter, "field_value", $$v)},expression:"filter.field_value"}}):_vm._e(),(_vm.fieldIs(filter.field_id, ['number']))?_c('b-input',{attrs:{"type":"number","placeholder":"Number"},model:{value:(filter.field_value),callback:function ($$v) {_vm.$set(filter, "field_value", _vm._n($$v))},expression:"filter.field_value"}}):_vm._e(),(
                _vm.fieldIs(filter.field_id, [
                  'select_one',
                  'select_multi',
                  'item_type',
                  'user',
                  'form_ids'
                ]) && _vm.subOptions[filter.field_id]
              )?_c('v-select',{staticStyle:{"backgroundColor":"white"},attrs:{"multiple":_vm.fieldIs(filter.field_id, [
                  'select_one',
                  'select_multi',
                  'item_type',
                  'user',
                  'form_ids'
                ]),"options":_vm.subOptions[filter.field_id],"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.disabled; },"label":"text","placeholder":"Select"},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                var option = ref.option;
                var deselect = ref.deselect;
return [_c('span',{class:("vs__selected border " + (option.color ? 'pl-0' : ''))},[(option.color)?_c('div',{staticClass:"mr-1",style:({
                      backgroundColor: option.color,
                      width: '15px',
                      height: '100%',
                      borderRadius: '3px 0 0 3px'
                    })}):_vm._e(),_vm._v(" "+_vm._s(option.text)+" "),_c('button',{staticClass:"vs__deselect no-focus",style:({ color: 'rgba(60, 60, 60, 0.5)' }),attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return deselect(option)},"mousedown":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]}},{key:"option",fn:function(ref){
                    var color = ref.color;
                    var text = ref.text;
return [(color)?_c('div',{staticClass:"option p-1 d-inline-block",style:({ background: color })}):_vm._e(),_vm._v(" "+_vm._s(text)+" ")]}}],null,true),model:{value:(filter.field_value),callback:function ($$v) {_vm.$set(filter, "field_value", $$v)},expression:"filter.field_value"}}):_vm._e()],1):_vm._e()]),_c('b-col',{attrs:{"cols":"3","sm":"2","lg":"1"}},[_c('b-button',{attrs:{"variant":"white"},on:{"click":function($event){return _vm.removeFilter(index)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1)],1)],1)],1)}),_c('b-row',{staticClass:"my-3"},[_c('b-col',[_c('b-button',{attrs:{"size":"sm","variant":"white"},on:{"click":_vm.addFilter}},[_vm._v("Add a filter")])],1),(!_vm.onlyCreateFilters)?_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"white"},on:{"click":_vm.clearFilters}},[_vm._v("Clear filters")])],1):_vm._e()],1),_c('b-row',{staticClass:"my-3"},[(!_vm.onlyCreateFilters)?_c('b-col',[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"primary-light"},on:{"click":_vm.applyFilters}},[_vm._v("Apply filters")])],1):_vm._e()],1),_c('hr'),(_vm.items.length && _vm.showPreview)?_c('div',{staticClass:"mb-3 mt-3"},[_vm._m(0),_c('b-select',{style:({ minHeight: '250px' }),attrs:{"options":_vm.items,"id":"results","multiple":"","scroll-box":""},on:{"change":_vm.handleItemIdsChanged},model:{value:(_vm.selectedItemIds),callback:function ($$v) {_vm.selectedItemIds=$$v},expression:"selectedItemIds"}})],1):_vm._e(),(_vm.noItems)?_c('div',{staticClass:"mt-3"},[_c('strong',{staticClass:"text-warning"},[_vm._v("No items found.")])]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"results"}},[_c('h4',[_vm._v("Filter Results")])])}]

export { render, staticRenderFns }