<template>
  <div>
    <ConfigurationFields
      :configuration="editableConfiguration"
      :editable="editable"
      :key="editableConfiguration.id"
      :invalid-fields="invalidFields"
    />

    <b-modal
      @ok="onSaveConfiguration()"
      id="parent-config-changes-modal"
      size="xl"
      title="Changes To Other Configurations"
      no-fade
    >

      <div class="mb-2">
        The following field changes will affect other configurations. Save anyway?
      </div>

      <b-table
        :fields="fields"
        :items="changes"
        bordered
        hover
        responsive
        small
        striped
      />

      <template #modal-footer="{ ok, close }">
        <b-button @click="close" variant="white">
          Cancel
        </b-button>

        <b-button @click="ok" variant="secondary">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SEI_API_BASE, CONFIGURATION_KEYS, CONFIGURATION_SECURITY_OPTION_KEYS, CONFIGURATION_ADAPTIVE_KEYS, CONFIGURATION_LINEAR_KEYS, CONFIGURATION_MARK_AND_REVIEW_KEYS } from '../../utils/constants'
import { EVENT } from '../../utils/event-bus'
import { deepCopy, removeEmptyKeys, arrayEquality } from '../../utils/misc'
import { HTTP } from '../../utils/requests'
import { SESSION } from '../../utils/session'
import isEqual from 'lodash.isequal'

import ConfigurationFields from './ConfigurationFields'

async function saveSameConfigurationRequest(projectId, configuration) {
  try {
    const url = `${SEI_API_BASE}/exams/${projectId}/configurations/${configuration.id}`
    const payload = { ...configuration }
    const response = await HTTP.put(url, payload)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

async function saveNewConfigurationRequest(projectId, configuration) {
  try {
    const url = `${SEI_API_BASE}/exams/${projectId}/configurations`
    const payload = { ...configuration }
    const response = await HTTP.post(url, payload)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

async function deleteConfigurationRequest (configurationId) {
  try {
    const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/configurations/${ configurationId }`
    const response = await HTTP.delete(url)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

function copyAndRemoveEmptyKeys (configuration) {
  const copy = deepCopy(configuration)

  removeEmptyKeys(copy.settings)

  return copy
}

const KEY_TO_LABEL = {
  instructions: 'Instructions',
  proctor_code: 'Proctor Code',
  start_paused: 'Start the exam in a paused state',
  suspicious_event_threshold: 'Suspicious event threshold',
  block_screen: 'Block screen on certain keys',
  copy_paste: 'Block copy paste',
  proctor_setting_overrides: 'Proctor setting overrides',
  fresh_token_each_launch: 'Generate fresh token each launch',
  allow_selection: 'Block text selection',
  time_limit: 'Exam Time Limit (seconds)',
  mode: 'Exam Mode',
  selected_languages: 'Available Languages',
  default_language: 'Default Language',
  score_scale_lower: 'Score Scale Lower',
  score_scale_upper: 'Score Scale Upper',
  cutscores: 'Cutscores',
  content_area_split_char: 'Content Area Split',
  pause_resume: 'Allow users to pause and resume their own exam',
  feedback_enabled: 'Show item feedback after each page',
  exam_review_enabled: 'Show item feedback at the end of exam',
  calculator: 'Calculator',
  comments: 'Allow users to submit comments on items',
  mark_and_review: 'Allow users to go back and change answers',
  prevent_section_mark_and_review: 'Prevent mark and review between sections',
  enable_sections: 'Enable section timers and navigation',
  end_early: 'End the test early when a passing or failing score is determined',
  min_items: 'Min Items',
  max_items: 'Max Items',
  max_error: 'Max Error',
  score_ranges: 'Content Area Breakdown Text',
  score_report_content: 'Score Report Content',
  score_report_preface: 'Score Report Preface',
  score_report_pass_message: 'Score Report Pass Message',
  score_report_fail_message: 'Score Report Fail Message',
  show_score: 'Show Scaled Score',
  show_total_score: 'Show Total Score',
  show_pass_fail: 'Show Pass/Fail Decision',
  show_cutscore: 'Show Cutscore',
  show_print_button: 'Show Print Button',
  show_email_button: 'Show Email Button',
  show_score_scale: 'Show Score Scale',
  show_content_area_score: 'Show Content Area Breakdown Score',
  show_content_area_breakdown: 'Show Content Area Breakdown Percentage',
  show_content_area_breakdown_range_text: 'Show Content Area Breakdown Text',
  score_display_type: 'Score display type',
  feedback_hide_key: 'Hide correct responses on feedback page',
  adjust_time_after_disconnect: 'Adjust time after a user disconnects'
}

export default {
  name: 'ConfigurationEditor',
  components: {
    ConfigurationFields
  },
  props: {
    configuration: {
      type: Object
    },
    configurations: {
      type: Array
    },
    editOnly: {
      type: Boolean,
      default: false
    }
  },
  created() {
    EVENT.$on('save-configuration', this.beforeSaveConfiguration)
    EVENT.$on('delete-configuration', this.onDeleteConfiguration)

    this.editableConfiguration = deepCopy(this.configuration)
    this.unchangedConfiguration = deepCopy(this.configuration)
    this.editable = this.editOnly
    this.originalConfigName = this.editableConfiguration.name
    this.defaultConfigId = this.configurations.find(config => config.is_default).id
  },
  beforeDestroy () {
    EVENT.$off('save-configuration')
    EVENT.$off('delete-configuration')
  },
  data() {
    return {
      editableConfiguration: {},
      editable: false,
      originalConfigName: null,
      defaultConfigId: null,
      changes: [],
      fields: [{ key: 'field' }, { key: 'count', label: 'Configurations Affected' }],
      invalidFields: {}
    }
  },
  methods: {
    onConfigurationIsEditable(isEditable) {
      if (!this.editOnly) {
        this.editable = isEditable
      }

      if (!this.editable) {
        const configuration = this.configurations.find(
          (config) => config.id === this.editableConfiguration.id
        )
        this.editableConfiguration = deepCopy(configuration)
      }
    },
    onConfigurationSelected(configurationId) {
      const configuration = this.configurations.find(
        (config) => config.id === configurationId
      )
      this.editableConfiguration = deepCopy(configuration)
      this.originalConfigName = this.editableConfiguration.name
      this.$emit('configuration-changed', false, configuration)

      this.configurationSelected(configurationId)
    },
    checkForEmptyValues () {
      const config = copyAndRemoveEmptyKeys(this.editableConfiguration)

      const TOP_LEVEL_KEYS = [ ...CONFIGURATION_KEYS ]

      if (config.settings.mode === 'adaptive') {
        TOP_LEVEL_KEYS.push(...CONFIGURATION_ADAPTIVE_KEYS)
      } else {
        TOP_LEVEL_KEYS.push(...CONFIGURATION_LINEAR_KEYS)

        if (config.settings.mark_and_review) {
          TOP_LEVEL_KEYS.push(...CONFIGURATION_MARK_AND_REVIEW_KEYS)
        }
      }

      let missingKey = false

      for (const key of CONFIGURATION_KEYS) {
        if (!(key in config.settings)) {
          missingKey = true

          break
        }
      }

      if (!missingKey) {
        for (const key of CONFIGURATION_SECURITY_OPTION_KEYS) {
          const securityOptions = config.settings.security_options || {}

          if (!(key in securityOptions)) {
            missingKey = true

            break
          }
        }
      }

      return missingKey
    },
    getChangedKeys () {
      const changedKeys = []

      const changedSecurityOptions = []

      const skipKeys = new Set(['security_options', 'selected_languages', 'cutscores', 'score_ranges'])

      for (const [ key, value ] of Object.entries(this.editableConfiguration.settings)) {
        if (skipKeys.has(key)) continue

        if (value != this.unchangedConfiguration.settings[key]) {
          changedKeys.push(key)
        }
      }

      for (const [ key, value ] of Object.entries(this.editableConfiguration.settings.security_options)) {
        if (value != this.unchangedConfiguration.settings.security_options[key]) {
          changedSecurityOptions.push(key)
        }
      }

      if (!arrayEquality(this.editableConfiguration.settings.selected_languages, this.unchangedConfiguration.settings.selected_languages)) {
        changedKeys.push('selected_languages')
      }

      let cutscoreChanged = false

      for (const [ index, cutscore ] of this.editableConfiguration.settings.cutscores.entries()) {
        const originalCutscore = this.unchangedConfiguration.settings.cutscores[index] || {}

        if (!isEqual(cutscore, originalCutscore)) {
          cutscoreChanged = true

          break
        }
      }

      if (cutscoreChanged || this.editableConfiguration.settings.cutscores.length !== this.unchangedConfiguration.settings.cutscores.length) {
        changedKeys.push('cutscores')
      }

      let scoreRangeChanged = false

      for (const [ index, scoreRange ] of this.editableConfiguration.settings.score_ranges.entries()) {
        const originalScoreRange = this.unchangedConfiguration.settings.score_ranges[index] || {}

        if (!isEqual(scoreRange, originalScoreRange)) {
          scoreRangeChanged = true

          break
        }
      }

      if (scoreRangeChanged || this.editableConfiguration.settings.score_ranges.length !== this.unchangedConfiguration.settings.score_ranges.length) {
        changedKeys.push('score_ranges')
      }

      return { changedKeys, changedSecurityOptions }
    },
    getChangedConfigs (keys) {
      const { changedKeys, changedSecurityOptions } = keys

      const countLookup = {}

      const nonDefaultConfigs = this.configurations.filter(c => !c.is_default)

      for (const config of nonDefaultConfigs) {
        for (const key of changedKeys) {
          if (!(key in config.settings)) {
            const label = KEY_TO_LABEL[key]

            if (countLookup[label]) {
              countLookup[label]++
            } else {
              countLookup[label] = 1
            }
          }
        }

        for (const key of changedSecurityOptions) {
          const securityOptions = config.settings.security_options || {}

          if (!(key in securityOptions)) {
            const label = KEY_TO_LABEL[key]

            if (countLookup[label]) {
              countLookup[label]++
            } else {
              countLookup[label] = 1
            }
          }
        }
      }

      const changes = []

      for (const [ key, value ] of Object.entries(countLookup)) {
        changes.push({ field: key, count: value })
      }

      return changes
    },
    beforeSaveConfiguration (saveNew) {
      const invalidFields = {}

      if (this.editableConfiguration.is_default) {
        if (!this.editableConfiguration.settings.score_scale_lower && this.editableConfiguration.settings.score_scale_lower !== 0) {
          invalidFields.score_scale_lower = false
        }

        if (!this.editableConfiguration.settings.score_scale_upper && this.editableConfiguration.settings.score_scale_upper !== 0) {
          invalidFields.score_scale_upper = false
        }

        if (!this.editableConfiguration.settings.security_options.suspicious_event_threshold && this.editableConfiguration.settings.security_options.suspicious_event_threshold !== 0) {
          invalidFields.suspicious_event_threshold = false
        }

        if (!this.editableConfiguration.settings.time_limit && this.editableConfiguration.settings.time_limit !== 0) {
          invalidFields.time_limit = false
        }

        if (!this.editableConfiguration.settings.default_language) {
          invalidFields.default_language = false
        }

        if (!this.editableConfiguration.settings.selected_languages?.length) {
          invalidFields.selected_languages = false
        }
      }

      if (this.editableConfiguration.settings.score_ranges) {
        for (const [ index, scoreRange ] of this.editableConfiguration.settings.score_ranges.entries()) {
            if (!scoreRange.start && scoreRange.start !== 0) {
            try {
                invalidFields.scoreRangeStart[index] = false
            } catch (error) {
                invalidFields.scoreRangeStart = { [index]: false }
            }
            }

            if (!scoreRange.end && scoreRange.end !== 0) {
            try {
                invalidFields.scoreRangeEnd[index] = false
            } catch (error) {
                invalidFields.scoreRangeEnd = { [index]: false }
            }
            }

            if (!scoreRange.text) {
            try {
                invalidFields.scoreRangeText[index] = false
            } catch (error) {
                invalidFields.scoreRangeText = { [index]: false }
            }
          }
        }
      }

      if (this.editableConfiguration.settings.cutscores) {
        for (const [ index, cutscore ] of this.editableConfiguration.settings.cutscores.entries()) {
            if (!cutscore.name) {
            try {
                invalidFields.cutscoreNames[index] = false
            } catch (error) {
                invalidFields.cutscoreNames = { [index]: false }
            }
            }

            if (!cutscore.score && cutscore.score !== 0) {
            try {
                invalidFields.cutscoreScores[index] = false
            } catch (error) {
                invalidFields.cutscoreScores = { [index]: false }
            }
          }
        }
      }

      this.invalidFields = invalidFields

      if (Object.keys(invalidFields).length) {
        this.$nextTick(() => {
          const firstInvalidElement = document.querySelector('.is-invalid')

          firstInvalidElement.scrollIntoView()

          EVENT.alert({
            variant: 'danger',
            message: 'Please fill in the highlighted fields.'
          })
        })

        return
      }

      if (this.editableConfiguration.is_default) {
        this.checkParentConfig()

        return
      }

      this.checkChildConfig(saveNew)

    },
    async checkParentConfig () {
      const changedKeys = this.getChangedKeys()

      const changedConfigs = this.getChangedConfigs(changedKeys)

      if (changedConfigs.length) {
        this.changes = changedConfigs

        this.$bvModal.show('parent-config-changes-modal')

        return
      }

      this.onSaveConfiguration()
    },
    async checkChildConfig (saveNew) {
      const missingKey = this.checkForEmptyValues()

      if (missingKey) {
          const message = 'This configuration has blank fields and will use the values on the parent configuration. Save anyway?'

          const options = {
            title: 'Confirm',
            okVariant: 'secondary',
            okTitle: 'Save',
            cancelVariant: 'white',
            size: 'sm',
            noFade: true
          }

          const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

          if (!confirmed) return
      }

      this.onSaveConfiguration(saveNew)
    },
    async onSaveConfiguration(saveNew) {
      if (saveNew) {
        return this.saveNewConfiguration()
      }

      this.saveSameConfiguration()
    },
    async saveSameConfiguration() {
      this.$emit('configuration-save-state', true)

      if (this.originalConfigName != this.editableConfiguration.name && this.configNames.indexOf(this.editableConfiguration.name) > 0) {
        this.$emit('configuration-save-state', false)

        EVENT.alert({
          variant: 'danger',
          message: `The name "${this.editableConfiguration.name}" is already taken.`
        })
        return
      }

      const { data, error } = await saveSameConfigurationRequest(
        SESSION.project.id,
        copyAndRemoveEmptyKeys(this.editableConfiguration)
      )

      this.$emit('configuration-save-state', false)

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save configuration.'
        })
      }

      const index = this.configurations.findIndex(
        (config) => config.id === data.id
      )

      this.configurations.splice(index, 1, data) // eslint-disable-line vue/no-mutating-props
      this.$emit('configuration-changed', false, data)

      if (!this.editOnly) {
        this.editable = false
      }

      this.$emit('save-form')
    },
    async saveNewConfiguration() {
      this.$emit('configuration-save-state', true)

      if (this.configNames.indexOf(this.editableConfiguration.name) > -1) {
        this.$emit('configuration-save-state', false)

        EVENT.alert({
          variant: 'danger',
          message: `The name "${this.editableConfiguration.name}" is already taken.`
        })
        return
      }

      const { data, error } = await saveNewConfigurationRequest(
        SESSION.project.id,
        copyAndRemoveEmptyKeys(this.editableConfiguration)
      )

      this.$emit('configuration-save-state', false)

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save as a new configuration.'
        })
      }

      this.configurations.push(data) // eslint-disable-line vue/no-mutating-props
      this.$emit('configuration-changed', false, data)

      this.editableConfiguration = deepCopy(data)

      this.configurationSelected(data.id)

      this.originalConfigName = this.editableConfiguration.name

      if (!this.editOnly) {
        this.editable = false
      }

      this.$emit('save-form')
    },
    async onDeleteConfiguration () {
      this.$emit('configuration-save-state', true)

      const configId = this.editableConfiguration.id

      const { error } = await deleteConfigurationRequest(configId)

      this.$emit('configuration-save-state', false)

      if (error) {
        const message = error.response.data?.messages?.[0] || 'Failed to delete configuration'

        return EVENT.alert({
          variant: 'danger',
          message
        })
      }

      this.$emit('configuration-deleted', configId)

      this.$bvModal.hide('edit-configuration-modal')
    },
    configurationSelected(configurationId) {
      this.$emit('configuration-selected', configurationId)
    }
  },
  computed: {
    configNames() {
      return this.configurations.map(config => {
        return config.name
      })
    }
  },
  watch: {
    editableConfiguration: {
      deep: true,
      handler(changedConfig) {
        const oldConfig = deepCopy(this.configuration)
        const newConfig = deepCopy(changedConfig)
        removeEmptyKeys(oldConfig.settings)
        removeEmptyKeys(newConfig.settings)
        if (!isEqual(oldConfig, newConfig)) {
          this.$emit('configuration-changed', true)
        } else {
          this.$emit('configuration-changed', false)
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
