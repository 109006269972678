<template>
  <b-container class="mt-3" fluid>
    <b-form id="app-settings-form" ref="form" novalidate>
      <b-form-group
        label="Name *"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="name-input"
      >
        <b-form-input
          id="name-input"
          required
          type="text"
          v-model="fields.first_name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Redirect URL"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="redirect-url-input"
      >
        <b-input-group prepend="https://">
          <b-form-input
            id="redirect-url-input"
            type="url"
            v-model="formattedUrl"
            v-bind:class="{ error: !redirectUrlValid }"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="description-input"
      >
        <b-form-input
          id="description-input"
          type="text"
          v-model="fields.app_description"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Image URL"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="image-url-input"
      >
        <b-form-input
          id="image-url-input"
          type="url"
          v-model="fields.profile_picture"
        ></b-form-input>
      </b-form-group>
    </b-form>
  </b-container>
</template>

<script>
import { VALIDATE } from '../../utils/validate'
import { URL_REGEX } from '../../utils/constants'

export default {
  name: 'Settings',
  created() {
    this.setFields()
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: {},
      redirectUrlValid: true
    }
  },
  methods: {
    setFields() {
      const { first_name, app_description, app_redirect_url, profile_picture } = this.app

      this.fields.first_name = first_name || ''
      this.fields.app_description = app_description || ''
      this.fields.app_redirect_url = app_redirect_url || ''
      this.fields.profile_picture = profile_picture || ''
    },
    report() {
      this.redirectUrlValid = URL_REGEX.test(this.fields.app_redirect_url)

      const isValid = VALIDATE.validateFields(this.$refs.form, true)

      const data = { ...this.fields }

      if (data.app_redirect_url === 'https://') {
        data.app_redirect_url = null

        this.redirectUrlValid = true
      }

      const error = !isValid

      this.$emit('report', data, null, error)
    }
  },
  computed: {
    formattedUrl: {
      get: function () {
        return this.fields.app_redirect_url.replace('https://', '')
      },
      set: function (url) {
        this.fields.app_redirect_url = 'https://' + url.replace('https://', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  border-color: rgb(216, 106, 3);
  box-shadow: rgb(216, 106, 3) 0px 0px 2px;
}
</style>
