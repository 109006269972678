<template>
  <div>
    <b-navbar sticky variant="light">
      <div class="w-100">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                :disabled="isSaving"
                variant="secondary"
                @click="beforeSaveApp"
              >
                <b-spinner
                  label="Small Spinner"
                  small
                  v-show="isSaving"
                ></b-spinner
                >&nbsp;Save
              </b-button>
              <ApiCredentials :app-id="app.caveon_id" class="ml-2" />
            </b-button-group>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="ml-2"
              variant="white"
              :disabled="isDeleting"
              v-b-modal.delete-app-modal
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isDeleting"
              ></b-spinner>
              &nbsp;Delete this app
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-navbar>
    <General :app="app" @report="onReport" ref="settings" class="" />
    <div class="container-fluid">
      <b-card no-body class="mb-3">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.content-accordion
            variant="primary"
            class="text-left"
            >Content</b-button
          >
        </b-card-header>
        <b-collapse id="content-accordion" visible role="tabpanel">
          <b-card-body>
            <Content :app="app" @report="onReport" ref="content" />
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-3">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.hooks-accordion
            variant="primary"
            class="text-left"
            >Hooks</b-button
          >
        </b-card-header>
        <b-collapse id="hooks-accordion" visible role="tabpanel">
          <b-card-body>
            <Hooks :app="app" @report="onReport" ref="hooks" />
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-3">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.permissions-accordion
            variant="primary"
            class="text-left"
            >Permissions</b-button
          >
        </b-card-header>
        <b-collapse id="permissions-accordion" visible role="tabpanel">
          <b-card-body>
            <Permissions :app="app" @report="onReport" ref="permissions" />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <b-modal
      @ok="confirmDeleteApp()"
      id="delete-app-modal"
      size="xl"
      title="Delete App"
    >
      Are you sure you want to permanently delete this app?
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button
            :disabled="isDeleting"
            @click="cancel()"
            variant="white"
            >Cancel</b-button
          >
          <b-button
            :disabled="isDeleting"
            @click="ok()"
            variant="warning"
          >
            Delete
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import ApiCredentials from './ApiCredentials.vue'
  import General from './General.vue'
  import Content from './Content.vue'
  import Hooks from './Hooks.vue'
  import Permissions from './Permissions.vue'

  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy } from '../../utils/misc'

  async function saveApp(app) {
    try {
      const url = `${SEI_API_BASE}/apps/${app.caveon_id}`
      const response = await HTTP.put(url, app)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function deleteApp(app) {
    try {
      const url = `${SEI_API_BASE}/apps/${app.caveon_id}`
      const response = await HTTP.delete(url, app)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Settings',
    components: {
      ApiCredentials,
      General,
      Content,
      Hooks,
      Permissions
    },
    props: {
      myApp: {
        type: Object
      }
    },
    created() {
      this.app = deepCopy(this.myApp)
    },
    data() {
      return {
        app: null,
        preSaveError: false,
        isSaving: false,
        isDeleting: false
      }
    },
    methods: {
      async saveApp() {
        this.isSaving = true

        const { data, error } = await saveApp(this.app)

        this.isSaving = false

        if (error) {
          if (error?.response?.data?.messages) {
            const msg = error.response.data.messages.join(', ')
            return EVENT.alert({
              variant: 'danger',
              message: msg
            })
          }
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save! Please try again.'
          })
        }

        this.app = data

        this.$emit('app-updated', this.app)
      },
      beforeSaveApp() {
        this.preSaveError = false

        for (const ref of Object.values(this.$refs)) {
          if (ref.report) ref.report()
        }

        if (this.preSaveError) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Please fill in the highlighted fields'
          })
        }

        this.saveApp()
      },
      async confirmDeleteApp() {
        this.isDeleting = true

        const { error } = await deleteApp(this.app)

        this.isDeleting = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete app.'
          })
        }

        this.backToApps()
      },
      backToApps() {
        this.$router.push({ name: 'apps' })
      },
      onReport(data, property, error) {
        if (error) {
          return (this.preSaveError = true)
        }

        if (!property) {
          return (this.app = { ...this.app, ...data })
        }

        this.app.app_settings[property] = data
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
