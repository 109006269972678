<template>
  <div v-if="display" class="border-top pt-2">
    <h3 class="d-inline">
      {{ role.title }}
      <b-button
        :disabled="shouldDisable"
        @click.stop="openAddRole"
        class="mb-2 ml-2"
        size="sm"
        v-if="role.isAdd"
        variant="primary-light"
        >Add</b-button
      ><i style="font-size: .5em;" class="float-right mt-2" v-else
        >This is a legacy role and adding to it is no longer supported</i
      >
    </h3>
    <p>{{ role.description }}</p>
    <div class="py-2">
      <b-table
        :fields="fields"
        :items="roleUsers"
        hover
        responsive
        small
        striped
        bordered
        v-if="role.users.length"
        @row-clicked="(row, index) => rowClicked(role, row, index)"
      >
        <template v-slot:table-colgroup="scope">
          <col
            :key="field.key"
            style="width=auto"
            v-for="field in scope.fields"
          />
        </template>
        <template v-slot:cell(remove)="scope">
          <b-button
            :disabled="role.isDisableDeleteBtn || isLastAdmin(role) || shouldDisable"
            @click="removeRole(scope.item.id)"
            class="float-right px-1 py-0 rounded"
            variant="white"
          >
            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import get from 'lodash.get'
  import { DateTimeParser } from '../../utils/date-time-parser'

  import { SESSION } from '../../utils/session'

  function decodeMetaRestrictions(project, restrictions) {
    let isInvalid = false

    const custom = get(project, 'meta.scorpion.custom')

    if (!restrictions) {
      return
    }

    if (!custom) {
      return
    }

    let result = []
    for (const [key, values] of restrictions) {
      const field = custom[key]

      if (!field) continue

      if (field.type.startsWith('select_')) {
        const options = []

        for (const value of values) {
          if (!(key in custom) || !(value in custom[key].options)) {
            options.push('Invalid Restriction')

            isInvalid = true

            continue
          }

          options.push(custom[key].options[value].text)
        }

        result = [...result, ...options]
      } else {
        result = [...result, ...values]
      }
    }

    return { restrictions: result.join(', '), isInvalid }
  }

  export default {
    name: 'AccessRole',
    props: {
      role: {
        type: Object,
        required: true
      },
      expand: {
        type: Boolean
      },
      project: {
        type: Object
      }
    },
    data() {
      return {
        dropdownId: '',
        fields: [
          { key: 'first_name', label: 'First Name', sortable: true },
          { key: 'last_name', label: 'Last Name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          {
            key: 'type',
            label: 'Type',
            sortable: true,
            formatter: value => (value === 'placeholder' ? '' : value)
          },
          {
            key: 'expires_at',
            label: 'Expires',
            formatter: expires_at => {
              return this.dateTimeParser.displayExpirationLocal(expires_at)
            }
          },
          { key: 'remove', label: '' }
        ],
        shouldDisable: false
      }
    },
    created() {
      this.dropdownId = 'dropdown-' + this._uid

      if (this.role.type === 'workshop') {
        this.fields.splice(4, 0, {
          key: 'meta_restrictions',
          label: 'Restrictions'
        })
      }

      if (this.role.type === 'proctor') {
        this.fields.splice(4, 0, {
          key: 'tag_restrictions',
          label: 'Tag Restrictions'
        })
      }

      if (SESSION.isAppAlias && this.role.type === 'admin') {
        this.shouldDisable = true
      }

      this.dateTimeParser = DateTimeParser(this.$moment)
    },
    methods: {
      removeRole(roleId) {
        this.$emit('remove-role', roleId, this.role.type)
      },
      openAddRole() {
        this.$emit('open-add-role', this.role.type)
      },
      isLastAdmin(role) {
        return role.title === 'Administrator' && role.users.length === 1
      },
      rowClicked(roleType, row) {
        if (this.shouldDisable) {
          return
        }

        const user = roleType.users.find(u => u.id === row.id)

        user.is_invalid = row.is_invalid

        this.$emit('edit-role', this.role.type, user)
      }
    },
    computed: {
      roleUsers() {
        let roleUsers = []
        for (const user of this.role.users) {
          const metaRestrictions = decodeMetaRestrictions(this.project, user.meta_restrictions)

          let roleUser = {
            first_name: '',
            last_name: '',
            email: '',
            type: '',
            id: user.id,
            meta_restrictions: metaRestrictions?.restrictions || '',
            is_invalid: metaRestrictions?.isInvalid,
            tag_restrictions: user.tag_restrictions?.join(', '),
            expires_at: user.expires_at
          }

          if (metaRestrictions?.isInvalid) {
            roleUser._rowVariant = 'danger'
          }

          if (user.user) {
            roleUser.first_name = user.user.first_name
            roleUser.last_name = user.user.last_name
            roleUser.email = user.user.email
            roleUser.type = user.user.type
          } else if (user.group) {
            roleUser.first_name = user.group.name
            roleUser.type = 'group'
          }
          roleUsers.push(roleUser)
        }
        return roleUsers
      },
      display() {
        return this.role.isAdd || this.role.users.length
      }
    }
  }
</script>

<style lang="scss" scoped></style>
