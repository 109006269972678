import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'

import VueMoment from 'vue-moment'

import VueSelect from 'vue-select'

import TextareaAutosize from 'vue-textarea-autosize'

import MarkdownIt from 'markdown-it'
import MarkdownItContainer from 'markdown-it-container';
import MarkdownItKatex from 'markdown-it-katex';
import hljs from 'highlight.js';
import '../src/styles/highlight.css'

import BootstrapVue from 'bootstrap-vue'
import './styles/app.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', VueSelect)

Vue.use(VueMoment)
Vue.use(BootstrapVue)
Vue.use(TextareaAutosize)

const cavMarkdown = new MarkdownIt({
  breaks: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (err) {
        console.warn(err)
      }
    }

    return ''; // use external default escaping
  }
});

cavMarkdown.use(MarkdownItKatex);
cavMarkdown.use(MarkdownItContainer, 'html5video', {
  validate: function(params) {
    return params.trim().match(/^html5video\s+(.*)$/)
  },
  render: function(tokens, idx) {
    const blockDeclaration = tokens[idx].info.trim().match(/^html5video\s+(.*)$/);
    if (tokens[idx].nesting === 1) {
      return '<div class="videoBlock"><video src="' + blockDeclaration[1] + '" controls width="100%"></video>';
    }
    return '</div>\n';
  }
});
cavMarkdown.use(MarkdownItContainer, 'html5audio', {
  validate: function(params) {
    return params.trim().match(/^html5audio\s+(.*)$/);
  },
  render: function(tokens, idx) {
    const blockDeclaration = tokens[idx].info.trim().match(/^html5audio\s+(.*)$/);

    if (tokens[idx].nesting === 1) {
      return '<div class="audioBlock"><audio src="' + blockDeclaration[1] + '" controls></audio>';
    }
    return '</div>\n';
  }
});

cavMarkdown.use(MarkdownItContainer, 'iframe', {
  validate: function(params) {
    return params.trim().match(/^iframe\s+(.*)$/);
  },
  render: function(tokens, idx) {
    if (tokens[idx].nesting === 1) {
      var passed = tokens[idx].info.trim().split(" ")
      return '<div class="iframeBlock" style="width: ' + (passed[2] ? passed[2] : 'auto') + '; height: ' + (passed[3] ? passed[3] : '500px') + '"><iframe src="' + passed[1] + '" width="100%" height="100%"></iframe>';
    }
    return '</div>\n';
  }
});

Vue.directive('markdown', {
  bind: function(element, binding) {
    element.innerHTML = cavMarkdown.render(binding.value)
  },
  update: function(element, binding) {
    element.innerHTML = cavMarkdown.render(binding.value)
  }
})

Vue.directive('direction', (el, binding) => {
  const direction = binding.value === 'rtl' ? 'rtl' : 'ltr'

  el.style.direction = direction
})

const router = createRouter()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
