<template>
  <b-tab :active="active" title="Shared Content">
    <b-modal
      id="shared-content-modal"
      size="md"
      :title="`${ newSharedContent ? 'Create' : 'Edit' } Shared Content`"
      @ok.prevent="saveSharedContent"
      @hidden="previewContent(true)"
    >
      <div v-if="!preview">
        <label for="shared-content-name">Name</label>
        <b-input
          class="mb-2"
          placeholder="Name"
          id="shared-content-name"
          v-model="editing.name"
          autofocus
        ></b-input>
        <label for="shared-content-textarea">Content</label>
        <textarea-autosize
          id="shared-content-textarea"
          placeholder="Enter content"
          rows="1"
          :min-height="37"
          v-model="editing.content"
          class="mb-2 form-control"
        />
      </div>
      <div v-else v-markdown="editing.content" class="break-word"></div>
      <template #modal-footer="{ cancel, ok }">
        <div class="w-100">
          <b-button variant="white" @click="previewContent()">{{ previewButtonText }}</b-button>
          <b-button @click="ok" variant="secondary" :disabled="!editing.name || !editing.content || saving" class="float-right ml-2">
            <b-spinner small v-if="saving" />
            {{ newSharedContent ? 'Create' : 'Save' }} Shared Content
          </b-button>
          <b-button @click="cancel" variant="white" :disabled="saving" class="float-right">Cancel</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="delete-shared-content-modal"
      size="sm"
      title="Delete Shared Content"
      @ok.prevent="deleteSharedContent"
    >
      Are you sure you would like to delete: <mark :style="{ borderRadius: '3px', backgroundColor: 'rgb(244, 245, 247)' }">{{ editing.name }}</mark>?
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="white" :disabled="saving">Cancel</b-button>
        <b-button @click="ok" variant="danger" :disabled="saving">
          <b-spinner small v-if="saving" />
          Delete Shared Content
        </b-button>
      </template>
    </b-modal>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <b-button
        :disabled="shouldDisable('create_items')"
        @click="addSharedContent()"
        variant="primary-light"
        >Add Shared Content</b-button
      >
      <b-pagination
        v-if="safeSharedContent.length > perPage"
        v-model="page"
        :per-page="perPage"
        :total-rows="safeSharedContent.length"
      ></b-pagination>
    </div>

    <b-table
      :fields="fields"
      :items="safeSharedContent"
      :current-page="page"
      :per-page="perPage"
      responsive
      small
      striped
      fixed
      sort-icon-left
      sort-by="name"
      v-if="safeSharedContent.length"
    >
      <template v-slot:cell(actions)="row">
        <b-button-group class="float-right">
          <b-button :disabled="shouldDisable('edit_items')" @click="editSharedContent(row)" size="sm" variant="white"
            >Edit</b-button
          >
          <b-button :disabled="shouldDisable('delete_items')" @click="beforeDeleteShared(row)" size="sm" variant="white">
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-button-group>
      </template>
    </b-table>
  </b-tab>
</template>

<script>
  import { SEI_API_BASE } from '../../utils/constants'
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import { deepCopy } from '../../utils/misc'
  import { SESSION } from '../../utils/session'

  import { v4 as uuidv4 } from 'uuid'

  async function addSharedContentRequest(projectId, sharedContent) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/shared_content`
      const response = await HTTP.post(url, sharedContent)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveSharedContentRequest(projectId, sharedContent) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/shared_content/${sharedContent.id}`
      const response = await HTTP.put(url, sharedContent)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function deleteSharedContentRequest(projectId, sharedContentId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/shared_content/${sharedContentId}`
      const response = await HTTP.delete(url)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ResourceShared',
    props: {
      sharedContent: {
        type: Array
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.safeSharedContent = deepCopy(this.sharedContent)
      this.safeSharedContent.sort(shared => shared.name)
    },
    data() {
      return {
        safeSharedContent: [],
        editing: {},
        saving: false,
        preview: false,
        page: 1,
        perPage: 50,
        fields: [
          { key: 'name', sortable: true },
          { key: 'actions', label: '' }
        ]
      }
    },
    methods: {
      async addSharedContent() {
        const sharedContentToAdd = {
          name: '',
          content: '',
          id: uuidv4()
        }

        this.editing = sharedContentToAdd

        this.$bvModal.show('shared-content-modal')
      },
      async saveSharedContent() {
        const sharedContent = { ...this.editing }

        const isNew = !sharedContent.exam_id

        let task

        if (isNew) {
          task = addSharedContentRequest
        } else {
          task = saveSharedContentRequest
        }

        this.saving = true

        const { data, error } = await task(
          this.$route.params.projectId,
          sharedContent
        )

        this.saving = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save shared content.'
          })
        }

        if (isNew) {
          data._rowVariant = 'primary-light'
          this.safeSharedContent.unshift(data)
        } else {
          const index = this.safeSharedContent.findIndex(shared => shared.id === data.id)
          this.safeSharedContent.splice(index, 1, data)
        }

        this.$bvModal.hide('shared-content-modal')

        this.emitToParent()
      },
      beforeDeleteShared(row) {
        const sharedContent = this.safeSharedContent.find(shared => shared.id === row.item.id)
        this.editing = { ...sharedContent }
        this.$bvModal.show('delete-shared-content-modal')
      },
      async deleteSharedContent() {
        this.saving = true

        const { error } = await deleteSharedContentRequest(
          this.$route.params.projectId,
          this.editing.id
        )

        this.saving = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete shared content.'
          })
        }

        const index = this.safeSharedContent.findIndex(shared => shared.id === this.editing.id)

        this.safeSharedContent.splice(index, 1)

        this.$bvModal.hide('delete-shared-content-modal')

        this.emitToParent()
      },
      editSharedContent(row) {
        const sharedContent = this.safeSharedContent.find(shared => shared.id === row.item.id)
        this.editing = { ...sharedContent }
        this.$bvModal.show('shared-content-modal')
      },
      previewContent(clear = false) {
        if (clear) {
          return this.preview = false
        }

        this.preview = !this.preview
      },
      emitToParent() {
        const contentForParent = deepCopy(this.safeSharedContent)
        this.$emit('update-shared', contentForParent)
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      }
    },
    computed: {
      newSharedContent() {
        return !this.editing.exam_id
      },
      previewButtonText() {
        return this.preview ? 'Edit Content' : 'Preview Content'
      }
    }
  }
</script>

<style lang="scss" scoped>
.pagination {
  margin-bottom: 0px;
}
.break-word {
  overflow-wrap: break-word;
}
</style>
