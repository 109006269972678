<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle.option-stats-collapse
        variant="primary"
        >Option Stats</b-button
      >
    </b-card-header>
    <b-collapse id="option-stats-collapse" :visible="expand">
      <b-card-body>
        <div class="border mb-3">
          <b-table
            striped
            hover
            sticky-header
            :fields="fields"
            :items="optionStats"
            class="mb-0"
          >
            <template v-slot:head(duration)="row">
              <b-button variant="link" size="sm" @click="setType('duration')">
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(responses)="row">
              <b-button variant="link" size="sm" @click="setType('responses')">
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(p_endorsed)="row">
              <b-button variant="link" size="sm" @click="setType('pEndorsed')">
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(p_correct)="row">
              <b-button variant="link" size="sm" @click="setType('pCorrect')">
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(correlation)="row">
              <b-button
                variant="link"
                size="sm"
                @click="setType('correlation')"
              >
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(discrimination)="row">
              <b-button
                variant="link"
                size="sm"
                @click="setType('discrimination')"
              >
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(duration_correct)="row">
              <b-button
                variant="link"
                size="sm"
                @click="setType('duration_correct')"
              >
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:head(duration_incorrect)="row">
              <b-button
                variant="link"
                size="sm"
                @click="setType('duration_incorrect')"
              >
                {{ row.label }}
                <font-awesome-icon icon="chart-bar"></font-awesome-icon>
              </b-button>
            </template>

            <template v-slot:cell(option)="row">
              <span
                v-b-tooltip.hover.right
                :title="item.version.content.options[row.item.option - 1]"
              >
                {{ row.item.option }}
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
            </template>
          </b-table>
        </div>

        <div v-show="type === 'responses'">
          <h4>Item Responses</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxResponses"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].responses"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].responses
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'pEndorsed'">
          <h4>P-Endorsed</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxPEndorsed"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].p_endorsed"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].p_endorsed
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'pCorrect'">
          <h4>P-Correct</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxPCorrect"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].p_correct"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].p_correct
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'correlation'">
          <h4>Correlation</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxCorrelation"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].correlation"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].correlation
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'discrimination'">
          <h4>Discrimination</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxDiscrimination"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].discrimination"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].discrimination
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'duration'">
          <h4>Duration</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxDuration"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].duration"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].duration
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'duration_correct'">
          <h4>Duration When Correct</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxDurationWhenCorrect"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].duration_correct"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].duration_correct
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-show="type === 'duration_incorrect'">
          <h4>Duration When Incorrect</h4>
          <div class="options-box">
            <b-row
              class="mb-3"
              v-for="(option, index) in optionStats"
              :key="index"
            >
              <b-col sm="3" md="2">
                <span
                  v-b-tooltip.hover.right
                  :title="item.version.content.options[index]"
                >
                  {{ index + 1 }}
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </span>
              </b-col>
              <b-col>
                <b-progress
                  class="custom-progress-bar"
                  :max="maxDurationWhenIncorrect"
                  height="30px"
                >
                  <b-progress-bar
                    :value="optionStats[index].duration_incorrect"
                    :style="{ backgroundColor: color(index) }"
                  ></b-progress-bar>
                  <span class="d-flex align-items-center ml-2 h6">{{
                    optionStats[index].duration_incorrect
                  }}</span>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  import { CHART_COLORS } from '../../utils/constants'

  import get from 'lodash.get'

  const DEFAULT_MAX = 100

  function max(objs, key) {
    return objs
      .map(obj => obj[key])
      .reduce((max_, value) => {
        if (value > max_) {
          max_ = value
        }

        return max_
      }, 0)
  }

  export default {
    name: 'ItemOptionStats',
    props: {
      item: {
        type: Object
      }
    },
    data() {
      return {
        fields: [
          { key: 'option' },
          {
            key: 'correct',
            formatter: value => {
              if (value) return '✔'
            }
          },
          { key: 'responses' },
          { key: 'p_endorsed' },
          { key: 'p_correct' },
          { key: 'correlation' },
          { key: 'discrimination' }
        ],
        expand: true,
        optionStats: [],
        type: 'pEndorsed',
        maxResponses: DEFAULT_MAX,
        maxPEndorsed: DEFAULT_MAX,
        maxPCorrect: DEFAULT_MAX,
        maxCorrelation: DEFAULT_MAX,
        maxDiscrimination: DEFAULT_MAX,
        maxDuration: DEFAULT_MAX,
        maxDurationWhenCorrect: DEFAULT_MAX,
        maxDurationWhenIncorrect: DEFAULT_MAX,
        itemType: ''
      }
    },
    created() {
      const rows = []

      const version = this.item.version
      const stats = version.stats
      const options = get(version, 'content.options', [])
      this.itemType = version.settings.type

      if (this.itemType === 'domc') {
        this.fields.splice(2, 0, { key: 'duration', label: 'Duration (sec)' })
        this.fields.push( { key: 'duration_correct', label: 'Duration When Correct (sec)' } )
        this.fields.push( { key: 'duration_incorrect', label: 'Duration When Incorrect (sec)' } )
      }

      if (this.itemType === 'matching') {
        for (const [index, field] of this.fields.entries()) {
          if (field.key === 'p_endorsed') {
            this.fields.splice(index, 1)
            break
          }
        }
        this.type = 'responses'
      }

      for (let i = 0; i < options.length; i++) {
        const responses = get(stats.option_responses, i, '-')
        const correlation = get(stats.option_correlations, i)
        const discrimination = get(stats.option_discriminations, i)

        const row = {
          option: i + 1,
          correct: version.settings.key[i],
          p_endorsed: this.optionPEndorsed(i),
          p_correct: this.optionPCorrect(i),
          correlation: this.round(correlation),
          discrimination: this.round(discrimination),
          responses
        }

        if (this.itemType === 'domc') {
          const duration = get(stats.option_durations, i)
          row.duration = duration ? duration.toFixed(2) : '-'
          this.maxDuration = max(rows, 'duration')

          const durationWhenCorrect = get(stats.option_durations_when_correct, i)
          const durationWhenIncorrect = get(stats.option_durations_when_incorrect, i)
          row['duration_correct'] = durationWhenCorrect ? durationWhenCorrect.toFixed(2) : '-'
          row['duration_incorrect'] = durationWhenIncorrect ? durationWhenIncorrect.toFixed(2) : '-'
        }

        rows.push(row)
      }

      this.optionStats = rows
      this.maxResponses = max(rows, 'responses')
      this.maxPEndorsed = max(rows, 'p_endorsed')
      this.maxPCorrect = max(rows, 'p_correct')
      this.maxCorrelation = max(rows, 'correlation')
      this.maxDiscrimination = max(rows, 'discrimination')
      this.maxDurationWhenCorrect = max(rows, 'duration_correct')
      this.maxDurationWhenIncorrect = max(rows, 'duration_incorrect')
    },
    methods: {
      round(num) {
        if (typeof num === 'number') return num.toFixed(2)
        else return '-'
      },
      optionPCorrect(optionIdx) {
        try {
          let result = this.round(
            this.item.version.stats.option_corrects[optionIdx] /
              this.item.version.stats.option_responses[optionIdx]
          )
          if (isNaN(result)) result = '-'
          return result
        } catch (e) {
          return '-'
        }
      },
      optionPEndorsed(optionIdx) {
        try {
          let pEndorsed
          if (this.item.version.settings.key[optionIdx]) {
            pEndorsed =
              this.item.version.stats.option_corrects[optionIdx] /
              this.item.version.stats.option_responses[optionIdx]
          } else {
            pEndorsed =
              1 -
              this.item.version.stats.option_corrects[optionIdx] /
                this.item.version.stats.option_responses[optionIdx]
          }
          let result = this.round(pEndorsed)
          if (isNaN(result)) result = '-'
          return result
        } catch (e) {
          return '-'
        }
      },
      setType(type) {
        this.type = type
      },
      color(index) {
        if (index > CHART_COLORS.length - 1) {
          index %= CHART_COLORS.length
        }

        return CHART_COLORS[index]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-progress-bar {
    background-color: transparent;
    box-shadow: none;
  }
</style>
