<template>
  <div>
    <b-button
      variant="white"
      size="sm"
      class="mr-1"
      @click="translate()"
    >
      Auto-translate {{ getLanguageName() }}
    </b-button>
  </div>
</template>
<script>
import {SESSION} from '../../utils/session'
import {AutoTranslateApi} from '../../utils/translation'
import { EVENT } from '../../utils/event-bus'


export default {
  name: 'TranslateContentButton',
  components: {},
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    },
    content: {
      type: String
    },
    targetLanguageCode: {
      type: String
    }
  },
  data() {
    return {
      supportedLanguages: null
    }
  },
  async created() {
    this.autoTranslateApi = new AutoTranslateApi(this.project.id, this.targetLanguage)
    this.supportedLanguages = SESSION.supportedLanguages
  },
  methods: {
    async translate() {
      if (!this.content?.length) return

      const response = await this.autoTranslateApi.translate(this.content)

      if (response.error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to translate'
        })
      }

      this.$emit('input', response.data.translation)
    },
    getLanguageName() {
      if (!this.supportedLanguages) return ''

      for (const [languageName, languageCode] of Object.entries(this.supportedLanguages.all)) {
        if (this.targetLanguage === languageCode) return `(${languageName})`.replace(/_/g, ' ')
      }

      return ''
    }
  },
  computed: {
    targetLanguage() {
      return this.targetLanguageCode || this.language.properties.auto_translate_language_code || 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
