import get from 'lodash.get'
import set from 'lodash.set'
import merge from 'lodash.merge'

export const _ = {
  get(obj, keyStr, defaultValue) {
    return get(obj, keyStr, defaultValue)
  },
  set(obj, keyStr, value) {
    return set(obj, keyStr, value)
  },
  merge(obj, ...sources) {
    return merge(obj, ...sources)
  }
}
