<template>
    <b-tab :active="active" title="Widgets">
        <b-button variant="primary-light" :disabled="shouldDisable('create_items')" @click="addWidget" class="mb-4">Add Widget</b-button>

        <h6>Default</h6>

        <b-table
            :fields="fields"
            :items="defaultWidgets"
            responsive
            small
            striped
            fixed
            sort-icon-left
            sort-by="name"
        >
        </b-table>

        <div class="mt-4" v-show="showIntegrationWidgets">
            <div class="d-flex justify-content-between align-items-end">
                <h6>Integration</h6>
                <b-pagination
                    size="sm"
                    class="mb-1"
                    v-show="integrationWidgets.length > perPage"
                    v-model="integrationPage"
                    :per-page="perPage"
                    :total-rows="integrationWidgets.length"
                />
            </div>

            <b-table
                :fields="fields"
                :items="integrationWidgets"
                :current-page="integrationPage"
                :per-page="perPage"
                responsive
                small
                striped
                fixed
                sort-icon-left
                sort-by="name"
            >

                <template #cell(buttons)="row">
                    <div class="d-flex justify-content-end">
                        <b-button size="sm" variant="white" @click="openModal(row.item, 'view-widget')">View</b-button>
                    </div>
                </template>
            </b-table>
        </div>

        <div class="mt-4" v-show="showCustomWidgets">
            <div class="d-flex justify-content-between align-items-end">
                <h6>Custom</h6>
                <b-pagination
                    size="sm"
                    class="mb-1"
                    v-show="customWidgets.length > perPage"
                    v-model="customPage"
                    :per-page="perPage"
                    :total-rows="customWidgets.length"
                />
            </div>

            <b-table
                :fields="fields"
                :items="customWidgets"
                :current-page="customPage"
                :per-page="perPage"
                responsive
                small
                striped
                fixed
                sort-icon-left
                sort-by="name"
            >
                <template #cell(buttons)="row">
                    <div class="d-flex justify-content-end">
                        <b-button-group size="sm">
                            <b-button variant="white" @click="openModal(row.item, 'edit-widget')" :disabled="deleting || shouldDisable('edit_items')">Edit</b-button>
                            <b-button variant="white" @click="deleteWidget(row.item)" :disabled="deleting || shouldDisable('delete_items')">
                                <font-awesome-icon icon="trash" />
                            </b-button>
                        </b-button-group>
                    </div>
                </template>
            </b-table>
        </div>

        <b-modal
            id="add-widget"
            size="sm"
            title="Add Widget"
            no-fade
            @ok.prevent="createWidget"
        >
            <label>Name</label>
            <b-input autofocus v-model="editing.name" placeholder="Enter Name" class="mb-4" />

            <label>URL</label>
            <b-input v-model="editing.url" placeholder="Enter URL" class="mb-4" />

            <label>Icon</label>
            <IconPicker @icon-selected="onAddIconSelected" :selected="editing.icon" />

            <b-checkbox v-model="editing.securePdf">Use secure pdf viewer</b-checkbox>

            <template #modal-footer="{ cancel, ok }">
                <b-button variant="white" @click="cancel">Cancel</b-button>
                <b-button variant="secondary" @click="ok" :disabled="saveDisabled">
                    <b-spinner small v-show="saving" />
                    Save
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="view-widget"
            size="sm"
            title="View Widget"
            no-fade
        >
            <label>Name</label>
            <b-input :value="editing.name" class="mb-4" disabled />

            <div v-if="editing.url">
                <label>URL</label>
                <b-input :value="editing.url" class="mb-4" disabled />
            </div>

            <div v-if="editing.icon" class="mt-2">
                <label>Icon</label>
                <IconPicker :preview="editing.icon" />
            </div>

            <b-checkbox disabled v-model="editing.securePdf" class="mt-4">Use secure pdf viewer</b-checkbox>

            <template #modal-footer="{ cancel }">
                <b-button variant="white" @click="cancel">Close</b-button>
            </template>
        </b-modal>

        <b-modal
            id="edit-widget"
            size="sm"
            title="Edit Widget"
            no-fade
            @ok.prevent="saveWidget"
        >
            <label>Name</label>
            <b-input v-model="editing.name" placeholder="Enter Name" class="mb-4" />

            <label>URL</label>
            <b-input v-model="editing.url" placeholder="Enter URL" class="mb-4" />

            <label>Icon</label>
            <IconPicker @icon-selected="onAddIconSelected" :selected="editing.icon" />

            <b-checkbox v-model="editing.securePdf">Use secure pdf viewer</b-checkbox>

            <template #modal-footer="{ cancel, ok }">
                <b-button variant="white" @click="cancel">Cancel</b-button>
                <b-button variant="secondary" @click="ok" :disabled="saveDisabled">
                    <b-spinner small v-show="saving" />
                    Save
                </b-button>
            </template>
        </b-modal>
    </b-tab>
</template>

<script>
import { EVENT } from '../../utils/event-bus'
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants'
import { SESSION } from '../../utils/session'

import IconPicker from './IconPicker.vue'

async function createWidgetRequest(payload) {
    try {
        const url = `${SEI_API_BASE}/exams/${SESSION.project.id}/widgets`
        const response = await HTTP.post(url, payload)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function deleteWidgetRequest(widgetId) {
    try {
        const url = `${SEI_API_BASE}/exams/${SESSION.project.id}/widgets/${widgetId}`
        const response = await HTTP.delete(url)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function saveWidgetRequest(widgetId, payload) {
    try {
        const url = `${SEI_API_BASE}/exams/${SESSION.project.id}/widgets/${widgetId}`
        const response = await HTTP.put(url, payload)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

function formatWidgetForDisplay(widget) {
    const { id, name, url, meta } = widget

    if (!meta) return widget

    const formattedWidget = {
        id,
        name,
        url,
        windowed: Boolean(meta?.windowed),
        securePdf: Boolean(meta?.secure_pdf),
        icon: meta?.icon
    }

    return formattedWidget
}

function formatWidgetForSave(widget) {
    const { name, url, windowed, securePdf, icon } = widget

    const formattedWidget = {
        name,
        url,
        meta: {
            windowed: Number(windowed),
            secure_pdf: Number(securePdf),
            icon
        }
    }

    return formattedWidget
}

export default {
    name: 'ResourceWidgets',
    components: {
        IconPicker
    },
    props: {
        widgets: {
            type: Array
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fields: [
                { key: 'name', sortable: true },
                { key: 'buttons', label: '' }
            ],
            defaultWidgets: [],
            integrationWidgets: [],
            customWidgets: [],
            editing: {},
            integrationPage: 1,
            customPage: 1,
            perPage: 50,
            name: '',
            url: '',
            saving: false,
            deleting: false
        }
    },
    created() {
        this.setWidgets()
    },
    methods: {
        addWidget() {
            this.editing = {
                name: '',
                url: '',
                icon: null,
                windowed: false,
                securePdf: false
            }

            this.$bvModal.show('add-widget')
        },
        onAddIconSelected (icon) {
            this.editing.icon = icon
        },
        async createWidget() {
            const payload = formatWidgetForSave(this.editing)

            payload.enabled = false

            this.saving = true

            const { data, error } = await createWidgetRequest(payload)

            this.saving = false

            if (error) {
                const alert = {
                    message: 'Failed to save widget',
                    variant: 'danger'
                }

                return EVENT.alert(alert)
            }

            this.$bvModal.hide('add-widget')

            const customWidget = formatWidgetForDisplay(data)

            this.customWidgets.push(customWidget)

            this.emitToParent()
        },
        async deleteWidget(widget) {
            const { name, id } = widget

            const message = `Are you sure you would like to delete ${ name }?`

            const options = {
                title: 'Confirm',
                size: 'sm',
                okTitle: 'Delete',
                okVariant: 'danger',
                cancelTitle: 'Cancel',
                cancelVariant: 'white',
                noFade: true
            }

            const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

            if (!confirmed) return

            this.deleting = true

            const { error } = await deleteWidgetRequest(id)

            this.deleting = false

            if (error) {
                const alert = {
                    message: 'Failed to delete widget',
                    variant: 'danger'
                }

                return EVENT.alert(alert)
            }

            const index = this.customWidgets.findIndex(customWidget => customWidget.id === id)

            this.customWidgets.splice(index, 1)

            this.emitToParent()
        },
        emitToParent() {
            const contentForParent = [ ...this.defaultWidgets, ...this.integrationWidgets, ...this.customWidgets ]

            this.$emit('update-widgets', contentForParent)
        },
        openModal(widget, modal) {
            this.editing = { ...widget }

            if (!this.editing.icon) {
                this.editing.icon = null
            }

            this.$bvModal.show(modal)
        },
        async saveWidget() {
            const payload = formatWidgetForSave(this.editing)

            this.saving = true

            const { data, error } = await saveWidgetRequest(this.editing.id, payload)

            this.saving = false

            if (error) {
                const alert = {
                    message: 'Failed to save widget',
                    variant: 'danger'
                }

                return EVENT.alert(alert)
            }

            this.$bvModal.hide('edit-widget')

            const customWidget = formatWidgetForDisplay(data)

            const index = this.customWidgets.findIndex(widget => widget.id === customWidget.id)

            this.customWidgets.splice(index, 1, customWidget)

            this.emitToParent()
        },
        setWidgets() {
            const defaultWidgets = []
            const integrationWidgets = []
            const customWidgets = []

            for (const widget of this.widgets) {
                if (widget.type === 'default') {
                    defaultWidgets.push(widget)
                    continue
                }

                if (widget.type === 'integration') {
                    integrationWidgets.push(widget)
                    continue
                }

                const customWidget = formatWidgetForDisplay(widget)

                customWidgets.push(customWidget)
            }

            this.defaultWidgets = defaultWidgets
            this.integrationWidgets = integrationWidgets
            this.customWidgets = customWidgets
        },
        shouldDisable(neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        }
    },
    computed: {
        saveDisabled() {
            return !this.editing.name || !this.editing.url || this.saving
        },
        showCustomWidgets() {
            return Boolean(this.customWidgets.length)
        },
        showIntegrationWidgets() {
            return Boolean(this.integrationWidgets.length)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
