<template>
  <div>
    <b-card class="mb-1" no-body>
      <b-card-header class="p-1">
        <b-button
          block
          class="text-left text-capitalize px-3 py-2"
          size="lg"
          v-b-toggle="dropdownId"
          variant="primary"
          >{{ dropdownHeader }}</b-button
        >
      </b-card-header>
      <b-collapse :id="dropdownId" :visible="expand" v-if="filteredData.length">
        <b-card-body>
          <b-table
            :fields="fieldsToDisplay"
            :items="filteredData"
            @row-clicked="rowClicked"
            hover
            primary-key="id"
            responsive
            small
            striped
          >
            <template v-slot:table-colgroup="scope">
              <col
                :key="field.key"
                :style="{ width: field.key === 'starred' ? '40px' : 'auto' }"
                v-for="field in scope.fields"
              />
            </template>
            <template v-slot:cell(starred)="data">
              <div class="text-center" data-star>
                <div style="pointerEvents: none;">
                  <font-awesome-icon
                    class="text-secondary"
                    icon="star"
                    v-if="data.item.starred"
                  ></font-awesome-icon>
                  <font-awesome-icon
                    :icon="['far', 'star']"
                    v-else
                  ></font-awesome-icon>
                </div>
              </div>
            </template>
          </b-table>
          <Spinner v-if="loadingMore" />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
  import Spinner from './Spinner'

  export default {
    name: 'AccordionList',
    components: {
      Spinner
    },
    props: {
      fieldsToDisplay: {
        type: Array,
        default: () => []
      },
      tableData: {
        type: Array,
        default: () => []
      },
      tableName: {
        type: String,
        default: 'Unnamed'
      },
      expand: {
        type: Boolean
      },
      searchText: {
        type: String
      },
      searchKey: {
        type: String
      },
      routeName: {
        type: String,
        default: ''
      },
      routeId: {
        type: String,
        default: ''
      },
      idProperty: {
        type: String,
        default: ''
      },
      visible: {
        type: Boolean,
        default: false
      },
      loadingMore: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dropdownId: '',
        filteredData: []
      }
    },
    created() {
      this.dropdownId = 'accordion-' + this._uid
      this.filteredData = this.tableData
    },
    methods: {
      rowClicked(record, index, event) {
        if (event.target.hasAttribute('data-star')) {
          this.$emit('star', record)
        } else {
          this.$router.push({
            name: this.routeName,
            params: { [this.routeId]: record[this.idProperty] }
          })
        }
      },
      filterData() {
        this.filteredData = this.tableData.filter(data => {
          return data[this.searchKey]
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        })
      }
    },
    computed: {
      dropdownHeader() {
        return `${this.tableName} (${this.filteredData.length})`
      }
    },
    watch: {
      searchText() {
        this.filterData()
      },
      tableData() {
        this.filterData()
      }
    }
  }
</script>

<style lang="scss" scoped></style>
