<template>
  <div>
    <b-tabs v-model="tabIndex" lazy card content-class="mt-3">
      <b-tab title="Team" v-if="showTeamTab">
        <Roles :project="project" :is-premium="isPremium" />
      </b-tab>

      <b-tab title="Integrated Apps" v-if="showIntegrationsTab">
        <Apps :project="project" :is-premium="isPremium" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import Apps from './Apps.vue'
  import Roles from './Roles.vue'

  const TAB_NAMES = ['#Team', '#IntegratedApps']

  export default {
    name: 'ProjectAccess',
    components: {
      Apps,
      Roles
    },
    props: {
      project: {
        type: Object
      },
      isPremium: {
        type: Boolean
      }
    },
    data() {
      return {
        tabIndex: 0
      }
    },
    watch: {
      tabIndex: function(index) {
        const tabName = TAB_NAMES[index]
        
        window.history.replaceState(null, null, tabName)
      }
    },
    created() {
      this.setTabIndex()
    },
    methods: {
      setTabIndex () {
        const index = TAB_NAMES.indexOf(this.$route.hash)

        if (index > -1) {
          this.tabIndex = index
        }
      }
    },
    computed: {
      showTeamTab() {
        return SESSION.hasPermissions('manage_users')
      },
      showIntegrationsTab () {
        return SESSION.hasPermissions('manage_integrations')
      }
    }
  }

</script>
