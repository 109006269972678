<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle.blank-stats-collapse
        variant="primary"
        >Blank Stats</b-button
      >
    </b-card-header>
    <b-collapse id="blank-stats-collapse" :visible="expand">
      <b-card-body>
        <div class="border mb-3">
          <div 
            :key="index"
            v-for="(segment, index) in blankSegments"
          >
            <h4 class="mb-3 mt-2">
              Blank {{ index + 1 }} ({{ percentCorrect(index) }}% Correct)
            </h4>
            <b-table
            striped
            hover
            sticky-header
            :fields="fields"
            :items="blankStats[index]"
            class="mb-0"
          >
            <template v-slot:cell(option)="row">
              <span
                v-b-tooltip.hover.right
                :title="row.item.title"
              >
                {{ row.item.option }}
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
              </span>
             </template>
            </b-table>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  import get from 'lodash.get'

  import { deepCopy } from '../../utils/misc'

  export default {
    name: 'ItemBlankStats',
    props: {
      item: {
        type: Object
      }
    },
    data() {
      return {
        fields: [
          { key: 'option' },
          {
            key: 'correct',
            formatter: value => {
              if (value) return '✔'
            }
          },
          { key: 'responses' },
          { key: 'p_endorsed' }
        ],
        expand: true,
        blankStats: [],
        typeStats: {}
      }
    },
    created() {
      this.typeStats = deepCopy(this.item.version.stats)

      for (let i = 0; i < this.blankSegments.length; i++) {
        const segment = this.blankSegments[i]
        const options = segment.options
        
        const rows = []
        for (let j = 0; j < options.length; j++) {
          const option = options[j]
          const responses = get(this.typeStats.blank_responses, [i, j], '-')

          const row = {
            title: option.text,
            option: j + 1,
            correct: option.is_correct,
            p_endorsed: this.optionPEndorsed(i, j),
            responses
          }
          rows.push(row)
        }

        // Handle "Other" option
        if (segment.type === 'short_answer') {
          const lastIndex = options.length + 1

          let numOfEndorsements = 0
          for (const row of rows) {
            numOfEndorsements += row.responses
          }

          const totalNumOfEndorsements = this.typeStats.num_responses
          const numOfOtherEndorsements = totalNumOfEndorsements - numOfEndorsements

          let pEndorsed = this.round( numOfOtherEndorsements / totalNumOfEndorsements)
          if (isNaN(pEndorsed)) pEndorsed = '-'

          const row = {
            title: "Other",
            option: lastIndex,
            correct: false,
            p_endorsed: pEndorsed,
            responses: numOfOtherEndorsements
          }
          rows.push(row)
        }

        this.blankStats.push(rows)
      }
    },
    methods: {
      round(num) {
        if (typeof num === 'number') return num.toFixed(2)
        else return '-'
      },
      optionPCorrect(segmentIndex, optionIndex) {
        try {
          let result = this.round(
            this.typeStats.blank_percent_correct[segmentIndex] /
            this.typeStats.blank_responses[segmentIndex][optionIndex]
          )
          if (isNaN(result)) result = '-'
          return result
        } catch (e) {
          return '-'
        }
      },
      optionPEndorsed(segmentIndex, optionIndex) {
        try {
          let pEndorsed = (
            this.typeStats.blank_responses[segmentIndex][optionIndex] / 
            this.typeStats.num_responses
          )
          let result = this.round(pEndorsed)
          if (isNaN(result)) {
            result = '-'
          }

          return result
        } catch (e) {
          return '-'
        }
      },
      percentCorrect(index) {
        return this.round( get(this.typeStats, ['blank_percent_correct', index], 0) * 100 )
      }
    },
    computed: {
      blankSegments() {
        return this.item.version.content.segments.filter( (segment) => {
          if (segment.type === 'content') {
            return false
          }
          return true
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
