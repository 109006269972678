<template>
  <div>
    <TopNavigation />
    <b-navbar sticky variant="light">
      <b-button-group v-if="!search">
        <b-button
          v-b-modal.new-org-modal
          v-if="isGlobalAdmin"
          variant="primary-light"
          >New Organization</b-button
        >
        <b-button @click="showSearch" key="search-btn" variant="white"
          >Search</b-button
        >
      </b-button-group>
      <div class="w-100 d-flex" v-else>
        <b-form-input
          autofocus
          placeholder="Search Organizations"
          style="maxWidth: 600px"
          v-model="searchText"
        />
        <b-button
          @click="hideSearch"
          class="ml-1"
          key="search-cancel-btn"
          variant="white"
          >Cancel</b-button
        >
      </div>
    </b-navbar>
    <!-- Create Org Modal -->
    <b-modal id="new-org-modal" size="xl" title="Create New Organization">
      <b-form @submit.prevent="onSubmit" id="add-org-form" novalidate>
        <b-form-group
          label="Organization Name *"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="orgName"
        >
          <b-input-group>
            <b-form-input
              autofocus
              id="orgName"
              required
              type="text"
              v-model="orgName"
            />
          </b-input-group>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            :disabled="disableCreateOrgBtn"
            form="add-org-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner label="Small Spinner" small v-show="isSaving"></b-spinner
            >&nbsp;Create
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
    <b-container class="mt-3" fluid v-if="!loading">
      <AccordionList
        :expand="true"
        :fieldsToDisplay="fields"
        :searchText="searchText"
        :tableData="sortedOrgs"
        @star="setStars"
        idProperty="id"
        routeId="orgId"
        routeName="orgDashboard"
        searchKey="name"
        tableName="Organizations"
        v-if="!error"
      />
      <b-alert
        class="d-flex justify-content-between align-items-center"
        show
        v-else
        variant="danger"
      >
        Failed to load organizations.
        <b-button @click="getOrgs" variant="danger">Reload</b-button>
      </b-alert>
    </b-container>
    <Spinner v-else />
  </div>
</template>

<script>
  import { HTTP } from '../utils/requests'
  import { SEI_API_BASE } from '../utils/constants'
  import { SESSION } from '../utils/session'
  import { LOCAL } from '../utils/local'
  import { EVENT } from '../utils/event-bus'
  import { VALIDATE } from '../utils/validate'

  import AccordionList from './AccordionList'
  import TopNavigation from './TopNavigation'
  import Spinner from './Spinner'

  const PER_PAGE = 100

  async function getOrganizations(page) {
    try {
      const url = `${SEI_API_BASE}/organizations?include=my_organization_permissions&only=id,name,my_organization_permissions&page=${page}&per_page=${PER_PAGE}`

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'OrganizationPage',
    components: {
      AccordionList,
      TopNavigation,
      Spinner
    },
    created() {
      this.getOrgs()
      this.isGlobalAdmin = SESSION.user.global_roles.includes('admin')
    },
    data() {
      return {
        organizations: [],
        orgName: '',
        error: false,
        fields: [
          { key: 'starred', label: '' },
          { key: 'name', sortable: true }
        ],
        loading: true,
        isSaving: false,
        search: false,
        searchText: '',
        isGlobalAdmin: false
      }
    },
    methods: {
      async getOrgs() {
        try {
          this.loading = true

          let page = 1
          let hasMore = true
          const orgs = []
          while (hasMore) {
            const { data, error } = await getOrganizations(page)

            if (error) {
              return (this.error = true)
            }

            orgs.push(...data.results)
            hasMore = data.has_next
            page++
          }

          const orgsToDisplay = []
          for (const org of orgs) {
            if (org.my_organization_permissions.includes('manage_groups')) {
              orgsToDisplay.push(org)
            }
          }
          this.getStars(orgsToDisplay)
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.createOrg()
        }
      },
      async createOrg() {
        try {
          this.isSaving = true
          const orgUrl = `${SEI_API_BASE}/organizations`
          const response = await HTTP.post(orgUrl, { name: this.orgName })
          const newOrg = response.data
          newOrg.starred = false
          this.organizations.push(newOrg)
          this.exitOrgModal()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to create organization. Please try again.'
          })
        } finally {
          this.isSaving = false
        }
      },
      exitOrgModal() {
        this.$bvModal.hide('new-org-modal')
        this.orgName = ''
      },
      getStars(orgs) {
        const starred = LOCAL.star.get('orgs')
        for (const org of orgs) {
          org.starred = starred.includes(org.id)
        }
        this.organizations = orgs
      },
      setStars(org) {
        const starred = LOCAL.star.get('orgs')
        org.starred = !org.starred
        if (org.starred) {
          starred.push(org.id)
        } else {
          starred.splice(starred.indexOf(org.id), 1)
        }
        LOCAL.star.set('orgs', starred)
      },
      showSearch() {
        this.search = true
      },
      hideSearch() {
        this.search = false
        this.searchText = ''
      }
    },
    computed: {
      sortedOrgs() {
        return [...this.organizations].sort(
          (current, next) => next.starred - current.starred
        )
      },
      disableCreateOrgBtn() {
        return this.isSaving
      }
    }
  }
</script>

<style lang="scss" scoped></style>
