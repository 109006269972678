<template>
  <div>
    <div v-if="!loadingApps">
      <div v-if="!error">
        <div fluid v-if="!refreshingApps">
          <div :class="!isPremium ? 'upgrade-block' : null" class="mb-4 pb-4 border-bottom">
            <b-alert
              class="d-flex justify-content-between align-items-center"
              show
              v-if="!isPremium && isPremiumEnabled"
              variant="info"
            >
              Free accounts cannot add new apps
              <b-button @click="upgrade()" size="sm" variant="info"
                >Upgrade to Premium</b-button
              >
            </b-alert>
            <h3>Integrated Apps</h3>
            <div class="searchApp">
              <b-card
                class="appCard"
                :key="app.id"
                v-for="app in apps"
              >
                <div class="row">
                  <div v-if="app.app.profile_picture" class="col-3">
                    <span class="img-container">
                      <img :src="app.app.profile_picture" :class="{ invisible: !app.app.profile_picture }" width="100%" alt="App Image">
                    </span>
                  </div>
                  <div class="col">
                    <h5 class="card-title">{{ app.app.first_name }}</h5>
                    <p class="card-text app-description">{{ app.app.app_description }}</p>
                    <b-button-group>
                    <b-button
                      :disabled="shouldDisable"
                      @click="appPermissions(app)"
                      variant="white"
                      size="sm"
                      v-b-tooltip="{
                        title: 'Update required',
                        disabled: app.is_up_to_date,
                        trigger: 'hover'
                      }"
                    >
                      <font-awesome-icon v-if="!app.is_up_to_date" icon="exclamation-circle" class="app-alert"></font-awesome-icon>
                      Permissions
                    </b-button>
                    <b-button
                      :disabled="shouldDisable"
                      @click="addApp(app)"
                      variant="white"
                      size="sm"
                      >Refresh</b-button
                    >
                    <b-button
                      :disabled="shouldDisable"
                      @click="removeApp(app)"
                      variant="white"
                      size="sm"
                      >Remove</b-button
                    >
                    <b-button 
                      :disabled="shouldDisable" 
                      @click="getToken(app)" 
                      variant="white"
                      size="sm"
                      >Get Token</b-button
                    >
                  </b-button-group>
                  </div>
                </div>
                
              </b-card>
            </div>
          </div>

          <h3>Available Apps</h3>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Search apps</span>
            </div>
            <b-form-input
              placeholder="Enter an app name"
              v-model="searchText"
            />
          </div>
          <div v-if="isPremium" class="searchApp"> 
            <b-card
              class="appCard"
              :key="app.id"
              @click="showAddAppModal(app)"
              v-for="app in unIntegratedApps"
            >
              <div class="row">
                <div v-if="app.profile_picture" class="col-3">
                  <span style="padding: 10px; background: #fff; display:flex; border-radius: 3px;" >
                    <img :src="app.profile_picture" width="100%" alt="App Image">
                  </span>
                </div>
                <div class="col">
                  <h5 class="card-title">{{ app.first_name }}</h5>
                  <p class="card-text app-description">{{ app.app_description }}</p>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <Spinner v-else />
        <b-modal
            id="add-app-modal"
            size="xl"
            title="Integrate An App"
            scrollable
          >
          <div v-if="selected">
            <h2>{{ selected.first_name }}</h2>
            <p>{{ selected.app_description }}</p>
            <hr/>
            <p class="mt-3 d-block"><b>{{ selected.first_name }}</b> requires the following:</p>
            <b-row>
              <b-col lg="4">
                <h4>Permissions</h4>
                <p>The app will be able to perform these actions:</p>
                <ul>
                  <li
                    :key="permission"
                    class="text-capitalize"
                    v-for="permission in selected.app_settings.permissions"
                  >
                    {{ displayName(permission) }}
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Content</h4>
                <p>The app will embed content in these locations:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(content, key) in selected.app_settings.content"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize"
                        >{{ displayName(key) }}:</span
                      >
                      <br />
                      <small class="wrap">{{ printContent(content) }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Hooks</h4>
                <p>The app will be notified of these events:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(hook, key) in selected.app_settings.hooks"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize">{{
                        displayName(key)
                      }}</span>
                      <br />
                      <small class="wrap">{{ hook }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
          <template v-slot:modal-footer="{ cancel }">
            <b-button-group>
              <b-button @click="cancel()" variant="white">Cancel</b-button>
              <b-button @click="addApp(selected)" variant="secondary">
                <b-spinner
                label="Small Spinner"
                small
                v-show="isSavingApp"
                >
                </b-spinner>&nbsp;Integrate
              </b-button>
            </b-button-group>
          </template>
        </b-modal>
        <b-modal
          hide-footer
          id="permissions-app-modal"
          size="xl"
          title="App Permissions"
          scrollable
        >
          <div v-if="selected">
            <h2>{{ selected.first_name }}</h2>
            <p>{{ selected.app_description }}</p>
            <hr/>
            <p>This app requires the following:</p>
            <b-row>
              <b-col lg="4">
                <h4>Permissions</h4>
                <p>The app will be able to perform these actions:</p>
                <ul>
                  <li
                    :key="permission"
                    class="text-capitalize"
                    v-for="permission in selected.app_settings.permissions"
                  >
                    {{ displayName(permission) }}
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Content</h4>
                <p>The app will embed content in these locations:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(content, key) in selected.app_settings.content"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize"
                        >{{ displayName(key) }}:</span
                      >
                      <br />
                      <small class="wrap">{{ printContent(content) }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Hooks</h4>
                <p>The app will be notified of these events:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(hook, key) in selected.app_settings.hooks"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize">{{
                        displayName(key)
                      }}</span>
                      <br />
                      <small class="wrap">{{ hook }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </b-modal>
        <b-modal
          @cancel="removeApp()"
          @ok="updateApp()"
          id="update-app-modal"
          size="xl"
          title="Updated App Permissions"
          scrollable
        >
          <div v-if="selected">
            <p>{{ selected.first_name }} requires the following:</p>
            <b-row>
              <b-col lg="4">
                <h4>Permissions</h4>
                <p>The app will be able to perform these actions:</p>
                <ul>
                  <li
                    :key="permission"
                    class="text-capitalize"
                    v-for="permission in selected.app_settings.permissions"
                  >
                    {{ displayName(permission) }}
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Content</h4>
                <p>The app will embed content in these locations:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(content, key) in selected.app_settings.content"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize"
                        >{{ displayName(key) }}:</span
                      >
                      <br />
                      <small class="wrap">{{ printContent(content) }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
              <b-col lg="4">
                <h4>Hooks</h4>
                <p>The app will be notified of these events:</p>
                <ul>
                  <li
                    :key="key"
                    class="text-capitalize"
                    v-for="(hook, key) in selected.app_settings.hooks"
                  >
                    <p class="text-lowercase mb-0">
                      <span class="text-capitalize">{{
                        displayName(key)
                      }}</span>
                      <br />
                      <small class="wrap">{{ hook }}</small>
                    </p>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button-group>
              <b-button @click="cancel()" variant="white">Decline</b-button>
              <b-button @click="ok()" variant="dark">Accept</b-button>
            </b-button-group>
          </template>
        </b-modal>
        <b-modal
          @hide="clearToken()"
          hide-footer
          id="token-app-modal"
          size="xl"
          title="App Token"
        >
          <b-input-group v-if="!loadingToken">
            <b-form-input :value="appToken" readonly ref="copyText" />
            <b-input-group-append>
              <b-button @click="copyToken()" id="token-copy-btn" variant="white"
                >Copy</b-button
              >
              <b-tooltip
                :show.sync="copiedText"
                target="token-copy-btn"
                triggers="manual"
                variant="primary-dark"
                >Copied!</b-tooltip
              >
            </b-input-group-append>
          </b-input-group>
          <Spinner padding="p-1" v-else />
        </b-modal>
        <b-modal
          @hide="appConfirmed()"
          hide-footer
          id="confirm-app-modal"
          no-close-on-backdrop
          size="xl"
          title="Complete the app and then close this window."
          scrollable
        >
          <div v-show="readyToShowFrame">
            <b-embed
              :src="appUrl"
              @load="frameContentLoaded()"
              type="iframe"
            ></b-embed>
          </div>
          <Spinner v-if="!readyToShowFrame" />
        </b-modal>
      </div>
      <b-container class="mt-3" v-if="error">
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load apps.
          <b-button @click="getAllApps()" variant="danger"
            >Reload</b-button
          >
        </b-alert>
      </b-container>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { SEI_API_BASE, IS_UPGRADE_TO_PREMIUM_ENABLED } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'

  import Spinner from '../Spinner'

  export default {
    name: 'Apps',
    components: {
      Spinner
    },
    async created() {
      this.apps = this.project.integrations
      await this.getAllApps()

      if (this.$route?.params?.accepting) {
        for (const app of this.apps) {
          if (!app.is_up_to_date) {
            return this.appPermissions(app)
          }
        }
      }
    },
    props: {
      project: {
        type: Object
      },
      isPremium: {
        type: Boolean
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      project(updatedProject) {
        this.apps = updatedProject.integrations
        this.refreshingApps = false
        this.createOptions()
      }
    },
    data() {
      return {
        apps: [],
        appOptions: [],
        availableApps: [],
        appToken: '',
        appUrl: '',
        loadingApps: true,
        refreshingApps: false,
        error: false,
        loadingToken: true,
        loadingFrame: true,
        copiedText: false,
        selected: null,
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED,
        searchText: '',
        isSavingApp: false
      }
    },
    methods: {
      async getAllApps() {
        try {
          this.loadingApps = true
          const privateApps = await HTTP.get(`${SEI_API_BASE}/apps?exam_id=${this.project.id}`)
          const publicApps = await HTTP.get(`${SEI_API_BASE}/apps/public`)
          this.availableApps = [
            ...privateApps.data.results,
            ...publicApps.data.results
          ]
          this.createOptions()
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loadingApps = false
        }
      },
      async createOptions() {
        const enabledIds = this.apps.map(
          app => app.app_caveon_id
        )
        const options = []
        for (const app of this.availableApps) {
          if (!enabledIds.includes(app.caveon_id)) {
            options.push({
              value: app,
              text: app.first_name
            })
          }
        }
        options.sort((current, next) => current.text.localeCompare(next.text))
        this.appOptions = [
          { value: null, text: 'Select an app', disabled: true },
          ...options
        ]
      },
      frameContentLoaded() {
        this.loadingFrame = false
      },
      async addApp(app) {
        const appId = app.caveon_id ? app.caveon_id : app.app_caveon_id
        try {
          this.appUrl = ''
          this.isSavingApp = true
          const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/create_integration/${appId}`
          const response = await HTTP.post(url, {})
          this.appUrl = response.data.url
          this.isSavingApp = false
        } catch (error) {
          this.isSavingApp = false
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to add app.'
          })
        }
        this.$bvModal.hide('add-app-modal')
        if (this.appUrl) {
          this.loadingFrame = true
          this.$bvModal.show('confirm-app-modal')
        } else {
          this.refreshApps()
        }
      },
      async removeApp(app) {
        let id
        if (app) {
          id = app.app_caveon_id
        } else {
          id = this.selected.caveon_id
        }
        try {
          this.refreshingApps = true
          const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/delete_integration/${id}`
          await HTTP.post(url, {})
          this.refreshApps()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to remove app.'
          })
          this.refreshingApps = false
        }
      },
      appConfirmed() {
        this.refreshingApps = true
        this.refreshApps()
      },
      async updateApp() {
        try {
          this.refreshingApps = true
          const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/update_integration/${this.selected.caveon_id}`
          await HTTP.post(url, {})
          this.refreshApps()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to update app.'
          })
          this.refreshingApps = false
        }
      },
      refreshApps() {
        EVENT.getProject()
      },
      appPermissions(app) {
        this.selected = app.app
        if (app.is_up_to_date) {
          this.$bvModal.show('permissions-app-modal')
        } else {
          this.$bvModal.show('update-app-modal')
        }
      },
      async getToken(app) {
        try {
          this.$bvModal.show('token-app-modal')
          this.loadingToken = true
          const url = `${SEI_API_BASE}/integrations/${app.id}/api_token`
          const response = await HTTP.get(url)
          this.appToken = response.data.api_token
        } catch (error) {
          EVENT.alert({ variant: 'danger', message: 'Failed to load token.' })
        } finally {
          this.loadingToken = false
        }
      },
      clearToken() {
        this.appToken = ''
      },
      showAddAppModal(app) {
        this.selected = app
        this.$bvModal.show('add-app-modal')
      },
      copyToken() {
        this.$refs.copyText.select()
        document.execCommand('copy')
        this.copiedText = true
        setTimeout(() => (this.copiedText = false), 1200)
      },
      printContent(value) {
        if (typeof value === 'string') {
          return value
        } else if (typeof value === 'object' && value !== null) {
          return value.url
        }
        return ''
      },
      displayName(permission) {
        return permission.split('_').join(' ')
      },
      upgrade() {
        const orgId = this.project.organization_id

        if (!orgId) {
          return this.$router.push({
            name: 'projectSettings'
          })
        }

        this.$router.push({
          name: 'orgBilling',
          params: { orgId }
        })
      }
    },
    computed: {
      readyToShowFrame() {
        return !this.loadingFrame && this.appUrl
      },
      displayCollapse() {
        return this.apps.length || this.refreshingApps
      },
      integratedAppIds() {
        const ids = []
        for (const app of this.apps) {
          ids.push(app.app_caveon_id)
        }
        return ids
      },
      unIntegratedApps() {
        const search = this.searchText.toLowerCase()
        const filtered = this.availableApps.filter(app => !this.integratedAppIds.includes(app.caveon_id) && app.first_name.toLowerCase().includes(search))
        const sorted = filtered.sort((current, next) => current.first_name.localeCompare(next.first_name))
        return sorted
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.searchApp {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

}
.app-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-alert {
  color: $red;
}
.img-container {
  padding: 10px; 
  display:flex; 
  border-radius: 3px;
  background: #ffffff;
}
.appCard {
  max-width: 31%;
  width: 31%;
  min-width: 325px;
  cursor: pointer;
  background: $light;
  transition: all .25s;
  &:hover {
    background:darken($light, 5%);
  }
  
}
.wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.invisible {
    visibility: hidden;
  }
</style>
