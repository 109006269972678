<template>
  <div v-if="!filtering">
    <b-table
      :fields="fields"
      :items="deliveries"
      @row-clicked="rowClicked"
      bordered
      hover
      id="deliveries"
      responsive
      small
      striped
      v-if="deliveries.length"
    >
      <template v-slot:head(modified_at)>
        <div
          @click="sort('modified_at')"
          class="d-flex justify-content-between align-items-center"
          style="cursor: pointer;"
        >
          Date
          <font-awesome-icon
            :icon="sortIcon('modified_at')"
          ></font-awesome-icon>
        </div>
      </template>
      <template v-slot:head(score)>
        <div
          @click="sort('score')"
          class="d-flex justify-content-between align-items-center"
          style="cursor: pointer;"
        >
          Score
          <font-awesome-icon :icon="sortIcon('score')"></font-awesome-icon>
        </div>
      </template>
      <template v-slot:cell(status)="row">
        <b-badge
          :title="row.item._computed.title"
          :variant="row.item._computed.variant"
          class="text-capitalize statuses"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          >{{ row.item.status[0] }}</b-badge
        >
      </template>
      <template v-slot:cell(score)="row">
        <span class="text-capitalize">{{ row.item._computed.score }}</span>
      </template>
      <template v-slot:cell(modified_at)="row">{{
        row.item._computed.date
      }}</template>
      <template v-slot:cell(tags)="row">
        <span>{{ row.item._computed.tags }}</span>
      </template>
      <template
        v-for="(schema, index) in schemaFields"
        v-slot:[fieldName(schema.key)]="row"
      >
        <span :key="index">{{ row.item.examinee.info[schema.key] }}</span>
      </template>
    </b-table>
    <div class="p-2" v-else>No Deliveries.</div>
  </div>
  <Spinner v-else />
</template>

<script>
  import Spinner from '../Spinner'

  export default {
    name: 'DeliveriesTable',
    components: {
      Spinner
    },
    props: {
      deliveries: {
        type: Array
      },
      fields: {
        type: Array
      },
      schemaFields: {
        type: Array
      },
      filtering: {
        type: Boolean
      },
      sortBy: {
        type: String
      },
      sortDirection: {
        type: String
      }
    },
    data() {
      return {
        avoidCustomSort: ['modified_at', 'score']
      }
    },
    methods: {
      rowClicked(record) {
        this.$router.push({
          name: 'projectDelivery',
          params: { deliveryId: record.id }
        })
      },
      sort(type) {
        this.$emit('sort', type)
      },
      sortIcon(type) {
        let sortIcon = 'sort'
        if (this.sortBy === type) {
          if (this.sortDirection) {
            sortIcon = 'sort-down'
          } else {
            sortIcon = 'sort-up'
          }
        }
        return sortIcon
      },
      fieldName(key) {
        return `cell(${key})`
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>
