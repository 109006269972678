<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle="dropdownId"
        variant="primary"
        >Forms</b-button
      >
    </b-card-header>

    <b-collapse :id="dropdownId" :visible="expand" v-if="items.length">
      <b-card-body>
        <b-table
          :fields="fieldsToDisplay"
          :items="items"
          @row-clicked="editForm"
          hover
          responsive
          small
          striped
        >
          <template v-slot:table-colgroup="scope">
            <col :key="field.key" v-for="field in scope.fields" />
          </template>
          <template v-slot:cell(buttons)="row">
            <b-button-group class="float-right">
              
              <b-button
                :disabled="shouldDisable"
                @click="editForm(row.item)"
                class="ml-1"
                size="sm"
                variant="white"
                >Edit</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  export default {
    name: 'FormsListing',
    props: {
      forms: {
        type: Array
      },
      expand: {
        type: Boolean
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      forms: {
        handler: function(newValue) {
          this.forms = newValue // eslint-disable-line vue/no-mutating-props
          this.processForms()
        },
        deep: true
      }
    },
    data() {
      return {
        dropdownId: '',
        fieldsToDisplay: [
          { key: 'name', sortable: true },
          { key: 'version', sortable: true },
          { key: 'items', sortable: true },
          { key: 'exposures', sortable: true },
          {
            key: 'difficulty',
            sortable: true,
            formatter: difficulty => {
              if (!difficulty) {
                return (0).toFixed(2)
              }
              return difficulty.toFixed(2)
            }
          },
          { key: 'buttons', label: '' }
        ],
        items: []
      }
    },
    created() {
      this.dropdownId = 'dropdown-' + this._uid
      this.processForms()
    },
    methods: {
      processForms() {
        this.items = []

        for (const form of this.forms) {
          this.items.push({
            name: form.name,
            version: `Version ${form.live_version_number} (${form.num_versions})`,
            items: this.getItemCount(form),
            exposures: form.num_exposures,
            id: form.id,
            difficulty: form.difficulty
          })
        }
      },
      editForm(form) {
        if (this.shouldDisable) {
          return
        }
        this.$router.push({
          name: 'projectFormEditor',
          params: { formId: form.id }
        })
      },
      getItemCount(form) {
        let count = 0
        if (!form.version) {
          return form.num_items || count
        }
        for (const group of form.version.groups) {
          count = group.item_ids.length + count
          // Count items in selection rules
          if (group?.selection_rules) {
            for (const rule of group.selection_rules) {
              if (rule?.count) {
                // Rule selects all items
                count += rule.count
              }
              if (rule?.num_items) {
                // Rule selects certain number of items
                count += rule.num_items
              }
            }
          }
        }
        return count
      }
    }
  }
</script>

<style lang="scss" scoped></style>
