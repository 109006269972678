<template>
  <div>
    <div>
      <small v-if="showRestrictionMessage"
        >Changing custom fields could limit your access to this item</small
      >
    </div>
    <b-form-group
      :key="index"
      label-cols-lg="3"
      label-cols-sm="4"
      v-for="(field, index) in fields"
    >
      <template v-slot:label>
        <div style="overflow-wrap: break-word">
          <div>{{ getName(field) }}</div>
          <small>{{ getDescription(field) }}</small>
        </div>
      </template>
      <!-- eslint-disable vue/no-mutating-props -->
      <textarea-autosize
        v-if="field.type === 'text'"
        v-model="values[field.id]"
        :min-height="37"
        :required="field.required"
        :disabled="disabled(field)"
        class="form-control"
        placeholder="Text"
        rows="1"
        type="text"
      />

      <b-form-input
        v-if="field.type === 'number'"
        v-model.number="values[field.id]"
        :required="field.required"
        :disabled="disabled(field)"
        placeholder="Number"
        type="number"
      ></b-form-input>

      <!-- eslint-enable vue/no-mutating-props -->
      <ItemMetaSelect
        v-if="field.type === 'select_one'"
        :field="field"
        :values="values"
        :disabled="disabled(field)"
      />

      <ItemMetaSelect
        v-if="field.type === 'select_multi'"
        :multiple="true"
        :field="field"
        :values="values"
        :disabled="disabled(field)"
      />
    </b-form-group>
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import ItemMetaSelect from './ItemMetaSelect.vue'

  export default {
    name: 'ItemMeta',
    components: {
      ItemMetaSelect
    },
    props: {
      show: {
        type: String
      },
      values: {
        type: Object
      }
    },
    data() {
      return {
        fields: []
      }
    },
    created() {
      const project = SESSION.project
      const hasCustomFields = project.meta.scorpion.custom_field_order && project.meta.scorpion.custom_field_order.length
      let customFields = []

      if (hasCustomFields) {
        customFields = project.meta.scorpion.custom_field_order.map(
          id => project.meta.scorpion.custom[id]
        )
        .filter(field => !field.by_option)
      }

      switch (this.show) {
        case 'meta':
          customFields = customFields.filter(field => !field.pinned)
          break
        case 'top':
          customFields = customFields.filter(field => field.pinned)
          break
      }

      this.fields = [...customFields]
    },
    methods: {
      getName(field) {
        let { name } = field

        if (field.required) {
          name += ' *'
        }

        return name
      },
      hasDescription(field) {
        return this.getDescription(field).length
      },
      getDescription(field) {
        return field.description || ''
      },
      disabled(field) {
        return !SESSION.isAdmin() && field.admin_only
      }
    },
    computed: {
      isPremium() {
        return SESSION.isPremium()
      },
      isWorkshop() {
        return SESSION.project.my_role_types.includes('workshop')
      },
      showRestrictionMessage() {
        return this.isWorkshop && this.fields && this.fields.length
      }
    }
  }
</script>
