import Vue from 'vue'
import Router from 'vue-router'

import Error from '../components/Error.vue'
import PageNotFound from '../components/PageNotFound.vue'

import ExamWatch from '../components/ExamWatch.vue'

import Project from '../components/Project.vue'
import ProjectsPage from '../components/ProjectsPage.vue'
import ProjectDeliveries from '../components/project/Deliveries.vue'
import ProjectItems from '../components/project/Items.vue'
import ProjectItem from '../components/project/Item.vue'
import ProjectDelivery from '../components/project/Delivery.vue'
import ProjectLms from '../components/project/Lms.vue'
import ProjectApps from '../components/project/Apps.vue'
import ProjectFullApp from '../components/project/FullApp.vue'
import ProjectSettings from '../components/project/Settings.vue'
import ProjectExport from '../components/project/Export.vue'
import ProjectForms from '../components/project/Forms.vue'
import ProjectLaunchOptions from '../components/project/LaunchOptions.vue'
import ProjectFormEditor from '../components/project/FormEditor.vue'
import ProjectImport from '../components/project/Import.vue'
import ProjectActivityLogs from '../components/project/ActivityLogs.vue'
import ProjectFlows from '../components/project/Flows.vue'
import ProjectFlow from '../components/project/Flow.vue'
import LanguagesHome from '../components/project/LanguagesHome.vue'
import LanguagesTranslationsHome from '../components/project/LanguagesTranslationsHome.vue'
import ProjectAccess from '../components/project/ProjectAccess.vue'

import App from '../components/App.vue'
import AppsPage from '../components/AppsPage.vue'
import AppDashboard from '../components/app/Dashboard.vue'
import AppSettings from '../components/app/Settings.vue'
import AppAccess from '../components/app/Access.vue'
import AppProjects from '../components/app/Projects.vue'

import Organization from '../components/Organization.vue'
import OrganizationsPage from '../components/OrganizationsPage.vue'
import OrganizationDashboard from '../components/organization/Dashboard.vue'
import OrganizationGroups from '../components/organization/Groups.vue'
import OrganizationSettings from '../components/organization/Settings.vue'
import OrganizationBilling from '../components/organization/Billing.vue'

Vue.use(Router)

export function createRouter() {
  return new Router({
    mode: 'history',
    scrollBehavior: () => ({
      y: 0
    }),
    routes: [
      {
        path: '/projects',
        component: ProjectsPage,
        name: 'projects',
        meta: {
          title: 'Projects',
          helpPage: 'projects'
        }
      },
      {
        path: '/projects/:projectId',
        component: Project,
        name: 'project',
        children: [
          {
            path: 'settings',
            component: ProjectSettings,
            name: 'projectSettings',
            meta: {
              title: 'Settings',
              helpPage: 'project-settings'
            }
          },
          {
            path: 'forms',
            component: ProjectForms,
            name: 'projectForms',
            meta: {
              title: 'Forms',
              helpPage: 'project-forms'
            }
          },
          {
            path: 'forms/:formId',
            component: ProjectFormEditor,
            name: 'projectFormEditor',
            meta: {
              title: 'Forms',
              helpPage: 'project-forms-editor',
              backLink: 'projectForms',
              subPage: 'Form Details'
            }
          },
          {
            path: 'launchoptions',
            component: ProjectLaunchOptions,
            name: 'projectLaunchOptions',
            meta: {
              title: 'Launch Options',
              helpPage: 'project-launch-options'
            }
          },
          {
            path: 'deliveries',
            component: ProjectDeliveries,
            name: 'projectDeliveries',
            meta: {
              title: 'Deliveries',
              helpPage: 'project-deliveries'
            }
          },
          {
            path: 'deliveries/:deliveryId',
            component: ProjectDelivery,
            name: 'projectDelivery',
            meta: {
              title: 'Deliveries',
              helpPage: 'project-deliveries-details',
              backLink: 'projectDeliveries',
              subPage: 'Delivery Details'
            }
          },
          {
            path: 'items',
            component: ProjectItems,
            name: 'projectItems',
            meta: {
              title: 'Items',
              helpPage: 'project-items'
            }
          },
          {
            path: 'items/:itemId',
            component: ProjectItem,
            name: 'projectItem',
            meta: {
              title: 'Items',
              helpPage: 'project-items-details',
              backLink: 'projectItems',
              subPage: 'Item Details'
            }
          },
          {
            path: 'lms',
            component: ProjectLms,
            name: 'projectLms',
            meta: {
              title: 'LMS'
            }
          },
          {
            path: 'apps',
            component: ProjectApps,
            name: 'projectApps',
            meta: {
              title: 'Apps'
            }
          },
          {
            path: 'flows',
            component: ProjectFlows,
            name: 'projectFlows',
            meta: {
              title: 'Flows',
              helpPage: 'project-flow'
            }
          },
          {
            path: 'flows/:flowId',
            component: ProjectFlow,
            name: 'projectFlow',
            meta: {
              title: 'Flows',
              helpPage: 'project-flow',
              backLink: 'projectFlows',
              subPage: 'Flow Details'
            }
          },
          {
            path: 'apps/:appId',
            component: ProjectFullApp,
            name: 'projectAppFull',
            meta: {
              title: 'App'
            }
          },
          {
            path: 'export',
            component: ProjectExport,
            name: 'projectExport',
            meta: {
              title: 'Export',
              helpPage: 'project-export'
            }
          },
          {
            path: 'import',
            component: ProjectImport,
            name: 'projectImport',
            meta: {
              title: 'Import',
              helpPage: 'project-import'
            }
          },
          {
            path: 'languages',
            component: LanguagesHome,
            name: 'languagesHome',
            meta: {
              title: 'Languages',
              helpPage: 'project-languages'
            }
          },
          {
            path: 'languages/:languageId',
            component: LanguagesTranslationsHome,
            name: 'languagesTranslationsHome',
            meta: {
              title: 'Languages',
              helpPage: 'project-languagesTranslationsHome', // TODO: Add help page
              backLink: 'languagesHome',
              subPage: 'Translations'
            }
          },
          {
            path: 'activity',
            component: ProjectActivityLogs,
            name: 'projectActivityLogs',
            meta: {
              title: 'Activity Logs',
              helpPage: 'project-activity-logs' // TODO: Add help page
            }
          },
          {
            path: 'access',
            component: ProjectAccess,
            name: 'projectAccess',
            meta: {
              title: 'Project Access',
              helpPage: 'project-access', // TODO add help page
            }
          }
        ]
      },
      {
        path: '/apps',
        component: AppsPage,
        name: 'apps',
        meta: {
          title: 'Apps',
          helpPage: 'apps'
        }
      },
      {
        path: '/apps/:appId',
        component: App,
        name: 'app',
        children: [
          {
            path: 'dashboard',
            component: AppDashboard,
            name: 'appDashboard',
            meta: {
              title: 'Dashboard',
              helpPage: 'app-projects'
            }
          },
          {
            path: 'settings',
            component: AppSettings,
            name: 'appSettings',
            meta: {
              title: 'Settings'
            }
          },
          {
            path: 'access',
            component: AppAccess,
            name: 'appAccess',
            meta: {
              title: 'Access'
            }
          },
          {
            path: 'projects',
            component: AppProjects,
            name: 'appProjects',
            meta: {
              title: 'Projects',
              helpPage: 'app-projects'
            }
          }
        ]
      },
      {
        path: '/organizations',
        component: OrganizationsPage,
        name: 'orgs',
        meta: {
          title: 'Organizations',
          helpPage: 'orgs'
        }
      },
      {
        path: '/organizations/:orgId',
        component: Organization,
        name: 'org',
        children: [
          {
            path: 'dashboard',
            component: OrganizationDashboard,
            name: 'orgDashboard',
            meta: {
              title: 'Dashboard',
              helpPage: 'orgs-dashboard'
            }
          },
          {
            path: 'groups',
            component: OrganizationGroups,
            name: 'orgGroups',
            meta: {
              title: 'Org Access',
              helpPage: 'orgs-groups'
            }
          },
          {
            path: 'settings',
            component: OrganizationSettings,
            name: 'orgSettings',
            meta: {
              title: 'Settings',
              helpPage: 'orgs-settings'
            }
          },
          {
            path: 'billing',
            component: OrganizationBilling,
            name: 'orgBilling',
            meta: {
              title: 'Billing',
              helpPage: 'orgs-billing'
            }
          }
        ]
      },
      {
        path: '/examwatch',
        component: ExamWatch,
        name: 'examWatch',
        meta: {
          title: 'Exam Watch',
          helpPage: 'exam-watch'
        }
      },
      {
        path: '/error',
        component: Error,
        name: 'error',
        meta: {
          title: 'Error',
          helpPage: 'error'
        }
      },
      {
        path: '*',
        component: PageNotFound,
        name: 'notFound'
      }
    ]
  })
}
