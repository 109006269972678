import io from 'socket.io-client'
import Vue from 'vue'

import { LOCAL } from './local'
import { notificationVariant } from './misc'

const NAMESPACE = 'user'

const state = Vue.observable({
  connected: false,
  notifications: [],
  widgets: [],
  newNotifications: 0,
  adminMessage: null,
  accessToken: null
})

const connected = () => {
  state.connected = true
}

const addNotification = note => {
  delete note.unsubscribe_url

  note.variant = note.requires_manual_score
    ? 'manual-scoring'
    : notificationVariant(note.event)

  state.notifications.push(note)
  state.newNotifications++

  const notificationsToCache = state.notifications.slice(-100)
  LOCAL.notifications.set(notificationsToCache)
}

const setAdminMessage = message => {
  state.adminMessage = message
}

const socketListeners = [
  { event: 'connected', handler: connected },
  { event: 'add_notification', handler: addNotification },
  { event: 'admin_message', handler: setAdminMessage }
]

let socket = null
const init = (options) => {
  const authToken = `Bearer ${ state.accessToken }`

  if (options.query) {
    options.query.Authorization = authToken
  } else {
    options.query = { Authorization: authToken }
  }

  socket = io(`${window.location.origin}/${NAMESPACE}`, {
    ...options,
    transports: ['websocket', 'polling'] // use websocket first, if available
  })
  socket.on('connect_error', () => {
    // revert to classic upgrade
    socket.io.opts.transports = ['polling', 'websocket']
  })
  const existingNotifications = LOCAL.notifications.get()
  state.notifications = [...existingNotifications]

  socketListeners.map(socketListeners =>
    socket.on(socketListeners.event, socketListeners.handler)
  )
}

const clearNotificationCount = () => {
  state.newNotifications = 0
}

const setAccessToken = accessToken => {
  state.accessToken = accessToken

  if (socket) {
    socket.io.opts.query.Authorization = `Bearer ${ accessToken }`
  }
}

const clearAccessToken = () => {
  state.accessToken = null

  delete socket.io.opts.query.Authorization
}

export const socketProvider = {
  init,
  clearNotificationCount,
  setAdminMessage,
  setAccessToken,
  clearAccessToken,
  state,
  get socket() {
    return socket
  }
}
