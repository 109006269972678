<template>
  <b-card class="mb-1" no-body>
    <b-card-header class="p-1">
      <b-button
        block
        class="text-left"
        size="lg"
        v-b-toggle="dropdownId"
        variant="primary"
      >
        <div @click.stop class="d-inline" v-if="hasPermission">
          <b-form-checkbox
            :checked="allChecked"
            @change="checkAll"
            class="d-inline position-relative"
            style="bottom: -4px;"
          />
        </div>
        {{ dropdownHeader }}
        <b-button
          @click="editGroup"
          class="float-right"
          pill
          size="sm"
          v-if="!group.is_admin"
          variant="primary-light"
        >
          <font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
        </b-button>
      </b-button>
    </b-card-header>

    <b-collapse :id="dropdownId" :visible="expand" v-if="group.users.length">
      <b-card-body>
        <b-table
          :fields="fieldsToDisplay"
          :items="group.users"
          hover
          responsive
          small
          striped
        >
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: field.key === 'checked' ? '40px' : 'auto' }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:cell(checked)="scope">
            <b-form-checkbox :value="scope.item.caveon_id" v-model="checked" />
          </template>
        </b-table>
        <div v-if="org && projects">
          <b-card class="mt-4 p-0" bg-variant="light">
            <p v-if="projectAccess">This group has access to the following project roles:</p>
          <ProjectAccessListing :org="org" :projects="projects" :group="group" :projectAccess="projectAccess" :onRemoveProjectAccess="onRemoveProjectAccess" />
          <ProjectAccessEntry :org="org" :projects="projects" :group="group" :onUpdate="onProjectAccessUpdate" />
          </b-card>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  import ProjectAccessEntry from './ProjectAccessEntry.vue'
  import ProjectAccessListing from './ProjectAccessListing.vue'

  export default {
    name: 'GroupsListing',
    props: {
      group: {
        type: Object
      },
      expand: {
        type: Boolean
      },
      hasPermission: {
        type: Boolean
      },
      checkedUsers: {
        type: Object
      },
      projects: {
        type: Array,
        defaultValue: []
      },
      org: {
        type: Object
      },
      onProjectAccessUpdate: {
        type: Function,
        default: () => { /* NOOP */ }
      },
      projectAccess: {
        type: Array,
        defaultValue: []
      },
      onRemoveProjectAccess:  {
        type: Function,
        default: () => { /* NOOP */ }
      }
    },
    components: {
      ProjectAccessEntry,
      ProjectAccessListing
    },
    data() {
      return {
        dropdownId: '',
        fieldsToDisplay: [
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'email' }
        ],
        checked: [],
        allChecked: false
      }
    },
    watch: {
      checked(checked) {
        this.updateChecked(checked)
      },
      checkedUsers() {
        this.checked = []
        this.allChecked = false
      }
    },
    created() {
      if (this.hasPermission) {
        this.fieldsToDisplay.unshift({ key: 'checked', label: '' })
      }
      this.dropdownId = 'dropdown-' + this._uid
    },
    methods: {
      checkAll(checked) {
        if (checked) {
          const users = this.group.users.map(user => user.caveon_id)
          this.updateChecked(users)
        } else {
          this.updateChecked([])
        }
        this.allChecked = checked
      },
      updateChecked(checked) {
        this.checked = checked
        this.$emit('checkbox-changed', this.group.id, checked)
        if (checked.length < 1) {
          this.allChecked = false
        }
      },
      editGroup(event) {
        event.stopPropagation()
        this.$emit('edit-group', this.group.id)
      }
    },
    computed: {
      dropdownHeader() {
        return `${this.group.name} (${this.group.users.length})`
      }
    }
  }
</script>

<style lang="scss" scoped></style>
