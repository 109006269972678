<template>
  <b-row class="border mt-1">
    <b-col>
      <small>Response</small>
      <div v-markdown="essayResponse"></div>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Essay',
    props: {
      item: {
        type: Object
      }
    },
    computed: {
      essayResponse() {
        const response = this.item.for_exam.response
        if (response) {
          return response
        }
        return 'No Response'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
