<template>
    <div>
        <v-select @input="tagsUpdated($event)" :data-value="selectedTags" :options="projectTags"
            :reduce="option => option.name.trim().toLowerCase()" placeholder="Enter Tags" v-model="selectedTags" label="description"
            :multiple="multiple" :taggable="taggable" :create-option="
              value => ({
                name: value,
                description: '',
                verbose: '',
              })
            ">
            <template #no-options="{}">
                <em style="opacity: 0.5;">No results found.</em>
            </template>
            <template #option="{ name, description }">
                <div><b>{{name}}</b> {{ description }}</div>
            </template>
            <template #selected-option="{ name, description, verbose }">
                <div v-b-tooltip.hover.d700 :title="verbose"><b>{{name}}</b> {{ description }}</div>
            </template>
        </v-select>
    </div>
</template>
  

<script>
import { deepCopy } from '../../utils/misc'

const TAG_CHAR_LIMIT = 30
const ALL_TAGS_CHAR_LIMIT = 100

export default {
    name: 'TagSelector',
    props: {
        // Use to identify multiple TagSelector componenents in the same parent component
        index: {
            type: Number,
            default: -1
        },
        // Use to identify components at the same index
        type: {
            type: String,
            default: ''
        },
        // Allows multiple tags to be selected
        multiple: {
            type: Boolean,
            default: true
        },
        // Allows creation of new tags
        taggable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: true
        },
        tags: {
            type: Object,
            required: true
        },
        selected: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            selectedTags: []
        }
    },
    created() {
        // Clone to prevent mutating the prop
        this.selectedTags = deepCopy(this.selected)

        this.formatTags()
    },
    watch: {
        tags() {
            this.formatTags()
        }
    },
    methods: {
        tagsUpdated(tags) {
            this.$emit('tags-updated', tags, this.index, this.type)
        },
        formatTags() {
            for (const [index, tag] of this.selectedTags.entries()) {
                let tagName = tag
                if (tag?.name) {
                    tagName = tag.name
                }
                const existingTag = this.projectTags.find(tag => tag.name === tagName)
                if (existingTag) {
                    this.$set(this.selectedTags, index, existingTag)
                }
                else {
                    this.$set(this.selectedTags, index, { name: tagName, description: '', verbose: '' })
                }
            }
        }
    },
    computed: {
        projectTags() {
            const formattedTags = []
            for (const [tagName, tag] of Object.entries(this.tags)) {
                const allForms = []
                const formattedTag = { name: tagName, description: '', verbose: '' }
                if (tag?.forms) {
                    const trimmed = []
                    for (const formName of tag.forms) {
                        trimmed.push(formName.substring(0, TAG_CHAR_LIMIT))
                        allForms.push(formName)
                    }
                    let descr = `(Forms: ${trimmed.join(', ')})`
                    if (descr.length > ALL_TAGS_CHAR_LIMIT) {
                        descr = descr.substring(0, ALL_TAGS_CHAR_LIMIT) + '...)'
                    }
                    formattedTag.description = descr
                }
                if (allForms.length > 0) {
                    formattedTag.verbose = `Forms: ${allForms.join(', ')}`
                }
                formattedTags.push(formattedTag)
            }
            
            return formattedTags.sort((current, next) => current.name.localeCompare(next.name))
        }
    }
}
</script>

<style lang="scss" scoped>

</style>