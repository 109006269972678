<template>
	<div>
		<v-select
			@input="orgsChanged"
			:required="selected.length < 1"
			:reduce="option => option.id"
			:options="options"
			:value="selected"
			label="name"
			placeholder="Select"
			multiple
	>
			<template #no-options="">
				<em style="opacity: 0.5;">
					No organizations found.
				</em>
			</template>
		</v-select>
	</div>
</template>

<script>
  export default {
	name: 'OrgsInput',
	props: {
		options: {
			type: Array
		}
	},
	data() {
		return {
			selected: []
		}
	},
	methods: {
		orgsChanged (value) {
			this.selected = value

			this.$emit('orgs-changed', this.selected)
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
