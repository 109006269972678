<template>
  <b-row>
    <b-col>
      <b-row class="border mt-1">
        <b-col>
          <small>Response</small>
          <div v-markdown="externalResponse"></div>
        </b-col>
      </b-row>
      <b-row class="border mt-1" v-if="rows.length">
        <b-col>
          <small>Meta</small>
          <b-table :items="rows" hover responsive small striped></b-table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'External',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.createTableData()
    },
    data() {
      return {
        rows: []
      }
    },
    methods: {
      createTableData() {
        try {
          const externalMeta = this.item.for_exam.response.other.external_meta
          for (const meta in externalMeta) {
            const row = {
              key: meta,
              value: externalMeta[meta]
            }
            this.rows.push(row)
          }
        } catch (error) {
          this.meta = []
        }
      }
    },
    computed: {
      externalResponse() {
        const ext_resp = this.item.other?.external_response
        if (ext_resp) {
          return ext_resp
        }
        return 'No Response'
      }
    }
  }
</script>

<style lang="scss" scoped></style>
