import { HTTP } from './requests'
import { SEI_API_BASE } from './constants'


export const Language = class Language {
  static DEFAULT_PROPERTIES = {
    'auto_translate_language_code': null
  }

  constructor({ id, exam_id, name, properties } = {}) {
    this._raw = { id, exam_id, name, properties }

    this.id = id
    this.exam_id = exam_id
    this.name = name
    this.properties = properties
  }

  async create(formData) {
    try {
      this.sync(formData)

      const url = `${SEI_API_BASE}/exams/${this.exam_id}/languages`
      const response = await HTTP.post(url, this.toJson())

      this.sync(response.data)

      return { data: response.data }
    } catch (error) {
      this.rollback()
      return { error }
    }
  }

  async update(formData) {
    try {
      this.sync(formData)

      const url = `${SEI_API_BASE}/exams/${this.exam_id}/languages/${this.id}`
      const response = await HTTP.put(url, this.toJson())

      this.sync(response.data)

      return { data: response.data }
    } catch (error) {
      this.rollback()
      return { error }
    }
  }

  async delete() {
    try {
      const url = `${SEI_API_BASE}/exams/${this.exam_id}/languages/${this.id}`

      await HTTP.delete(url)
    } catch (error) {
      return { error }
    }
  }

  sync({ id, exam_id, name, properties }) {
    this.id = id
    this.exam_id = exam_id
    this.name = name
    this.properties = {
      ...this.properties,
      ...properties
    }
  }
  
  rollback() {
    const { id, exam_id, name, properties } = this._raw

    this.id = id
    this.exam_id = exam_id
    this.name = name
    this.properties = properties
  }

  toJson() {
    return {
      id: this.id,
      exam_id: this.exam_id,
      name: this.name,
      properties: {
        ...Language.DEFAULT_PROPERTIES,
        ...this.properties
      }
    }
  }

  static async FetchAll(projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/languages`
      const response = await HTTP.get(url)

      return { results: response.data.results.map((obj) => new Language(obj)) }
    } catch (error) {
      return { error }
    }
  }

  static async FetchOne(projectId, languageId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/languages/${languageId}`
      const response = await HTTP.get(url)

      return new Language(response.data)
    } catch (error) {
      return { error }
    }
  }
}


export const LanguageProgress = class LanguageProgress {
  constructor(progress) {
    this.complete = Object.keys(progress).reduce((count, key) => {
      count += progress[key].complete

      return count
    }, 0)

    this.notComplete = Object.keys(progress).reduce((count, key) => {
      count += progress[key].not_complete

      return count
    }, 0)

    this.total = this.complete + this.notComplete
  }

  get percentComplete() {
    if (this.total === 0) return 0

    return Math.round(this.complete / this.total * 100)
  }

  percentCompleteFriendly() {
    return this.percentComplete + '%'
  }

  static async FetchByLanguage(projectId, languageId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/languages/${languageId}/progress`
      const response = await HTTP.get(url)

      return new LanguageProgress(response.data)
    } catch (error) {
      return { error }
    }
  }
}

export const TranslationSupportedLanguages = class TranslationSupportedLanguages {
  constructor(projectId) {
    this.projectId = projectId
    this._supported_languages = {}
  }

  async fetchAll() {
    const response = await this._fetch(`${SEI_API_BASE}/exams/${this.projectId}/translations/translate/supported_languages`)

    if (response.error) return

    this._supported_languages = response.data
  }

  async _fetch(url) {
    try {
      return await HTTP.get(url)
    } catch (error) {
      return { error }
    }
  }

  _friendlyLanguageName(key) {
    return key.replace(/_/g, ' ')
  }

  get vue_options() {
    return Object.keys(this._supported_languages).map(key => {
      return {
        text: this._friendlyLanguageName(key),
        value: this._supported_languages[key]
      }
    })
  }

  get all() {
    return this._supported_languages
  }
}
