<template>
  <b-container fluid>
    
    <div v-if="segment.type === 'content'">
      <label class="mt-2" :for="'segment-content-' + index">Content</label>
      <!-- eslint-disable vue/no-mutating-props -->
      <textarea-autosize
        :min-height="37"
        class="mb-3 form-control"
        :id="'segment-content-' + index"
        ref="segment"
        rows="1"
        v-model="segment.text"
      />
      <!-- eslint-enable vue/no-mutating-props -->
    </div>

    <div v-if="segment.type === 'select' || segment.type === 'short_answer'">

      <label class="mt-2" :for="'segment-options-' + index">{{ segmentType }}
        <b-button
          href="#"
          tabindex="0"
          v-b-tooltip.focus
          title="Correct options are green. Incorrect options are white. Click on an option to switch." 
          variant="unstyled"
          class="p-0"
        >
          <font-awesome-icon icon="info-circle"></font-awesome-icon>
        </b-button>
      </label>
      <!-- eslint-disable vue/no-mutating-props -->
      <v-select
        taggable
        multiple
        :id="'segment-options-' + index"
        ref="segment"
        style="background-color: white"
        :options="segment.options"
        :create-option="
          option => ({
            text: option,
            is_correct: true,
            index: segment.options.length
          })
        "
        label="text"
        v-model="segment.options"
      >
        <template #selected-option="option">
          <b-button
            :style="
              option.is_correct
                ? 'background-color: #69b701; color: #fff'
                : 'background-color: #ffffff; color: #000'
            "
            @mousedown.stop
            @click="toggleSegmentOption(option)"
            size="sm"
            class="optionbutton"
          >
            {{ option.text }}
          </b-button>
          <b-button
            @click="editOption(option)"
            variant="info"
            size="sm"
          >
            Edit
          </b-button>
        </template>
        <template #no-options="{}">
          <small><i>Type a new option, then press <b>Enter</b> to add it.</i></small>
        </template>
      </v-select>
      <!-- eslint-enable vue/no-mutating-props -->

      <div v-if="isWeighted">
        <label class="mt-2" for="segment-weight">Weight</label>
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-input
          id="segment-weight"
          name="segment-weight"
          type="number"
          min="0"
          max="100"
          v-model.number="segment.weight"
        ></b-form-input>
        <!-- eslint-enable vue/no-mutating-props -->
      </div>

      <div v-if="segment.type === 'short_answer'">
        <label class="mt-2" for="segment-method">Method</label>
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-select
          id="segment-method"
          :options="methodOptions"
          v-model="segment.scoring"
        ></b-form-select>
        <!-- eslint-enable vue/no-mutating-props -->
      </div> 

      <div v-if="segment.type === 'select'">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-checkbox
          :id="'segment-randomize-' + index"
          v-model="segment.randomize"
          class="mt-2"
        >
          Randomize options
        </b-form-checkbox>
        <!-- eslint-enable vue/no-mutating-props -->
      </div>
      
    </div>
  </b-container>
</template>

<script>
  export default {
    name: 'ItemOptionSegment',
    props: {
      segment: {
        type: Object
      },
      scoring: {
        type: String
      },
      index: {
        type: Number
      }
    },
    data() {
      return {
        methodOptions: [
          { text: 'Exact', value: 'exact' },
          { text: 'Ignore Case', value: 'ignore_case' },
          { text: 'Regular Expression', value: 'regex' }
        ],
      }
    },
    methods: {
      toggleSegmentOption(option) {
        option.is_correct = !option.is_correct
        this.segment.options.splice(option.index, 1, option) // eslint-disable-line vue/no-mutating-props
      },
      editOption(option) {
        const editedText = prompt('Edit option:', option.text);
        if (editedText !== null && editedText.trim() !== '') {
          option.text = editedText.trim();
          const index = this.segment.options.findIndex(opt => opt.index === option.index);
          if (index !== -1) {
            this.$set(this.segment.options, index, option);
          }
        }
      },
      giveFocus() {
        if (this.segment.type === 'select' || this.segment.type === 'short_answer') {
          this.$refs.segment.$refs.search.focus()
        } else {
          this.$refs.segment.$el.focus()
        }
      }
    },
    computed: {
      segmentType() {
        if (this.segment.type === 'select') {
          return 'Select Options'
        }
        if (this.segment.type === 'short_answer') {
          return 'Short Answer Options'
        }

        return 'Invalid Item Type'
      },
      isWeighted() {
        if (this.scoring === 'weighted') {
          return true
        }
        return false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .optionbutton {
    border-radius: 0;
    border:none;
    margin-right:4px;
  }
</style>
