import axios from 'axios'
import jwt_decode from 'jwt-decode'

let caveonIdUrl = ''

let accessToken = ''

const timer = ms => new Promise(res => setTimeout(res, ms))

export const setCaveonIdUrl = (url) => {
  caveonIdUrl = url
}

export const setAccessToken = (token) => {
  accessToken = token
}

export function customAxios() {
  const myAxios = axios.create({})

  myAxios.interceptors.request.use(async function (config) {
    if (!accessToken) return config

    const decoded = jwt_decode(accessToken)

    if (Date.now() >= decoded.exp * 1000) {
      const caveonIdFrame = document.getElementById('caveonid-frame')

      const payload = {
        type: 'refresh_access_token',
        client: 'scorpion'
      }

      if (caveonIdFrame?.contentWindow && caveonIdUrl) {
        caveonIdFrame.contentWindow.postMessage(payload, caveonIdUrl)
      }

      for (let i = 0; i < 5; i++) {
        await timer(1000)

        try {
          const newDecoded = jwt_decode(accessToken)

          if (Date.now() < newDecoded.exp * 1000) break
        } catch (error) {
          continue
        }
      }
    }

    config.headers.Authorization = 'Bearer ' + accessToken

    return config
  })

  return myAxios
}

export function standardAxios() {
  return axios.create({})
}
