import { render, staticRenderFns } from "./DeliveryListing.vue?vue&type=template&id=66db6e19&scoped=true&"
import script from "./DeliveryListing.vue?vue&type=script&lang=js&"
export * from "./DeliveryListing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66db6e19",
  null
  
)

export default component.exports