<template>
  <b-button v-b-modal.api-credentials-modal variant="white">
    Get API Credentials

    <b-modal
      @ok.prevent="getApiCredentials"
      @hidden="clearApiCredentials"
      id="api-credentials-modal"
      size="xl"
      title="API Credentials"
    >
      <div v-if="!credentials">
        Getting API Credentials will invalidate all previous credentials. This
        action is not recoverable. Are you sure you want to continue?
      </div>
      <div v-else>
        <b-form-group
          label="ID"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="id-input"
        >
          <b-input-group>
            <b-form-input
              :value="credentials.id"
              id="id-input"
              readonly
              type="text"
            ></b-form-input>
            <b-input-group-append>
              <b-tooltip
                :show.sync="showCopyTooltip['id-input']"
                target="id-copy-btn"
                title="Copied!"
                triggers="manual"
                noninteractive
              ></b-tooltip>
              <b-button
                @click="copyText('id-input')"
                id="id-copy-btn"
                variant="white"
                >Copy</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Secret"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="secret-input"
        >
          <b-input-group>
            <b-form-input
              :value="credentials.secret"
              id="secret-input"
              readonly
              type="text"
            ></b-form-input>
            <b-input-group-append>
              <b-tooltip
                :show.sync="showCopyTooltip['secret-input']"
                target="secret-copy-btn"
                title="Copied!"
                triggers="manual"
                noninteractive
              ></b-tooltip>
              <b-button
                @click="copyText('secret-input')"
                id="secret-copy-btn"
                variant="white"
                >Copy</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Authorization"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="authorization-input"
        >
          <b-input-group>
            <b-form-input
              :value="credentials.token"
              id="authorization-input"
              readonly
              type="text"
            ></b-form-input>
            <b-input-group-append>
              <b-tooltip
                :show.sync="showCopyTooltip['authorization-input']"
                target="auth-copy-btn"
                title="Copied!"
                triggers="manual"
                noninteractive
              ></b-tooltip>
              <b-button
                @click="copyText('authorization-input')"
                id="auth-copy-btn"
                variant="white"
                >Copy</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button @click="cancel" variant="white">{{
          credentials ? 'Close' : 'Cancel'
        }}</b-button>
        <b-button
          :disabled="isGettingApiCredentials"
          @click="ok"
          variant="primary-light"
          v-if="!credentials"
        >
          <b-spinner
            label="Small Spinner"
            small
            v-show="isGettingApiCredentials"
          ></b-spinner
          >&nbsp;Continue
        </b-button>
      </template>
    </b-modal>
  </b-button>
</template>

<script>
import { EVENT } from '../../utils/event-bus'
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants'

async function getApiCredentials(appId) {
  try {
    const url = `${SEI_API_BASE}/apps/${appId}/api_credentials`
    const response = await HTTP.get(url)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

export default {
  name: 'ApiCredentials',
  props: {
    appId: {
      type: String
    }
  },
  data() {
    return {
      credentials: null,
      isGettingApiCredentials: false,
      showCopyTooltip: {
        'id-input': false,
        'secret-input': false,
        'authorization-input': false
      }
    }
  },
  methods: {
    async getApiCredentials() {
      this.isGettingApiCredentials = true

      const { data, error } = await getApiCredentials(this.appId)

      this.isGettingApiCredentials = false

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to get API credentials! Please try again.'
        })
      }

      const token = `${data.id}:${data.secret}`
      const encoded = btoa(token)
      data.token = `Basic ${encoded}`

      this.credentials = data
    },
    clearApiCredentials() {
      this.credentials = null
    },
    copyText(idSelector) {
      let input = document.querySelector('#' + idSelector)
      input.select()
      document.execCommand('copy')
      this.showCopyTooltip[idSelector] = true
      setTimeout(() => (this.showCopyTooltip[idSelector] = false), 1200)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
