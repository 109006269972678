<template>
  <div>
    <b-modal
      @ok.prevent="saveSettingsBulk()"
      ref="bulk-settings-modal"
      size="xl"
      title="Bulk settings"
    >
      <p>Change settings by type</p>
      <b-card class="mb-1" no-body v-for="selectedType in selectedTypes" :key="selectedType">
        <b-card-header class="p-1">
          <b-button
            block
            class="text-left"
            size="lg"
            @click="toggleVisibility(selectedType)"
            variant="primary"
            >{{ types[selectedType] }} ({{ count[selectedType] }})</b-button
          >
        </b-card-header>
        <b-collapse v-model="visible[selectedType]">
          <b-card-body>
            <ItemSettings
              :fields="fieldsByType[selectedType]"
              :type="selectedType"
              :itemIds="allItemIds"
              bulk
            />
          </b-card-body>
        </b-collapse>
      </b-card>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            :disabled="saving"
            @click="ok"
            variant="secondary"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="saving"
            ></b-spinner
            >&nbsp;Update settings for {{ selected.ids.length }} items
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ItemSettings from './ItemSettings'
  import { SESSION } from '../../utils/session'
  import { HTTP } from '../../utils/requests'
  import {
    DEFAULT_ITEM_CONTENT,
    DEFAULT_ITEM_SETTINGS,
    DEFAULT_ITEM_SETTINGS_FOR_BULK_UPDATE,
    SEI_API_BASE
  } from '../../utils/constants'
  import { deepCopy } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'

  async function getAllItemIds(projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/all_ids`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function bulkUpdateSettings(projectId, itemIds, typeSettings) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/bulk_settings`
      const payload = {
        item_ids: itemIds,
        type_settings: typeSettings
      }
      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  function removeKeys(typeSettings) {
    for (const [type, obj] of Object.entries(typeSettings)) {
      const allowedKeys = DEFAULT_ITEM_SETTINGS_FOR_BULK_UPDATE[type]

      for (const key of Object.keys(obj.settings)) {
        if (!(key in allowedKeys)) delete obj.settings[key]
      }
    }
  }

  const ITEM_TYPES = {
    build_list: 'Build List',
    domc: 'DOMC',
    essay: 'Essay',
    external: 'External',
    hotspot: 'Hotspot',
    likert: 'Likert',
    matching: 'Matching',
    multiple_choice: 'Multiple Choice',
    scale: 'Scale',
    short_answer: 'Short Answer',
    smart: 'Smart',
    true_false: 'True False',
    fill_in_the_blanks: 'Fill In the Blanks'
  }

  export default {
    name: 'ItemSettingsBulk',
    components: {
      ItemSettings
    },
    props: {
      items: {
        type: Array,
        defaultValue: () => []
      },
      selected: {
        type: Object,
        defaultValue: () => {}
      },
      reloadItems: {
        type: Function
      }
    },
    data() {
      return {
        saving: false,
        fieldsByType: {},
        visible: {},
        allItemIds: [],
        types: ITEM_TYPES
      }
    },
    created() {
      this.setAllItemIds()
      this.setFieldsByType()
    },
    methods: {
      async setAllItemIds() {
        const { data } = await getAllItemIds(SESSION.project.id)

        this.allItemIds = data
      },
      showModal() {
        this.$refs['bulk-settings-modal'].show()
      },
      async saveSettingsBulk() {
        const itemIds = [ ...this.selected.ids ]
        const typeSettings = this.selectedTypes.reduce((settingsByType, selectedType) => {
          settingsByType[selectedType] = this.fieldsByType[selectedType]

          return settingsByType
        }, {})

        removeKeys(typeSettings)

        this.saving = true
        const { error } = await bulkUpdateSettings(SESSION.project.id, itemIds, typeSettings)

        if (error) {
          this.saving = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to update settings.'
          })
        }

        EVENT.alert({
          variant: 'success',
          message: 'Update complete. Reloading...'
        })

        setTimeout(() => {
          this.reloadItems()
        }, 3000)
      },
      setFieldsByType() {
        Object.keys(this.types).map(type => {
          const options = {
            name: '',
            enemy_ids: [],
            points: 1,
            content: deepCopy(DEFAULT_ITEM_CONTENT[type]),
            settings: deepCopy(DEFAULT_ITEM_SETTINGS[type]),
            meta: { scorpion: {} },
            feedback: {}
          }

          this.$set(this.fieldsByType, type, options)
        })
      },
      toggleVisibility(selectedType) {
        this.visible[selectedType] = !this.visible[selectedType]
      },
    },
    computed: {
      selectedTypes() {
        const types = {}

        this.selected.ids.forEach(itemId => {
          if (this.itemsMap[itemId]) {
            types[this.itemsMap[itemId].latest_item_type] = 1
          }
        })

        return Object.keys(types)
      },
      itemsMap() {
        return this.items.reduce((map, item) => {
          map[item.id] = item

          return map
        }, {})
      },
      count() {
        return this.selected.ids.reduce((counts, itemId) => {
          const item = this.itemsMap[itemId]

          if (item) {
            if (!counts[item.latest_item_type]) {
              counts[item.latest_item_type] = 0
            }

            counts[item.latest_item_type]++
          }

          return counts
        }, {})
      }
    }
  }
</script>
