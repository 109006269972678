<template>
  <div>
    <TopNavigation :resource="app" />
    <div v-if="!loading">
      <router-view :myApp="app" v-if="!error" @app-updated="onAppUpdated" />
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load app.
          <b-button @click="reload" variant="danger">Reload</b-button>
        </b-alert>
      </b-container>
    </div>
    <!-- Loading Spinner -->
    <Spinner v-else />
  </div>
</template>

<script>
  import { SEI_API_BASE } from '../utils/constants'
  import { HTTP } from '../utils/requests'

  import TopNavigation from './TopNavigation'
  import Spinner from './Spinner'

  async function getApp(appId) {
    try {
      const url = `${SEI_API_BASE}/apps/${appId}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'App',
    components: {
      TopNavigation,
      Spinner
    },
    created() {
      this.getApp()
    },
    data() {
      return {
        app: null,
        loading: true,
        error: false
      }
    },
    methods: {
      async getApp() {
        const { data, error } = await getApp(this.$route.params.appId)

        this.loading = false

        if (error) {
          return (this.error = true)
        }

        this.app = data
        this.error = false
      },
      reload() {
        this.loading = true
        this.getApp()
      },
      onAppUpdated (app) {
        this.app = { ...this.app, ...app }
      },
    }
  }
</script>

<style lang="scss" scoped></style>
