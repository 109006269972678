
<template>
<div>
  <b-row class="mt-4">
    <b-col>
      <b-row v-for="(part, index) in this.smartParts" :key="index">
        <b-col>
          <b-card style="background: #eee;" class="mb-4">
            <b-card-text>
              <b-row>
              <b-col>
                <small>Source {{ index + 1 }} Preview:</small><br>
                <strong>{{ partPreview(part) }}</strong>
              </b-col>
              <b-col cols="3" md="2" class="text-right" >
                  <b-button-group class="mt-1">
                    <b-button :disabled="part._loading" @click="openSmartItem(index)" variant="white">
                      <b-spinner label="Small Spinner" small v-show="part._loading"></b-spinner
                      >&nbsp;Edit&nbsp;<font-awesome-icon icon="external-link-alt"></font-awesome-icon>
                    </b-button>
                    <b-button variant="white" @click="deleteSmartPart(index)">
                      <b-spinner label="Small Spinner" small v-show="part._deleting"></b-spinner
                      >&nbsp;<font-awesome-icon icon="trash"></font-awesome-icon>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-card-text>
            <b-card-body class="p-0">
              <b-row v-show="showAdvanced" class="pt-3" style="border-top: 1px solid #00000030;">
                <b-col sm="2">
                  Weight<br>
                </b-col>
                <b-col>
                  <b-form-input type="number" v-model.number="part.weight" @change="handleWeightChange" min="0" max="100" step="1"></b-form-input>
                </b-col>
                <b-col sm="6">
                  <i>Approximately {{ partPercent(part) }}% of renderings will use this source. <small>( {{ part.weight }} of {{ totalWeight }} )</small></i>
                </b-col>
              </b-row>
              <b-row class="mt-3" v-show="showAdvanced">
                <b-col>

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-button variant="dark" @click="addSmartPart()" :disabled="loadingPart">
        <b-spinner label="Small Spinner" small v-show="loadingPart"></b-spinner
        >&nbsp;{{ addPartBtnText }}
      </b-button>
    </b-col>
  </b-row>
</div>
</template>

<script>
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'


  async function smartUrl(projectId, itemId, index) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/smartitem_editor_url?index=${index}`
      const response = await HTTP.get(url)

      return { data: response.data.url }
    } catch (error) {
      return { error }
    }
  }


  async function addPart(projectId, itemId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/smartitem_part`
      const response = await HTTP.post(url, { action: 'add' })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }


  async function deletePart(projectId, itemId, id) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/smartitem_part`
      const response = await HTTP.post(url, { action: 'delete', id })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }


  function wrapPart(rawPart) {
    return {
      ...rawPart,
      _loading: false,
      _deleting: false
    }
  }

  function unwrapPart(part) {
    const rawPart = { ...part }

    delete rawPart._loading
    delete rawPart._deleting

    return rawPart
  }


  export default {
    name: 'SmartItemContent',
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      propSmartItemPartChanged: {
        type: Function,
        default: () => { /* NOOP */ }
      }
    },
    created() {
      if (this.item.smartitem_parts) {
        this.smartParts = [ ...this.item.smartitem_parts.map(wrapPart) ]
        this.previewTokens = [ ...this.item.version.smartitem_preview_tokens ]
      }
    },
    data() {
      return {
        smartParts: [],
        loadingPart: false,
        previewTokens: []
      }
    },
    methods: {
      async openSmartItem(index) {
        const part = this.smartParts[index]

        part._loading = true

        const { data, error } = await smartUrl(
          this.$route.params.projectId,
          this.$route.params.itemId,
          index
        )

        part._loading = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to open the SmartItem Editor.'
          })
        }

        const partPreviewToken = this.previewTokens[index] || this.item.version.preview_token
        const previewUrl = `${location.origin}/take/?preview_token=${partPreviewToken}`
        const primaryColor = encodeURIComponent('#6c456e')
        const secondaryColor = encodeURIComponent('#69b701')
        const smartItemUrl = `${data}&previewUrl=${encodeURIComponent(previewUrl)}&restrictTemplateSelection=true&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}`

        open(smartItemUrl, '_blank')
      },
      async addSmartPart() {
        if (this.loadingPart) return

        this.loadingPart = true
        const { data, error } = await addPart(
          this.$route.params.projectId,
          this.$route.params.itemId
        )
        this.loadingPart = false
        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to add SmartItem part.'
          })
        }

        this.smartParts = [ ...data.smartitem_parts.map(wrapPart) ]
        this.previewTokens = [ ...data.smartitem_preview_tokens ]
      },
      async deleteSmartPart(index) {
        const part = this.smartParts[index]

        part._deleting = true

        const { data, error } = await deletePart(
          this.$route.params.projectId,
          this.$route.params.itemId,
          part.id
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete SmartItem part.'
          })
        }

        this.smartParts = [ ...data.smartitem_parts.map(wrapPart) ]
      },
      handleWeightChange(changedWeightVal) {
        const isDecimal = Number(changedWeightVal) !== Math.trunc(changedWeightVal)
        const isEmpty = changedWeightVal === ''

        if ((isDecimal || isEmpty) && this.fixWeights()) {          
            EVENT.alert({
            variant: 'danger',
            message: 'Weight must be an integer. Decimal values truncated.'
          })
        }

        this.propSmartItemPartChanged(this.smartParts.map(unwrapPart))
      },
      fixWeights() {
        this.smartParts.forEach(part => {
          part.weight = part.weight || 0
          part.weight = Math.trunc(part.weight)
        })

        return true
      },
      partPreview(part) {
        if (this.item.version.preview_smart_parts) {
          return this.item.version.preview_smart_parts[part.id]
        }

        return 'Error loading preview'
      },
      partPercent(part) {
        return Math.round(this.totalWeight && part.weight / this.totalWeight * 100)
      }
    },
    computed: {
      totalWeight() {
        return this.smartParts.reduce((total, next) => {
          total += next.weight || 0

          return total
        }, 0)
      },
      addPartBtnText() {
        if (this.smartParts.length) {
          return 'Add source'
        }

        return 'Start here'
      },
      showAdvanced() {
        return this.smartParts.length > 1
      }
    }
  }
</script>
