var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[_c('b-navbar',{attrs:{"sticky":"","variant":"light"}},[_c('b-button-group',[_c('b-button',{attrs:{"disabled":_vm.shouldDisable('manage_forms'),"variant":"secondary"},on:{"click":_vm.openNewFormModal}},[_vm._v("Add Form")]),_c('b-button',{attrs:{"disabled":_vm.shouldDisable('manage_forms'),"variant":"secondary"},on:{"click":_vm.openNewConfigurationModal}},[_vm._v("Add Configuration")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.import-forms-modal",modifiers:{"import-forms-modal":true}}],attrs:{"disabled":_vm.shouldDisable(['view_items', 'manage_forms']),"variant":"white"}},[_vm._v("Import Forms")])],1)],1),_c('b-container',{staticClass:"mt-3",attrs:{"fluid":""}},[(_vm.error)?_c('b-alert',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"show":"","variant":"danger"}},[_vm._v(" Failed to load forms and configurations. "),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.getForms}},[_vm._v("Reload")])],1):_c('div',[_c('b-button',{attrs:{"size":"sm","variant":"link"},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_vm._v(_vm._s(_vm.expandOrCollapse))]),_c('FormsListing',{attrs:{"expand":_vm.expand,"forms":this.forms.results,"shouldDisable":_vm.shouldDisable(['view_items', 'manage_forms'])}}),_c('ConfigurationListing',{attrs:{"configurations":_vm.configurations,"expand":_vm.expand,"shouldDisable":_vm.shouldDisable('manage_forms')},on:{"edit-configuration":_vm.openEditConfigurationModal}})],1)],1)],1):_c('Spinner'),_c('b-modal',{attrs:{"id":"new-form-modal","size":"xl","title":"New Form"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button-group',[_c('b-button',{attrs:{"variant":"white"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"form":"new-form","type":"submit","variant":"secondary"}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCreatingForm),expression:"isCreatingForm"}],attrs:{"label":"Small Spinner","small":""}}),_vm._v(" Create ")],1)],1)]}}])},[_c('b-form',{attrs:{"id":"new-form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"form-name"}},[_vm._v("Form Name *")]),_c('b-form-input',{attrs:{"autofocus":"","id":"form-name","required":"","type":"text"},model:{value:(_vm.formName),callback:function ($$v) {_vm.formName=$$v},expression:"formName"}})],1),_c('ConfigurationSelect',{attrs:{"configurations":_vm.configurations,"selected-configuration":_vm.formConfiguration,"stack-name":""},on:{"configuration-selected":_vm.onConfigurationSelected}})],1)],1),_c('b-modal',{attrs:{"id":"new-configuration-modal","size":"xl","title":"New Configuration"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button-group',[_c('b-button',{attrs:{"variant":"white"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"secondary","disabled":_vm.savingConfiguration},on:{"click":_vm.saveNewConfiguration}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.savingConfiguration),expression:"savingConfiguration"}],attrs:{"label":"Small Spinner","small":""}}),_vm._v(" Create ")],1)],1)]}}])},[_c('ConfigurationFields',{attrs:{"configuration":_vm.configuration,"defaultConfiguration":_vm.configurations[0]}})],1),_c('b-modal',{attrs:{"id":"edit-configuration-modal","size":"xl","title":_vm.editConfigurationModalTitle,"scrollable":""},on:{"hide":_vm.onConfigEditorClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"text-right w-100"},[(!_vm.configuration.is_default && !_vm.clone)?_c('b-button',{staticClass:"float-left",attrs:{"variant":"white","disabled":_vm.configurationSaveState},on:{"click":_vm.deleteConfiguration}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button-group',[_c('b-button',{attrs:{"variant":"white"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"secondary","disabled":_vm.configurationSaveState},on:{"click":function($event){return _vm.saveConfiguration(_vm.clone)}}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.configurationSaveState),expression:"configurationSaveState"}],attrs:{"label":"Small Spinner","small":""}}),_vm._v(" Save ")],1)],1)],1)]}}])},[_c('ConfigurationEditor',{attrs:{"configuration":_vm.configuration,"configurations":_vm.configurations,"edit-only":""},on:{"configuration-changed":_vm.onConfigurationChanged,"configuration-deleted":_vm.onConfigurationDeleted,"configuration-save-state":_vm.onConfigurationSaveState}})],1),_c('b-modal',{attrs:{"id":"import-forms-modal","size":"xl","title":"Import Forms"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button-group',[_c('b-button',{attrs:{"variant":"white"},on:{"click":cancel}},[_vm._v("Cancel")])],1)]}}])},[_c('input',{attrs:{"id":"forms-csv-file","type":"file"},on:{"change":_vm.importFromCSV}}),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.importing || _vm.saving),expression:"importing || saving"}],attrs:{"label":"Small Spinner","small":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }