<template>
  <div>
    <b-button
      variant="white"
      size="sm"
      class="mr-1"
      @click="translate()"
    >
      Auto-translate all {{ getLanguageName() }}
    </b-button>
  </div>
</template>
<script>
import {SESSION} from '../../utils/session'
import { EVENT } from '../../utils/event-bus'


export default {
  name: 'TranslateManyContentButton',
  components: {},
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    },
    object: {
      type: Object
    },
    setFieldsLoading: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      supportedLanguages: null
    }
  },
  async created() {
    this.supportedLanguages = SESSION.supportedLanguages
  },
  methods: {
    async translate() {
      this.setFieldsLoading(true)
      const response = await this.object.auto_translate(this.targetLanguage)
      this.setFieldsLoading(false)

      if (response.error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to translate'
        })
      }
    },
    getLanguageName() {
      if (!this.supportedLanguages) return ''

      for (const [languageName, languageCode] of Object.entries(this.supportedLanguages.all)) {
        if (this.targetLanguage === languageCode) return `(${languageName})`.replace(/_/g, ' ')
      }

      return ''
    }
  },
  computed: {
    targetLanguage() {
      return this.targetLanguageCode || this.language.properties.auto_translate_language_code || 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
