<template>
  <div>
    <div v-if="!loading">
      <b-navbar sticky variant="light">
        <b-button-group>
          <b-button :disabled="shouldDisable('manage_forms')" @click="openNewFormModal" variant="secondary"
            >Add Form</b-button
          >
          <b-button :disabled="shouldDisable('manage_forms')" @click="openNewConfigurationModal" variant="secondary"
            >Add Configuration</b-button
          >
          <b-button :disabled="shouldDisable(['view_items', 'manage_forms'])" v-b-modal.import-forms-modal variant="white"
            >Import Forms</b-button
          >
        </b-button-group>
      </b-navbar>

      <b-container class="mt-3" fluid>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          v-if="error"
          variant="danger"
        >
          Failed to load forms and configurations.
          <b-button @click="getForms" variant="danger">Reload</b-button>
        </b-alert>
        <div v-else>
          <b-button @click="expand = !expand" size="sm" variant="link">{{
            expandOrCollapse
          }}</b-button>

          <FormsListing
            :expand="expand"
            :forms="this.forms.results"
            :shouldDisable="shouldDisable(['view_items', 'manage_forms'])"
          />

          <ConfigurationListing
            :configurations="configurations"
            :expand="expand"
            @edit-configuration="openEditConfigurationModal"
            :shouldDisable="shouldDisable('manage_forms')"
          />
        </div>
      </b-container>
    </div>

    <Spinner v-else />

    <!-- Create Form Modal -->
    <b-modal
      id="new-form-modal"
      size="xl"
      title="New Form"
    >
      <b-form @submit.prevent="onSubmit" id="new-form" novalidate>
        <b-form-group>
          <label for="form-name">Form Name *</label>
          <b-form-input
            autofocus
            id="form-name"
            required
            type="text"
            v-model="formName"
          />
        </b-form-group>

        <ConfigurationSelect
          :configurations="configurations"
          :selected-configuration="formConfiguration"
          @configuration-selected="onConfigurationSelected"
          stack-name
        />

      </b-form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button form="new-form" type="submit" variant="secondary">
            <b-spinner
              label="Small Spinner"
              small
              v-show="isCreatingForm"
            ></b-spinner
            >&nbsp;Create
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <!-- Add Configuration Modal -->
    <b-modal
      id="new-configuration-modal"
      size="xl"
      title="New Configuration"
    >
      <ConfigurationFields :configuration="configuration" :defaultConfiguration="configurations[0]" />
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button variant="secondary" @click="saveNewConfiguration" :disabled="savingConfiguration">
            <b-spinner
              label="Small Spinner"
              small
              v-show="savingConfiguration"
            ></b-spinner
            >&nbsp;Create
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <!-- Edit Configuration Modal -->
    <b-modal
      id="edit-configuration-modal"
      size="xl"
      :title="editConfigurationModalTitle"
      @hide="onConfigEditorClose"
      scrollable
    >
      <ConfigurationEditor :configuration="configuration" :configurations="configurations" @configuration-changed="onConfigurationChanged" @configuration-deleted="onConfigurationDeleted" @configuration-save-state="onConfigurationSaveState" edit-only />
      <template v-slot:modal-footer="{ cancel }">


        <div class="text-right w-100">
          <b-button
              v-if="!configuration.is_default && !clone"
              variant="white"
              class="float-left"
              @click="deleteConfiguration"
              :disabled="configurationSaveState"
            >
              Delete
            </b-button>
          <b-button-group>
            <b-button @click="cancel" variant="white">Cancel</b-button>
            <b-button
              variant="secondary"
              @click="saveConfiguration(clone)"
              :disabled="configurationSaveState"
            >
              <b-spinner v-show="configurationSaveState" label="Small Spinner" small />&nbsp;Save
            </b-button
            >
          </b-button-group>
        </div>
      </template>
    </b-modal>

    <!-- Import Form Modal -->
    <b-modal
      id="import-forms-modal"
      size="xl"
      title="Import Forms"
    >
      <input id="forms-csv-file" type="file" @change="importFromCSV" />
      <b-spinner
        label="Small Spinner"
        small
        v-show="importing || saving"
      ></b-spinner>
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
        </b-button-group>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import { HTTP } from '../../utils/requests'
  import { DEFAULT_CONFIGURATION, SEI_API_BASE } from '../../utils/constants'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy, removeEmptyKeys, setMissingKeysOnParentConfiguration } from '../../utils/misc.js'
  import { SESSION } from '../../utils/session'
  import { VALIDATE } from '../../utils/validate'

  import ConfigurationEditor from './ConfigurationEditor'
  import ConfigurationFields from './ConfigurationFields'
  import ConfigurationSelect from './ConfigurationSelect'
  import ConfigurationListing from './ConfigurationListing'
  import FormsListing from './FormsListing'
  import Spinner from '../Spinner'

  async function getFormsData(projectId) {
    try {
      let url = `${SEI_API_BASE}/exams/${projectId}/forms?include=version&version_number=-1`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getConfigurationsData(projectId) {
    let moreConfigurations = true
    let page = 1

    const configurations = []

    while (moreConfigurations) {
      try {
        const url = `${SEI_API_BASE}/exams/${projectId}/configurations?page=${page}`
        const response = await HTTP.get(url)
        configurations.push(...response.data.results)
        moreConfigurations = response.data.has_next
        page++
      } catch (error) {
        return { error }
      }
    }

    return { data: configurations }
  }

  async function updateFormData(form, projectId) {
    try {
      const data = {
        name: form.name,
        settings: form.version.settings,
        groups: form.version.groups,
        group_order: form.version.group_order
      }
      let url = `${SEI_API_BASE}/exams/${projectId}/forms/${form.id}`
      await HTTP.put(url, data)
      return {}
    } catch (error) {
      return { error }
    }
  }

  async function postFormData(form, projectId) {
    try {
      let url = `${SEI_API_BASE}/exams/${projectId}/forms`
      const result = await HTTP.post(url, form)
      return { data: result.data }
    } catch (error) {
      return { error }
    }
  }

  async function putFormData(payload, projectId, formId) {
    try {
      let url = `${SEI_API_BASE}/exams/${projectId}/forms/${formId}`
      await HTTP.put(url, payload)
      return {}
    } catch (error) {
      return { error }
    }
  }

  async function postConfiguration(examId, configuration) {
    try {
      const url = `${SEI_API_BASE}/exams/${examId}/configurations`
      const payload = { ...configuration }
      removeEmptyKeys(payload.settings)
      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  const LETTERS_AND_NUMBERS = 'abcdefghijklmnopqrstuvwxyz12345678901234567890'

  function getRandomName() {
    let name = ''
    for (let i = 0; i < 4; i++) {
      name += LETTERS_AND_NUMBERS.substr(Math.round(Math.random() * (LETTERS_AND_NUMBERS.length - 1)), 1)
    }
    return name
  }

  async function getItemsLookup(examId) {
    try {
      const url = `${SEI_API_BASE}/exams/${examId}/items/lookup`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Forms',
    components: {
      ConfigurationEditor,
      ConfigurationFields,
      ConfigurationSelect,
      ConfigurationListing,
      FormsListing,
      Spinner
    },
    created() {
      this.safeProject = deepCopy(this.project)
      this.getFormsAndConfigurations()
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      isSaving: {
        type: Boolean
      }
    },
    data() {
      return {
        configurationSaveState: false,
        loading: true,
        error: false,
        isCreatingForm: false,
        configChanged: false,
        formName: '',
        formConfiguration: null,
        safeProject: {},
        forms: {},
        configuration: {},
        editable: false,
        configurations: [],
        expand: true,
        importing: false,
        fileToImport: null,
        saving: false,
        savingConfiguration: false,
        clone: false,
        selectedConfiguration: this.$route.params.configId
      }
    },
    methods: {
      onConfigurationSaveState (saving) {
        if (this.clone && this.configurationSaveState && !saving) {
          this.clone = false
        }

        this.configurationSaveState = saving
      },
      saveConfiguration (saveNew) {
        EVENT.$emit('save-configuration', saveNew)
      },
      async deleteConfiguration () {
        const message = 'Are you sure you would like to delete this configuration?'

        const options = {
            title: 'Confirm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelVariant: 'white',
            size: 'sm',
            noFade: true
        }

        const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

        if (!confirmed) return
        
        EVENT.$emit('delete-configuration')
      },
      async getFormsAndConfigurations() {
        this.loading = true

        const forms = await this.getForms()
        const configurations = await this.getConfigurations()

        this.loading = false

        if (!forms || !configurations) {
          return this.error = true
        }

        if (forms.results.length === 1) {
          const [ form ] = forms.results

          const route = {
            name: 'projectFormEditor',
            params: {
              formId: form.id
            }
          }

          return this.$router.replace(route)
        }

        const defaultConfiguration = configurations.find(c => c.is_default)

        setMissingKeysOnParentConfiguration(defaultConfiguration)

        this.forms = forms
        this.configurations = configurations
        this.sortConfigurations()

        if (this.selectedConfiguration) {
          this.openEditConfigurationModal({ id: this.selectedConfiguration })
        }

        this.error = false
      },
      async getForms() {
        const { data } = await getFormsData(this.$route.params.projectId)
        return data
      },
      async getConfigurations() {
        const { data } = await getConfigurationsData(this.$route.params.projectId)
        return data
      },
      async moveForm(data) {
        const { id } = data
        const index = this.getFormIndex(id)
        const form = this.forms.results[index]
        const { error } = await updateFormData(
          form,
          this.$route.params.projectId
        )
        if (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to update form! Please try again.'
          })
        }
      },
      getFormIndex(id) {
        for (const [index, form] of this.forms.results.entries()) {
          if (id === form.id) {
            return index
          }
        }
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.createForm()
        }
      },
      async createForm() {
        this.isCreatingForm = true
        const form = {
          name: this.formName,
          configuration_id: this.formConfiguration,
          groups: []
        }
        if (this.formNames.indexOf(form.name) > -1) {
          this.isCreatingForm = false
          EVENT.alert({
            variant: 'danger',
            message: `The name "${form.name}" is already taken.`
          })
          return
        }
        const { error } = await postFormData(form, this.$route.params.projectId)
        if (error) {
          this.isCreatingForm = false
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to create form! Please try again.'
          })
          return
        }
        const formResult = await getFormsData(this.$route.params.projectId)
        if (formResult.error) {
          this.isCreatingForm = false
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to create form! Please try again.'
          })
          return
        }
        this.forms = formResult.data
        this.isCreatingForm = false
        this.$bvModal.hide('new-form-modal')
      },
      async importFromCSV(event) {
        this.importing = true

        const itemsLookup = await getItemsLookup(this.project.id)

        try {
          const file = event.target.files[0]
          if (!~file.type.indexOf('csv')) throw new Error('Not CSV File')

          const reader = new FileReader()
          reader.onload = async (e) => {
            try {
              const raw = e.target.result
              const lines = raw.split('\n')
              for (let i = 0; i < lines.length; i++) {
                lines[i] = lines[i].trim().split(',')
              }

              const forms = lines[0]
              const formsToSave = []
              const formsLength = forms.length
              for (let j = 0; j < formsLength; j++) {
                var form = {
                  name: forms[j],
                  // item_ids: [],
                  groups: [{
                    item_ids: [],
                    items_per_page: 1,
                    items_to_select: null,
                    name: getRandomName(),
                    order: 'random'
                  }]
                }
                formsToSave[formsToSave.length] = form
              }

              const itemsNames = []
              const itemIds = []
              for (var id in itemsLookup) {
                if (itemsLookup.hasOwnProperty(id)) { // eslint-disable-line no-prototype-builtins
                  itemsNames.push(itemsLookup[id].n)
                  itemIds.push(id)
                }
              }

              for (let k = 1; k < lines.length; k++) {
                const line = lines[k]
                for (let l = 0; l < line.length; l++) {
                  if (line[l] === '' && k < lines.length - 1) {
                    const group = {
                      item_ids: [],
                      items_per_page: 1,
                      items_to_select: null,
                      name: getRandomName(),
                      order: 'random'
                    }
                    formsToSave[l % formsLength].groups.push(group)
                  } else if (k < lines.length - 1) {
                    const itemIndex = itemsNames.indexOf(line[l])
                    if (!~itemIndex) continue
                    const itemId = itemIds[itemIndex]
                    formsToSave[l % formsLength].groups[formsToSave[l % formsLength].groups.length - 1].item_ids.push(itemId)
                    // if (l % formsLength === 0) {
                    //     formsToSave[0].item_ids.push(itemId);
                    // } else if (l % formsLength === 1) {
                    //     formsToSave[1].item_ids.push(itemId);
                    // } else if (l % formsLength === 2) {
                    //     formsToSave[2].item_ids.push(itemId);
                    // }
                  }
                }
              }

              this.saving = true
              const taskArr = []
              for (let i = formsToSave.length; i--;) {
                var formName = formsToSave[i].name
                const payload = { name: formName, groups: formsToSave[i].groups }

                var found = false
                var searchInForms = this.forms.results.map(f => { return f.name })
                found = searchInForms.indexOf(formName)
                var task = null
                if (found !== -1) {
                  const foundId = this.forms.results[found].id
                  payload.force_fresh = true
                  task = putFormData(payload, this.project.id, foundId)
                } else {
                  task = postFormData(payload, this.project.id)
                }

                taskArr[taskArr.length] = task
              }

              await Promise.all(taskArr)
              window.location.reload()
            } catch (e) {
              EVENT.alert({
                variant: 'danger',
                message: 'Failed to import forms! Please try again.'
              })
            } finally {
              document.getElementById('forms-csv-file').value = ''
              this.saving = false
              this.importing = false
            }
          }

          reader.readAsText(file)
        } catch (e) {
          document.getElementById('forms-csv-file').value = ''
          this.saving = false
          this.importing = false
        }
      },
      openNewFormModal() {
        const defaultConfiguration = this.configurations.find(config => config.is_default)
        this.formConfiguration = defaultConfiguration.id
        this.formName = ''
        this.$bvModal.show('new-form-modal')
      },
      openNewConfigurationModal() {
        this.configuration = deepCopy(DEFAULT_CONFIGURATION)
        this.$bvModal.show('new-configuration-modal')
      },
      openEditConfigurationModal(configuration, clone = false) {
        this.clone = clone

        const config = this.configurations.find(c => c.id === configuration.id)

        this.configuration = deepCopy(config)

        if (clone) {
          this.configuration.name += ' (clone)'
        }

        this.$bvModal.show('edit-configuration-modal')
      },
      onConfigurationSelected(configurationId) {
        this.formConfiguration = configurationId
      },
      sortConfigurations() {
        const configurations = [ ...this.configurations ]

        configurations.sort((current, next) => current.name.localeCompare(next.name))

        const index = configurations.findIndex(config => config.is_default)
        const [defaultConfiguration] = configurations.splice(index, 1)

        configurations.unshift(defaultConfiguration)

        this.configurations = configurations
      },
      async saveNewConfiguration() {
        this.savingConfiguration = true

        if (this.configNames.indexOf(this.configuration.name) > -1) {
          this.savingConfiguration = false
          EVENT.alert({
            variant: 'danger',
            message: `The name "${this.configuration.name}" is already taken.`
          })
          return
        }

        const { data, error } = await postConfiguration(this.project.id, this.configuration)

        this.savingConfiguration = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save configuration.'
          })
        }

        this.configurations.push(data)

        this.sortConfigurations()

        this.$bvModal.hide('new-configuration-modal')
      },
      onConfigEditorClose(event) {
        if (this.configChanged && !confirm('Leave without saving changes?')) {
          event.preventDefault()
        }
      },
      onConfigurationChanged(isChanged, newConfig=null) {
        this.configChanged = isChanged
        if (newConfig !== null) {
          Object.assign(this.configuration, newConfig)
        }
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      onConfigurationDeleted (configId) {
        this.configurations = this.configurations.filter(config => config.id !== configId)
      }
    },
    computed: {
      editConfigurationModalTitle () {
        if (this.clone) {
          return 'Clone Configuration'
        }

        return 'Edit Configuration'
      },
      expandOrCollapse() {
        if (this.expand) return 'Collapse All'
        return 'Expand All'
      },
      formNames() {
        return this.forms.results.map(form => {
          return form.name
        })
      },
      configNames() {
        return this.configurations.map(config => {
          return config.name
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
