<template>
  <div>
    <div>
      <b-navbar sticky v-if="hasPermission" variant="light">
        <b-button-group>
          <b-button
            :disabled="isSaving"
            form="org-settings-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner label="Small Spinner" small v-show="isSaving"></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </b-navbar>
      <b-container class="mt-3" fluid>
        <b-form @submit.prevent="onSubmit" id="org-settings-form" novalidate>
          <b-row>
            <b-col>
              <b-alert :show="!hasPermission" variant="danger"
                >You are not authorized to make changes to this
                organization.</b-alert
              >
              <b-form-group
                class="mb-3"
                label="Organization Name *"
                label-cols-lg="3"
                label-cols-md="4"
                label-for="orgName"
              >
                <b-form-input
                  :readonly="!hasPermission"
                  id="orgName"
                  required
                  type="text"
                  v-model="safeOrg.name"
                />
              </b-form-group>

              <b-row class="mx-1 p-1 pl-0 mb-3">
                <b-col class="pl-0">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    v-model="safeOrg.delivery_creation_disabled"
                    >Disable Delivery creation for all exams in this
                    organization</b-form-checkbox
                  >
                </b-col>
              </b-row>
              <i>Individual exam switch is on the exam settings page</i>
              <br />
              <br />
              <p>
                Add fields to be included on deliveries for this organization:
              </p>

              <b-card
                :key="index"
                bg-variant="light"
                class="mb-2"
                no-body
                v-for="(schema, index) in safeOrg.settings.examinee_schema"
              >
                <b-card-body>
                  <b-form-group label="Type" label-cols-lg="3" label-cols-md="4">
                    <b-form-select v-model="schema.type" :disabled="!hasPermission" :options="typeOptions" />
                  </b-form-group>

                  <b-form-group label="Key *" label-cols-lg="3" label-cols-md="4">
                    <b-form-input v-model="schema.key" :readonly="!hasPermission" type="text" required />

                    <template #description>
                      <b-form-checkbox v-model="schema.required" :disabled="!hasPermission" class="d-inline mr-2">
                        Required
                      </b-form-checkbox>

                      <b-form-checkbox v-model="schema.preview" :disabled="!hasPermission" class="d-inline ml-2">
                        In Preview
                      </b-form-checkbox>
                    </template>
                  </b-form-group>

                  <b-button v-if="hasPermission" @click="removeExamineeSchema(index)" variant="link" class="float-right">
                    Delete
                  </b-button>
                </b-card-body>
              </b-card>
              <b-form-group>
                <b-button
                  @click="addExamineeSchema"
                  class="p-2 d-block"
                  size="sm"
                  v-if="hasPermission"
                  variant="primary-light"
                  >Add a key</b-button
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { deepCopy } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { VALIDATE } from '../../utils/validate'

  const TYPE_OPTIONS = [
    { text: 'Text', value: 'text' },
    { text: 'Email', value: 'email' },
    { text: 'Name', value: 'name' },
  ]

  export default {
    name: 'OrganizationSettings',
    created() {
      this.hasPermission = this.org.my_organization_permissions.includes(
        'edit_organization'
      )
      this.safeOrg = this.initializeSafeOrg(this.org)

      this.setDefaults()
    },
    props: {
      org: {
        type: Object
      },
      isSaving: {
        type: Boolean
      }
    },
    data() {
      return {
        safeOrg: {},
        hasPermission: false,
        typeOptions: TYPE_OPTIONS
      }
    },
    methods: {
      setDefaults () {
        for (const schema of this.safeOrg.settings.examinee_schema) {
          if (!schema.type) schema.type = 'text'
        }
      },
      initializeSafeOrg(org) {
        const initSafeOrg = deepCopy(org)

        if (!initSafeOrg.settings.examinee_schema) {
          initSafeOrg.settings = {
            ...initSafeOrg.settings,
            examinee_schema: []
          }
        }

        if (initSafeOrg.delivery_creation_disabled !== true) {
          initSafeOrg.delivery_creation_disabled = false
        }

        return initSafeOrg
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.saveOrgSettings()
        }
      },
      saveOrgSettings() {
        EVENT.saveOrg(this.safeOrg)
      },
      addExamineeSchema() {
        this.safeOrg.settings.examinee_schema.push({
          key: '',
          type: 'text',
          required: false,
          preview: false
        })
      },
      removeExamineeSchema(index) {
        this.safeOrg.settings.examinee_schema.splice(index, 1)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
