<template>
    <div>
        <b-navbar sticky variant="light">
            <b-button-group>
                <ApiCredentials :app-id="app.caveon_id" />
            </b-button-group>
        </b-navbar>
        <div class="container-fluid mt-4">
            <Users :app="app" @get-app="getApp" ref="users" />
            
            <Orgs :app="app" @get-app="getApp" ref="orgs" />
        </div>
    </div>
</template>

<script>
import ApiCredentials from './ApiCredentials.vue'
import Users from './Users.vue'
import Orgs from './Orgs.vue'

import { deepCopy } from '../../utils/misc.js'
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants.js'

async function getApp (appId) {
    try {
        const url = `${SEI_API_BASE}/apps/${appId}`

        const response = await HTTP.get(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

export default {
    name: 'Access',
    components: {
        ApiCredentials,
        Users,
        Orgs
    },
    props: {
        myApp: {
            type: Object
        }
    },
    data () {
        return {
            app: null
        }
    },
    created () {
        this.app = deepCopy(this.myApp)
    },
    methods: {
        async getApp () {
            const { data, error } = await getApp(this.$route.params.appId)

            if (error) return

            this.app = data

            if (this.$refs.users) {
                this.$refs.users.appUpdated()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
