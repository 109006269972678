<template>
  <div :id="id">
    <div class="position-relative">
      <div class="color-picker-wrapper" v-if="showColorPicker">
        <Chrome :value="value" @input="onColorInput" />
      </div>
      <b-input-group>
        <b-form-input
          placeholder="Color"
          :value="value"
          @input="onTextInput"
          @click="toggleColorPicker(true)"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="white"
            :style="{ color: '#eee', background: value }"
            @click="toggleColorPicker(!showColorPicker)"
          >
            <font-awesome-icon icon="eye-dropper"></font-awesome-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

function isValidHexColor(color) {
  return /^#[0-9A-F]{6}$/i.test(color) || !color
}

export default {
  name: 'ColorInput',
  components: {
    Chrome
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created() {
    this.id = 'color-input' + this._uid
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick)
  },
  data() {
    return {
      id: '',
      showColorPicker: false
    }
  },
  methods: {
    toggleColorPicker(toggle) {
      if (toggle) {
        document.addEventListener('click', this.onDocumentClick)
      } else {
        document.removeEventListener('click', this.onDocumentClick)
      }
      this.showColorPicker = toggle
    },
    onDocumentClick(event) {
      const element = document.getElementById(this.id)

      if (element === event.target || element.contains(event.target)) {
        return
      }

      this.toggleColorPicker(false)
    },
    onColorInput(color) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.$emit('color-changed', color.hex)
      }, 0)
    },
    onTextInput(color) {
      const colorIsValid = isValidHexColor(color)

      if (colorIsValid) {
        this.onColorInput({ hex: color })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker-wrapper {
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 99;
}
</style>
