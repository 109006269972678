import { render, staticRenderFns } from "./DeliveriesSearch.vue?vue&type=template&id=1d565350&scoped=true&"
import script from "./DeliveriesSearch.vue?vue&type=script&lang=js&"
export * from "./DeliveriesSearch.vue?vue&type=script&lang=js&"
import style0 from "./DeliveriesSearch.vue?vue&type=style&index=0&id=1d565350&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d565350",
  null
  
)

export default component.exports