import { render, staticRenderFns } from "./LanguagesTranslationsAgreements.vue?vue&type=template&id=25c9bb24&scoped=true&"
import script from "./LanguagesTranslationsAgreements.vue?vue&type=script&lang=js&"
export * from "./LanguagesTranslationsAgreements.vue?vue&type=script&lang=js&"
import style0 from "./LanguagesTranslationsAgreements.vue?vue&type=style&index=0&id=25c9bb24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c9bb24",
  null
  
)

export default component.exports