var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display)?_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.dropdownId),expression:"dropdownId"}],staticClass:"text-left",attrs:{"block":"","size":"lg","variant":"primary"}},[_vm._v(_vm._s(_vm.dropdownHeader))])],1),(_vm.data.length)?_c('b-collapse',{attrs:{"id":_vm.dropdownId,"visible":_vm.expand}},[_c('b-card-body',[_c('b-table',{style:(_vm.clickableTable),attrs:{"fields":_vm.fields,"items":_vm.data,"hover":"","responsive":"","small":"","striped":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(item_idx)",fn:function(row){return [_vm._v(_vm._s(row.item.item_idx + 1))]}},{key:"cell(manual_score)",fn:function(row){return [(!_vm.needsManualScore(row.item))?_c('span',[_vm._v(_vm._s(row.item.manual_score))]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              trigger: 'hover',
              title: 'Requires Manual Score',
              interactive: false
            }),expression:"{\n              trigger: 'hover',\n              title: 'Requires Manual Score',\n              interactive: false\n            }"}]},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}})],1)]}},{key:"cell(is_excluded)",fn:function(row){return [(row.item.is_excluded)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              trigger: 'hover',
              title: 'Item Is Excluded',
              interactive: false
            }),expression:"{\n              trigger: 'hover',\n              title: 'Item Is Excluded',\n              interactive: false\n            }"}]},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e()]}},{key:"cell(comments)",fn:function(row){return [(row.item.comments.length)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              trigger: 'hover',
              title: 'Item Has Comments',
              interactive: false
            }),expression:"{\n              trigger: 'hover',\n              title: 'Item Has Comments',\n              interactive: false\n            }"}]},[_c('font-awesome-icon',{attrs:{"icon":"comment"}})],1):_vm._e()]}}],null,false,1886926673)})],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }