<template>
  <b-tab active title="Files">
    <b-modal
      id="file-urls-modal"
      size="sm"
      title="File Urls"
    >
      <b-input-group class="mb-2" prepend="URL Only">
        <b-form-input
          :value="editing.url_code"
          readonly
          ref="file-url-input"
        ></b-form-input>
        <b-input-group-append>
          <b-tooltip
            ref="file-url"
            target="file-url"
            noninteractive
            triggers="click"
            variant="primary-dark"
            >Copied!</b-tooltip
          >
          <b-button
            id="file-url"
            @click="copyText('file-url')"
            variant="white"
            >Copy</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-input-group prepend="Smart Embed">
        <b-form-input
          ref="embed-url-input"
          :value="editing.embed_code"
          readonly
        ></b-form-input>
        <b-input-group-append>
          <b-tooltip
            ref="embed-url"
            target="embed-url"
            noninteractive
            triggers="click"
            variant="primary-dark"
            >Copied!</b-tooltip
          >
          <b-button
            id="embed-url"
            @click="copyText('embed-url')"
            variant="white"
            >Copy</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel" variant="white">Close</b-button>
      </template>
    </b-modal>
    <b-modal
      id="delete-file-modal"
      size="sm"
      title="Delete File"
      @ok.prevent="deleteFile"
    >
      Are you sure you would like to delete: <mark :style="{ borderRadius: '3px', backgroundColor: 'rgb(244, 245, 247)' }">{{ editing.name }}</mark>?
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="white" :disabled="deleting">Cancel</b-button>
        <b-button @click="ok" variant="danger" :disabled="deleting">
          <b-spinner small v-if="deleting" />
          Delete File 
        </b-button>
      </template>
    </b-modal>
    <b-card bg-variant="light" no-body class="mt-4 mb-2">
      <b-card-body class="px-3 py-3">
        <p class="mb-0">{{ uploadText }}<br><small><i>Not allowed .exe, .msi, .7z, .bin, .lua, .pif, .svg, .vbs, .com</i></small></p>
        <b-input-group class="mb-2">
          <b-form-file
            :disabled="isUploading || isUploadingMultiple"
            class="mr-1"
            drop-placeholder="Drop file here..."
            placeholder="Choose a file or drop it here..."
            v-model="file"
            @change="checkIfEncrypted"
            :multiple="multiple"
          ></b-form-file>
          <b-button :disabled="!file || isUploading || isUploadingMultiple || shouldDisable('create_items')" @click="beforeFileUpload"
            >Upload</b-button
          >
        </b-input-group>
        <div class="mt-3" v-show="isUploading || isUploadingMultiple">
          <b-progress
            :max="maxProgress"
            :value="fileProgress"
            show-progress
            variant="warning"
            v-if="isUploading"
          ></b-progress>
          <b-progress
            :max="maxProgress"
            :value="allFilesProgress"
            show-progress
            variant="warning"
            v-else
          ></b-progress>
        </div>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col sm="5" md="6" lg="8">
        <b-form-input
          id="filter-files"
          class="my-3"
          placeholder="Filter"
          v-model="fileFilter"
          v-if="safeFiles.length"
        ></b-form-input>
      </b-col>
      <b-col>
        <b-pagination
          v-if="filteredFiles.length > perPage"
          v-model="page"
          :per-page="perPage"
          :total-rows="filteredFiles.length"
          class="my-3 float-right"
        ></b-pagination>
      </b-col>
    </b-row>
    
    <b-table
      :fields="fields"
      :items="filteredFiles"
      :current-page="page"
      :per-page="perPage"
      sort-by="name"
      responsive
      small
      striped
      fixed
      sort-icon-left
      v-if="filteredFiles.length"
      style="word-break: break-word;"
    >
      <template v-slot:cell(actions)="row">
        <b-button-group class="float-right">
          <b-button
            size="sm"
            :href="row.item.download_url"
            variant="white"
            target="blank"
            >Preview</b-button
          >
          <b-button size="sm" variant="white" @click="openModal(row, 'file-urls-modal')"
            >Embed</b-button
          >
          <b-button
            :disabled="shouldDisable('delete_items')"
            size="sm"
            @click="openModal(row, 'delete-file-modal')"
            variant="white"
          >
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <!-- Secure File Password Modal -->
    <b-modal id="pw-modal" title="Password" @ok="sendPassword">
      <div>Secure files require a password:</div>
      <b-form-input
          id="password-input"
          type="password"
          placeholder="Password"
          v-model="password"
          autofocus
      ></b-form-input>
    </b-modal>
  </b-tab>
</template>

<script>
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP, HTTP_STANDARD } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy } from '../../utils/misc'
  import { SESSION }  from '../../utils/session'

  async function addFileRequest(projectId, file) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/files?include=download_url,url_code,embed_code`
      const response = await HTTP.post(url, file)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function addToBucketRequest(url, data, config) {
    try {
      const response = await HTTP_STANDARD.post(url, data, config)
      return { bucketData: response.data }
    } catch (error) {
      return { bucketError: error }
    }
  }

  async function deleteFileRequest(projectId, fileId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/files/${fileId}`
      const response = await HTTP.delete(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ResourceFiles',
    props: {
      files: {
        type: Array
      },
      multiple: {
        type: Boolean
      }
    },
    created() {
      this.safeFiles = deepCopy(this.files)
      this.safeFiles.sort(file => file.name)
    },
    beforeDestroy() {
      this.$emit('set-password', '')
    },
    data() {
      return {
        safeFiles: [],
        fileFilter: '',
        file: null,
        editing: {},
        page: 1,
        perPage: 50,
        fileProgress: 0,
        allFilesProgress: 0,
        maxProgress: 100,
        isUploading: false,
        isUploadingMultiple: false,
        deleting: false,
        password: '',
        fields: [
          { key: 'name', sortable: true },
          {
            key: 'type',
            sortable: true,
            formatter: type => {
              if (type && type.startsWith('(')) {
                return type.substring(1, type.length - 2)
              }

              return type
            }
          },
          {
            key: 'created_at',
            sortable: true,
            formatter: date => {
              const formattedDate = this.$moment.utc(date).local().format('L')
              if (formattedDate !== 'Invalid date') {
                return formattedDate
              }
              return ''
            }
          },
          { key: 'actions', label: '' }
        ]
      }
    },
    methods: {
      checkIfEncrypted(e) {
        let files = e.target.files
        if (files && files.length) {
          let ext = files[0].name.split('.').pop()
          if (ext === 'secure') {
            this.$bvModal.show('pw-modal')
          }
        }
      },
      sendPassword() {
        this.$emit('set-password', this.password)
      },
      async beforeFileUpload() {
        let multiple = false

        if (Array.isArray(this.file)) {
          if (this.file.length === 1) {
            this.file = this.file[0]
          } else {
            multiple = true
          }
        }

        if (multiple) {
          await this.uploadMultiple()
        } else {
          await this.uploadFile(this.file)
        }

        this.file = null
      },
      async uploadMultiple() {
        let done = 0
        const promises = []

        for (const file of this.file) {
          const promise = this.uploadFile(file, true)
          promise.then(() => {
            done++
            this.allFilesProgress = Math.round((done * 100) / this.file.length)
          })
          promises.push(promise)
        }

        this.isUploadingMultiple = true

        await Promise.all(promises)

        this.isUploadingMultiple = false
        this.allFilesProgress = 0
      },
      uploadFileFailed(msg) {
        EVENT.alert({
          variant: 'danger',
          message: msg
        })
        this.isUploading = false
        this.fileProgress = 0
      },
      async uploadFile(file, multiple = false) {
        const payload = {
          name: file.name,
          size: file.size,
          type: file.type
        }

        if (!multiple) {
          this.isUploading = true
        }

        const { data, error } = await addFileRequest(this.$route.params.projectId, payload)
        if (error) {
          let msg = error.response.data.messages[0]
          return this.uploadFileFailed(msg)
        }

        const formData = new FormData()
        const fields = data.upload_info.fields
        const responseUrl = data.upload_info.url

        for (const field in fields) {
          formData.append(field, fields[field])
        }

        formData.append('file', file)

        const config = {
          headers: {
            'x-amz-server-side-encryption': 'AES256'
          },
          onUploadProgress: this.onUploadProgress
        }

        const { bucketError } = await addToBucketRequest(responseUrl, formData, config)

        if (bucketError) {
          this.editing = data
          this.deleteFile()
          return this.uploadFileFailed('File upload failed')
        }

        this.isUploading = false
        this.fileProgress = 0

        this.safeFiles.unshift({ ...data, _rowVariant: 'primary-light' })

        this.emitToParent()
      },
      onUploadProgress(event) {
        this.fileProgress = Math.round((event.loaded * 100) / event.total)
      },
      openModal(row, modal) {
        const file = this.safeFiles.find(file => file.id === row.item.id)
        this.editing = { ...file }
        this.$bvModal.show(modal)
      },
      async deleteFile() {
        this.deleting = true

        const { error } = await deleteFileRequest(
          this.$route.params.projectId,
          this.editing.id
        )

        this.deleting = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete file.'
          })
        }

        const index = this.safeFiles.findIndex(file => file.id === this.editing.id)

        this.safeFiles.splice(index, 1)

        this.$bvModal.hide('delete-file-modal')

        this.emitToParent()
      },
      copyText(type) {
        const inputRef = type + '-input'
        const tooltipRef = type

        this.$refs[inputRef].select()
        document.execCommand('copy')
        setTimeout(() => this.$refs[tooltipRef].$emit('close'), 1200)
      },
      emitToParent() {
        const contentForParent = deepCopy(this.safeFiles)
        this.$emit('update-files', contentForParent)
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      }
    },
    computed: {
      uploadText() {
        return this.multiple ? 'Upload Files' : 'Upload a File'
      },
      filteredFiles() {
        if (!this.fileFilter) {
          return this.safeFiles
        }
        return this.safeFiles.filter(file => {
          return file.name.toLowerCase().indexOf(this.fileFilter.toLowerCase()) != -1
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
