<template>
  <div>
    <b-table
      :fields="fields"
      :items="logs"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      bordered
      hover
      responsive
      small
      striped
    ></b-table>
    <b-button v-if="hasNext" @click="loadMore" variant="white"
      >Load more</b-button
    >
  </div>
</template>

<script>
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { objectToQueryString } from '../../utils/misc'

  async function getChanges(examId, resourceId, queryParams) {
    try {
      queryParams.resource_id = resourceId
      const queryString = objectToQueryString(queryParams)
      const url = `${SEI_API_BASE}/exams/${examId}/activity_logs${queryString}`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ResourceActivityLogs',
    props: {
      projectId: {
        type: String,
        required: true
      },
      resourceId: {
        type: String,
        required: true
      },
      sortBy: {
        type: String,
        default: () => {
          return 'created_at'
        }
      },
      sortDesc: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        logs: [],
        page: 1,
        hasNext: false,
        fields: [
          {
            key: 'created_at',
            label: 'Time',
            sortable: true,
            formatter: row =>
              this.$moment
                .utc(row)
                .local()
                .format('llll')
          },
          { key: 'resource_name', label: 'Name', sortable: true },
          {
            key: 'description',
            label: 'Description',
            sortable: true
          },
          { key: 'resource_version', label: 'Version', sortable: true },
          { key: 'name', label: 'User', sortable: true }
        ]
      }
    },
    async created() {
      this.logs = await this.getLogs()
    },
    methods: {
      async getLogs() {
        if (this.resourceId === 'clone') {
          return []
        }
        const { results, has_next, error } = await getChanges(
          this.projectId,
          this.resourceId,
          { page: this.page }
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to get activity logs'
          })
        }

        this.hasNext = has_next

        return results
      },
      async loadMore() {
        this.page++
        this.logs = [...this.logs, ...(await this.getLogs())]
      }
    },
    computed: {}
  }
</script>
