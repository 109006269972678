<template>
  <div>
    <b-navbar sticky variant="light">
      <div class="d-flex">
        <b-form-input
          @keyup.enter="doSearch"
          placeholder="Search Logs"
          style="maxWidth: 600px"
          v-model="searchText"
        />
        <b-button @click="doSearch" class="ml-1" variant="primary-light">
          Search
        </b-button>
      </div>
      <b-navbar-nav class="ml-auto">
        <b-pagination
          v-if="totalPages > 1"
          aria-controls="my-table"
          :disabled="filtering"
          :per-page="perPage"
          :total-rows="rows"
          :value="page"
          @change="onPageChange"
        ></b-pagination>
      </b-navbar-nav>
    </b-navbar>
    <div class="ml-3 mt-3">
      <b-row>
        <b-col class="d-flex justify-content-between mb-2">
          <h4>Activity Logs</h4>
          <div>
            <b-dropdown
              right
              text="Types"
              variant="white"
              size="sm"
            >
              <b-dropdown-item @click="toggleLogType('item')">
                Items
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('item')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('flow')">
                Flows
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('flow')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('form')">
                Forms
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('form')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('config')">
                Configs
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('config')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('delivery')">
                Deliveries
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('delivery')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('exam')">
                Exams
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('exam')"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="toggleLogType('translation')">
                Translations
                <font-awesome-icon
                  icon="check"
                  v-if="showLogTypes.includes('translation')"
                ></font-awesome-icon>
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              right
              class="ml-1"
              text="Sort By"
              variant="white"
              size="sm"
            >
              <b-dropdown-item @click="changeSort('created_at')">
                Time
                <font-awesome-icon
                  icon="check"
                  v-if="sortBy === 'created_at'"
                ></font-awesome-icon>
              </b-dropdown-item>
              
              
              <b-dropdown-item @click="changeSort('resource_type')">
                Type
                <font-awesome-icon
                  icon="check"
                  v-if="sortBy === 'resource_type'"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="changeSort('resource_name')">
                Name
                <font-awesome-icon
                  icon="check"
                  v-if="sortBy === 'resource_name'"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="changeSort('description')">
                Description
                <font-awesome-icon
                  icon="check"
                  v-if="sortBy === 'description'"
                ></font-awesome-icon>
              </b-dropdown-item>

              <b-dropdown-item @click="changeSort('resource_version')">
                Version
                <font-awesome-icon
                  icon="check"
                  v-if="sortBy === 'resource_version'"
                ></font-awesome-icon>
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              right
              class="ml-1"
              text="Order"
              variant="white"
              size="sm"
            >
              <b-dropdown-item @click="changeOrder('-')">
                Descending
                <font-awesome-icon
                  icon="check"
                  v-if="sortOrder === '-'"
                ></font-awesome-icon>
              </b-dropdown-item>
              <b-dropdown-item @click="changeOrder('')">
                Ascending
                <font-awesome-icon
                  icon="check"
                  v-if="sortOrder === ''"
                ></font-awesome-icon>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>

      <ActivityLogsTable
        :logs="filteredLogs"
        :loading="loading"
        :sortBy="sortBy"
        :sortDesc="sortOrder == '-'"
      />

    </div>
    <b-container class="mt-3" fluid> </b-container>
  </div>
</template>

<script>
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'
  import { objectToQueryString } from '../../utils/misc'

  import ActivityLogsTable from './ActivityLogsTable.vue'

  async function getActivityLogs(examId, queryParams) {
    try {
      const queryString = objectToQueryString(queryParams)
      const url = `${SEI_API_BASE}/exams/${examId}/activity_logs${queryString}`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ActivityLogs',
    props: {
      project: {
        type: Object
      }
    },
    components: {
      ActivityLogsTable
    },
    data() {
      return {
        loading: true,
        search: false,
        searchText: '',
        logs: [],
        groupOptions: [
          { text: 'User', value: 'user' },
          { text: 'Changes', value: 'changes' },
          { text: 'Timestamp', value: 'timestamp' }
        ],
        filtering: false,
        queryKey: '',
        itemGroups: [],
        showLogTypes: ['item', 'form', 'config', 'delivery', 'exam', 'translation', 'flow'],
        page: 1,
        perPage: 200,
        rows: 0,
        totalPages: 0,
        has_next: false,
        sortBy: 'created_at',
        sortOrder: '-',
        examId: ''
      }
    },
    async created() {
      this.logs = await this.getLogs(1)

      this.loading = false
    },
    methods: {
      async getLogs(page) {

        const queryParams = { page: page }

        if (this.searchText && this.searchText.length > 0) {
          queryParams.search = this.searchText
        }

        queryParams.sort = this.sortOrder + this.sortBy

        const { results, total, has_next, error } = await getActivityLogs(
          this.project.id,
          queryParams
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to get activity logs.'
          })
        }

        this.hasNext = has_next
        this.rows = total
        this.totalPages = Math.ceil(
          total / this.perPage
        )
        this.page = page

        return results
      },
      async onPageChange(page) {
        this.loading = true
        this.logs = await this.getLogs(page)
        this.loading = false
      },
      showSearch() {
        this.search = true
      },
      async hideSearch() {
        this.search = false
        if (this.searchText.length > 0) {
          this.searchText = ''
          await this.doSearch()
        }
      },
      async doSearch() {
        this.loading = true
        this.logs = await this.getLogs(1)
        this.loading = false
      },
      changeSort(property) {
        this.sortBy = property
        this.doSearch()
      },
      changeOrder(order) {
        this.sortOrder = order
        this.doSearch()
      },
      toggleLogType(type) {
        const index = this.showLogTypes.indexOf(type)
        if (index > -1) {
          this.showLogTypes.splice(index, 1)
        } else {
          this.showLogTypes.push(type)
        }
      }
    },
    computed: {
      filteredLogs() {
        const filteredLogs = []

        for (const log of this.logs) {
          if (this.showLogTypes.includes(log.resource_type)) {
            filteredLogs.push(log)
          }
        }

        return filteredLogs
      },
      isDescending() {
        if (this.sortOrder === '-') {
          return true
        }

        return false
      }
    }
  }
</script>

<style></style>
