<template>
  <div>
    <Spinner v-if="loading"/>
    <b-table
      v-else
      :fields="fields"
      :items="logs"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @row-clicked="rowClicked"
      bordered
      hover
      responsive
      small
      striped
    ></b-table>
  </div>
</template>

<script>
  import Spinner from '../Spinner.vue'

  export default {
    name: 'ActivityLogsTable',
    props: {
      logs: {
        type: Array,
        required: true
      },
      sortBy: {
        type: String,
        default: () => {
          return 'created_at'
        }
      },
      sortDesc: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components: {
      Spinner
    },
    data() {
      return {
        fields: [
          {
            key: 'created_at',
            label: 'Time',
            sortable: true,
            formatter: row =>
              this.$moment
                .utc(row)
                .local()
                .format('llll')
          },
          { key: 'resource_type', label: 'Type', sortable: true },
          { key: 'resource_name', label: 'Name', sortable: true },
          {
            key: 'description',
            label: 'Description',
            sortable: true
          },
          { key: 'resource_version', label: 'Version', sortable: true },
          { key: 'name', label: 'User', sortable: true }
        ]
      }
    },
    methods: {
      rowClicked(item) {
        let pageName = ''
        let params = {}
        let query = {}
        const id = item.resource_id
        switch (item.resource_type) {
          case 'delivery':
            pageName = 'projectDelivery'
            params.deliveryId = id
            break;
          case 'item':
            pageName = 'projectItem'
            params.itemId = id
            query.version_number = item.resource_version
            break;
          case 'form':
            pageName = 'projectFormEditor'
            params.formId = id
            break;
          case 'exam':
            pageName = 'projectSettings'
            break;
          case 'translation':
            pageName = 'languagesTranslationsHome'
            params.languageId = id
            break;
          case 'flow':
            pageName = 'projectFlow'
            params.flowId = id
            break;
          case 'config':
            pageName = 'projectForms'
            params.configId = id
            break;
          default:
            return;
        }

        this.$router.push({
          name: pageName,
          params: params,
          query: query
        })
      }
    },
  }
</script>
