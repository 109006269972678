<template>
  <b-row align-h="start">
    <b-col sm="4">
      <AnalyticsLegend :chart="chart" v-if="chart" />
    </b-col>
    <b-col sm="4">
      <canvas height="250" ref="canvas"></canvas>
    </b-col>
  </b-row>
</template>

<script>
  import AnalyticsLegend from './AnalyticsLegend'
  import { Pie, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    name: 'AnalyticsPieChart',
    components: {
      AnalyticsLegend
    },
    extends: Pie,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object
      }
    },
    data() {
      return {
        chart: null
      }
    },
    mounted() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label(tooltip, data) {
              return data.labels[tooltip.index]
            }
          }
        },
        legend: {
          display: false
        }
      })

      this.chart = this.$data._chart
    }
  }
</script>
