import { deepCopy } from './misc'
import get from 'lodash.get'
import { TranslationSupportedLanguages } from './language'


const DEFAULT_ITEMS = {
  lookup: [],
  filters: {
    filters: [],
    search: '',
    includeDeleted: false,
    includeFiles: false,
    includeShared: false,
    includeReferences: false,
    includeEnemies: false
  },
  savedSearchId: null,
  currentPage: 1,
  groupBy: null,
  collapsedGroups: []
}

export const SESSION = {
  user: {},
  preferences: {},
  itemsList: null,
  items: deepCopy(DEFAULT_ITEMS),
  itemClone: {},
  project: {},
  roleTypes: [],
  app: {},
  isPremium() {
    return get(SESSION.project, 'organization.is_premium', false)
  },
  isAdmin() {
    return get(SESSION.project, 'my_role_types', []).includes('admin')
  },
  isDeliveryWorker() {
    return get(SESSION.project, 'my_role_types', []).includes('delivery_worker')
  },
  isTranslator() {
    return get(SESSION.project, 'my_role_types', []).includes('translator')
  },
  numberOfRoles() {
    return get(SESSION.project, 'my_role_types', []).length
  },
  hasItemFilters() {
    let hasFilter = false

    for (const value of Object.values(SESSION.items.filters)) {
      if (Array.isArray(value)) {
        hasFilter = Boolean(value.length)
      } else {
        hasFilter = Boolean(value)
      }

      if (hasFilter) break
    }

    return hasFilter
  },
  clearItems() {
    SESSION.items = deepCopy(DEFAULT_ITEMS)
  },
  clearItemFilters() {
    SESSION.items.filters = deepCopy(DEFAULT_ITEMS.filters)
  },
  hasPermissions(neededPerms) {
    // Convert to array if perm is a string
    if (typeof neededPerms === 'string') {
      neededPerms = [neededPerms]
    }
  
    // Check if app alias or user
    const perms = SESSION.project.my_permissions
    
    // Must have ALL permissions
    if (neededPerms.every(p => perms.includes(p))) {
      return true
    }
  
    return false
  },
  async initSupportedLanguages() {
    if (SESSION._supportedLanguages) return
  
    const supportedLanguages = new TranslationSupportedLanguages(SESSION.project.id)
    await supportedLanguages.fetchAll()

    SESSION._supportedLanguages = supportedLanguages
  },
  _supportedLanguages: null,
  get supportedLanguages() {
    return SESSION._supportedLanguages
  }
}
