<template>
  <div>
    <b-modal
      @ok.prevent="deleteItem()"
      id="delete-item-modal"
      size="xl"
      title="Confirm"
    >
      Are you sure you want to delete this item?
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button :disabled="deleting" @click="cancel" variant="white"
            >Cancel</b-button
          >
          <b-button :disabled="deleting" @click="ok" variant="secondary">
            <b-spinner label="Small Spinner" small v-show="deleting"></b-spinner
            >&nbsp;Delete
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <b-modal id="preview" title="Item Preview" hide-footer>
      <template v-slot:modal-title>
        Item Preview
        <b-button
          @click="reloadPreview()"
          size="sm"
          variant="primary-light"
          class="ml-2"
        >
          Reload Item
        </b-button>
      </template>
      <iframe :src="previewItemUrl" ref="previewFrame" />
    </b-modal>

    <b-modal
      id="item-writing-instructions"
      size="xl"
      title="Instructions"
    >
      <div v-markdown="project.settings.item_writing_instructions"></div>
      <template v-slot:modal-footer="{ ok }">
        <b-button @click="ok" variant="secondary">
          Ok
        </b-button>
      </template>
    </b-modal>

    <div v-if="!loading">
      <div v-if="!error">
        <b-navbar sticky variant="light">
          <b-button-group class="mr-2">
            <b-button
              :disabled="savingOrUpdating || shouldDisable(['edit_items', 'edit_item_meta'])"
              @click="submitThroughStep()"
              style="marginRight: 2px;"
              variant="secondary"
            >
              <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
              >&nbsp;Save
            </b-button>
            <b-dropdown :disabled="savingOrUpdating || shouldDisable(['edit_items', 'edit_item_meta'])" left variant="secondary">
              <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta'])" @click="submitThroughStep('exit')"
                >Save and exit</b-dropdown-item
              >
              <b-dropdown-item
                :disabled="shouldDisable(['edit_items', 'edit_item_meta'])"
                @click="submitThroughStep('next')"
                v-if="showNext && !isNew"
                >Save and edit next</b-dropdown-item
              >
              <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta', 'create_items'])" @click="submitThroughStep('another')"
                >Save and create another</b-dropdown-item
              >
              <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta', 'create_items'])" @click="submitThroughStep('clone')"
                >Save and create a clone</b-dropdown-item
              >
            </b-dropdown>
          </b-button-group>
          <b-button-group class="mr-2">
            <b-button
              :disabled="savingOrUpdating"
              @click="openPreview()"
              variant="white"
              v-if="!isNew"
              >Preview</b-button
            >
            <b-button
              :disabled="savingOrUpdating || shouldDisable('create_items')"
              @click="cloneItem()"
              variant="white"
              v-if="!isNew"
              >Clone</b-button
            >
            <Resource
              :active-tab="activeTab"
              :project="project"
              @clear-active="clearActiveTab"
              @references-updated="referencesUpdated"
              @resource-loaded="resourceLoaded"
              @shared-updated="sharedUpdated"
              @widgets-updated="widgetsUpdated"
            />
            <b-button
              v-if="hasInstructions"
              v-b-modal.item-writing-instructions
              variant="white"
              >Instructions</b-button
            >
          </b-button-group>
          <ItemGenerate
            :item="item"
            :project="project"
            v-if="selectedType === 'smart' && !isNew"
          />
          <b-navbar-nav class="ml-auto" v-if="!isNew">
            <b-button-group>
              <b-button
                @click="navigateToItem(-1)"
                class="px-3"
                v-if="showPrev"
                variant="white"
              >
                <font-awesome-icon icon="caret-left"></font-awesome-icon>
              </b-button>
              <b-button
                @click="navigateToItem(1)"
                class="px-3 ml-1"
                v-if="showNext"
                variant="white"
              >
                <font-awesome-icon icon="caret-right"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </b-navbar-nav>
        </b-navbar>
        <b-container class="mt-3 mb-3" fluid v-if="!updatingVersion">
          <b-form @submit.prevent="onSubmit" novalidate ref="form">
            <b-form-group
              label="Name"
              label-cols-lg="3"
              label-cols-sm="4"
              label-for="item-name-id"
            >
              <b-input-group>
                <b-form-input
                  class="mr-2"
                  id="item-name-id"
                  v-model="fields.name"
                ></b-form-input>
                <b-button
                  :disabled="shouldDisable('create_items')"
                  @click="autoName()"
                  v-show="hasNamingScheme"
                  variant="white"
                  >Generate name</b-button
                >
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Type"
              label-cols-lg="3"
              label-cols-sm="4"
              label-for="item-type-id"
            >
              <b-form-select
                :options="itemTypes"
                id="item-type-id"
                v-model="selectedType"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Version"
              label-cols-lg="3"
              label-cols-sm="4"
              v-if="showVersion"
            >
              <b-row>
                <b-col md="9">
                  <b-form-select
                    :disabled="savingOrUpdating || shouldDisable('edit_items')"
                    :options="versionOptions"
                    :value="selectedVersion"
                    @change="versionSelected"
                    id="version-input"
                    style="width: 120px;"
                  ></b-form-select>
                  <span
                    class="live-text ml-1 mr-4"
                    v-if="
                      item.live_version_number === item.version.version_number
                    "
                    variant="outline-secondary"
                    >LIVE</span
                  >
                  <b-button
                    :disabled="savingOrUpdating || shouldDisable('edit_items')"
                    @click="makeVersionLive"
                    class="ml-1 mr-4"
                    v-else
                    variant="primary-light"
                    >MAKE LIVE</b-button
                  >
                  <div class="d-sm-block d-md-inline-block mt-2">
                    <b-button class="mr-1" size="sm" variant="white">
                      Exposures
                      <b-badge pill variant="info">{{
                        item.version.num_exposures
                      }}</b-badge>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>

            <ItemMeta
              :values="fields.meta.scorpion"
              show="top"
              v-if="hasPinned"
            />

            <b-tabs v-model="tabIndex" content-class="mt-3">
              <b-tab title="Edit Item">
                <b-button @click="expand = !expand" size="sm" variant="link">{{
                  expandOrCollapse
                }}</b-button>
                <b-card class="mb-1" no-body>
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.content-area-collapse
                      variant="primary"
                      >Content</b-button
                    >
                  </b-card-header>
                  <b-collapse :visible="expand" id="content-area-collapse">
                    <b-card-body>
                      <b-row>
                        <b-col>
                          <b-form-checkbox
                            @change="onSharedChange"
                            class="float-right"
                            name="checkbox-1"
                            v-model="toggleShared"
                            >Include shared content</b-form-checkbox
                          >
                        </b-col>
                      </b-row>
                      <b-collapse :visible="toggleShared" id="collapse-1">
                        <b-form-group
                          v-show="toggleShared"
                          label="Shared Content"
                          label-cols-lg="3"
                          label-cols-sm="4"
                          label-for="shared-content"
                        >
                          <v-select
                            :loading="loadingResource"
                            v-if="loadingResource"
                          >
                            <template #spinner="{ loading }">
                              <div
                                class="vs__spinner"
                                style="border-left-color: rgba(88,151,251,0.71)"
                                v-if="loading"
                              ></div>
                            </template>
                          </v-select>
                          <v-select
                            :options="sharedOptions"
                            :reduce="option => option.value"
                            id="shared-content"
                            label="text"
                            multiple
                            placeholder="Select Shared Content"
                            v-if="readyToDisplay"
                            v-model="fields.content.shared"
                          ></v-select>
                        </b-form-group>

                        <b-form-group>
                          <b-row>
                            <b-col class="text-right">
                              <b-button
                                class="mr-2"
                                size="sm"
                                v-b-toggle.contentview
                                variant="white"
                                >View Content</b-button
                              >
                              <b-button
                                :disabled="shouldDisable('create_items')"
                                @click="openResourceModal('shared')"
                                size="sm"
                                variant="primary-light"
                                >Add shared content</b-button
                              >
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-collapse
                          class="mb-4"
                          id="contentview"
                          v-if="
                            !loadingResource && fields.content.shared.length
                          "
                        >
                          <div class="scroll-box p-1">
                            <b-card
                              :header="sharedLookup[sharedId].name"
                              :key="sharedId"
                              class="mt-0 mb-3 border-info"
                              header-bg-variant="info"
                              header-text-variant="white"
                              v-for="sharedId in fields.content.shared"
                            >
                              <div
                                v-markdown="sharedLookup[sharedId].content"
                              ></div>
                            </b-card>
                          </div>
                        </b-collapse>
                      </b-collapse>

                      <b-row>
                        <b-col>
                          <b-form-checkbox
                            @change="onWidgetChange"
                            class="float-right"
                            v-model="toggleWidget"
                            >Include widgets</b-form-checkbox
                          >
                        </b-col>
                      </b-row>

                      <b-collapse :visible="toggleWidget">
                        <b-form-group
                          v-show="toggleWidget"
                          label="Widgets"
                          label-cols-lg="3"
                          label-cols-sm="4"
                          label-for="widgets"
                        >
                          <v-select
                            :loading="loadingResource"
                            v-if="loadingResource"
                          >
                            <template #spinner="{ loading }">
                              <div
                                class="vs__spinner"
                                style="border-left-color: rgba(88,151,251,0.71)"
                                v-if="loading"
                              ></div>
                            </template>
                          </v-select>
                          <v-select
                            :options="widgetOptions"
                            :reduce="option => option.value"
                            id="widgets"
                            label="text"
                            multiple
                            placeholder="Select Widgets"
                            v-if="readyToDisplay"
                            v-model="fields.content.widgets"
                          ></v-select>
                        </b-form-group>

                        <b-form-group>
                          <b-row>
                            <b-col class="text-right">
                              <b-button
                                @click="openResourceModal('widgets')"
                                size="sm"
                                variant="primary-light"
                                >Add widgets</b-button
                              >
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-collapse>

                      <div v-if="selectedType === 'fill_in_the_blanks'">
                        <b-row>
                          <b-col class="text-right">
                            <b-button
                              @click="toggleShowMore = !toggleShowMore"
                              size="sm"
                              variant="link"
                            >{{ optionExpandOrCollapse }}</b-button
                            >
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else-if="selectedType !== 'smart'">
                        <label for="stem" class="d-flex justify-content-between align-items-center">
                          Stem
                          <b-row>
                            <b-col class="text-right">
                              <b-button
                                @click="toggleShowMore = !toggleShowMore"
                                size="sm"
                                variant="link"
                              >{{ optionExpandOrCollapse }}</b-button
                              >
                            </b-col>
                          </b-row>
                        </label>
                        <textarea-autosize
                          :min-height="37"
                          :max-height="500"
                          class="mb-3 w-100 p-2 stem-field"
                          id="stem"
                          rows="1"
                          v-model="fields.content.stem"
                        />
                      </div>
                      <b-collapse :visible="toggleShowMore" class="py-2 px-3">
                        <b-row class="my-3">
                          <b-col lg="3" md="4" sm="6">
                            <label for="feedback">Feedback</label>
                          </b-col>
                          <b-col lg="9" md="8" sm="6">
                            <textarea-autosize
                              :min-height="37"
                              class="mb-3 form-control"
                              rows="1"
                              v-model="fields.feedback['stem']"
                            />
                          </b-col>
                        </b-row>
                      </b-collapse>
                      <ItemOptions
                        :fields="fields"
                        :isNew="isNew"
                        :item="item"
                        :project="project"
                        :saving="saving"
                        :toggle="toggleOptionCollapse"
                        :type="selectedType"
                        :show-more="toggleShowMore"
                        @open-preview="openPreview"
                        @save="submitThroughStep()"
                      />
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card class="mb-1" no-body>
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.settings-collapse
                      variant="primary"
                      >Settings</b-button
                    >
                  </b-card-header>
                  <b-collapse :visible="expand" id="settings-collapse">
                    <b-card-body>
                      <ItemSettings
                        :fields="fields"
                        :type="selectedType"
                        :item-ids="allItemIds"
                      />
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card class="mb-1" no-body>
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.references-collapse
                      variant="primary"
                      >References</b-button
                    >
                  </b-card-header>
                  <b-collapse :visible="expand" id="references-collapse">
                    <b-card-body>
                      <b-row
                        :key="index"
                        class="option"
                        v-for="(reference, index) in itemReferences"
                      >
                        <b-col lg="5" md="9">
                          <b-form-select
                            :options="referenceOptions"
                            class="mb-2"
                            v-model="reference.reference_id"
                          ></b-form-select>
                        </b-col>
                        <b-col lg="5" md="9">
                          <b-form-input
                            class="mb-2"
                            placeholder="Location"
                            v-model="reference.location"
                          ></b-form-input>
                        </b-col>
                        <b-col lg="2" md="3">
                          <b-button-group>
                            <b-button
                              :disabled="index === 0"
                              @click="moveReference(index, -1)"
                              size="sm"
                              variant="white"
                            >
                              <font-awesome-icon icon="arrow-up"></font-awesome-icon>
                            </b-button>
                            <b-button
                              :disabled="index === itemReferences.length - 1"
                              @click="moveReference(index, 1)"
                              size="sm"
                              variant="white"
                            >
                              <font-awesome-icon icon="arrow-down"></font-awesome-icon>
                            </b-button>
                            <b-button @click="deleteReference(index)" size="sm" variant="white">
                              <font-awesome-icon icon="trash"></font-awesome-icon>
                            </b-button>
                          </b-button-group>
                        </b-col>
                      </b-row>
                      <b-button
                        :disabled="shouldDisable('edit_items')"
                        @click="addReference()"
                        class="mt-2"
                        size="sm"
                        variant="white"
                        >Select a Reference</b-button
                      >
                      <b-button
                        :disabled="shouldDisable('edit_items')"
                        @click="openResourceModal('reference')"
                        class="mt-2 ml-2"
                        size="sm"
                        variant="white"
                        >Add Reference</b-button
                      >
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card
                  :class="!isPremium ? 'upgrade-block' : null"
                  class="mb-1"
                  no-body
                  v-if="hasMeta"
                >
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.meta-collapse
                      variant="primary"
                      >Meta</b-button
                    >
                  </b-card-header>
                  <b-collapse :visible="expand" id="meta-collapse">
                    <b-card-body>
                      <ItemMeta :values="fields.meta.scorpion" show="meta" />
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-tab>
              <b-tab title="Item Stats" v-if="item.id">
                <b-button @click="expand = !expand" size="sm" variant="link">{{
                  expandOrCollapse
                }}</b-button>
                <b-row>
                  <b-col>
                    <b-card class="mb-1" no-body v-if="!isNew">
                      <b-card-header class="p-1">
                        <b-button
                          block
                          class="text-left"
                          size="lg"
                          v-b-toggle.stats-collapse
                          variant="primary"
                          >Item Stats</b-button
                        >
                      </b-card-header>
                      <b-collapse :visible="expand" id="stats-collapse">
                        <b-card-body>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Responses</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item.stats.num_responses || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._stats.avg_seconds || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration When Correct</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="
                                  item._stats.avg_seconds_when_correct || 0
                                "
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration When Incorrect</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="
                                  item._stats.avg_seconds_when_incorrect || 0
                                "
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>P-Value</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._stats.avg_score || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Correlation</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._stats.correlation || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Discrimination</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._stats.discrimination || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </b-col>
                  <b-col sm="6" v-if="!isNew">
                    <b-card class="mb-1" no-body>
                      <b-card-header class="p-1">
                        <b-button
                          block
                          class="text-left"
                          size="lg"
                          v-b-toggle.version-stats-collapse
                          variant="primary"
                          >Version Stats</b-button
                        >
                      </b-card-header>
                      <b-collapse :visible="expand" id="version-stats-collapse">
                        <b-card-body>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Responses</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item.version.stats.num_responses || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._versionStats.avg_seconds || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration When Correct</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="
                                  item._versionStats.avg_seconds_when_correct ||
                                    0
                                "
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Median Duration When Incorrect</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="
                                  item._versionStats
                                    .avg_seconds_when_incorrect || 0
                                "
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>P-Value</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._versionStats.avg_score || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Correlation</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._versionStats.correlation || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col md="8" sm="6">
                              <label>Discrimination</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="item._versionStats.discrimination || 0"
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3" v-if="selectedType === 'domc'">
                            <b-col md="8" sm="6">
                              <label>Median Stem Duration</label>
                            </b-col>
                            <b-col>
                              <b-form-input
                                :value="
                                  item._versionStats.median_stem_duration || 0
                                "
                                class="text-right"
                                readonly
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <div v-if="selectedType === 'scale'">
                            <b-row class="mb-3">
                              <b-col md="8" sm="6">
                                <label>Mean</label>
                              </b-col>
                              <b-col>
                                <b-form-input
                                  :value="item._versionStats.response_mean || 0"
                                  class="text-right"
                                  readonly
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col md="8" sm="6">
                                <label>Median</label>
                              </b-col>
                              <b-col>
                                <b-form-input
                                  :value="
                                    item._versionStats.response_median || 0
                                  "
                                  class="text-right"
                                  readonly
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-row class="mb-3">
                              <b-col md="8" sm="6">
                                <label>Mode</label>
                              </b-col>
                              <b-col>
                                <b-form-input
                                  :value="item._versionStats.response_mode || 0"
                                  class="text-right"
                                  readonly
                                ></b-form-input>
                              </b-col>
                            </b-row>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row v-if="hasOptions">
                  <b-col>
                    <ItemOptionStats :item="item" />
                  </b-col>
                </b-row>
                <b-row v-if="hasBlanks">
                  <b-col>
                    <ItemBlankStats :item="item" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Testing Info" v-if="item.id">
                <!-- Item Difficulty -->
                <b-form-group
                  label="Item Difficulty"
                  label-for="item-difficulty"
                  label-cols-lg="2"
                  label-cols-sm="3"
                >
                  <b-form-input
                    id="item-difficulty"
                    type="number"
                    :value="item.difficulty"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <!-- Forms -->
                <br/>
                <p>This item is included in {{ item.forms_info.length }} forms:</p>
                <b-card class="mb-1" no-body>
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.forms-dropdown
                      variant="primary"
                      >Forms</b-button
                    >
                  </b-card-header>
                  <b-collapse id="forms-dropdown" :visible="true">
                    <b-card-body>
                      <b-table
                        :fields="formFields"
                        :items="item.forms_info"
                        @row-clicked="openEditForm"
                        hover
                        responsive
                        small
                        striped
                      >
                        <template v-slot:table-colgroup="scope">
                          <col :key="field.key" v-for="field in scope.fields" />
                        </template>
                        <template v-slot:cell(buttons)="row">
                          <b-button-group class="float-right">
                            <b-button
                              :disabled="shouldDisable(['view_forms'])"
                              @click="openEditForm(row.item)"
                              class="ml-1"
                              size="sm"
                              variant="white"
                              >Edit</b-button
                            >
                          </b-button-group>
                        </template>
                      </b-table>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Enemies  -->
                <br/>
                <p>This item has {{ item.enemies.length }} enemies:</p>
                <b-card class="mb-1" no-body>
                  <b-card-header class="p-1">
                    <b-button
                      block
                      class="text-left"
                      size="lg"
                      v-b-toggle.enemies-dropdown
                      variant="primary"
                      >Enemy Items</b-button
                    >
                  </b-card-header>
                  <b-collapse id="enemies-dropdown" :visible="true">
                    <b-card-body>
                      <b-table
                        :fields="enemyFields"
                        :items="item.enemies"
                        @row-clicked="openEditItem"
                        hover
                        responsive
                        small
                        striped
                      >
                        <template v-slot:table-colgroup="scope">
                          <col :key="field.key" v-for="field in scope.fields" />
                        </template>
                        <template v-slot:cell(buttons)="row">
                          <b-button-group class="float-right">
                            <b-button
                              :disabled="shouldDisable(['edit_items'])"
                              @click="openEditItem(row.item)"
                              class="ml-1"
                              size="sm"
                              variant="white"
                              >Edit</b-button
                            >
                          </b-button-group>
                        </template>
                      </b-table>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-tab>
              <b-tab title="Activity Log" v-if="isAdmin && item.id">
                <ResourceActivityLogs
                  :resourceId="item.id"
                  :projectId="project.id"
                />
              </b-tab>
              <b-tab title="Translations" v-if="item.id && !isSmart && languages.length">
                <b-select :options="languageOptions" v-model="selectedLanguageId"></b-select>
                <LanguagesTranslationsItems
                  v-if="selectedLanguageId"
                  :key="selectedLanguageId"
                  :language="selectedLanguage"
                  :project="project"
                  :itemId="item.id"
                  :versionNumber="item.version.version_number"
                  :uiOptions="{showFilters: false, showLanguageName: true}"
                />

              </b-tab>
              <div
                :key="index"
                v-for="(integration, index) in project.integrations"
                class="mb-1"
              >
                <b-tab
                  :title="integration.app.first_name"
                  v-if="integration.app.app_settings.content.item_widget"
                >
                  <b-embed
                    :src="integrationUrl(integration)"
                    type="iframe"
                    class="iframe-box"
                    aspect="16by9"
                  ></b-embed>
                </b-tab>
              </div>
            </b-tabs>
           <b-button-group class="my-3">
              <b-button
                :disabled="shouldDisable(['edit_items', 'edit_item_meta'])"
                @click="submitThroughStep()"
                style="marginRight: 2px;"
                variant="secondary"
              >
                <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
                >&nbsp;Save
              </b-button>
              <b-dropdown :disabled="savingOrUpdating || shouldDisable(['edit_items', 'edit_item_meta'])" left variant="secondary">
                <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta'])" @click="submitThroughStep('exit')"
                  >Save and exit</b-dropdown-item
                >
                <b-dropdown-item
                  :disabled="shouldDisable(['edit_items', 'edit_item_meta'])"
                  @click="submitThroughStep('next')"
                  v-if="showNext && !isNew"
                  >Save and edit next</b-dropdown-item
                >
                <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta', 'create_items'])" @click="submitThroughStep('another')"
                  >Save and create another</b-dropdown-item
                >
                <b-dropdown-item :disabled="shouldDisable(['edit_items', 'edit_item_meta', 'create_items'])" @click="submitThroughStep('clone')"
                  >Save and create a clone</b-dropdown-item
                >
              </b-dropdown>
            </b-button-group>
            <b-button
              :disabled="shouldDisable('delete_items')"
              class="float-right my-3"
              v-b-modal.delete-item-modal
              v-if="!isNew"
              variant="white"
              >Delete this item</b-button
            >
          </b-form>
        </b-container>
        <Spinner v-else />
      </div>
      <b-container class="mt-3" fluid v-else>
        <b-alert
          :variant="notAllowed ? 'info' : 'danger'"
          class="d-flex justify-content-between align-items-center"
          show
        >
          {{ errorMessage }}
          <div>

            <b-button @click="reloadItem()" size="sm" variant="white">{{
              errorButtonText
            }}</b-button>
            <b-button
              @click="upgrade()"
              class="ml-1"
              size="sm"
              v-if="notAllowed && isPremiumEnabled"
              variant="info"
              >Upgrade to Premium</b-button
            >
          </div>
        </b-alert>
      </b-container>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { deepCopy, friendlyName, iframeUrl, formatItemsForPreview } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import {
    DEFAULT_ITEM_CONTENT,
    DEFAULT_ITEM_SETTINGS,
    SEI_API_BASE,
    IS_UPGRADE_TO_PREMIUM_ENABLED
  } from '../../utils/constants'
  import { SESSION } from '../../utils/session'
  import { VALIDATE } from '../../utils/validate'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { DefaultItem } from '../../utils/default-item'

  import get from 'lodash.get'
  import isEqual from 'lodash.isequal'

  import ItemMeta from './ItemMeta'
  import ItemOptions from './ItemOptions'
  import ItemSettings from './ItemSettings'
  import Resource from './Resource'
  import Spinner from '../Spinner'
  import ItemGenerate from './ItemGenerate'
  import ItemOptionStats from './ItemOptionStats'
  import ItemBlankStats from './ItemBlankStats.vue'
  import LanguagesTranslationsItems from './LanguagesTranslationsItems.vue'
  import { Language } from '../../utils/language'
  import ResourceActivityLogs from './ResourceActivityLogs'

  const NON_META_FIELD_GROUP = ['author', 'item_type', 'last_edited_by', 'form']

  async function getListOfItems(
    projectId,
    page,
    perPage,
    search,
    queryKey,
    sortBy,
    sortOrder,
    metaFieldId
  ) {
    try {
      let route = 'items'
      if (metaFieldId) {
        if (NON_META_FIELD_GROUP.includes(metaFieldId))
          route += `/group/${metaFieldId}`
        else route += `/group_meta/${metaFieldId}`
      }

      let url = `${SEI_API_BASE}/exams/${projectId}/${route}?per_page=${perPage}&page=${page}&sort=${sortOrder}${sortBy}&only=id,name,latest_item_type,live_version_number,num_versions,preview_fields,has_enemy,has_file,has_reference,has_shared,created_at,edited_at,stats`

      if (search) {
        url += `&search=${search}`
      }

      if (queryKey) {
        url += `&query_key=${queryKey}`
      }

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function referenceRequest(projectId, itemId, method, reference) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/references`

      if (method === 'get') {
        const response = await HTTP.get(url)
        return { data: response.data }
      }

      if (method === 'post') {
        const response = await HTTP.post(url, reference)
        return { data: response.data }
      }

      if (method === 'put') {
        const params = '/' + reference.id
        const response = await HTTP.put(url + params, reference)
        return { data: response.data }
      }

      if (method === 'delete') {
        const params = '/' + reference.id
        const response = await HTTP.delete(url + params)
        return { data: response.data }
      }
    } catch (error) {
      return { error }
    }
  }

  async function getItemDetails(projectId, itemId, version) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}?include=enemy_ids,version,stats,smartitem_parts,forms_info&version_number=${version}&include_shared=true&include_enemies=true`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getAllItemIds(projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/all_ids`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveItemRequest(projectId, itemId, fields, originalItemId, originalItemType) {
    try {
      const isSmart = fields.settings.type === 'smart'

      let requestMethod = 'post'
      let url = `${SEI_API_BASE}/exams/${projectId}/items`

      if (itemId) {
        requestMethod = 'put'
        url += `/${itemId}`
      }

      const payload = {
        name: fields.name,
        meta: fields.meta,
        enemy_ids: fields.enemy_ids,
        content: fields.content,
        settings: fields.settings,
        set_smartitem_id: isSmart,
        feedback: fields.feedback
      }

      if (isSmart) {
        payload['smartitem_parts'] = fields.smartitem_parts
      }

      if (fields.references && fields.references.length) {
        payload['references'] = fields.references
      }

      const response = await HTTP[requestMethod](url, payload)

      if (isSmart) {
        if (itemId) {
          await setPreview(projectId, itemId)
        }
        if (originalItemId && originalItemType === 'smart') {
          const error = await cloneSmart(
            projectId,
            originalItemId,
            response.data.id
          )

          if (error) {
            throw new Error(error)
          } else {
            SESSION.itemClone = {}
          }
        }
      }

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function deleteItemRequest(projectId, itemId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}`
      const response = await HTTP.delete(url)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function cloneSmart(projectId, originalItemId, newItemId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${originalItemId}/clone_smart_item`
      const payload = {
        clone_item_id: newItemId
      }

      await HTTP.post(url, payload)
    } catch (error) {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${newItemId}/delete_smart_clone`
      await HTTP.delete(url)

      return error
    }
  }

  async function updateVersion(projectId, itemId, versionNumber, copyTranslations) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/activate_version`
      const payload = {
        version_number: versionNumber,
        copy_translations: copyTranslations
      }

      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function setPreview(projectId, itemId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/set_preview`
      const response = await HTTP.post(url, {})
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function autoName(projectId, itemData) {
    delete itemData.name

    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/auto_name`
      const response = await HTTP.post(url, itemData)

      return { name: response.data.name }
    } catch (error) {
      return { error }
    }
  }

  function formatIncomingMeta(meta, customFieldOrder) {
    if (!meta) meta = { scorpion: {} }

    if (!meta.scorpion) meta.scorpion = {}

    for (const key of Object.keys(meta.scorpion)) {
      if (!customFieldOrder.includes(key)) {
        delete meta.scorpion[key]
      }
    }

    return meta
  }

  function setReferenceOrder(itemReferences) {
    return itemReferences.map((ref, index) => {
      ref.order = index

      return ref
    })
  }

  const PERSIST_OPTIONS = [
    'multiple_choice',
    'domc',
    'matching',
    'build_list',
    'short_answer',
    'external'
  ]

  const PERSIST_OPTIONS_KEY = [
    'multiple_choice',
    'domc',
    'short_answer',
    'external'
  ]

  const EMPTY_REFERENCE = {
    reference_id: null,
    location: ''
  }

  const TYPE_SHOW_OPTION_STATS = ['multiple_choice', 'domc', 'build_list', 'matching']

  const TYPE_SHOW_BLANK_STATS = ['fill_in_the_blanks']

  const TAB_NAMES = ['#EditItem', '#ItemStats', '#TestingInfo', '#ActivityLog', '#Translations']

  export default {
    name: 'Item',
    components: {
      ItemMeta,
      ItemOptions,
      ItemSettings,
      Resource,
      Spinner,
      ItemGenerate,
      ItemOptionStats,
      ItemBlankStats,
      LanguagesTranslationsItems,
      ResourceActivityLogs
    },
    props: {
      project: {
        type: Object
      },
      clone: {
        type: Object
      },
      isPremium: {
        type: Boolean
      }
    },
    watch: {
      selectedType(newType, oldType) {
        if (newType && oldType) {
          this.options[oldType] = {
            content: this.fields.content,
            settings: this.fields.settings
          }

          if (PERSIST_OPTIONS.includes(oldType)) {
            this.options._options = this.fields.content.options
          }

          if (PERSIST_OPTIONS_KEY.includes(oldType)) {
            this.options._key = this.fields.settings.key
          }

          const existing = this.options[newType]
          const options = this.options._options
          const key = this.options._key
          const stem = this.fields.content.stem

          if (existing) {
            this.fields.content = existing.content
            this.fields.settings = existing.settings
          } else {
            this.fields.content = deepCopy(DEFAULT_ITEM_CONTENT[newType])
            const defaultItem = this.getDefaultItem(newType)
            this.fields.settings = deepCopy(defaultItem.settings)
          }

          this.fields.content.stem = stem

          if (PERSIST_OPTIONS.includes(newType) && options) {
            this.fields.content.options = options
          }

          if (PERSIST_OPTIONS_KEY.includes(newType) && key) {
            this.fields.settings.key = key
          }

          const newKey = this.fields.settings.key
          const newOptions = this.fields.content.options

          if (Array.isArray(newKey) && Array.isArray(newOptions)) {
            const [defaultValue] = DEFAULT_ITEM_SETTINGS[newType].key
            const optionsLength = newOptions.length
            const keyLength = newKey.length

            if (optionsLength > keyLength) {
              const difference = optionsLength - keyLength
              const defaultValuesToAppend = new Array(difference).fill(defaultValue)
              newKey.push(...defaultValuesToAppend)
            } else if (keyLength > optionsLength) {
              this.fields.settings.key = new Array(optionsLength).fill(defaultValue)
            }
          }
        }
      },
      tabIndex: function(index) {
        const tabName = TAB_NAMES[index]
        window.history.replaceState(null, null, tabName)
      }
    },
    created() {
      if (!this.isPremium) {
        for (const [i, type] of this.itemTypes.entries()) {
          if (type.value === 'smart') {
            this.itemTypes.splice(i, 1)
            break;
          }
        }
      }
      if (SESSION.isAdmin()) {
        this.isAdmin = true
      }
      this.checkForNextItem()
      this.loadItem()
      this.loadItemIds()
      this.metaHelper = initMetaHelper(this.project)
      this.setTabIndex()
      window.addEventListener('message', this.onWindowMessage)
      window.addEventListener('beforeunload', this.onBeforeUnload)

      this.FetchLanguages()
      SESSION.initSupportedLanguages()
    },
    mounted() {
      if (this.hasInstructions) {
        this.showItemWritingInstructions()
      }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.onWindowMessage)
      window.removeEventListener('beforeunload', this.onBeforeUnload)
    },
    data() {
      return {
        item: {},
        itemTypes: [
          { text: 'Multiple Choice', value: 'multiple_choice' },
          { text: 'True / False', value: 'true_false' },
          { text: 'Discrete Option', value: 'domc' },
          { text: 'Matching', value: 'matching' },
          { text: 'Build List', value: 'build_list' },
          { text: 'Short Answer', value: 'short_answer' },
          { text: 'HotSpot', value: 'hotspot' },
          { text: 'Essay', value: 'essay' },
          { text: 'Likert', value: 'likert' },
          { text: 'Scale', value: 'scale' },
          { text: 'External', value: 'external' },
          { text: 'Smart / Generator', value: 'smart' },
          { text: 'Fill In the Blanks', value: 'fill_in_the_blanks' }
        ],
        enemyFields: [
          { key: 'name', label: 'Name', sortable: true },
          { key: 'preview', label: 'Stem', sortable: true },
          {
            key: 'latest_item_type',
            sortable: true,
            formatter: value => friendlyName(value)
          },
          { key: 'buttons', label: '', sortable: false }
        ],
        formFields: [
          { key: 'name', label: 'Name', sortable: true },
          { key: 'buttons', label: '', sortable: false }
        ],
        optionStats: [],
        sharedOptions: [],
        itemReferences: [],
        safeReferences: [],
        referenceOptions: [],
        widgetOptions: [],
        versionOptions: [],
        allItemIds: [],
        sharedLookup: {},
        fields: {},
        originalFields: {},
        options: {},
        selectedVersion: Number(this.$route.query.version_number),
        selectedType: '',
        step: '',
        activeTab: '',
        previewItemUrl: '',
        externalOptionIndex: null,
        loading: true,
        saving: false,
        deleting: false,
        updatingVersion: false,
        error: false,
        isNew: false,
        loadingResource: true,
        readyToDisplay: false,
        showNext: true,
        showPrev: true,
        expand: true,
        notAllowed: false,
        toggleOptionCollapse: false,
        toggleShared: false,
        toggleWidget: false,
        toggleFeedback: false,
        toggleShowMore: false,
        max: 100,
        tabIndex: 0,
        smartOrigin: new RegExp(/^https:\/\/smart.*\.caveon\.com$/),
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED,
        isAdmin: false,
        languages: [],
        selectedLanguageId: null,
        sessionItems: SESSION.items
      }
    },
    async beforeRouteLeave(to, from, next) {
      const itemChanged = !isEqual(this.fields, this.originalFields)

      if (!itemChanged || confirm('Leave without saving changes?')) {
        if (to.name !== 'projectItem') {
          SESSION.itemClone = {}
        }
        next()
      }
    },
    methods: {
      async loadItem() {
        const itemId = this.$route.params.itemId

        if (itemId === 'new') {
          return this.createNew()
        }

        if (itemId === 'clone') {
          const hasData = Object.keys(SESSION.itemClone).length
          if (!hasData) {
            return this.$router.push({
              name: 'projectItems'
            })
          }

          return this.createNewFromClone()
        }

        SESSION.scrollToItem = itemId

        await this.loadItemReferences()

        if (!this.selectedVersion) {
          return this.$router.push({
            name: 'projectItems'
          })
        }

        return this.getItem()
      },
      async loadItemReferences(itemId) {
        itemId = itemId || this.$route.params.itemId

        const { data, error } = await referenceRequest(
          this.$route.params.projectId,
          itemId,
          'get'
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to load item references.'
          })
        }

        this.itemReferences = [ ...data.results ].sort((a, b) => (a.order - b.order))
        this.safeReferences = deepCopy(this.itemReferences)
      },
      async loadItemIds() {
        const { data } = await getAllItemIds(SESSION.project.id)

        if (data) {
          const allExceptCurrent = data.filter(
            item => item.id !== this.$route.params.itemId
          )
          this.allItemIds = allExceptCurrent
        }
      },
      async getItem() {
        const { data, error } = await getItemDetails(
          this.$route.params.projectId,
          this.$route.params.itemId,
          this.selectedVersion
        )
        if (error) {
          this.error = true
        } else {
          this.item = data
          this.selectedType = this.item.version.settings.type
          this.notAllowed = !this.isPremium && this.selectedType === 'smart'
          if (!this.item.version.shared_content) {
            this.item.version.shared_content = []
          }
          if (!this.item.version.content.widgets) {
            this.item.version.content.widgets = []
          }
          this.toggleShared = Boolean(this.item.version.shared_content.length)
          this.toggleWidget = Boolean(this.item.version.content.widgets.length)

          if (this.notAllowed) {
            this.error = true
            return (this.loading = false)
          }

          this.formatStats()
          this.setFields()
          this.createVersionOptions()
          this.error = false
        }
        this.updatingVersion = false
        this.loading = false

        this.$nextTick(() => {
          this.originalFields = deepCopy(this.fields)
        })
      },
      createVersionOptions() {
        const options = []

        for (let i = 1; i <= this.item.num_versions; i++) {
          const liveVersion = this.item.live_version_number

          if (i === liveVersion && i !== this.selectedVersion) {
            options.push({ text: `${i} (Live)`, value: i })
            continue
          }
          options.push(i)
        }

        this.versionOptions = options
      },
      hasPage(page) {
        let totalPages = this.sessionItems.totalPages
        if (page <= totalPages && page >= 1) {
          return true
        }
        return false
      },
      checkForNextItem() {
        const lookup = get(SESSION, 'items.lookup')

        if (!lookup) {
          this.showPrev = false
          return (this.showNext = false)
        }

        const index = lookup.findIndex(
          item => item.id === this.$route.params.itemId
        )

        let currentPage = this.sessionItems.currentPage
        if (index === lookup.length - 1 && !this.hasPage(currentPage + 1)) {
          this.showNext = false
        }

        if (index === 0 && !this.hasPage(currentPage - 1)) {
          this.showPrev = false
        }
      },
      async getItemsAtPage(page) {
        let items = []

        let results = await getListOfItems(
            this.$route.params.projectId,
            page,
            this.sessionItems.perPage,
            this.sessionItems.filters.search,
            this.sessionItems.queryKey,
            this.sessionItems.sortBy,
            this.sessionItems.sortOrder,
            this.sessionItems.groupBy
        )

        const { error, data } = results
        if (error) {
          return []
        }

        const customFields = get(this.project, 'meta.scorpion.custom')
        const groupBy = get(SESSION, 'items.groupBy')
        if (groupBy) {
          for (const group of data.results) {
            const formattted = formatItemsForPreview(group.value, customFields)
            items.push(...formattted)
          }
        } else {
          items = formatItemsForPreview(data.results, customFields)
        }

        return items
      },
      formatStats() {
        if (!this.isNew) {
          this.item._stats = {}
          this.item._versionStats = {}

          for (const stat in this.item.stats) {
            const num = this.item.stats[stat]
            if (num && typeof num === 'number') {
              this.item._stats[stat] = num.toFixed(2)
            }
          }

          for (const stat in this.item.version.stats) {
            const num = this.item.version.stats[stat]
            if (num && typeof num === 'number') {
              this.item._versionStats[stat] = num.toFixed(2)
            }
          }
        }
      },
      openEditItem(item) {
        if (this.shouldDisable(['edit_items'])) {
          return
        }
        this.$router.push({
          name: 'projectItem',
          params: { itemId: item.id },
          query: { version_number: item.live_version_number }
        })
      },
      openEditForm(form) {
        if (this.shouldDisable(['manage_forms'])) {
          return
        }
        this.$router.push({
          name: 'projectFormEditor',
          params: { formId: form.id }
        })
      },
      createNew() {
        this.isNew = true
        this.selectedType = 'multiple_choice'
        this.setFields()
        this.loading = false
      },
      createNewFromClone() {
        const { settings: { type }, references } = SESSION.itemClone
        this.isNew = true
        this.selectedType = type
        this.itemReferences = references
        this.setFields(true)
        this.loading = false
      },
      setFields(isClone = false) {
        if (this.isNew) {
          if (isClone) {
            const {
              points,
              content,
              settings,
              meta,
              enemy_ids,
              feedback
            } = SESSION.itemClone
            return (this.fields = {
              name: '',
              points,
              content,
              settings,
              meta,
              enemy_ids,
              feedback
            })
          }

          const defaultItem = this.getDefaultItem()

          return (this.fields = {
            name: '',
            enemy_ids: [],
            points: 1,
            content: deepCopy(DEFAULT_ITEM_CONTENT[this.selectedType]),
            settings: deepCopy(defaultItem.settings),
            meta: { scorpion: {} },
            feedback: {}
          })
        }

        return (this.fields = {
          name: this.item.name,
          enemy_ids: this.item.enemy_ids,
          points: this.item.version.settings.points,
          content: this.item.version.content,
          settings: this.item.version.settings,
          meta: formatIncomingMeta(this.item.version.meta, this.metaHelper.customFieldOrder),
          feedback: this.item.version.feedback
        })
      },
      getDefaultItem(type) {
        type = type || this.selectedType

        const defaultItem = new DefaultItem(type)
        const settings = this.project.settings.item_defaults?.settings?.[type]

        if (settings)  {
          defaultItem.updateSettings(settings)
        }

        return defaultItem
      },
      preSaveCheckSuccess() {
        let restricted = false

        const meta = get(this.fields, 'meta.scorpion', {})

        for (const restriction of this.project.role_restrictions) {
          const [fieldId, values] = restriction

          const field = this.metaHelper.getField(fieldId)

          const hasValues = Array.isArray(values) && values.length

          if (!field || !hasValues) continue

          if (field.type === 'select_one') {
            if (!values.includes(meta[fieldId])) {
              restricted = true
              break
            }

            continue
          }

          const metaValueAsArray = Array.isArray(meta[fieldId]) ? meta[fieldId] : []

          for (const value of values) {
            if (!metaValueAsArray.includes(value)) {
              restricted = true
              break
            }
          }

          if (restricted) break
        }

        if (restricted) {
          const confirmed = confirm(`
            This items custom fields have values that conflict with your restrictions in this project.
            If you save you will not have access to this item anymore.
            Save anyway?
            `
          )

          if (!confirmed) return

          this.step = 'exit'
        }

        if (!this.isNew && this.selectedVersion !== this.item.num_versions) {
          const confirmed = confirm(
            'Saving an older version of an item can potentially overwrite the latest version. Save anyway?'
          )

          if (!confirmed) return
        }

        if (this.hasOptions && !this.fields.settings.key.some(Boolean)) {
          const points = this.fields.settings.type === 'multiple_choice' ? 'full' : 'zero'
          const confirmed = confirm(`No correct option set. Examinee will get ${points} points. Save anyway?`)

          if (!confirmed) return
        }

        return true
      },
      async saveItem() {
        if (!this.preSaveCheckSuccess()) {
          return
        }

        this.saving = true
        const itemId = this.isNew ? null : this.$route.params.itemId

        let fields = {
          ...this.fields
        }

        if (this.selectedType === 'external') {
          fields = this.formatExternalForSave()
        }

        const { original_id, original_type } = SESSION.itemClone

        this.itemReferences = setReferenceOrder(this.itemReferences)

        const missingReferenceIds = this.itemReferences.some(reference => !reference.reference_id)
        if (missingReferenceIds) {
          this.saving = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save! Please make sure all references have a value selected.'
          })
        }

        const newReferences = this.itemReferences.filter(ref => !ref.id)

        if (newReferences && newReferences.length) {
          fields['references'] = newReferences
        }

        const { data, error } = await saveItemRequest(
          this.$route.params.projectId,
          itemId,
          fields,
          original_id,
          original_type
        )

        if (!this.isNew && !error) {
          const existingReferences = this.itemReferences.filter(ref => ref.id)
          await this.saveExistingReferences(data.id, data.exam_id, existingReferences)
        }

        if (!error) {
          await this.loadItemReferences(data.id)
        }

        this.saving = false

        if (error) {
          let message = 'Failed to save! Please try again.'

          const messages = get(error, 'response.data.messages')
          const nameError = get(error, 'response.data.errors.name')

          if (nameError) {
            message = 'An item with this name already exists in this project.'
          }

          if (messages && messages.length) {
            message = [ message, ...messages ].join('\n')
          }

          this.$bvModal.hide('save-old-modal')
          return EVENT.alert({
            variant: 'danger',
            message
          })
        }

        const metaChanged = !isEqual(this.fields.meta, this.originalFields.meta)

        if (metaChanged) {
          EVENT.getProject()
        }

        this.originalFields = deepCopy(this.fields)

        SESSION.scrollToItem = data.id

        if (this.isNew && !this.step) {
          return this.$router.push({
            params: {
              itemId: data.id
            },
            query: {
              version_number: data.live_version_number
            }
          })
        }

        if (this.step === 'exit') {
          return this.$router.push({
            name: 'projectItems'
          })
        }

        if (this.step === 'next') {
          return this.navigateToItem(1)
        }

        if (this.step === 'another') {
          if (this.$route.params.itemId === 'new') {
            return EVENT.reloadRoute()
          }
          this.$router.push({
            name: 'projectItem',
            params: { itemId: 'new' }
          })
        }

        if (this.step === 'clone') {
          return this.cloneItem()
        }

        if (this.selectedVersion !== data.num_versions) {
          this.$router.replace({
            params: {
              itemId: data.id
            },
            query: {
              version_number: data.num_versions
            }
          })
        }

        if (this.step === 'smart-save') {
          return EVENT.reloadRoute()
        }
      },
      async deleteItem() {
        this.deleting = true

        const { error } = await deleteItemRequest(this.project.id, this.item.id)

        if (error) {
          this.deleting = false
          return EVENT.alert({
            variant: 'danger',
            message: 'An error was encountered while deleting this item'
          })
        }

        this.$router.push({
          name: 'projectItems'
        })
      },
      async saveExistingReferences(itemId, projectId, existingReferences) {
        let err = false

        for (const reference of existingReferences) {
          const safeReference = this.safeReferences.find(
            safeRef => safeRef.id === reference.id
          )
          const sameLocation = reference.location === safeReference.location
          const sameReference = reference.reference_id === safeReference.reference_id
          const sameOrder = reference.order === safeReference.order

          if (!sameLocation || !sameReference || !sameOrder) {
            const { error } = await referenceRequest(
              projectId,
              itemId,
              'put',
              reference
            )

            if (error) {
              err = true
            }
          }
        }

        if (err) {
          EVENT.alert({
            variant: 'danger',
            message: 'An error was encountered while saving item references.'
          })
        }
      },
      async deleteReference(index) {
        const [ reference ] = this.itemReferences.splice(index, 1)

        if (!this.item.id || !reference || !reference.id) {
          return
        }

        const { error } = await referenceRequest(
          this.project.id,
          this.item.id,
          'delete',
          reference
        )

        if (error) {
          this.itemReferences.splice(index, 1, reference)
          EVENT.alert({
            variant: 'danger',
            message: 'Error deleting reference. It might be required.'
          })
        }
      },
      moveReference(index, amount) {
        const to = index + amount

        const a = this.itemReferences[index]
        const b = this.itemReferences[to]
        const itemReferences = [
          ...this.itemReferences
        ]

        itemReferences[index] = b
        itemReferences[to] = a

        this.itemReferences = itemReferences
      },
      async navigateToItem(by) {
        const currentIndex = SESSION.items.lookup.findIndex(
          item => item.id === this.item.id
        )

        let currentPage = this.sessionItems.currentPage
        let newIndex = currentIndex + by
        let newPage = currentPage + by
        let nextItem = {}
        if (newIndex > -1 && newIndex < SESSION.items.lookup.length) {
          nextItem = SESSION.items.lookup[newIndex]
        }
        else {
          let items = await this.getItemsAtPage(newPage)
          if (by > 0) {
            // First item of next page
            nextItem = items[0]
          } else {
            // Last item of previous page
            nextItem = items[items.length - 1]
          }
          this.sessionItems.currentPage = newPage
          this.sessionItems.lookup = items
        }

        this.$router.push({
          name: 'projectItem',
          params: { itemId: nextItem.id },
          query: { version_number: nextItem.num_versions }
        })
      },
      reloadItem() {
        if (this.notAllowed) {
          return this.$router.push({
            name: 'projectItems'
          })
        }
        this.loading = true
        this.getItem()
      },
      cloneItem() {
        const references = []
        for (const reference of this.itemReferences) {
          const { reference_id, location, order } = reference
          const newReference = {
            reference_id,
            location,
            order
          }
          references.push(newReference)
        }
        SESSION.itemClone = {
          ...this.fields,
          original_id: this.$route.params.itemId,
          original_type: this.item.version.settings.type,
          references
        }
        if (this.$route.params.itemId === 'clone') {
          return EVENT.reloadRoute()
        }
        this.$router.push({
          name: 'projectItem',
          params: { itemId: 'clone' }
        })
      },
      formatExternalForSave() {
        const fields = deepCopy(this.fields)

        if (!fields.settings._advanced) {
          delete fields.settings.request
        }

        if (fields.settings.request) {
          fields.content.url = ''

          const headerKeys = new Set()

          for (const header of fields.settings._headers) {
            headerKeys.add(header.key)
            fields.settings.request.headers[header.key] = header.value
          }

          for (const key of Object.keys(fields.settings.request.headers)) {
            if (!headerKeys.has(key)) delete fields.settings.request.headers[key]
          }

          const jsonKeys = new Set()

          for (const json of fields.settings._json) {
            jsonKeys.add(json.key)
            fields.settings.request.json[json.key] = json.value
          }

          for (const key of Object.keys(fields.settings.request.json)) {
            if (!jsonKeys.has(key)) delete fields.settings.request.json[key]
          }
        }

        delete fields.settings._headers
        delete fields.settings._json
        delete fields.settings._advanced

        return fields
      },
      onSubmit(event) {
        if (this.hasOptions) {
          const optionWrapper = document.getElementById('options-list')
          const optionFieldsAreValid = VALIDATE.validateFields(
            optionWrapper,
            true,
            true
          )

          if (!optionFieldsAreValid) {
            this.showMore = true

            return setTimeout(() => {
              this.testValidity(event.target)
            }, 500)
          }
        }

        this.testValidity(event.target)
      },
      testValidity(target) {
        const isValid = VALIDATE.validateFields(target)
        if (isValid) {
          this.saveItem()
        }
      },
      submitThroughStep(step = null) {
        this.step = step
        this.$refs.form.dispatchEvent(new Event('submit', { cancelable: true }))
      },
      versionText(version) {
        const versionNumber = version || this.selectedVersion
        let text = `Version ${versionNumber}`
        if (versionNumber === this.item.live_version_number) {
          text += ' (Live)'
        }
        return text
      },
      versionSelected(version) {
        if (version !== this.selectedVersion) {
          this.$router.push({
            name: 'projectItem',
            query: { version_number: version }
          })
        }
      },
      openPreview(externalOptionIndex = null) {
        if (this.isNew) {
          const confirmSave = confirm('This item must be saved before you can preview it. Do you want to save now?')

          if (!confirmSave) return

          return this.testValidity(this.$refs.form)
        }

        this.externalOptionIndex = externalOptionIndex
        this.previewItemUrl = `/take/?preview_token=${this.item.version.preview_token}`
        this.$bvModal.show('preview')
      },
      reloadPreview() {
        const frame = this.$refs.previewFrame
        frame.src += ''
      },
      async makeVersionLive() {
        let copyTranslations = false

        if (this.selectedVersion === this.versionOptions.length) {
            const message = 'Would you like to copy over item translations from the current live version?'

            const options = {
                title: 'Copy Translations',
                okVariant: 'secondary',
                okTitle: 'Yes',
                cancelVariant: 'white',
                cancelTitle: 'No',
                size: 'sm',
                noFade: true
            }

            copyTranslations = await this.$bvModal.msgBoxConfirm(message, options)
        }

        this.updatingVersion = true

        const { error } = await updateVersion(
          this.$route.params.projectId,
          this.$route.params.itemId,
          this.selectedVersion,
          copyTranslations
        )

        if (error) {
          this.updatingVersion = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to make version live.'
          })
        }

        this.loadItem()
      },
      resourceLoaded() {
        this.loadingResource = false

        this.$nextTick(() => {
          this.readyToDisplay = true
        })
      },
      sharedUpdated(sharedList) {
        const sharedLookup = {}
        for (const shared of sharedList) {
          sharedLookup[shared.id] = {
            ...shared
          }
        }
        this.sharedLookup = sharedLookup

        this.sharedOptions = sharedList.map(shared => {
          return { value: shared.id, text: shared.name }
        })

        for (const [index, id] of this.fields.content.shared.entries()) {
          const sharedExists = this.sharedOptions.find(
            shared => shared.value === id
          )
          if (!sharedExists) {
            this.fields.content.shared.splice(index, 1)
          }
        }
      },
      referencesUpdated(references) {
        const options = [{ value: null, text: 'Select', disabled: true }]

        for (const [index, reference] of this.itemReferences.entries()) {
          const referenceExists = references.find(
            ref => ref.id === reference.reference_id
          )
          if (!referenceExists) {
            this.itemReferences.splice(index, 1)
          }
        }

        for (const reference of references) {
          options.push({
            value: reference.id,
            text: reference.name
          })
        }

        this.referenceOptions = options
      },
      widgetsUpdated(widgets) {
        const options = []

        for (const widget of widgets) {
          const option = {
            text: widget.name,
            value: widget.id
          }

          options.push(option)
        }

        this.widgetOptions = options
      },
      async addReference() {
        const newReference = deepCopy(EMPTY_REFERENCE)
        newReference.order = this.itemReferences.length - 1

        this.itemReferences.push(newReference)
      },
      openResourceModal(tab) {
        this.activeTab = tab
        this.$bvModal.show('resources')
      },
      clearActiveTab() {
        this.activeTab = ''
      },
      upgrade() {
        const orgId = this.project.organization_id

        if (!orgId) {
          return this.$router.push({
            name: 'projectSettings'
          })
        }

        this.$router.push({
          name: 'orgBilling',
          params: { orgId }
        })
      },
      async autoName() {
        const { name, error } = await autoName(
          this.project.id,
          deepCopy(this.fields)
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to auto name.'
          })
        }

        this.fields.name = name
      },
      onSharedChange(value) {
        if (!value) {
          this.fields.content.shared = []
        }
      },
      onWidgetChange(value) {
        if (!value) {
          this.fields.content.widgets = []
        }
      },
      setTabIndex() {
        const index = TAB_NAMES.indexOf(this.$route.hash)
        if (index > -1) {
          this.tabIndex = index
        }
      },
      integrationUrl(integration) {
        let replaceWith = '.'
        if (SESSION.env) replaceWith = `.${SESSION.env}.`
        const url = iframeUrl(
          integration.app.app_settings.content.item_widget.replace('.env.', replaceWith),
          integration.shared_token,
          integration.exam_id,
          this.item.id
        )

        if (this.item.version && this.item.version.version_number !== -1) {
          return `${url}&version_number=${this.item.version.version_number}`
        }

        return url
      },
      onWindowMessage(event) {
        if (this.smartOrigin.test(event.origin)) {
          const { action } = event.data

          if (action === 'save') {
            this.submitThroughStep('smart-save')
          }
        }

        if (typeof this.externalOptionIndex !== 'number') return

        if (event.data.hasOwnProperty('previewResponse') && typeof event.data.previewResponse === 'string') { // eslint-disable-line no-prototype-builtins
          this.fields.content.options.splice(this.externalOptionIndex, 1, event.data.previewResponse)
        }

        if (event.data.hasOwnProperty('previewScore') && typeof event.data.previewScore === 'number') { // eslint-disable-line no-prototype-builtins
          this.fields.settings.key.splice(this.externalOptionIndex, 1, event.data.previewScore)
        }
      },
      onBeforeUnload(event) {
        const itemChanged = !isEqual(this.fields, this.originalFields)

        if (itemChanged) {
          event.returnValue = ''
        }
      },
      showItemWritingInstructions() {
        const storage = window.sessionStorage
        const storageKey = `item-writing-instructions-${this.project.id}`
        const storageValue = Math.floor(Date.now() / 1000)

        if (!storage.getItem(storageKey)) {
          storage.setItem(storageKey, storageValue)
          this.$bvModal.show('item-writing-instructions')
        }
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      async FetchLanguages() {
        const response = await Language.FetchAll(this.project.id)

        if (response.error) {
          console.error('it broke')
          return
        }

        this.languages = [...response.results]
      }
    },
    computed: {
      showVersion() {
        return this.item.num_versions && !this.isNew
      },
      versionIsntLive() {
        return this.selectedVersion !== this.item.live_version_number
      },
      savingOrUpdating() {
        return this.saving || this.updatingVersion
      },
      errorMessage() {
        if (this.notAllowed) {
          return 'Free accounts cannot edit smart items'
        }
        return 'Failed to load item.'
      },
      expandOrCollapse() {
        if (this.expand) return 'Collapse All'
        return 'Expand All'
      },
      errorButtonText() {
        if (this.notAllowed) {
          return 'Back to Items'
        }
        return 'Reload'
      },
      hasMeta() {
        return this.metaHelper.getMetaFields().length
      },
      hasPinned() {
        return this.metaHelper.getPinnedFields().length
      },
      hasOptions() {
        return TYPE_SHOW_OPTION_STATS.includes(this.fields.settings.type)
      },
      hasBlanks() {
        return TYPE_SHOW_BLANK_STATS.includes(this.fields.settings.type)
      },
      hasNamingScheme() {
        return Boolean(this.project.settings.naming_scheme_compiled)
      },
      optionExpandOrCollapse() {
        if (this.toggleShowMore) return 'Hide more'
        return 'Show more'
      },
      hasInstructions() {
        return this.project.settings.item_writing_instructions?.length
      },
      languageOptions() {
        return [{text: 'Select language', value: null}].concat(this.languages.map(language => {
          return {
            text: language.name,
            value: language.id
          }
        }))
      },
      selectedLanguage() {
        if (!this.selectedLanguageId) return

        return this.languages.filter(language => {
          return language.id === this.selectedLanguageId
        })[0]
      },
      isSmart() {
        return this.fields.settings.type === 'smart'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-button:hover {
    text-decoration: none;
  }
  .b-table-sticky-header {
    max-height: 60vh;
  }
  .option {
    background: #eee;
    margin: 6px 0;
    border-radius: 5px;
    padding: 0.65em 0;
    border: 2px solid transparent;
  }
  .modal-body {
    iframe {
      width: 100%;
      min-height: 85vh;
      border: 1px solid #eee;
    }
  }

  .iframe-box {
    min-height: 50vh;
  }
  .embed-responsive-16by9 {
    min-height: 50vh;
  }
  .stem-field {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
</style>
