<template>
  <b-container>
    <b-row>
      <b-col class="border">
        <small>Type</small>
        <h6>{{ type }}</h6>
      </b-col>
      <b-col class="border ml-1" v-if="!hideScore">
        <small>Score</small>
        <h6>{{ item.score }} <span v-if="item.manual_score !== null">(manual: {{item.manual_score}})</span></h6>
      </b-col>
      <b-col class="border mx-1" v-if="!hideScore">
        <small>Points Earned</small>
        <h6>{{ pointsEarned }}</h6>
      </b-col>
      <b-col class="border mr-1" v-if="!hideScore">
        <small>Possible</small>
        <h6>{{ item.points }}</h6>
      </b-col>
      <b-col class="border mr-1">
        <small>Item Response Time</small>
        <h6>{{ duration }}</h6>
      </b-col>
      <b-col class="border">
        <small>Language Used</small>
        <h6>{{ item.langName }}</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="border mt-1">
        <small>Stem</small>
        <div v-if="item.for_exam.type === 'fill_in_the_blanks'" v-markdown="fillInTheBlanksStem"></div>
        <div v-else v-markdown="item.for_exam.stem"></div>
      </b-col>
    </b-row>
    <component :is="component" :item="item" />
  </b-container>
</template>

<script>
  import BuildList from './BuildList'
  import DOMC from './DOMC'
  import Essay from './Essay'
  import External from './External'
  import HotSpot from './HotSpot'
  import Likert from './Likert'
  import Matching from './Matching'
  import MultipleChoice from './MultipleChoice'
  import Scale from './Scale'
  import ShortAnswer from './ShortAnswer'
  import TrueFalse from './TrueFalse'
  import FillInTheBlanks from './FillInTheBlanks.vue'

  export default {
    name: 'ItemResponse',
    components: {
      BuildList,
      DOMC,
      Essay,
      External,
      HotSpot,
      Likert,
      Matching,
      MultipleChoice,
      Scale,
      ShortAnswer,
      TrueFalse,
      FillInTheBlanks
    },
    props: {
      item: {
        type: Object
      },
      hideScore: {
        type: Boolean
      }
    },
    created() {
      this.formatItemFields()
      if (this.item.item_type === 'smart') {
        this.componentToShow(this.item.for_exam.type)
      } else {
        this.componentToShow(this.item.item_version.settings.type)
      }
    },
    data() {
      return {
        component: null,
        type: '',
        pointsEarned: 0,
        duration: 0
      }
    },
    methods: {
      formatItemFields() {
        if (!this.item.is_excluded) {
          let score
          if (typeof this.item.manual_score === 'number') {
            score = this.item.manual_score
          } else {
            score = this.item.score
          }
          this.pointsEarned = parseFloat((score * this.item.points).toFixed(2))
        }
        const rounded = Math.round(this.item.seconds)
        this.duration = `${rounded}s`
      },
      componentToShow(type) {
        let chosenComponent
        let formattedType
        switch (type) {
          case 'build_list':
            chosenComponent = BuildList
            formattedType = 'Build List'
            break
          case 'domc':
            chosenComponent = DOMC
            formattedType = 'DOMC'
            break
          case 'essay':
            chosenComponent = Essay
            formattedType = 'Essay'
            break
          case 'external':
            chosenComponent = External
            formattedType = 'External'
            break
          case 'hotspot':
            chosenComponent = HotSpot
            formattedType = 'Hotspot'
            break
          case 'likert':
            chosenComponent = Likert
            formattedType = 'Likert'
            break
          case 'matching':
            chosenComponent = Matching
            formattedType = 'Matching'
            break
          case 'multiple_choice':
            chosenComponent = MultipleChoice
            formattedType = 'Multiple Choice'
            break
          case 'scale':
            chosenComponent = Scale
            formattedType = 'Scale'
            break
          case 'short_answer':
            chosenComponent = ShortAnswer
            formattedType = 'Short Answer'
            break
          case 'true_false':
            chosenComponent = TrueFalse
            formattedType = 'True/False'
            break
          case 'fill_in_the_blanks':
            chosenComponent = FillInTheBlanks
            formattedType = 'Fill In the Blanks'
            break
        }
        this.component = chosenComponent
        this.type = formattedType
      }
    },
    computed: {
      fillInTheBlanksStem() {
        let stemArr = []
        let index = 1
        for (const segment of this.item.for_exam.segments) {
          if (segment.type === 'content') {
            stemArr.push(segment.text)
          } else {
            stemArr.push(`[__Blank ${index}__]`)
            index++
          }
        }
        return stemArr.join(' ')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
