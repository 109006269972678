<template>
  <div>
    <b-navbar sticky variant="light">
      <b-button-group>
        <LanguagesAddEdit :project="project" :language="newLanguage()" @closed="updatePage" :supportedLanguages="supportedLanguages" />
      </b-button-group>
    </b-navbar>
    <b-container fluid v-if="!loading" class="mt-3">
      <div v-if="languages.length">
        <b-row v-for="lang in languages" :key="lang.id" class="border-bottom py-2">
          <b-col md="4">
            <h4 @click="editTranslations(lang)" :disabled="shouldDisable('manage_translations')" class="clickable">{{ lang.name }}</h4>
            <b-button-group>
              <b-button
                @click="editTranslations(lang)"
                :disabled="shouldDisable('manage_translations')"
                variant="white"
                size="sm">
                Translations
              </b-button>
              <LanguagesAddEdit :project="project" :language="lang" @closed="updatePage" :supportedLanguages="supportedLanguages" />
              <b-button
                @click="deleteLanguage(lang)"
                :disabled="shouldDisable('manage_translations')"
                title="Delete"
                variant="white"
                size="sm"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </b-col>
          <b-col>
            <LanguagesProgess :project="project" :language="lang" />
          </b-col>
        </b-row>
      </div>
      <div v-else>Add a language to start translating</div>
    </b-container>
    <Spinner v-else />
  </div>
</template>
<script>
import { Language } from '../../utils/language'
import { SESSION } from '../../utils/session'

import Spinner from '../Spinner'
import LanguagesAddEdit from './LanguagesAddEdit.vue'
import LanguagesProgess from './LanguagesProgress.vue'


export default {
  name: 'LanguagesHome',
  components: {
    Spinner,
    LanguagesAddEdit,
    LanguagesProgess
  },
  props: {
    project: {
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      languages: [],
      supportedLanguages: null
    }
  },
  async created() {
    await this.FetchLanguages()
    await SESSION.initSupportedLanguages()

    this.supportedLanguages = SESSION.supportedLanguages
    this.loading = false
  },
  methods: {
    async FetchLanguages() {
      const response = await Language.FetchAll(this.project.id)

      if (response.error) {
        console.error('it broke')
        return
      }

      this.languages = [...response.results]
    },
    newLanguage() {
      return new Language({ exam_id: this.project.id, properties: { direction: 'ltr' }})
    },
    async deleteLanguage(language) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Confirm delete',
        {
          title: 'This action is permanent',
          okVariant: 'danger',
          okTitle: `Delete ${language.name} and all translations`,
          cancelVariant: 'white',
          size: 'sm',
          noFade: true
        }
      )

      if (!confirmed) return

      await language.delete()
      await this.FetchLanguages()
    },
    async updatePage() {
      await this.FetchLanguages()
    },
    editTranslations(language) {
      this.$router.push({
        name: 'languagesTranslationsHome',
        params: { languageId: language.id }
      })
    },
    shouldDisable(neededPerms) {
      return !SESSION.hasPermissions(neededPerms)
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
  }
</style>
