<template>
  <div>
    <b-form-group
      :label="selectLabel"
      :label-cols-lg="nameLabelColsLg"
      :label-cols-sm="nameLabelColsSm"
      label-for="configuration-select"
    >
      <b-form-select
        id="configuration-select"
        :value="selectedValue"
        @change="onSelectedConfigurationChange"
      >
        <b-form-select-option-group label="Parent Configuration">
          <b-form-select-option :value="options.parent.value">
            {{ options.parent.text }}
          </b-form-select-option>
        </b-form-select-option-group>

        <b-form-select-option-group v-if="options.children.length" :options="options.children" label="Children (Fields left blank in these configurations use the settings of the Parent Configuration)"></b-form-select-option-group>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label=""
      label-cols-lg="3"
      label-cols-sm="4"
      v-if="showButtons"
    >
      <b-button
        v-if="!editable"
        variant="white"
        @click="editConfiguration(true)"
      >
        Edit Configuration
      </b-button>

      <b-button
        class="float-right"
        variant="white"
        v-if="editable && !editOnly"
        @click="editConfiguration(false)"
        >Cancel</b-button
      >
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'ConfigurationSelect',
  props: {
    defaultId: {
      type: String
    },
    configurations: {
      type: Array
    },
    selectedConfiguration: {
      type: String
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    stackName: {
      type: Boolean,
      default: false
    },
    editOnly: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.createOptions()
    this.selectedValue = this.selectedConfiguration
  },
  watch: {
    configurations() {
      this.createOptions()
    },
    selectedConfiguration() {
      this.selectedValue = this.selectedConfiguration
    }
  },
  data() {
    return {
      selectedValue: '',
      options: {}
    }
  },
  methods: {
    createOptions() {
      const options = {
        children: []
      }

      for (const configuration of this.configurations) {
        const { name, id, is_default } = configuration

        const option = {
          text: name,
          value: id
        }

        if (is_default) {
          options.parent = option

          continue
        }

        options.children.push(option)
      }

      this.options = options
    },
    onSelectedConfigurationChange(configurationId) {
      this.$emit('configuration-selected', configurationId)

      if (this.editable) {
        this.editConfiguration(false)
      }
    },
    editConfiguration(isEditable) {
      this.$emit('configuration-is-editable', isEditable)
    }
  },
  computed: {
    nameLabelColsLg() {
      return this.stackName ? 0 : 3
    },
    nameLabelColsSm() {
      return this.stackName ? 0 : 4
    },
    selectLabel() {
      return this.noLabel ? '' : 'Select a configuration'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
