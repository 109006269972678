import { SESSION } from './session'

export const LOCAL = {
  analytics: {
    get() {
      return (
        JSON.parse(
          localStorage.getItem(
            `${SESSION.project.id}-${SESSION.user.caveon_id}-analytics`
          )
        ) || {}
      )
    },
    set(data) {
      localStorage.setItem(
        `${SESSION.project.id}-${SESSION.user.caveon_id}-analytics`,
        JSON.stringify(data)
      )
    }
  },
  notifications: {
    get() {
      return (
        JSON.parse(
          localStorage.getItem(`${SESSION.user.caveon_id}-notifications`)
        ) || []
      )
    },
    set(data) {
      localStorage.setItem(
        `${SESSION.user.caveon_id}-notifications`,
        JSON.stringify(data)
      )
    }
  },
  projectListing: {
    get() {
      return JSON.parse(
        localStorage.getItem(`${SESSION.user.caveon_id}-projectListing`)
      )
    },
    set(data) {
      localStorage.setItem(
        `${SESSION.user.caveon_id}-projectListing`,
        JSON.stringify(data)
      )
    }
  },
  star: {
    get(key) {
      return (
        JSON.parse(
          localStorage.getItem(`${SESSION.user.caveon_id}-starred-${key}`)
        ) || []
      )
    },
    set(key, data) {
      localStorage.setItem(
        `${SESSION.user.caveon_id}-starred-${key}`,
        JSON.stringify(data)
      )
    }
  }
}
