<template>
  <div>
    <TopNavigation v-if="!hideButtons" />

    <Spinner v-if="loading" />

    <div v-else>
      <div v-if="invalidToken" class="p-4">
        <h3 class="d-flex justify-content-center">Invalid Token</h3>
      </div>

      <div v-else>
        <ExamWatchFilters :project-lookup="projectLookup" @filters-changed="onFiltersChanged" v-if="!hideButtons" />

        <ExamWatchDetails @active-exam="onActiveExam" @proctor-event="onProctorEvent" @remove-exam="onRemoveExam" @set-spawned-exam="onSetSpawnedExam" :socket="socket" />

        <b-navbar sticky variant="light">
          <b-button :disabled="filtering" variant="white" class="mr-2" @click="openFilterModal" v-if="!hideButtons">Projects</b-button>

          <b-dropdown :disabled="filtering" text="Actions" variant="white" class="mr-2">
            <b-dropdown-group v-if="hasSelected" header="Selected Exams">
              <b-dropdown-item-button @click="onProctorEvent('resume')">
                <font-awesome-icon icon="play" class="mr-1" />
                Resume
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="onProctorEvent('pause')">
                <font-awesome-icon icon="pause" class="mr-1" />
                Pause
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="onProctorEvent('stabilize')">
                <font-awesome-icon icon="redo" class="mr-1" />
                Fix Stuck
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="onProctorEvent('suspend')">
                <font-awesome-icon icon="stop" class="mr-1" />
                Suspend
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="onRemoveSelectedDisconnected">
                <font-awesome-icon icon="trash" class="mr-1" />
                Remove Disconnected
              </b-dropdown-item-button>

              <b-dropdown-item-button @click="clearSelected()">
                <font-awesome-icon icon="eraser" class="mr-1" />
                Clear Selected
              </b-dropdown-item-button>
            </b-dropdown-group>

            <b-dropdown-group header="All Exams">
              <b-dropdown-item-button @click="onRemoveAllDisconnected">
                <font-awesome-icon icon="trash" class="mr-1" />
                Remove Disconnected
              </b-dropdown-item-button>
            </b-dropdown-group>
          </b-dropdown>

          <b-input v-model="search" :disabled="filtering" placeholder="Search Exams" debounce="200" />
        </b-navbar>

        <Spinner v-if="filtering" />

        <div v-else>
            <div v-if="activeExams">
                <ExamWatchTable
                    v-for="projectId in sortedProjectIds"
                    @add-selected="onAddSelected"
                    @remove-selected="onRemoveSelected"
                    :key="projectId"
                    :exams="examLookup[projectId]"
                    :project-name="projectLookup[projectId].name"
                    :search="search"
                    :ref="projectId"
                />
            </div>

            <div v-else>
                <h3 class="mt-4 mb-0 pt-4 d-flex justify-content-center align-items-center">
                    No Active Exams
                </h3>

                <div class="d-flex justify-content-center align-items-center">
                    <b-button @click="openFilterModal" variant="link">
                        Open Project Select
                    </b-button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client'

import { EVENT } from '../utils/event-bus'

import ExamWatchDetails from './ExamWatchDetails.vue'
import ExamWatchFilters from './ExamWatchFilters.vue'
import ExamWatchTable from './ExamWatchTable.vue'
import Spinner from './Spinner.vue'
import TopNavigation from './TopNavigation.vue'

const SOCKET_LISTENERS = [
  { event: 'exam_watch_results', handler: 'onExamWatchResults' },
  { event: 'exam_watch_filter_results', handler: 'onExamWatchFilterResults' },
  { event: 'exam_watch_connection', handler: 'onExamWatchConnection' },
  { event: 'exam_watch_disconnection', handler: 'onExamWatchDisconnection' },
  { event: 'exam_watch_update', handler: 'onExamWatchUpdate' },
  { event: 'exam_watch_spawn', handler: 'onExamWatchSpawn' },
  { event: 'exam_watch_invalid_token', handler: 'onExamWatchInvalidToken' },
  { event: 'exam_watch_settings', handler: 'onExamWatchSettings' }
]

const SELECTED_EXAMS = new Set()

function getIndexAndExam(examLookup, projectId, examId) {
  try {
    const exams = examLookup[projectId]
    const index = exams.findIndex(exam => exam.id === examId)
    if (index === -1) {
      return { index }
    }
    const exam = exams[index]
    return { index, exam }
  } catch (error) {
    return { error }
  }
}

function sortProjectIds(projectIds, projectLookup) {
  return projectIds.sort((a, b) => {
    return projectLookup[a].name.localeCompare(projectLookup[b].name)
  })
}

export default {
  name: 'ExamWatch',
  components: {
    ExamWatchDetails,
    ExamWatchFilters,
    ExamWatchTable,
    Spinner,
    TopNavigation
  },
  created() {
    let options

    if (this.$route.query.token) {
      this.hideButtons = true

      options = {
        query: {
          'proctor-token': this.$route.query.token
        }
      }
    }

    this.socket = io(`${location.origin}/exam_watch`, options)

    this.socket.once('connect', this.onConnect)

    this.registerListeners()
  },
  beforeDestroy() {
    this.unregisterListeners()

    this.socket.disconnect()

    delete this.socket.io.nsps[this.socket.nsp]
  },
  watch: {
    examLookup: {
      handler(newValue) {
        const projectIds = Object.keys(newValue)

        this.sortedProjectIds = sortProjectIds(projectIds, this.projectLookup)
      }
    }
  },
  data() {
    return {
      loading: true,
      filtering: false,
      hasSelected: false,
      invalidToken: false,
      hideButtons: false,
      sortedProjectIds: [],
      projectLookup: {},
      examLookup: {},
      activeExamId: '',
      search: '',
      socket: null
    }
  },
  methods: {
    registerListeners() {
      for (const listener of SOCKET_LISTENERS) {
        this.socket.on(listener.event, this[listener.handler])
      }
    },
    unregisterListeners() {
      for (const listener of SOCKET_LISTENERS) {
        this.socket.off(listener.event, this[listener.handler])
      }
    },
    onConnect() {
      this.socket.emit('exam_watch_init')
    },
    onExamWatchResults(data) {
      const { projects } = data

      this.projectLookup = projects

      this.loading = false

      this.$nextTick(() => {
        this.openFilterModal()
      })
    },
    formatExam(exam, projectInfo) {
      const { id, projectId, tags, examinee_info, is_paused, logs, status, progress, settings } = exam

      const { examinee_keys } = projectInfo

      const formattedExam = {
        id,
        projectId,
        tags,
        settings,
        examineeInfo: examinee_info,
        paused: is_paused,
        disconnected: false
      }

      formattedExam.tagsAsString = tags.join('')

      this.setExamineeData(formattedExam, examinee_info, examinee_keys)
      this.setLogData(formattedExam, logs)
      this.setStatusData(formattedExam, status)
      this.setProgressData(formattedExam, progress)
      this.setSettings(formattedExam, settings)

      return formattedExam
    },
    setExamineeData(exam, examineeInfo, examineeKeys) {
      let examinee = ''

      let keys

      if (examineeKeys.length) {
        keys = examineeKeys
      } else {
        keys = Object.keys(examineeInfo)
      }

      const examineeSegments = []

      for (const key of keys) {
        const value = examineeInfo[key]

        if (value) {
          examineeSegments.push(value)
        }
      }

      if (examineeSegments.length) {
        examinee = examineeSegments.join(', ')
      }

      exam.examinee = examinee
    },
    formatLog(log) {
      const [ time, event, type ] = log

      const formattedLog = {}

      formattedLog.utc = time
      formattedLog.time = this.$moment.utc(time).local().format('l h:mma')
      formattedLog.event = event
      formattedLog.warning = type === 'warning'

      return formattedLog
    },
    setLogData(exam, logs) {
      if (logs) {
        const formattedLogs = []

        for (const log of logs.reverse()) {
          const formattedLog = this.formatLog(log)

          formattedLogs.push(formattedLog)
        }

        exam.logs = formattedLogs
      }

      const [ log ] = exam.logs

      if (log) {
        exam.logLastEvent = log.time
        exam.logLastEventUTC = log.utc
      }

      exam.logEvents = 0
      exam.logWarnings = 0

      for (const log of exam.logs) {
        if (log.warning) {
          exam.logWarnings++
        } else {
          exam.logEvents++
        }
      }
    },
    setStatusData(exam, status) {
      exam.statusText = status.replace('_', ' ')
      exam.statusVariant = status.replace('_', '-')

      exam._cellVariants = { statusText: exam.statusVariant }
    },
    setProgressData(exam, progress) {
      const [complete, total] = progress

      exam.progressComplete = complete
      exam.progressTotal = total
      exam.progressText = `${complete} / ${total}`
    },
    setSettings (exam, settings) {
        if (!settings) return

        exam.timeLimit = settings.time_limit

        exam.markAndReview = !!settings.mark_and_review

        exam.preventSectionMarkAndReview = !!settings.prevent_section_mark_and_review

        const securityOptions = settings.security_options || {}

        exam.blockScreen = !!securityOptions.block_screen

        exam.allowSelection = !!securityOptions.allow_selection

        exam.copyPaste = !!securityOptions.copy_paste

        exam.proctorSettingOverrides = !!securityOptions.proctor_setting_overrides
    },
    onExamWatchFilterResults(data) {
      const { exams } = data

      for (const [key, values] of Object.entries(exams)) {
        const formattedExams = []

        const projectInfo = this.projectLookup[key]

        for (const value of values) {
          value.projectId = key

          const formattedExam = this.formatExam(value, projectInfo)

          formattedExams.push(formattedExam)
        }

        const existingExams = this.examLookup[key]

        if (existingExams) {
          for (const exam of formattedExams) {
            const existingIndex = existingExams.findIndex(existing => existing.id === exam.id)

            if (existingIndex !== -1) {
              existingExams.splice(existingIndex, 1)
            }
          }

          existingExams.push(...formattedExams)

          this.$set(this.examLookup, key, existingExams)

          continue
        }

        this.$set(this.examLookup, key, formattedExams)
      }
    },
    onExamWatchConnection(data) {
      const { exam_id, id } = data

      const { index } = getIndexAndExam(this.examLookup, exam_id, id)

      const projectInfo = this.projectLookup[exam_id]

      data.projectId = exam_id

      const exam = this.formatExam(data, projectInfo)

      this.emitDetails(exam)

      if (exam_id in this.examLookup) {
        if (index !== -1) {
          return this.$set(this.examLookup[exam_id], index, exam)
        }

        return this.examLookup[exam_id].push(exam)
      }

      this.$set(this.examLookup, exam_id, [exam])
    },
    onExamWatchDisconnection(data) {
      const { exam_id, id, log } = data

      const { index, exam } = getIndexAndExam(this.examLookup, exam_id, id)

      if (!exam) return

      exam.disconnected = true

      const formattedLog = this.formatLog(log)

      exam.logs.unshift(formattedLog)

      this.setLogData(exam)

      this.emitDetails(exam)

      this.$set(this.examLookup[exam_id], index, exam)
    },
    onExamWatchUpdate(data) {
      const { exam_id, id } = data

      const { index, exam } = getIndexAndExam(this.examLookup, exam_id, id)

      if (!exam) return

      if ('log' in data) {
        const formattedLog = this.formatLog(data.log)

        exam.logs.unshift(formattedLog)

        this.setLogData(exam)
      }

      if ('is_paused' in data) {
        exam.paused = data.is_paused
      }

      if ('status' in data) {
        this.setStatusData(exam, data.status)
      }

      if ('progress' in data) {
        this.setProgressData(exam, data.progress)
      }

      this.emitDetails(exam)

      this.$set(this.examLookup[exam_id], index, exam)
    },
    onExamWatchSpawn (data) {
      const { exam_id, id, spawned } = data

      const { index, exam } = getIndexAndExam(this.examLookup, exam_id, id)

      if (!exam) return

      exam.spawned = true

      exam.spawnedDeliveryId = spawned

      this.emitDetails(exam)

      this.$set(this.examLookup[exam_id], index, exam)
    },
    emitDetails(exam) {
      if (this.activeExamId === exam.id) {
        EVENT.$emit('exam-details-modal', exam)
      }
    },
    openFilterModal() {
      EVENT.$emit('open-filter-modal')
    },
    onFiltersChanged(newProjectIds, removedProjectIds, newTagFilters, removedTagFilters) {
      this.filtering = true

      this.search = ''

      if (removedProjectIds.length) {
        for (const id of removedProjectIds) {
          this.$delete(this.examLookup, id)
        }
      }

      if (Object.keys(newTagFilters).length) {
        for (const [key, value] of Object.entries(newTagFilters)) {
          const exams = this.examLookup[key]

          if (!exams) continue

          const removedIds = []

          const filteredExams = exams.filter(exam => {
            let passed = true

            for (const tag of value) {
              if (!exam.tags.includes(tag)) {
                passed = false

                removedIds.push(exam.id)

                break
              }
            }

            return passed
          })

          this.onRemoveSelected(removedIds)

          if (!filteredExams.length) {
            this.$delete(this.examLookup, key)

            continue
          }

          this.$set(this.examLookup, key, filteredExams)
        }
      }

      if (Object.keys(removedTagFilters).length) {
        for (const key in removedTagFilters) {
            const project = this.projectLookup[key]

            if (project.tags?.length) {
                this.$delete(this.examLookup, key)
            }
        }
      }

      const payload = {
        new_ids: newProjectIds,
        removed_ids: removedProjectIds,
        new_filters: newTagFilters,
        removed_filters: removedTagFilters
      }

      this.socket.emit('exam_watch_filters', payload, () => {
        this.filtering = false
      })
    },
    onAddSelected(selected) {
      for (const id of selected) {
        SELECTED_EXAMS.add(id)
      }

      this.hasSelected = true
    },
    onRemoveSelected(selected) {
      for (const id of selected) {
        SELECTED_EXAMS.delete(id)
      }

      if (!SELECTED_EXAMS.size) {
        this.hasSelected = false
      }
    },
    onActiveExam(id) {
      this.activeExamId = id
    },
    async onProctorEvent(event, examId) {
      let numberOfSelected

      if (examId) {
        numberOfSelected = 1
      } else {
        numberOfSelected = SELECTED_EXAMS.size
      }

      if (!numberOfSelected) return

      if (event === 'suspend') {
        let message

        if (numberOfSelected === 1) {
          message = 'Are you sure you want to suspend this exam?'
        } else {
          message = `Are you sure you want to suspend ${ numberOfSelected } exams?`
        }

        const options = {
          title: 'Confirm',
          okVariant: 'danger',
          okTitle: 'Suspend',
          cancelVariant: 'white',
          size: 'sm',
          noFade: true
        }

        const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

        if (!confirmed) return
      }

      const payload = {
        delivery_ids: examId ? [examId] : Array.from(SELECTED_EXAMS),
        event
      }

      this.socket.emit('exam_watch_proctor', payload)
    },
    onRemoveExam(projectId, examId) {
      this.onRemoveSelected([examId])

      const examLookup = this.examLookup[projectId]

      if (examLookup.length === 1) {
        this.$delete(this.examLookup, projectId)
        return
      }

      const index = examLookup.findIndex(exam => exam.id === examId)

      examLookup.splice(index, 1)
    },
    onSetSpawnedExam(projectId, examId) {
      const examLookup = this.examLookup[projectId]

      const exam = examLookup.find(exam => exam.id === examId)

      if (!exam) {
        const alertData = {
          variant: 'danger',
          message: 'Failed to find details.'
        }

        return EVENT.alert(alertData)
      }

      EVENT.$emit('exam-details-modal', exam, true)
    },
    onRemoveSelectedDisconnected() {
      for (const [projectId, exams] of Object.entries(this.examLookup)) {
        const removedIds = []

        const filteredExams = exams.filter(exam => {
          if (exam.disconnected && SELECTED_EXAMS.has(exam.id)) {
            removedIds.push(exam.id)

            return false
          }

          return true
        })

        if (filteredExams.length !== exams.length) {
          this.onRemoveSelected(removedIds)

          if (!filteredExams.length) {
            this.$delete(this.examLookup, projectId)
            continue
          }

          const [ ref ] = this.$refs[projectId]

          ref.updateSelected(removedIds)

          this.$set(this.examLookup, projectId, filteredExams)
        }
      }
    },
    onRemoveAllDisconnected() {
      for (const [projectId, exams] of Object.entries(this.examLookup)) {
        const filteredExams = exams.filter(exam => !exam.disconnected)

        if (filteredExams.length !== exams.length) {
          if (!filteredExams.length) {
            this.$delete(this.examLookup, projectId)
            continue
          }

          this.$set(this.examLookup, projectId, filteredExams)
        }
      }
    },
    clearSelected() {
      for (const key in this.$refs) {
        const [ref] = this.$refs[key]
        ref.clearSelected()
      }

      SELECTED_EXAMS.clear()

      this.hasSelected = false
    },
    onExamWatchInvalidToken() {
      this.loading = false
      this.invalidToken = true
    },
    onExamWatchSettings (data) {
      const { exam_id, id } = data

      const { index, exam } = getIndexAndExam(this.examLookup, exam_id, id)

      if (!exam) return

      this.setSettings(exam, data)

      EVENT.$emit('exam-details-modal', exam, true)

      this.$set(this.examLookup[exam_id], index, exam)
    }
  },
  computed: {
    activeExams() {
      return Boolean(Object.keys(this.examLookup).length)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
