<template>
  <div :class="styleString">
    <b-spinner class="Spinner Large"></b-spinner>
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
    props: {
      padding: {
        type: String,
        default: 'p-5'
      }
    },
    computed: {
      styleString() {
        return `d-flex justify-content-center align-items-center ${this.padding}`
      }
    }
  }
</script>
