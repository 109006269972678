<template>
  <div v-if="!org.billing_info.managed">
    <div v-if="!loading">
      <b-navbar v-if="!org.is_premium && isPremiumEnabled" sticky variant="light">
        <b-button-group>
          <b-button v-b-modal.upgrade-modal variant="info"
            >Upgrade to Premium</b-button
          >
        </b-button-group>
      </b-navbar>
      <b-container class="mt-3" fluid>
        <div>
          <p
            v-if="
              (org.is_premium || org.billing_info.pending_downgrade) &&
                !hasCreditCard
            "
          >
            WARNING! No payment method found. Add a credit card before the end
            of the month or your account will be suspended.
          </p>
        </div>
        <b-row>
          <b-col>
            <b-card no-body class="mb-3">
              <b-card-header class="p-2">
                Alerts
              </b-card-header>
              <b-card-body class="p-2">
                <b-card
                  v-for="(alert, index) in billingAlerts"
                  :key="index"
                  bg-variant="light"
                  no-body
                  class="mb-1"
                >
                  <b-card-body class="pt-0 pb-0">
                    <b-row class="p-2">
                      <b-col md="12" lg="6" class="mt-2 pl-0">
                        <label>Send an {{ alert.type }} to:</label><br />
                        <input
                          type="email"
                          class="w-100"
                          v-model="alert.email"
                        /><br />
                      </b-col>
                      <b-col md="12" lg="6" class="mt-2 pl-0">
                        <label>When your delivery count reaches:</label><br />
                        <input
                          type="number"
                          min="1"
                          step="1"
                          class="w-100"
                          v-model.number="alert.when"
                        />
                      </b-col>
                    </b-row>
                    <div class="text-right">
                      <b-button
                        @click="removeAlert(index)"
                        variant="link"
                        class="text-right"
                        size="sm"
                        >Remove Alert</b-button
                      >
                    </div>
                  </b-card-body>
                </b-card>
                <b-row class="mt-3">
                  <b-col>
                    <b-button
                      @click="addAlert"
                      variant="primary-light"
                      size="sm"
                      >Add Alert</b-button
                    >
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      @click="saveSettings"
                      variant="secondary"
                      size="sm"
                    >
                      <b-spinner
                        label="Small Spinner"
                        small
                        v-show="isSavingSettings"
                      ></b-spinner
                      >&nbsp;Save alerts
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <b-card no-body class="mb-3">
              <b-card-header class="p-2">
                Credit Card
              </b-card-header>
              <b-card-body class="p-2">
                <p v-if="!hasCreditCard" class="mb-1">
                  Add a credit card to make payments online for this
                  organization.
                </p>
                <b-button
                  @click="showAddCreditCardModal"
                  v-if="!hasCreditCard"
                  variant="primary-light"
                  size="sm"
                  >Add Credit Card</b-button
                >
                <b-row
                  v-for="card in paymentMethods.cards"
                  :key="card.payment_method_id"
                >
                  <b-col class="text-capitalize"
                    >{{ card.brand }} ending in: {{ card.last4 }}</b-col
                  >
                  <b-col class="text-right">
                    <b-button
                      @click="paymentMethodDelete(card.payment_method_id)"
                      variant="white"
                      size="sm"
                      >Remove this Card</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <b-card no-body class="mb-3">
              <b-card-header class="p-2">
                Invoices
              </b-card-header>
              <b-card-body class="p-2">
                <b-row
                  v-for="invoice in billingHistory"
                  :key="invoice.id"
                  class="mr-1 ml-1 pt-1 pb-1 border-bottom"
                >
                  <b-col class="pl-0">{{ getDate(invoice.end_date) }}</b-col>
                  <b-col class="text-right pr-0">
                    <b-button-group>
                      <b-button
                        @click="confirmPendingPayment"
                        v-if="hasPendingPayment(invoice.id)"
                        variant="secondary"
                        size="sm"
                        >Confirm pending payment</b-button
                      >
                      <b-button
                        @click="payNow(invoice.id)"
                        v-if="canPayNow(invoice)"
                        :disabled="!hasCreditCard"
                        variant="secondary"
                        size="sm"
                        >Pay now</b-button
                      >
                      <b-button
                        variant="white"
                        size="sm"
                        :href="getLink(invoice.id)"
                        target="blank"
                        >View</b-button
                      >
                    </b-button-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="5" lg="4" v-if="isPremiumEnabled">
            <p v-if="org.is_premium">Your account is premium.</p>
            <b-button
              v-b-modal.downgrade-modal
              v-if="org.is_premium && !org.billing_info.pending_downgrade"
              variant="white"
              size="sm"
              >Downgrade to Free</b-button
            >
            <b-button
              @click="cancelDowngrade"
              v-if="org.billing_info.pending_downgrade"
              variant="secondary"
              >Cancel downgrade</b-button
            >
            <b-card
              bg-variant="info"
              class="text-white mb-3"
              v-if="!org.is_premium"
            >
              <h6>Upgrade</h6>
              <p>A Premium Accounts includes:</p>
              <ul>
                <li>AIG (Automated Item Generation)</li>
                <li>Unlimited users for your projects</li>
                <li>Custom meta data fields</li>
                <li>Smart Items</li>
                <li>Organizational controls</li>
                <li>Integrations with 3rd party apps</li>
                <li>Item and form review tools</li>
                <li>Form splitter</li>
                <li>Bulk delivery discounts</li>
              </ul>
              <b-button variant="white" class="d-block w-100" size="sm"
                >Learn more</b-button
              >
              <b-button
                v-b-modal.upgrade-modal
                v-if="!org.is_premium"
                class="w-100 mt-2"
                variant="white"
                size="sm"
                >Upgrade to Premium</b-button
              >
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Spinner v-else />

    <b-modal
      @ok.prevent="handleAddPaymentMethod"
      ref="add-payment-method-modal"
      size="xl"
      title="Add Credit Card"
    >
      <b-form-group label="Email">
        <input type="email" v-model="billingDetails.email" class="w-100" />
      </b-form-group>
      <b-form-group label="Card">
        <div id="card-element"></div>
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel()" variant="white">Cancel</b-button>
          <b-button @click="ok()" variant="secondary">
            <b-spinner
              label="Small Spinner"
              small
              v-show="isSavingCard"
            ></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <b-modal
      @ok.prevent="upgrade"
      id="upgrade-modal"
      size="xl"
      title="Upgrade to premium"
    >
      <p>
        Clicking upgrade will immediately charge your saved credit card ${{
          pricing.prorated_base_amt
        }}. And setup recurring billing on the 1st of each month for ${{
          pricing.base_amt
        }}
        plus per delivery charges with the following tiers:
      </p>
      <div>
        <div v-for="(tier, index) in pricing.delivery_tiers" :key="index">
          <span v-if="tier[0]"
            >{{ tier[0] }} deliveries at ${{ tier[1] }} each</span
          >
          <span v-else>All additional deliveries at ${{ tier[1] }} each</span>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel()" variant="white">Stay with free</b-button>
          <b-button @click="ok()" variant="dark" :disabled="!hasCreditCard">
            <b-spinner
              label="Small Spinner"
              small
              v-show="isUpgrading"
            ></b-spinner
            >&nbsp;Authorize ${{ pricing.prorated_base_amt }} charge and upgrade
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <b-modal
      @ok.prevent="downgrade"
      id="downgrade-modal"
      size="xl"
      title="Downgrade to free"
    >
      <p>
        Downgrading will prevent the creation and editing of the following
        features:
      </p>
      <ul>
        <li>AIG</li>
        <li>Unlimited users</li>
        <li>Custom meta data</li>
        <li>Smart items</li>
        <li>Organizational controls</li>
        <li>Integrations</li>
        <li>Reviews</li>
        <li>Form splitter</li>
        <li>Bulk delivery discounts</li>
      </ul>
      <p>Downgrading will happen on the first of the next month.</p>
      <p>You will receive 1 final invoice for deliveries created this month.</p>
      <p>You will not lose data. Do you want to continue?</p>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button-group>
          <b-button @click="cancel()" variant="white"
            >No. Stay a premium account.</b-button
          >
          <b-button @click="ok()" variant="dark">
            <b-spinner
              label="Small Spinner"
              small
              v-show="isDowngrading"
            ></b-spinner
            >&nbsp;Yes. Downgrade to free.
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
  <div v-else>
    <b-container class="mt-3" fluid>
      <p>
        Your account is managed by Caveon. Contact your Caveon representative to
        change billing settings.
      </p>
    </b-container>
  </div>
</template>

<script>
  import { SEI_API_BASE, IS_UPGRADE_TO_PREMIUM_ENABLED } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { initializeStripe } from '../../utils/stripeWrapper'
  import Spinner from '../Spinner'

  async function getPublicKey() {
    try {
      const url = `${SEI_API_BASE}/stripe_public_key`
      const response = await HTTP.get(url)

      return response.data.public_key
    } catch (error) {
      return { error }
    }
  }

  async function getBillingHistory(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}`
      const response = await HTTP.get(url)

      return response.data.results
    } catch (error) {
      return { error }
    }
  }

  async function getPricing(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/pricing`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function payNow(orgId, billingHistoryId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/pay_now/${billingHistoryId}`
      const response = await HTTP.post(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function paymentMethodsIndex(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/payment_methods`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function paymentMethodsDelete(orgId, paymentMethodId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/payment_methods/${paymentMethodId}`

      await HTTP.delete(url)
    } catch (error) {
      return { error }
    }
  }

  async function paymentMethodsInitializeCreate(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/payment_methods_initialize_create`
      const response = await HTTP.post(url)
      const intent = response.data

      return intent
    } catch (error) {
      return { error }
    }
  }

  async function paymentMethodsConfirmCreate(orgId, data) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/payment_methods_confirm_create`
      const response = await HTTP.post(url, data)
      const savedCardData = response.data

      return savedCardData
    } catch (error) {
      return { error }
    }
  }

  async function upgrade(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/upgrade`
      const response = await HTTP.put(url)

      return response
    } catch (error) {
      return { error }
    }
  }

  async function downgrade(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/downgrade`
      const response = await HTTP.put(url)

      return response
    } catch (error) {
      return { error }
    }
  }

  async function cancelDowngrade(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/cancel_downgrade`
      const response = await HTTP.put(url)

      return response
    } catch (error) {
      return { error }
    }
  }

  async function saveOrg(org) {
    try {
      const result = await HTTP.put(
        `${SEI_API_BASE}/organizations/${org.id}`,
        org
      )

      return result.data
    } catch (error) {
      return { error }
    }
  }

  async function confirmPayment(orgId) {
    try {
      const url = `${SEI_API_BASE}/billing/organizations/${orgId}/confirm_payment`

      await HTTP.put(url)
    } catch (error) {
      return { error }
    }
  }

  let stripeWrapper = null

  export default {
    name: 'OrganizationBilling',
    async created() {
      const pageData = await Promise.all([
        getPublicKey(),
        paymentMethodsIndex(this.org.id),
        getBillingHistory(this.org.id),
        getPricing(this.org.id)
      ])

      const publicKey = pageData[0]
      this.paymentMethods = pageData[1]
      this.billingHistory = pageData[2]
      this.pricing = pageData[3]
      this.billingAlerts = this.org.billing_info.alerts || []

      stripeWrapper = initializeStripe(publicKey)

      this.loading = false
    },
    components: {
      Spinner
    },
    props: {
      org: {
        type: Object
      }
    },
    data() {
      return {
        paymentMethods: {},
        cardElement: null,
        billingDetails: {},
        billingHistory: [],
        billingAlerts: [],
        pricing: {},
        loading: true,
        isSavingSettings: false,
        isSavingCard: false,
        isUpgrading: false,
        isDowngrading: false,
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED
      }
    },
    methods: {
      showAddCreditCardModal() {
        this.$refs['add-payment-method-modal'].show()
        setTimeout(() => {
          this.cardElement = stripeWrapper.mountElements()
        })
      },
      hideAddCreditCardModal() {
        this.$refs['add-payment-method-modal'].hide()
      },
      async paymentMethodDelete(payment_method_id) {
        await paymentMethodsDelete(this.org.id, payment_method_id)
        this.paymentMethods.cards = this.paymentMethods.cards.filter(method => {
          return method.payment_method_id !== payment_method_id
        })
      },
      async handleAddPaymentMethod() {
        if (this.hasCreditCard) {
          this.hideAddCreditCardModal()
          return
        }

        this.isSavingCard = true

        const intent = await paymentMethodsInitializeCreate(this.org.id)
        const result = await stripeWrapper.confirmCardSetup(
          intent.client_secret,
          this.cardElement,
          this.billingDetails
        )

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: result.error.message
          })

          this.isSavingCard = false

          return
        }

        const saved = await stripeWrapper.retrieveSetupIntent(
          intent.client_secret
        )
        const card = await paymentMethodsConfirmCreate(this.org.id, {
          payment_method_id: saved.payment_method
        })

        this.isSavingCard = false

        this.hideAddCreditCardModal()
        this.paymentMethods.cards.push(card)
      },
      async upgrade() {
        this.isUpgrading = true

        const result = await upgrade(this.org.id)

        this.isUpgrading = false

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: result.error.message
          })
        } else {
          window.location.reload()
        }
      },
      async downgrade() {
        this.isDowngrading = true

        const result = await downgrade(this.org.id)

        this.isDowngrading = false

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: result.error.message
          })
        } else {
          window.location.reload()
        }
      },
      async cancelDowngrade() {
        const result = await cancelDowngrade(this.org.id)

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: result.error.message
          })
        } else {
          window.location.reload()
        }
      },
      async payNow(billingHistoryId) {
        const result = await payNow(this.org.id, billingHistoryId)

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Payment failed'
          })

          return EVENT.getOrg()
        }

        this.billingHistory = this.billingHistory.reduce(
          (billingHistory, record) => {
            if (record.id === billingHistoryId) {
              billingHistory.push(result)
            } else {
              billingHistory.push(record)
            }

            return billingHistory
          },
          []
        )
      },
      addAlert() {
        this.billingAlerts.push({
          type: 'email',
          email: '',
          when: 100
        })
      },
      removeAlert(index) {
        this.billingAlerts.splice(index, 1)
      },
      async saveSettings() {
        this.isSavingSettings = true
        const saveData = {
          id: this.org.id
        }

        this.billingAlerts = this.billingAlerts.filter(alert_ => {
          return alert_.email && alert_.when
        })

        saveData.billing_info = {
          alerts: this.billingAlerts
        }

        const response = await saveOrg(saveData)

        this.isSavingSettings = false

        if (response.error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save! Please try again.'
          })

          return
        }
      },
      getLink(billingHistoryId) {
        return `${SEI_API_BASE}/billing/organizations/${this.org.id}/download/${billingHistoryId}`
      },
      getDate(date) {
        return this.$moment
          .utc(date)
          .local()
          .format('ll')
      },
      async confirmPendingPayment() {
        const clientSecret = this.org.billing_info['payment_auth_required'][
          'client_secret'
        ]
        const paymentMethodId = this.org.billing_info['payment_auth_required'][
          'payment_method_id'
        ]
        const result = await stripeWrapper.confirmPayment(
          clientSecret,
          paymentMethodId
        )

        if (result.error) {
          EVENT.alert({
            variant: 'danger',
            message: result.error.message
          })

          return
        }

        await confirmPayment(this.org.id)

        window.location.reload()
      },
      hasPendingPayment(invoiceId) {
        return (
          this.org.billing_info.payment_auth_required &&
          this.org.billing_info.payment_auth_required['billing_history_id'] ===
            invoiceId
        )
      },
      canPayNow(invoice) {
        return (
          !invoice.paid_at &&
          !(
            this.org.billing_info.payment_auth_required &&
            this.org.billing_info.payment_auth_required[
              'billing_history_id'
            ] === invoice.id
          )
        )
      }
    },
    computed: {
      hasCreditCard() {
        return this.paymentMethods.cards && this.paymentMethods.cards.length > 0
      }
    },
    mounted() {
      let stripeScript = document.createElement('script')
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(stripeScript)
    }
  }
</script>

<style lang="scss" scoped>
  #card-element {
    border: 1px solid #ddd;
    padding: 0.3em;
  }
</style>
