<template>
  <div>
    <b-form-group
      :label="label"
      :label-for="id"
      label-cols-md="4"
      label-cols-lg="3"
    >
      <b-input-group prepend="https://">
        <template v-slot:append>
          <b-button @click="removeHook" size="sm" variant="white">
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </template>
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-input
          @blur="validateInput"
          :class="isInvalid"
          :id="id"
          type="url"
          v-model="hooks[name]"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'HookInput',
  props: {
    name: {
      type: String
    },
    hooks: {
      type: Object
    },
    invalidInputs: {
      type: Array
    }
  },
  methods: {
    removeHook() {
      this.$emit('remove-hook', this.name)
    },
    validateInput() {
      this.$emit('validate-input', this.name, this.hooks[this.name])
    }
  },
  computed: {
    id() {
      return this.name.split('_').join('-') + '-input'
    },
    label() {
      return this.name
        .replace('_widget', '')
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    isInvalid() {
      return this.invalidInputs.includes(this.name) ? 'invalid' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid {
  border-color: #d86a03;
  box-shadow: 0 0 2px #d86a03;
}
</style>
