<template>
  <div id="deliveryDetails">
    <div v-if="!loading">
      <div v-if="!error">
        <b-modal
          id="edit-delivery-settings-modal"
          size="xl"
          title="Edit Delivery Settings"
          lazy
          scrollable
        >
          <b-select
            :options="settingsSelectOptions"
            :value="selectedSettings"
            @change="onSettingsSelectChange"
            class="mb-3"
          ></b-select>

          <ConfigurationSelect
            :configurations="configurations"
            :selected-configuration="configuration.id"
            @configuration-selected="onConfigurationSelected"
            v-if="selectedSettings === 'configuration'"
            stack-name
          />

          <ConfigurationFields :configuration="configuration" :key="configuration.id" hide-name />

          <template v-slot:modal-footer="{ cancel }">
            <b-button-group>
              <b-button @click="cancel" variant="white">Cancel</b-button>
              <b-button
                variant="secondary"
                :disabled="savingDelivery"
                @click="saveDeliverySettings"
              >
                <b-spinner
                  label="Small Spinner"
                  small
                  v-show="savingDelivery"
                ></b-spinner
                >&nbsp;Save
              </b-button>
            </b-button-group>
          </template>
        </b-modal>

        <b-navbar sticky v-if="!isViewer" variant="light">
          <b-button-group>
            <b-button
              @click="getScoreReport()"
              v-if="notSuspendedAndComplete"
              variant="primary-light"
              >Score Report</b-button
            >
            <b-button
              @click="getFeedbackLink()"
              v-if="notSuspendedAndComplete"
              variant="white"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="loadingFeedback"
              ></b-spinner
              >&nbsp;Feedback Link
            </b-button>
            <b-button
              :disabled="shouldDisable(['create_deliveries', 'run_deliveries', 'proctor_deliveries'])"
              @click="getLaunchTokens()"
              v-if="notManualOrComplete"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="loadingTokens"
              ></b-spinner
              >&nbsp;Launch URLs
            </b-button>
            <b-button @click="createRetake()" v-if="isComplete" variant="white">
              <b-spinner
                label="Small Spinner"
                small
                v-show="loadingRetake"
              ></b-spinner
              >&nbsp;Create Retake
            </b-button>
            <b-button
              variant="white"
              @click="openEditDeliverySettingsModal"
              v-if="notManualOrComplete"
              :disabled="loadingConfigurations || shouldDisable(['edit_deliveries', 'edit_delivery_meta'])"
            >
              <b-spinner
                small
                v-if="loadingConfigurations"
              ></b-spinner>&nbsp;Edit Settings
            </b-button>
            <b-dropdown text="Export" v-if="notFresh && hasPermission" variant="white">
              <b-dropdown-item
                @click="routeToExport('caveon_delivery_summary')"
                title="Delivery summary"
                >Delivery summary</b-dropdown-item
              >
              <b-dropdown-item
                @click="routeToExport('caveon_item_comments')"
                title="Item comments"
                >Item comments</b-dropdown-item
              >
              <b-dropdown-item
                @click="routeToExport('caveon_responses')"
                title="Item responses"
                >Item responses</b-dropdown-item
              >
              <b-dropdown-item
                @click="routeToExport('caveon_scores')"
                title="Delivery scores"
                >Delivery scores</b-dropdown-item
              >
            </b-dropdown>
          </b-button-group>
          <b-navbar-nav class="d-none d-md-block ml-auto">
            <b-button-group>
              <b-button
                @click="navigateToDelivery(-1)"
                class="px-3"
                v-if="showPrev"
                variant="white"
              >
                <font-awesome-icon icon="caret-left"></font-awesome-icon>
              </b-button>
              <b-button
                @click="navigateToDelivery(1)"
                class="px-3 ml-1"
                v-if="showNext"
                variant="white"
              >
                <font-awesome-icon icon="caret-right"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </b-navbar-nav>
        </b-navbar>

        <b-modal
          hide-footer
          :hide-header-close="updatingResponse"
          :no-close-on-backdrop="updatingResponse"
          id="response-modal"
          size="lg"
        >
          <template v-slot:modal-header="{ close }">
            <ModalContentSwitcher
              :close="close"
              :current-index="currentIndex"
              :loading="loadingResponse"
              :max-index="maxIndexOfList"
              :title="modalTitle"
              @switch="changeResponse"
            />
          </template>
          <div v-if="!loadingResponse">
            <b-alert
              show
              v-if="needsManualScore && !hideScore"
              variant="warning"
              >Requires Manual Score</b-alert
            >
            <b-alert
              :show="itemFailed"
              variant="danger"
              >Item failed to render and was awarded full points.</b-alert
            >
            <ItemResponse :hideScore="hideScore" :item="record" />
            <b-form
              @submit.prevent="updateItemResponse()"
              id="item-response-form"
              v-if="!hideScore && !isViewer"
            >
            <b-row class="my-4">
              <b-col cols="4">
                <label>Manual Scoring Options</label>
                <b-select v-model="manualScoringMethod" :options="getManualScoringOptions()"></b-select>
              </b-col>
              <b-col cols="6">
                <div v-if="manualScoringMethod === 'byScore'">
                  <label>Score</label>
                  <b-form-input
                    max="1"
                    min="0"
                    step="0.01"
                    type="number"
                    v-model.number="formData.manual_score"
                  />
                </div>
                <div v-if="manualScoringMethod === 'byPoints'">
                  <label>Points</label>
                  <b-form-input
                    min="0"
                    step="0.01"
                    type="number"
                    v-model.number="formData.manual_points"
                  />
                </div>
              </b-col>
              <b-col cols="2" class="text-right mt-4" v-if="manualScoringMethod">
                <b-button
                  :disabled="updatingResponse"
                  form="item-response-form"
                  type="submit"
                  v-if="!hideScore"
                  variant="primary"
                >
                  <b-spinner
                    label="Small Spinner"
                    small
                    v-show="updatingResponse"
                  ></b-spinner
                  >&nbsp;Save
                </b-button>
              </b-col>
            </b-row>
            </b-form>
          </div>
          <Spinner v-else />
        </b-modal>

        <b-modal :title="record.name" id="agreements-modal" size="lg">
          <div>{{ record.content }}</div>
          <template v-slot:modal-footer="{ close }">
            <b-button @click="close()" variant="white">Close</b-button>
          </template>
        </b-modal>

        <b-modal hide-footer id="launch-modal" size="lg" title="Launch URLs">
          <label for="take">Take URL</label>
          <b-input-group class="mb-3">
            <template v-slot:append>
              <b-tooltip
                :show.sync="showCopyTooltip['take']"
                target="take-copy-btn"
                title="Copied!"
                triggers="manual"
              ></b-tooltip>
              <b-button
                @click="copyText('take')"
                id="take-copy-btn"
                variant="white"
                >Copy</b-button
              >
              <b-button @click="launch('launchLink')">Launch</b-button>
            </template>
            <b-form-input
              :value="launchLink"
              class="mr-1"
              name="take"
              readonly
              ref="take"
            ></b-form-input>
          </b-input-group>
          <label for="proctor">Proctor URL</label>
          <b-input-group class="mb-3">
            <b-form-input
              :value="proctorLink"
              class="mr-1"
              name="proctor"
              readonly
              ref="proctor"
            ></b-form-input>
            <b-input-group-append>
              <b-tooltip
                :show.sync="showCopyTooltip['proctor']"
                target="proctor-copy-btn"
                title="Copied!"
                triggers="manual"
              ></b-tooltip>
              <b-button
                @click="copyText('proctor')"
                id="proctor-copy-btn"
                variant="white"
                >Copy</b-button
              >
              <b-button @click="launch('proctorLink')">Launch</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-modal>

        <b-modal
          :no-close-on-backdrop="deletingDelivery"
          @hide="preventClose"
          @ok="deleteDelivery()"
          id="delete-modal"
          size="xl"
          title="Delete Delivery"
        >
          Are you sure you would like to delete this delivery?
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button-group>
              <b-button
                :disabled="deletingDelivery"
                @click="cancel()"
                variant="white"
                >Cancel</b-button
              >
              <b-button
                :disabled="deletingDelivery || shouldDisable('delete_deliveries')"
                @click="ok()"
                variant="warning"
              >
                <b-spinner
                  label="Small Spinner"
                  small
                  v-show="deletingDelivery"
                ></b-spinner
                >&nbsp;Delete this delivery
              </b-button>
            </b-button-group>
          </template>
        </b-modal>

        <b-modal
          hide-footer
          id="feedback-modal"
          size="xl"
          title="Feedback Link"
        >
          <label for="feedback"
            >This is a single use link to exam feedback. The feedback page
            contains solutions.</label
          >
          <b-input-group class="mb-3">
            <b-form-input
              :value="feedbackLink"
              name="feedback"
              readonly
              ref="feedback"
            ></b-form-input>
            <b-input-group-append>
              <b-tooltip
                :show.sync="showCopyTooltip['feedback']"
                target="feedback-copy-btn"
                title="Copied!"
                triggers="manual"
              ></b-tooltip>
              <b-button
                @click="copyText('feedback')"
                id="feedback-copy-btn"
                variant="white"
                >Copy</b-button
              >
              <b-button @click="launch('feedbackLink')" variant="primary-light"
                >Open</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button @click="cancel()" variant="white">Close</b-button>
          </template>
        </b-modal>

        <b-container class="mt-3" fluid>
          <b-alert :show="deliveryIncludesErrors" variant="danger">This delivery includes items that failed to render. Item responses highlighted in red were automatically given full points.</b-alert>
          <b-button @click="expand = !expand" size="sm" variant="link">{{
            expandOrCollapse
          }}</b-button>
          <b-card class="mb-1" no-body>
            <b-card-header class="p-1">
              <b-button
                :variant="deliveryVariant"
                block
                class="text-left"
                size="lg"
                v-b-toggle.examinee
              >
                <span>Examinee Information</span>
                <b-badge
                  :variant="deliveryVariant"
                  class="float-right mt-1 text-capitalize"
                  @click.stop
                  >
                  <b-select v-show="canEditStatus" v-model="pendingStatus" @change="updateStatus()" :disabled="isUpdatingStatus || shouldDisable(['edit_deliveries', 'edit_delivery_meta'])">
                    <b-select-option value="in_progress">in progress</b-select-option>
                    <b-select-option value="complete">complete</b-select-option>
                    <b-select-option value="abandoned">abandoned</b-select-option>
                  </b-select>


                  <b-modal ref="confirm-status-change" hide-footer size="sm" title="Confirm status change">
                    <div class="d-block text-center">
                      <p>Manually changing the status is irreversible.</p>
                      <p>Are you sure you want to continue?</p>
                    </div>
                    <br>
                    <b-button-group style="float: right;">
                      <b-button variant="warning" @click="cancelStatusChange">Cancel</b-button>
                      <b-button variant="success" @click="updateStatus(1)">Change status to {{ pendingStatusFriendly }}</b-button>
                    </b-button-group>
                  </b-modal>

                  <span v-show="!canEditStatus && !isUpdatingStatus">{{ badgeText }}</span>
                </b-badge
                >
              </b-button>
            </b-card-header>
            <b-collapse :visible="expand" id="examinee">
              <b-card-body>
                <b-form-group
                  label="Delivery ID"
                  label-cols="12"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.delivery_id"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-row
                  :key="key"
                  class="mb-3"
                  v-for="key in sortedExamineeInfo"
                >
                  <b-col cols="12">
                    <label>{{ key }}</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      disabled
                      v-bind:value="examineeInfoText(key)"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card class="mb-1" no-body>
            <b-card-header class="p-1">
              <b-button
                block
                class="text-left"
                size="lg"
                v-b-toggle.details
                variant="primary"
                >Delivery Details</b-button
              >
            </b-card-header>
            <b-collapse :visible="expand" id="details">
              <b-card-body>
                <b-form-group
                  label="Cutscore"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.cutscore"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Score"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input :value="details.score" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Score Scale"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.scoreScale"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Raw Points"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.rawPoints"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Duration"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.duration"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.type"
                    class="text-capitalize"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Form"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input :value="details.form" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tags"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input :value="details.tags" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Created"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input
                    :value="details.created"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Ended"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input :value="details.ended" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Languages Used"
                  label-cols-sm="3"
                  label-for="input-lg"
                >
                  <b-form-input :value="langsUsed" disabled></b-form-input>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>

          <DeliveryListing
            :display="Boolean(meta.length)"
            :data="meta"
            :expand="expand"
            :fields="metaFields"
            title="Meta"
          />

          <DeliveryListing
            v-for="(group, index) in groupedResponses"
            :key="index"
            :data="group.value.responses"
            :expand="expand"
            :fields="itemResponseFields"
            @open-modal="openModal"
            clickable
            modal="response-modal"
            :title="group.value.name"
          />

          <DeliveryListing
            :data="responses"
            :display="Boolean(notFresh && (responses.length || !groupedResponses.length))"
            :expand="expand"
            :fields="itemResponseFields"
            @open-modal="openModal"
            clickable
            modal="response-modal"
            title="Responses"
          />

          <DeliveryListing
            :data="agreements"
            :display="notFresh"
            :expand="expand"
            :fields="agreementFields"
            @open-modal="openModal"
            clickable
            modal="agreements-modal"
            title="Agreements"
            v-if="agreements.length"
          />

          <DeliveryListing
            :data="surveys"
            :display="notFresh"
            :expand="expand"
            :fields="surveyFields"
            @open-modal="openModal"
            clickable
            modal="surveys-modal"
            title="Surveys"
            v-if="surveys.length"
          />

          <DeliveryListing
            :data="proctorLogs"
            :display="notFresh"
            :expand="expand"
            :fields="proctorFields"
            title="Proctor Logs"
          />

          <b-card class="mb-1" no-body v-if="isAdmin">
            <b-card-header class="p-1">
              <b-button
                block
                class="text-left"
                size="lg"
                v-b-toggle.activitylog
                variant="primary"
                >Activity Log</b-button
              >
            </b-card-header>
            <b-collapse :visible="expand" id="activitylog">
              <b-card-body>
                <ResourceActivityLogs 
                  :resourceId="delivery.id"
                  :projectId="project.id"
                />
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card class="mb-1" no-body v-if="hasSharedOutcome">
            <b-card-header class="p-1">
              <b-button
                block
                class="text-left"
                size="lg"
                v-b-toggle.sharedOutcome
                variant="primary"
                >Related Deliveries</b-button
              >
            </b-card-header>
            <b-collapse :visible="expand" id="sharedOutcome">
              <b-card-body>
                <b-table
                  :fields="outcomeFields"
                  :items="delivery.outcome_ids"
                  hover
                  responsive
                  small
                  striped
                >
                  <template #cell(id)="row">
                      <b-link :to="outcomeLink(row.item.id)" class="stretched-link" />

                      {{ row.item.id }}
                  </template>
                </b-table>
              </b-card-body>
            </b-collapse>
          </b-card>

          <div
            :key="index"
            v-for="(integration, index) in project.integrations"
          >
            <b-card
              class="mb-1"
              no-body
              v-if="integration.app.app_settings.content.delivery_widget"
            >
              <b-card-header class="p-1">
                <b-button
                  block
                  class="text-left"
                  size="lg"
                  v-b-toggle="'integration' + index"
                  variant="primary"
                  >{{ integration.app.first_name }}</b-button
                >
              </b-card-header>
              <b-collapse :id="'integration' + index" :visible="expand">
                <b-card-body>
                  <b-embed
                    :src="integrationUrl(integration)"
                    type="iframe"
                    aspect="16by9"
                    class="iframe-box"
                  ></b-embed>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <b-button
            class="float-right mt-3 mb-5"
            v-b-modal.delete-modal
            variant="white"
            >Delete this delivery</b-button
          >
        </b-container>
      </div>
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load the requested delivery.
          <div>
            <b-button @click="getDelivery" class="mr-2" variant="danger"
              >Retry</b-button
            >
            <b-button @click="backToDeliveries" variant="danger"
              >Back to Deliveries</b-button
            >
          </div>
        </b-alert>
      </b-container>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import { DEFAULT_CONFIGURATION, SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy, iframeUrl, removeEmptyKeys } from '../../utils/misc'
  import { Language } from '../../utils/language'

  import ConfigurationFields from './ConfigurationFields'
  import ConfigurationSelect from './ConfigurationSelect'
  import DeliveryListing from './DeliveryListing'
  import ItemResponse from '../item/ItemResponse'
  import ModalContentSwitcher from './ModalContentSwitcher'
  import ResourceActivityLogs from './ResourceActivityLogs.vue'
  import Spinner from '../Spinner'

  const MAX_ATTEMPTS = 25
  const POLLING_INTERVAL_MS = 1500
  const UUID_LENGTH = 36
  const NON_COMPLETE = ['in_progress', 'fresh']

  async function getDeliveryData(projectId, deliveryId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}?include=examinee,examinee_preview,score_token,scoreScale,form,accommodations,logs,item_responses,change_logs,exam,agreement_responses,type,comments,outcome_ids`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function createJob(projectId, deliveryId, responseId, data) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}/item_responses/${responseId}`
      const response = await HTTP.put(url, data)
      const jobId = response.data.job_id
      if (jobId) {
        return { data: jobId }
      } else {
        throw new Error('Failed to create job.')
      }
    } catch (error) {
      return { error }
    }
  }

  async function getJobData(jobId) {
    try {
      const url = `${SEI_API_BASE}/jobs/${jobId}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function updateStatus(projectId, deliveryId, status) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}`
      const response = await HTTP.put(url, { status })
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getConfigurations(projectId, page) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/configurations?page=${page}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveDelivery(projectId, deliveryId, configuration) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/deliveries/${deliveryId}?only=settings`
      const payload = { settings: configuration.settings }
      removeEmptyKeys(payload.settings)
      const response = await HTTP.put(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getLiveForm(projectId, formId, versionNumber) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/forms/${formId}?version_number=${versionNumber}&include=version`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Delivery',
    components: {
      ConfigurationFields,
      ConfigurationSelect,
      DeliveryListing,
      ItemResponse,
      ModalContentSwitcher,
      Spinner,
      ResourceActivityLogs
    },
    created() {
      if (SESSION.isAdmin()) {
        this.isAdmin = true
      }
      this.checkForNextDelivery()
      this.getDelivery()
    },
    props: {
      isViewer: {
        type: Boolean
      },
      project: {
        type: Object
      }
    },
    data() {
      return {
        isAdmin: false,
        deliveryUrl: `${SEI_API_BASE}/exams/${this.$route.params.projectId}/deliveries/${this.$route.params.deliveryId}?include=examinee,examinee_preview,score_token,scoreScale,form,accommodations,logs,item_responses,change_logs,exam,agreement_responses,type,comments`,
        delivery: {},
        details: {},
        record: {},
        formData: {},
        configuration: {},
        configurations: null,
        launchLink: '',
        proctorLink: '',
        feedbackLink: '',
        selectedSettings: '',
        complete: false,
        responses: [],
        groupedResponses: [],
        allResponses: [],
        meta: [],
        expand: true,
        settingsSelectOptions: [
          { text: 'Edit current settings on delivery', value: 'delivery' },
          { text: 'Copy settings from a configuration', value: 'configuration' }
        ],
        itemResponseFields: [
          { key: 'item_idx', label: 'Order' },
          { key: 'item_version_name', label: 'Item Name' },
          { key: 'score', label: 'Orig Score' },
          { key: 'manual_score' },
          { key: 'points_earned', label: 'Pts Earned' },
          { key: 'is_excluded', label: 'Excluded' },
          { key: 'duration' },
          { key: 'comments' }
        ],
        metaFields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value '}
        ],
        manualScoringMethod: null,
        surveys: [],
        surveyFields: [
          { key: 'item_version_name', label: 'Item Name' },
          { key: 'duration' }
        ],
        agreements: [],
        agreementFields: [
          { key: 'timestamp' },
          { key: 'name' },
          { key: 'response' }
        ],
        proctorLogs: [],
        proctorFields: [
          { key: 'category' },
          { key: 'event' },
          { key: 'timestamp' }
        ],
        outcomeFields: [
          { key: 'id', label: 'Delivery ID' }
        ],
        changeLogs: [],
        changeFields: [
          { key: 'event' },
          { key: 'item_name' },
          { key: 'user' },
          { key: 'moment', label: 'Timestamp' }
        ],
        showCopyTooltip: {
          take: false,
          proctor: false,
          feedback: false
        },
        error: false,
        loading: true,
        loadingConfigurations: false,
        loadingTokens: false,
        loadingFeedback: false,
        loadingRetake: false,
        loadingResponse: false,
        updatingResponse: false,
        deletingDelivery: false,
        needsManualScore: false,
        hideScore: false,
        showPrev: true,
        showNext: true,
        interval: null,
        deliveryIncludesErrors: false,
        deliveryHasSavedSettings: false,
        savingDelivery: false,
        attempts: 0,
        currentIndex: 0,
        isUpdatingStatus: false,
        pendingStatus: null,
        liveForm: null,
        customLangs: [],
        langsUsed: '-'
      }
    },
    methods: {
      async getDelivery() {
        try {
          this.loading = true
          const { data, error } = await getDeliveryData(
            this.$route.params.projectId,
            this.$route.params.deliveryId
          )
          if (error) {
            throw new Error('Failed to get delivery.')
          }
          this.delivery = data
          this.deliveryHasSavedSettings = Boolean(Object.keys(this.delivery.settings).length)
          this.pendingStatus = this.delivery.status
          this.createDetails()
          this.createResponses()
          this.createLogs()
          this.createOutcome()
          this.createMeta()
          await this.createAgreements()
          await this.createUsedLanguages()
          this.liveForm = this.delivery.form ? (await getLiveForm(this.$route.params.projectId, this.delivery.form.id, this.delivery.form.live_version_number)).data : null
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      },
      async openEditDeliverySettingsModal() {
        if (!this.configurations) {
          this.loadingConfigurations = true

          let moreConfigurations = true
          let page = 1

          const configurations = []

          while (moreConfigurations) {
            const { data, error } = await getConfigurations(this.project.id, page)

            if (error) {
              this.loadingConfigurations = false
              return EVENT.alert({
                variant: 'danger',
                message: 'Failed to load configurations.'
              })
            }

            configurations.push(...data.results)
            moreConfigurations = data.has_next
            page++
          }

          this.loadingConfigurations = false

          this.configurations = configurations
        }

        this.selectedSettings = 'delivery'

        this.copyConfiguration()

        this.$bvModal.show('edit-delivery-settings-modal')
      },
      copyConfiguration() {
        if (this.selectedSettings === 'delivery') {
          let settings

          if (this.deliveryHasSavedSettings) {
            settings = deepCopy(this.delivery.settings)
          } else if (this.liveForm && this.liveForm.version?.configuration) {
            settings = this.liveForm.version.configuration.settings
          } else {
            settings = deepCopy(DEFAULT_CONFIGURATION.settings)
          }

          this.configuration = { settings }
        } else {
          const defaultConfiguration = this.configurations.find(config => config.is_default)
          this.configuration = deepCopy(defaultConfiguration)
        }
      },
      onSettingsSelectChange(value) {
        this.selectedSettings = value
        this.copyConfiguration()
      },
      onConfigurationSelected(configurationId) {
        const configuration = this.configurations.find(config => config.id === configurationId)
        this.configuration = deepCopy(configuration)
      },
      async saveDeliverySettings() {
        this.savingDelivery = true

        const { data, error } = await saveDelivery(this.project.id, this.delivery.id, this.configuration)

        this.savingDelivery = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save delivery settings.'
          })
        }

        this.delivery.settings = data.settings

        this.deliveryHasSavedSettings = true

        this.$bvModal.hide('edit-delivery-settings-modal')
      },
      checkForNextDelivery() {
        const lookup = SESSION.deliveryLookup

        if (!lookup) {
          this.showPrev = false
          return (this.showNext = false)
        }

        const index = lookup.findIndex(
          delivery => delivery.id === this.$route.params.deliveryId
        )

        if (index === lookup.length - 1) {
          this.showNext = false
        }

        if (index === 0) {
          this.showPrev = false
        }
      },
      navigateToDelivery(by) {
        const currentIndex = SESSION.deliveryLookup.findIndex(
          delivery => delivery.id === this.delivery.id
        )
        const nextDelivery = SESSION.deliveryLookup[currentIndex + by]
        this.$router.push({
          name: 'projectDelivery',
          params: { deliveryId: nextDelivery.id }
        })
      },
      async refreshDeliveryResponses() {
        const { data, error } = await getDeliveryData(
          this.$route.params.projectId,
          this.$route.params.deliveryId
        )
        if (error) {
          throw new Error('Failed to get delivery.')
        }
        this.delivery = data
        this.createResponses()
        this.createDetails()
        this.createLogs()
        this.createOutcome()
        this.createMeta()
      },
      createDetails() {
        if (this.delivery.status !== 'fresh') {
          this.details = {
            cutscore: this.cutscore(),
            delivery_id: this.delivery.id,
            score: this.score(),
            tags: this.tags(),
            scoreScale: this.scoreScale(),
            form: this.form(),
            rawPoints: this.rawPoints(),
            created: this.createdAt(),
            duration: `${this.delivery.used_seconds}s`,
            ended: this.endedAt()
          }
        } else {
          this.details = {
            cutscore: '-',
            delivery_id: this.delivery.id,
            score: '-',
            type: '-',
            tags: this.tags(),
            scoreScale: this.scoreScale(),
            form: this.form(),
            rawPoints: '-',
            created: this.createdAt(),
            duration: '-',
            ended: '-'
          }
        }
        this.complete = !NON_COMPLETE.includes(this.delivery.status)
      },
      createResponses() {
        const allResponses = []
        const surveyResponses = []
        const ungroupedResponses = []
        const groupedResponses = new Map()

        const sortedResponses = this.delivery.item_responses.sort((first, second) => first.item_idx - second.item_idx)
        for (const response of sortedResponses) {
          const processed = this.processResponse(response)

          if (processed.type === 'survey') {
            surveyResponses.push(processed)
            continue
          }

          processed._index = allResponses.length
          allResponses.push(processed)

          if (processed.group_id) {
            const group = groupedResponses.get(processed.group_id)
            if (group) {
              group.responses.push(processed)
            } else {
              groupedResponses.set(processed.group_id, { name: processed.group_name, responses: [ processed ] })
            }
            continue
          }

          ungroupedResponses.push(processed)
        }

        const arrayOfGroups = Array.from(groupedResponses, ([ key, value ]) => ({ key, value }))

        this.allResponses = allResponses
        this.surveys = surveyResponses
        this.groupedResponses = arrayOfGroups
        this.responses = ungroupedResponses
      },
      createLogs() {
        const proctorLogs = []
        for (const log of this.delivery.logs) {
          const category = log[2]
          const event = log[1]
          const timestamp = this.$moment
            .utc(log[0])
            .local()
            .format('llll')
          proctorLogs.push({ category, event, timestamp })
        }

        const changeLogs = []
        for (const log of this.delivery.change_logs) {
          log.moment = this.$moment
            .utc(log.timestamp)
            .local()
            .format('llll')
          changeLogs.push(log)
        }

        this.proctorLogs = proctorLogs
        this.changeLogs = changeLogs
      },
      createOutcome () {
        const formattedIds = []

        for (const outcomeId of this.delivery.outcome_ids) {
          formattedIds.push({ id: outcomeId })
        }

        this.delivery.outcome_ids = formattedIds
      },
      createMeta () {
        const meta = []

        const deliveryMeta = this.delivery.meta || {}

        for (const [key, value] of Object.entries(deliveryMeta)) {
          meta.push({ key, value })
        }

        this.meta = meta
      },
      async createAgreements() {
        const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/agreements`
        const agreementPromises = []
        for (const agreement of this.delivery.agreement_responses) {
          const agreementPromise = HTTP.get(`${url}/${agreement.agreement_id}`)
          agreementPromises.push(agreementPromise)
        }
        try {
          const resultArray = await Promise.all(agreementPromises)
          this.agreements = resultArray.map((result, index) => {
            const { response, timestamp } = this.delivery.agreement_responses[index]
            result.data.response = response
            result.data.timestamp = this.$moment
              .utc(timestamp)
              .local()
              .format('llll')
            return result.data
          })
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to load agreements.'
          })
        }
      },
      async createUsedLanguages () {
        const defaultLangSet = new Set([])

        const customLangSet = new Set([])

        if (!this.delivery.item_responses?.length) return

        for (const response of this.delivery.item_responses) {
          const langId = response?.other?.language_id

          if (!langId) continue

          if (langId.length === UUID_LENGTH) {
            customLangSet.add(langId)
          } else {
            defaultLangSet.add(langId)
          }
        }

        const defaultLangArray = Array.from(defaultLangSet)

        const customLangArray = []

        if (customLangSet.size) {
          const customLangs = await Language.FetchAll(this.project.id)

          if (customLangs.results) {
            this.customLangs = customLangs.results

            for (const customLangId of Array.from(customLangSet)) {
              const customLang = this.customLangs.find(lang => lang.id === customLangId)

              if (customLang) {
                customLangArray.push(customLang.name)
              }
            }
          }
        }

        const langsUsed = [ ...defaultLangArray, ...customLangArray ].sort((a, b) => a.localeCompare(b))

        if (langsUsed.length) {
          this.langsUsed = langsUsed.join(', ')
        }
      },
      attachCommentsToResponse(response) {
        return this.delivery.comments.filter(
          comment => comment.item_id === response.item_id
        )
      },
      openModal(modal, record, index) {
        if (modal === 'agreements-modal') {
          this.record = record
          this.$bvModal.show(modal)
        } else {
          if (modal === 'surveys-modal') {
            this.hideScore = true
          } else {
            this.hideScore = false
          }
          this.getResponseDetails(record, index)
        }
      },
      changeResponse(index) {
        let record
        if (this.hideScore) {
          record = this.surveys[index]
        } else {
          record = this.allResponses[index]
        }
        this.getResponseDetails(record, index)
      },
      async getResponseDetails(record, index) {
        try {
          this.loadingResponse = true
          this.currentIndex = index
          this.manualScoringMethod = null
          this.$bvModal.show('response-modal')
          const url = `${SEI_API_BASE}/exams/${this.project.id}/deliveries/${this.delivery.id}/item_responses/${record.id}?include=for_exam`
          const response = await HTTP.get(url)

          let langName = ''

          if (response.data?.other?.language_id) {
            const langId = response.data.other.language_id

            if (langId.length === UUID_LENGTH) {
              const customLang = this.customLangs.find(lang => lang.id === langId)

              if (customLang) {
                langName = customLang.name
              }
            } else {
              langName = langId
            }
          }

          response.data.langName = langName

          this.record = response.data
          this.needsManualScore = Boolean(
            this.record.requires_manual_score &&
              this.record.manual_score !== 0 &&
              !this.record.manual_score &&
              !this.record.is_excluded
          )
          this.formData = {
            manual_score: response.data.manual_score,
            is_excluded: response.data.is_excluded
          }
        } catch (error) {
          EVENT.$emit(
            'global.alert',
            'danger',
            'Failed to get response details.'
          )
        } finally {
          this.loadingResponse = false
        }
      },
      getManualScoringOptions() {
        const options = [
          { value: null, text: 'Select an option' },
          { value: 'byScore', text: 'Manually enter a score' },
          { value: 'byPoints', text: 'Manually enter points earned' },
        ]

        if (this.record.is_excluded || this.record.manual_score !== null) {
          options.push({ value: 'reset', text: 'Revert to orginal score' })
        }
        if (!this.record.is_excluded) {
          options.push({ value: 'exclude', text: 'Exclude response from delivery score' })
        }
        return options
      },
      async updateItemResponse() {
        this.updatingResponse = true
        let payload
        if (this.manualScoringMethod === 'exclude') {
          payload = {
            is_excluded: true
          }
        } else if (this.manualScoringMethod === 'byPoints') {
          payload = {
            manual_points: this.formData.manual_points
          }
        } else if (this.manualScoringMethod === 'byScore') {
          payload = {
            manual_score: this.formData.manual_score
          }
        } else if (this.manualScoringMethod === 'reset') {
          payload = {
            is_excluded: false,
            manual_score: null
          }
        }
        const { data, error } = await createJob(
          this.project.id,
          this.delivery.id,
          this.record.id,
          payload
        )
        if (error) {
          this.updatingResponse = false
          EVENT.$emit('global.alert', 'danger', 'Failed to update response.')
        } else {
          this.poll(data)
        }
      },
      async poll(jobId) {
        this.interval = setInterval(async () => {
          try {
            if (this.attempts > MAX_ATTEMPTS) {
              throw new Error('Max attempts reached.')
            }
            const { data, error } = await getJobData(jobId)
            if (error) {
              throw new Error(error)
            }
            if (data.status === 'failed') {
              throw new Error(data.status)
            }
            if (data.status === 'finished') {
              clearInterval(this.interval)
              await this.refreshDeliveryResponses()
              this.hideResponseModal()
            }
          } catch (error) {
            clearInterval(this.interval)
            this.hideResponseModal()
            EVENT.$emit('global.alert', 'danger', 'Failed to update response.')
          }
        }, POLLING_INTERVAL_MS)
      },
      hideResponseModal() {
        this.attempts = 0
        this.updatingResponse = false
        this.$bvModal.hide('response-modal')
      },
      async getLaunchTokens() {
        try {
          this.loadingTokens = true
          const launchUrl = `${SEI_API_BASE}/exams/${this.project.id}/deliveries/${this.delivery.id}/get_launch_token`
          const proctorUrl = `${SEI_API_BASE}/exams/${this.project.id}/deliveries/${this.delivery.id}/get_proctor_token`
          const responseArray = await Promise.all([
            HTTP.get(launchUrl),
            HTTP.get(proctorUrl)
          ])
          this.launchLink = `${location.origin}/take?launch_token=${responseArray[0].data.launch_token}`
          this.proctorLink = `${location.origin}/proctor/${responseArray[1].data.proctor_token}`
          this.loadingTokens = false
          this.$bvModal.show('launch-modal')
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to get launch tokens.'
          })
        }
      },
      async createRetake() {
        const { examinee, accommodations } = this.delivery
        const payload = {
          examinee_id: examinee.id,
          examinee_info: examinee.info,
          accommodations: accommodations
        }
        const url = `${SEI_API_BASE}/exams/${this.$route.params.projectId}/deliveries`
        try {
          this.loadingRetake = true
          const response = await HTTP.post(url, payload)
          this.$router.push({
            name: 'projectDelivery',
            params: { deliveryId: response.data.delivery_id }
          })
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message:
              'This delivery may contain outdated examinee info. Creating a retake is unavailable.'
          })
        } finally {
          this.loadingRetake = false
        }
      },
      async getFeedbackLink() {
        try {
          this.loadingFeedback = true
          const feedbackUrl = `${SEI_API_BASE}/exams/${this.project.id}/deliveries/${this.delivery.id}/get_feedback_token`
          const response = await HTTP.get(feedbackUrl)
          this.feedbackLink = `${location.origin}/feedback/${response.data.feedback_token}`
          this.$bvModal.show('feedback-modal')
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to get feedback link.'
          })
        } finally {
          this.loadingFeedback = false
        }
      },
      getScoreReport() {
        const url = `${location.origin}/score/${this.delivery.score_token}`
        open(url, '_blank')
      },
      processResponse(response) {
        const responseDuration = Math.round(response.seconds)
        response.duration = `${responseDuration}s`
        response.comments = this.attachCommentsToResponse(response)
        if (response.is_excluded) {
          response.points_earned = 0
        } else {
          if (typeof response.manual_score === 'number') {
            response.points_earned = response.manual_score * response.points
          } else {
            response.points_earned = response.score * response.points
          }
        }
        if (response.other.failed_to_render) {
          response._rowVariant = 'danger'
          this.deliveryIncludesErrors = true
        }
        response.manual_score = this.score(response, true)
        return response
      },
      cutscore() {
        if (this.delivery.passed) {
          const cutscore = this.delivery.cutscore
          if (cutscore) {
            return cutscore.name
          }
        }
        return 'No cutscore achieved'
      },
      score(response, isManual) {
        let data
        if (response) {
          data = response
        } else {
          data = this.delivery
        }
        let score
        if (isManual) {
          score = data.manual_score
        } else {
          score = data.score
        }
        if (score === 0 || score) {
          return score.toFixed(2)
        } else {
          return '-'
        }
      },
      tags() {
        const tags = this.delivery.tags
        if (tags && tags.length) {
          return tags.join(', ')
        }
        return 'No tags'
      },
      scoreScale() {
        const scaleLower = this.delivery.score_scale[0]
        const scaleUpper = this.delivery.score_scale[1]
        if (typeof scaleLower === 'number' && typeof scaleUpper === 'number') {
          return `${scaleLower} - ${scaleUpper}`
        } else {
          return 'No scale set'
        }
      },
      form() {
        const form = this.delivery.form
        if (form) {
          return form.name
        } else {
          return 'None'
        }
      },
      rawPoints() {
        const earned = this.delivery.points_earned || 0
        const available = this.delivery.points_available || 0
        return `${earned} / ${available}`
      },
      createdAt() {
        return this.$moment
          .utc(this.delivery.created_at)
          .local()
          .format('llll')
      },
      endedAt() {
        return this.$moment
          .utc(this.delivery.modified_at)
          .local()
          .format('llll')
      },
      copyText(type) {
        this.$refs[type].select()
        document.execCommand('copy')
        this.showCopyTooltip[type] = true
        setTimeout(() => (this.showCopyTooltip[type] = false), 1200)
      },
      launch(type) {
        open(this[type], '_blank')
      },
      async deleteDelivery() {
        try {
          this.deletingDelivery = true
          const url = `${this.deliveryUrl}/${this.delivery.id}`
          await HTTP.delete(url)
          this.backToDeliveries()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete delivery.'
          })
        } finally {
          this.deletingDelivery = false
        }
      },
      preventClose(event) {
        if (this.deletingDelivery) {
          event.preventDefault()
        }
      },
      backToDeliveries() {
        this.$router.push({ name: 'projectDeliveries' })
      },
      examineeInfoText(key) {
        return this.delivery.examinee.info[key] || 'No information provided'
      },
      routeToExport(type) {
        this.$router.push({
          name: 'projectExport',
          query: { type, delivery_id: this.delivery.id }
        })
      },
      integrationUrl(integration) {
        let replaceWith = '.'
        if (SESSION.env) replaceWith = `.${SESSION.env}.`
        return iframeUrl(
          integration.app.app_settings.content.delivery_widget.replace('.env.', replaceWith),
          integration.shared_token,
          integration.exam_id,
          null,
          this.delivery.id
        )
      },
      cancelStatusChange() {
        this.pendingStatus = this.delivery.status
        this.$refs['confirm-status-change'].hide()
      },
      async updateStatus(confirmed) {
        if (!confirmed) {
          this.$refs['confirm-status-change'].show()
          return
        }

        this.$refs['confirm-status-change'].hide()
        this.isUpdatingStatus = true
        const updated = await updateStatus(this.project.id, this.delivery.id, this.pendingStatus)

        this.isUpdatingStatus = false

        if (updated.error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to update the status.'
          })
          return
        }

        this.delivery.status = this.pendingStatus
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      outcomeLink (deliveryId) {
        return {
          name: 'projectDelivery',
          params: { deliveryId }
        }
      }
    },
    computed: {
      hasSharedOutcome () {
        return Boolean(this.delivery.outcome_ids?.length)
      },
      badgeText() {
        return this.delivery.status.replace('_', ' ')
      },
      expandOrCollapse() {
        if (this.expand) return 'Collapse All'
        return 'Expand All'
      },
      deliveryVariant() {
        return this.delivery.status.replace('_', '-')
      },
      sortedExamineeInfo() {
        const order = []
        for (const preview of this.delivery.examinee_preview) {
          const [key] = preview
          order.push(key)
        }
        for (const key in this.delivery.examinee.info) {
          if (!order.includes(key)) {
            order.push(key)
          }
        }
        return order
      },
      isComplete() {
        return this.delivery.status === 'complete'
      },
      notFresh() {
        return this.delivery.status !== 'fresh'
      },
      notSuspendedAndComplete() {
        return this.delivery.status !== 'suspended' && this.complete
      },
      notManualOrComplete() {
        return (
          this.delivery.status !== 'complete' &&
          this.delivery.status !== 'manual_scoring'
        )
      },
      modalTitle() {
        if (this.hideScore) {
          return 'Survey Details'
        }
        return 'Response Details'
      },
      maxIndexOfList() {
        if (this.hideScore) {
          return this.surveys.length - 1
        }
        return this.allResponses.length - 1
      },
      itemFailed() {
        return this.record.other ? this.record.other.failed_to_render : false
      },
      canEditStatus() {
        return this.delivery.status === 'in_progress'
      },
      hasPermission() {
        return SESSION.isAdmin()
      },
      pendingStatusFriendly() {
        return this.pendingStatus.replace('_', ' ')
      }
    }
  }
</script>

<style lang="scss">
  #deliveryDetails {
    #searchFilters .badge {
      min-width: 22px;
    }
    .iframe-box {
      min-height: 80vh;
    }
    .embed-responsive-16by9 {
      min-height: 80vh;
      height: 100%;
    }
    #sharedOutcome tr td {
      transform: rotate(0);
    }
  } 
</style>
