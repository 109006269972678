<template>
  <div class="p-1 mb-1 border">
    <b-card-header class="border-0 p-0">
      <b-button
        block
        class="text-left px-3 py-2"
        v-b-toggle="dropdownId"
        variant="primary"
      >
        <div @click.stop class="d-inline">
          <b-form-checkbox
            @change="checkAll"
            :checked="allChecked"
            class="d-inline position-relative"
            style="bottom: -1px"
          />
          {{ dropdownHeader }}
        </div>
      </b-button>
    </b-card-header>
    <b-collapse :id="dropdownId" v-if="users.length" visible>
      <b-button v-b-modal.add-user-modal variant="white" class="m-3 ml-0"
        >Add Users</b-button
      >
      <b-dropdown v-show="checked.length || isBulkAction" variant="white">
        <template v-slot:button-content>
          <b-spinner
            label="Small Spinner"
            small
            v-show="isBulkAction"
          ></b-spinner
          >&nbsp;Bulk Actions
        </template>
        <b-dropdown-item @click="removeBulk">Remove Users</b-dropdown-item>
      </b-dropdown>
      <div class="p-2">
        <b-table :fields="fields" :items="users" hover responsive small striped>
          <template v-slot:table-colgroup="scope">
            <col
              :key="field.key"
              :style="{ width: field.key === 'checked' ? '40px' : 'auto' }"
              v-for="field in scope.fields"
            />
          </template>
          <template v-slot:cell(checked)="scope">
            <b-form-checkbox :value="scope.item.caveon_id" v-model="checked" />
          </template>
          <template v-slot:cell(remove)="scope">
            <b-button
              @click="removeUser(scope.item.caveon_id)"
              class="float-right px-1 py-0 rounded"
              variant="white"
            >
              <font-awesome-icon icon="trash-alt"></font-awesome-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'UsersListing',
  props: {
    users: {
      type: Array
    },
    fields: {
      type: Array
    },
    isBulkAction: {
      type: Boolean
    }
  },
  data() {
    return {
      dropdownId: '',
      checked: [],
      remove: ''
    }
  },
  created() {
    this.dropdownId = 'dropdown-' + this._uid
  },
  methods: {
    removeBulk() {
      this.$emit('remove-user', this.checked, true)
    },
    removeUser(userId) {
      this.$emit('remove-user', [userId], false)
    },
    clearChecked() {
      this.checked = []
    },
    checkAll(checked) {
      if (checked) {
        this.checked = this.users.map((user) => user.caveon_id)
      } else {
        this.checked = []
      }
    }
  },
  computed: {
    dropdownHeader() {
      return `Users (${this.users.length})`
    },
    allChecked() {
      return this.checked.length === this.users.length
    }
  }
}
</script>

<style lang="scss" scoped></style>
