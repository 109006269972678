<template>
  <b-row class="mt-4">
    <b-col sm="3">
      <b-button v-b-toggle="collapseId" variant="white" size="sm" class="mb-2">Manage project access</b-button>
    </b-col>
    <b-col>
      <b-collapse :id="collapseId">
        <b-input-group>
          <b-select :options="availableProjects" v-model="selectedProjectId" size="sm" class="mr-1 mb-2"></b-select>
          <b-button :disabled="!selectedProjectId" @click="launchProjectRoles" variant="primary-light" size="sm" class="mb-2">View roles &amp; users</b-button>
        </b-input-group>
      </b-collapse>
    </b-col> 
  </b-row>
</template>

<script>
export default {
  name: 'ProjectAccessEntry',
  props: {
    org: {
      type: Object
    },
    projects: {
      type: Array,
      defaultValue: []
    },
    onUpdate: {
      type: Function,
      default: () => { /* NOOP */ }
    },
    group: {
      type: Object,
      defaultValue: {}
    }
  },
  data() {
    return {
      selectedProjectId: null
    }
  },
  methods: {
    launchProjectRoles() {
      const projectId = this.selectedProjectId
      const url = `${window.location.origin}/projects/${projectId}/access`

      window.onfocus = this.handleUpdate.bind(this)
      window.open(url, 'blank')
    },
    handleUpdate() {
      try {
        this.onUpdate()
      } finally {
        window.onfocus = null
        this.selectedProjectId = null
      }
    }
  },
  computed: {
    availableProjects() {
      return [{text:'Select a project', value: null}].concat(this.projects.map(project => {
        return {
          text: project.name,
          value: project.id
        }
      }))
    },
    availableRoles() {
      return this.roles.map(role => {
        return {
          text: role.title,
          value: role.type
        }
      })
    },
    collapseId() {
      return `project-permissions-${this.group.id}`
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
