<script>
  import { Line, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    name: 'AnalyticsLineChart',
    extends: Line,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object
      },
      chartScaleOptions: {
        type: Object
      },
      height: {
        type: Number,
        default: 200
      }
    },
    mounted() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        ...this.chartScaleOptions
      })
    }
  }
</script>
