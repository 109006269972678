<template>
  <b-row class="border mt-1">
    <b-col>
      <small v-if="!responses.length">Response</small>
      <b-table
        :items="responses"
        hover
        responsive
        small
        striped
        v-if="responses.length"
      >
        <template v-slot:cell(option)="row">
          <div v-markdown="row.item.option"></div>
        </template>
        <template v-slot:cell(correct)="row">
          <font-awesome-icon
            icon="check"
            v-if="row.item.correct"
          ></font-awesome-icon>
        </template>
        <template v-slot:cell(extra)="row">
          <font-awesome-icon
            icon="check"
            v-if="row.item.extra"
          ></font-awesome-icon>
        </template>
      </b-table>
      <h6 v-else>No Response</h6>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'DOMC',
    props: {
      item: {
        type: Object
      }
    },
    created() {
      this.createResponses()
    },
    data() {
      return {
        responses: []
      }
    },
    methods: {
      createResponses() {
        const final = this.item.final ? this.item.final : []
        const options = ['No', 'Yes']
        for (const [index, isFinal] of final.entries()) {
          const optionIndex = this.item.presented[index]
          const response = {
            option: this.item.for_exam.options[index],
            response: options[isFinal],
            duration: this.duration(this.item.durations[index]),
            correct: this.item.option_scores[optionIndex],
            extra: false
          }
          this.responses.push(response)
        }
        if (this.item.other.extra_option) {
          const optionIndex = this.item.presented[this.item.final.length]
          const response = {
            option: this.item.for_exam.options[this.item.final.length],
            response: options[this.item.other.extra_option_response],
            duration: this.duration(this.item.other.extra_option_duration),
            correct: this.item.option_scores[optionIndex],
            extra: true
          }
          this.responses.push(response)
        }
      },
      duration(durations) {
        const start = this.$moment.utc(durations[0]).unix()
        const end = this.$moment.utc(durations[1]).unix()
        const duration = end - start
        return `${duration}s`
      }
    }
  }
</script>

<style lang="scss" scoped></style>
