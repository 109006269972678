<template>
  <b-container class="mt-3" fluid v-if="!loading">
   
  <TranslationModalWrapper
    :modalId="modalId"
    :closeModal="closeModal"
    :hasPrevious="hasPrevious"
    :hasNext="hasNext"
    :mode="mode"
    @changes="handleModalChanges"
    @previous="previous"
    @next="next"
  >
    <template v-slot:extraButtons>
        <TranslateManyContentButton v-if="!isPreview" :project="project" :language="language" class="ml-3" :object="active" :setFieldsLoading="setFieldsLoading" />
    </template>

    <template v-slot:name>
      <b-badge
        class="text-capitalize statuses"
        v-b-tooltip="{ trigger: 'hover', interactive: false }"
        v-if="active.isComplete"
        >C</b-badge
      >
      <b-badge
        class="text-capitalize statuses"
        variant="warning"
        v-b-tooltip="{ trigger: 'hover', interactive: false }"
        v-if="!active.isComplete"
        >I</b-badge
      >
      {{ active.name }}
    </template>

    <template v-slot:content>
      <div v-if="!fieldsLoading">
        <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch" v-if="!hasSegments">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Stem</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.content.stem" v-model="active.form.content.stem" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled style="flex:1;" :value="active.content.stem" class="m-1"></b-textarea>
          <b-textarea style="flex:1;" v-model="active.form.content.stem" class="m-1" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.content.stem || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch" v-if="active.feedback.stem">
          <b-col cols="12" md="6" class="p-1">
            <p class="mb-2">Stem feedback</p>
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.feedback.stem" v-model="active.form.feedback.stem" />
                <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
              </b-col>
            </b-row>
          </b-col>
          <b-textarea disabled style="flex:1;" :value="active.feedback.stem" class="m-1"></b-textarea>
          <b-textarea style="flex:1;" v-model="active.form.feedback.stem" class="m-1" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
          <div v-if="isPreview" v-markdown="active.form.feedback.stem || ''" class="preview-box" v-direction="language.properties.direction"></div>
        </b-row>

        <div v-if="hasOptions">
          <div v-for="(option, optionIndex) in active.content.options" :key="optionIndex">
            <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch">
              <b-col cols="12" md="6" class="p-1">
                <p class="mb-2">Option</p>
              </b-col>
              <b-col cols="12" md="6" class="p-1">
                <b-row align-v="stretch">
                  <b-col>
                    <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="option" @input="optionChanged($event, optionIndex)" />
                    <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                  </b-col>
                </b-row>
              </b-col>
              <b-textarea disabled style="flex:1;" :value="option" class="m-1"></b-textarea>
              <b-textarea style="flex:1;" class="m-1" @change="optionChanged($event, optionIndex)" :value="active.form.content.options[optionIndex]" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
              <div v-if="isPreview" v-markdown="active.form.content.options[optionIndex] || ''" class="preview-box" v-direction="language.properties.direction"></div>
            </b-row>

            <b-row v-if="hasOptionFeedback" class="mx-3 flex-wrap flex-column flex-md-row flex-stretch">
              <b-col cols="12" md="6" class="p-1">
                <p class="mb-2">Option feedback</p>
              </b-col>
              <b-col cols="12" md="6" class="p-1">
                <b-row align-v="stretch">
                  <b-col>
                    <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="active.feedback[optionIndex]" @input="feedbackChanged($event, optionIndex)" />
                    <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                  </b-col>
                </b-row>
              </b-col>
              <b-textarea disabled style="flex:1;" :value="active.feedback[optionIndex]" class="m-1"></b-textarea>
              <b-textarea style="flex:1;" class="m-1" @change="feedbackChanged($event, optionIndex)" :value="active.form.feedback[optionIndex]" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
              <div v-if="isPreview" v-markdown="active.form.feedback[optionIndex] || ''" class="preview-box" v-direction="language.properties.direction"></div>
            </b-row>
          </div>
        </div>

        <div v-if="hasMatches">
          <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch" v-for="(option, optionIndex) in active.content.matches" :key="optionIndex">
            <b-col cols="12" md="6" class="p-1">
              <p class="mb-2">Match</p>
            </b-col>
            <b-col cols="12" md="6" class="p-1">
              <b-row align-v="stretch" class="flex-stretch">
                <b-col>
                  <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="option"  @input="matchChanged($event, optionIndex)" />
                  <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                </b-col>
              </b-row>
            </b-col>
            <b-textarea disabled style="flex:1;" :value="option" class="m-1"></b-textarea>
            <b-textarea style="flex:1;" class="m-1" @change="matchChanged($event, optionIndex)" :value="active.form.content.matches[optionIndex]" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
            <div v-if="isPreview" v-markdown="active.form.content.matches[optionIndex] || ''" class="preview-box" v-direction="language.properties.direction"></div>
          </b-row>
        </div>

        <div v-if="hasSegments">
          <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch" v-for="(segment, segmentIndex) in active.content.segments" :key="segmentIndex">
            <b-col cols="12" md="6" class="p-1">
              <p class="mb-2">Segment</p>
            </b-col>
            <b-col cols="12" md="6" class="p-1">
              <b-row align-v="stretch">
                <b-col v-if="segment.type !== 'short_answer'">
                  <TranslateContentButton v-if="!isPreview && segment.type !== 'select'" :project="project" :language="language" :content="segment.text" v-model="active.form.content.segments[segmentIndex].text" />
                  <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <div v-if="segment.type === 'content'">
                <b-row flex-wrap flex-column flex-md-row flex-stretch>
                  <b-textarea disabled style="flex:1;" :value="segment.text" class="m-1"></b-textarea>
                  <b-textarea style="flex:1;" class="m-1" v-model="active.form.content.segments[segmentIndex].text" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
                  <div v-if="isPreview" v-markdown="active.form.content.segments[segmentIndex].text || ''" class="preview-box" v-direction="language.properties.direction"></div>
                </b-row>
              </div>
              <div v-if="segment.type === 'select'">
                <div v-for="(segmentOption, segmentOptionIndex) in segment.options" :key="segmentOptionIndex">
                  <b-row flex-wrap flex-column flex-md-row flex-stretch>
                    <b-col cols="12" md="6" class="p-1 m-0">&nbsp;</b-col>
                    <b-col cols="12" md="6" class="p-1 m-0"><TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="segmentOption.text" @input="segmentOptionChanged($event, segmentIndex, segmentOptionIndex)" /></b-col>
                  </b-row>
                  <b-row cols="12" md="6" class="flex-stretch flex-row" flex-wrap flex-column flex-md-row flex-stretch>
                    <b-textarea disabled style="flex:1;" :value="segmentOption.text" class="m-1"></b-textarea>
                    <b-textarea style="flex:1;" class="m-1" @change="segmentOptionChanged($event, segmentIndex, segmentOptionIndex)" :value="active.form.content.segments[segmentIndex].options[segmentOptionIndex].text" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
                    <div v-if="isPreview" v-markdown="active.form.content.segments[segmentIndex].options[segmentOptionIndex].text || ''" class="preview-box" v-direction="language.properties.direction"></div>
                  </b-row>
                </div>
              </div>
              <div v-if="segment.type === 'short_answer'">
                Add short answer translations using the item editor options list
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="hasLabels">
          <div v-for="(label, labelIndex) in active.content.labels" :key="labelIndex">
            <b-row class="mx-3 flex-wrap flex-column flex-md-row flex-stretch">
              <b-col cols="12" md="6" class="p-1">
                <p class="mb-2">{{labelType(labelIndex)}} Label</p>
              </b-col>
              <b-col cols="12" md="6" class="p-1">
                <b-row align-v="stretch">
                  <b-col>
                    <TranslateContentButton v-if="!isPreview" :project="project" :language="language" :content="label"  @input="labelChanged($event, labelIndex)" />
                    <div v-if="isPreview" class="mt-2"><small>Preview:</small></div>
                  </b-col>
                </b-row>
              </b-col>
              <b-textarea disabled style="flex:1;" :value="label" class="m-1"></b-textarea>
              <b-textarea style="flex:1;" class="m-1" @change="labelChanged($event, labelIndex)" :value="active.form.content.labels[labelIndex]" v-if="isEdit" v-direction="language.properties.direction"></b-textarea>
              <div v-if="isPreview" v-markdown="active.form.content.labels[labelIndex] || ''" class="preview-box" v-direction="language.properties.direction"></div>
            </b-row>
          </div>
        </div>
      </div>
      <div v-else>
        <Spinner />
      </div>
    </template>
    <template v-slot:saveBtns>
      <b-button-group>
        <b-button @click="save(0)" variant="warning" class="mr-1" size="sm">Save as Incomplete</b-button>
        <b-button @click="save(1)" size="sm">Save as Complete</b-button>
      </b-button-group>
    </template>
  </TranslationModalWrapper>

  <TranslationsStickyTools>
    <template v-slot:columns>
      <b-col cols="12" sm="6" md="3" class="mb-1">
        <b-select
          size="sm"
          name="live-latest"
          :options="LIVE_OR_LATEST_OPTIONS"
          v-model="liveOrLatest"
          @change="changeLiveOrLatest()"
          v-if="showFilters"
        ></b-select>
      </b-col>
      <b-col cols="12" sm="6" md="3" class="mb-1">
        <b-form @submit="search()" v-if="showFilters">
          <b-input-group>
            <b-input size="sm" type="text" placeholder="Search" v-model="searchText"></b-input>
            <b-button size="sm" type="submit" variant="white">Search</b-button>
          </b-input-group>
        </b-form>
      </b-col>
      <b-col cols="6" sm="6" md="3" class="mb-1">
        <b-select size="sm" :value="helper.status" @change="filter($event)" v-if="showFilters">
          <option value="">All</option>
          <option value="not_complete">Incomplete</option>
          <option value="complete">Complete</option>
        </b-select>
      </b-col>

      <b-col cols="6" md="3" class="mb-1">
        <b-pagination
          size="sm"
          v-if="showPagination"
          :per-page="perPage"
          :total-rows="total"
          :value="page"
          class="float-right mb-0"
          @change="changePage"
        ></b-pagination>
      </b-col>
    </template>
  </TranslationsStickyTools>

  <div v-if="items.length" >
    <b-button v-for="item in items" :key="item.id" class="w-100 border-bottom d-block pb-2" style="text-align:unset;" @click="setActive(item.id)" variant="text" size="sm">
      <b-row>
        <b-col cols="2" md="1">
          <b-badge
            class="text-capitalize statuses"
            variant="secondary"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="item.isComplete"
            >C</b-badge
          >
          <b-badge
            class="text-capitalize statuses"
            variant="warning"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="!item.isComplete"
            >I</b-badge
          >
        </b-col>
        <b-col cols="5">
          <label class="d-block">{{ item.name }}</label>
          <small><div>{{ preview(item.content.stem) }}</div></small>
        </b-col>
        <b-col cols="6">
          <label class="d-block">Translation</label>
          <small><div v-text="preview(item.form.content.stem, '-')" v-direction="language.properties.direction"></div></small>
        </b-col>
        <b-col cols="5" v-if="showLanguageName">
          <div>{{ language.name }}</div>
        </b-col>
      </b-row>
    </b-button>
  </div>
  <div v-else>Nothing to translate</div>
  </b-container>
  <Spinner v-else />
</template>
<script>
import Spinner from '../Spinner'
import { TranslationsHelper, TranslationType, TranslationModes } from '../../utils/translation'
import TranslationModalWrapper from './TranslationModalWrapper.vue'
import TranslationsStickyTools from './TranslationsStickyTools.vue'
import TranslateContentButton from './TranslateContentButton.vue'
import { EVENT } from '../../utils/event-bus'
import TranslateManyContentButton from './TranslateManyContentButton.vue'


export default {
  name: 'LanguagesTranslationsItems',
  components: {
    Spinner,
    TranslationModalWrapper,
    TranslationsStickyTools,
    TranslateContentButton,
    TranslateManyContentButton
  },
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    },
    itemId: {
      type: String
    },
    versionNumber: {
      type: Number
    },
    uiOptions: {
      type: Object,
      default() {
        return {
          showFilters: true,
          showLanguageName: false
        }
      }
    }
  },
  data() {
    return {
      loading: true,
      helper: null,
      translations: [],
      liveOrLatest: 'live',
      LIVE_OR_LATEST_OPTIONS: [
        {text: 'Live item versions', value: 'live'},
        {text: 'Latest item versions', value: 'latest'}
      ],
      items: [],
      mode: TranslationModes.PREVIEW,
      activeIndex: -1,
      active: null,
      empty: {content: {}, form: {content: {labels: []}}, feedback: {}},
      perPage: 15,
      page: 1,
      total: 0,
      languageOptions: [],
      searchText: '',
      fieldsLoading: false
    }
  },
  async created() {
    if (this.$route.query.page)
      this.page = this.$route.query.page
    
    if (this.$route.query.per_page)
      this.perPage = this.$route.query.per_page
      // TODO store in url

    this.active = {...this.empty}
    this.helper = TranslationsHelper.Factory(this.project.id, this.language.id, TranslationType.item)

    this.languageOptions = [{value: this.language.id, text: this.language.name}]
    this.searchText = this.helper.searchText

    await this.fetchPage()
  },
  methods: {
    async fetchPage() {
      this.loading = true
      this.helper.setPagination({page: this.page, perPage: this.perPage})

      await this.fetchContent()
      await this.fetchTranslations()

      this.loading = false
    },
    async fetchContent() {
      await this.helper.fetchContent({ isLive: this.liveOrLatest === 'live', itemId: this.itemId, versionNumber: this.versionNumber })

      this.items = this.helper.things
      this.total = this.helper.total
    },
    async fetchTranslations() {
      await this.helper.fetchTranslations()

      this.items.forEach(item => {
        const {id} = item

        if (this.helper.translations[id]) {
          item.applyTranslation(this.helper.translations[id])
        }
      })
    },
    async save(isComplete) {
      this.fieldsLoading = true
      const { error } = await this.active.save(isComplete)

      if (error) {
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save! Please try again.'
        })
      }

      this.fieldsLoading = false

      EVENT.alert({
        variant: 'success',
        message: 'Saved'
      })
    },
    preview(content, defaultContent) {
      return content
        ? content.length > 99 ? content.slice(0, 97) + '...' : content
        : defaultContent || ''
    },
    setActive(id) {
      let activeIndex = this.items.map(item => item.id).indexOf(id)

      if (activeIndex < 0 || activeIndex >= this.items.length) return {}

      const activeItem = this.items[activeIndex]

      this.activeIndex = activeIndex
      this.active = activeItem
      this.$bvModal.show(this.modalId)
    },
    closeModal() {
      this.$bvModal.hide(this.modalId)
      this.active = this.empty
    },
    previous() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
      }

      this.active = this.items[this.activeIndex]
    },
    next() {
      if (this.activeIndex < this.items.length - 1) {
        this.activeIndex = this.activeIndex + 1
      }

      this.active = this.items[this.activeIndex]
    },
    optionChanged(value, index) {
      this.active.form.content.options.splice(index, 1, value)
    },
    matchChanged(value, index) {
      this.active.form.content.matches.splice(index, 1, value)
    },
    labelChanged(value, index) {
      this.active.form.content.labels.splice(index, 1, value)
    },
    feedbackChanged(value, key) {
      this.$set(this.active.form.feedback, key, value)
    },
    segmentOptionChanged(value, segmentIndex, segmentOptionIndex) {
      const segment = this.active.form.content.segments[segmentIndex]
      const option = segment.options[segmentOptionIndex]

      option.text = value

      segment.options.splice(segmentOptionIndex, 1, option)
      this.active.form.content.segments.spluce(segmentIndex, 1, segment)
    },
    async changePage(page) {
      this.page = page
      await this.fetchPage()
    },
    handleModalChanges(changes) {
      Object.keys(changes).forEach(key => {
        this[key] = changes[key]
      })
    },
    async search() {
      this.helper.setFilters({searchText: this.searchText})

      await this.fetchPage()
    },
    async filter(status) {
      this.helper.setFilters({status})

      await this.fetchPage()
    },
    changeLiveOrLatest() {
      this.fetchPage()
    },
    labelType(index) {
      if (index === 0) return 'Lower'
      return 'Upper'
    },
    setFieldsLoading(value) {
      this.fieldsLoading = value
    }
  },
  computed: {
    isEdit() {
      return this.mode === 'edit'
    },
    hasPrevious() {
      return this.activeIndex > 0
    },
    hasNext() {
      return this.activeIndex < this.items.length - 1
    },
    hasOptions() {
      return ['multiple_choice', 'domc', 'matching', 'build_list', 'likert'].includes(this.active.type)
    },
    hasMatches() {
      return ['matching'].includes(this.active.type)
    },
    hasSegments() {
      return this.active.type === 'fill_in_the_blanks'
    },
    showPagination() {
      return this.total > this.perPage
    },
    modalId() {
      return `edit-modal-${this.language.id}`
    },
    isPreview() {
      return this.mode === 'preview'
    },
    showFilters() {
      return this.uiOptions.showFilters
    },
    showLanguageName() {
      return this.uiOptions.showLanguageName
    },
    hasOptionFeedback() {
      return Object.keys(this.active.feedback).length > 1
    },
    hasLabels() {
      return ['scale'].includes(this.active.type)
    }
  }
}
</script>
<style lang="scss" scoped>
  .sticky-tools {
    position:sticky; 
    top: 40px; 
    z-index: 1; 
    padding: 8px 0; 
    background-color: #fff; 
    border-bottom: 1px dashed #eee;
    margin-bottom: 30px;
  }
  .preview-box {
    flex: 1; 
    border-left: 1px solid #ccc;
    margin: 5px 10px;
    padding: 5px 10px;
    max-width: 48%;
  }
  @media only screen and (max-width: 768px) {
    .preview-box {
      max-width: 100%;
    }
  }
</style>
