import { DEFAULT_ITEM_SETTINGS } from './constants'


export const DefaultItem = function DefaultItem(type) {
    DefaultItem.BuildItemDefaults = function BuildItemDefaults(defaultItems) {
      const settings = {}

      for (const item of defaultItems) {
        settings[item.type] = item.settings
      }

      return { settings }
    }

    this.type = type
    this.friendlyName = type.split('_').reduce((words, word) => {
      words.push(word[0].toUpperCase() + word.slice(1))

      return words
    }, []).join(' ')

    this.settings = DEFAULT_ITEM_SETTINGS[type]
    this.content = {}

    if (type === 'scale')  {
      this.content = {
        ...this.content,
        labels: []
      }
    }

    this.updateSettings = (settings) => {
      this.settings = {
        ...this.settings,
        ...settings
      }
    }
}
