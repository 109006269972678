<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <h5 class="m-0">{{ title }}</h5>
    <div>
      <b-button
        :disabled="cantGoBack"
        @click="change(-1)"
        size="sm"
        variant="primary"
      >
        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
      </b-button>
      <b-button
        :disabled="cantGoForward"
        @click="change(1)"
        class="mx-1"
        size="sm"
        variant="primary"
      >
        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
      </b-button>
      <b-button-close @click="close"></b-button-close>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalContentSwitcher',
    props: {
      close: {
        type: Function
      },
      currentIndex: {
        type: Number
      },
      loading: {
        type: Boolean
      },
      maxIndex: {
        type: Number
      },
      title: {
        type: String
      }
    },
    methods: {
      change(direction) {
        this.$emit('switch', this.currentIndex + direction)
      }
    },
    computed: {
      cantGoBack() {
        return this.loading || this.currentIndex === 0
      },
      cantGoForward() {
        return this.loading || this.currentIndex === this.maxIndex
      }
    }
  }
</script>

<style lang="scss" scoped></style>
