<template>
  <div>
    <div v-if="!loading">
      <b-navbar sticky variant="light">
        <b-button-group v-if="!search">
          <b-button
            :disabled="error"
            v-b-modal.add-group-modal
            v-if="hasPermission"
            variant="primary-light"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isAddingGroup"
            ></b-spinner
            >&nbsp;New Group
          </b-button>
          <b-button
            :disabled="error"
            v-b-modal.add-user-modal
            v-if="hasPermission"
            variant="primary-light"
            >Add Users</b-button
          >
          <b-button :disabled="error" @click="showSearch" variant="white">
            <font-awesome-icon
              class="d-md-none"
              icon="search"
            ></font-awesome-icon>
            <span class="d-none d-md-block">Search</span>
          </b-button>
          <!-- <b-button :disabled="error" variant="white">Views</b-button> -->
        </b-button-group>
        <div class="w-100 d-flex" v-else>
          <b-form-input
            @input="searchGroups"
            autofocus
            placeholder="Search Groups"
            style="maxWidth: 600px"
            v-model="searchText"
          />
          <b-button @click="hideSearch" class="ml-2" variant="white"
            >Cancel</b-button
          >
        </div>
      </b-navbar>

      <!-- Add Group Modal -->
      <b-modal id="add-group-modal" size="xl" title="Create New Group">
        <b-form
          @submit.prevent="onSubmit($event, 'createGroup')"
          id="add-group-form"
          novalidate
        >
          <b-form-group
            label="Group Name *"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="groupName"
          >
          <b-input-group>
            <b-form-input
              id="groupName"
              required
              type="text"
              v-model="groupName"
            />
          </b-input-group>
          </b-form-group>
          <b-form-group
           label="Permissions"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="permissions"
          >
          <div>
            <b-form-checkbox
              :key="index"
              :value="permission.value"
              v-for="(permission, index) in groupPermissions"
              v-model="addingGroup"
              class="d-block"
              >{{ permission.name }}</b-form-checkbox
            >
          </div>
          </b-form-group>
          
        </b-form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button-group>
            <b-button @click="cancel()" variant="white">Cancel</b-button>
            <b-button
              :disabled="disableAddGroupBtn"
              form="add-group-form"
              type="submit"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isAddingGroup"
              ></b-spinner
              >&nbsp;Create
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
      <b-modal
        @hidden="exitAddUserModal"
        id="add-user-modal"
        size="xl"
        title="Add Users"
      >
        <b-form
          @submit.prevent="onSubmit($event, 'addUsers')"
          id="add-users-form"
          novalidate
        >
          <b-form-group>
            <label for="addEmail">Add Users / Send Invitations *</label>
            <RolesInput
              :options="userOptions"
              placeholder="Email Addresses"
              @roles-changed="usersChanged"
            />
          </b-form-group>
          <b-form-group
            label="Groups *"
            label-cols-sm="4"
            label-for="groupSelect"
          >
              <v-select
                :data-value="selectedGroups"
                :options="groupOptions"
                :reduce="option => option.id"
                label="name"
                multiple
                placeholder="Select Groups"
                required
                v-model="selectedGroups"
              ></v-select>
          </b-form-group>
        </b-form>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button-group>
            <b-button @click="cancel()" variant="white">Cancel</b-button>
            <b-button
              :disabled="disableAddUsersBtn"
              form="add-users-form"
              type="submit"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isAddingUser"
              ></b-spinner
              >&nbsp;Save
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
      <b-modal id="edit-group-modal" size="xl" title="Edit Group">
        <b-alert :show="!hasPermission && isPremium" variant="warning"
          >You are not authorized to manage groups for this
          organization.</b-alert
        >
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          v-if="!isPremium && isPremiumEnabled"
          variant="info"
        >
          Free accounts cannot edit groups
          <b-button @click="upgrade" size="sm" variant="info"
            >Upgrade to Premium</b-button
          >
        </b-alert>
        <b-form
          @submit.prevent="onSubmit($event, 'updateGroup')"
          id="update-group-form"
          novalidate
        >
          <b-form-group
        label="Group Name *"
            label-cols-sm="4"
            label-for="groupName"
          >
            <b-input-group>
              <b-form-input
                :readonly="!hasPermission"
                id="groupName"
                required
                type="text"
                v-model="editingGroup.name"
              />
            </b-input-group>
            </b-form-group>

            <b-form-group
              label="Permissions"
                label-cols-sm="4"
                label-for="permissions"
              >
              <div>
                <b-form-checkbox
                  :disabled="!hasPermission"
                  :key="index"
                  :value="permission.value"
                  v-for="(permission, index) in groupPermissions"
                  v-model="editingGroup.permissions"
                  >{{ permission.name }}</b-form-checkbox
                >
              </div>
            </b-form-group>
        </b-form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button
            :disabled="isDeletingGroup"
            @click="deleteGroup()"
            class="mr-auto danger"
            size="sm"
            v-if="hasPermission && editingGroup.name != 'Administrators'"
            variant="link"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isDeletingGroup"
            ></b-spinner
            >&nbsp;Delete Group
          </b-button>
          <b-button-group>
            <b-button @click="cancel()" variant="white">Cancel</b-button>
            <b-button
              :disabled="disableUpdateGroupBtn"
              form="update-group-form"
              type="submit"
              v-if="hasPermission"
              variant="secondary"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="isUpdatingGroup"
              ></b-spinner
              >&nbsp;Save
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
      <b-container class="mt-3" fluid>
        <div v-if="error">
          <b-alert
            class="d-flex justify-content-between align-items-center"
            show
            variant="danger"
          >
            Groups for this organization failed to load.
            <b-button @click="reload" variant="danger">Reload</b-button>
          </b-alert>
        </div>
        <div v-else>
          <b-alert
            class="d-flex justify-content-between align-items-center"
            show
            v-if="!hasPermission && isPremiumEnabled"
            variant="info"
          >
            Free accounts cannot edit groups
            <b-button @click="upgrade" size="sm" variant="info"
              >Upgrade to Premium</b-button
            >
          </b-alert>
          <b-button-group class="mb-2">
            <b-button @click="expand = !expand" size="sm" variant="link">{{
              expandOrCollapse
            }}</b-button>
            <b-dropdown
              @hide="showDropdown = false"
              ref="dropdown"
              size="sm"
              v-if="hasPermission && hasChecked"
              variant="white"
            >
              <template v-slot:button-content>
                <b-spinner
                  label="Small Spinner"
                  small
                  v-show="isBulkAction"
                ></b-spinner
                >&nbsp;Bulk Actions
              </template>
              <b-dropdown-item @click="removeUsers()"
                >Remove Users</b-dropdown-item
              >
              <li class="position-relative" role="presentation">
                <a
                  @click="toggleGroupList"
                  class="dropdown-item"
                  href="#"
                  role="menuitem"
                >
                  Move Users
                  <font-awesome-icon icon="caret-right"></font-awesome-icon>
                </a>
                <ul
                  class="dropdown-menu d-block"
                  style="maxHeight: 200px; overflow: auto;"
                  v-if="showDropdown"
                >
                  <li
                    :key="index"
                    @click="moveUsers($event, group.id)"
                    role="presentation"
                    v-for="(group, index) in groupOptions"
                  >
                    <a class="dropdown-item" href="#" role="menuitem">{{
                      group.name
                    }}</a>
                  </li>
                </ul>
              </li>
            </b-dropdown>
          </b-button-group>
          <div :key="group.id" v-for="group in groups">
            <GroupsListing
              :expand="expand"
              :group="group"
              :hasPermission="hasPermission"
              @checkbox-changed="updateCheckboxes"
              @edit-group="editGroup"
              v-show="filteredGroups.includes(group.id)"
              :projects="projects"
              :org="org"
              :onProjectAccessUpdate="onProjectAccessUpdate"
              :projectAccess="projectAccess"
              :onRemoveProjectAccess="onRemoveProjectAccess"
            />
          </div>
        </div>
      </b-container>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { SEI_API_BASE, IS_UPGRADE_TO_PREMIUM_ENABLED } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { deepCopy } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { VALIDATE } from '../../utils/validate'

  import GroupsListing from './GroupsListing'
  import Spinner from '../Spinner'
  import RolesInput from '../RolesInput'


  async function getOrgProjects(orgId) {
    try {
      const url = `${SEI_API_BASE}/exams?organization_id=${orgId}&only=name,id`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function getProjectAccess(orgId) {
    try {
      const url = `${SEI_API_BASE}/organizations/${orgId}/project_access`
      const response = await HTTP.get(url)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'OrganizationGroup',
    components: {
      GroupsListing,
      Spinner,
      RolesInput
    },
    created() {
      this.isPremium = this.org.is_premium
      this.hasPermission =
        this.org.my_organization_permissions.includes('manage_groups') &&
        this.isPremium
      this.getGroups()
      this.getOrgProjects()
      this.getProjectAccess()
    },
    props: {
      org: {
        type: Object
      }
    },
    data() {
      return {
        groups: [],
        filteredGroups: [],
        groupName: '',
        groupsUrl: `${SEI_API_BASE}/organizations/${this.$route.params.orgId}/groups`,
        groupOptions: [],
        isBulkAction: false,
        isDeletingGroup: false,
        isUpdatingGroup: false,
        isAddingGroup: false,
        addingGroup: [],
        isAddingUser: false,
        editingGroup: {},
        selectedGroups: [],
        hasPermission: false,
        isPremium: false,
        showDropdown: false,
        loading: true,
        error: false,
        expand: true,
        search: false,
        searchText: '',
        users: [],
        userEmail: '',
        checkedUsers: {},
        hasChecked: false,
        groupPermissions: [
          { name: 'Manage groups and users', value: 'manage_groups' },
          { name: 'Manage examinees', value: 'manage_examinees' },
          { name: 'Create exams for this organization', value: 'create_exams' }
        ],
        isPremiumEnabled: IS_UPGRADE_TO_PREMIUM_ENABLED,
        newGroup: {},
        projects: [],
        projectAccess: []
      }
    },
    methods: {
      async getGroups(suppressLoading) {
        try {
          const params = '?include=users'
          const response = await HTTP.get(this.groupsUrl + params)
          this.groups = response.data.results
          this.searchGroups()
          this.groupOptions = this.groups.map(group => {
            return { name: group.name, id: group.id }
          })
          this.error = false
        } catch (error) {
          this.error = true
        } finally {
          if (!suppressLoading)
            this.loading = false
        }
      },
      async getOrgProjects() {
        const response = await getOrgProjects(this.org.id)

        if (response.error) {
          EVENT.alert({
            variant: 'danger',
            message: response.error.message
          })

          return
        }

        this.projects = [ ...response.results ]
      },
      async getProjectAccess() {
        const response = await getProjectAccess(this.org.id)

        if (response.error) {
          EVENT.alert({
            variant: 'danger',
            message: response.error.message
          })

          return
        }

        this.projectAccess = [ ...response.results ]
      },
      toggleGroupList(event) {
        event.preventDefault()
        this.showDropdown = true
      },
      async moveUsers(event, groupId) {
        event.preventDefault()
        try {
          this.showDropdown = false
          this.isBulkAction = true
          let users = []
          for (const id in this.checkedUsers) {
            await this.removeUsersRequest(id)
            users = [...users, ...this.checkedUsers[id]]
          }
          const payload = { caveon_ids: users }
          await this.addUsersRequest(groupId, payload)
          await this.getGroups()
          this.checkedUsers = {}
          this.hasChecked = false
          this.$refs.dropdown.hide()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Encountered a problem while moving users.'
          })
        } finally {
          this.isBulkAction = false
        }
      },
      addEmails() {
        if (this.userEmail) {
          let emailsList = this.userEmail.split(',')
          for (let i = 0; i < emailsList.length; i++) {
            const email = emailsList[i].trim()
            this.users.push(email)
          }
          this.userEmail = ''
        }
      },
      removeEmail(index) {
        this.users.splice(index, 1)
      },
      async addUsers() {
        try {
          this.isAddingUser = true
          const payload = { caveon_ids: this.users }
          for (const groupId of this.selectedGroups) {
            await this.addUsersRequest(groupId, payload)
          }
          await this.getGroups()
          this.exitAddUserModal()
        } catch (error) {
          const resp = error.response.data
          if (resp.messages && resp.messages.length) {
            EVENT.alert({
              variant: 'danger',
              message: resp.messages[0]
            })
          } else {
            EVENT.alert({
              variant: 'danger',
              message: 'Encountered a problem while adding users.'
            })
          }
        } finally {
          this.isAddingUser = false
        }
      },
      async addUsersRequest(groupId, payload) {
        const url = `${this.groupsUrl}/${groupId}/add_users`
        await HTTP.post(url, payload)
      },
      exitAddUserModal() {
        this.$bvModal.hide('add-user-modal')
        this.userEmail = ''
        this.users = []
        this.selectedGroups = []
      },
      async removeUsersRequest(groupId) {
        const url = `${this.groupsUrl}/${groupId}/remove_users`
        const payload = { caveon_ids: this.checkedUsers[groupId] }
        await HTTP.post(url, payload)
      },
      async removeUsers() {
        try {
          this.isBulkAction = true
          for (const groupId in this.checkedUsers) {
            await this.removeUsersRequest(groupId)
          }
          await this.getGroups()
          this.checkedUsers = {}
          this.hasChecked = false
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Encountered a problem while removing users.'
          })
        } finally {
          this.isBulkAction = false
        }
      },
      updateCheckboxes(groupId, checked) {
        if (checked.length) {
          this.checkedUsers[groupId] = checked
        } else {
          delete this.checkedUsers[groupId]
        }
        this.hasChecked = Boolean(Object.keys(this.checkedUsers).length)
      },
      onSubmit(event, method) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this[method]()
        }
      },
      async createGroup() {
        try {
          this.isAddingGroup = true
          const payload = {
            name: this.groupName,
            permissions: this.addingGroup
          }

          await HTTP.post(this.groupsUrl, payload)
          await this.getGroups()
          this.exitAddGroupModal()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to create new group.'
          })
        } finally {
          this.isAddingGroup = false
        }
      },
      exitAddGroupModal() {
        this.$bvModal.hide('add-group-modal')
        this.groupName = ''
        this.addingGroup = []
        this.newGroup = {}
      },
      async updateGroup() {
        try {
          this.isUpdatingGroup = true
          const url = `${SEI_API_BASE}/organizations/${this.$route.params.orgId}/groups/${this.editingGroup.id}`
          const payload = Object.keys(this.editingGroup).filter(key => !key.startsWith('_')).reduce((cleanData, key) => {
            cleanData[key] = this.editingGroup[key]

            return cleanData
          }, {})

          await HTTP.put(url, payload)
          await this.getGroups()
          this.$bvModal.hide('edit-group-modal')
        } catch (error) {
          EVENT.alert({ variant: 'danger', message: 'Failed to update group.' })
        } finally {
          this.isUpdatingGroup = false
        }
      },
      async deleteGroup() {
        try {
          this.isDeletingGroup = true
          const url = `${this.groupsUrl}/${this.editingGroup.id}`
          await HTTP.delete(url)
          await this.getGroups()
          this.$bvModal.hide('edit-group-modal')
        } catch (error) {
          EVENT.alert({ variant: 'danger', message: 'Failed to delete group.' })
        } finally {
          this.isDeletingGroup = false
        }
      },
      editGroup(groupId) {
        const groupToEdit = this.groups.find(group => group.id === groupId)
        this.editingGroup = deepCopy(groupToEdit)
        this.$bvModal.show('edit-group-modal')
      },
      searchGroups() {
        this.filteredGroups = this.groups
          .map(group => {
            if (
              group.name.toLowerCase().includes(this.searchText.toLowerCase())
            ) {
              return group.id
            }
          })
          .filter(group => group)
      },
      reload() {
        this.loading = true
        this.getGroups()
      },
      showSearch() {
        this.search = true
      },
      hideSearch() {
        this.search = false
        this.searchText = ''
        this.searchGroups()
      },
      upgrade() {
        this.$router.push({
          name: 'orgBilling',
          params: { orgId: this.org.id }
        })
      },
      usersChanged(userOptons) {
        this.users = userOptons.map(option => option.value)
      },
      onProjectAccessUpdate() {
        this.loading = true
        this.getGroups(true)
        this.getOrgProjects()
        this.getProjectAccess()
        this.loading = false
      },
      onRemoveProjectAccess(roleId)  {
        const index = this.projectAccess.map(access => access.role_id).indexOf(roleId)

        this.projectAccess.splice(index, 1)
      }
    },
    computed: {
      expandOrCollapse() {
        if (this.expand) return 'Collapse All'
        return 'Expand All'
      },
      disableAddUsersBtn() {
        return this.isAddingUser
      },
      disableAddEmailsBtn() {
        return this.isAddingUser || this.userEmail.length < 1
      },
      disableUpdateGroupBtn() {
        return (
          // Don't allow the Administrator group to be edited
          this.editingGroup.name == 'Administrators' || this.isUpdatingGroup
        )
      },
      disableAddGroupBtn() {
        return this.isAddingGroup
      },
      userOptions() {
        const userOptions = []
        const userSet = new Set()
        for (const group of this.groups) {
          for (const user of group.users) {
            if (!userSet.has(user.email)) {
              userOptions.push({
                key: user.caveon_id,
                type: 'user',
                value: user.email,
                name: `${user.email} (User)`
              })
              userSet.add(user.email)
            }
          }
        }
        return userOptions
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
</style>
