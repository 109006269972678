<template>
  <div>
    <b-row v-for="role in groupProjectAccess" :key="role.role_id" class="border-bottom2 pb-2 mb-2 project-access">
      <b-col><div v-if="role.exam_name"><small>PROJECT:</small> </div>{{ role.exam_name }}</b-col>
      <b-col><div v-if="role.role_type"><small>ROLE:</small> </div>{{ getRoleName(role.role_type) }}</b-col>
      <b-col><div v-if="role.expires_at"><small>EXPIRES:</small> </div>{{ getLocalDateTime(role.expires_at) }}</b-col>
      <b-col cols="2">
        <b-button-group>
          <b-button @click="openProjectRoles(role.exam_id)" size="sm" variant="white">View</b-button>
          <b-button @click="removeProjectAccess(role)" size="sm" variant="white">
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ROLE_TYPES } from '../../utils/constants'
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants'
import { EVENT } from '../../utils/event-bus'
import { DateTimeParser } from '../../utils/date-time-parser'


async function removeRole(projectId, roleId) {
  try {
    const url = `${SEI_API_BASE}/exams/${projectId}/roles/${roleId}`

    await HTTP.delete(url)
  } catch (error) {
    return { error, message: 'You do not have permission to manage roles in this project.' }
  }
}

export default {
  name: 'ProjectAccessListing',
  props: {
    org: {
      type: Object
    },
    projects: {
      type: Array,
      defaultValue: []
    },
    projectAccess: {
      type: Array,
      defaultValue: []
    },
    onRemoveProjectAccess: {
      type: Function,
      default: () => { /* NOOP */ }
    },
    group: {
      type: Object,
      defaultValue: {}
    }
  },
  data() {
    return {
      roleTypeMap: {}
    }
  },
  created() {
    this.roleTypeMap = ROLE_TYPES.reduce((map, next) => {
      map[next.type] = next.title

      return map
    }, {})
    this.dateTimeParser = DateTimeParser(this.$moment)
  },
  methods: {
    async removeProjectAccess(role) {
      const error = await removeRole(role.exam_id, role.role_id)

      if (error) {
        EVENT.alert({
          variant: 'danger',
          message: error.message
        })
      } else {
        this.onRemoveProjectAccess(role.role_id)
      }
    },
    getRoleName(roleType) {
      return this.roleTypeMap[roleType]
    },
    getLocalDateTime(dateTime) {
      return this.dateTimeParser.displayExpirationLocal(dateTime)
    },
    openProjectRoles(projectId) {
      window.open(
        `${window.location.origin}/projects/${projectId}/access`,
        'blank'
      )
    }
  },
  computed: {
    availableProjects() {
      return this.projects.map((project) => {
        return {
          text: project.name,
          value: project.id
        }
      })
    },
    availableRoles() {
      return this.roles.map((role) => {
        return {
          text: role.title,
          value: role.type
        }
      })
    },
    groupProjectAccess() {
      return [ ...this.projectAccess ].filter(access => access.group_id === this.group.id).sort((a, b) => {
        return a.exam_name.localeCompare(b.exam_name) || a.role_type.localeCompare(b.role_type)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-access small {
  font-size: .7em;
  color: #666;
}
.border-bottom2 {
  border-bottom: 1px solid #ddd;
}
</style>
