<template>
  <div>
    <UsersListing
      ref="userListing"
      :fields="fieldsToDisplay"
      :users="app.users"
      :is-bulk-action="isBulkAction"
      @remove-user="deleteUsers"
    />

    <!-- Add Users Modal -->
    <b-modal
      @hidden="hideAddUserModal"
      id="add-user-modal"
      size="xl"
      title="Add Users"
    >
      <b-form @submit.prevent="onSubmit" id="add-users-form" novalidate>
        <b-form-group
          label="Add Users / Send Invitations *"
          label-for="email-addresses-input"
        >
          <RolesInput
            placeholder="Email Addresses"
            @roles-changed="emailsChanged"
          />
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            :disabled="disableAddUsersBtn"
            form="add-users-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner label="Small Spinner" small v-show="isSaving"></b-spinner
            >&nbsp;Add Users
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from '../../utils/requests'
import { SEI_API_BASE } from '../../utils/constants'
import { EVENT } from '../../utils/event-bus'
import { VALIDATE } from '../../utils/validate'

import UsersListing from './UsersListing'
import RolesInput from '../RolesInput'

async function addUsers(appId, emails) {
  try {
    const url = `${SEI_API_BASE}/apps/${appId}/add_users`
    const payload = {
      caveon_ids: emails
    }
    const response = await HTTP.post(url, payload)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

async function deleteUsers(appId, userIds) {
  try {
    const url = `${SEI_API_BASE}/apps/${appId}/remove_users`
    const payload = {
      caveon_ids: userIds
    }
    const response = await HTTP.post(url, payload)
    return { data: response.data }
  } catch (error) {
    return { error }
  }
}

export default {
  name: 'Users',
  components: {
    UsersListing,
    RolesInput
  },
  created() {
    this.dropdownId = 'accordion-' + this._uid
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isBulkAction: false,
      isSaving: false,
      emailsInput: '',
      emails: [],
      users: {},
      dropdownId: '',
      fieldsToDisplay: [
        { key: 'checked', label: '' },
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          formatter: (value) => (value === 'placeholder' ? '' : value)
        },
        { key: 'remove', label: '' }
      ]
    }
  },
  methods: {
    appUpdated() {
      if (this.isSaving) {
        this.isSaving = false
        this.$bvModal.hide('add-user-modal')
      }

      if (this.isBulkAction) {
        this.isBulkAction = false
        this.$refs.userListing.clearChecked()
      }
    },
    onSubmit(event) {
      const isValid = VALIDATE.validateFields(event.target)
      if (isValid) {
        this.addUsers()
      }
    },
    async addUsers() {
      this.isSaving = true

      const { error } = await addUsers(this.app.caveon_id, this.emails)

      if (error) {
        this.isSaving = false
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to add! Please try again.'
        })
      }

      this.$emit('get-app')
    },
    async deleteUsers(userIds, isBulk) {
      if (isBulk) {
        this.isBulkAction = true
      }

      const { error } = await deleteUsers(this.app.caveon_id, userIds)

      if (error) {
        this.isBulkAction = false
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to delete! Please try again.'
        })
      }

      this.$emit('get-app')
    },
    addEmails() {
      if (this.emailsInput) {
        let emailsList = this.emailsInput.split(',')
        for (let i = 0; i < emailsList.length; i++) {
          const email = emailsList[i].trim()
          this.emails.push(email)
        }
        this.emailsInput = ''
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1)
    },
    hideAddUserModal() {
      this.emails = []
      this.emailsInput = ''
    },
    emailsChanged(emailOptions) {
      this.emails = emailOptions.map((option) => option.value)
    }
  },
  computed: {
    disableAddEmailsBtn() {
      return this.emailsInput.length < 1
    },
    disableAddUsersBtn() {
      return this.isSaving
    }
  }
}
</script>

<style lang="scss" scoped></style>
