<template>
  <b-form-group
    :label="label"
    :label-for="id"
    label-cols-md="4"
    label-cols-lg="3"
  >
    <b-input-group prepend="https://">
      <template v-slot:append>
        <b-button @click="removeWidget" size="sm" variant="white">
          <font-awesome-icon icon="trash"></font-awesome-icon>
        </b-button>
      </template>
      <!-- eslint-disable vue/no-mutating-props -->
      <b-form-input
        :class="isInvalid"
        :id="id"
        @blur="validateInput"
        type="url"
        v-if="isTakeWidget"
        v-model="widgets[name].url"
      ></b-form-input>
      <b-form-input
        :class="isInvalid"
        :id="id"
        @blur="validateInput"
        type="url"
        v-else
        v-model="widgets[name]"
      ></b-form-input>
    </b-input-group>
    <span v-if="showAnalyticsWarning">
      <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>&nbsp;
      <small>Now displayed as full page app integration. Find this widget in Apps section of the project dropdown</small>
    </span>
    <div v-if="isTakeWidget" class="mt-2">
      <label>Icon</label>
      <IconPicker @icon-selected="onIconSelected" :selected="widgets[name].icon" />
    </div>
  </b-form-group>
</template>

<script>
import IconPicker from '../project/IconPicker.vue'

export default {
  name: 'ContentWidget',
  components: {
    IconPicker
  },
  props: {
    name: {
      type: String
    },
    widgets: {
      type: Object
    },
    invalidInputs: {
      type: Array
    }
  },
  data() {
    return {
      isTakeWidget: false
    }
  },
  created() {
    if (this.name == 'take_widget') {
      this.isTakeWidget = true
    }
  },
  methods: {
    onIconSelected (icon) {
      this.widgets[this.name].icon = icon // eslint-disable-line vue/no-mutating-props
      this.$forceUpdate()
    },
    removeWidget() {
      this.$emit('remove-widget', this.name)
    },
    validateInput() {
      if (this.isTakeWidget) {
        this.$emit('validate-input', this.name, this.widgets[this.name].url)
      } else {
        this.$emit('validate-input', this.name, this.widgets[this.name])
      }
    }
  },
  computed: {
    id() {
      return this.name.split('_').join('-') + '-input'
    },
    label() {
      if (this.name === 'dashboard_widget') {
        return 'Analytics (deprecated)'
      }

      return this.name
        .replace('_widget', '')
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    isInvalid() {
      return this.invalidInputs.includes(this.name) ? 'invalid' : ''
    },
    showAnalyticsWarning() {
      return this.label.startsWith('Analytics')
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid {
  border-color: #d86a03;
  box-shadow: 0 0 2px #d86a03;
}
</style>
