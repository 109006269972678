<template>
  <b-tab :active="active" title="References">
    <b-modal
      id="reference-modal"
      size="sm"
      :title="`${ newReference ? 'Create' : 'Edit' } Reference`"
      @ok.prevent="saveReference"
    >
      <b-input
        placeholder="Reference"
        v-model="editing.name"
        autofocus
      ></b-input>
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="white" :disabled="saving">Cancel</b-button>
        <b-button @click="ok" variant="secondary" :disabled="!editing.name || saving">
          <b-spinner small v-if="saving" />
          {{ newReference ? 'Create' : 'Save' }} Reference
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="delete-reference-modal"
      size="sm"
      title="Delete Reference"
      @ok.prevent="deleteReference"
    >
      Are you sure you would like to delete: <mark :style="{ borderRadius: '3px', backgroundColor: 'rgb(244, 245, 247)' }">{{ editing.name }}</mark>?
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="white" :disabled="saving">Cancel</b-button>
        <b-button @click="ok" variant="danger" :disabled="saving">
          <b-spinner small v-if="saving" />
          Delete Reference
        </b-button>
      </template>
    </b-modal>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <b-button
        @click="addReference()"
        variant="primary-light"
        :disabled="saving || shouldDisable('create_items')"
        >Add Reference</b-button
      >
      <b-pagination
        v-if="safeReferences.length > perPage"
        v-model="page"
        :per-page="perPage"
        :total-rows="safeReferences.length"
      ></b-pagination>
    </div>

    <b-table
      :fields="fields"
      :items="safeReferences"
      :current-page="page"
      :per-page="perPage"
      responsive
      small
      striped
      fixed
      sort-icon-left
      sort-by="name"
      v-if="safeReferences.length"
    >
      <template v-slot:cell(actions)="row">
        <b-button-group class="float-right">
          <b-button
            @click="editReference(row)"
            size="sm"
            variant="white"
            :disabled="saving || shouldDisable('edit_items')"
            >Edit</b-button
          >
          <b-button
            @click="beforeDeleteReference(row)"
            size="sm"
            variant="white"
            :disabled="saving || shouldDisable('delete_items')"
          >
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-button-group>
      </template>
    </b-table>
  </b-tab>
</template>

<script>
  import { deepCopy } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'
  import { SESSION } from '../../utils/session'
  import { v4 as uuidv4 } from 'uuid'

  const EMPTY_REFERENCE = {
    name: ''
  }

  async function addReferenceRequest(projectId, reference) {
    reference = reference || EMPTY_REFERENCE

    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/references`
      const response = await HTTP.post(url, reference)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveReferenceRequest(projectId, reference) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/references/${reference.id}`
      const response = await HTTP.put(url, reference)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function deleteReferenceRequest(projectId, referenceId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/references/${referenceId}`
      const response = await HTTP.delete(url)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ResourceReference',
    props: {
      references: {
        type: Array
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.safeReferences = deepCopy(this.references)
      this.safeReferences.sort(ref => ref.name)
    },
    data() {
      return {
        safeReferences: [],
        saving: false,
        editing: {},
        page: 1,
        perPage: 50,
        fields: [
          { key: 'name', sortable: true },
          { key: 'actions', label: '' }
        ]
      }
    },
    methods: {
      editReference(row) {
        const reference = this.safeReferences.find(reference => reference.id === row.item.id)
        this.editing = { ...reference }
        this.$bvModal.show('reference-modal')
      },
      addReference() {
        const referenceToAdd = {
          name: '',
          id: uuidv4()
        }

        this.editing = referenceToAdd

        this.$bvModal.show('reference-modal')
      },
      async saveReference() {
        const reference = { ...this.editing }

        const isNew = !reference.exam_id

        let task

        if (isNew) {
          task = addReferenceRequest
        } else {
          task = saveReferenceRequest
        }

        this.saving = true

        const { data, error } = await task(
          this.$route.params.projectId,
          reference
        )

        this.saving = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save reference.'
          })
        }

        if (isNew) {
          data._rowVariant = 'primary-light'
          this.safeReferences.unshift(data)
        } else {
          const index = this.safeReferences.findIndex(reference => reference.id === data.id)
          this.safeReferences.splice(index, 1, data)
        }

        this.$bvModal.hide('reference-modal')

        this.emitToParent()
      },
      beforeDeleteReference(row) {
        const reference = this.safeReferences.find(reference => reference.id === row.item.id)
        this.editing = { ...reference }
        this.$bvModal.show('delete-reference-modal')
      },
      async deleteReference() {
        this.saving = true

        const { error } = await deleteReferenceRequest(
          this.$route.params.projectId,
          this.editing.id
        )

        this.saving = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete reference.'
          })
        }

        const index = this.safeReferences.findIndex(reference => reference.id === this.editing.id)

        this.safeReferences.splice(index, 1)

        this.$bvModal.hide('delete-reference-modal')

        this.emitToParent()
      },
      emitToParent() {
        const contentForParent = deepCopy(this.safeReferences)
        this.$emit('update-reference', contentForParent)
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      }
    },
    computed: {
      newReference() {
        return !this.editing.exam_id
      }
    }
  }
</script>

<style lang="scss" scoped>
.pagination {
  margin-bottom: 0px;
}
</style>
