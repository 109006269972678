<template>
<div v-if="!loading">
  <b-navbar sticky variant="light">
    <div>
      <h5 class="mb-0">{{ language.name }}</h5>
    </div>
    <a href="#app" aria-label="Scroll to Top" class="ml-2 top-button">🔝</a>
  </b-navbar>
  <b-container class="mt-3" fluid v-if="!loading">
    <b-tabs lazy v-model="tabIndex" >
      <b-tab title="Settings">
				<LanguagesTranslationsSettings :language="language" :project="project" />
			</b-tab>
      <b-tab title="Forms">
				<LanguagesTranslationsForms
          :language="language"
          :project="project"
        />
			</b-tab>
      <b-tab title="Items">
				<LanguagesTranslationsItems
          :language="language"
          :project="project"
        />
			</b-tab>

      <b-tab title="Shared Content">
				<LanguagesTranslationsSharedContent :language="language" :project="project" />
			</b-tab>

      <b-tab title="Agreements">
				<LanguagesTranslationsAgreements :language="language" :project="project" />
			</b-tab>

      <b-tab title="Surveys">
				<LanguagesTranslationsSurveys :language="language" :project="project" />
			</b-tab>

      <!-- <b-tab title="Score Report">
				<LanguagesTranslationsScoreReport :language="language" :project="project" />
			</b-tab> -->

      <b-tab title="Exam Interface">
				<LanguagesTranslationsExamInterface :language="language" :project="project" />
			</b-tab>

      <b-tab title="Readiness Checklist">
        <LanguagesTranslationsReadiness :language="language" :project="project" />
      </b-tab>

		</b-tabs>
  </b-container>
  <Spinner v-else />
</div>
</template>
<script>
import Spinner from '../Spinner'
import LanguagesTranslationsSettings from './LanguagesTranslationsSettings.vue'
import LanguagesTranslationsForms from './LanguagesTranslationsForms.vue'
import LanguagesTranslationsItems from './LanguagesTranslationsItems.vue'
import LanguagesTranslationsSharedContent from './LanguagesTranslationsSharedContent.vue'
import LanguagesTranslationsAgreements from './LanguagesTranslationsAgreements.vue'
// import LanguagesTranslationsScoreReport from './LanguagesTranslationsScoreReport.vue'
import LanguagesTranslationsExamInterface from './LanguagesTranslationsExamInterface.vue'
import LanguagesTranslationsReadiness from './LanguagesTranslationsReadiness.vue'
import LanguagesTranslationsSurveys from './LanguagesTranslationsSurveys.vue'
import { Language } from '../../utils/language'
import { SESSION } from '../../utils/session'


export default {
  name: 'TranslationsBreakdown',
  components: {
    Spinner,
		LanguagesTranslationsItems,
    LanguagesTranslationsForms,
    LanguagesTranslationsSettings,
    LanguagesTranslationsSharedContent,
    LanguagesTranslationsAgreements,
    // LanguagesTranslationsScoreReport,
    LanguagesTranslationsExamInterface,
    LanguagesTranslationsReadiness,
    LanguagesTranslationsSurveys
  },
  props: {
    languageStatus: {
      type: Object
    },
		project: {
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      TABS: ['Settings', 'Forms', 'Items', 'SharedContent', 'Agreements', 'Surveys', 'Interface', 'Readiness'],
      tabIndex: 0
    }
  },
  async created() {
    this.language = await Language.FetchOne(this.project.id, this.$route.params.languageId)

    if (this.$route.hash.length > 1) {
      const tabIndex = this.TABS.indexOf(this.$route.hash.slice(1))

      if (tabIndex !== -1) {
        this.changeTab(tabIndex)
      }
    }

    await SESSION.initSupportedLanguages()

    this.loading = false
  },
  methods: {
    setTabIndex() {
      const index = this.TABS.indexOf(this.$route.hash)
      if (index) {
        this.tabIndex = index
      }
    },
    changeTab(tabIndex) {
      this.tabIndex = tabIndex
      scrollTo(0, 0)
    }
  },
  computed: {},
  watch: {
      tabIndex(newIndex) {
        const tabName = this.TABS[newIndex]
        window.history.replaceState(null, null, '#' + tabName)
      }
    }
}
</script>

<style lang="scss" scoped></style>
