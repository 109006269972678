<template>
	<div class="p-1 mb-1 border">
		<b-card-header class="border-0 p-0">
			<b-button v-b-toggle="dropdownId" class="text-left px-3 py-2" variant="primary" block>
				<div @click.stop class="d-inline">
					<b-form-checkbox v-if="hasOrgs" @change="checkAll" :checked="allChecked" class="d-inline position-relative" style="bottom: -1px" />

					{{ dropdownHeader }}
				</div>
			</b-button>
		</b-card-header>

		<b-collapse :id="dropdownId" visible>
			<b-button v-b-modal.add-org-modal variant="white" class="m-3 ml-0">
				Add Orgs
			</b-button>

			<b-dropdown v-show="checked.length" variant="white">
				<template #button-content>
					<b-spinner v-show="removing" label="Small Spinner" small /> &nbsp;Bulk Actions
				</template>

				<b-dropdown-item @click="removeBulk">Remove Orgs</b-dropdown-item>
			</b-dropdown>

			<div v-if="hasOrgs" class="p-2">
				<b-table :fields="fields" :items="orgs" sort-by="name" hover responsive small striped>
					<template #table-colgroup="scope">
						<col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'checked' ? '40px' : 'auto' }" />
					</template>

					<template #cell(checked)="scope">
						<b-form-checkbox v-model="checked" :value="scope.item.id" />
					</template>

					<template #cell(remove)="scope">
						<b-button @click="removeOrg(scope.item.id)" class="float-right px-1 py-0 rounded" variant="white">
							<font-awesome-icon icon="trash-alt" />
						</b-button>
					</template>
				</b-table>
			</div>
		</b-collapse>
	</div>
</template>

<script>
export default {
	name: 'OrgsListing',
	props: {
		orgs: {
			type: Array
		},
		fields: {
			type: Array
		},
		removing: {
			type: Boolean
		}
	},
	data () {
		return {
			checked: [],
			dropdownId: '',
			remove: ''
		}
	},
	created () {
		this.dropdownId = 'dropdown-' + this._uid
	},
	methods: {
		removeBulk () {
			this.$emit('remove-orgs', this.checked)
		},
		removeOrg (orgId) {
			this.$emit('remove-orgs', [ orgId ])
		},
		clearChecked () {
			this.checked = []
		},
		checkAll (checked) {
			if (checked) {
				this.checked = this.orgs.map(org => org.id)
			} else {
				this.checked = []
			}
		}
	},
	computed: {
		allChecked () {
			return this.checked.length === this.orgs.length
		},
		dropdownHeader () {
			return `Organizations (${ this.orgs.length })`
		},
		hasOrgs () {
			return Boolean(this.orgs.length)
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
