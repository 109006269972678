<template>
  <b-container class="mt-3" fluid>
    <b-row>
      <b-col md="9" xs="12">
        <b-row>
          <b-col md="6">
            <h3>Delivery</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="delivery-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="create_deliveries"
                  >Create</b-form-checkbox
                >
                <b-form-checkbox value="delete_deliveries"
                  >Delete</b-form-checkbox
                >
                <b-form-checkbox value="edit_deliveries">Edit</b-form-checkbox>
                <b-form-checkbox value="edit_delivery_meta"
                  >Edit Meta</b-form-checkbox
                >
                <b-form-checkbox value="run_deliveries">Run</b-form-checkbox>
                <b-form-checkbox value="view_deliveries">View</b-form-checkbox>
                <b-form-checkbox value="proctor_deliveries"
                  >Proctor</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-form-group>
            <h3>Exam</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="exam-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="edit_agreements"
                  >Edit Agreements</b-form-checkbox
                >
                <b-form-checkbox value="edit_exam_meta"
                  >Edit Meta</b-form-checkbox
                >
                <b-form-checkbox value="edit_exam_settings"
                  >Edit Settings</b-form-checkbox
                >                
                <b-form-checkbox value="edit_surveys"
                  >Edit Surveys</b-form-checkbox
                >
                <b-form-checkbox value="view_agreements"
                  >View Agreements</b-form-checkbox
                >
                <b-form-checkbox value="view_exam_settings"
                  >View Settings</b-form-checkbox
                >
                <b-form-checkbox value="view_surveys"
                  >View Surveys</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-form-group>
            <h3>Export</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="export-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="export_content"
                  >Content</b-form-checkbox
                >
                <b-form-checkbox value="export_data">Data</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col md="6" xs="12">
            <h3>Item</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="item-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="create_items">Create</b-form-checkbox>
                <b-form-checkbox value="delete_items">Delete</b-form-checkbox>
                <b-form-checkbox value="edit_items">Edit</b-form-checkbox>
                <b-form-checkbox value="edit_item_meta"
                  >Edit Meta</b-form-checkbox
                >
                <b-form-checkbox value="view_items">View</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
            <h3>Manage</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="manage-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="manage_examinees"
                  >Examinees</b-form-checkbox
                >
                <b-form-checkbox value="manage_forms">Forms</b-form-checkbox>
                <b-form-checkbox value="manage_integrations"
                  >Integrations</b-form-checkbox
                >
                <b-form-checkbox value="manage_translations">Translations</b-form-checkbox>
                <b-form-checkbox value="manage_users">Users</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
            <h3>Response</h3>
            <b-form-group>
              <b-form-checkbox-group
                id="response-permissions-checkbox-group"
                stacked
                v-model="fields"
              >
                <b-form-checkbox value="edit_responses">Edit</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { deepCopy } from '../../utils/misc.js'

export default {
  name: 'Permissions',
  created() {
    this.setFields()
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: {}
    }
  },
  methods: {
    report() {
      const data = [...this.fields]
      this.$emit('report', data, 'permissions')
    },
    setFields() {
      const {
        app_settings: { permissions }
      } = this.app
      this.fields = deepCopy(permissions)
    }
  }
}
</script>

<style lang="scss" scoped></style>
