import Vue from 'vue'

/**
 * Import EVENT to listen for and to emit global events.
 *
 * Each event should be namespaced based on which file is listening for the event.
 * For example, the event 'project.saveProject' is aptly prefixed with 'project.' because
 * it is in the Project.vue file. If you listen in the main App.vue file, then they
 * should be namespaced as global (e.g. 'global.alert'). Event names should always
 * be unique to avoid conflicts.
 *
 * For simple child --> parent relationships use this.$emit(). For example, when
 * AccordionList (child) emits to AppsPage, OrgsPage, and ProjectsPage (parents) when a star is clicked.
 *
 * If you have an event that needs to be emitted at various levels, such as 'global.alert', then use EVENT.
 */
export const EVENT = new Vue({
  data: {
    alertEvent: 'global.alert',
    saveAppEvent: 'app.saveApp',
    getAppEvent: 'app.getApp',
    getOrgEvent: 'organization.getOrg',
    saveOrgEvent: 'organization.saveOrg',
    saveProjectEvent: 'project.saveProject',
    getProjectEvent: 'project.getProject',
    reloadRouteEvent: 'project.reloadRoute',
    updateFlowCountEvent: 'project.updateFlowCount',
    updateFormCountEvent: 'project.updateFormCount'
  },
  methods: {
    alert(args) {
      this.$emit(this.alertEvent, args)
    },
    saveApp(app) {
      this.$emit(this.saveAppEvent, app)
    },
    getApp() {
      this.$emit(this.getAppEvent)
    },
    getOrg() {
      this.$emit(this.getOrgEvent)
    },
    saveOrg(org) {
      this.$emit(this.saveOrgEvent, org)
    },
    saveProject(project, options) {
      this.$emit(this.saveProjectEvent, project, options)
    },
    getProject() {
      this.$emit(this.getProjectEvent)
    },
    reloadRoute() {
      this.$emit(this.reloadRouteEvent)
    },
    updateFlowCount(by) {
      this.$emit(this.updateFlowCountEvent, by)
    },
    updateFormCount(by) {
      this.$emit(this.updateFormCountEvent, by)
    }
  }
})
