export const DateTimeParser = moment => {
  return {
    parseUtc(utcDateTime) {
      const dateTime = moment.utc(utcDateTime).toDate()
      const localDate = moment(dateTime).local().format('YYYY-MM-DD')
      const localTime = moment(dateTime).local().format('HH:mm:ss')

      return { localDate, localTime }
    },
    parseLocal({ localDate, localTime }) {
      return moment.utc(moment(`${localDate} ${localTime}`, 'YYYY-MM-DD HH:mm:ss').toDate())
    },
    displayExpirationLocal(expires_at) {
      if (!expires_at) return ''

      const dateTime = moment.utc(expires_at)
      const now = moment.utc()

      if (now.isAfter(dateTime)) return 'Expired'

      return dateTime.local().format('lll')
    }
  }
}
