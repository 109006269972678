<template> 
  <div v-if="!filtering" id="items-table">
    <b-table
      :fields="fields"
      :items="itemsWithTableProperties"
      responsive
      small
      striped
      fixed
      v-if="items.length"
      class="mb-0 tall"
      tbody-tr-class="row-container"
    >
      <template v-slot:table-colgroup="scope">
        <col
          :key="field.key"
          :style="{ width: columnWidth(field.key), minWidth: columnMinWidth(field.key) }"
          v-for="field in scope.fields"
        />
      </template>
      <template v-slot:cell(checked)="scope">
        <!-- eslint-disable vue/no-mutating-props -->
        <b-form-checkbox
          :value="scope.item.id"
          class="ml-2"
          v-model="checked.ids"
        ></b-form-checkbox>
        <!-- eslint-enable vue/no-mutating-props -->
      </template>
      <template #cell(name)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        {{ scope.item.name }}
        <span
          v-b-tooltip="{
            trigger: 'hover',
            title: 'Discrimination below 0.10',
            interactive: false
          }"
          v-if="scope.item._hasWarnings"
          :style="{ position: 'relative', zIndex: '99' }"
        >
          <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
        </span>
      </template>
      <template #cell(latest_item_type)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        {{ friendlyName(scope.item.latest_item_type) }}
      </template>
      <template #cell(extra)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
      </template>
      <template v-slot:cell(version)="scope">
        <div :id="scrollToId(scope.item.id)">
          <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
          {{ scope.item.live_version_number }}
          <span
            v-b-tooltip="{
              trigger: 'hover',
              title: 'Not the Latest',
              interactive: false
            }"
            v-if="scope.item.live_version_number !== scope.item.num_versions"
            :style="{ position: 'relative', zIndex: '99' }"
          >
            <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
          </span>
        </div>
      </template>
      <template v-slot:cell(info)="scope">
        <div>
          <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
          <b-badge
            class="rounded-circle mr-1"
            :variant="infoVariant(scope.item.has_reference)"
            :style="{ position: 'relative', zIndex: '99' }"
            v-b-tooltip.hover
            title="References"
            >R</b-badge
          >
          <b-badge
            class="rounded-circle mr-1"
            :variant="infoVariant(scope.item.has_enemy)"
            :style="{ position: 'relative', zIndex: '99' }"
            v-b-tooltip.hover
            title="Enemies"
            >E</b-badge
          >
          <b-badge
            class="rounded-circle mr-1"
            :variant="infoVariant(scope.item.has_shared)"
            :style="{ position: 'relative', zIndex: '99' }"
            v-b-tooltip.hover
            title="Shared Content"
            >S</b-badge
          >
          <b-badge
            class="rounded-circle"
            :variant="infoVariant(scope.item.has_file)"
            :style="{ position: 'relative', zIndex: '99' }"
            v-b-tooltip.hover
            title="Files"
            >F</b-badge
          >
        </div>
      </template>
      <template
        v-for="fieldId in selectFields"
        v-slot:[customCell(fieldId)]="scope"
      >
      <div :key="fieldId">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        <div class="d-flex flex-wrap" v-if="scope.item.preview_fields[fieldId]">
          <b-badge
            pill
            :key="fieldId + '-' + index"
            :style="{ position: 'relative', zIndex: '99', backgroundColor: 'white', color: 'black' }"
            :title="getPreviewOptionValue(fieldId, optionId, 'tooltip')"
            class="d-flex align-items-center custom-badge border"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-for="(optionId, index) in getSelectField(scope.item.preview_fields[fieldId])"
          >
            <div
              :style="{
                backgroundColor: getPreviewOptionValue(fieldId, optionId, 'color'),
                width: '15px',
                height: '15px',
                borderRadius: '30px'
              }"
              class="mr-1"
              v-if="getPreviewOptionValue(fieldId, optionId, 'color')"
            ></div>
            <div class="text-capitalize text-truncate py-1">
              {{ getPreviewOptionValue(fieldId, optionId, 'text') }}
            </div>
          </b-badge>
        </div>
      </div>
      </template>
      <template #cell(created_at)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        {{ $moment.utc(scope.item.created_at).local().format('L')  }}
      </template>
      <template #cell(edited_at)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        {{ friendlyEditedAt(scope.item.edited_at) }}
      </template>
      <template #cell(forms)="scope">
        <b-link :disabled="shouldDisable" :to="rowLink(scope.item)" class="stretched-link"></b-link>
        <div class="d-flex flex-wrap">
          <b-badge
            pill
            :style="{ position: 'relative', zIndex: '99', backgroundColor: 'white', color: 'black' }"
            :title="formatForms(scope.item.forms_info)"
            class="d-flex align-items-center custom-badge border"
            v-b-tooltip.right="{ trigger: 'hover', interactive: false, html: true }"
          >
            <div class="pt-1 px-2">
              {{ scope.item.forms_info.length  }}
            </div>
          </b-badge>
        </div>
      </template>
      <template v-slot:row-details="row">
        <b-link :disabled="shouldDisable" :to="rowLink(row.item)" :data-highlight="scrollToId(row.item.id)" class="stretched-link">
          <div class="details">{{ row.item.stem }}</div>
        </b-link>
      </template>
    </b-table>
    <div class="p-2" v-else>No Items.</div>
  </div>
  <Spinner v-else />
</template>

<script>
  import { friendlyName } from '../../utils/misc'
  import { SESSION } from '../../utils/session'

  import get from 'lodash.get'

  import Spinner from '../Spinner'

  const HIGHLIGHT_DISCIMINATION_LOW = 0.10

  export default {
    name: 'ItemsTable',
    components: {
      Spinner
    },
    props: {
      items: {
        type: Array
      },
      previewFields: {
        type: Array
      },
      previewOptionLookup: {
        type: Object
      },
      filtering: {
        type: Boolean
      },
      checked: {
        type: Object
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.setFields()
    },
    data() {
      return {
        fields: [
          { key: 'checked', label: '', tdClass: 'noPointer' },
          { key: 'name' },
          { key: 'latest_item_type', label: 'Type' },
          { key: 'extra', label: '' },
          { key: 'version' },
          { key: 'info', label: 'Includes' },
          { key: 'forms', label: 'Forms' }
        ],
        selectFields: []
      }
    },
    methods: {
      setFields() {
        const selectFields = []
        const previewFields = []

        const groupBy = get(SESSION, 'items.groupBy')

        if (groupBy === 'item_type') {
          this.fields = this.fields.filter(
            field => field.key !== 'latest_item_type'
          )
        }

        for (const field of this.previewFields) {
          if (field.key !== groupBy) {
            if (field.isSelect) {
              selectFields.push(field.key)
            }

            field.tdClass = 'default-td ellipsis'
            previewFields.push(field)
          }
        }

        const truncLong = value => {
          if (value === 0) {
            return 0
          }

          if (!value) {
            return ''
          }

          return value.toFixed(3)
        } 

        this.selectFields = selectFields
        this.fields.push(
          ...previewFields,
          { key: 'created_at', label: 'Created' },
          { key: 'edited_at', label: 'Edited' },
          { key: 'stats.num_responses', label: 'Responses' },
          { key: 'stats.avg_score', label: 'P Value', formatter: truncLong },
          { key: 'stats.correlation', label: 'Correlation', formatter: truncLong },
          { key: 'stats.discrimination', label: 'Discrimination', formatter: truncLong },
          { key: 'stats.avg_seconds', label: 'Duration', formatter: truncLong }
        )
      },
      customCell(key) {
        return `cell(${key})`
      },
      rowLink(item) {
        return {
          name: 'projectItem',
          params: { itemId: item.id },
          query: { version_number: item.num_versions }
        }
      },
      rowClicked(record) {
        const checkbox = event.target.querySelector('[type=checkbox]')
        if (checkbox) {
          if (checkbox.checked) {
            this.checked.ids = this.checked.ids.filter( // eslint-disable-line vue/no-mutating-props
              id => id !== checkbox.value
            )
          } else {
            this.checked.ids.push(checkbox.value) // eslint-disable-line vue/no-mutating-props
          }
          return
        }

        this.$router.push({
          name: 'projectItem',
          params: { itemId: record.id },
          query: { version_number: record.num_versions }
        })
      },
      columnWidth(key) {
        let width
        switch (key) {
          case 'checked':
            width = '35px'
            break
          case 'name':
            width = '250px'
            break
          case 'version':
            width = '70px'
            break
          case 'latest_item_type':
            width = '170px'
            break
          case 'extra':
            width = 'auto'
            break
          case 'info':
            width = '120px'
            break
          default:
            width = '170px'
        }

        return width + ' !important'
      },
      columnMinWidth(key) {
        let minwidth
        switch (key) {
          case 'name':
            minwidth = '30%'
            break
          case 'checked':
            minwidth = '45px'
            break
          default:
            minwidth = '35px'
        }

        return minwidth + ' !important'
      },
      scrollToId(id) {
        return id === SESSION.scrollToItem ? 'scroll-to-item' : null
      },
      infoVariant(info) {
        return info ? 'black' : 'white'
      },
      friendlyName(itemType) {
        return friendlyName(itemType)
      },
      friendlyEditedAt(date) {
        const formattedDate = this.$moment.utc(date).local().format('L')

        if (formattedDate !== 'Invalid date') {
          return formattedDate
        }

        return ''
      },
      getVariant(item) {
        if (item.stats.discrimination < HIGHLIGHT_DISCIMINATION_LOW) return 'warning'
      },
      formatForms(formInfo) {
        const formNames = formInfo.map(form => form.name)
        let html = `<ul class="text-truncate" style="text-align:left; list-style-position: inside; padding-left: 0; margin-bottom: 0;">`
        for (const name of formNames) {
          if (name.length > 25) {
            html += `<li>${name.substring(0, 23)}...</li>`
          } else {
            html += `<li>${name}</li>`
          }
        }
        html += `</ul>`
        return html
      },
      getPreviewOptionValue(fieldId, optionId, key) {
        let previewOptionValue = ''
        const previewOptions = this.previewOptionLookup[fieldId].filter((option) => option.value == optionId)
        if (previewOptions.length) {
          const previewOption = previewOptions[0]
          if (previewOption.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
            previewOptionValue = previewOption[key]
          }
        }
        return previewOptionValue
      },
      getSelectField(fields) {
        if (typeof fields === 'string') {
          return [fields]
        }
        return fields
      }
    },
    computed: {
      itemsWithTableProperties() {
        return [ ...this.items ].map(item => {
          item._showDetails = true
          // item._rowVariant = this.getVariant(item)
          item._hasWarnings = this.getVariant(item)

          return item
        })
      }
    }
  }
</script>

<style lang="scss">
  th {
    min-width: 80px;
  }
  .noPointer {
    cursor: default;
  }
  .custom-badge {
    min-width: 30px;
    min-height: 25px;
  }
  .table .badge-white {
    opacity: 0.3;
    border: 1px solid #ccc;
  }
  .details {
    padding: 0 15px;
    font-size: .9em;
    color: #888;
  }
  #items-table .b-table-details td {
    padding: 0px 0 8px 26px !important;
  }
  #items-table tr td {
    transform: rotate(0);
    word-wrap: break-word;
  }
  .default-td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 200px !important;
  }
  a {
    text-decoration: none !important;
  }
  .tall {
    max-height: calc(100vh - 223px);
    border: 1px solid #ddd;
  }
</style>
