<template>
  <div>
    <div v-if="!loading">
      <div v-if="!error">
        <b-modal
          @hidden="clearSelectedPreview()"
          @ok.prevent="bulkUpdateCustomFields()"
          id="bulk-custom-modal"
          size="xl"
          title="Change Custom Fields"
        >
          <label>Field</label>
          <b-form-select
            :options="bulkUpdateOptions"
            @change="clearSelectedSubOptions()"
            v-model="selectedPreviewOption"
          ></b-form-select>
          <div class="mt-3" v-if="selectedPreviewOption">
            <label>Value</label>
            <b-form-input
              placeholder="Text"
              v-if="display('text')"
              v-model="previewValue.notMulti"
            ></b-form-input>
            <b-form-input
              placeholder="Number"
              type="number"
              v-if="display('number')"
              v-model.number="previewValue.notMulti"
            ></b-form-input>
            <v-select
              v-if="display('select_one')"
              :disabled="!isPremium"
              :options="subOptionLookup[selectedPreviewOption]"
              :reduce="option => option.value"
              :selectable="option => !option.disabled"
              :data-value="Boolean(previewValue.notMulti)"
              v-model="previewValue.notMulti"
              label="text"
              placeholder="Not selected"
              style="backgroundColor: white"
            >
              <template #selected-option-container="{ option, deselect }">
                <span
                  :class="`vs__selected border ${option.color ? 'pl-0' : ''}`"
                >
                  <div
                    :style="{
                      backgroundColor: option.color,
                      width: '15px',
                      height: '100%',
                      borderRadius: '3px 0 0 3px'
                    }"
                    class="mr-1"
                    v-if="option.color"
                  ></div>
                  {{ option.text }}
                  <button
                    :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                    @click.stop="deselect(option)"
                    @mousedown.stop
                    class="vs__deselect no-focus"
                    type="button"
                  >
                    <font-awesome-icon icon="times"></font-awesome-icon>
                  </button>
                </span>
              </template>
              <template #option="{ color, text }">
                <div
                  :style="{ background: color }"
                  class="option p-1 d-inline-block"
                  v-if="color"
                ></div>
                {{ text }}
              </template>
            </v-select>
            <v-select
              v-if="display('select_multi')"
              :disabled="!isPremium"
              :options="subOptionLookup[selectedPreviewOption]"
              :reduce="option => option.value"
              :selectable="option => !option.disabled"
              :data-value="Boolean(previewValue.multi)"
              v-model="previewValue.multi"
              label="text"
              placeholder="Not selected"
              style="backgroundColor: white"
              multiple
            >
              <template #selected-option-container="{ option, deselect }">
                <span
                  :class="`vs__selected border ${option.color ? 'pl-0' : ''}`"
                >
                  <div
                    :style="{
                      backgroundColor: option.color,
                      width: '15px',
                      height: '100%',
                      borderRadius: '3px 0 0 3px'
                    }"
                    class="mr-1"
                    v-if="option.color"
                  ></div>
                  {{ option.text }}
                  <button
                    :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                    @click.stop="deselect(option)"
                    @mousedown.stop
                    class="vs__deselect no-focus"
                    type="button"
                  >
                    <font-awesome-icon icon="times"></font-awesome-icon>
                  </button>
                </span>
              </template>
              <template #option="{ color, text }">
                <div
                  :style="{ background: color }"
                  class="option p-1 d-inline-block"
                  v-if="color"
                ></div>
                {{ text }}
              </template>
            </v-select>
          </div>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button-group>
              <b-button @click="cancel" variant="white">Cancel</b-button>
              <b-button
                :disabled="modalSaveDisabled"
                @click="ok"
                variant="secondary"
              >
                <b-spinner
                  label="Small Spinner"
                  small
                  v-show="saving"
                ></b-spinner
                >&nbsp;Save
              </b-button>
            </b-button-group>
          </template>
        </b-modal>
        
        <ItemSettingsBulk ref="refItemSettingsBulk" :items="items" :selected="checked" :reloadItems="reloadItems" />

        <b-navbar sticky variant="light">
          
            <b-button
              :disabled="filtering || shouldDisable('create_items')"
              @click="addItem()"
              variant="secondary"
              class="mr-2"
              >Add Item</b-button
            >
            <b-button-group class="mr-2">
              <Resource :project="project" />
              <AnalyticsDevelopment v-if="isAdmin" />
            </b-button-group>
          <b-button-group>
            <b-form-input
              placeholder="Search Items"
              @keyup.enter="simpleSearch"
              :disabled="filtering"
              v-model="searchText"
            />
            <b-button
              :disabled="filtering"
              @click="simpleSearch"
              variant="primary-light"
              >Search</b-button
            >
          </b-button-group>
          <b-button
              variant="text"
              size="sm"
              :disabled="filtering"
              v-b-toggle.sidebar-filters
              style="cursor: pointer;"
              >Advanced</b-button
            >
          <b-navbar-nav class="ml-auto" v-if="totalPages > 1">
            <b-pagination
              :disabled="filtering"
              :per-page="perPage"
              :total-rows="rows"
              aria-controls="my-table"
              :value="sessionItems.currentPage"
              @change="onPageChange"
            ></b-pagination>
          </b-navbar-nav>

        </b-navbar>

        <b-container class="mt-3" fluid>
          <div v-if="!filtering">
            <b-row>
              <b-col class="d-flex justify-content-between mb-2">
                <h3>Items: {{ rows }}</h3>
                <div>
                  <b-dropdown
                    :disabled="filtering"
                    text="Bulk Actions"
                    variant="white"
                    size="sm"
                  >
                    <b-dropdown-item @click="selectAllIds()" v-if="!hideSelectAll"
                      >Select All</b-dropdown-item
                    >
                    <b-dropdown-item @click="clearSelectedIds()" v-if="showOption"
                      >Clear Selected</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="shouldDisable('delete_items')" @click="bulkDelete()" v-if="showOption"
                      >Delete Selected</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="shouldDisable('edit_items')"  @click="bulkMakeLive()" v-if="showOption"
                      >Make Selected Latest Versions Live</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="shouldDisable('edit_items')" @click="bulkMakeAllLive()"
                      >Make All Latest Versions Live</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-b-modal.bulk-custom-modal
                      :disabled="shouldDisable('edit_item_meta')"
                      v-if="showOption && isPremium"
                      >Change custom field values</b-dropdown-item
                    >
                    <b-dropdown-item
                      :disabled="shouldDisable(['edit_items', 'edit_item_meta'])"
                      @click="$refs.refItemSettingsBulk.showModal()"
                      v-if="showOption && isPremium"
                      >Change settings</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    size="sm" class="ml-1"
                    :disabled="filtering"
                    text="Group By"
                    v-if="groupOptions.length"
                    variant="white"
                  >
                    <b-dropdown-item @click="removeGroups()" v-if="isGrouped()"
                      >Remove Groups</b-dropdown-item
                    >
                    <b-dropdown-item
                      :key="index"
                      @click="getItemsByMeta(option.value)"
                      v-for="(option, index) of groupOptions">
                        {{ option.text }}
                        <font-awesome-icon
                          icon="check"
                          v-if="sessionItems.groupBy === option.value"
                        ></font-awesome-icon>
                      </b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown right text="Sort By" variant="white" size="sm" class="ml-1">
                    <b-dropdown-item @click="changeSort('name')">
                      Name
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'name'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('created_at')">
                      Created
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'created_at'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('edited_at')">
                      Edited
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'edited_at'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('num_responses')">
                      Responses
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'num_responses'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('avg_score')">
                      P Value
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'avg_score'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('correlation')">
                      Correlation
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'correlation'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('discrimination')">
                      Discrimination
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'discrimination'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeSort('avg_seconds')">
                      Duration
                      <font-awesome-icon
                        icon="check"
                        v-if="sortBy === 'avg_seconds'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    right
                    class="ml-1"
                    text="Order"
                    variant="white"
                    size="sm"
                  >
                    <b-dropdown-item @click="changeOrder('')">
                      Ascending
                      <font-awesome-icon
                        icon="check"
                        v-if="sortOrder === ''"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeOrder('-')">
                      Descending
                      <font-awesome-icon
                        icon="check"
                        v-if="sortOrder === '-'"
                      ></font-awesome-icon>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button
                    :disabled="filtering"
                    @click="clearSearch"
                    variant="white"
                    v-if="queryKey"
                    size="sm"
                    class="ml-1"
                    >Reset</b-button
                  >
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col v-if="hasGroups">
                <b-button @click="changeAllCollapses" size="sm" variant="link">{{
                  expandOrCollapse
                }}</b-button>
                <div
                  v-for="(group, index) in itemGroups"
                  :key="index"
                  class="mb-4"
                >
                  <div v-if="group.isDivider">
                    <h4 class="border-bottom d-flex align-items-center">
                      <b-checkbox
                        :style="{ paddingTop: '5px' }"
                        @change="checkAllItemsInDivider($event, index)"
                        :checked="allItemsInDividerChecked(index)"
                      />
                      <span
                        :style="{ cursor: 'pointer' }"
                        class="pl-1"
                        @click="collapseDividerGroup(group)"
                      >
                        {{ group.title }}
                      </span>
                    </h4>
                    <b-card
                      :key="subgroupIndex"
                      class="mb-1"
                      no-body
                      v-for="(subgroup, subgroupIndex) in group.groups"
                    >
                      <b-card-header class="p-1">
                        <b-button
                          block
                          class="text-left px-3 py-2 d-flex justify-content-between align-items-center"
                          size="lg"
                          variant="primary"
                          @click="onCollapseChange(subgroup.key)"
                        >
                          <div>
                            <div
                              @click.stop
                              class="d-inline"
                              v-if="subgroup.items.length"
                            >
                              <b-form-checkbox
                                :checked="
                                  allItemsInGroupChecked(subgroup.items)
                                "
                                @change="
                                  checkAllItemsInGroup($event, subgroup.items)
                                "
                                class="d-inline position-relative"
                                style="bottom: -4px;"
                              />
                            </div>
                            <span :style="{ overflow: 'wrap' }">
                              {{ subgroup.text }} ({{ subgroup.items.length }})
                            </span>
                          </div>
                          <div v-if="subgroup.target" class="ml-4">
                            Target:
                            <b-badge
                              :variant="
                                subgroup.target.complete
                                  ? 'secondary'
                                  : 'danger'
                              "
                            >
                              {{ subgroup.target.text }}
                            </b-badge>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="index + 'subgroup' + subgroupIndex"
                        :visible="isVisible(subgroup.key)"
                        :data-divider="index"
                      >
                        <b-card-body
                          :style="{ overflowX: 'hidden' }"
                          class="p-0"
                        >
                          <ItemsTable
                            :checked="checked"
                            :filtering="filtering"
                            :items="subgroup.items"
                            :preview-fields="previewFields"
                            :preview-option-lookup="subOptionLookup"
                            :shouldDisable="shouldDisable('edit_items')"
                            v-if="subgroup.items.length"
                          />
                          <b-button
                            :disabled="shouldDisable('create_items')"
                            class="m-2"
                            size="sm"
                            variant="link"
                            v-if="showAddItem"
                            @click="
                              createNewItemFromGroup(
                                subgroup.fieldId,
                                subgroup.optionId
                              )
                            "
                            >Add Item</b-button
                          >
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                  <div v-else>
                    <b-card class="mb-1" no-body>
                      <b-card-header class="p-1">
                        <b-button
                          block
                          class="text-left px-3 py-2 d-flex justify-content-between align-items-center"
                          size="lg"
                          variant="primary"
                          @click="onCollapseChange(group.key)"
                        >
                          <div>
                            <div
                              @click.stop
                              class="d-inline"
                              v-if="group.items.length"
                            >
                              <b-form-checkbox
                                :checked="allItemsInGroupChecked(group.items)"
                                @change="
                                  checkAllItemsInGroup($event, group.items)
                                "
                                class="d-inline position-relative"
                                style="bottom: -4px;"
                              />
                            </div>
                            <span :style="{ overflow: 'wrap' }">
                              {{ group.text }} ({{ group.items.length }}) {{ lastGroupHasNext && (itemGroups.length - 1 === index) ? '(Continued on next page)' : '' }}
                            </span>
                          </div>
                          <div v-if="group.target" class="ml-4">
                            Target:
                            <b-badge
                              :variant="
                                group.target.complete ? 'secondary' : 'danger'
                              "
                            >
                              {{ group.target.text }}
                            </b-badge>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse :id="'group' + index" :visible="isVisible(group.key)">
                        <b-card-body
                          :style="{ overflowX: 'hidden' }"
                          class="p-0"
                        >
                          <ItemsTable
                            :checked="checked"
                            :filtering="filtering"
                            :items="group.items"
                            :preview-fields="previewFields"
                            :preview-option-lookup="subOptionLookup"
                            :shouldDisable="shouldDisable('edit_items')"
                            v-if="group.items.length"
                          />
                          <b-button
                            :disabled="shouldDisable('create_items')"
                            class="m-2"
                            size="sm"
                            variant="link"
                            v-if="showAddItem && !group.noGroup"
                            @click="
                              createNewItemFromGroup(
                                group.fieldId,
                                group.optionId
                              )
                            "
                            >Add Item</b-button
                          >
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </b-col>
              <b-col v-else :style="{ overflowX: 'hidden' }">
                <ItemsTable
                  :checked="checked"
                  :filtering="filtering"
                  :items="items"
                  :preview-fields="previewFields"
                  :preview-option-lookup="subOptionLookup"
                  :shouldDisable="shouldDisable('edit_items')"
                />
              </b-col>
            </b-row>
          </div>
          <Spinner v-else />
        </b-container>
      </div>
      <b-container class="mt-3" fluid v-else>
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          variant="danger"
        >
          Failed to load items.
          <b-button @click="reloadItems()" variant="danger">Reload</b-button>
        </b-alert>
      </b-container>
    </div>
    <Spinner v-if="loading" />
    <b-sidebar
      backdrop
      backdrop-variant="black"
      bg-variant="light"
      id="sidebar-filters"
      title="Filter Items"
      width="75%"
      right
      shadow
      sidebar-class="border-left border-medium"
    >
      <b-container fluid>
        <b-form-group
          id="saved-searches"
          label="Load a Saved Search"
          label-for="savedsearchselect"
        >
          <b-select
            @change="onSaveSearchChange"
            :options="savedSearchOptions"
            v-model="sessionItems.savedSearchId"
          />
        </b-form-group>
        <b-card class="p-4" no-body>
          <b-input-group>
            <b-form-input
              @keyup.enter="applyFilters()"
              autofocus
              placeholder="Search Items"
              v-model="sessionItems.filters.search"
              class="mr-1"
            />
            <b-button
              :disabled="filtering"
              @click="cancelSearch()"
              key="search-cancel-btn"
              size="sm"
              variant="white"
              >Clear</b-button
            >
          </b-input-group>
          <div v-show="isPremium">
            <div class="my-3">
              <ItemFilters
                :existing-filters="sessionItems.filters"
                :only-create-filters="true"
                :project="project"
                :showPreview="false"
                @filters-changed="setFilters"
                @items-changed="setItemIds"
                @query-key="setQueryKey"
                class="mb-3"
                ref="filters"
              />
            </div>
            <b-button-group>
              <b-button variant="secondary" @click="applyFilters()"
                >Run Search</b-button
              >
              <b-button variant="white" @click="clearFilters">Clear</b-button>
            </b-button-group>
          </div>
        </b-card>
        <b-form-group
          id="fieldset-1"
          label="Save This Search"
          label-for="savesearch"
          class="mt-3"
          v-if="!sessionItems.savedSearchId"
        >
          <b-input-group>
            <b-form-input
              id="savesearch"
              class="mr-1"
              placeholder="Name for Saved Search"
              v-model="sessionItems.filters.name"
            ></b-form-input>
            <b-button @click="saveNewSearch()" :disabled="savingSearch">
              <b-spinner
                label="Small Spinner"
                small
                v-show="savingSearch"
              ></b-spinner
              >&nbsp;Save
            </b-button>
          </b-input-group>
        </b-form-group>
        <b-form-group
          id="updatesearch"
          label="Update This Search"
          label-for="savesearch"
          class="mt-3"
          v-else
        >
          <b-input-group>
            <b-form-input
              id="updatesearch"
              class="mr-1"
              placeholder="Name for Saved Search"
              v-model="sessionItems.filters.name"
            ></b-form-input>
            <b-button
              variant="primary-light"
              @click="saveSearch(sessionItems.savedSearchId)"
              :disabled="savingSearch"
            >
              <b-spinner
                label="Small Spinner"
                small
                v-show="savingSearch"
              ></b-spinner
              >&nbsp;Update Saved Search
            </b-button>
          </b-input-group>
          <b-button
            class="pl-0 mt-2"
            variant="link"
            size="sm"
            @click="deleteSearch"
            >Delete this saved search</b-button
          >
        </b-form-group>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
  import { deepCopy, friendlyName, formatItemsForPreview } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { HTTP } from '../../utils/requests'
  import { SEI_API_BASE } from '../../utils/constants'
  import { SESSION } from '../../utils/session'
  import { initMetaHelper } from '../../utils/meta-helper'

  import get from 'lodash.get'
  import { v4 as uuidv4 } from 'uuid'

  import ItemsTable from './ItemsTable'
  import Spinner from '../Spinner'
  import ItemFilters from './ItemFilters'
  import Resource from './Resource'
  import AnalyticsDevelopment from './AnalyticsDevelopment'
  import ItemSettingsBulk from './ItemSettingsBulk'

  
  const HIGHLIGHT_ROW_COLOR = '#9E5DA32E'

  const NON_META_FIELD_GROUP = ['author', 'item_type', 'last_edited_by', 'form']

  async function getListOfItems(
    projectId,
    page,
    perPage,
    search,
    queryKey,
    sortBy,
    sortOrder,
    metaFieldId
  ) {
    try {
      let route = 'items'
      if (metaFieldId) {
        if (NON_META_FIELD_GROUP.includes(metaFieldId))
          route += `/group/${metaFieldId}`
        else route += `/group_meta/${metaFieldId}`
      }

      let url = `${SEI_API_BASE}/exams/${projectId}/${route}?per_page=${perPage}&page=${page}&sort=${sortOrder}${sortBy}&only=id,name,latest_item_type,live_version_number,num_versions,preview_fields,has_enemy,has_file,has_reference,has_shared,created_at,edited_at,stats,forms_info`

      if (search) {
        url += `&search=${search}`
      }

      if (queryKey) {
        url += `&query_key=${queryKey}`
      }

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  const MAX_ATTEMPTS = 25
  const POLLING_INTERVAL_MS = 1500

  async function bulkDeleteRequest(projectId, ids) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/bulk_delete`
      const payload = {
        item_ids: ids
      }
      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function makeLiveRequest(projectId, itemId, copyTranslations) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/${itemId}/activate_version`
      const payload = {
        version_number: -1,
        copy_translations: copyTranslations
      }
      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function bulkMakeAllLiveRequest(projectId, copyTranslations) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/activate_all_latest_versions?copy_translations=${ copyTranslations }`
      const response = await HTTP.get(url)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getJobData(jobId) {
    try {
      const url = `${SEI_API_BASE}/jobs/${jobId}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function bulkUpdateCustomFieldsRequest(
    projectId,
    itemIds,
    customFieldId,
    customFieldValue
  ) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/items/bulk_update_meta`
      const payload = {
        item_ids: itemIds,
        custom_field: {
          id: customFieldId,
          value: customFieldValue
        }
      }

      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  function getFields(project) {
    const fields = []
    const previewFields = []
    const customFields = get(project, 'meta.scorpion.custom')
    const customFieldOrder = get(project, 'meta.scorpion.custom_field_order')

    if (customFields && customFieldOrder) {
      for (const customFieldId of customFieldOrder) {
        const customField = customFields[customFieldId]

        if (customField.by_option) {
          continue
        }

        if (customField.preview) {
          let previewKey = customField.id.toString()
          if (!customField.type.startsWith('select_')) {
            previewKey = 'preview_fields.' + customField.id.toString()
          }
          previewFields.push({
            key: previewKey,
            label: customField.name,
            isSelect: customField.type.startsWith('select_')
          })
        }

        fields.push(customField)
      }
    }

    return { fields, previewFields }
  }

  function getBulkUpdateOptions(fields) {
    const options = [{ text: 'Select', value: null, disabled: true }]

    for (const field of fields) {
      options.push({
        text: field.name,
        value: field.id
      })
    }

    return options
  }

  function getSubOptions(fields) {
    const lookup = {}

    for (const field of fields) {
      if (field.options) {
        const options = [{ text: 'Select', value: '', disabled: true }]

        for (const optionId of field.option_order) {
          const option = field.options[optionId]

          options.push({
            text: option.text,
            value: option.id,
            disabled: option.is_divider,
            color: option.color
          })
        }

        lookup[field.id] = options
      }
    }

    return lookup
  }

  async function savePreferences(preferences) {
    try {
      const url = `${SEI_API_BASE}/preferences`
      const payload = { preferences }
      const response = await HTTP.put(url, payload)
      return { data: response }
    } catch (error) {
      return { error }
    }
  }

  const GROUP_OPTIONS = ['select_one']

  export default {
    name: 'Items',
    components: {
      ItemsTable,
      Spinner,
      ItemFilters,
      Resource,
      AnalyticsDevelopment,
      ItemSettingsBulk
    },
    props: {
      project: {
        type: Object
      },
      isPremium: {
        type: Boolean
      }
    },
    created() {
      this.metaHelper = initMetaHelper(this.project)
      const { fields, previewFields } = getFields(this.project)
      this.previewFields = previewFields
      this.bulkUpdateOptions = getBulkUpdateOptions(fields)
      this.subOptionLookup = getSubOptions(fields)
      this.preserveFilters()
      this.createGroupOptions()
      this.createSavedSearchOptions()
      if (!SESSION.hasItemFilters()) {
        this.getItems()
      }
    },
    mounted() {
      if (SESSION.hasItemFilters()) {
        this.applyFilters(false)
      }
    },
    data() {
      return {
        allItems: [],
        items: [],
        itemCount: 0,
        itemGroups: [],
        noGroupItems: [],
        checked: {
          ids: []
        },
        groupOptions: [],
        savedSearchOptions: [],
        savingSearch: false,
        previewFields: [],
        bulkUpdateOptions: [],
        previewValue: {
          notMulti: null,
          multi: []
        },
        selectedPreviewOption: null,
        subOptionLookup: {},
        loading: true,
        error: false,
        filtering: false,
        saving: false,
        search: false,
        interval: null,
        attempts: 0,
        expand: true,
        selectedMeta: ['all'],
        selectedItemIds: [],
        groupByOrder: [],
        perPage: SESSION.items.perPage || 200,
        rows: 0,
        totalPages: 0,
        sortBy: SESSION.items.sortBy || 'name',
        sortOrder: SESSION.items.sortOrder || '',
        queryKey: SESSION.items.queryKey || '',
        sessionItems: SESSION.items,
        metaHelper: null,
        searchText: '',
        lastGroupHasNext: false
      }
    },
    methods: {
      async getItems(isSimple = false) {
        this.filtering = true
        this.checked.ids = []

        this.sessionItems.lookup = []
        this.sessionItems.perPage = this.perPage
        this.sessionItems.queryKey = this.queryKey
        this.sessionItems.sortBy = this.sortBy
        this.sessionItems.sortOrder = this.sortOrder

        let results
        if (isSimple) {
          results  = await getListOfItems(
            this.$route.params.projectId,
            1,
            this.perPage,
            this.searchText,
            '',
            this.sortBy,
            this.sortOrder,
            this.sessionItems.groupBy
          )
        } else {
          results = await getListOfItems(
            this.$route.params.projectId,
            this.sessionItems.currentPage,
            this.perPage,
            this.sessionItems.filters.search,
            this.queryKey,
            this.sortBy,
            this.sortOrder,
            this.sessionItems.groupBy
          )
          this.searchText = ''
        }

        const { error, data } = results
        if (error) {
          this.error = true
          this.loading = false
          return (this.filtering = false)
        }

        this.lastGroupHasNext = data.last_group_has_next

        const customFields = get(this.project, 'meta.scorpion.custom')
        const groupBy = get(SESSION, 'items.groupBy')

        if (groupBy) {
          const itemGroups = []

          if (NON_META_FIELD_GROUP.includes(groupBy)) {
            for (const group of data.results) {
              const items = formatItemsForPreview(group.value, customFields)
              itemGroups.push({
                key: group.key,
                text: friendlyName(group.key),
                items
              })
              this.sessionItems.lookup.push(...items)
            }
          } else {
            for (const group of data.results) {
              const items = formatItemsForPreview(group.value, customFields)
              this.sessionItems.lookup.push(...items)

              if (group.key === 'no_group') {
                itemGroups.push({
                  key: 'no_group',
                  items,
                  text: 'No Group',
                  target: null,
                  noGroup: true
                })
                continue
              }

              const field = this.metaHelper.getField(groupBy)
              const option = field.options[group.key]
              const { text, target } = option

              let groupTarget
              if (target) {
                groupTarget = {
                  text: `${group.count} / ${target}`,
                  complete: group.count >= target
                }
              }

              const formattedGroup = {
                key: group.key,
                items,
                text,
                target: groupTarget,
                fieldId: field.id,
                optionId: option.id
              }

              if (group.section_id) {
                const lastElement = itemGroups[itemGroups.length - 1]
                if (
                  !lastElement ||
                  lastElement.sectionId !== group.section_id
                ) {
                  const divider = field.options[group.section_id]
                  itemGroups.push({
                    sectionId: group.section_id,
                    isDivider: true,
                    title: divider.text,
                    groups: [formattedGroup]
                  })
                } else {
                  lastElement.groups.push(formattedGroup)
                }
              } else {
                itemGroups.push(formattedGroup)
              }
            }
          }

          this.itemGroups = itemGroups
        } else {
          this.items = formatItemsForPreview(data.results)
          this.sessionItems.lookup = this.items
        }

        this.rows = data.total
        this.totalPages = Math.ceil(this.rows / this.perPage)
        this.sessionItems.totalPages = this.totalPages
        this.error = false
        this.loading = false
        this.filtering = false

        this.$nextTick(() => {
          const scrollTo = document.getElementById('scroll-to-item')
          const scrollToDetails = document.querySelector('[data-highlight]')
          if (scrollTo) {
            const row = scrollTo.parentElement.parentElement
            const rowDetails = scrollToDetails.parentElement.parentElement
            row.style.backgroundColor = HIGHLIGHT_ROW_COLOR
            rowDetails.style.backgroundColor = HIGHLIGHT_ROW_COLOR

            scrollTo.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })

            SESSION.scrollToItem = null
          }
        })
      },
      reloadItems() {
        this.loading = true
        this.getItems()
      },
      addItem() {
        this.$router.push({
          name: 'projectItem',
          params: { itemId: 'new' }
        })
      },
      createGroupOptions() {
        const options = []
        const customFields = get(this.project, 'meta.scorpion')

        if (customFields) {
          const { custom_field_order, custom } = customFields

          if (custom_field_order && custom_field_order.length) {
            for (const fieldId of custom_field_order) {
              const field = custom[fieldId]

              if (GROUP_OPTIONS.includes(field.type) && !field.by_option) {
                options.push({ text: field.name, value: field.id })
              }
            }
          }
        }

        options.push(
          { text: 'Item Type', value: 'item_type' },
          { text: 'Author', value: 'author' },
          { text: 'Last Edited By', value: 'last_edited_by' },
          { text: 'Form', value: 'form' }
        )
        this.groupOptions = options
      },
      onSaveSearchChange(id) {
        this.sessionItems.savedSearchId = id

        if (!id) {
          return this.clearSavedSearch()
        }

        const savedSearch =
          deepCopy(SESSION.preferences.saved_searches[SESSION.project.id][id])
        this.sessionItems.filters = savedSearch

        this.sessionItems.filters.name = savedSearch.name
        this.$refs.filters.setExistingFilters()
      },
      createSavedSearchOptions() {
        const savedSearches = get(
          SESSION.preferences,
          `saved_searches.${SESSION.project.id}`,
          {}
        )
        const options = [{ text: 'None', value: null }]

        for (const [id, search] of Object.entries(savedSearches)) {
          options.push({ text: search.name, value: id })
        }

        this.savedSearchOptions = options
      },
      saveNewSearch() {
        if (!SESSION.preferences.saved_searches) {
          SESSION.preferences.saved_searches = {}
        }
        if (!SESSION.preferences.saved_searches[SESSION.project.id]) {
          SESSION.preferences.saved_searches[SESSION.project.id] = {}
        }
        this.saveSearch(uuidv4(), true)
      },
      async saveSearch(id, isNew = false) {
        this.$refs.filters.handleFiltersChanged()
        const savedSearches =
          SESSION.preferences.saved_searches[SESSION.project.id]
        savedSearches[id] = {
          ...this.sessionItems.filters,
          name: this.sessionItems.filters.name
        }

        this.savingSearch = true
        const { error } = await savePreferences(SESSION.preferences)
        this.savingSearch = false

        if (error) {
          if (isNew) {
            delete savedSearches[id]
          }
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to save search.'
          })
        }

        this.createSavedSearchOptions()

        if (isNew) {
          this.sessionItems.savedSearchId = id
        }
      },
      async deleteSearch() {
        const savedSearches =
          SESSION.preferences.saved_searches[SESSION.project.id]
        const searchId = this.sessionItems.savedSearchId
        const savedSearch = savedSearches[searchId]

        delete savedSearches[searchId]

        this.savingSearch = true
        const { error } = await savePreferences(SESSION.preferences)
        this.savingSearch = false

        if (error) {
          savedSearches[searchId] = savedSearch
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete search.'
          })
        }

        this.sessionItems.savedSearchId = null
        this.clearSavedSearch()
        this.createSavedSearchOptions()
      },
      clearSavedSearch() {
        this.sessionItems.filters.name = ''
        SESSION.clearItemFilters()
        this.$refs.filters.setExistingFilters()
      },
      async getItemsByMeta(metaFieldId) {
        await this.$refs.filters.applyFilters()
        this.sessionItems.groupBy = metaFieldId
        this.sessionItems.collapsedGroups = []
        this.sessionItems.currentPage = 1
        this.getItems()
      },
      async removeGroups() {
        await this.$refs.filters.applyFilters()
        this.itemGroups = {}
        this.groupByOrder = []
        this.sessionItems.groupBy = null
        this.sessionItems.collapsedGroups = []
        this.sessionItems.currentPage = 1

        this.getItems()
      },
      checkAllItemsInDivider(checkAll, index) {
        const dividerGroup = this.itemGroups[index]

        if (checkAll) {
          for (const group of dividerGroup.groups) {
            for (const item of group.items) {
              if (!this.checked.ids.includes(item.id)) {
                this.checked.ids.push(item.id)
              }
            }
          }
          return
        }

        for (const group of dividerGroup.groups) {
          for (const item of group.items) {
            const indexToRemove = this.checked.ids.findIndex(
              checked => checked === item.id
            )

            if (indexToRemove !== -1) {
              this.checked.ids.splice(indexToRemove, 1)
            }
          }
        }
      },
      checkAllItemsInGroup(checkAll, items) {
        if (checkAll) {
          for (const item of items) {
            if (!this.checked.ids.includes(item.id)) {
              this.checked.ids.push(item.id)
            }
          }
          return
        }

        for (const item of items) {
          const indexToRemove = this.checked.ids.findIndex(
            checked => checked === item.id
          )
          if (indexToRemove !== -1) {
            this.checked.ids.splice(indexToRemove, 1)
          }
        }
      },
      allItemsInDividerChecked(index) {
        const dividerGroup = this.itemGroups[index]
        let count = 0
        let total = 0

        for (const group of dividerGroup.groups) {
          for (const item of group.items) {
            if (this.checked.ids.includes(item.id)) {
              count++
            }
          }
          total += group.items.length
        }

        return total ? total === count : false
      },
      allItemsInGroupChecked(items) {
        let count = 0
        for (const item of items) {
          if (this.checked.ids.includes(item.id)) {
            count++
          }
        }

        return count === items.length
      },
      preserveFilters() {
        if (this.sessionItems.search) {
          this.search = true
        }
      },
      showSearch() {
        this.search = true
      },
      searchFilter(item) {
        const search = this.searchText.toLowerCase()
        const name = item.name.toLowerCase()
        const preview = item.preview_fields.stem.toLowerCase()

        return name.includes(search) || preview.includes(search)
      },
      searchItems() {
        this.sessionItems.currentPage = 1
        this.getItems()
      },
      cancelSearch() {
        this.sessionItems.filters.search = ''
      },
      async bulkDelete() {
        const confirmed = confirm(
          'Are you sure you want to delete the selected items?'
        )

        if (!confirmed) {
          return
        }

        this.filtering = true

        const { error } = await bulkDeleteRequest(
          this.project.id,
          this.checked.ids,
          'bulk_delete'
        )

        if (error) {
          this.filtering = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to delete selected items.'
          })
        }

        this.getItems()
      },
      removeFilter(item) {
        return !this.checked.ids.includes(item.id)
      },
      removeDeletedItems() {
        this.allItems = this.allItems.filter(this.removeFilter)
        this.items = this.items.filter(this.removeFilter)

        let count = this.items.length

        for (const groupId in this.sessionItems.groups) {
          const group = this.sessionItems.groups[groupId]
          group.items = group.items.filter(this.removeFilter)
        }

        for (const groupId in this.itemGroups) {
          const group = this.itemGroups[groupId]
          group.items = group.items.filter(this.removeFilter)
          count += group.items.length
        }

        this.itemCount = count
      },
      async bulkMakeLive() {
        const message = 'Would you like to copy over item translations from the current live version?'

        const options = {
            title: 'Copy Translations',
            okVariant: 'secondary',
            okTitle: 'Yes',
            cancelVariant: 'white',
            cancelTitle: 'No',
            size: 'sm',
            noFade: true
        }

        const copyTranslations = await this.$bvModal.msgBoxConfirm(message, options)

        this.filtering = true

        let err = false

        for (const itemId of this.checked.ids) {
          const { error } = await makeLiveRequest(this.project.id, itemId, copyTranslations)

          if (error) {
            err = true
          }
        }

        if (err) {
          this.filtering = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to update selected items.'
          })
        }

        this.refreshItems()
      },
      async bulkMakeAllLive() {
        const message = 'Would you like to copy over item translations from the current live version?'

        const options = {
            title: 'Copy Translations',
            okVariant: 'secondary',
            okTitle: 'Yes',
            cancelVariant: 'white',
            cancelTitle: 'No',
            size: 'sm',
            noFade: true
        }

        const copyTranslations = await this.$bvModal.msgBoxConfirm(message, options)

        this.filtering = true

        const { data, error } = await bulkMakeAllLiveRequest(this.project.id, copyTranslations)

        if (error) {
          this.filtering = false
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to update all items.'
          })
        }

        this.poll(data.job_id)
      },
      refreshItems() {
        this.checked.ids = []
        this.getItems()
      },
      async poll(jobId) {
        this.attempts = 0

        this.interval = setInterval(async () => {
          try {
            if (this.attempts > MAX_ATTEMPTS) {
              throw new Error('Max attempts reached.')
            }

            const { data, error } = await getJobData(jobId)

            this.attempts++

            if (error) {
              throw new Error(error)
            }

            if (data.status === 'failed') {
              throw new Error(data.status)
            }

            if (data.status === 'finished') {
              clearInterval(this.interval)
              return this.refreshItems()
            }
          } catch (error) {
            this.filtering = false
            clearInterval(this.interval)
            EVENT.alert({
              variant: 'danger',
              message: 'Failed to update items.'
            })
          }
        }, POLLING_INTERVAL_MS)
      },
      isGrouped() {
        return Boolean(Object.keys(this.itemGroups).length)
      },
      updateCheckboxes(updatedCheckBoxes, type) {
        if (updatedCheckBoxes[0] === 'all' && updatedCheckBoxes.length > 1) {
          updatedCheckBoxes.shift()
        }
        if (updatedCheckBoxes.includes('all') || !updatedCheckBoxes.length) {
          updatedCheckBoxes = ['all']
        }
        this[type] = updatedCheckBoxes
      },
      setItemIds(itemIds) {
        this.selectedItemIds = itemIds
      },
      setFilters(filters) {
        Object.assign(this.sessionItems.filters, filters)
      },
      setQueryKey(queryKey) {
        this.queryKey = queryKey
      },
      async applyFilters(clear = true) {
        await this.$refs.filters.applyFilters()

        if (clear) {
          this.sessionItems.currentPage = 1
          this.sessionItems.collapsedGroups = []
        }

        if (!this.selectedItemIds.length) {
          return this.displayNoItems()
        }

        this.getItems()
      },
      clearFilters() {
        this.perPage = 200
        this.queryKey = ''
        this.sortBy = 'name'
        this.sortOrder = ''

        this.sessionItems.currentPage = 1
        this.sessionItems.collapsedGroups = []
        this.sessionItems.filters.search = ''
        this.$refs.filters.clearFilters()
        
        this.getItems()
      },
      displayNoItems() {
        this.rows = 0
        this.totalPages = 0
        this.items = []
        this.itemGroups = []
        this.noGroupItems = []
        this.sessionItems.lookup = []
        this.filtering = false
        this.loading = false
      },
      async bulkUpdateCustomFields() {
        let value = ''
        if (this.previewValue.notMulti) {
          value = this.previewValue.notMulti
        } else {
          value = this.previewValue.multi
        }

        this.saving = true

        const { error } = await bulkUpdateCustomFieldsRequest(
          this.project.id,
          this.checked.ids,
          this.selectedPreviewOption,
          value
        )

        this.saving = false
        this.$bvModal.hide('bulk-custom-modal')

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to update items.'
          })
        }

        this.clearSelectedIds()
        this.getItems()
      },
      selectAllIds() {
        if (this.sessionItems.groupBy) {
          const ids = []

          for (const group of this.itemGroups) {
            if (group.isDivider) {
              for (const subgroup of group.groups) {
                const itemIds = subgroup.items.map(item => item.id)
                ids.push(...itemIds)
              }
            } else {
              const itemIds = group.items.map(item => item.id)
              ids.push(...itemIds)
            }
          }

          return (this.checked.ids = ids)
        }

        this.checked.ids = this.items.map(item => item.id)
      },
      clearSelectedIds() {
        this.checked.ids = []
      },
      clearSelectedPreview() {
        this.selectedPreviewOption = null
      },
      clearSelectedSubOptions() {
        this.previewValue = {
          notMulti: null,
          multi: []
        }
      },
      display(type) {
        const customFields = get(this.project, 'meta.scorpion.custom')
        if (!customFields) {
          return false
        }

        const customField = customFields[this.selectedPreviewOption]
        return customField.type === type
      },
      onPageChange(page) {
        this.sessionItems.currentPage = page
        this.sessionItems.collapsedGroups = []
        this.getItems()
      },
      changeSort(property) {
        this.sortBy = property
        this.applyFilters()
      },
      changeOrder(order) {
        this.sortOrder = order
        this.applyFilters()
      },
      collapseDividerGroup(group) {
        for (const subgroup of group.groups) {
          this.onCollapseChange(subgroup.key)
        }
      },
      createNewItemFromGroup(fieldId, optionId) {
        this.$router.push({
          name: 'projectItem',
          params: { itemId: 'new' },
          query: { fieldId, optionId }
        })
      },
      onCollapseChange(key) {
        const { collapsedGroups } = this.sessionItems
        const index = collapsedGroups.indexOf(key)

        if (index !== -1) {
          collapsedGroups.splice(index, 1)
        } else {
          collapsedGroups.push(key)
        }
      },
      changeAllCollapses() {
        this.expand = !this.expand
        this.sessionItems.collapsedGroups = []

        if (!this.expand) {
          for (const group of this.itemGroups) {
            if (group.groups) {
              for (const subgroup of group.groups) {
                this.sessionItems.collapsedGroups.push(subgroup.key)
              }
            } else {
              this.sessionItems.collapsedGroups.push(group.key)
            }
          }
        }
      },
      isVisible(key) {
        return !this.sessionItems.collapsedGroups.includes(key)
      },
      clearSearch() {
        this.clearFilters()
      },
      simpleSearch() {
        this.getItems(true)
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      }
    },
    computed: {
      showOption() {
        return Boolean(this.checked.ids.length)
      },
      hideSelectAll() {
        return this.checked.ids.length === this.itemsLength
      },
      hasGroups() {
        return Boolean(Object.keys(this.itemGroups).length)
      },
      allMeta() {
        return this.selectedMeta.includes('all') || this.filtering
      },
      expandOrCollapse() {
        if (this.expand) return 'Collapse All'
        return 'Expand All'
      },
      modalSaveDisabled() {
        const { multi, notMulti } = this.previewValue
        const hasValue = multi.length || notMulti

        return this.saving || !this.selectedPreviewOption || !hasValue
      },
      itemsLength() {
        return this.sessionItems.lookup
      },
      showAddItem() {
        return !NON_META_FIELD_GROUP.includes(this.sessionItems.groupBy)
      },
      isAdmin() {
        return SESSION.isAdmin()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .top-button:hover {
    text-decoration: none;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    html,
    body {
      scroll-behavior: smooth;
    }
  }
</style>