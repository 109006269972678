<template>
  <div>
    <b-modal
      id="delivery-analytics-modal"
      size="xl"
      title="Delivery Analytics"
      @shown="toggleModalLoading(false)"
      @hidden="toggleModalLoading(true)"
    > 
      <AnalyticsDelivery v-if="!modalLoading" />
      <Spinner v-else />
      <template #modal-footer="{ cancel }">
          <b-button @click="cancel" variant="white">Close</b-button>
      </template>
    </b-modal>
    <div v-if="!loading">
      <b-navbar sticky variant="light">
        <span v-if="!search">
          <b-button
            :disabled="filtering || shouldDisable('create_deliveries')"
            @click="addDelivery()"
            variant="secondary"
            class="mr-2"
            >Add Delivery</b-button
          >
          <b-button variant="white" v-b-modal.delivery-analytics-modal class="mr-2">Analytics</b-button>
        </span>


        <b-button-group>
          <b-form-input
            placeholder="Search Items"
            @keyup.enter="simpleSearch"
            :disabled="filtering"
            v-model="simpleSearchText"
          />
          <b-button
            :disabled="filtering"
            @click="simpleSearch"
            variant="primary-light"
            >Search</b-button
          >
        </b-button-group>
        <b-button
            variant="white"
            class="ml-2"
           :disabled="filtering"
            v-b-toggle.sidebar-search
           style="cursor: pointer;"
            >Advanced</b-button
          >
        <b-button v-if="hasQueryKey || hasFilters" @click="clearSearch" variant="white" class="ml-2">Reset</b-button>
      </b-navbar>

      <b-sidebar
        backdrop
        backdrop-variant="black"
        id="sidebar-search"
        title="Search Items"
        width="75%"
        right
        shadow
        bg-variant="light"
        sidebar-class="border-left border-medium"
      >
        <b-container fluid>
          <b-card class="mb-3" no-body>
            <b-card-body class="py-0">
              <b-form-group class="mt-3" label="Delivery Status">
                <div
                  class="border-top border-bottom p-1"
                  style="max-height:200px; overflow: auto;"
                >
                  <b-form-checkbox
                    :disabled="allStatuses"
                    @change="updateCheckboxes($event, 'selectedStatuses')"
                    v-model="selectedStatuses"
                    value="all"
                    >All</b-form-checkbox
                  >
                  <b-form-checkbox-group
                    :disabled="filtering"
                    :options="statusOptions"
                    @change="updateCheckboxes($event, 'selectedStatuses')"
                    name="status"
                    stacked
                    v-model="selectedStatuses"
                  ></b-form-checkbox-group>
                </div>
              </b-form-group>

              <br />
            </b-card-body>
          </b-card>
          <b-card no-body class="p-3">
            <label>Search keywords</label>
            <b-form-input
              autofocus
              placeholder="Search Deliveries"
              v-model="searchText"
              class="mb-2"
            />
            <label>Search tags</label>
            <b-form-tags
              @input="onTagsInput"
              :value="searchTags"
              class="mb-2"
              remove-on-delete 
            />
            <label>Search meta</label>
            <b-input-group v-for="(metaObj, index) in searchMeta" :key="index" class="mb-2">
              <b-input v-model="metaObj.key" class="mr-1" placeholder="Enter Meta Key" />
              <b-input v-model="metaObj.value" class="ml-1" placeholder="Enter Meta Value" />
              <b-input-group-append>
                <b-button @click="removeMetaSearch(index)" class="ml-2" variant="white">
                  <font-awesome-icon icon="trash" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div>
              <b-button @click="addMetaSearch" variant="white" class="mt-2">
                Add
              </b-button>
            </div>
          </b-card>
          <br>
          <b-card>
            <DeliveriesSearch @ready="filterDeliveries" :project="project" ref="deliveriesSearch"></DeliveriesSearch>
          </b-card>
          <div class="d-flex py-4">
            <b-button
            @click="beforeFilterDeliveries()"
            variant="primary-light"
            class="my-4"
            :disabled="filtering"
            >Search</b-button
          >
          <b-button v-if="hasQueryKey || hasFilters" @click="clearSearch" variant="white" class="my-4 ml-2">Reset</b-button>
          </div>
        </b-container>
      </b-sidebar>

      <b-modal
        @hide="preventClose"
        id="add-delivery-modal"
        size="xl"
        title="Create a Test Delivery"
      > 
        <b-form @submit.prevent="onSubmit" novalidate id="create-delivery-form">
          <b-alert :show="deliveryCreationIsDisabled" variant="danger" class="d-flex justify-content-between align-items-center">
            Delivery creation is disabled.
            <b-button size="sm" variant="white" @click="openProjectSettings" v-if="isAdmin">Change Setting</b-button>
          </b-alert>
          <b-form-group
            :key="index"
            :label="schemaLabel(schema)"
            :label-for="`schema-input-${index}`"
            v-for="(schema, index) in unsavedDelivery.schema"
          >
            <b-form-input
              :id="`schema-input-${index}`"
              :required="schema.required"
              v-model="schema.value"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Tags"
            label-for="delivery-tags"
          >
            <tag-selector id="delivery-tags" :tags="project.tags" :selected="tags" @tags-updated="tagsUpdated" />
          </b-form-group>

          <b-checkbox v-if="showChangeSettings" @change="onChangeSettings">Change delivery settings</b-checkbox>

          <div v-if="changeSettings" class="pt-2 mt-2">
            <b-form-group
              label="Form"
              label-for="schema-form-input"
              class="checked-label mb-4"
            >
              <b-input-group class="dashed-line">
                <b-form-select
                  :options="formOptions"
                  class="mr-1"
                  id="schema-form-input"
                  :value="unsavedDelivery.form_id"
                  @change="onFormChange"
                ></b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-select
              :options="changeDeliverySettingsOptions"
              :value="selectedSettingsOption"
              @change="onSelectedSettingsChange"
            ></b-form-select>

            <div v-if="selectedSettingsOption === 'form'">
              <div v-if="!loadingForm" class="mt-3">
                <ConfigurationFields :configuration="formConfiguration" :key="configuration.id" hide-name collapsed />
              </div>
              <Spinner v-else />
            </div>

            <div v-else class="mt-3">
              <ConfigurationSelect 
                :configurations="configurations" 
                :selected-configuration="selectedConfiguration" 
                stack-name 
                @configuration-selected="onConfigurationSelected"
              />
              <ConfigurationFields :configuration="configuration" :key="configuration.id" hide-name collapsed />
            </div>
          </div>
        </b-form>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button-group>
            <b-button :disabled="saving" @click="cancel()" variant="white"
              >Cancel</b-button
            >
            <b-button
              :disabled="saving || deliveryCreationIsDisabled || shouldDisable('create_deliveries')"
              form="create-delivery-form"
              type="submit"
              variant="secondary"
            >
              <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
              >&nbsp;Create Delivery
            </b-button>
          </b-button-group>
        </template>
      </b-modal>
      <b-container class="mt-3" fluid>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <h3>Deliveries: {{ rows }}</h3>
              </b-col>
              <b-col>
                <div v-if="totalPages > 1">
                  <b-pagination
                    class="float-right"
                    :disabled="filtering"
                    :per-page="perPage"
                    :total-rows="rows"
                    aria-controls="my-table"
                    @change="onPageChange"
                    :value="currentPage"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>

            <DeliveriesTable
              :deliveries="deliveries"
              :fields="fieldsToDisplay"
              :filtering="filtering"
              :schema-fields="schemaFields"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @sort="createSort"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import { DEFAULT_CONFIGURATION, SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { deepCopy, removeEmptyKeys } from '../../utils/misc'
  import { EVENT } from '../../utils/event-bus'
  import { VALIDATE } from '../../utils/validate'

  import AnalyticsDelivery from './AnalyticsDelivery.vue'
  import ConfigurationSelect from './ConfigurationSelect'
  import ConfigurationFields from './ConfigurationFields'
  import DeliveriesTable from './DeliveriesTable'
  import DeliveriesSearch from './DeliveriesSearch'
  import Spinner from '../Spinner'
  import TagSelector from './TagSelector.vue'

  import get from 'lodash.get'

  async function getForms(projectId) {
    try {
      let url = `${SEI_API_BASE}/exams/${projectId}/forms?only=name,id,status,live_version_number&per_page=1000&page=1`
      const response = await HTTP.get(url)
      return response.data.results
    } catch (error) {
      return { error }
    }
  }

  async function getConfigurations(projectId) {
    let moreConfigurations = true
    let page = 1

    const configurations = []

    while (moreConfigurations) {
      try {
        const url = `${SEI_API_BASE}/exams/${projectId}/configurations?page=${page}`
        const response = await HTTP.get(url)
        configurations.push(...response.data.results)
        moreConfigurations = response.data.has_next
        page++
      } catch (error) {
        return { error }
      }
    }

    return configurations
  }

  async function getFormConfiguration(projectId, formId, liveVersionNumber) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/forms/${formId}?only=version&version_number=${liveVersionNumber}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Deliveries',
    components: {
      AnalyticsDelivery,
      ConfigurationSelect,
      ConfigurationFields,
      DeliveriesTable,
      DeliveriesSearch,
      Spinner,
      TagSelector
    },
    props: {
      project: {
        type: Object
      }
    },
    async created() {
      const { queryKey } = this.$route.query
      const deliveryParams = queryKey ? `&query_key=${queryKey}` : ''
      let [, forms, configurations] = await Promise.all([this.getDeliveries(deliveryParams, true), getForms(this.project.id), getConfigurations(this.project.id)])

      if (forms?.error?.response?.status === 403 || configurations?.error?.response?.status === 403) {
        this.showChangeSettings = false
        configurations = []
        forms = []
      }

      this.configurations = configurations

      const formLiveVersionNumberLookup = {}
      
      for (const form of forms) {
        formLiveVersionNumberLookup[form.id] = form.live_version_number
      }

      this.formLiveVersionNumberLookup = formLiveVersionNumberLookup

      this.formOptions = [
        { value: null, text: 'Use flow form selection' },
        ...forms
          .map(form => ({ value: form.id, text: form.name }))
      ]

      this.accommodationFormOptions = forms.map(form => ({ value: form.id, text: form.name }))
      this.loading = false
      this.hasQueryKey = queryKey && queryKey.length
    },
    data() {
      return {
        deliveriesUrl: `${SEI_API_BASE}/exams/${this.$route.params.projectId}/deliveries?include=examinee_preview`,
        deliveries: [],
        configurations: [],
        unsavedDelivery: {},
        accommodations: {},
        formLiveVersionNumberLookup: {},
        formConfiguration: {},
        configuration: {},
        tags: [],
        fieldsToDisplay: [
          { key: 'status' },
          { key: 'modified_at', label: 'Date' },
          { key: 'score' },
          { key: 'tags' }
        ],
        schemaFields: [],
        statusOptions: [
          { text: 'Fresh', value: 'fresh' },
          { text: 'In progress', value: 'in_progress' },
          { text: 'Manual scoring', value: 'manual_scoring' },
          { text: 'Complete', value: 'complete' },
          { text: 'Suspended', value: 'suspended' },
          { text: 'Needs review', value: 'needs_review' },
          { text: 'Abandoned', value: 'abandoned' }
        ],
        changeDeliverySettingsOptions: [
          { text: 'Edit current settings on form', value: 'form' },
          { text: 'Copy settings from a configuration', value: 'configuration' }
        ],
        tagOptions: [],
        formOptions: [],
        configurationOptions: [],
        selectedStatuses: ['all'],
        selectedSettingsOption: '',
        selectedConfiguration: '',
        search: false,
        searchText: '',
        searchTags: [],
        searchMeta: [],
        sortBy: '',
        sortDirection: '',
        totalPages: 0,
        perPage: 200,
        rows: 0,
        currentPage: 1,
        loading: true,
        loadingForm: false,
        filtering: true,
        saving: false,
        hasForm: false,
        hasAccommodations: false,
        hasTags: false,
        accommodationFormOptions: [],
        hasFilters: false,
        changeSettings: false,
        modalLoading: true,
        simpleSearchText: '',
        showChangeSettings: true
      }
    },
    beforeRouteLeave(to, from, next) {
      SESSION.deliveryLookup = this.deliveries

      if (to.name !== 'projectDelivery') {
        SESSION.deliveryLookup = null
      }
      next()
    },
    methods: {
      async getDeliveries(params = '', initial = false) {
        try {
          this.filtering = true
          const url = `${this.deliveriesUrl}&page=${this.currentPage}&per_page=${this.perPage}${params}`
          const response = await HTTP.get(url)
          this.rows = response.data.total
          this.totalPages = Math.ceil(this.rows / this.perPage)
          this.formatData(response.data.results)
          this.addSchemaFields()
          if (initial) {
            this.createTagOptions()
          }
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to load deliveries.'
          })
        } finally {
          this.filtering = false
        }
      },
      toggleModalLoading(value) {
        this.modalLoading = value
      },
      beforeFilterDeliveries (clearPage = true) {
        this.filtering = true

        if (clearPage) {
          this.currentPage = 1
        }

        this.$refs.deliveriesSearch.report()
      },
      filterDeliveries(queryKey) {
        let hasFilters = false
        let status = ''
        if (!this.selectedStatuses.includes('all')) {
          status = this.selectedStatuses.join(',')
          hasFilters = true
        }
        let tags = ''
        if (this.searchTags.length) {
          tags = this.searchTags.join(',')
          hasFilters = true
        }
        if (queryKey) {
          hasFilters = true
        }

        let meta = ''

        const searchMetaWithValues = this.searchMeta.filter(meta => meta.key && meta.value)

        if (searchMetaWithValues.length) {
          const metaStrings = []

          for (const meta of searchMetaWithValues) {
            const metaString = `${ meta.key }||${ meta.value }`

            metaStrings.push(metaString)
          }

          meta = metaStrings.join(',')

          hasFilters = true
        }

        let params = `&status=${status}&tags=${tags}&meta=${meta}&search=${
          this.searchText
        }&sort=${this.sortDirection + this.sortBy}`

        if (queryKey) {
          params += `&query_key=${queryKey}`
        }

        this.hasFilters = hasFilters || this.searchText.length
        this.getDeliveries(params)
        this.simpleSearchText = ''
      },
      onPageChange (page) {
        this.currentPage = page

        this.beforeFilterDeliveries(false)
      },
      createSort(type) {
        if (this.sortBy === type) {
          this.sortDirection = this.sortDirection === '-' ? '' : '-'
        } else {
          this.sortBy = type
          this.sortDirection = ''
        }
        this.beforeFilterDeliveries()
      },
      showSearch() {
        this.search = true
      },
      cancelSearch() {
        this.search = false
        if (this.searchText || this.searchTags.length || this.searchMeta.length) {
          this.searchText = ''
          this.searchTags = []
          this.searchMeta = []
          this.beforeFilterDeliveries()
        }
      },
      addMetaSearch () {
        const searchMeta = {
          key: '',
          value: ''
        }

        this.searchMeta.push(searchMeta)
      },
      removeMetaSearch (index) {
        this.searchMeta.splice(index, 1)
      },
      updateCheckboxes(updatedCheckBoxes, type) {
        if (updatedCheckBoxes[0] === 'all' && updatedCheckBoxes.length > 1) {
          updatedCheckBoxes.shift()
        }
        if (updatedCheckBoxes.includes('all') || !updatedCheckBoxes.length) {
          updatedCheckBoxes = ['all']
        }
        this[type] = updatedCheckBoxes
      },
      addDelivery() {
        this.clearDelivery()
        const schema = this.project.settings.examinee_schema
        if (schema) {
          this.unsavedDelivery.schema = deepCopy(schema)
        } else {
          this.unsavedDelivery.schema = [{}]
        }
        this.$bvModal.show('add-delivery-modal')
      },
      clearDelivery() {
        this.unsavedDelivery = {
          form_id: null
        }

        if (this.showChangeSettings) {
          this.formConfiguration = deepCopy(DEFAULT_CONFIGURATION)
          const defaultConfiguration = this.configurations.find(config => config.is_default)
          this.configuration = deepCopy(defaultConfiguration)
          this.selectedConfiguration = defaultConfiguration.id
        }

        this.tags = []
        this.changeSettings = false
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.saveDelivery()
        }
      },
      async saveDelivery() {
        const payload = {}
        const data = {}
        for (const schema of this.unsavedDelivery.schema) {
          data[schema.key] = schema.value
        }
        payload.examinee_info = data
        if (this.accommodations && Object.keys(this.accommodations).length) {
          payload.accommodations = { ...this.accommodations }
        }
        if (this.unsavedDelivery.form_id) {
          payload.form_id = this.unsavedDelivery.form_id
        }
        if (this.tags.length) {
          payload.tags = this.tags
        }
        if (this.changeSettings) {
          if (this.selectedSettingsOption === 'form') {
            payload.settings = this.formConfiguration.settings
          } else {
            payload.settings = this.configuration.settings
          }
          removeEmptyKeys(payload.settings)
        }
        try {
          this.saving = true
          await HTTP.post(this.deliveriesUrl, payload)
          await this.getDeliveries()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to create delivery.'
          })
        } finally {
          this.saving = false
          this.$bvModal.hide('add-delivery-modal')
        }
      },
      preventClose(event) {
        if (this.saving) {
          event.preventDefault()
        }
      },
      formatData(deliveries) {
        for (const delivery of deliveries) {
          if (!delivery.tags) {
            delivery.tags = []
          }
          const { status, modified_at, tags } = delivery
          delivery._computed = {
            title: this.badgeTitle(status),
            variant: this.badgeVariant(status),
            date: this.getDate(modified_at),
            tags: this.getTags(tags),
            score: this.getScore(delivery)
          }
        }
        this.deliveries = deliveries
      },
      addSchemaFields() {
        const delivery = this.deliveries[0]
        if (!delivery) return
        this.schemaFields = delivery.examinee_preview.map(field => {
          const [key] = field
          return { key }
        })
        this.fieldsToDisplay.splice(1, 0, ...this.schemaFields)
      },
      createTagOptions() {
        const tags = new Set([])
        for (const delivery of this.deliveries) {
          for (const tag of delivery.tags) {
            tags.add(tag)
          }
        }
        this.tagOptions = Array.from(tags).map(tag => {
          return { text: tag, value: tag }
        })
      },
      showForm(value) {
        if (value) {
          this.unsavedDelivery.form_id = null
        } else {
          delete this.unsavedDelivery.form_id
        }

        this.hasForm = value
      },
      showAccommodations(value) {
        this.accommodations = {}
        this.hasAccommodations = value
      },
      showTags(value) {
        this.tags = []
        this.hasTags = value
      },
      onTagsInput (tags) {
        tags = tags.map(tag => tag.toLowerCase())

        this.searchTags = tags
      },
      removeForm() {
        delete this.unsavedDelivery.form_id
        this.hasForm = false
      },
      removeAccommodations() {
        this.accommodations = {}
        this.hasAccommodations = false
      },
      badgeTitle(status) {
        return status
          .split('_')
          .map(word => word[0].toUpperCase() + word.slice(1))
          .join(' ')
      },
      badgeVariant(status) {
        return status.replace('_', '-')
      },
      getDate(date) {
        return this.$moment
          .utc(date)
          .local()
          .format('l h:mma')
      },
      getTags(tags) {
        return tags.join(', ')
      },
      getScore(item) {
        if (item.score || item.score === 0) {
          return item.score.toFixed(2)
        }
        return item.status.replace('_', ' ')
      },
      schemaLabel(schema) {
        let label = schema.key

        if (schema.required) {
          label += ' *'
        }

        return label
      },
      clearAccommodation(type) {
        const currentAccommodations = { ...this.accommodations }
        delete currentAccommodations[type]

        this.accommodations = {
          ...currentAccommodations
        }
      },
      focusInput() {
        const input = document.querySelector('#delivery-tags input')
        this.$nextTick(() => {
          input.focus()
        })
      },
      addTag() {
        const input = document.querySelector('#delivery-tags input')
        const enterEvent = new KeyboardEvent('keydown', { keyCode: 13 })
        input.dispatchEvent(enterEvent)
      },
      openProjectSettings() {
        this.$router.push({
          name: 'projectSettings'
        })
      },
      clearSearch() {
        if (this.hasQueryKey) {
          this.$router.push({ name: 'projectDeliveries', params: { projectId: this.project.id } })
        } else if (this.hasFilters) {
          this.searchText = ''
          this.searchTags = []
          this.searchMeta = []
          this.selectedStatuses = ['all']
          this.beforeFilterDeliveries()
        }
      },
      onConfigurationSelected(configurationId) {
        const configuration = this.configurations.find(config => config.id === configurationId)
        this.configuration = deepCopy(configuration)
      },
      onChangeSettings(value) {
        this.changeSettings = value

        if (this.changeSettings) {
          this.selectedSettingsOption = 'form'
        }
      },
      onSelectedSettingsChange(option) {
        if (option === 'form') {
          const defaultConfiguration = this.configurations.find(config => config.is_default)
          this.selectedConfiguration = defaultConfiguration.id
          this.configuration = deepCopy(defaultConfiguration)
        }

        this.selectedSettingsOption = option
      },
      async onFormChange(formId) {
        this.unsavedDelivery.form_id = formId

        if (!formId) {
          return this.formConfiguration = deepCopy(DEFAULT_CONFIGURATION)
        }

        const liveVersionNumber = this.formLiveVersionNumberLookup[formId]

        this.loadingForm = true

        const { data, error } = await getFormConfiguration(this.project.id, formId, liveVersionNumber)

        this.loadingForm = false

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to load form settings.'
          })
        }

        this.formConfiguration = data.version.configuration
      },
      simpleSearch() {
        const params = `&search=${this.simpleSearchText}`

        this.currentPage = 1

        this.getDeliveries(params)
      },
      shouldDisable(neededPerms) {
        return !SESSION.hasPermissions(neededPerms)
      },
      tagsUpdated(tags) {
        this.tags = tags
      }
    },
    computed: {
      allStatuses() {
        return this.selectedStatuses.includes('all') || this.filtering
      },
      isAdmin() {
        return this.project.my_role_types.includes('admin')
      },
      deliveryCreationIsDisabled() {
        const orgDisabled = get(this.project, 'organization.delivery_creation_disabled', false)
        const projectDisabled = this.project.delivery_creation_disabled
        return orgDisabled || projectDisabled
      }
    }
  }
</script>

<style lang="scss" scoped>
.form-row {
  margin-left: 20px!important;
}
  @media screen and (prefers-reduced-motion: no-preference) {
    html,
    body {
      scroll-behavior: smooth;
    }
  }
  .top-button:hover {
    text-decoration: none;
  }
</style>
