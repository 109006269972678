import { HTTP } from './requests'
import { SEI_API_BASE } from './constants'
import {_} from '../utils/lodash'


class Translation {
  constructor(data, form, languageId) {
    this._data = data
    this._form = { ...form }
    this.language_id = languageId

    this._translation = null
    this._pending_complete === null
  }

  get isComplete() {
    return this._translation && this._translation.is_complete
  }

  get pendingComplete() {
    if (this._pending_complete === null || this._pending_complete === undefined) return this.isComplete

    return this._pending_complete
  }

  set pendingComplete(value) {
    this._pending_complete = value
  }

  get translation() {
    return this._translation
  }

  formJson() {
    return {
      ...this._form
    }
  }

  applyTranslation(translation) {
    if (!translation) return

    if (this._translation) {
      this._translation = {
        ...this.translation,
        ...translation
      }
    } else {
      this._translation = translation
    }

    this._form = _.merge(this._form, translation.translation)
  }

  async save(pendingComplete) {
    this.pendingComplete = pendingComplete

    if (this._translation?.id) {
      return await this.update()
    }

    return await this.create()
  }

  async create() {
    try {
      const url = `${SEI_API_BASE}/exams/${this.exam_id}/translations`
      const response = await HTTP.post(url, this.payloadForCreate)

      this.applyTranslation(response.data)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async update() {
    try {
      const { id } = this._translation
      const url = `${SEI_API_BASE}/exams/${this.exam_id}/translations/${id}`
      const response = await HTTP.put(url, this.payloadForUpdate)

      this.applyTranslation(response.data)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async auto_translate(targetLanguageCode) {
    const autoTranslationUrl = `${SEI_API_BASE}/exams/${this.exam_id}/translations/translate_object`

    try {
      const payload = {
        resource_id: this.id,
        type: this.payloadForCreate.type,
        target_language: targetLanguageCode
      }

      const response = await HTTP.post(autoTranslationUrl, payload)

      this.applyTranslation(response.data)
      return {data: null}
    } catch (error) {
      return { error }
    }
  }
}


class TranslationItem extends Translation {
  static DEFAULT_OPTIONS = [
    'Strongly agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly disagree'
  ]

  constructor(item, languageId) {
    super(item, TranslationItem.BuildForm(item), languageId)

    if (this._data.version.settings.type === 'likert') {
      this._data.version.content.options = [...TranslationItem.DEFAULT_OPTIONS]
      this._form.content.options = TranslationItem.DEFAULT_OPTIONS.map(() => {})
    }

    this._item = this._data
  }

  static BuildForm(item) {
    const form = {content: {stem: ''}, feedback:{}}
    const options = [ ...item.version.content.options || [] ].map(() => '')
    const matches = [ ...item.version.content.matches || [] ].map(() => '')
    const segments = [ ...item.version.content.segments || [] ].map(segment => {
      const cleanSegment = {}

      if (segment.options) {
        cleanSegment.options = segment.options.map(() => { return {text: ''} })
      } else {
        cleanSegment.text = ''
      }

      return cleanSegment
    })
    const labels = [ ...item.version.content.labels || [] ].map(() => '')

    form.content.options = options
    form.content.matches = matches
    form.content.segments = segments
    form.content.labels = labels

    return form
  }

  get id() {
    return this._item.version.id
  }

  get name() {
    return this._item.name
  }

  get content() {
    return this._item.version.content
  }

  get feedback() {
    return this._item.version.feedback
  }

  get type() {
    return this._item.version.settings.type
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get exam_id() {
    return this._item.exam_id
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: TranslationType.item,
      item_version_id: this.id,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationInterface extends Translation {
  static Factory(result, examId, languageId) {
    const form = {}
    Object.keys(result).forEach(productName => {
      Object.keys(result[productName]).forEach(key => {
        if (!form[productName]) form[productName] = {}

        form[productName][key] = ''
      })
    })

    return new TranslationInterface(result, form, examId, languageId)
  }

  constructor(data, form, examId, languageId) {
    super(data, form, languageId)

    this.exam_id = examId
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get products() {
    return Object.keys(this._data)
  }

  strings(productName) {
    return this._data[productName]
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: TranslationType.interface,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationScoreReport extends Translation {
  static Factory(result, examId, languageId) {
    const form = {...result}

    return new TranslationScoreReport(result, form, examId, languageId)
  }

  constructor(data, form, examId, languageId) {
    super(data, form, languageId)

    this.exam_id = examId
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get data() {
    return this._data
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: TranslationType.score_report,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationSettings extends Translation {
  static EMPTY_FORM = {
    name: '',
    display_name: '',
    description: '',
    examinee_schema: []
  }

  static Factory(result, examId, languageId) {
    const form = {...TranslationSettings.EMPTY_FORM}
    form.examinee_schema = result.examinee_schema.map(() => { return {key: ''} })

    return new TranslationSettings(result, form, examId, languageId)
  }

  constructor(data, form, examId, languageId) {
    super(data, form, languageId)

    this.exam_id = examId
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get name() {
    return this._data.name
  }

  get display_name() {
    return this._data.display_name
  }

  get description() {
    return this._data.description
  }

  get examinee_schema() {
    return this._data.examinee_schema
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: TranslationType.settings,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationForm extends Translation {
  constructor(data, languageId) {
    super(data, TranslationForm.BuildForm(data), languageId)
  }

  static BuildForm(data) {
    const form = {
      settings: {},
      groups: data.version.groups.map(group => { return {id: group.id, name: '', instructions: ''} }),
    }

    return form
  }

  get id() {
    return this._data.version.id
  }

  get name() {
    return this._data.name
  }

  get settings() {
    return this._data.version.configuration.settings
  }

  get groups() {
    return this._data.version.groups
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get exam_id() {
    return this._data.exam_id
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: TranslationType.form,
      form_version_id: this.id,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationBasic extends Translation {
  static EMPTY_FORM = {
    name: '',
    content: ''
  }

  static Factory(apiResult, languageId, type) {
    const extraArgs = {
      resourceKey: `${type}_id`,
      languageId,
      type
    }

    if (type === TranslationType.agreement) {
      return new TranslationAgreement(apiResult, TranslationBasic.EMPTY_FORM, extraArgs)
    }

    if (type === TranslationType.survey) {
      return new TranslationSurvey(apiResult, TranslationSurvey.EMPTY_FORM, extraArgs)
    }

    if (type === TranslationType.shared_content) {
      return new TranslationSharedContent(apiResult, TranslationBasic.EMPTY_FORM, extraArgs)
    }

    if (type === TranslationType.flow) {
      return new TranslationFlow(apiResult, {readiness_checklist:[]}, extraArgs)
    }

    return new TranslationBasic(apiResult, TranslationBasic.EMPTY_FORM, extraArgs)
  }

  constructor(data, form, extraArgs) {
    super(data, form, extraArgs.languageId)

    this._type = extraArgs.type
    this._resouceKey = extraArgs.resourceKey
  }

  get id() {
    return this._data.id
  }

  get form() {
    return this._form
  }

  set form(form) {
    this._form = form
  }

  get exam_id() {
    return this._data.exam_id
  }

  get payloadForCreate() {
    return {
      language_id: this.language_id,
      type: this._type,
      [this._resouceKey]: this.id,
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }

  get payloadForUpdate() {
    return {
      translation: {...this.formJson()},
      is_complete: this.pendingComplete
    }
  }
}


class TranslationAgreement extends TranslationBasic {
  get name() {
    return this._data.name
  }

  get content() {
    return this._data.content
  }
}


class TranslationSurvey extends TranslationBasic {
  static EMPTY_FORM = {
    name: '',
    instructions: ''
  }

  get name() {
    return this._data.name
  }

  get instructions() {
    return this._data.instructions
  }
}


class TranslationSharedContent extends TranslationBasic {
  get name() {
    return this._data.name
  }

  get content() {
    return this._data.content
  }
}


class TranslationFlow extends TranslationBasic {
  get _readiness_check_step() {
    const filteredSteps = this._data.steps.filter(step => step.type === 'readiness_check')

    return filteredSteps?.length ? filteredSteps[0] : null
  }

  get readiness_checklist() {
    return this._readiness_check_step?.settings.readiness_checklist
  }
}


export const TranslationsHelper = class TranslationsHelper {
  static Factory(projectId, languageId, type) {
    const BASIC = [TranslationType.agreement, TranslationType.survey, TranslationType.shared_content, TranslationType.flow]
    const STRINGS = [TranslationType.interface, TranslationType.settings, TranslationType.score_report]

    if (BASIC.includes(type)) {
      return new TranslationsHelperBasic(projectId, languageId, type)
    }

    if (STRINGS.includes(type)) {
      return new TranslationsHelperStrings(projectId, languageId, type)
    }

    if (type === TranslationType.item) return new TranslationsHelperItems(projectId, languageId, type)
    if (type === TranslationType.form) return new TranslationsHelperForms(projectId, languageId, type)

    return new TranslationsHelper(projectId, languageId, type)
  }

  constructor(projectId, languageId, type) {
    this.projectId = projectId
    this.languageId = languageId
    this.type = type

    this.things = []
    this.translations = []

    this.page = 1
    this.perPage = 5
    this.total = 0

    this.idsComplete = []
    this.idsSearch = []
    this.status = ''
  }

  setPagination({page, perPage}) {
    this.page = page
    this.perPage = perPage
  }

  setFilters(filters) {
    if ('status' in filters)
      this.status = filters.status
    
    if ('searchText' in filters)
      this.searchText = filters.searchText
  }

  async _fetch(url) {
    try {
      return await HTTP.get(url)
    } catch (error) {
      return { error }
    }
  }

  async _applyFilters(url) {
    await this._fetchIdsComplete()
    await this._fetchIdsSearchText()

    let symbolCalls = url.includes('?') ? 1 : 0
    let symbol = () => {
      if (symbolCalls) return '&'
      symbolCalls++
      return '?'
    }

    if (this.include) {
      url += `${symbol()}include=${this.include}`
    }

    if (this.only) {
      url += `${symbol()}only=${this.only}`
    }

    if (this.page) {
      url += `${symbol()}page=${this.page}`
    }

    if (this.perPage) {
      url += `${symbol()}per_page=${this.perPage}`
    }

    if (!this.isLive) {
      url += `${symbol()}version_number=-1`
    }

    if (this.searchText && this.searchText.length) {
      url += `${symbol()}search=${this.searchText}`
    }


    if (this.status === 'not_complete') {
      if (this.idsComplete.length) {
        url += `${symbol()}not_ids=${this.idsComplete.join(',')}`
      }
    } else {
      if (this.idsComplete.length) {
        url += `${symbol()}ids=${this.idsComplete.join(',')}`
      }
    }

    if (this.idsSearch.length) {
      url += `${symbol()}force_ids=${this.idsSearch.join(',')}`
    }

    return url
  }

  fetchContent() { throw new Error('[TranslationsHelper.fetchContent] not implemented') }
  fetchTranslations() { throw new Error('[TranslationsHelper.fetchTranslations] not implemented') }

  set isLive(value) {
    this._isLive = !!value
  }

  get isLive() {
    return this._isLive === undefined || this._isLive
  }

  get resourceKey() {
    switch(this.type) {
      case TranslationType.shared_content: {
        return 'shared_content_id'
      }
      case TranslationType.survey: {
        return 'survey_id'
      }
      case TranslationType.agreement: {
        return 'agreement_id'
      }
      case TranslationType.form: {
        return 'form_version_id'
      }
      case TranslationType.item: {
        return 'item_version_id'
      }
      case TranslationType.flow: {
        return 'flow_id'
      }
    }

    return ''
  }

  async _fetchIdsComplete() {
    if (this.status === 'complete' || this.status === 'not_complete') {
      let url = `${SEI_API_BASE}/exams/${this.projectId}/translations/complete?type=${this.type}&language_id=${this.languageId}`

      if (!this.isLive) {
        url += '&version_number=-1'
      }

      const response = await this._fetch(url)

      if (response.error) {
        // TODO handle this error
        return
      }

      this.idsComplete = response.data.results
    } else {
      this.idsComplete = []
    }
  }

  async _fetchIdsSearchText() {
    let url = `${SEI_API_BASE}/exams/${this.projectId}/translations?exclude=translation&type=${this.type}&language_id=${this.languageId}`

    if (!this.isLive) {
      url += '&version_number=-1'
    }

    if (this.searchText && this.searchText.length) {
      url += `&search=${this.searchText}`

      const response = await this._fetch(url)

      if (response.error) {
        // TODO handle this errpr
        return
      }
      this.idsSearch = response.data.results.map(result => { return result[this.resourceKey] })
    } else {
      this.idsSearch = []
    }
  }
}


class TranslationsHelperForms extends TranslationsHelper {
  constructor(projectId, languageId, type) {
    super(projectId, languageId, type)

    this.only = 'id,version,name,exam_id'
    this.include = 'version'
  }

  async fetchContent({ isLive, formId, versionNumber }) {
    let response

    if (formId) {
      response = await this._fetchForm(formId, versionNumber)
    } else {
      response = await this._fetchFormsReq(isLive)
    }

    const forms = response.map(data => {
      return new TranslationForm(data, this.languageId)
    })
    const formsMap = forms.reduce((map, form) => {
      map[form.id] = form

      return map
    }, {})

    this.thingsMap = formsMap
    this.things = forms

    return this.things
  }

  async _fetchFormsReq(isLive) {
    this.isLive = isLive
    const url = await this._applyFilters(`${SEI_API_BASE}/exams/${this.projectId}/forms`)
    const response = await this._fetch(url)

    if (response.error) {
      return []
    } else {
      this.total = response.data.total

      return response.data.results
    }
  }

  async _fetchForm(formId, versionNumber) {
    let url = `${SEI_API_BASE}/exams/${this.projectId}/forms/${formId}?include=${this.include}&only=${this.only}`

    if (versionNumber) {
      url += `&version_number=${versionNumber}`
    }

    const response = await this._fetch(url)

    if (response.error) {
      return []
    } else {
      this.total = 1

      return [ response.data ]
    }
  }

  async fetchTranslations() {
    const ids = this.things.map(thing => thing.id)
    const url = `${SEI_API_BASE}/exams/${this.projectId}/translations?type=${this.type}&language_id=${this.languageId}&form_ids=${ids}`
    const response = await this._fetch(url)

    let translations = []

    if (!response.error) {
      translations = [...response.data.results]
    }

    this.translations = translations.reduce((map, translation) => {
      map[translation.form_version_id] = translation

      return map
    }, {})

    return this.translations
  }  

}

class TranslationsHelperItems extends TranslationsHelper {
  constructor(projectId, languageId, type) {
    super(projectId, languageId, type)

    this.only = 'id,version,name,exam_id'
    this.include = 'version'
  }

  async fetchContent({ isLive, itemId, versionNumber }) {
    let itemsResponse

    if (itemId) {
      itemsResponse = await this._fetchItem(itemId, versionNumber)
    } else {
      itemsResponse = await this._fetchItemsReq(isLive)
    }

    const items = itemsResponse.map(data => {
      return new TranslationItem(data, this.languageId)
    })
    const itemsMap = items.reduce((map, item) => {
      map[item.id] = item

      return map
    }, {})

    this.thingsMap = itemsMap
    this.things = items

    return this.things
  }

  async _fetchItemsReq(isLive) {
    this.isLive = isLive
    const url = await this._applyFilters(`${SEI_API_BASE}/exams/${this.projectId}/translations/items`)
    const response = await this._fetch(url)

    if (response.error) {
      return []
    } else {
      this.total = response.data.total

      return response.data.results
    }
  }

  async _fetchItem(itemId, versionNumber) {
    let url = `${SEI_API_BASE}/exams/${this.projectId}/items/${itemId}?include=${this.include}&only=${this.only}`

    if (versionNumber) {
      url += `&version_number=${versionNumber}`
    }

    const response = await this._fetch(url)

    if (response.error) {
      return []
    } else {
      this.total = 1

      return [ response.data ]
    }
  }

  async fetchTranslations() {
    const ids = this.things.map(thing => thing.id)
    let url = `${SEI_API_BASE}/exams/${this.projectId}/translations?type=${this.type}&language_id=${this.languageId}&item_ids=${ids}`

    if (this.status) {
      url += `&status=${this.status}`
    }

    const response = await this._fetch(url)

    let translations = []

    if (!response.error) {
      translations = [...response.data.results]
    }

    this.translations = translations.reduce((map, translation) => {
      map[translation.item_version_id] = translation

      return map
    }, {})

    return this.translations
  }
}

class TranslationsHelperStrings extends TranslationsHelper {
  get fetchContent() {
    switch(this.type) {
      case TranslationType.settings: {
        return this._fetchSettings
      }

      case TranslationType.interface: {
        return this._fetchInterface
      }

      case TranslationType.score_report: {
        return this._fetchScoreReport
      }

      default: {
        return super.fetchContent
      }
    }
  }

  async _fetchInterface() {
    const DEFAULT_RESPONSE = {}
    const url = `${SEI_API_BASE}/exams/${this.projectId}/translations/interface`
    const response = await this._fetch(url)

    if (response.error) {
      return DEFAULT_RESPONSE
    } else {
      this.things = [ TranslationInterface.Factory(response.data, this.projectId, this.languageId) ]

      return this.things
    }
  }

  async fetchNamedInterface({ languageName }) {
    const safeLanguageName = encodeURIComponent(languageName)
    const url = `${SEI_API_BASE}/exams/${this.projectId}/translations/interface?language=${safeLanguageName}`
    const response = await this._fetch(url)

    if (response.error) {
      return {}
    } else {
      return response.data
    }
  }

  async _fetchSettings(settings) {
    this.things = [ TranslationSettings.Factory(settings, this.projectId, this.languageId) ]

    return Promise.resolve(this.things)
  }

  async _fetchScoreReport() {
    const DEFAULT_RESPONSE = {}
    const url = `${SEI_API_BASE}/exams/${this.projectId}/translations?type=${this.type}&language_id=${this.languageId}`
    const response = await this._fetch(url)

    if (response.error) {
      return DEFAULT_RESPONSE
    } else {
      this.things = [ TranslationScoreReport.Factory(response.data, this.projectId, this.languageId) ]

      return this.things
    }
  }

  async fetchTranslations() {
    let url = `${SEI_API_BASE}/exams/${this.projectId}/translations?type=${this.type}&language_id=${this.languageId}`

    const response = await this._fetch(url)

    let translations = []

    if (!response.error) {
      translations = [...response.data.results]
    }

    this.translations = translations

    return this.translations
  }

  async save(complete) {
    return await Promise.all(this.things.map(thing => thing.save(complete)))
  }
}

class TranslationsHelperBasic extends TranslationsHelper {
  get url() {
    switch(this.type) {
      case TranslationType.shared_content: {
        return `${SEI_API_BASE}/exams/${this.projectId}/shared_content`
      }

      case TranslationType.survey: {
        return `${SEI_API_BASE}/exams/${this.projectId}/surveys`
      }

      case TranslationType.agreement: {
        return `${SEI_API_BASE}/exams/${this.projectId}/agreements`
      }

      case TranslationType.flow: {
        return `${SEI_API_BASE}/exams/${this.projectId}/flows?with_readiness_check=1`
      }
    }

    return ''
  }

  async fetchContent() {
    const url = await this._applyFilters(this.url)
    const response = await this._fetch(url)

    if (response.error) {
      return []
    } else {
      this.things = response.data.results.map(result => { return TranslationBasic.Factory(result, this.languageId, this.type) })
      this.total = response.data.total

      return this.things
    }
  }

  async fetchTranslations() {
    const ids = this.things.map(thing => thing.id)
    const url = `${SEI_API_BASE}/exams/${this.projectId}/translations?type=${this.type}&language_id=${this.languageId}&${this.resourceKey}=${ids}`
    const response = await this._fetch(url)

    let translations = []

    if (!response.error) {
      translations = [...response.data.results]
    }

    this.translations = translations.reduce((map, translation) => {
      map[translation[this.resourceKey]] = translation

      return map
    }, {})

    return this.translations
  }
}

export const TranslationType = class TranslationType {
  static item = 'item'
  static form = 'form'
  static shared_content = 'shared_content'
  static interface = 'interface'
  static settings = 'settings'
  static agreement = 'agreement'
  static survey = 'survey'
  static score_report = 'score_report'
  static flow = 'flow'
}

export const TranslationModes = class TranslationModes {
  static EDIT = 'edit'
  static PREVIEW = 'preview'
  static OPTIONS = [
    {text: 'Preview Mode', value: this.PREVIEW},
    {text: 'Edit Mode', value: this.EDIT}
  ]
}

export const AutoTranslateApi = class AutoTranslateApi {
  constructor(projectId, targetLanguageCode) {
    this.projectId = projectId
    this.targetLanguageCode = targetLanguageCode
    this._translationUrl = `${SEI_API_BASE}/exams/${this.projectId}/translations/translate`
  }

  async _postTranslation(text) {
    try {
      const payload = {
        text,
        target_language: this.targetLanguageCode
      }

      return await HTTP.post(this._translationUrl, payload)
    } catch (error) {
      return { error }
    }
  }

  async translate(text) {
    return await this._postTranslation(text)
  }
}
