<template>
  <div>
    <b-modal
      :hide-header-close="isSaving"
      :no-close-on-backdrop="isSaving"
      :title="modalTitle"
      @hidden="clearFields()"
      id="bulk-proctor-modal"
      size="xl"
    >
      <b-form @submit.prevent="onSubmit" id="add-bulk-form" novalidate>
        <label for="bulk-proctor-tags">Tags *</label>
        <b-input-group>
          <tag-selector id="bulk-proctor-tags" :required="true" :tags="project.tags" :selected="editing.tags" @tags-updated="tagsUpdated" style="flex: 1" />
        </b-input-group>
      </b-form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button
          :disabled="isSaving"
          @click="removeBulkProctor()"
          class="mr-auto"
          size="sm"
          v-if="!isNew"
          variant="link"
        >
          <b-spinner label="Small Spinner" small v-show="isDeleting"></b-spinner
          >&nbsp;Delete
        </b-button>
        <b-button-group>
          <b-button :disabled="isSaving" @click="cancel()" variant="white"
            >Cancel</b-button
          >
          <b-button
            :disabled="disabledSaveButton"
            form="add-bulk-form"
            type="submit"
          >
            <b-spinner
              label="Small Spinner"
              small
              v-show="isSaving && !isDeleting"
            ></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </template>
    </b-modal>

    <b-modal
      @hidden="clearUrl()"
      hide-footer
      id="bulk-proctor-url-modal"
      size="xl"
      title="Bulk Proctor URL"
    >
      <b-input-group v-if="!loadingUrl">
        <b-form-input :value="url" readonly ref="copyUrl" />
        <b-input-group-append>
          <b-button-group>
            <b-button @click="copyUrl()" id="url-copy-btn" variant="white"
              >Copy</b-button
            >
            <b-button @click="openUrl()" variant="secondary">Open</b-button>
          </b-button-group>
          <b-tooltip
            :show.sync="copiedUrl"
            target="url-copy-btn"
            triggers="manual"
            variant="primary-dark"
            >Copied!</b-tooltip
          >
        </b-input-group-append>
      </b-input-group>
      <Spinner padding="p-1" v-else />
    </b-modal>

    <p>{{ dropdownHeader }}</p>
    <b-table
      :fields="fields"
      :items="bulkProctors"
      hover
      responsive
      small
      striped
      v-if="bulkProctors.length"
    >
      <template v-slot:cell(buttons)="row">
        <b-button-group class="float-right">
          <b-button :disabled="shouldDisable" @click="openEditModal(row.index)" size="sm" variant="white"
            >Edit</b-button
          >
          <b-button
            :disabled="shouldDisable"
            @click="getBulkProctorUrl(row.item)"
            size="sm"
            variant="secondary"
            >Get Link</b-button
          >
        </b-button-group>
      </template>
    </b-table>
    <b-button
      :disabled="shouldDisable"
      @click.stop="openNewModal()"
      class="mb-2"
      size="sm"
      variant="primary-light"
      >Add a Bulk Proctor</b-button
    >
  </div>
</template>

<script>
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { EVENT } from '../../utils/event-bus'
  import { deepCopy } from '../../utils/misc'
  import { VALIDATE } from '../../utils/validate'

  import Spinner from '../Spinner'
  import TagSelector from './TagSelector.vue'

  export default {
    name: 'BulkProctor',
    components: {
      Spinner,
      TagSelector
    },
    props: {
      project: {
        type: Object
      },
      isSaving: {
        type: Boolean
      },
      shouldDisable: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      project(newProject) {
        this.$bvModal.hide('bulk-proctor-modal')
        this.safeProject = deepCopy(newProject)
        this.createTableData()
      }
    },
    created() {
      this.safeProject = deepCopy(this.project)
      this.createTableData()
    },
    data() {
      return {
        safeProject: {},
        bulkProctors: [],
        fields: [{ key: 'tags' }, { key: 'buttons', label: '' }],
        editing: {},
        index: null,
        tag: '',
        isNew: false,
        isDeleting: false,
        loadingUrl: false,
        url: '',
        copiedUrl: false
      }
    },
    methods: {
      createTableData() {
        const tableData = []
        for (const bulkProctor of this.safeProject.settings.bulk_proctors) {
          const row = {
            tags: this.listTags(bulkProctor.tags),
            link_id: bulkProctor.link_id,
            buttons: ''
          }
          tableData.push(row)
        }
        this.bulkProctors = tableData
      },
      openEditModal(index) {
        this.editing = deepCopy(this.safeProject.settings.bulk_proctors[index])
        this.index = index
        this.$bvModal.show('bulk-proctor-modal')
      },
      openNewModal() {
        this.editing = {
          tags: []
        }
        this.isNew = true
        this.$bvModal.show('bulk-proctor-modal')
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.addBulkProctor()
        }
      },
      addBulkProctor() {
        if (this.isNew) {
          this.saveNewBulkProctor()
        } else {
          this.save()
        }
      },
      saveNewBulkProctor() {
        this.safeProject.settings.bulk_proctors.push(this.editing)
        this.updateProject()
      },
      save() {
        try {
          this.safeProject.settings.bulk_proctors[this.index] = this.editing
          this.updateProject()
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to save bulk proctor.'
          })
        }
      },
      updateProject() {
        EVENT.saveProject(this.safeProject)
      },
      removeBulkProctor() {
        this.safeProject.settings.bulk_proctors.splice(this.index, 1)
        this.isDeleting = true
        this.updateProject()
      },
      listTags(tags) {
        return tags.join(', ')
      },
      clearFields() {
        this.isNew = false
        this.isDeleting = false
        this.tag = ''
      },
      async getBulkProctorUrl(bulkProctor) {
        try {
          this.loadingUrl = true
          this.$bvModal.show('bulk-proctor-url-modal')
          const url = `${SEI_API_BASE}/exams/${this.safeProject.id}/get_bulk_proctor_link/${bulkProctor.link_id}`
          const response = await HTTP.get(url)
          this.url = `${location.origin}${response.data}`
        } catch (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to get bulk proctor link.'
          })
        } finally {
          this.loadingUrl = false
        }
      },
      copyUrl() {
        this.$refs.copyUrl.select()
        document.execCommand('copy')
        this.copiedUrl = true
        setTimeout(() => (this.copiedUrl = false), 1200)
      },
      openUrl() {
        open(this.url, '_blank')
      },
      clearUrl() {
        this.url = ''
      },
      tagsUpdated(tags) {
        this.editing.tags = tags
      }
    },
    computed: {
      dropdownHeader() {
        return `Bulk Proctors (${this.bulkProctors.length})`
      },
      modalTitle() {
        if (this.isNew) {
          return 'Add Bulk Proctor'
        }
        return 'Edit Bulk Proctor'
      },
      disabledSaveButton() {
        return this.isSaving
      }
    }
  }
</script>

<style lang="scss" scoped></style>
