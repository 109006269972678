<template>
  <div>
    <hr />
    <h4 class="my-3">Filters</h4>
    <b-checkbox v-model="includeDeleted">Include deleted items</b-checkbox>
    <b-checkbox v-model="includeFiles"
      >Only include items with files</b-checkbox
    >
    <b-checkbox v-model="includeShared"
      >Only include items with shared content</b-checkbox
    >
    <b-checkbox v-model="includeReferences"
      >Only include items with references</b-checkbox
    >
    <b-checkbox v-model="includeEnemies"
      >Only include items with enemies</b-checkbox
    >
    <br />
    <b-card
      :key="index"
      bg-variant="light"
      class="mt-2"
      no-body
      v-for="(filter, index) in filters"
    >
      <b-card-body class="py-2">
        <b-row>
          <b-col md="5">
            <b-select
              :options="options"
              :value="filter.field_id"
              @change="filterChanged(arguments, index)"
            />
          </b-col>
          <b-col cols="9" sm="10" md="5" lg="6">
            <div v-if="filter.field_id">
              <div
                v-if="
                  fieldIs(filter.field_id, ['created_dates', 'edited_dates'])
                "
              >
                <label>Start Date</label>
                <b-form-datepicker
                  v-model="filter.field_value.start_date"
                  class="mb-2"
                ></b-form-datepicker>
                <label>End Date</label>
                <b-form-datepicker
                  v-model="filter.field_value.end_date"
                  class="mb-2"
                ></b-form-datepicker>
              </div>
              <b-input
                type="text"
                v-if="fieldIs(filter.field_id, ['text'])"
                v-model="filter.field_value"
                placeholder="Text"
              ></b-input>
              <b-input
                type="number"
                v-if="fieldIs(filter.field_id, ['number'])"
                v-model.number="filter.field_value"
                placeholder="Number"
              ></b-input>
              <v-select
                :multiple="
                  fieldIs(filter.field_id, [
                    'select_one',
                    'select_multi',
                    'item_type',
                    'user',
                    'form_ids'
                  ])
                "
                :options="subOptions[filter.field_id]"
                :reduce="option => option.value"
                :selectable="option => !option.disabled"
                label="text"
                placeholder="Select"
                style="backgroundColor: white"
                v-if="
                  fieldIs(filter.field_id, [
                    'select_one',
                    'select_multi',
                    'item_type',
                    'user',
                    'form_ids'
                  ]) && subOptions[filter.field_id]
                "
                v-model="filter.field_value"
              >
                <template #selected-option-container="{ option, deselect }">
                  <span
                    :class="`vs__selected border ${option.color ? 'pl-0' : ''}`"
                  >
                    <div
                      :style="{
                        backgroundColor: option.color,
                        width: '15px',
                        height: '100%',
                        borderRadius: '3px 0 0 3px'
                      }"
                      class="mr-1"
                      v-if="option.color"
                    ></div>
                    {{ option.text }}
                    <button
                      :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
                      @click.stop="deselect(option)"
                      @mousedown.stop
                      class="vs__deselect no-focus"
                      type="button"
                    >
                      <font-awesome-icon icon="times"></font-awesome-icon>
                    </button>
                  </span>
                </template>
                <template #option="{ color, text }">
                  <div
                    :style="{ background: color }"
                    class="option p-1 d-inline-block"
                    v-if="color"
                  ></div>
                  {{ text }}
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col cols="3" sm="2" lg="1">
            <b-button @click="removeFilter(index)" variant="white"
              ><font-awesome-icon icon="trash"></font-awesome-icon
            ></b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row class="my-3">
      <b-col>
        <b-button @click="addFilter" size="sm" variant="white"
          >Add a filter</b-button
        >
      </b-col>
      <b-col v-if="!onlyCreateFilters" class="text-right">
        <b-button @click="clearFilters" class="mr-3" size="sm" variant="white"
          >Clear filters</b-button
        >
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col v-if="!onlyCreateFilters">
        <b-button
          @click="applyFilters"
          class="mr-3"
          size="sm"
          variant="primary-light"
          >Apply filters</b-button
        >
      </b-col>
    </b-row>
    <hr />
    <div class="mb-3 mt-3" v-if="items.length && showPreview">
      <label for="results"><h4>Filter Results</h4></label>
      <b-select
        :options="items"
        :style="{ minHeight: '250px' }"
        @change="handleItemIdsChanged"
        id="results"
        multiple
        scroll-box
        v-model="selectedItemIds"
      ></b-select>
    </div>

    <div v-if="noItems" class="mt-3">
      <strong class="text-warning">No items found.</strong>
    </div>
  </div>
</template>

<script>
  import { EVENT } from '../../utils/event-bus'
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { SESSION } from '../../utils/session'
  import { ITEM_TYPES } from '../../utils/constants'

  const EMPTY_SELECT = {
    text: 'Select',
    value: null,
    disabled: true
  }

  async function filter(
    examId,
    filters,
    includeDeleted,
    includeFiles,
    includeShared,
    includeReferences,
    includeEnemies,
    queryParams
  ) {
    try {
      if (queryParams === 'error') {
        throw new Error('Failed to construct query!')
      }
      const includeParams = `?include_deleted=${includeDeleted}&include_files=${includeFiles}&include_shared=${includeShared}&include_references=${includeReferences}&include_enemies=${includeEnemies}`
      const url = `${SEI_API_BASE}/exams/${examId}/items/filter${includeParams}${queryParams}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getItemWriters(projectId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/item_writers`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getForms(projectId) {
    try {
      let url = `${SEI_API_BASE}/exams/${projectId}/forms?only=name,id,status,live_version_number&per_page=1000&page=1`
      const response = await HTTP.get(url)
      return response.data.results
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ItemFilters',
    props: {
      project: {
        type: Object
      },
      showPreview: {
        type: Boolean,
        default: true
      },
      existingFilters: {
        type: Object
      },
      onlyCreateFilters: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        options: [],
        subOptions: {},
        filters: [],
        items: [],
        selectedItemIds: [],
        includeDeleted: false,
        includeFiles: false,
        includeShared: false,
        includeReferences: false,
        includeEnemies: false,
        noItems: false,
        metaHelper: null
      }
    },
    async created() {
      this.metaHelper = initMetaHelper(this.project)

      if (this.existingFilters) {
        this.setExistingFilters()
      }

      const options = [
        EMPTY_SELECT,
        { text: 'Item Type', value: 'item_type' },
        { text: 'Original Item Writer', value: 'user' },
        {
          text: 'Created Date Range',
          value: 'created_dates',
          disabled: false
        },
        {
          text: 'Last Edited Date Range',
          value: 'edited_dates',
          disabled: false
        },
        { text: 'Forms', value: 'form_ids' },
      ]

      const forms = await getForms(this.project.id)
      const formOptions = forms.error ? [] : forms.map(form => {
        return {
          text: form.name,
          value: form.id
        }
      })
      const subOptions = { item_type: [EMPTY_SELECT, ...ITEM_TYPES], form_ids: [...formOptions] }

      if (this.metaHelper.hasCustomFields) {
        const customFieldOptions = this.metaHelper.getOptions()
        options.push(...customFieldOptions)

        for (const customFieldId of this.metaHelper.customFieldOrder) {
          const fieldType = this.metaHelper.getFieldType(customFieldId)

          if (!fieldType.startsWith('select')) {
            continue
          }

          const fieldOptions = this.metaHelper.getFieldOptions(customFieldId)

          subOptions[customFieldId] = [EMPTY_SELECT, ...fieldOptions]
        }
      }

      this.options = options
      this.subOptions = subOptions
      this.subOptions.user = await this.createItemWriterOptions()
    },
    methods: {
      async applyFilters() {
        const { data, error } = await filter(
          this.project.id,
          this.filters,
          this.includeDeleted,
          this.includeFiles,
          this.includeShared,
          this.includeReferences,
          this.includeEnemies,
          this.getQueryParams(this.filters)
        )

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to filter items'
          })
        }

        this.noItems = !data.items.length
        this.items = [...data.items.sort((a, b) => a.text.localeCompare(b.text))]
        this.selectedItemIds = this.items.map(item => item.value)
        this.handleItemIdsChanged()
        this.handleFiltersChanged()
        this.handleQueryKey(data.query_key)
      },
      addFilter() {
        this.filters.push({
          field_id: null,
          field_value: null
        })
      },
      toggleIncludeDeleted() {
        this.includeDeleted = !this.includeDeleted
      },
      handleItemIdsChanged() {
        this.$emit('items-changed', this.selectedItemIds)
      },
      handleFiltersChanged() {
        const filters = {
          filters: this.filters,
          includeDeleted: this.includeDeleted,
          includeFiles: this.includeFiles,
          includeShared: this.includeShared,
          includeReferences: this.includeReferences,
          includeEnemies: this.includeEnemies
        }
        this.$emit('filters-changed', filters)
      },
      handleQueryKey(queryKey) {
        this.$emit('query-key', queryKey)
      },
      fieldIs(fieldId, types) {
        const fieldType = this.metaHelper.getFieldType(fieldId)

        return types.includes(fieldType) || types.includes(fieldId)
      },
      clearFilters() {
        this.filters = []
        this.selectedItemIds = []
        this.includeDeleted = false
        this.includeFiles = false
        this.includeShared = false
        this.includeReferences = false
        this.includeEnemies = false
        this.handleItemIdsChanged()
        this.handleFiltersChanged()
        this.handleQueryKey(null)
        this.noItems = false
        this.setDisabledDates()
      },
      filterChanged([fieldId], index) {
        const customFieldType = this.metaHelper.getFieldType(fieldId)
        const allowsMultiple = [
          'select_one',
          'select_multi',
          'item_type',
          'user'
        ]
        const allowsDate = ['created_dates', 'edited_dates']

        let fieldValue = null
        if (
          allowsMultiple.includes(customFieldType) ||
          allowsMultiple.includes(fieldId)
        ) {
          fieldValue = []
        } else if (allowsDate.includes(fieldId)) {
          fieldValue = { start_date: null, end_date: null }
        }

        const filter = { ...this.filters[index] }
        filter.field_id = fieldId
        filter.field_value = fieldValue

        this.filters.splice(index, 1, filter)

        this.setDisabledDates()
      },
      removeFilter(index) {
        this.filters.splice(index, 1)
        this.setDisabledDates()
      },
      setDisabledDates() {
        const allowsDate = ['created_dates', 'edited_dates']

        const dateFilters = []

        for (const filter of this.filters) {
          if (allowsDate.includes(filter.field_id)) {
            dateFilters.push(filter.field_id)
          }
        }

        for (const option of this.options) {
          option.disabled = dateFilters.includes(option.value)
        }
      },
      setExistingFilters() {
        const {
          filters,
          includeDeleted,
          includeFiles,
          includeShared,
          includeReferences,
          includeEnemies
        } = SESSION.items.filters
        this.filters = filters
        this.includeDeleted = includeDeleted
        this.includeFiles = includeFiles
        this.includeShared = includeShared
        this.includeReferences = includeReferences
        this.includeEnemies = includeEnemies
        this.setDisabledDates()
      },
      async createItemWriterOptions() {
        const { data, error } = await getItemWriters(SESSION.project.id)

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to load item writers'
          })
        }

        const options = []
        for (const user of data.results) {
          options.push({ text: user.full_name, value: user.id })
        }

        options.sort((current, next) => current.text.localeCompare(next.text))

        options.unshift(EMPTY_SELECT)

        return options
      },
      getQueryParams(filters) {
        try {
          if (!filters.length) {
            return ''
          }
          const paramsLookup = {}

          for (const filter of filters) {
            const { field_id, field_value } = filter
            const value = []

            if (!field_value) continue

            if (Array.isArray(field_value)) {
              if (!field_value.length) continue
              value.push(...field_value)
            } else if (typeof field_value === 'object') {
              const { start_date, end_date } = field_value
              if (!start_date || !end_date) continue
              const exlusive_end_date = this.$moment(end_date)
                .add(1, 'days')
                .format('YYYY-MM-DD')
              value.push(...[start_date, exlusive_end_date])
            } else {
              value.push(field_value)
            }

            if (paramsLookup[field_id]) {
              paramsLookup[field_id].push(...value)
            } else {
              paramsLookup[field_id] = [...value]
            }
          }

          const params = []

          for (const key in paramsLookup) {
            const value = paramsLookup[key].join(',')

            params.push(`${key}=${value}`)
          }

          return '&' + params.join('&')
        } catch (error) {
          return 'error'
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
