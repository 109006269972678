<template>
  <b-container class="mt-3" fluid>
    <b-alert
      v-model="showImportError"
      variant="danger"
      dismissible
      @dismissed="importErrorDismissed"
    >
      {{ importError }}
    </b-alert>

    <b-alert v-model="isMapped" variant="success" dismissible>
      Successfully mapped!
    </b-alert>

    <div v-if="needsFile">
      <b-form-group label="Import type" label-cols-lg="3" label-cols-sm="4">
        <b-select :options="importerOptions" v-model="type"></b-select>
      </b-form-group>


      <b-form-group
        v-if="type"
        label="Upload a file"
        label-cols-lg="3"
        label-cols-sm="4"
      >
        <ResourceFiles :files="files" @update-files="setFileForImport" @set-password="setPassword" />
      </b-form-group>

      <div v-if="type">
        <b-button variant="link" @click="downloadSampleFile">Download sample file</b-button>
      </div>
    </div>

    <div v-if="needsReview">
      <div v-if="needsMapping">
        <h3>Fields to map</h3>
        <div :key="index" class="mr-1" v-for="(field, index) in unmappedFields">
          <b-form-group>
            <b-row>
              <b-col md="4" lg="3">
                <label>{{ field }}</label>
              </b-col>
              <b-col>
                <b-select
                  :options="customFieldOptions"
                  v-model="pendingMeta[field]"
                ></b-select>
              </b-col>
              <b-col>
                <b-button
                  size="sm"
                  variant="primary-light"
                  @click="applyMapping(field, index)"
                  >Apply</b-button
                >
                <b-button
                  @click="deleteUnmappedField(field, index)"
                  size="sm"
                  variant="white"
                >
                  <font-awesome-icon icon="trash"></font-awesome-icon>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </div>

      <br />
      <h3>Items ready for review</h3>
      <ItemsPreviewTable
        :project="project"
        :items="pendingItems"
        :references="pendingReferences"
        @delete-items="deleteItems"
        @delete-mult-items="deleteMultItems"
      />
      <br />
      <div v-if="failedItems.length">
        <b>{{ failedItems.length }} Item Import(s) Failed:</b>
        <b-table
          :fields="failedFields"
          :items="failedItems"
          bordered
          hover
          responsive
          small
          striped
        ></b-table>
      </div>
      <br />

      <h3>Files ready for review</h3>
      <FilesPreviewTable
        :project="project"
        :files="pendingFiles"
      />
      <br />
      <div v-if="failedFiles.length">
        <b>{{ failedFiles.length }} File Import(s) Failed:</b>
        <b-table
          :fields="failedFields"
          :items="failedFiles"
          bordered
          hover
          responsive
          small
          striped
        ></b-table>
      </div>

      <br />
      <h3>References ready for review</h3>
      <ReferencesPreviewTable
        :project="project"
        :references="pendingReferences"
        @delete-references="deleteReferences"
      />
      <br />
      <div v-if="failedReferences.length">
        <b>{{ failedReferences.length }} References(s) Failed:</b>
        <b-table
          :fields="failedFields"
          :items="failedReferences"
          bordered
          hover
          responsive
          small
          striped
        ></b-table>
      </div>
      <br />
      <b-button @click="startOver">Start Over</b-button>
    </div>

    <div v-if="polling">
      <p>Please wait while we convert to Caveon format... <Spinner /></p>
    </div>
  </b-container>
</template>
<script>
  import ResourceFiles from './ResourceFiles'
  import ItemsPreviewTable from './ItemsPreviewTable'
  import FilesPreviewTable from './FilesPreviewTable'
  import ReferencesPreviewTable from './ReferencesPreviewTable'
  import { SEI_API_BASE } from '../../utils/constants'
  import { HTTP } from '../../utils/requests'
  import Spinner from '../Spinner'
  import { initMetaHelper } from '../../utils/meta-helper'
  import { EVENT } from '../../utils/event-bus'

  const MAX_ATTEMPTS = 30
  const POLLING_INTERVAL_MS = 1000

  async function poll(jobId) {
    const url = `${SEI_API_BASE}/jobs/${jobId}`
    const response = await HTTP.get(url)

    return response.data
  }

  async function import_(projectId, type, fileId, password) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/import`
      const response = await HTTP.post(url, { type, file_id: fileId, password: password })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function deleteFile(projectId, fileId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/files/${fileId}`

      await HTTP.delete(url)
    } catch (error) {
      return { error }
    }
  }

  async function deleteReference(projectId, refId) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/references/${refId}`

      await HTTP.delete(url)
    } catch (error) {
      return { error }
    }
  }

  async function getSampleFileUrl(projectId, fileType) {
    try {
      const url = `${SEI_API_BASE}/exams/${projectId}/download_import_sample_file?type=${fileType}`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  // async function getFiles(projectId) {
  //     const files = []
  //     let hasMore = true
  //     while (hasMore) {
  //         const url = `${SEI_API_BASE}/exams/${projectId}/files?include=download_url,url_code,embed_code`
  //         const response = await HTTP.get(url)

  //         files.push(...response.data.results)
  //         hasMore = response.has_next
  //     }
  //     return files
  // }

  export default {
    name: 'Import',
    components: {
      ResourceFiles,
      ItemsPreviewTable,
      FilesPreviewTable,
      ReferencesPreviewTable,
      Spinner
    },
    props: {
      project: {
        type: Object
      }
    },
    data() {
      return {
        supportedImporters: {
          qti: 'QTI',
          ohio_xlsx: 'Ohio XLSX',
          ohio_csv: 'Ohio CSV',
          its_xml: 'ITS XML',
          xlsx: 'XLSX',
          hrcp_xlsx: 'HRCP XLSX',
          guidewire_xml: 'Guidewire XML',
          caveon_yaml: 'Caveon lossless',
          adobe_xml: 'Adobe XML',
          respondus_qti: 'Respondus QTI',
          hpe_translations: 'HPE Translations'
        },
        importerOptions: [],
        type: null,
        files: [],
        file: null,
        job: null,
        intervalCount: 0,
        polling: false,
        pendingItems: [],
        pendingFiles: [],
        unmappedFields: [],
        importError: '',
        needsFile: true,
        pendingMeta: {},
        customFieldOptions: [],
        pendingReferences: [],
        isMapped: false,
        failedItems: [],
        failedFiles: [],
        failedReferences: [],
        failedFields: [{ key: 'name' }, { key: 'errMsg', label: 'Error' }],
        showImportError: false,
        password: '',
        encrypted: false
      }
    },
    async created() {
      // this.files = await getFiles(this.project.id)
      const projectImporters = this.project.importers
        .filter(key => this.supportedImporters[key])
        .map(key => ({
          value: key,
          text: this.supportedImporters[key]
        }))

      this.importerOptions = [
        { value: null, text: 'Not selected' },
        ...projectImporters
      ]

      this.metaHelper = initMetaHelper(this.project)

      this.customFieldOptions = [
        { value: null, text: 'Not selected' },
        ...this.metaHelper.getOptions()
      ]
    },
    methods: {
      initPolling(jobId) {
        this.polling = true
        this.interval = setInterval(async () => {
          try {
            if (this.intervalCount >= MAX_ATTEMPTS) {
              throw new Error('max attempts reached')
            }

            this.intervalCount++
            const response = await poll(jobId)

            if (response.status === 'failed') {
              throw new Error(response.status)
            }

            this.job = response

            if (response.result) {
              this.pendingItems =
                response.result.items && response.result.items.length
                  ? response.result.items
                  : []
              this.pendingFiles =
                response.result.files && response.result.files.length
                  ? response.result.files
                  : []
              this.unmappedFields =
                response.result.unmapped_fields &&
                response.result.unmapped_fields.length
                  ? response.result.unmapped_fields
                  : []
              this.pendingReferences =
                response.result.references && response.result.references.length
                  ? response.result.references
                  : []

              this.importId = response.result.import_id
              if (response.result.error) {
                this.importError = response.result.error
                this.showImportError = true
              }

              if (response.result.failed_items) {
                this.failedItems = response.result.failed_items
              }

              if (response.result.failed_files) {
                this.failedFiles = response.result.failed_files
              }

              if (response.result.failed_references) {
                this.failedReferences = response.result.failed_references
              }

              this.clearPolling()
              this.needsFile = false
            }
          } catch (e) {
            this.job = {
              status: 'failed'
            }
            this.importError = 'Import failed to complete!'
            this.showImportError = true
            this.clearPolling()
          }
        }, POLLING_INTERVAL_MS)
      },
      clearPolling() {
        this.polling = false
        clearInterval(this.interval)
        deleteFile(this.project.id, this.file.id)
      },
      async setFileForImport(files) {
        this.showImportError = false
        this.importError = null
        this.file = files[0]
        const response = await import_(this.project.id, this.type, this.file.id, this.password)

        if (response.error) {
          const errMsg = response.error.response.data.messages

          if (errMsg) {
            this.importError = errMsg.join('\n')
          } else {
            this.importError = 'Import failed due to an unknown server error!'
          }

          this.showImportError = true

          return this.clearPolling()
        }

        this.initPolling(response.job_id)
      },
      setPassword(pw) {
        this.password = pw
      },
      deleteItems(index) {
        if (index === -1) {
          this.pendingItems = []
        } else {
          this.pendingItems.splice(index, 1)
        }
      },
      deleteMultItems(indexes) {
        this.pendingItems = this.pendingItems.filter(function(value, index) {
          return indexes.indexOf(index) == -1
        })
      },
      async deleteFiles(index) {
        if (index === -1) {
          const tasks = []

          for (const file of this.pendingFiles) {
            tasks.push(deleteFile(this.project.id, file.id))
          }

          await Promise.all(tasks)
          this.pendingFiles = []
        } else {
          const file = this.pendingFiles[index]

          await deleteFile(this.project.id, file.id)
          this.pendingFiles.splice(index, 1)
        }
      },
      async deleteReferences(index) {
        if (index === -1) {
          const tasks = []

          for (const ref of this.pendingReferences) {
            tasks.push(deleteReference(this.project.id, ref.id))
          }

          await Promise.all(tasks)
          this.pendingReferences = []
        } else {
          const ref = this.pendingReferences[index]

          await deleteReference(this.project.id, ref.id)
          this.pendingReferences.splice(index, 1)
        }
      },
      startOver() {
        this.clearPolling()
        this.pendingItems = []
        this.pendingFiles = []
        this.failedItems = []
        this.failedFiles = []
        this.failedReferences = []
        this.needsFile = true
        this.showImportError = false
        this.importError = null
      },
      applyMapping(friendlyKey, index) {
        const pendingItems = []
        const letters = { A: 0, B: 1, C: 2, D: 3 }

        for (const item of this.pendingItems) {
          const meta = {
            scorpion: {},
            ...item.meta
          }
          const importedMeta = this.importId
            ? item.meta[this.importId]
            : item.meta
          const fieldId = this.pendingMeta[friendlyKey]

          if (fieldId) {
            const field = this.metaHelper.getField(fieldId)
            const isByOption = field.by_option

            if (typeof meta.scorpion[fieldId] !== 'object') {
              meta.scorpion[fieldId] = {}
            }

            let optionIndex = 0

            if (isByOption) {
              const optionLetter = friendlyKey
                .trim()
                .split(' ')
                .pop()
                .toUpperCase()
              optionIndex = letters[optionLetter]
            }

            const friendlyValue = importedMeta[friendlyKey]
              ? String(importedMeta[friendlyKey]).trim()
              : null
            const options = this.metaHelper.getFieldOptions(fieldId)
            const matchingOption = options.find(
              option => option.text === friendlyValue
            )
            const fieldType = this.metaHelper.getFieldType(fieldId)

            let value = friendlyValue

            if (matchingOption) {
              value = matchingOption.value
            }

            if (fieldType === 'select_multi') {
              if (!Array.isArray(meta.scorpion[fieldId])) {
                if (isByOption) {
                  meta.scorpion[fieldId][optionIndex] = []
                } else {
                  meta.scorpion[fieldId] = []
                }
              }

              if (isByOption) {
                meta.scorpion[fieldId][optionIndex].push(value)
              } else {
                meta.scorpion[fieldId].push(value)
              }
            } else if (fieldType === 'number') {
              if (isByOption) {
                meta.scorpion[fieldId][optionIndex] = Number(value, 10) || 0
              } else {
                meta.scorpion[fieldId] = Number(value, 10) || 0
              }
            } else {
              if (isByOption) {
                meta.scorpion[fieldId][optionIndex] = value
              } else {
                meta.scorpion[fieldId] = value
              }
            }
          }

          if (this.importId) {
            delete meta[this.importId][friendlyKey]
          } else {
            delete meta[friendlyKey]
          }

          pendingItems.push({
            ...item,
            meta: {
              ...meta
            }
          })
        }

        this.pendingItems = [...pendingItems]
        this.unmappedFields.splice(index, 1)
        this.isMapped = this.unmappedFields.length
      },
      deleteUnmappedField(key, index) {
        if (this.importId) {
          this.pendingItems.forEach(item => {
            delete item.meta[this.importId][key]
          })
        } else {
          this.pendingItems.forEach(item => {
            delete item.meta[key]
          })
        }

        this.unmappedFields.splice(index, 1)
      },
      importErrorDismissed() {
        this.importError = null
        this.showImportError = false
      },
      async downloadSampleFile() {
        const { data, error } = await getSampleFileUrl(this.project.id, this.type)

        if (error) {
          return EVENT.alert({
            variant: 'danger',
            message: 'Failed to download file.'
          })
        }

        const a = document.createElement('A')

        a.href = data.url
        a.setAttribute('download', '')

        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    computed: {
      needsReview() {
        return !this.needsFile
      },
      needsMapping() {
        return this.unmappedFields.length && !this.polling
      },
      sampleFileUrl() {
        return `${SEI_API_BASE}/exams/${this.project.id}/download_import_sample_file?type=${this.type}`
      },
      isFailedImports() {
        return (
          this.failedItems.length > 0 ||
          this.failedFiles.length > 0 ||
          this.failedReferences.length > 0
        )
      }
    }
  }
</script>

<style lang="scss" scoped></style>
