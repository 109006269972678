<template>
  <b-sidebar
    backdrop
    backdrop-variant="black"
    @hidden="onSidebarHidden"
    @shown="onSidebarShown"
    bg-variant="light"
    id="sidebar-right"
    right
    shadow
    sidebar-class="border-left border-medium"
    title="Notifications"
    width="75%"
  >
    <b-container class="border-top pt-2 notifications-container" fluid>
      <div class="notifications-top">
        <b-button
          :disabled="loading"
          @click="openNotificationSettingsModal()"
          size="sm"
          variant="primary-light"
          >
          <b-spinner small v-show="loading"></b-spinner>&nbsp;Notifications settings</b-button
        >
        <b-form-checkbox
          :checked="allDisabled"
          @change="disableAllNotifications"
          class="mt-3"
          >Disable All Email &amp; SMS Notifications</b-form-checkbox
        >
      </div>
      <div
        @scroll="checkForBottomOfScroll"
        class="notifications-box flex-grow-1 my-3 position-relative"
        ref="notificationsBox"
      >
        <div class="m-1" v-if="notifications.length">
          <b-toast
            body-class="d-flex justify-content-center p-0"
            class="new-notification"
            header-class="d-none"
            id="new-notification-toast"
            no-auto-hide
            static
            title="More Notifications Below"
            variant="info"
          >
            <strong
              @click="scrollToBottom()"
              class="w-100 h-100 m-2 text-center"
              >New Notifications Below</strong
            >
          </b-toast>
          <b-list-group
            :key="index"
            v-for="(notification, index) in notifications"
          >
            <b-list-group-item
              :to="notification.resource_link"
              class="flex-column align-items-start"
            >
              <b-badge
                :variant="notification.variant"
                class="notification-badge"
                >.</b-badge
              >
              <div class="d-flex w-100 justify-content-between">
                <small>{{ notification.exam_name }}</small>
                <small>{{ formattedTime(notification.timestamp) }}</small>
              </div>
              <p class="mb-0">{{ notification.preview }}</p>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="m-2" v-else>
          <i>No new notifications</i>
        </div>
      </div>
    </b-container>

    <b-modal
      :hide-footer="error || loading"
      id="notification-settings-modal"
      size="lg"
      title="Notifications Settings"
    >
      <div v-if="!loading">
        <b-alert
          class="d-flex justify-content-between align-items-center"
          show
          v-if="error"
          variant="danger"
        >
          Failed to load notification settings
          <b-button
            @click="loadNotificationModalData()"
            size="sm"
            variant="danger"
            >Reload</b-button
          >
        </b-alert>
        <div v-else>
          <b-alert show variant="info">
            <div>{{ email }}</div>
            <div class="d-flex justify-content-between">
              {{ phone }}
              <b-button
                class="p-0"
                href="/caveonid/dashboard"
                size="sm"
                variant="link"
                >Change Email / Phone</b-button
              >
            </div>
          </b-alert>
          <b-form
            @submit.prevent="onSubmit"
            id="notification-settings-form"
            novalidate
          >
            <div v-if="visibleNotificationSettings">
              <div
                :key="index"
                v-for="(notification, index) in editableNotificationSettings"
              >
                <div v-if="!notification.deleted">
                  <b-row class="mt-2">
                    <b-col md="4">
                      <label>Project</label>
                      <v-select
                        :options="projectOptions"
                        :reduce="option => option.id"
                        required
                        :value="notification.exam_id"
                        :data-value="notification.exam_id"
                        :clearable="false"
                        @input="
                          notificationChanged(
                            arguments,
                            'exam_id',
                            index,
                            notification
                          )
                        "
                      ></v-select>
                    </b-col>
                    <b-col md="4">
                      <label>Trigger</label>
                      <b-form-select
                        :options="triggerOptions"
                        @change="
                          notificationChanged(
                            arguments,
                            'event',
                            index,
                            notification
                          )
                        "
                        required
                        size="sm"
                        v-model="notification.event"
                      ></b-form-select>
                    </b-col>
                    <b-col md="4">
                      <label>Type</label>
                      <b-form-select
                        :options="typeOptions"
                        @change="
                          notificationChanged(
                            arguments,
                            'type',
                            index,
                            notification
                          )
                        "
                        required
                        size="sm"
                        v-model="notification.type"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row
                    class="pb-3 mt-3"
                    style="border-bottom: 1px dotted #ccc;"
                  >
                    <b-col md="8">
                      <b-form-checkbox
                        @change="
                          notificationChanged(
                            arguments,
                            'is_muted',
                            index,
                            notification
                          )
                        "
                        v-model="notification.is_muted"
                        >Disable Email / SMS Notifications</b-form-checkbox
                      >
                    </b-col>
                    <b-col md="4">
                      <b-button
                        @click="removeNotification(index)"
                        class="float-right px-0"
                        size="sm"
                        variant="link"
                        >Remove Notification</b-button
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div v-else>
              <i>No notifications have been created.</i>
            </div>
          </b-form>
          <b-button
            @click="addNotification"
            class="mt-3"
            size="sm"
            variant="primary-light"
            >Add notification</b-button
          >
        </div>
      </div>
      <Spinner padding="p-2" v-else />
      <template v-slot:modal-footer="{ cancel }">
        <b-button-group>
          <b-button @click="cancel" variant="white">Cancel</b-button>
          <b-button
            :disabled="saving"
            form="notification-settings-form"
            type="submit"
            variant="secondary"
          >
            <b-spinner label="Small Spinner" small v-show="saving"></b-spinner
            >&nbsp;Save
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </b-sidebar>
</template>

<script>
  import { deepCopy } from '../utils/misc'
  import { EVENT } from '../utils/event-bus'
  import { HTTP } from '../utils/requests'
  import {
    NOTIFICATION_TRIGGER_OPTIONS,
    NOTIFICATION_TYPE_OPTIONS,
    SEI_API_BASE
  } from '../utils/constants'
  import { SESSION } from '../utils/session'
  import { socketProvider } from '../utils/sockets'
  import { LOCAL } from '../utils/local'
  import { VALIDATE } from '../utils/validate'

  import Spinner from './Spinner'

  import get from 'lodash.get'

  async function getListOfNotificationSettings(page) {
    try {
      const url = `${SEI_API_BASE}/notifications?page=${page}&per_page=${PER_PAGE}`

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveNewNotificationSetting(payload) {
    try {
      const url = `${SEI_API_BASE}/notifications`

      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function saveExistingNotificationSetting(payload) {
    try {
      const url = `${SEI_API_BASE}/notifications/${payload.id}`

      const response = await HTTP.put(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function deleteNotificationSetting(notificationId) {
    try {
      const url = `${SEI_API_BASE}/notifications/${notificationId}`

      const response = await HTTP.delete(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function savePreference(preferences) {
    try {
      const url = `${SEI_API_BASE}/preferences`
      const payload = { preferences }

      const response = await HTTP.put(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getListOfProjects(page) {
    try {
      const url = `${SEI_API_BASE}/exams?only=name,id,meta,dashboard&page=${page}`

      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  const PER_PAGE = 100

  export default {
    name: 'TopNavigationNotifications',
    components: {
      Spinner
    },
    async created() {
      if (!SESSION.preferences.notifications) {
        SESSION.preferences.notifications = {
          mute_all: false
        }
      }
    },
    props: {
      newNotifications: {
        type: Number
      }
    },
    watch: {
      newNotifications(newNotifications) {
        const element = this.$refs.notificationsBox
        if (
          element.scrollHeight - element.scrollTop === element.clientHeight ||
          !newNotifications
        ) {
          return
        }

        this.$bvToast.show('new-notification-toast')
      }
    },
    data() {
      return {
        notifications: socketProvider.state.notifications,
        notificationSettings: [],
        editableNotificationSettings: [],
        projects: [],
        active: [],
        archived: [],
        projectOptions: [],
        triggerOptions: NOTIFICATION_TRIGGER_OPTIONS,
        typeOptions: NOTIFICATION_TYPE_OPTIONS,
        loading: true,
        saving: false,
        error: false
      }
    },
    methods: {
      async getNotificationSettings() {
        const notificationSettings = []

        let page = 1
        let hasMore = true

        while (hasMore) {
          const { data, error } = await getListOfNotificationSettings(page)

          if (error) {
            return (this.error = true)
          }

          notificationSettings.push(...data.results)
          hasMore = data.has_next
          page++
        }

        this.error = false
        this.notificationSettings = notificationSettings
      },
      onSubmit(event) {
        const isValid = VALIDATE.validateFields(event.target)
        if (isValid) {
          this.saveNotificationSettings()
        }
      },
      async saveNotificationSettings() {
        this.saving = true
        const notificationSettings = deepCopy(this.editableNotificationSettings)
        const indicesToRemove = []

        let encounteredError = false

        for (const [index, notification] of notificationSettings.entries()) {
          const deleted = notification.deleted
          const changed = notification.changed

          delete notification.deleted
          delete notification.changed

          if (deleted) {
            const { error } = await deleteNotificationSetting(notification.id)

            if (error) {
              encounteredError = true
            } else {
              indicesToRemove.push(index)
            }

            continue
          }

          if (changed) {
            const { data, error } = await saveExistingNotificationSetting(
              notification
            )

            if (error) {
              encounteredError = true
            } else {
              this.editableNotificationSettings.splice(index, 1, data)
            }

            continue
          }

          if (!notification.id) {
            const { data, error } = await saveNewNotificationSetting(
              notification
            )

            if (error) {
              encounteredError = true
            } else {
              this.editableNotificationSettings.splice(index, 1, data)
            }
          }
        }

        for (const index of indicesToRemove) {
          this.editableNotificationSettings.splice(index, 1)
        }

        if (encounteredError) {
          EVENT.alert({
            variant: 'danger',
            message:
              'A problem was encountered while saving notification settings. Please try again.'
          })
        } else {
          this.$bvModal.hide('notification-settings-modal')
        }

        this.notificationSettings = deepCopy(this.editableNotificationSettings)
        this.saving = false
      },
      addNotification() {
        const newNotification = {
          exam_id: '',
          type: '',
          event: '',
          is_muted: false
        }

        this.editableNotificationSettings.push(newNotification)
      },
      removeNotification(index) {
        const notification = this.editableNotificationSettings[index]

        if (notification.id) {
          notification.deleted = true
          return this.editableNotificationSettings.splice(
            index,
            1,
            notification
          )
        }

        this.editableNotificationSettings.splice(index, 1)
      },
      notificationChanged([value], property, index, currentNotification) {
        currentNotification[property] = value

        if (currentNotification.id) {
          let changed = false
          const existingNotification = this.notificationSettings[index]

          for (const key in existingNotification) {
            if (existingNotification[key] !== currentNotification[key]) {
              changed = true
            }
          }

          currentNotification.changed = changed
        }
      },
      async getProjects() {
        this.active = []
        this.archived = []

        let page = 1
        let moreProjects = true
        let encounteredError = false
        while (moreProjects) {
          const { data, error } = await getListOfProjects(page)

          if (error) {
            encounteredError = true
            break
          }

          const starred = this.getStars(data.results)
          this.createGroups(starred)
          moreProjects = data.has_more
          page++
        }

        if (encounteredError) {
          EVENT.alert({
            variant: 'danger',
            message: 'Encountered an error while loading project options'
          })
        }

        this.projects = [...this.active, ...this.archived]
        this.createOptions()
      },
      getStars(projects) {
        const starred = LOCAL.star.get('projects')
        for (const project of projects) {
          project.starred = starred.includes(project.id)
        }

        return projects
      },
      createGroups(projects) {
        const active = []
        const archived = []
        for (const project of projects) {
          const status = get(project, 'meta.scorpion.status')
          if (status === 'active') {
            active.push(project)
          } else {
            archived.push(project)
          }
        }
        this.active.push(...active)
        this.archived.push(...archived)
      },
      createOptions() {
        const options = []

        if (this.active.length) {
          for (const project of this.active) {
            options.push({
              label: project.name,
              id: project.id
            })
          }
        }

        if (this.archived.length) {
          for (const project of this.archived) {
            options.push({
              label: project.name + ' (Archived)',
              id: project.id
            })
          }
        }

        this.projectOptions = options
      },
      openNotificationSettingsModal() {
        this.editableNotificationSettings = deepCopy(this.notificationSettings)
        this.$bvModal.show('notification-settings-modal')
      },
      async disableAllNotifications(value) {
        SESSION.preferences.notifications.mute_all = value

        const { error } = await savePreference(SESSION.preferences)

        if (error) {
          EVENT.alert({
            variant: 'danger',
            message: 'Failed to update notification preference.'
          })
        }
      },
      notificationLink(route) {
        return `${location.origin}${route}`
      },
      onSidebarShown() {
        const notificationsBox = this.$refs.notificationsBox
        notificationsBox.scrollTop = notificationsBox.scrollHeight
        socketProvider.clearNotificationCount()
        this.loadNotificationModalData()
      },
      async loadNotificationModalData() {
        this.loading = true
        await this.getNotificationSettings()
        await this.getProjects()
        this.loading = false
      },
      onSidebarHidden() {
        socketProvider.clearNotificationCount()
      },
      formattedTime(time) {
        return this.$moment
          .utc(time)
          .local()
          .format('l h:mma')
      },
      checkForBottomOfScroll(event) {
        const element = event.target

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          this.$bvToast.hide('new-notification-toast')
        }
      },
      scrollToBottom() {
        const element = this.$refs.notificationsBox
        element.scrollTop = element.scrollHeight - element.clientHeight
      }
    },
    computed: {
      visibleNotificationSettings() {
        const visible = []

        for (const notification of this.editableNotificationSettings) {
          if (!notification.deleted) {
            visible.push(notification)
          }
        }

        return Boolean(visible.length)
      },
      allDisabled() {
        return SESSION.preferences.notifications.mute_all
      },
      email() {
        return `Email: ${SESSION.user.email || ''}`
      },
      phone() {
        return `Phone: ${SESSION.user.phone || ''}`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notifications-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .notifications-top {
    height: 100px;
  }
  .notifications-box {
    background: white;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .list-group-item {
    padding: 0.25rem 1rem 0.25rem 2rem;
  }
  .notification-badge {
    float: left;
    margin: 0 0 0 -25px;
    height: 63px;
    width: 13px;
    color: rgba(0, 0, 0, 0);
  }
  .new-notification {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: 0 auto;
    z-index: 9999;
    cursor: pointer;
  }
</style>
