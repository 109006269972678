<template>
    <div v-if="!loading">
        <b-navbar variant="light" sticky class="flex">
            <div style="flex:1; display: flex; ">
                <b-button @click="beforeSave" :disabled="shouldDisable('edit_exam_settings') || saving" class="mr-2" variant="secondary">
                    <b-spinner v-if="saving" small />
                    Save
                </b-button>
                <SurveysModal @surveys="onSurveys" @loading-surveys="onLoadingSurveys" />
                <AgreementsModal @agreements="onAgreements" @loading-agreements="onLoadingAgreements" />
            </div>
            <b-button v-if="!isNew" @click="addNewFlow" class="ml-2" variant="secondary" style="margin-left: auto;">
                Add New Flow
            </b-button>
        </b-navbar>

        <b-container class="mt-3" fluid>
            <b-row v-if="!flow.is_default" class="mb-2">
                <b-col cols="12" lg="3" sm="4">
                    <span class="mr-1" v-b-tooltip.hover.right title="Deliveries with these tags will be directed to this flow">
                        <font-awesome-icon icon="info-circle"></font-awesome-icon>
                    </span>
                    Tags
                </b-col>
                <b-col cols="12" lg="9" sm="8" class="pl-1 mb-1">
                    <tag-selector type="main" :tags="project.tags" :selected="flow.tags" @tags-updated="tagsUpdated" />
                </b-col>
            </b-row>

            <b-form-group label="Weight" label-cols-lg="3" label-cols-sm="4">
                <b-form-input v-model="flow.weight" type="number" step="1" min="0" />
            </b-form-group>

            <div class="d-flex justify-content-between align-items-center">
                <b-button class="pl-0" variant="link" size="sm" @click="toggleExpand()">
                    {{ expandOrCollapse }}
                </b-button>
            </div>

            <b-card v-for="(step, index) in flow.steps" :key="index" class="mb-2" no-body>
                <b-card-header class="p-1">
                    <b-button @click="toggleStep(step)" :disabled="loadingStep(step)" :variant="buttonVariant(step)" class="d-flex justify-content-between align-items-center min-btn-height" block>
                        <span class="p-1">
                            {{ index + 1 }}. {{ step.name }}
                        </span>

                        <b-button-group v-if="showStepReorder(step.type)">
                            <b-button @click.stop="moveArrayElement(flow.steps, index, -1)" :disabled="stepUpDisabled(index)" variant="white" size="sm">
                                <font-awesome-icon icon="arrow-up" />
                            </b-button>

                            <b-button @click.stop="moveArrayElement(flow.steps, index, 1)" :disabled="stepDownDisabled(index)" variant="white" size="sm">
                                <font-awesome-icon icon="arrow-down" />
                            </b-button>
                        </b-button-group>
                    </b-button>
                </b-card-header>

                <b-card-body v-if="showStep(step.id)">
                    <div v-if="step.isReadinessCheck">
                        <p>
                            Display the computer and connectivity checks:
                        </p>

                        <b-form-checkbox-group
                            v-model="step.settings.connectivity_checks"
                            :options="connectivityOptions"
                        />

                        <p class="mt-3">
                            Configure a readiness checklist: 
                        </p>

                        <b-form-group v-for="(_, index) in step.settings.readiness_checklist" :key="index" label="Checkbox Label" label-cols-lg="3" label-cols-sm="4">
                            <b-input-group>
                                <b-form-input v-model="step.settings.readiness_checklist[index]" />

                                <b-input-group-append>
                                    <b-button-group size="sm" class="ml-3">
                                        <b-button @click="moveArrayElement(step.settings.readiness_checklist, index, -1)" :disabled="elementUpDisabled(index)" variant="white">
                                            <font-awesome-icon icon="arrow-up" />
                                        </b-button>

                                        <b-button @click="moveArrayElement(step.settings.readiness_checklist, index, 1)" :disabled="elementDownDisabled(step.settings.readiness_checklist, index)" variant="white">
                                            <font-awesome-icon icon="arrow-down" />
                                        </b-button>

                                        <b-button @click="removeChecklistItem(step.settings.readiness_checklist, index)" variant="white" class="ml-3">
                                            <font-awesome-icon icon="trash" />
                                        </b-button>
                                    </b-button-group>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-button @click="addChecklistItem(step.settings.readiness_checklist)" variant="white" size="sm">
                            Add a checklist item
                        </b-button>
                    </div>

                    <div v-else-if="step.isMain">
                        <b-form-group label="Form selection setting" label-cols-lg="4" label-cols-sm="5" class="py-2 px-3 mb-2" style="background: #eee; margin: -20px">
                            <b-form-select v-model="step.form_order" :options="formOrderOptions" />
                        </b-form-group>

                        <div v-for="(formId, index) in flow.form_ids" :key="index" class="condition-container pb-2 my-4 d-flex justify-content-between">
                            <p>
                                {{ getFormName(formId) }}
                            </p>

                            <div>
                                <b-button @click="viewForm(index)" class="mr-3" variant="white" size="sm">
                                    View
                                </b-button>
                                <b-button-group>
                                    <b-button @click="moveForm(index, -1)" :disabled="formUpDisabled(index)" variant="white" size="sm">
                                        <font-awesome-icon icon="arrow-up" />
                                    </b-button>

                                    <b-button @click="moveForm(index, 1)" :disabled="formDownDisabled(index)" variant="white" size="sm">
                                        <font-awesome-icon icon="arrow-down" />
                                    </b-button>
                                </b-button-group>
                                <b-button @click="removeForm(index)" class="ml-3" variant="white" size="sm">
                                    <font-awesome-icon icon="trash" />
                                </b-button>
                            </div>
                        </div>
                        <div  class="pt-2 px-3 pb-3 border-top" style="margin:10px -20px -20px -20px">
                            <b-button v-if="!formSelect" @click="enableFormSelect" variant="white" size="sm" class="mt-2">
                                Select forms
                            </b-button>
                            <div v-else>
                                <b-form-group label="Select forms for this flow" label-cols-lg="4" label-cols-sm="5" description="Hold Command (mac) or Control (win) to select more than one form.">
                                    <b-form-select v-model="formsToAdd" :options="formOptions" multiple />
                                </b-form-group>
                                <b-form-group label=" " label-cols-lg="4" label-cols-sm="5" >
                                    <b-button @click="addForms" variant="primary-light" class="mr-2">
                                        Use these selected forms
                                    </b-button>
                                    <b-button @click="cancelFormAdd" variant="white" class="mr-2">
                                        Cancel
                                    </b-button>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="step.isSurvey">
                        <b-form-group label="Select a survey" label-cols-lg="3" label-cols-sm="4">
                            <b-input-group>
                                <b-form-select v-model="step.survey_id" :options="surveyOptions" />

                                <b-input-group-append>
                                    <b-button @click="viewSurvey(step.survey_id)" :disabled="!step.survey_id" variant="white">
                                        View
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-button @click="removeStep(index)" class="float-right mb-3" variant="link">
                            Remove from flow
                        </b-button>
                    </div>

                    <div v-else-if="step.isAgreement">
                        <b-form-group v-if="agreementOptions.length" label="Select an agreement" label-cols-lg="3" label-cols-sm="4">
                            <b-input-group>
                                <b-form-select v-model="step.agreement_id" :options="agreementOptions" />

                                <b-input-group-append>
                                    <b-button @click="viewAgreement(step.agreement_id)" :disabled="!step.agreement_id" variant="white">
                                        View
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-spinner v-else />

                        <b-button @click="removeStep(index)" class="float-right mb-3" variant="link">
                            Remove from flow
                        </b-button>
                    </div>

                    <div v-else>
                        
                        <p class="condition-container pb-2">After exams are completed examinees are directed to the Scorpion score report unless there are conditions with ending actions configured below:</p>
                        
                        <div v-for="(condition, index) in step.settings.conditions" :key="index" class="mb-4 condition-container">
                            <b-row>
                                <b-col cols="9">
                                    <b-form-group label="Condition" label-cols-lg="3" label-cols-sm="4">
                                        <b-form-select v-if="expandedCondition(condition.condition_name)" @change="onConditionNameChange($event, condition)" :value="condition.condition_name" :options="conditionNameOptions" />

                                        <b-input-group v-else>
                                            <b-input-group-prepend class="mr-1">
                                                <b-form-select @change="onConditionNameChange($event, condition)" :value="condition.condition_name" :options="conditionNameOptions" />
                                            </b-input-group-prepend>

                                            <b-form-input 
                                                v-if="showConditionField('below_score', condition.condition_name)" 
                                                v-model.number="condition.condition_params[0]"
                                                type="number" 
                                                placeholder="Enter Score" 
                                            />

                                            <b-form-input 
                                                v-if="showConditionField('score', condition.condition_name)" 
                                                v-model.number="condition.condition_params[0]"
                                                type="number" 
                                                placeholder="Enter Score" 
                                            />

                                            <tag-selector 
                                                v-if="showConditionField('tag', condition.condition_name)"
                                                :index="index"
                                                type="condition"
                                                :tags="project.tags"
                                                :selected="formatCondition(condition.condition_params[0])"
                                                :taggable="false"
                                                :multiple="false"
                                                @tags-updated="tagsUpdated"
                                                style="width: 100%"
                                                class="mt-3"
                                            />

                                            <tag-selector 
                                                v-if="showConditionField('missing_tag', condition.condition_name)"
                                                :index="index"
                                                type="condition"
                                                :tags="project.tags"
                                                :selected="formatCondition(condition.condition_params[0])"
                                                :taggable="false"
                                                :multiple="false"
                                                @tags-updated="tagsUpdated"
                                                style="width: 100%"
                                                class="mt-3"
                                            />
                                            
                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group label="Action" label-cols-lg="3" label-cols-sm="4" :description="actionDescription(condition.action_name)">
                                        <b-form-select v-if="expandedAction(condition.action_name)" @change="onActionNameChanged($event, condition)" :value="condition.action_name" :options="actionOptions" />

                                        <b-input-group v-else>
                                            <b-input-group-prepend class="mr-1">
                                                <b-form-select @change="onActionNameChanged($event, condition)" :value="condition.action_name" :options="actionOptions" />
                                            </b-input-group-prepend>

                                            <b-form-input
                                                v-if="showConditionField('redirect', condition.action_name)"
                                                v-model="condition.action_params[0]"
                                                placeholder="Enter URL"
                                            />

                                            <tag-selector 
                                                v-if="showConditionField('new_delivery', condition.action_name)"
                                                :index="index"
                                                type="action"
                                                :tags="project.tags"
                                                :selected="condition.action_params[0]"
                                                @tags-updated="tagsUpdated"
                                                style="width: 100%"
                                                class="mt-3"
                                            />

                                            <tag-selector 
                                                v-if="showConditionField('add_tags', condition.action_name)"
                                                :index="index"
                                                type="action"
                                                :tags="project.tags"
                                                :selected="condition.action_params[0]"
                                                @tags-updated="tagsUpdated"
                                                style="width: 100%"
                                                class="mt-3"
                                            />

                                            <tag-selector 
                                                v-if="showConditionField('remove_tags', condition.action_name)"
                                                :index="index"
                                                type="action"
                                                :tags="project.tags"
                                                :selected="condition.action_params[0]"
                                                @tags-updated="tagsUpdated"
                                                style="width: 100%"
                                                class="mt-3"
                                            />
                                        </b-input-group>
                                    </b-form-group>                                   
                                </b-col>
                                <b-col class="text-right">
                                    <b-button-group>
                                        <b-button @click="moveCondition(step.settings.conditions, index, -1)" :disabled="conditionUpDisabled(index)" variant="white" size="sm">
                                            <font-awesome-icon icon="arrow-up" />
                                        </b-button>

                                        <b-button @click="moveCondition(step.settings.conditions, index, 1)" :disabled="conditionDownDisabled(step.settings.conditions, index)" variant="white" size="sm">
                                            <font-awesome-icon icon="arrow-down" />
                                        </b-button>

                                        <b-button @click="removeCondition(step, index)"  variant="white" size="sm" class="ml-3">
                                            <font-awesome-icon icon="trash" />
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </div>
                        <b-button @click="addCondition(step)" class="mt-2" variant="white" size="sm">
                            Add a condition
                        </b-button>
                    </div>
                </b-card-body>
            </b-card>

            <div class="d-flex justify-content-between mt-3">
                <div>
                    <b-button @click="addSurvey" class="mr-2" variant="primary-light" size="sm">
                        Add Survey
                    </b-button>

                    <b-button @click="addAgreement" variant="primary-light" size="sm">
                        Add Agreement
                    </b-button>
                </div>

                <b-button v-if="!flow.is_default && !isNew" @click="deleteFlow" :disabled="deleting" variant="text" class="text-danger">
                    <b-spinner v-if="deleting" small />

                    Delete This Flow
                </b-button>
            </div>
        </b-container>
    </div>

    <Spinner v-else />
</template>

<script>
import { deepCopy } from '../../utils/misc.js'
import { EVENT } from '../../utils/event-bus.js'
import { HTTP } from '../../utils/requests.js'
import { SEI_API_BASE } from '../../utils/constants.js'
import { SESSION } from '../../utils/session.js'

import isEqual from 'lodash.isequal'
import { v4 as uuidv4 } from 'uuid'

import Spinner from '../Spinner.vue'
import SurveysModal from './SurveysModal.vue'
import AgreementsModal from './AgreementsModal.vue'
import TagSelector from './TagSelector.vue'

async function getProject (projectId) {
    try {
        // Only returns project tags
        let url = `${SEI_API_BASE}/exams/${projectId}?only=tags`
        let response = await HTTP.get(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function getFlowRequest (flowId) {
    if (flowId === 'new') {
        return { data: deepCopy(DEFAULT_FLOW) }
    }

    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/flows/${ flowId }?include=form_ids`

        const response = await HTTP.get(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function getFormsRequest () {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/forms`

        const response = await HTTP.get(url)

        return { data: response.data.results }
    } catch (error) {
        return { error }
    }
}

async function saveNewFlowRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/flows`

        const response = await HTTP.post(url, payload)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function saveFlowRequest (payload) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/flows/${ payload.id }?include=form_ids`

        const response = await HTTP.put(url, payload)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function deleteFlowRequest (flowId) {
    try {
        const url = `${ SEI_API_BASE }/exams/${ SESSION.project.id }/flows/${ flowId }`

        const response = await HTTP.delete(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

function formatFlow (flow, previousFlow) {
    const hasReadinessCheckStep = flow.steps.find(step => step.type === 'readiness_check')

    if (!hasReadinessCheckStep) {
        flow.steps.unshift(READINESS_CHECK_STEP)
    }

    const hasConclusionStep = flow.steps.find(step => step.type === 'conclusion')

    if (!hasConclusionStep) {
        flow.steps.push(CONCLUSION_STEP)
    }

    for (const [index, step] of flow.steps.entries()) {
        if (previousFlow) {
            step.id = previousFlow.steps[index].id
        } else {
            step.id = uuidv4()
        }

        step.name = NAME_LOOKUP[step.type]

        step[ BOOL_LOOKUP[step.type] ] = true

        if (step.isMain && !step.form_order) {
            step.form_order = 'random'
        }
    }
}

function checkForChanges (flowA, flowB) {
    return !isEqual(flowA, flowB)
}

const READINESS_CHECK_STEP = {
    type: 'readiness_check',
    settings: {
        connectivity_checks: ['socket', 'ping', 'download', 'upload', 'cookies', 'browser'],
        readiness_checklist: []
    }
}

const MAIN_STEP = {
    type: 'main',
    form_order: 'random'
}

const CONCLUSION_STEP = {
    type: 'conclusion',
    settings: {
        conditions: []
    }
}

const DEFAULT_FLOW = {
    tags: [],
    weight: 100,
    form_ids: [],
    steps: [ READINESS_CHECK_STEP, MAIN_STEP, CONCLUSION_STEP ]
}

const CONNECTIVITY_OPTIONS = [
    { value: 'socket', text: 'Socket' },
    { value: 'ping', text: 'Ping' },
    { value: 'download', text: 'Download' },
    { value: 'upload', text: 'Upload' },
    { value: 'cookies', text: 'Cookies' },
    { value: 'browser', text: 'Browser' },
]

const FORM_ORDER_OPTIONS = [
    { value: 'random', text: 'Random' },
    { value: 'in_order', text: 'In order (top to bottom)' }
]

const NAME_LOOKUP = {
    'readiness_check': 'Readiness Check',
    'conclusion': 'Conclusion',
    'main': 'Take Exam',
    'survey': 'Survey',
    'agreement': 'Agreement'
}

const BOOL_LOOKUP = {
    'readiness_check': 'isReadinessCheck',
    'conclusion': 'isConclusion',
    'main': 'isMain',
    'survey': 'isSurvey',
    'agreement': 'isAgreement'
}

const DEFAULT_SELECT_VALUE = {
    text: 'Select',
    value: null
}

const CONDITION_OPTIONS = [
    { value: 'passed', text: 'Delivery has a passing score' },
    { value: 'failed', text: 'Delivery has a failing score' },
    { value: 'out_of_time', text: 'Delivery ran out of time' },
    { value: 'score', text: 'Delivery score is above', params: [0] },
    { value: 'below_score', text: 'Delivery score is below', params: [0] },
    { value: 'tag', text: 'Delivery includes tag', params: [''] },
    { value: 'missing_tag', text: 'Delivery does not include tag', params: [''] }
]

const ACTION_OPTIONS = [
    {
        label: 'Actions',
        options: [
            { value: 'add_tags', text: 'Add Tags To Delivery', params: [[]] },
            { value: 'remove_tags', text: 'Remove Tags From Delivery', params: [[]] },
        ]
    },
    {
        label: 'Ending Actions',
        options: [
            { value: 'redirect', text: 'Redirect', params: [''] },
            { value: 'new_delivery', text: 'Launch a New Delivery', params: [[]] }
        ]
    }
]

export default {
    name: 'Flow',
    components: {
        Spinner,
        SurveysModal,
        AgreementsModal,
        TagSelector
    },
    props: {
        project: {
            type: Object
      }
    },
    data () {
        return {
            loading: true,
            loadingForms: false,
            loadingSurveys: false,
            loadingAgreements: false,
            saving: false,
            deleting: false,
            flow: {},
            formOptions: [],
            surveyOptions: [],
            agreementOptions: [],
            connectivityOptions: CONNECTIVITY_OPTIONS,
            formOrderOptions: FORM_ORDER_OPTIONS,
            conditionNameOptions: CONDITION_OPTIONS,
            actionOptions: ACTION_OPTIONS,
            active: [],
            formSelect: false,
            formsToAdd: []
        }
    },
    async created () {
        const { params: { flowId } } = this.$route

        await this.getFlow(flowId)
        
        this.flow.steps.forEach(step => this.activateStep(step))
    },
    beforeRouteLeave (to, from, next) {
        const flowChanged = checkForChanges(this.flow, this.original)

        if (flowChanged) {
            const message = 'Would you like to leave without saving changes to this flow?'

            const confirmed = confirm(message)

            if (!confirmed) return
        }

        next()
    },
    methods: {
        async getFlow (flowId) {
            this.loading = true

            const { data, error } = await getFlowRequest(flowId)

            this.loading = false

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to load flow.'
                }

                return EVENT.alert(alertData)
            }

            formatFlow(data)

            this.flow = data

            this.original = deepCopy(data)
        },
        async getForms (stepId) {
            this.loadingForms = true

            const { data, error } = await getFormsRequest()

            this.loadingForms = false

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to load forms.'
                }

                return EVENT.alert(alertData)
            }

            this.setFormOptions(data)

            this.active.push(stepId)
        },
        setFormOptions (forms) {
            const formOptions = []

            for (const form of forms) {
                const formOption = {
                    text: form.name,
                    value: form.id
                }

                formOptions.push(formOption)
            }

            this.formOptions = formOptions
        },
        cacheNewTag() {
            const currentTags = Object.keys(this.project.tags)
            if (this.flow.tags.length === 1) {
                const tag = this.flow.tags[0]
                if (!currentTags.includes(tag)) {
                    const formNames = []
                    for (const form of this.formOptions) {
                        if (this.flow.form_ids.includes(form.value)) {
                            formNames.push(form.text)
                        }
                    }
                    this.$set(this.project.tags, tag, { forms: formNames })
                }
            }
        },
        async beforeSave () {
            if (!this.flow.form_ids.length) {
                const message = 'You have not selected any forms to deliver. This flow will not deliver any items. Save anyway?'

                const options = {
                    title: 'Confirm',
                    okVariant: 'success',
                    okTitle: 'Save',
                    cancelVariant: 'white',
                    size: 'sm',
                    noFade: true
                }

                const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

                if (!confirmed) return
            }

            if (!this.flow.id) {
                return this.saveNewFlow()
            }

            this.saveFlow()
        },
        async saveNewFlow () {
            this.saving = true

            const { data, error } = await saveNewFlowRequest(this.flow)
            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to save flow.'
                }
                this.saving = false

                return EVENT.alert(alertData)
            }

            await this.updateTags()

            this.saving = false

            const route = {
                name: 'projectFlow',
                params: {
                    flowId: data.id
                }
            }

            EVENT.updateFlowCount(1)

            this.$router.push(route)
        },
        async saveFlow () {
            const blankSteps = []

            for (const [index, step] of this.flow.steps.entries()) {
                const blankAgreement = step.isAgreement && !step.agreement_id
                const blankSurvey = step.isSurvey && !step.survey_id

                if (blankAgreement || blankSurvey) blankSteps.push(String(index + 1))
            }

            if (blankSteps.length) {
                const plural = blankSteps.length > 1 ? 's' : ''

                const areIs = blankSteps.length > 1 ? 'are' : 'is'

                const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })

                const formattedSteps = formatter.format(blankSteps)

                const message = `Step${ plural } ${ formattedSteps } ${ areIs } missing a selection. Save anyway?`
                
                const options = {
                    title: 'Confirm',
                    okVariant: 'secondary',
                    okTitle: 'Save',
                    cancelVariant: 'white',
                    size: 'sm',
                    noFade: true
                }

                const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

                if (!confirmed) return
            }

            this.saving = true

            const { data, error } = await saveFlowRequest(this.flow)

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to save flow.'
                }

                return EVENT.alert(alertData)
            }

            formatFlow(data, this.flow)

            this.flow = data

            this.original = deepCopy(data)

            await this.updateTags()

            this.saving = false
        },
        async updateTags() {
            const { data, error } = await getProject(this.project.id)
            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to update tags.'
                }

                return EVENT.alert(alertData)
            }
            this.project.tags = data.tags // eslint-disable-line vue/no-mutating-props
        },
        async deleteFlow () {
            const message = 'Are you sure you would like to delete this flow?'

            const options = {
                title: 'Confirm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelVariant: 'white',
                size: 'sm',
                noFade: true
            }

            const confirmed = await this.$bvModal.msgBoxConfirm(message, options)

            if (!confirmed) return

            this.deleting = true

            const { error } = await deleteFlowRequest(this.flow.id)

            this.deleting = false

            if (error) {
                const alertData = {
                    variant: 'danger',
                    message: 'Failed to delete flow.'
                }

                return EVENT.alert(alertData)
            }

            const route = {
                name: 'projectFlows'
            }

            EVENT.updateFlowCount(-1)

            this.$router.push(route)
        },
        onSurveys (surveys, error, stepId) {
            if (!error) {
                const surveyOptions = [ DEFAULT_SELECT_VALUE ]

                const surveyIds = new Set()

                for (const survey of surveys) {
                    const surveyOption = {
                        text: survey.name,
                        value: survey.id
                    }

                    surveyOptions.push(surveyOption)

                    surveyIds.add(survey.id)
                }

                this.surveyOptions = surveyOptions

                for (const step of this.flow.steps) {
                    if (step.isSurvey && !surveyIds.has(step.survey_id)) {
                        step.survey_id = null
                    }
                }
            }

            this.active.push(stepId)

            this.loadingSurveys = false
        },
        onLoadingSurveys () {
            this.loadingSurveys = true
        },
        onAgreements (agreements, error, stepId) {
            if (!error) {
                const agreementOptions = [ DEFAULT_SELECT_VALUE ]

                const agreementIds = new Set()

                for (const agreement of agreements) {
                    const agreementOption = {
                        text: agreement.name,
                        value: agreement.id
                    }

                    agreementOptions.push(agreementOption)

                    agreementIds.add(agreement.id)
                }

                this.agreementOptions = agreementOptions

                for (const step of this.flow.steps) {
                    if (step.isAgreement && !agreementIds.has(step.agreement_id)) {
                        step.agreement_id = null
                    }
                }
            }

            this.active.push(stepId)

            this.loadingAgreements = false
        },
        onLoadingAgreements () {
            this.loadingAgreements = true  
        },
        getFormName (formId) {
            const form = this.formOptions.find(form => form.value === formId)

            return form.text
        },
        viewSurvey (surveyId) {
            EVENT.$emit('view-survey', surveyId)
        },
        viewAgreement (agreementId) {
            EVENT.$emit('view-agreement', agreementId)
        },
        showStepReorder (stepType) {
            return stepType !== 'readiness_check' && stepType !== 'conclusion'
        },
        addChecklistItem (array) {
            array.push('')
        },
        removeChecklistItem (array, index) {
            array.splice(index, 1)
        },
        moveChecklistItem (index, by) {
            const [ checklistItem ] = this.flow.readiness_checklist.splice(index, 1)

            this.flow.readiness_checklist.splice(index + by, 0, checklistItem)
        },
        elementUpDisabled (index) {
            return !index
        },
        elementDownDisabled (array, index) {
            return index === array.length - 1
        },
        addSurvey () {
            const step = {
                name: 'Survey',
                type: 'survey',
                survey_id: null,
                isSurvey: true,
                id: uuidv4()
            }

            const index = this.flow.steps.length - 1

            this.flow.steps.splice(index, 0, step)
            this.activateStep(step)
        },
        addAgreement () {
            const step = {
                name: 'Agreement',
                type: 'agreement',
                agreement_id: null,
                isAgreement: true,
                id: uuidv4()
            }

            const index = this.flow.steps.length - 1

            this.flow.steps.splice(index, 0, step)
            this.activateStep(step)
        },
        tagsUpdated(tags, index, type) {
            if (type === 'main') {
                this.flow.tags = tags
            }
            else if (['condition', 'action'].includes(type)) {
                for (const step of this.flow.steps) {
                    if (step.isConclusion) {
                        switch (type) {
                            case 'condition':
                                step.settings.conditions[index].condition_params[0] = tags
                                break;
                            case 'action':
                                step.settings.conditions[index].action_params[0] = tags
                                break;
                            default:
                                break;
                        }
                        break;
                    }
                }
            }
        },
        removeTag(index) {
            this.flow.tags.splice(index, 1)
        },
        removeStep (index) {
            this.flow.steps.splice(index, 1)
        },
        moveArrayElement (array, index, by) {
            const [ block ] = array.splice(index, 1)

            array.splice(index + by, 0, block)
        },
        stepUpDisabled (index) {
            return index === 1
        },
        stepDownDisabled (index) {
            return index === this.flow.steps.length - 2
        },
        moveForm (index, by) {
            const [ formId ] = this.flow.form_ids.splice(index, 1)

            this.flow.form_ids.splice(index + by, 0, formId)
        },
        formUpDisabled (index) {
            return !index
        },
        formDownDisabled (index) {
            return index === this.flow.form_ids.length - 1
        },
        moveCondition (conditions, index, by) {
            const [ condition ] = conditions.splice(index, 1)

            conditions.splice(index + by, 0, condition)
        },
        conditionUpDisabled (index) {
            return !index
        },
        conditionDownDisabled (conditions, index) {
            return index === conditions.length - 1
        },
        activateStep (step) {
            if (step.isMain && !this.loadingForms && !this.formOptions.length) {
                return this.getForms(step.id)
            }

            if (step.isSurvey && !this.loadingSurveys && !this.surveyOptions.length) {
                return EVENT.$emit('get-surveys', step.id)
            }

            if (step.isAgreement && !this.loadingAgreements && !this.agreementOptions.length) {
                return EVENT.$emit('get-agreements', step.id)
            }

            this.active.push(step.id)
        },
        toggleStep(step) {
            if (this.active.includes(step.id)) {
                this.active = this.active.filter(activeId => activeId !== step.id)
            } else {
                this.active.push(step.id)
            }
        },
        loadingStep (step) {
            if (step.isMain && this.loadingForms) return true

            else if (step.isSurvey && this.loadingSurveys) return true

            else if (step.isAgreement && this.loadingAgreements) return true

            return false
        },
        showStep (stepId) {
            return this.active.includes(stepId)
        },
        buttonVariant (step) {
            return step.isMain ? 'info' : 'primary'
        },
        addCondition (step) {
            const condition = {
                condition_name: 'passed',
                action_name: 'add_tags',
                action_params: [[]]
            }

            step.settings.conditions.push(condition)
        },
        removeCondition (step, index) {
            step.settings.conditions.splice(index, 1)
        },
        enableFormSelect () {
            this.formSelect = true

            const formIds = this.flow.form_ids || []

            this.formsToAdd = [ ...formIds ]
        },
        cancelFormAdd () {
            this.formSelect = false
        },
        addForms () {
            this.formSelect = false

            this.$set(this.flow, 'form_ids', this.formsToAdd)
        },
        viewForm (index) {
            const formId = this.flow.form_ids[index]

            const route = {
                name: 'projectFormEditor',
                params: {
                    formId
                }
            }

            this.$router.push(route)
        },
        removeForm (index) {
            this.flow.form_ids.splice(index, 1)
        },
        addNewFlow () {
            const route = {
                name: 'projectFlow',
                params: {
                    flowId: 'new'
                }
            }

            this.$router.push(route)
        },
        expandedCondition (conditionType) {
            return ['passed', 'failed', 'out_of_time'].includes(conditionType)
        },
        expandedAction (actionType) {
            return ['scorpion_score_report'].includes(actionType)
        },
        showConditionField (fieldType, conditionType) {
            return fieldType === conditionType
        },
        onConditionNameChange (value, condition) {
            const { params } = CONDITION_OPTIONS.find(option => option.value === value)

            if (params) {
                condition.condition_params = [ ...params ]
            } else {
                condition.condition_params = []
            }

            condition.condition_name = value
        },
        onActionNameChanged (value, condition) {
            let params

            for (const group of ACTION_OPTIONS) {
                const option = group.options.find(o => o.value === value)

                if (option) {
                    params = option.params

                    break
                }
            }

            if (params) {
                condition.action_params = [ ...params ]
            } else {
                condition.action_params = []
            }

            condition.action_name = value
        },
        actionDescription (actionName) {
            let message = ''

            if (['redirect', 'new_delivery'].includes(actionName)) {
                message = '* This action will end the conclusion step and no conditions below this will be evaluated.'
            }

            return message
        },
        toggleExpand () {
            if (this.active.length > 0) {
                this.active = []
                return
            }

            this.flow.steps.forEach(step => this.active.push(step.id))
        },
        formatCondition (condition) {
            if (condition) {
                return [condition]
            }
            return []
        },
        shouldDisable(neededPerms) {
            return !SESSION.hasPermissions(neededPerms)
        },
    },
    computed: {
        isNew () {
            return this.$route.params.flowId === 'new'
        },
        expandOrCollapse () {
            if (this.active.length > 0) return 'Collapse All'
            return 'Expand All'
        }
    }
}
</script>

<style lang="scss" scoped>
.condition-container {
    border-bottom: 1px dashed rgb(211, 211, 211);
}

</style>
