<template>
  <div>
    <!-- eslint-disable vue/no-mutating-props -->
    <v-select
      :disabled="disabled || !isPremium"
      :options="getOptions(field)"
      :reduce="option => option.value"
      :required="field.required"
      :selectable="option => !option.disabled"
      :taggable="field.extendable"
      :multiple="multiple"
      :data-value="fieldIsValid"
      :create-option="tag => ({ text: tag, value: tag })"
      @input="updateField"
      v-model="values[field.id]"
      label="text"
      placeholder="Not selected"
      style="backgroundColor: white"
    >
    <!-- eslint-enable vue/no-mutating-props -->
      <template #selected-option-container="{ option, deselect }">
        <span :class="`vs__selected border ${option.color ? 'pl-0' : ''}`">
          <div
            :style="{
              backgroundColor: option.color,
              width: '15px',
              height: '100%',
              borderRadius: '3px 0 0 3px'
            }"
            class="mr-1"
            v-if="option.color"
          ></div>
          {{ option.text }}
          <button
            :disabled="disabled || !isPremium"
            :style="{ color: 'rgba(60, 60, 60, 0.5)' }"
            @click.stop="deselect(option)"
            @mousedown.stop
            class="vs__deselect no-focus"
            type="button"
          >
            <font-awesome-icon icon="times"></font-awesome-icon>
          </button>
        </span>
      </template>
      <template #option="{ color, text }">
        <div
          :style="{ background: color }"
          class="option p-1 d-inline-block"
          v-if="color"
        ></div>
        {{ text }}
      </template>
    </v-select>
    <div style="overflow-wrap: break-word" v-for="(description, index) in descriptions" :key="index">
      <small v-if="description.text">
        <span v-if="multiple">{{ description.name }}:&nbsp;</span>
        {{ description.text }}
      </small>
    </div>
  </div>
</template>

<script>
  import { SESSION } from '../../utils/session'
  import { initMetaHelper } from '../../utils/meta-helper'

  import get from 'lodash.get'

  export default {
    name: 'ItemMetaSelect',
    props: {
      field: {
        type: Object,
        required: true
      },
      values: {
        type: Object,
        required: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    created() {
      const { fieldId, optionId } = this.$route.query
      if (this.field.id === fieldId && this.field.options[optionId]) {
        this.values[fieldId] = optionId // eslint-disable-line vue/no-mutating-props
      }
      this.metaHelper = initMetaHelper(SESSION.project)
      this.updateField(this.values[this.field.id])
    },
    data() {
      return {
        fieldIsValid: false,
        descriptions: []
      }
    },
    methods: {
      getOptions(field) {
        return this.metaHelper.getFieldOptions(field.id)
      },
      updateField(value) {
        const length = get(value, 'length')
        this.fieldIsValid = length ? length : ''
        if (Array.isArray(value)) {
          const descriptions = []
          for (const optionId of value) {
            const option = get(this.field, `options.${optionId}`)
            if (option) {
              descriptions.push({ name: option.text, text: option.description })
            }
          }
          this.descriptions = descriptions
        } else {
          const option = get(this.field, `options.${value}`)
          if (option) {
            this.descriptions = [{ name: option.text, text: option.description }]
          }
        }
      }
    },
    computed: {
      isPremium() {
        return SESSION.isPremium()
      }
    }
  }
</script>
