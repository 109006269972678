<template>
  <b-container class="mt-3" fluid v-if="!loading">
    <b-row class="sticky-tools">
      <b-col cols="2">
        <b-badge
          class="text-capitalize statuses"
          v-b-tooltip="{ trigger: 'hover', interactive: false }"
          v-if="content.isComplete"
          >C</b-badge
        >
        <b-badge
            class="text-capitalize statuses"
            variant="warning"
            v-b-tooltip="{ trigger: 'hover', interactive: false }"
            v-if="!content.isComplete"
            >I</b-badge
          >
      </b-col>
      <b-col cols="12" sm="6" md="6" class="mb-1">
        <b-input-group>
          <b-input size="sm" type="text" placeholder="Type to search" v-model="searchText" class="mr-3"></b-input>
          <TranslateManyContentButton :project="project" :language="language" class="ml-3" :object="content" />
          <b-button @click="initPreset" variant="white" size="sm" class="py-0">Use a preset</b-button>
        </b-input-group>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button-group size="sm" class="ml-2">
          <b-button @click="save(0)" variant="warning" class="mr-1" size="sm">Save as incomplete</b-button>
          <b-button @click="save(1)" size="sm">Save as complete</b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <div v-for="productName in filteredProducts" :key="productName" class="mb-3">
      <b-button variant="primary" @click="setVisibleStrings(productName)" class="w-100 text-left">{{ friendly(productName) }}</b-button>
      <b-card v-if="visibleStrings === productName" class="mt-1">
        <b-row v-for="(value, key) in filteredStrings(productName)" :key="key" class="border-bottom p-2 flex-wrap flex-column flex-md-row">
          <b-col cols="12" md="6" class="p-1">
            &nbsp;
          </b-col>
          <b-col cols="12" md="6" class="p-1">
            <b-row align-v="stretch">
              <b-col>
                <TranslateContentButton :project="project" :language="language" :content="value" v-model="content.form[productName][key]" />
              </b-col>
            </b-row>
          </b-col>
          <b-card no-body class="w-100 px-2 py-1 m-1 value-box" bg-variant="light">{{ value }}</b-card>
          <b-input v-model="content.form[productName][key]" style="flex:1;" class="m-1" v-direction="language.properties.direction"></b-input>
        </b-row>
      </b-card>
    </div>
    
    <b-modal 
      id="preset-modal"
      title="Modal"
      scrollable
      centered 
      static
      footer-sm 
      no-fade 
      size="lg"
    >
    <template #modal-header="{}" flex-sm-column>
      Preset Translations
    </template>
    <b-form-group
      label="Select a language preset"
      label-cols-lg="3"
      label-cols-sm="4">
      <b-select :options="builtinLanguageOptions" v-model="selectedPresetLanguage" class="mb-3"></b-select>
    </b-form-group>
    <b-form-group
      label="Select tools to apply preset translations"
      label-cols-lg="3"
      label-cols-sm="4">
      <b-select :options="productNameOptions" multiple v-model="selectedProductsForPreset"></b-select>
    </b-form-group>
    <template #modal-footer="{ close }">
      <b-col class="text-right">
        <b-button-group>
          <b-button @click="close" variant="white">Cancel</b-button>
          <b-button @click="applyPreset" variant="primary">Apply</b-button>
        </b-button-group>
      </b-col>
    </template>
  </b-modal>

  </b-container>
  <Spinner v-else />
</template>
<script>
import Spinner from '../Spinner'
import { TranslationsHelper, TranslationType } from '../../utils/translation'
import TranslateContentButton from './TranslateContentButton.vue'
import { EVENT } from '../../utils/event-bus'
import TranslateManyContentButton from './TranslateManyContentButton.vue'


export default {
  name: 'LanguagesTranslationsExamInterface',
  components: {
    Spinner,
    TranslateContentButton,
    TranslateManyContentButton
  },
  props: {
    project: {
      type: Object
    },
    language: {
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      content: {},
      helper: null,
      selectedProductsForPreset: [],
      selectedPresetLanguage: null,
      productNameOptions: [],
      builtinLanguageOptions: [
        {value:null, text: 'Select language', disabled: true},
        {value:'عربى', text:'عربى'},
        {value:'中国人', text:'中国人'},
        {value:'Français', text:'Français'},
        {value:'日本', text:'日本'},
        {value:'Español', text:'Español'},
        {value:'Deutsch', text:'Deutsch'}
      ],
      searchText: '',
      visibleStrings: ''
    }
  },
  async created() {
    this.helper = TranslationsHelper.Factory(this.project.id, this.language.id, TranslationType.interface)

    await this.fetchContent()
    await this.fetchTranslations()
    this.setProductNames()
    this.setVisibleStrings(this.productNameOptions[0].value)

    this.loading = false
  },
  methods: {
    async fetchContent() {
      await this.helper.fetchContent()

      this.content = this.helper.things[0]
    },
    async fetchTranslations() {
      await this.helper.fetchTranslations()

      this.content.applyTranslation(this.helper.translations[0])
    },
    friendly(str) {
      const friendlyKey = str.replace(/_/g, ' ').replace('northern hawk', '')

      return friendlyKey[0].toUpperCase() + friendlyKey.slice(1)
    },
    async save(complete) {
      const response = await this.helper.save(complete)
      const {error} = response[0]

      if (error) {``
        return EVENT.alert({
          variant: 'danger',
          message: 'Failed to save! Please try again.'
        })
      }

      EVENT.alert({
        variant: 'success',
        message: 'Saved'
      })
    },
    initPreset() {
      this.$bvModal.show('preset-modal')
    },
    setProductNames() {
      this.productNameOptions = this.content.products.map(product => {
        const text = product.replace(/_/g, ' ').replace('northern hawk', '')

        return {value: product, text}
      })
    },
    async applyPreset() {
      this.$bvModal.hide('preset-modal')

      const translatedStrings = await this.helper.fetchNamedInterface({ languageName: this.selectedPresetLanguage })

      for (const key of Object.keys(translatedStrings)) {
        if (!this.selectedProductsForPreset.includes(key)) {
          translatedStrings[key] = Object.keys(translatedStrings[key]).reduce((acc, next) => {
            acc[next] = this.content.translation.translation[key][next] || ''

            return acc
          }, {})
        }
      }

      const translation = this.content.translation || {translation: {}}

      translation.translation = {
        ...translation.translation,
        ...translatedStrings
      }

      this.content.applyTranslation(translation)
    },
    filteredStrings(productName) {
      if (this.searchText && this.searchText.length) {
        const strings = this.content.strings(productName)
        const keys = Object.keys(strings)

        const found = {}
        const parsedSearchText = this.searchText.replace(/\s/g, '_')

        for (const key of keys) {
          const keyMatches = key.match(parsedSearchText)

          if (keyMatches?.length) found[key] = strings[key]
          else {
            const valueMatches = String(strings[key]).match(this.searchText)

            if (valueMatches?.length) {
              found[key] = strings[key]
            }

          }
        }

        return found        
      }

      return this.content.strings(productName)
    },
    setVisibleStrings(productName) {
      this.visibleStrings = this.visibleStrings === productName ? '' : productName
    }
  },
  computed: {
    filteredProducts() {
      if (this.searchText && this.searchText.length) {
        const found = new Set()

        for (const product of this.content.products) {
          const strings = this.content.strings(product)

          const keys = Object.keys(strings)
          const values = Object.values(strings)

          for (const key of keys) {
            const matches = key.match(this.searchText)
            if (matches?.length) found.add(product)
          }

          for (const value of values) {
            const matches = String(value).match(this.searchText)
            if (matches?.length) found.add(product)
          }
        }

        return [...found]
      }

      return this.content.products
    }
  }
}
</script>

<style lang="scss" scoped>
  .sticky-tools {
    position:sticky; 
    top: 40px; 
    z-index: 1; 
    padding: 8px 0; 
    background-color: #fff; 
    border-bottom: 1px dashed #eee;
    margin-bottom: 30px;
  }
  .value-box {
    flex:1; 
    overflow:auto; 
    white-space:nowrap;
  }
</style>
